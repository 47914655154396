/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Search from "../Search";
import SearchAdvanced from "../SearchAdvanced";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface Props {
  scrollTop: () => void;
  history: any;
}

const SearchComponent: FC<Props> = ({ scrollTop, history }) => {
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const user = useSelector((state: AppState) => state.auth.user);
  useEffect(() => {
    if (window.localStorage.getItem("searchType") == "adv") {
      setIsAdvancedSearch(true);
    }
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      {/* <> */}
      {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
      {/* <div
          style={{
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.5)",
            width: 600,
          }}
          > */}
      {/* <div className="md-form-register md-form-mobile">
            <div className="container"> */}
      <div
        className="form-register"
        id="search_form"
        style={{
          paddingTop: 40,
          // left: 55,
          position: "relative",
        }}
      >
        <h2 className={"d-lg-block"} style={{ fontSize: 30 }}>
          No venues found. Please try again.
        </h2>
        {/* <p
                    style={{
                      color: "#34B6CE",
                      marginTop: 10,
                      marginBottom: 30,
                      fontSize: 18,
                    }}
                  >
                    From only $69 per month.
                  </p>
                  <>
                    {window.localStorage.getItem("searchType") == "adv" ? (
                      <SearchAdvanced history={history} scrollTop={scrollTop} />
                    ) : (
                      <Search
                        history={history}
                        isAdvancedSearch={isAdvancedSearch}
                        scrollTop={scrollTop}
                      />
                    )}
                    <div className="booking-search__filter">
                      <a
                        href="#"
                        onClick={() => {
                          window.localStorage.setItem(
                            "searchType",
                            isAdvancedSearch ? "basic" : "adv"
                          );
                          setIsAdvancedSearch(!isAdvancedSearch);
                        }}
                      >
                        {window.localStorage.getItem("searchType") == "adv"
                          ? "Basic Search"
                          : "Advanced Search"}
                      </a>
                    </div>
                  </>
                  <p
                    style={{
                      paddingTop: 22,
                      textAlign: "center",
                      lineHeight: "125%",
                      paddingBottom: 30,
                    }}
                  >
                    Can’t find a venue near you? Drop us an email at{" "}
                    <a
                      href="mailto:hello@dorpee.com"
                      style={{ color: "#4956A2" }}
                    >
                      hello@dorpee.com
                    </a>{" "}
                    and we will work to find you a space.
                  </p>
                 */}
      </div>
      {/* </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}

      <p className="search-result">
        Can’t find a venue near you? Drop us an email at
        <a
          href="mailto:hello@dorpee.com"
          style={{
            color: "blue",
            cursor: "pointer",
            marginLeft: 5,
            marginRight: 5,
          }}
        >
          hello@dorpee.com
        </a>
        <span> and we will work to find you a space or </span>
        <span>
          <Link
            style={{
              color: "blue",
              cursor: "pointer",
              marginLeft: 5,
              marginRight: 5,
            }}
            to="/"
          >
            click here
          </Link>
        </span>
        to try again.
      </p>
      {/* </> */}
    </div>
  );
};

export default SearchComponent;
