export async function createFile(url: string, name: string, type: string) {
  let hostUrl: string = process.env.REACT_APP_HOST || "";
  let apiUrl: string = process.env.REACT_APP_API_HOST || "";
  // url = url.replace(hostUrl, "");
  let res = url.replace(hostUrl, "");

  // TODO, CORS workaround, Access-Control-Allow-Origin: *
  let response = await fetch(`${apiUrl}get-images?url=${res}`, {
    mode: "cors",
  });
  let data = await response.blob();
  return new File([data], name, { type });
}
