/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from "react";
import { getBookingEffect } from "../../Screens/Book/Booking/Actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

import Confirmation from "../../Screens/Dashboard/modals/Confirmation";
import WifiDetailsComponent from "../../Screens/Dashboard/modals/WifiDetailsModal";

import { getDashboardDataEffect } from "../../Screens/Dashboard/Actions";
import { getProfileEffect } from "../../Screens/Auth/Actions";
import LoaderModal from "../../Screens/Dashboard/modals/Loader";

import ExtendBookingModal from "../Dashboard/modals/ExtendBookingModal";
import BookingCard from "./BookingCard";
import { AppState } from "../../store";

interface props {
  handlEditValdiation: (value: any) => void;
  openConfirmationBox: (
    value: any,
    booking: any,
    flag: boolean
  ) => void;
  handleValdiation: (value: any) => void;
  hideModal: () => void;
  handleCancelBooking: (value: any) => void;
  handlCheckinValdiation: () => void;
  isConfimrationBoxOpen: boolean;
  message: string;
  header: string;
  myBookings: any[];
  id: any;
  showExtendBookingModal: any;
  showButtons: any;
}
const CustomerBookings: FC<props> = ({
  handlEditValdiation,
  openConfirmationBox,
  handleValdiation,
  isConfimrationBoxOpen,
  hideModal,
  handleCancelBooking,
  handlCheckinValdiation,
  myBookings,
  message,
  header,
  id,
  showExtendBookingModal,
  showButtons
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getBookingEffect());
    dispatch(getProfileEffect());
    dispatch(getDashboardDataEffect());
    window.scrollTo(0, 0);
  }, []);
  const [showModal, setshowModal] = useState(false);
  const [modalData, setModalData] = useState({
    wifi_name: "",
    wifi_password: ""
  });

  const [activeBookings, setActiveBookings] = useState<any>([]);
  const [pastBookings, setPastBookings] = useState<any>([]);
  const [cancelledBookings, setCancelledBookings] = useState<any>([]);
  const [bookingDetails, setBookingDetails] = useState<any>();
  const [activeTab, setActiveTab] = useState<any>("Upcoming");

  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  useEffect(() => {
    var past: any = [];
    var active: any = [];
    var cancelled: any = [];
    if (myBookings && myBookings.length > 0) {
      for (var i = 0; i < myBookings.length; i++) {
        let end_date = new Date(myBookings[i].end_date);
        let time = myBookings[i].end_time.split(":");
        end_date.setHours(time[0], time[1], time[2]);

        let booking_time = end_date.getTime() > new Date().getTime();
        if (
          myBookings[i].booking_status == "active" &&
          (myBookings[i].status == "Scheduled" ||
            myBookings[i].status == "Checked-In") &&
          booking_time
        ) {
          active = active.concat(myBookings[i]);
        }
        if (
          (myBookings[i].booking_status == "past" &&
            (myBookings[i].status != "Cancelled" ||
              myBookings[i].status == "Checked-Out")) ||
          (myBookings[i].booking_status == "active" &&
            myBookings[i].status == "Checked-Out")
        ) {
          past = past.concat(myBookings[i]);
        }
        if (myBookings[i].status == "Cancelled") {
          cancelled = cancelled.concat(myBookings[i]);
        }
      }
    }

    past.sort((a: any, b: any) => {
      let adate: any = new Date(a.start_date);
      let atime = a.start_time.split(":");
      let bdate: any = new Date(b.start_date);
      let btime = b.start_time.split(":");

      adate.setHours(atime[0], atime[1], atime[2]);
      bdate.setHours(btime[0], btime[1], btime[2]);
      return bdate - adate;
    });
    setActiveBookings(active);
    setPastBookings(past);
    setCancelledBookings(cancelled);
  }, [myBookings]);
  return (
    <>
      <div className="container pd-0">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Bookings</title>
        </Helmet>
        <div className="approval__header-title">
          <div
            className="dashboard__title"
            style={{ marginTop: "3rem" }}
          >
            My Bookings{" "}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bookings-wrap">
          <>
            <div
              className="add-employer__heading invitation-tab-heading"
              style={{ justifyContent: "start" }}
            >
              <div
                className={
                  "dashboard__title tab-title " +
                  (activeTab === "Upcoming" ? "active" : "")
                }
                onClick={() => setActiveTab("Upcoming")}
                style={{
                  color:
                    activeTab === "Upcoming" ? "black" : "#b9b9b9",
                  fontWeight: 600,
                  fontSize: "20px"
                }}
              >
                Upcoming
              </div>
              <div
                className={
                  "dashboard__title tab-title " +
                  (activeTab === "Past" ? "active" : "")
                }
                onClick={() => setActiveTab("Past")}
                style={{
                  color: activeTab === "Past" ? "black" : "#b9b9b9",
                  fontWeight: 600,
                  fontSize: "20px"
                }}
              >
                Past
              </div>
              <div
                className={
                  "dashboard__title tab-title " +
                  (activeTab === "Cancelled" ? "active" : "")
                }
                onClick={() => setActiveTab("Cancelled")}
                style={{
                  color:
                    activeTab === "Cancelled" ? "black" : "#b9b9b9",
                  fontWeight: 600,
                  fontSize: "20px"
                }}
              >
                Cancelled
              </div>
            </div>

            {activeBookings &&
            activeBookings.length > 0 &&
            activeTab === "Upcoming" ? (
              <>
                {activeBookings.map((booking: any, index: any) => (
                  <BookingCard
                    booking={booking}
                    key={index}
                    handlCheckinValdiation={handlCheckinValdiation}
                    setModalData={setModalData}
                    setshowModal={setshowModal}
                    handlEditValdiation={handlEditValdiation}
                    openConfirmationBox={openConfirmationBox}
                    setBookingDetails={setBookingDetails}
                  />
                ))}
              </>
            ) : activeTab === "Upcoming" ? (
              <p style={{ marginTop: 15, fontSize: 17 }}>
                No Upcoming Bookings
              </p>
            ) : null}

            {pastBookings &&
            pastBookings.length > 0 &&
            activeTab === "Past" ? (
              <>
                {pastBookings.map((booking: any, index: any) => (
                  <BookingCard booking={booking} key={index} />
                ))}
              </>
            ) : activeTab === "Past" ? (
              <p style={{ marginTop: 15, fontSize: 17 }}>
                No Past Bookings
              </p>
            ) : null}

            {cancelledBookings &&
            cancelledBookings.length > 0 &&
            activeTab === "Cancelled" ? (
              <>
                {cancelledBookings.map((booking: any, index: any) => (
                  <BookingCard booking={booking} key={index} />
                ))}
              </>
            ) : activeTab === "Cancelled" ? (
              <p style={{ marginTop: 15, fontSize: 17 }}>
                No Cancelled Bookings
              </p>
            ) : null}
          </>
        </div>
      </div>

      {isConfimrationBoxOpen ? (
        <Confirmation
          message={message}
          decline={hideModal}
          accept={handleCancelBooking}
          id={id}
          bookinDetails={bookingDetails ? bookingDetails : null}
          acceptText={"Yes"}
          declineText={"No"}
          modalHeader={header}
          showButtons={showButtons}
        ></Confirmation>
      ) : null}
      {showExtendBookingModal ? (
        <ExtendBookingModal
          message={message}
          decline={hideModal}
          id={id}
          bookinDetails={bookingDetails ? bookingDetails : null}
          acceptText={"Yes"}
          declineText={"No"}
          modalHeader={header}
        />
      ) : null}
      {showModal ? (
        <WifiDetailsComponent
          handleToggleModal={setshowModal}
          openModal={showModal}
          wifiName={modalData.wifi_name}
          wifiPassword={modalData.wifi_password}
        ></WifiDetailsComponent>
      ) : null}
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};

export default CustomerBookings;
