import { Reducer } from "react";
import { AuthState } from "../../store";
import { AuthAction } from "./Actions";

const initialState: AuthState = {
  isSuspended: false,
  userTransaction: [],
  invitedList: [],
  inviteLoading: false,
  isLoggedIn: false
};

export const authReducer: Reducer<AuthState, AuthAction> = (
  state: AuthState = initialState,
  action
) => {
  switch (action.type) {
    case "@@auth/SIGN_IN":
      return {
        ...state,
        token: action.token,
        user: action.user,
        loginError: undefined
      };
    case "@@auth/SIGN_OUT":
      return initialState;
    case "@@auth/UPDATE_USER":
      return {
        ...state,
        user: state.user
          ? Object.assign(state.user, action.user)
          : action.user
      };
    case "@@auth/USER_ROLE":
      return { ...state, userRoles: action.user };
    case "@@auth/TRANSACTIONS_USER":
      return { ...state, userTransaction: action.user };
    case "@@auth/IS_SUSPENDED":
      return { ...state, isSuspended: action.field };
    case "@@auth/SELECT_TYPE":
      return { ...state, selectedType: action.field };
    case `@@auth/INVITED_LIST`:
      return { ...state, invitedList: action.data };
    case `@@auth/INVITE_LOADING`:
      return { ...state, inviteLoading: action.check };
    case "@@auth/IS_LOGGEDIN":
      return { ...state, isLoggedIn: action.field };
    default:
      return state;
  }
};
