import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import useForm from "../../hooks/useForm";
import { AppState } from "../../store";
import { postAccountDetailsEffect } from "../../Screens/Auth/Actions";
import { postVenueAccountDetails } from "../../Screens/VenueWizard/Actions";
import { getVenuesEffect } from "../WorkspaceWizard/Actions";
export type AccountDetailFields = {
  bsb: Number;
  accountName: string;
  accountNumber: Number;
  abn: Number;
  company_legal_name: string;
  sameAsCompany?: boolean;
  venueId?: any;
};

const AccountDetail = () => {
  let data = {
    bsb: "",
    accountName: "",
    accountNumber: "",
    abn: "",
    company_legal_name: "",
    sameAsCompany: false,
    venue_id: ""
  };
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { form, setForm, handleChange, resetForm } = useForm(data);
  const accountDetail = useSelector(
    (state: AppState) => state.auth.user
  );
  const venues = useSelector(
    (state: AppState) => state.venueWizard.venues
  );
  const [isSameSelected, setIsSameSelected] = useState(false);
  const [isVenueSelected, setIsVenueSelected] = useState(false);
  const [selectedType, setSelectedType] = useState("null");
  const [errors, setErrors] = useState({
    bsbError: "",
    accountNameError: "",
    accountNumberError: "",
    abnError: "",
    company_legal_nameError: ""
  });
  useEffect(() => {
    setHookForm();
  }, [accountDetail]);
  useEffect(() => {
    dispatch(getVenuesEffect());
  }, []);

  const setHookForm = () => {
    if (accountDetail) {
      setForm({
        bsb: accountDetail.bsb,
        accountName: accountDetail.account_name,
        accountNumber: accountDetail.account_number,
        company_legal_name: accountDetail.company_legal_name,
        abn: accountDetail.abn
      });
    }
  };
  const handleType = (e: any) => {
    let value = e.target.value;
    if (venues && venues.length > 1) {
      if (value != "null") {
        setIsVenueSelected(true);
        setSelectedType(value);
        if (!setVenueDetails(value)) {
          resetForm();
        }
      } else {
        setIsVenueSelected(false);
        setHookForm();
      }
    }
  };
  const setVenueDetails = (value: any) => {
    if (venues && value != "null") {
      let data: any = venues.find(v => v.id === parseInt(value));
      if (data) {
        if (
          data.bsb != null ||
          data.company_name != null ||
          data.abn !== null ||
          data.account_number != null ||
          data.account_name != null
        ) {
          setForm({
            bsb: data.bsb,
            accountName: data.account_name,
            accountNumber: data.account_number,
            company_legal_name: data.company_name,
            abn: data.abn,
            sameAsCompany: data.sameAs
          });
          return true;
        }
        return false;
      }
    }
  };
  const hasFormError = () => {
    let error = false;
    let check: any = { ...errors };
    if (form.bsb == "") {
      error = true;
      check.bsbError = "Required";
      setErrors(check);
    }
    if (form.accountNumber == "") {
      error = true;
      check.accountNumberError = "Required";
    }
    if (form.company_legal_name == "") {
      check.company_legal_nameError = "Required";
      error = true;
    }
    if (form.abn == "") {
      error = true;
      check.abnError = "Required";
    }
    if (form.accountName == "") {
      error = true;
      check.accountNameError = "Required";
    }
    setErrors(check);
    return error;
  };
  const handleTitleChange = (event: any) => {
    let error: any = { ...errors };
    error[event.target.name + "Error"] = "";
    setErrors(error);
    handleChange(event);
  };
  const handleSwitch = (e: any) => {
    setIsSameSelected(e);
    if (e) {
      setHookForm();
    } else {
      resetForm();
      setVenueDetails(selectedType);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!hasFormError()) {
      const isEdit =
        accountDetail &&
        accountDetail.account_number &&
        accountDetail.bsb
          ? true
          : false;
      let req: any = {
        bsb: form.bsb,
        account_name: form.accountName,
        account_number: form.accountNumber,
        abn: form.abn
      };
      if (isVenueSelected) {
        req["sameAsCompany"] = isSameSelected;
        req["venue_id"] = selectedType;
        req["company_name"] = form.company_legal_name;
        dispatch(
          postVenueAccountDetails(req, isEdit, state, history)
        );
      } else {
        req["company_legal_name"] = form.company_legal_name;
        dispatch(
          postAccountDetailsEffect(req, isEdit, state, history)
        );
      }
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee Payments</title>
      </Helmet>
      <form>
        <div className="wrapper wrapper-login">
          <div className="wizard account-wizard">
            <div className="wizard__title">Payment Details</div>

            <div className="input-container">
              <select
                style={{
                  padding: 5,
                  background:
                    "url(/img/triangle-arrow.png) no-repeat right"
                }}
                onChange={e => handleType(e)}
              >
                <option value="null">Company Account</option>

                {venues &&
                  venues.length > 1 &&
                  venues.map(v => (
                    <option value={v.id}>{v.name}</option>
                  ))}
              </select>
            </div>
            <div className="validation-error"></div>

            {isVenueSelected ? (
              <div
                className="ui toggle checkbox"
                style={{ marginTop: 20, marginBottom: 15 }}
              >
                <label className="switch">
                  <input
                    defaultChecked={false}
                    type="checkbox"
                    checked={isSameSelected}
                    onChange={e => {
                      handleSwitch(!isSameSelected);
                    }}
                    required
                  />
                  <span className="slider round"></span>
                </label>
                <div className="validation-error"></div>
                <span>Same as company account</span>
              </div>
            ) : null}
            {!isVenueSelected || !isSameSelected ? (
              <>
                <label htmlFor="">Company Legal Name</label>
                <div className="input-container">
                  <input
                    type="text"
                    name="company_legal_name"
                    placeholder="Company Legal Name"
                    value={form.company_legal_name}
                    defaultValue={form.company_legal_name}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="validation-error">
                  {errors.company_legal_nameError}
                </div>
                <label htmlFor="">ABN Number</label>

                <div className="input-container">
                  <input
                    type="text"
                    name="abn"
                    placeholder="ABN Number"
                    value={form.abn}
                    defaultValue={form.abn}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="validation-error">
                  {errors.abnError}
                </div>
                <label htmlFor="">Bank Account Name</label>
                <div className="input-container">
                  <input
                    name="accountName"
                    type="text"
                    placeholder="Account Name"
                    value={form.accountName}
                    defaultValue={form.accountName}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="validation-error">
                  {errors.accountNameError}
                </div>
                <label htmlFor="">BSB Number</label>
                <div className="input-container">
                  <input
                    name="bsb"
                    className={"noButtons"}
                    type="number"
                    placeholder="BSB Number"
                    value={form.bsb}
                    defaultValue={form.bsb}
                    onChange={handleTitleChange}
                  />
                </div>
                <div className="validation-error">
                  {errors.bsbError}
                </div>
                <label htmlFor="">Account Number</label>
                <div className="input-container">
                  <input
                    className={"noButtons"}
                    name="accountNumber"
                    type="number"
                    placeholder="Account Number"
                    onChange={handleTitleChange}
                    value={form.accountNumber}
                    defaultValue={form.accountNumber}
                  />
                </div>
                <div className="validation-error">
                  {errors.accountNumberError}
                </div>
              </>
            ) : null}
            <br />
            {form &&
            (form.bsb == "" ||
              form.account_number == "" ||
              form.account_name == "") ? (
              <button
                className="btn btn-pink wizard__btn mb-0"
                onClick={e => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-pink wizard__btn mb-0"
                onClick={e => {
                  handleSubmit(e);
                }}
              >
                Update
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountDetail;
