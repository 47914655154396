import React, { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { WrappedFieldProps } from "redux-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { profileImagesDelete } from "../../Screens/SignUp/Actions";

import "./UploadFieldVenueSpace.sass";

interface OwnProps {
  id?: string;
}

type Props = OwnProps & WrappedFieldProps;

const UploadFieldVenueSpace: FC<Props> = props => {
  const [previewUrl, setPreviewUrl] = useState<
    string | ArrayBuffer | null
  >(null);

  useEffect(() => {
    if (props.input.value) {
      let reader = new FileReader();
      let file = props.input.value;

      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  }, [props.input]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    let size = file && file.size;
    size = size && Math.round(size / 1024);
    if (!file!.name.match(/\.(jpg|jpeg|png)$/)) {
      toast.error(
        "Please upload photos in jpg, jpeg and png format."
      );
    } else if (size && size > 10048) {
      toast.error("Images must be below 10MB in size");
    } else {
      props.input.onChange(file);
    }
  };
  const dispatch = useDispatch();
  const handleClick = (e: any) => {
    e.preventDefault();
    setPreviewUrl(null);
    props.input.onChange(null);
    dispatch(
      profileImagesDelete({
        logo_deleted: 0,
        photo_deleted: 1,
        vaccination_certificate_deleted: 0
      })
    );
  };

  return (
    <>
      <label className="upload-field-label">
        <input
          id={props.id}
          className="upload-field-input"
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          onChange={onChange}
        />
        {previewUrl ? (
          <>
            <img
              className="upload-field-preview"
              src={previewUrl as string}
              alt=""
            />
            <div
              className="close"
              onClick={(e: any) => handleClick(e)}
            >
              <CloseIcon
                style={{
                  color: "red",
                  width: "1rem",
                  height: "1rem"
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </label>
    </>
  );
};

export default UploadFieldVenueSpace;
