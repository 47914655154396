/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from "react";
import { Keyboard, Person } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";

import { AppState } from "./store";
import Footer from "../src/footer";
import { getVenuesEffect } from "./Screens/VenueWizard/Actions";
import {
  signOutEffect,
  getProfileEffect,
  changeUserRole
} from "./Screens/Auth/Actions";
import useForm from "../src/hooks/useForm";
import { formatedTime, formatDate } from "./helpers/DateHelpers";
import MenuLinks from "./UI/MenuLinks";
import CityPicker from "./UI/LocationPicker/CityPicker";
import {
  saveBasicSearch,
  submitVenueSearchEffect
} from "./Screens/VenueSearch/Actions";
import {
  GetTotalSpaces,
  getVenueTypesEffect
} from "./Screens/WorkspaceWizard/Actions";
import SearchAdvancedNew from "./Screens/SearchAdvancedNew";
import { Address } from "./UI/LocationPicker/LocationPicker";

import "./sass/modules/header/header.sass";
import "../src/Screens/style.css";

type Props = {
  children: React.ReactElement;
  hideFooter?: boolean;
  isSubs?: boolean;
};

export type SearchFields = {
  location: Address;
  date: any;
  start_time: any;
  end_time: any;
  no_of_people: string;
  keyword: string;
};

const MenuLayout: FC<Props> = ({ children, hideFooter, isSubs }) => {
  let data = {
    location: "",
    date: "",
    start_time: "",
    end_time: "",
    no_of_people: "",
    keyword: ""
  };
  const dispatch = useDispatch();
  const history: any = useHistory();

  const { form, setForm, handleChange }: any = useForm(data);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isShowed, setIsShowed] = useState(false);
  const [isProfileMenuOpened, setIsProfileMenuOpened] =
    useState(false);
  const [filteredVenues, setfilteredVenues] = useState(0);
  const [topmargin, settopmargin] = useState(0);
  const [searchError, setSearchError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [errors, setErrors] = useState({
    cityError: "",
    dateError: "",
    start_timeError: "",
    end_timeError: "",
    no_of_peopleError: ""
  });
  // const [range, setRange]: any = useState();

  const user = useSelector((state: AppState) => state.auth.user);
  const role = useSelector((state: AppState) => state.auth.userRoles);
  const venues = useSelector(
    (state: AppState) => state.venueWizard.venues
  );

  const isLoadingProfile = useSelector(
    (state: AppState) => state.auth.token && !state.auth.user
  );
  const isAuthenticated = !!useSelector(
    (state: AppState) => state.auth.user
  );
  const tokenData = useSelector(
    (state: AppState) => state.dashboard.dashboardToken
  );
  const searchDetails = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );
  const inviteList = useSelector(
    (state: AppState) => state.dashboard.customerInvitation
  );
  // const venuesresults = useSelector(
  //   (state: AppState) => state.venueSearch.venues
  // );
  // const totalSpaces = useSelector(
  //   (state: AppState) => state.workspaceWizard.totalSpaces
  // );

  const closeBtn = useRef<HTMLDivElement>(null);
  const closeBtnMobile = useRef<HTMLDivElement>(null);
  const profileMenu = useRef<HTMLDivElement>(null);
  const profileBtn = useRef<HTMLDivElement>(null);

  const isVenueUser = user && role === "Venue" ? true : false;
  const isEmpUser = user && role === "Employer" ? true : false;

  useEffect(() => {
    dispatch(getVenuesEffect());
    dispatch(getProfileEffect());
    dispatch(GetTotalSpaces());
    dispatch(getVenueTypesEffect());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tokenData) {
      dispatch(getVenuesEffect(tokenData));
    }
  }, [tokenData]);

  useEffect(() => {
    if (searchDetails) {
      searchDetails &&
        Object.keys(searchDetails).length > 0 &&
        setForm((form: any) => ({
          ...form,
          location: searchDetails.location,
          start_time: searchDetails.start_time,
          end_time: searchDetails.end_time,
          date: searchDetails.date,
          no_of_people: searchDetails.capacity,
          keyword: searchDetails.keyword,
          only_ohs: searchDetails.only_ohs === "true" ? true : false
        }));
    }
  }, [searchDetails]);

  useEffect(() => {
    dispatch(getProfileEffect());
  }, [user]);

  useEffect(() => {
    isMenuOpened
      ? document.body.classList.add("disable")
      : document.body.classList.remove("disable");

    const reset = (e: MouseEvent) => {
      if (closeBtn.current) {
        if (
          !closeBtn.current!.contains(e.target as HTMLElement) ||
          !closeBtnMobile.current!.contains(e.target as HTMLElement)
        ) {
          setIsMenuOpened(false);
          setIsShowed(false);
        }
      }
    };

    document.body.addEventListener("click", reset);
    return () => {
      document.body.removeEventListener("click", reset);
    };
  }, [isMenuOpened]);

  useEffect(() => {
    settopmargin(user ? -30 : -25);
  }, [isAuthenticated]);

  useEffect(() => {
    const reset = (e: MouseEvent) => {
      if (
        (e.target !== profileBtn.current &&
          e.target !== profileMenu.current) ||
        !profileMenu.current ||
        !profileMenu.current.contains(e.target as HTMLDivElement)
      ) {
        setIsProfileMenuOpened(false);
      }
    };

    return () => {
      document.body.removeEventListener("click", reset);
    };
  }, [isProfileMenuOpened]);

  const toggleProfileMenu = (
    e: React.MouseEvent<HTMLDivElement, {}>
  ) => {
    if (e.target !== profileBtn.current) {
      setIsProfileMenuOpened(!isProfileMenuOpened);
    }
    setIsProfileMenuOpened(!isProfileMenuOpened);
  };

  useEffect(() => {
    venues &&
      setfilteredVenues(
        venues.filter(v => v.assessment == null).length
      );
  }, [venues]);

  const toggleProfileMenu2 = (
    e: React.MouseEvent<HTMLDivElement, {}>
  ) => {
    setIsProfileMenuOpened(!isProfileMenuOpened);
  };

  const handleFormChange = (event: any) => {
    let error: any = { ...errors };
    error[event.target.name + "Error"] = "";
    setErrors(error);
    dispatch(
      saveBasicSearch({
        [event.target.name]: Number(event.target.value)
      })
    );
    handleChange(event);
  };

  const handleKeyWordChange = (event: any) => {
    dispatch(
      saveBasicSearch({ [event.target.name]: event.target.value })
    );
    handleChange(event);
  };

  const handleLocationChange = async (address: any) => {
    let newLocation = {
      postalCode: address.postalCode,
      suburb: address.suburb,
      lat: address.lat,
      lng: address.lng
    };
    setForm((form: any) => ({
      ...form,
      location: newLocation
    }));

    var datatobesaved = {
      suburb: address.suburb,
      postalCode: address.postalCode,
      lat: address.lat,
      lng: address.lng
    };
    dispatch(saveBasicSearch({ location: datatobesaved }));
  };

  const handleDateChange = (date: any) => {
    setForm((form: any) => ({
      ...form,
      date: date
    }));
  };

  const handleTimeChange = (time: any, key: string) => {
    if (key == "start_time") {
      setForm((form: any) => ({
        ...form,
        start_time: time
      }));
    } else {
      setTimeError("");
      setForm((form: any) => ({
        ...form,
        end_time: time
      }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    window.localStorage.setItem("searchType", "basic");

    if (
      form.start_time &&
      form.end_time &&
      form.end_time._d.getTime() < form.start_time._d.getTime()
    ) {
      setTimeError("Must be greater than start time");
    } else {
      dispatch(
        submitVenueSearchEffect(
          form.location ? form.location.suburb || "" : "",
          form.location ? form.location.postalCode || "" : "",
          form.location ? form.location.lat || "" : "",
          form.location ? form.location.lng || "" : "",
          form.keyword,
          form.no_of_people,
          form.types,
          form.only_ohs ? "yes" : "no",
          history,
          form.date != "" &&
            form.date != null &&
            form.date != undefined
            ? formatDate(form.date)
            : "",
          form.start_time != "" &&
            form.start_time != null &&
            form.start_time != undefined
            ? formatedTime(form.start_time._d)
            : "",
          form.end_time != "" &&
            form.end_time != null &&
            form.end_time != undefined
            ? formatedTime(form.end_time._d)
            : ""
        )
      );

      var datatobesaved2 = {
        location: form.location,
        keyword: form.keyword,
        capacity: form.no_of_people,
        date: form.date,
        start_time: form.start_time,
        end_time: form.end_time
      };
      dispatch(saveBasicSearch(datatobesaved2));
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const isCustomer = () => {
    const isExist = user?.role.filter(
      ({ title }) => title === "Employer" || title === "Venue"
    );
    return isExist && isExist.length > 0;
  };

  return (
    <>
      <header
        className="page-header clearfix"
        style={{ maxHeight: "fit-content", minHeight: "60px" }}
      >
        <div
          className={`md-header-desktop container-fluid clearfix d-none d-lg-block${
            isMenuOpened ? " active" : ""
          }`}
        >
          <div className="container clearfix ">
            <div className=" page-header__top">
              <a href="/" className="page-header__logo">
                <div className="logo d-flex align-items-center">
                  <img src="/img/imgs/logo.png" alt="home.html" />
                  <div style={{ marginLeft: "5px" }}>
                    <p style={{ margin: 0 }}>WORKSPACES</p>
                    <p>ON DEMAND</p>
                  </div>
                </div>
              </a>

              <div className="main-nav-desktop">
                {isAuthenticated || isLoadingProfile ? (
                  <div className="nav__profile">
                    <div className="desktop-menu-in">
                      <ul className="clearfix">
                        <li className="current-menu-item1">
                          <a href="/workspaces">Explore Workspaces</a>
                        </li>
                      </ul>
                    </div>
                    {window.location.pathname.includes(
                      "employer/dashboard"
                    ) ? null : (
                      <div
                        ref={profileBtn}
                        onClick={toggleProfileMenu}
                        className="nav__profile-wrapper"
                      >
                        {user && user.avatar ? (
                          <img
                            className="nav__profile-avatar"
                            src={user.avatar}
                            alt={user.first_name}
                          />
                        ) : (
                          <img
                            className="nav__profile-avatar"
                            src={"/img/avatar.png"}
                            alt="Profile Image"
                          />
                        )}

                        {isProfileMenuOpened ? (
                          <div
                            ref={profileMenu}
                            className="nav__profile-menu-box"
                          >
                            {role && role != "Customer" ? (
                              <p
                                style={{
                                  marginBottom: "10px",
                                  color: "#31B5CE"
                                }}
                              >
                                Switch Role
                              </p>
                            ) : null}
                            {user &&
                              user.role.some(
                                r =>
                                  r.title === "Venue" ||
                                  r.title === "Employer"
                              ) && (
                                <div className="profile-menu__venue">
                                  <div className="profile-menu__item">
                                    <span
                                      style={{
                                        width: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginRight: "10px"
                                      }}
                                    >
                                      <img
                                        style={{ maxWidth: "16px" }}
                                        src="/img/icons/customer.svg"
                                        alt=""
                                      />
                                    </span>{" "}
                                    <div
                                      className="profile-menu__info"
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: 0
                                      }}
                                      onClick={e => {
                                        dispatch(
                                          changeUserRole(
                                            history,
                                            "Customer"
                                          )
                                        );
                                      }}
                                    >
                                      <div className="profile-menu__type">
                                        Individual Dashboard
                                      </div>
                                    </div>
                                  </div>
                                  <div className="profile-menu__item">
                                    <span
                                      style={{
                                        width: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginRight: "10px"
                                      }}
                                    >
                                      <div
                                        className="dashboard-icon"
                                        style={{ width: "20px" }}
                                      ></div>
                                    </span>

                                    <div
                                      className="profile-menu__info"
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: 0
                                      }}
                                      onClick={e => {
                                        let setRole = user.role.some(
                                          r => r.title === "Venue"
                                        )
                                          ? "Venue"
                                          : "Employer";
                                        dispatch(
                                          changeUserRole(
                                            history,
                                            setRole
                                          )
                                        );
                                      }}
                                    >
                                      <div className="profile-menu__type">
                                        {user.role.some(
                                          r => r.title === "Venue"
                                        )
                                          ? "Venue Dashboard"
                                          : "Employer Dashboard"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            <ul className="profile-menu__links">
                              <li
                                style={{
                                  display: `${
                                    isCustomer() ? "none" : "block"
                                  }`
                                }}
                              >
                                <a
                                  onClick={() => {
                                    history.push("/dashboard");
                                    setIsProfileMenuOpened(false);
                                  }}
                                >
                                  <span>
                                    <img
                                      src="/img/icons/customer.svg"
                                      alt=""
                                    />
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column"
                                    }}
                                  >
                                    <div className="profile-menu__type">
                                      Individual Dashboard
                                    </div>
                                  </div>
                                </a>
                              </li>

                              <li>
                                <a
                                  onClick={() => {
                                    dispatch(signOutEffect(history));
                                  }}
                                >
                                  <span>
                                    <img
                                      className="logout"
                                      src="/img/icons/logout.svg"
                                      alt=""
                                    />
                                  </span>
                                  Logout
                                </a>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <nav
                      className={`page-header__navigation ${
                        isShowed ? "is-showed" : ""
                      }`}
                    >
                      <ul>
                        <li className="header_links">
                          <Link
                            to="/register/customer"
                            onClick={() => {
                              setIsShowed(false);
                              document.body.style.overflow = "auto";
                            }}
                          >
                            FOR INDIVIDUALS
                          </Link>
                        </li>
                        <li className="header_links">
                          <Link
                            to="/register/employer"
                            onClick={() => {
                              setIsShowed(false);
                              document.body.style.overflow = "auto";
                            }}
                          >
                            FOR TEAMS
                          </Link>
                        </li>
                        <li className="header_links">
                          <Link
                            to="/venue-register"
                            onClick={() => {
                              setIsShowed(false);
                              document.body.style.overflow = "auto";
                            }}
                          >
                            FOR VENUES
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/login"
                            onClick={() => {
                              setIsShowed(false);
                              document.body.style.overflow = "auto";
                            }}
                          >
                            <Person />
                          </Link>
                        </li>
                        <li
                          className="btn btn_header_free header_links"
                          style={{
                            borderRadius: "none"
                          }}
                        >
                          <Link
                            to={
                              !user
                                ? "/register/select-type"
                                : "select-plan"
                            }
                            onClick={() => {
                              setIsShowed(false);
                              document.body.style.overflow = "auto";
                            }}
                            style={{
                              color: "#fff"
                            }}
                          >
                            TRY IT FOR FREE
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    <div className="menu-buttons">
                      <div className="page-header__search-btn js-open-search">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 19.881 19.881"
                        >
                          <g
                            id="Icon_feather-search"
                            data-name="Icon feather-search"
                            transform="translate(1 1)"
                          >
                            <path
                              id="Path_9245"
                              data-name="Path 9245"
                              d="M18.248,11.374A6.874,6.874,0,1,1,11.374,4.5,6.874,6.874,0,0,1,18.248,11.374Z"
                              transform="translate(-4.5 -4.5)"
                              fill="none"
                              stroke="#F5597F"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              id="Path_9246"
                              data-name="Path 9246"
                              d="M28.713,28.713l-3.738-3.738"
                              transform="translate(-13.246 -13.246)"
                              fill="none"
                              stroke="#F5597F"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </g>
                        </svg>
                      </div>
                      <div
                        className="page-header__menu-btn js-open-menu"
                        onClick={() => {
                          setIsShowed(!isShowed);
                          !isShowed
                            ? (document.body.style.overflow =
                                "hidden")
                            : (document.body.style.overflow = "auto");
                        }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              {window.location.pathname == "/" ||
              window.location.pathname.includes("workspaces") ||
              window.location.pathname.includes("search-results") ? (
                <div
                  className="page-header__bottom"
                  style={{ position: "relative" }}
                >
                  <div
                    className="search"
                    style={{ height: searchError ? "64px" : "auto" }}
                  >
                    <form
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <div className="search__input input-w1">
                        <CityPicker
                          isAutoFocus
                          input={{
                            ...form,
                            onBlur: () => {},
                            onkeyDown: (e: any) => {
                              handleSubmit(e);
                            }
                          }}
                          value={form.location}
                          home={true}
                          onChange={(data: any) => {
                            handleLocationChange(data);
                          }}
                        />
                      </div>
                      <div className="search__input input-w5">
                        <Keyboard style={{ color: "#9ba6ac" }} />
                        <input
                          type="text"
                          name="keyword"
                          placeholder="Keyword"
                          value={form.keyword}
                          onChange={handleKeyWordChange}
                        />
                      </div>
                      <div className="search__input input-w2">
                        {form.date === "" || form.date == null ? (
                          <svg
                            className="icon--date"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.278"
                            height="15.175"
                            viewBox="0 0 13.278 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_awesome-calendar"
                              data-name="Icon awesome-calendar"
                              d="M.356,5.691H12.923a.357.357,0,0,1,.356.356v7.706a1.423,1.423,0,0,1-1.423,1.423H1.423A1.423,1.423,0,0,1,0,13.753V6.046A.357.357,0,0,1,.356,5.691Zm12.923-1.3V3.32A1.423,1.423,0,0,0,11.856,1.9H10.433V.356A.357.357,0,0,0,10.077,0H8.892a.357.357,0,0,0-.356.356V1.9H4.742V.356A.357.357,0,0,0,4.387,0H3.2a.357.357,0,0,0-.356.356V1.9H1.423A1.423,1.423,0,0,0,0,3.32V4.387a.357.357,0,0,0,.356.356H12.923A.357.357,0,0,0,13.278,4.387Z"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        <DatePicker
                          minDate={new Date()}
                          placeholderText={"Date"}
                          isClearable
                          clearButtonTitle="clear"
                          dateFormat="dd-MM-yyyy"
                          selected={form.date ? form.date : null}
                          onChange={(date: any) => {
                            handleDateChange(date);
                          }}
                        />
                      </div>
                      <div className="search__input input-w3">
                        {form.start_time === "" ||
                        form.start_time == null ? (
                          <svg
                            className="icon--time"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.175"
                            height="15.175"
                            viewBox="0 0 15.175 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_ionic-ios-time"
                              data-name="Icon ionic-ios-time"
                              d="M10.963,3.375a7.588,7.588,0,1,0,7.588,7.588A7.586,7.586,0,0,0,10.963,3.375Zm.511,8.244a.512.512,0,0,1-.511.511h-3.5a.511.511,0,1,1,0-1.021h2.991V6.293a.511.511,0,1,1,1.021,0Z"
                              transform="translate(-3.375 -3.375)"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        {/* fullView */}
                        <TimePicker
                          className={"start_time"}
                          placeholder="Start time"
                          value={
                            form.start_time ? form.start_time : null
                          }
                          showSecond={false}
                          onChange={e => {
                            handleTimeChange(e, "start_time");
                            dispatch(
                              saveBasicSearch({ start_time: e })
                            );
                          }}
                          minuteStep={15}
                        />
                      </div>
                      <div className="search__input input-w4">
                        {form.end_time === "" ||
                        form.end_time == null ? (
                          <svg
                            className="icon--time"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.175"
                            height="15.175"
                            viewBox="0 0 15.175 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_ionic-ios-time"
                              data-name="Icon ionic-ios-time"
                              d="M10.963,3.375a7.588,7.588,0,1,0,7.588,7.588A7.586,7.586,0,0,0,10.963,3.375Zm.511,8.244a.512.512,0,0,1-.511.511h-3.5a.511.511,0,1,1,0-1.021h2.991V6.293a.511.511,0,1,1,1.021,0Z"
                              transform="translate(-3.375 -3.375)"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        <TimePicker
                          className={"end_time"}
                          placeholder="End time"
                          value={form.end_time ? form.end_time : null}
                          showSecond={false}
                          onChange={e => {
                            handleTimeChange(e, "end_time");
                            dispatch(
                              saveBasicSearch({ end_time: e })
                            );
                          }}
                          minuteStep={15}
                        />

                        <p
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                            margin: 0,
                            marginBottom: 0,
                            left: "5%",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {timeError}
                        </p>
                      </div>
                      <div className="search__input input-w5">
                        <svg
                          className="icon--users show"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23.511"
                          height="16.458"
                          viewBox="0 0 23.511 16.458"
                        >
                          <path
                            id="Icon_awesome-users"
                            data-name="Icon awesome-users"
                            d="M3.527,9.3A2.351,2.351,0,1,0,1.176,6.952,2.353,2.353,0,0,0,3.527,9.3Zm16.458,0a2.351,2.351,0,1,0-2.351-2.351A2.353,2.353,0,0,0,19.985,9.3Zm1.176,1.176H18.809a2.344,2.344,0,0,0-1.657.683,5.374,5.374,0,0,1,2.759,4.019h2.425a1.174,1.174,0,0,0,1.176-1.176V12.83A2.353,2.353,0,0,0,21.16,10.479Zm-9.4,0A4.115,4.115,0,1,0,7.641,6.365,4.112,4.112,0,0,0,11.756,10.479Zm2.821,1.176h-.3a5.681,5.681,0,0,1-5.033,0h-.3A4.233,4.233,0,0,0,4.7,15.887v1.058a1.764,1.764,0,0,0,1.763,1.763h10.58a1.764,1.764,0,0,0,1.763-1.763V15.887A4.233,4.233,0,0,0,14.577,11.655Zm-8.218-.492A2.344,2.344,0,0,0,4.7,10.479H2.351A2.353,2.353,0,0,0,0,12.83v1.176a1.174,1.174,0,0,0,1.176,1.176H3.6A5.387,5.387,0,0,1,6.359,11.162Z"
                            transform="translate(0 -2.25)"
                            fill="#9ba6ac"
                          />
                        </svg>
                        <input
                          type="number"
                          name="no_of_people"
                          min="0"
                          placeholder="No. of people"
                          value={form.no_of_people}
                          onChange={handleFormChange}
                        />
                      </div>

                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: -5,
                          margin: 0,
                          marginBottom: 0,
                          left: "5%"
                        }}
                      >
                        {searchError}
                      </p>
                      {isAdvancedSearch ? null : (
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="search__btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.881"
                            height="19.881"
                            viewBox="0 0 19.881 19.881"
                          >
                            <g
                              id="Icon_feather-search"
                              data-name="Icon feather-search"
                              transform="translate(1 1)"
                            >
                              <path
                                id="Path_9245"
                                data-name="Path 9245"
                                d="M18.248,11.374A6.874,6.874,0,1,1,11.374,4.5,6.874,6.874,0,0,1,18.248,11.374Z"
                                transform="translate(-4.5 -4.5)"
                                fill="none"
                                stroke="#FF567A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_9246"
                                data-name="Path 9246"
                                d="M28.713,28.713l-3.738-3.738"
                                transform="translate(-13.246 -13.246)"
                                fill="none"
                                stroke="#FF567A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </button>
                      )}
                      <div
                        className="search__advanced"
                        onClick={() => {
                          window.localStorage.setItem(
                            "searchType",
                            isAdvancedSearch ? "basic" : "adv"
                          );
                          setIsAdvancedSearch(!isAdvancedSearch);
                        }}
                      >
                        Advanced Search
                      </div>
                      {window.localStorage.getItem("searchType") ==
                        "adv" && isAdvancedSearch ? (
                        <div className="advanced_div">
                          <SearchAdvancedNew
                            history={history}
                            scrollTop={scrollTop}
                            setIsAdvancedSearch={setIsAdvancedSearch}
                            location={form.location}
                            start_time={form.start_time}
                            end_time={form.end_time}
                            date={form.date}
                            capacity={form.no_of_people}
                            setSearchError={setSearchError}
                            setTimeError={setTimeError}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {role === "Venue" ? (
            <div className="side-nav-desktop">
              <MenuLinks
                user={user}
                isVenueUser={isVenueUser}
                filteredVenues={filteredVenues}
                isEmpUser={isEmpUser}
                venues={venues}
              />
            </div>
          ) : null}
        </div>
        <div
          className={`md-header-mobile d-block d-lg-none${
            isMenuOpened ? " active" : ""
          }`}
        >
          <div className="top-navtigation">
            <div
              className="container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="ctn-inner clearfix"
                style={{ paddingBottom: 5 }}
              >
                <div className="logo">
                  <a href="/">
                    <img src="/img/imgs/logo-mobile.png" alt="" />
                  </a>
                </div>
                <div className="top-menu">
                  {isAuthenticated || isLoadingProfile ? (
                    window.location.pathname.includes(
                      "employer/dashboard"
                    ) ? null : (
                      <div className="mobile-menu-in">
                        <ul
                          className="clearfix"
                          style={{ display: "flex" }}
                        >
                          <li className="current-menu-item">
                            <a href="/workspaces">
                              Explore Workspaces
                            </a>
                          </li>
                        </ul>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {isAuthenticated || isLoadingProfile ? (
                    <div className="nav__profile">
                      <div
                        ref={profileBtn}
                        onClick={toggleProfileMenu2}
                        className="nav__profile-wrapper"
                      >
                        {user && user.avatar ? (
                          <img
                            className="nav__profile-avatar"
                            src={user.avatar}
                            alt={user.first_name}
                          />
                        ) : (
                          <img
                            className="nav__profile-avatar"
                            src={"/img/avatar.png"}
                            alt="Profile Image"
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mobile-menu">
                        <ul
                          className="clearfix"
                          style={{ display: "flex" }}
                        >
                          <li className="current-menu-item">
                            <a href="/workspaces">
                              Explore Workspaces
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="account">
                        <Link to="/login">Login</Link>
                      </div>
                      <div className="account">
                        <Link to="/register/select-type">
                          Get Started
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {window.location.pathname == "/" ||
              window.location.pathname.includes("workspaces") ||
              window.location.pathname.includes("search-results") ? (
                <div
                  className="page-header__bottom"
                  style={{ position: "relative" }}
                >
                  <div
                    className="search"
                    style={{ height: searchError ? "64px" : "auto" }}
                  >
                    <form
                      className="form_mobile"
                      style={{
                        display: "contents"
                      }}
                    >
                      <div className="search__input input-w1">
                        <CityPicker
                          isAutoFocus
                          input={{
                            ...form,
                            onBlur: () => {},
                            onkeyDown: (e: any) => {
                              handleSubmit(e);
                            }
                          }}
                          value={form.location}
                          home={true}
                          onChange={(data: any) => {
                            handleLocationChange(data);
                          }}
                        />
                      </div>
                      <div className="search__input input-w5">
                        <Keyboard style={{ color: "#9ba6ac" }} />
                        <input
                          type="text"
                          name="keyword"
                          placeholder="Keyword.. wi-fi, co-working, cafe, wine"
                          value={form.keyword}
                          onChange={handleKeyWordChange}
                        />
                      </div>
                      <div className="search__input input-w2">
                        {form.date === "" || form.date == null ? (
                          <svg
                            className="icon--date"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.278"
                            height="15.175"
                            viewBox="0 0 13.278 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_awesome-calendar"
                              data-name="Icon awesome-calendar"
                              d="M.356,5.691H12.923a.357.357,0,0,1,.356.356v7.706a1.423,1.423,0,0,1-1.423,1.423H1.423A1.423,1.423,0,0,1,0,13.753V6.046A.357.357,0,0,1,.356,5.691Zm12.923-1.3V3.32A1.423,1.423,0,0,0,11.856,1.9H10.433V.356A.357.357,0,0,0,10.077,0H8.892a.357.357,0,0,0-.356.356V1.9H4.742V.356A.357.357,0,0,0,4.387,0H3.2a.357.357,0,0,0-.356.356V1.9H1.423A1.423,1.423,0,0,0,0,3.32V4.387a.357.357,0,0,0,.356.356H12.923A.357.357,0,0,0,13.278,4.387Z"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        <DatePicker
                          minDate={new Date()}
                          placeholderText={"Date"}
                          isClearable
                          clearButtonTitle="clear"
                          dateFormat="dd-MM-yyyy"
                          selected={form.date ? form.date : null}
                          onChange={(date: any) => {
                            handleDateChange(date);
                          }}
                        />
                      </div>
                      <div className="search__input input-w3">
                        {form.start_time === "" ||
                        form.start_time == null ? (
                          <svg
                            className="icon--time"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.175"
                            height="15.175"
                            viewBox="0 0 15.175 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_ionic-ios-time"
                              data-name="Icon ionic-ios-time"
                              d="M10.963,3.375a7.588,7.588,0,1,0,7.588,7.588A7.586,7.586,0,0,0,10.963,3.375Zm.511,8.244a.512.512,0,0,1-.511.511h-3.5a.511.511,0,1,1,0-1.021h2.991V6.293a.511.511,0,1,1,1.021,0Z"
                              transform="translate(-3.375 -3.375)"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        <TimePicker
                          className={"start_time"}
                          placeholder="Start Time"
                          value={
                            form.start_time ? form.start_time : null
                          }
                          showSecond={false}
                          onChange={e => {
                            handleTimeChange(e, "start_time");
                            dispatch(
                              saveBasicSearch({ start_time: e })
                            );
                          }}
                          minuteStep={15}
                        />
                      </div>
                      <div className="search__input input-w4">
                        {form.end_time === "" ||
                        form.end_time == null ? (
                          <svg
                            className="icon--time"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.175"
                            height="15.175"
                            viewBox="0 0 15.175 15.175"
                            style={{ pointerEvents: "none" }}
                          >
                            <path
                              id="Icon_ionic-ios-time"
                              data-name="Icon ionic-ios-time"
                              d="M10.963,3.375a7.588,7.588,0,1,0,7.588,7.588A7.586,7.586,0,0,0,10.963,3.375Zm.511,8.244a.512.512,0,0,1-.511.511h-3.5a.511.511,0,1,1,0-1.021h2.991V6.293a.511.511,0,1,1,1.021,0Z"
                              transform="translate(-3.375 -3.375)"
                              fill="#9ba6ac"
                            />
                          </svg>
                        ) : null}
                        <TimePicker
                          className={"end_time"}
                          placeholder="End Time"
                          value={form.end_time ? form.end_time : null}
                          showSecond={false}
                          onChange={e => {
                            handleTimeChange(e, "end_time");
                            dispatch(
                              saveBasicSearch({ end_time: e })
                            );
                          }}
                          minuteStep={15}
                        />
                        <p
                          className="time_error_search"
                          style={{
                            color: "red",
                            position: "absolute",
                            bottom: "-20px",
                            margin: 0,
                            marginBottom: 0,
                            left: "5%",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {timeError}
                        </p>
                      </div>
                      <div className="search__input input-w5">
                        <svg
                          className="icon--users"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23.511"
                          height="16.458"
                          viewBox="0 0 23.511 16.458"
                        >
                          <path
                            id="Icon_awesome-users"
                            data-name="Icon awesome-users"
                            d="M3.527,9.3A2.351,2.351,0,1,0,1.176,6.952,2.353,2.353,0,0,0,3.527,9.3Zm16.458,0a2.351,2.351,0,1,0-2.351-2.351A2.353,2.353,0,0,0,19.985,9.3Zm1.176,1.176H18.809a2.344,2.344,0,0,0-1.657.683,5.374,5.374,0,0,1,2.759,4.019h2.425a1.174,1.174,0,0,0,1.176-1.176V12.83A2.353,2.353,0,0,0,21.16,10.479Zm-9.4,0A4.115,4.115,0,1,0,7.641,6.365,4.112,4.112,0,0,0,11.756,10.479Zm2.821,1.176h-.3a5.681,5.681,0,0,1-5.033,0h-.3A4.233,4.233,0,0,0,4.7,15.887v1.058a1.764,1.764,0,0,0,1.763,1.763h10.58a1.764,1.764,0,0,0,1.763-1.763V15.887A4.233,4.233,0,0,0,14.577,11.655Zm-8.218-.492A2.344,2.344,0,0,0,4.7,10.479H2.351A2.353,2.353,0,0,0,0,12.83v1.176a1.174,1.174,0,0,0,1.176,1.176H3.6A5.387,5.387,0,0,1,6.359,11.162Z"
                            transform="translate(0 -2.25)"
                            fill="#9ba6ac"
                          />
                        </svg>
                        <input
                          type="number"
                          min="0"
                          name="no_of_people"
                          placeholder="Number Of People"
                          value={form.no_of_people}
                          onChange={handleFormChange}
                        />
                      </div>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          margin: 0,
                          marginBottom: 0,
                          left: "5%"
                        }}
                      >
                        {searchError}
                      </p>
                      {isAdvancedSearch ? null : (
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className="search__btn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.881"
                            height="19.881"
                            viewBox="0 0 19.881 19.881"
                          >
                            <g
                              id="Icon_feather-search"
                              data-name="Icon feather-search"
                              transform="translate(1 1)"
                            >
                              <path
                                id="Path_9245"
                                data-name="Path 9245"
                                d="M18.248,11.374A6.874,6.874,0,1,1,11.374,4.5,6.874,6.874,0,0,1,18.248,11.374Z"
                                transform="translate(-4.5 -4.5)"
                                fill="none"
                                stroke="#FF567A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_9246"
                                data-name="Path 9246"
                                d="M28.713,28.713l-3.738-3.738"
                                transform="translate(-13.246 -13.246)"
                                fill="none"
                                stroke="#FF567A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </button>
                      )}
                      <div
                        className="search__advanced"
                        onClick={() => {
                          window.localStorage.setItem(
                            "searchType",
                            isAdvancedSearch ? "basic" : "adv"
                          );
                          setIsAdvancedSearch(!isAdvancedSearch);
                        }}
                      >
                        Advanced Search
                      </div>
                      {window.localStorage.getItem("searchType") ==
                        "adv" && isAdvancedSearch ? (
                        <div className="advanced_div">
                          <SearchAdvancedNew
                            history={history}
                            scrollTop={scrollTop}
                            setIsAdvancedSearch={setIsAdvancedSearch}
                            location={form.location}
                            start_time={form.start_time}
                            end_time={form.end_time}
                            date={form.date}
                            capacity={form.no_of_people}
                            setSearchError={setSearchError}
                            setTimeError={setTimeError}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {role === "Venue" ? (
            <div className="main-nav-mobile">
              <MenuLinks
                user={user}
                isVenueUser={isVenueUser}
                filteredVenues={filteredVenues}
                isEmpUser={isEmpUser}
                venues={venues}
              />
            </div>
          ) : null}
        </div>
      </header>
      {children}
      {!hideFooter && (
        <Footer
        //  isSubs={isSubs}
        />
      )}
    </>
  );
};

export default MenuLayout;
