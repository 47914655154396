import React, { Component } from "react";

export default class VenueSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      array: this.props.end ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6],
    };
  }
  render() {
    return this.props.isForBooking ? (
      <div className="md-step">
        {[1, 2, 3].map((i) => {
          return (
            <div
              key={i}
              className={`item ${
                this.props.step > i
                  ? "active"
                  : this.props.step === i
                  ? "active current"
                  : ""
              }`}
            >
              <span></span>
            </div>
          );
        })}
      </div>
    ) : (
      <div className="md-step">
        {this.state.array.map((i) => {
          return (
            <div
              className={`item ${
                this.props.step > i
                  ? "active"
                  : this.props.step === i
                  ? "active current"
                  : ""
              }`}
            >
              <span></span>
            </div>
          );
        })}
      </div>
    );
  }
}
