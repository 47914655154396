import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import store, { AppState } from "../../store";
import { DashboardApi } from "../../API/DashboardApi";

import RemoveEmployerAdmin from "./modals/RemoveEmployerAdmin";
import AddEmployerAdmin from "./modals/AddEmployeeAdmin";

const EmployerAdmins: FC = (props: any) => {
  const [employeeAdmins, setEmpployeeAdmins]: any = useState();
  const [visibleEmployeeAdmins, setVisibleEmpployeeAdmins]: any =
    useState();
  const [employeeModal, setEmpployeeModal]: any = useState(false);
  const [selectedEmployee, setSelecteEmployee]: any = useState();
  const [addAdminModal, setAddAdminModal]: any = useState(false);
  const user = useSelector((state: AppState) => state.auth.user);
  const getEmployeeAdmins = async (tokenTemp?: any) => {
    const token: any = store.getState();
    let employeeResponse: any = await DashboardApi.GetMyAdmins(
      token.auth.token
    );
    if (employeeResponse.data && employeeResponse.data.length > 0) {
      setEmpployeeAdmins(employeeResponse.data);
      setVisibleEmpployeeAdmins(employeeResponse.data);
    } else {
      toast.error("SomeThing Went Wrong");
    }
  };

  useEffect(() => {
    getEmployeeAdmins();
  }, []);

  const handleRemoveEmployee = (value: any) => {
    setSelecteEmployee(value);
    setEmpployeeModal(true);
  };

  const handleAddAdmin = () => {
    setAddAdminModal(true);
  };

  const handleAddEmployer = async (id: any) => {
    const token: any = store.getState();
    let response: any = await DashboardApi.AddEmployerAdmin(
      { admin_id: id },
      token.auth.token
    );
    if (response.data && response.data.success) {
      toast.success(response.data.success);
      getEmployeeAdmins();
      setAddAdminModal(false);
    } else {
      toast.error("SomeThing Went Wrong");
      setAddAdminModal(false);
    }
  };
  const handleRemoveEmployer = async (id: any) => {
    const token: any = store.getState();
    if (user && user?.id == id) {
      toast.error("Cannot Remove Logged In User");
    } else {
      let response: any = await DashboardApi.RemoveEmployerAdmin(
        { admin_id: id },
        token.auth.token
      );
      if (response.data && response.data.success) {
        toast.success(response.data.success);
        getEmployeeAdmins();
        setEmpployeeModal(false);
      } else {
        setEmpployeeModal(false);
        toast.error("SomeThing Went Wrong");
      }
    }
  };
  const searchAdmins = (event: any) => {
    const keyword = event.target.value;
    if (event.target.value != "") {
      let FilteredAdmins = employeeAdmins?.filter((admin: any) => {
        if (
          (admin.first_name &&
            admin.first_name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())) ||
          (admin.last_name &&
            admin.last_name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())) ||
          (admin.full_name &&
            admin.full_name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase()))
        ) {
          return admin;
        }
        if (
          admin.email &&
          admin.email
            .toLocaleLowerCase()
            .includes(keyword.toLocaleLowerCase())
        ) {
          return admin;
        }
      });
      setVisibleEmpployeeAdmins(FilteredAdmins);
      // setstate
    } else {
      setVisibleEmpployeeAdmins(employeeAdmins);
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Employer Admins</title>
      </Helmet>
      <div className="container dashboard ed">
        <div
          className="dashboard__title headerTable"
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          Employers Admins
          <div className="filter-btn__wrapper">
            <input
              type="text"
              onChange={e => searchAdmins(e)}
              id="searchAdmins"
              // ref={outSideClickRef}
              autoFocus={true}
              placeholder="Enter a keyword.."
              style={{
                display: "flex",
                borderRadius: "4px",
                paddingLeft: "18%",
                border: "1px solid #009CDC",
                position: "relative",
                height: "42px",
                color: "#009CDC",
                maxWidth: "100%"
              }}
            />
            <img
              src="/img/icons/searchIcon.png"
              style={{
                position: "absolute",
                top: "25%",
                left: "5%",
                width: "18px"
              }}
              alt="searchIcon"
            />
          </div>
        </div>
        {!visibleEmployeeAdmins ||
        (visibleEmployeeAdmins &&
          visibleEmployeeAdmins.length <= 0) ? (
          <h5 style={{ fontSize: "16px" }}>No Employer's Found</h5>
        ) : (
          <div className="team-list">
            <table id="roles_table">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {user &&
                  visibleEmployeeAdmins?.map((employee: any) => {
                    return (
                      <tr key={employee.id} style={{ height: 50 }}>
                        <td className="table-style">
                          {employee.full_name}
                        </td>
                        <td className="table-style">
                          {employee.email}
                        </td>
                        <td className="table-style">
                          {employee.status}
                        </td>
                        <td
                          className="table-style"
                          style={{
                            color: "#429bd6",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            handleRemoveEmployee(employee)
                          }
                        >
                          Remove Admin
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="Add_employee_button">
              <button
                className="btn_add_employee"
                onClick={handleAddAdmin}
              >
                Add Admin
              </button>
            </div>
          </div>
        )}
        {addAdminModal ? (
          <AddEmployerAdmin
            hideModal={setAddAdminModal}
            handleSubmit={handleAddEmployer}
          />
        ) : null}
        {employeeModal ? (
          <RemoveEmployerAdmin
            employee={selectedEmployee}
            hideModal={setEmpployeeModal}
            handleSubmit={handleRemoveEmployer}
          />
        ) : null}
      </div>
    </>
  );
};

export default EmployerAdmins;
