import React, { FC } from "react";
import { WrappedFieldProps } from "redux-form";

// import "../Screens/venue-11.css";
import "../Screens/venue-style.css";

export type CheckBox = {
  label: string;
  value: string;
};

const MultiCheckBoxNew: FC<
  WrappedFieldProps & {
    checkboxes: CheckBox[];
    isIcon: boolean;
    type1: string;
    isTitle?: boolean;
  }
> = (props) => {
  const { input } = props;

  let values: string[] = [];
  if (input.value) {
    values = input.value;
  }

  const contains = (value: string) => values.some((v) => value === v);

  const onClick = (value: string) => {
    const newValues = contains(value)
      ? values.filter((v) => v !== value)
      : [...values, value];
    props.input.onChange(newValues);
    props.input.onBlur(newValues);
  };
  const displayError = props.meta.touched && props.meta.error;

  return (
    <div style={{ width: "100%" }}>
      {!props.isTitle && (
        <div className="title">
          <p>Workspace {props.type1}</p>
        </div>
      )}

      <div className="all-item">
        <div className="row">
          {props.checkboxes.map((c, index) => {
            return (
              <div className="col-lg-2 col-md-4 col-6">
                <div
                  key={c.value}
                  onClick={() => onClick(c.value)}
                  className={`item${contains(c.value) ? " active" : ""}`}
                >
                  {props.isIcon ? (
                    <div className="icon">
                      <img
                        src={`/img/${!contains(c.value)
                            ? `icon${index + 1}.png`
                            : `ico-type-white${index + 1}.png`
                          }`}
                        alt=""
                        style={{ width: "50%" }}
                      />
                    </div>
                  ) : null}
                  <p
                    style={{
                      lineHeight: 1.1,
                      color: contains(c.value) ? "#fff" : "#000",
                    }}
                  >
                    {c.label}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {displayError && (
        <div
          style={{ marginTop: 10, alignSelf: "flex-start", display: "flex" }}
          className="validation-error"
        >
          {props.meta.error}
        </div>
      )}
    </div>
  );
};

export default MultiCheckBoxNew;
