/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import { useHistory } from "react-router";
import Helmet from "react-helmet";
import _ from "lodash";
import { AppState } from "../store";
import {
  getNearMeVenuesEffect,
  getWorkspacesForYouEffect,
  getTypesEffect
} from "./VenueWizard/Actions";
import { Venue } from "../API/VenueApi";
import InvitesModal from "./InvitesModal";
import DownLoadModal from "./DownLoadModal";

import "./HomePageNewStyles.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Home: FC = () => {
  const history = useHistory();
  const user = useSelector((state: AppState) => state.auth.user!);
  const userRole = useSelector(
    (state: AppState) => state.auth.userRoles
  );
  const [isplaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch();
  const { state: loginState }: any = useLocation();
  const venueNearMe = useSelector(
    (state: AppState) => state.venueWizard.VenuesNearMe
  );
  const [showAppModal, setShowAppModal] = useState(true);
  // const VenueData =
  //   venueNearMe?.nearBy.length > 0
  //     ? venueNearMe?.nearBy
  //     : venueNearMe?.featured.slice(0, 4);
  const VenueData = venueNearMe?.featured?.slice(0, 4);
  let latestVenues = useSelector(
    (state: AppState) => state.venueWizard.workspacesForYou
  );
  // latestVenues = latestVenues
  //   .filter((v) => v.spaces !== null && v.images != null)
  //   .slice(0, 5);

  useEffect(() => {
    dispatch(getNearMeVenuesEffect(0, 0));
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       if (position.coords) {
    //         dispatch(
    //           getNearMeVenuesEffect(
    //             position.coords.latitude,
    //             position.coords.longitude
    //           )
    //         );
    //       }
    //     },
    //     () => {
    //       dispatch(getNearMeVenuesEffect(0, 0));
    //     }
    //   );
    // } else {
    //   console.error("Geolocation is not supported by this browser!");
    // }
    dispatch(getWorkspacesForYouEffect());
    dispatch(getTypesEffect());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goWorkspace = (venueId: number) => {
    const data = VenueData.find(venue => venue.id === venueId);
    const isVenueFree =
      data && data.spaces.some(s => s.free_space === "No");
    if (!isVenueFree && userRole != "Venue") {
      if (!user) {
        history.push("/pricing");
      } else {
        if (user.plan == null) {
          history.push("/select-plan");
        } else if (user.plan.free_space === "No") {
          history.push("/select-plan");
        } else {
          history.push(`/workspace/${venueId}`);
        }
      }
    } else {
      if (!user) history.push("/pricing");
      else history.push(`/workspace/${venueId}`);
    }
  };
  const meta = {
    description: "Dorpee-workspaces on demand",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "react,meta,document,html,tags"
      }
    }
  };

  return (
    <div className="home_content">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Looking for a hot desk, coworking space, meeting room or hybrid workspace solution? Access unlimited places to work for solo work or your entire team."
        />
        <title>Dorpee | Hybrid Workspaces On Demand</title>
      </Helmet>
      {showAppModal &&
        (sessionStorage.getItem("appMessage") === null ||
          sessionStorage.getItem("appMessage") !== "NotShow") && (
          <div className="mobileModal">
            <DownLoadModal
              message="Download the Dorpee App"
              fromDashboard={true}
              showAppModal={setShowAppModal}
            />
          </div>
        )}
      <div className="bg-home hero-bg-img">
        <div className="container custom-container-first">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="text-white hero-heading">
                All kinds of workspaces <br />
                for all kinds of work
                <span style={{ color: "#FF567A", top: 0 }}>.</span>
              </h1>
              <p className="text-white p-hero mt-4">
                Work close to home. Work where you’re inspired.
                Work somewhere different every day of the week with a flexible monthly membership.
              </p>
              <a
                href={
                  !user ? "/register/select-type" : "/select-plan"
                }
                className="btn btn-dorpee"
              >
                TRY IT FOR FREE
              </a>
              <a
                href={
                  !user ? "/register/select-type" : "/select-plan"
                }
                className="btn btn-link-dorpee"
              >
                DORPEE FOR YOUR TEAM
              </a>
            </div>
            <div className="col-lg-4 d-sm-none d-block">
              <img
                src="img/homePageImg/hero-serotonin.png"
                className="img-fluid img-padd "
              />
            </div>
          </div>
        </div>
      </div>
      <div className="back_color_new">
        <div className="container custom-container-first">
          <div className="col-12 text-center dorpee_font">
            <h1>
              <span className="change-color">
                Change the way you work
              </span>{" "}
              with Dorpee workspaces on demand
            </h1>
          </div>
          <div className="row row_top">
            <div className="col-lg-4 col-12">
              <div className="member_box">
                <img
                  src="img/homePageImg/Icon awesome-map-marked-alt.svg"
                  className="img-fluid"
                  alt=""
                />
                <h1>
                  One membership, <br />
                  500+ workspaces
                </h1>
                <hr className="hr_size" />
                <p>
                  Get out of the house, the office or your hotel and
                  find a suitable workspace when you need one,
                  wherever you are.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="member_box col">
                <img
                  src="img/homePageImg/Icon ionic-ios-time.svg"
                  className="img-fluid"
                  alt=""
                />
                <h1>
                  Book by the hour <br />
                  or book the day
                </h1>
                <hr className="hr_size" />
                <div className="row">
                  <div className="col-12">
                    <p>
                      Say good-bye to day passes, time constraints or
                      lock-in contracts with singular workspaces and
                      only pay for what you need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="member_box">
                <img
                  src="img/homePageImg/Icon ionic-ios-people.svg"
                  className="img-fluid"
                  alt=""
                />
                <h1>
                  Support + connect <br />
                  with the community
                </h1>
                <hr className="hr_size" />
                <p>
                  Support local venues and businesses and build
                  connections and networks with your community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="workspace-bg">
        <div className="container custom-container-first">
          <div className="row Workspaces-padd">
            <div className="col-lg-6 col-12">
              <img
                src="img/homePageImg/workspaces-that-meet.png"
                className="img-fluid dorpee d-sm-none d-block"
                alt=""
              />
            </div>
            <div className="col-lg-6 col-12 dorpee_font px-lg-5">
              <h1>
                Workspaces that{" "}
                <span className="change-color">
                  meet your <br /> needs{" "}
                </span>{" "}
                - from one day to the
              </h1>
              <p className="mt-5">
                Easily search and filter results by the amenities that
                are important to you.
                <br />
                <br /> Looking from something dog-friendly or close to
                public-transport - easy. Can’t stand the heat? Find an
                air-conditioned venue. Keen for coffee or perhaps
                printing facilities - no probs.
                <br />
                <br /> You can even save your search preferences to
                make your next search even easier. Nice.
              </p>
              <div className="mt-5">
                <button
                  type="button"
                  className="btn btn-browse mt-lg-0 mt-3"
                >
                  <Link to="/workspaces"> BROWSE WORKSPACES</Link>
                </button>
                <button
                  type="button"
                  className="btn btn-dorpee ml-xl-2 mt-lg-0 mt-3"
                >
                  <Link
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                    style={{ color: "#fff" }}
                  >
                    TRY IT FOR FREE
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Top content --> */}

      <div className="bgg">
        <div className="dft-bg-img">
          <div className="container custom-container-first pr-lg-0 ">
            <div className="row">
              <div className="col-lg-6">
                <h1 className="text-white d-team mb-lg-4 mt-5 mt-lg-0">
                  Dorpee for{" "}
                  <span className="team-yellow">Teams</span>
                </h1>
                <p className="text-white dorpee_para">
                  Free your business from the constraints of an office
                  lease with Dorpee Teams. A customisable hybrid
                  workspace solution that works on demand.
                  <br />
                  <br /> Provide a flexible workspace solution for
                  your entire team, complete with full visibility of
                  bookings, cost metrics, key location visibility, and
                  behavioural insights. <br />
                  <br />
                  Whether you’re transitioning to a hybrid model,
                  looking for increased collaboration, improved
                  employee wellness, a boost in productivity, or all
                  of the above, we will customise your plan to suit
                  your budget and meet your business goals.
                </p>
                <div className="px-lg-0">
                  <button
                    type="button"
                    className="btn btn-more mt-lg-4 mb-lg-0 mb-4"
                  >
                    LEARN MORE
                  </button>
                </div>
              </div>
              <div className="col-lg-6 d-block d-sm-none">
                <img
                  src="img/homePageImg/teams_new.png"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-png">
        <div className="container">
          <div className="row bookings-padd">
            <div className="col-md-6 pad-left">
              <img src="/img/homePageImg/new_mobile.png" alt="mobile-apps" width="100%" />
              <img
                src="img/homePageImg/mobile_ss.png"
                className="img-fluid img-padd  d-block d-sm-none"
              />
            </div>
            <div className="col-md-6 pt-lg-0 pt-5">
              <h1 className="book-size">
                Make bookings{" "}
                <span className="change-color">on-the-go</span> <br />
                with an easy to use mobile app
              </h1>
              <ul className="fa-ul ul_style_size">
                <li>
                  <span className="fa-li icon_style">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  Search by location, amenities or even playlist{" "}
                </li>
                <li>
                  <span className="fa-li icon_style">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  Browse workspace photos
                </li>
                <li>
                  <span className="fa-li icon_style">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  Make and manage bookings in an instant
                </li>
                <li>
                  <span className="fa-li icon_style">
                    <i className="fa-solid fa-check"></i>
                  </span>
                  Invite team member to work with you
                </li>
              </ul>
              <p className="dorpee_para mt-5 mb-4">
                Available on Apple and Android devices:
              </p>
              <div className="row bookings-padd-b">
                <div className="col-md-3 col-6 pr-lg-2">
                  <a
                    href="https://apps.apple.com/us/app/id1517539910"
                    target="_blank"
                  >
                    <img
                      src="img/homePageImg/appstore.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md-3 col-6 pt-lg-0 pl-lg-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.droid.dorpee"
                    target="_blank"
                  >
                    <img
                      src="img/homePageImg/gplay.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="back_color_member">
        <div className="col-12 dorpee_font_member text-center">
          <h1>Find a membership that’s right for you</h1>
          <h6>No lock in contracts - cancel anytime.</h6>
        </div>
        <ReactOwlCarousel
          className=" owl-carousel owl-theme"
          id="owl-pricing"
          loop
          margin={19}
          dots={false}
          nav
          navText={[
            `<img src="img/homePageImg/left-arrow-dorpeePink.svg" alt:"left">`,
            `<img src="img/homePageImg/right-arrow-dorpeePink.svg" alt:"left">`
          ]}
          responsive={{
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            1100: {
              items: 3
            },
            1200: {
              items: 4
            },
            1820: {
              items: 5
            }
          }}
        >
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Trial</h1>
              <div className="card-body p-0">
                <h5>
                  <b>$0</b>/2 weeks
                </h5>
                <p className="card-text text_include">
                  Includes <b>60 credits</b> to book 3-4 workspace
                  hours*
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <p className="after_style">
                  After your trial you will roll over to the $69/mont
                  Intro Plan. You can change or cancel anytime during
                  your trial.
                </p>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    YES PLEASE
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Intro</h1>
              <div className="col-12 most_popular text-center">
                <h2>MOST POPULAR</h2>
              </div>
              <div className="card-body p-0">
                <h5>
                  <b>$69</b>/month
                </h5>
                <p className="card-text text_include">
                  Includes <b>150 credits</b> to book 7-8 workspace
                  hours*
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    100% credit rollover**
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No lock-in contract
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Referral benefits
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Regular</h1>
              <div className="card-body p-0">
                <h5>
                  <b>$249</b>/month
                </h5>
                <p className="card-text text_include">
                  Includes <b>600 credits</b> to book 30-35 workspace
                  hours*
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    100% credit rollover**
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No lock-in contract
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Referral benefits
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Pro</h1>
              <div className="card-body p-0">
                <h5>
                  <b>$449</b>/month
                </h5>
                <p className="card-text text_include">
                  Includes <b>1200 credits</b> to book 60-70 workspace
                  hours*
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    100% credit rollover**
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No lock-in contract
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Referral benefits
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Premium</h1>
              <div className="card-body p-0">
                <h5>
                  <b>$699</b>/month
                </h5>
                <p className="card-text text_include">
                  Includes <b>2150 credits</b> to book 150-200
                  workspace hours*
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    100% credit rollover**
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No lock-in contract
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Referral benefits
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="card card_trial p-4">
              <h1>Pay-as-you-go</h1>
              <div className="card-body p-0">
                <h5>
                  <sup> From </sup>
                  <b>$49</b>
                </h5>
                <p className="card-text text_include">
                  Minimum purchase 100 credits <b>100 credits</b>
                </p>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Access to 500+ workspaces
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No credit expiry
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    No lock-in contract
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    Referral benefits
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <div className="list_icon_style">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>
                  <div className="col-10 ios px-lg-1">
                    iOS + Android app
                  </div>
                </div>
                <div className="col-12 text-center p-0">
                  <Link
                    className="btn btn-please"
                    to={
                      !user ? "/register/select-type" : "/select-plan"
                    }
                  >
                    YES PLEASE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ReactOwlCarousel>

        <div className="col-12 text-center mt-3">
          <p className="my-4 col-lg-7 m-auto opacity">
            *Based on the average per/hour credit rate | **Rollover up
            to the total number of credits in your upcoming plan.
          </p>
          <h6 className="plan-size">
            Looking for a plan for your entire team - you’ve come to
            the right place.{" "}
            <a href="#" className="team_account">
              Get a Teams account.
            </a>
          </h6>
        </div>
      </div>

      <div className="change_the_way_image">
        <div className="container">
          <div className="color-overlay">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-12 text-center change_way">
                <h1>Change the way you work, for the better.</h1>
                <Link
                  className="btn btn_free"
                  to={!user ? "/register/select-type" : "select-plan"}
                >
                  START FREE TRIAL
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loginState && loginState.login === true ? (
        <InvitesModal />
      ) : null}
    </div>
  );
};

const VenueItem = ({ venue }: { venue?: Venue }) => {
  let isFreeSpace =
    venue && venue.spaces.some(v => v.free_space === "Yes");
  let HasFreeSpace =
    venue && venue.spaces.some(v => v.free_space === "No");
  const [venueLowestPrice, setVenueLowestPrice] = useState(0);
  const [lowestPrice, setLowestPrice] = useState(0);
  const [venueType, setVenueType] = useState("");

  const user = useSelector((state: AppState) => state.auth.user!);
  let status = venue && venue.status;
  const history = useHistory();

  const types = useSelector(
    (state: AppState) => state.venueWizard.types
  );
  useEffect(() => {
    let type =
      types && venue && types.find(v => v.id === venue.venue_type_id);
    setVenueType(type ? type.type : "");
  }, [types]);
  useEffect(() => {
    var venueLowestPrice =
      venue && !isNaN(Math.ceil(parseInt(venue.lowest_price) / 0.3))
        ? Math.ceil(parseInt(venue.lowest_price) / 0.3)
        : 0;
    setVenueLowestPrice(venueLowestPrice);
    var tempVenue = Number(venue && venue.spaces[0].price);
    let space =
      venue &&
      venue.spaces.sort(function (a, b) {
        return Number(a.price) - Number(b.price);
      });
    space = space && space.filter((v: any) => Number(v.price) > 0);
    if (space && space.length > 0) {
      tempVenue = Math.ceil(Number(space[0].price) / 0.3);
      setLowestPrice(tempVenue);
    }
  }, []);
  const goWorkspace = (venueId: any, venueSlug: any) => {
    if (!HasFreeSpace) {
      if (!user) {
        history.push("/pricing");
      } else {
        if (user.plan == null) {
          history.push("/select-plan");
        } else if (user.plan.free_space === "No") {
          history.push("/select-plan");
        } else {
          if (venueSlug) {
            history.push({
              pathname: `/workspace/${venueSlug}`,
              state: venueId
            });
          } else {
            history.push({
              pathname: `/workspace/${venueId}`,
              state: venueId
            });
          }
        }
      }
    } else {
      if (venueSlug) {
        history.push({
          pathname: `/workspace/${venueSlug}`,
          state: venueId
        });
      } else {
        history.push({
          pathname: `/workspace/${venueId}`,
          state: venueId
        });
      }
    }
  };
  return (
    <a
      onClick={() =>
        goWorkspace(venue && venue.id, venue && venue.slug)
      }
    >
      <div className="venue-container">
        <div
          className=" venue-imgHome--small venue-imgHome "
          style={{ cursor: "pointer" }}
        >
          <Link to={`workspace/${venue && venue?.slug}`}>
            <img
              style={{ borderRadius: 7 }}
              src={venue && venue.images && venue.images[0]}
              alt={`${venue && venue.name} 1`}
            />
          </Link>
          <div className="venue-img__status-building"></div>
          {user &&
          user.plan &&
          user.plan.free_space === "Yes" &&
          isFreeSpace ? (
            <>
              <div className=" ribbon ribbon-top-right ">
                <span>Free space</span>
              </div>
            </>
          ) : null}
          {status == "Closed" ? (
            <>
              <div className=" ribbon-left ribbon-top-left ">
                <span>Temporarily Unavailable</span>
              </div>
            </>
          ) : null}
          <div className="venue-type-label  venueTypeRib venueTypeRibHome ">
            <span className="ribbon-span" style={{ fontSize: 10 }}>
              {venueType}
            </span>
          </div>
        </div>
        <div className="venue-location">{venue && venue.suburb}</div>
        <a style={{ cursor: "pointer" }} className="venue-subtitle">
          {
            // user === undefined && !HasFreeSpace
            //   ? "FREE SPACE - Subscribe to a regular plan or higher to access"
            //   :
            venue && venue.name
          }
        </a>
        <div className="venue-rating venue-rating__flex-w">
          <div className="venue-rating__right">
            {venueLowestPrice &&
            venueLowestPrice === 0 &&
            isFreeSpace &&
            (!user ||
              (user.plan && user.plan.free_space === "No") ||
              lowestPrice == 0) ? (
              <>
                <span className={"from"}>Free Space</span>
              </>
            ) : (
              <>
                <span className={"from"}>From</span>{" "}
                <span className={"creditCount"}>{lowestPrice}</span>/{" "}
                <span className={"hour"}> hr</span>
              </>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};
export default Home;
