import React from "react";
import { Pie } from "react-chartjs-2";
const data = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
};

class BookedGroupSize extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: ["1", "2-4", "5+"],
      datasets: [
        {
          data: this.getData(),
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  getLabels() {
    let labels = [];
    let data = this.props.pie.data.no_of_people;
    for (let i in data) {
      i = this.replaceLabel(i);
      labels.push(i);
    }
    return labels;
  }

  getData() {
    let values = [];
    let data = this.props.pie.data.no_of_people;
    for (let i in data) {
      values.push(data[i]);
    }

    return values;
  }

  replaceLabel(label) {
    label = label.replace("eq", "");
    label = label.replace("gt", "");
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }

  render() {
    return (
      <div className="venue-chart-small">
        <Pie data={this.state} legend={{ position: "bottom" }} />
      </div>
    );
  }
}

export default BookedGroupSize;
