export const locationUrl = (
  address: string | number,
  suburb: string | number,
  state?: string | number,
  postcode?: string | number
) =>
  `http://www.google.com/maps/place/${address}/${suburb}/${state}/${postcode}`;

export const locationUrl2 = (
  address: string | number,
  suburb: string | number,
  state?: string | number,
  postcode?: string | number
) =>
  `https://www.google.com/maps/search/?api=1&query=${address}+${suburb}+${state}+${postcode}`;
