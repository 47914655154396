import React from "react";
import { Modal } from "../../../UI/Modal";
import TimePicker from "rc-time-picker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { formatedTimeMin2 } from "../../../helpers/DateHelpers";
import {
  getVenuesEffect,
  GetVenueOpenHoursEffect
} from "../../VenueWizard/Actions";
import { postVenueOpenHoursEffect } from "../../WorkspaceWizard/Actions";
import moment from "moment";
import { setLoader } from "../../Loader/Actions";
const week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];

class OpenHoursModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      venuesOpenHours: [],
      selectedVenuesHours: {},
      venue_id: 0,
      weekDays: {
        Monday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Tuesday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Wednesday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Thursday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Friday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Saturday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Sunday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        }
      },
      no_day_checked: false
    };

    this.CloseModal = this.CloseModal.bind(this);
    this.handleOpenTime = this.handleOpenTime.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    this.setState({ venuesOpenHours: this.props.venuesOpenHours });
    this.setState(
      this.props.venues[0] && { venue_id: this.props.venues[0].id }
    );
    this.props.GetVenueOpenHoursEffect();
    let filteredVenue =
      this.props.venues[0] &&
      this.props.venuesOpenHours.find(
        x => x.venue_id === this.props.venues[0].id
      );
    let cloneObject = this.state.weekDays;
    if (filteredVenue != null) {
      filteredVenue.availability.map(item => {
        if (item.openingTime !== null && item.closingTime != null) {
          cloneObject[item.day].opening_time = item.openingTime;
          cloneObject[item.day].closing_time = item.closingTime;
          cloneObject[item.day].checked = true;
        } else {
          cloneObject[item.day].opening_time = "";
          cloneObject[item.day].closing_time = "";
          cloneObject[item.day].checked = false;
        }
      });
      this.setState({ weekDays: cloneObject });
      this.setState({ venue_id: this.props.venues[0].id });
    } else {
      let unSetWeekdays = this.state.weekDays;
      week.map(item => {
        unSetWeekdays[item].opening_time = "";
        unSetWeekdays[item].closing_time = "";
        unSetWeekdays[item].checked = false;
      });
    }
  }

  componentDidUpdate(previouProps) {
    if (
      previouProps.venues &&
      this.props.venues &&
      previouProps.venues.length !== this.props.venues.length
    ) {
      let filteredVenue = this.props.venuesOpenHours.find(
        x => x.venue_id === this.props.venues[0].id
      );
      let cloneObject = this.state.weekDays;
      if (filteredVenue != null) {
        filteredVenue.availability.map(item => {
          if (item.openingTime !== null && item.closingTime != null) {
            cloneObject[item.day].opening_time = item.openingTime;
            cloneObject[item.day].closing_time = item.closingTime;
          } else {
            cloneObject[item.day].opening_time = "";
            cloneObject[item.day].closing_time = "";
          }
        });
        this.setState({ weekDays: cloneObject });
      } else {
        let unSetWeekdays = this.state.weekDays;
        week.map(item => {
          unSetWeekdays[item].opening_time = "";
          unSetWeekdays[item].closing_time = "";
        });
      }
      this.setState({ venue_id: this.props.venues[0].id });
    }
  }

  isOneDayChecked() {
    let dayChecked = false;
    for (let day in this.state.weekDays) {
      if (this.state.weekDays[day].checked) {
        dayChecked = true;
        break;
      }
    }
    if (!dayChecked) this.setState({ no_day_checked: true });
    return dayChecked;
  }

  resetErrors() {
    let cloneObject = this.state.weekDays;
    for (let day in this.state.weekDays) {
      cloneObject[day].error_opening = false;
      cloneObject[day].error_closing = false;
      cloneObject[day].valid_format = false;
    }
    this.setState({
      weekDays: cloneObject,
      no_day_checked: false
    });
  }

  isFormValid() {
    if (!this.isOneDayChecked()) return false;
    this.resetErrors();
    let cloneObject = this.state.weekDays;
    let valid = true;
    for (let day in this.state.weekDays) {
      if (this.state.weekDays[day].checked) {
        // if (this.state.weekDays[day].opening_time.length === 0)
        // cloneObject[day].error_opening = true;
        // if (this.state.weekDays[day].closing_time.length === 0)
        // cloneObject[day].error_closing = true;
        if (
          (this.state.weekDays[day].opening_time &&
            this.state.weekDays[day].closing_time &&
            this.state.weekDays[day].opening_time >
            this.state.weekDays[day].closing_time) ||
          this.state.weekDays[day].opening_time ===
          this.state.weekDays[day].closing_time
        )
          cloneObject[day].valid_format = false;
        if (
          cloneObject[day].error_opening ||
          cloneObject[day].error_closing ||
          cloneObject[day].valid_format
        )
          valid = false;
      }
    }
    this.setState({ weekDays: cloneObject });
    return valid;
  }

  CloseModal() {
    this.props.CloseModal();
  }

  handleOpenTime(day, e) {
    if (!e) return;
    let openTime = e._d.toString().split(" ");
    let cloneObject = this.state.weekDays;
    cloneObject[day].opening_time = openTime[4];
    cloneObject[day].error_opening = false;
    if (
      this.state.weekDays[day].opening_time <
      this.state.weekDays[day].closing_time
    )
      cloneObject[day].valid_format = false;
    this.setState({ weekDays: cloneObject });
  }

  handleCloseTime(day, e) {
    if (!e) return;
    let closeTime = e._d.toString().split(" ");
    let cloneObject = this.state.weekDays;
    cloneObject[day].closing_time = closeTime[4];
    cloneObject[day].error_closing = false;
    if (
      this.state.weekDays[day].opening_time <
      this.state.weekDays[day].closing_time
    )
      cloneObject[day].valid_format = false;
    this.setState({ weekDays: cloneObject });
  }

  handleChecked(day) {
    let cloneObject = this.state.weekDays;
    cloneObject[day].checked = !this.state.weekDays[day].checked;
    this.setState({
      weekDays: cloneObject
    });
    if (this.state.weekDays[day].checked) {
      for (let key in this.state.weekDays) {
        if (this.state.weekDays[key].opening_time) {
          cloneObject[day].opening_time = this.state.weekDays[key].opening_time;
          this.setState({ weekDays: cloneObject });
        }
        if (this.state.weekDays[key].closing_time) {
          cloneObject[day].closing_time = this.state.weekDays[key].closing_time;
          this.setState({ weekDays: cloneObject });
        }
      }
    } else {
      for (let key in this.state.weekDays) {
        if (this.state.weekDays[key].opening_time) {
          cloneObject[day].opening_time = null;
          this.setState({ weekDays: cloneObject });
        }
        if (this.state.weekDays[key].closing_time) {
          cloneObject[day].closing_time = null;
          this.setState({ weekDays: cloneObject });
        }
      }
    }
  }

  handleVenue(event) {
    let venue_id = event.target.value;
    let filteredVenue = this.props.venuesOpenHours.find(
      x => x.venue_id === parseInt(venue_id)
    );
    let cloneObject = this.state.weekDays;
    if (filteredVenue != null) {
      filteredVenue.availability.map(item => {
        if (item.openingTime !== null && item.closingTime != null) {
          cloneObject[item.day].opening_time = item.openingTime;
          cloneObject[item.day].closing_time = item.closingTime;
          cloneObject[item.day].checked = true;
        } else {
          cloneObject[item.day].opening_time = "";
          cloneObject[item.day].closing_time = "";
          cloneObject[item.day].checked = false;
        }
      });
      this.setState({ weekDays: cloneObject });
    } else {
      let unSetWeekdays = this.state.weekDays;
      week.map(item => {
        unSetWeekdays[item].opening_time = "";
        unSetWeekdays[item].closing_time = "";
        unSetWeekdays[item].checked = false;
      });
    }
    this.setState({ venue_id: venue_id });
  }

  handleSave = async () => {
    if (this.isFormValid()) {
      this.props.CloseModal();
      let { weekDays } = this.state;

      var filtered = week.map(day => {
        if (weekDays[day].checked === true) {
          return {
            checked: true,
            opening_time: weekDays[day].opening_time,
            closing_time: weekDays[day].closing_time
          };
        } else {
          return {
            checked: false,
            opening_time: "",
            closing_time: ""
          };
        }
      });

      var selectedDays = filtered.filter(v => v.checked);
      let availability = week.map(day => {
        if (weekDays[day].checked) {
          weekDays[day].opening_time =
            weekDays[day].opening_time === ""
              ? selectedDays[0].opening_time
              : weekDays[day].opening_time;
          weekDays[day].closing_time =
            weekDays[day].closing_time === ""
              ? selectedDays[0].closing_time
              : weekDays[day].closing_time;
        } else {
          weekDays[day].opening_time = "";
          weekDays[day].closing_time = "";
        }
        return {
          day,
          openingTime: weekDays[day].opening_time,
          closingTime: weekDays[day].closing_time
        };
      });

      let request = {
        venue_id: parseInt(this.state.venue_id),
        availability
      };

      await this.props.postVenueOpenHoursEffect(request);
      //    this.props.setLoader(true)
      this.props.GetVenueOpenHoursEffect();
    }
  };

  render() {
    const days = week.map(i => (
      <tr key={i}>
        <td className="hours-checkbox">
          <input
            id={i}
            type="checkbox"
            checked={this.state.weekDays[i].checked}
            className="d-none"
          />
          <label
            htmlFor={i}
            className="my-modal-check"
            onClick={this.handleChecked.bind(this, i)}
          >
            {i}
          </label>
        </td>
        <td>
          <div className="input-container_t">
            <span>
              <svg className="hours" width="20" height="20" viewBox="0 0 21 20">
                <g>
                  <g>
                    <path
                      fill="#d1d1d1"
                      d="M11.346 5h-1.5v6l5.25 3.15.75-1.23-4.5-2.67zm-.5 13c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-.01-18C5.316 0 .846 4.48.846 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10z"
                    />
                  </g>
                </g>
              </svg>
              <TimePicker
                showSecond={false}
                value={
                  this.state.weekDays[i].opening_time
                    ? moment(
                      formatedTimeMin2(this.state.weekDays[i].opening_time)
                    )
                    : ""
                }
                minuteStep={15}
                onChange={this.handleOpenTime.bind(this, i)}
              />
            </span>
            {this.state.weekDays[i].error_opening ? (
              <p className="contact-form-error-block">Invalid value</p>
            ) : null}
          </div>
        </td>
        <td>
          <div className="input-container_t">
            <span>
              <svg className="hours" width="20" height="20" viewBox="0 0 21 20">
                <g>
                  <g>
                    <path
                      fill="#d1d1d1"
                      d="M11.346 5h-1.5v6l5.25 3.15.75-1.23-4.5-2.67zm-.5 13c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-.01-18C5.316 0 .846 4.48.846 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10z"
                    />
                  </g>
                </g>
              </svg>
              <TimePicker
                showSecond={false}
                value={
                  this.state.weekDays[i].closing_time
                    ? moment(
                      formatedTimeMin2(this.state.weekDays[i].closing_time)
                    )
                    : ""
                }
                minuteStep={15}
                onChange={this.handleCloseTime.bind(this, i)}
              />
            </span>
            {this.state.weekDays[i].error_closing ||
              this.state.weekDays[i].valid_format ? (
                <p className="contact-form-error-block">Invalid value</p>
              ) : null}
          </div>
        </td>
      </tr>
    ));

    return (
      <>
        <Modal>
          <div className="addressbook-form-box day-hours" style={{ marginTop: -26 }}>
            <div className="congrat-box__header">
              <span>Open Hours</span>
              <div
                onClick={this.CloseModal.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form" style={{ marginTop: -10 }}>
              <div className="addressbook-form">
                <div className="col-md-5" style={{ marginLeft: -10 }}>
                  <div
                    style={{ border: "1px solid #009cdc", width: "100%" }}
                    className="input-container"
                  >
                    <div className="input-select">
                      <select
                        style={{ color: "#009cdc" }}
                        className="pl-12"
                        onChange={e => this.handleVenue(e)}
                      >
                        {this.props.venues &&
                          this.props.venues.map(venue => (
                            <option key={venue.id} value={venue.id}>
                              {venue.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <table className="modal-table team-list-table">
                  <thead>
                    <tr>
                      <th className="modal-table-head w-40"></th>
                      <th className="modal-table-head w-30 text-left hours-header">
                        Opening Time
                      </th>
                      <th className="modal-table-head w-30 text-left hours-header">
                        Closing Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>{days}</tbody>
                </table>
                <div className="days-submit">
                  {this.state.no_day_checked ? (
                    <p className="no-day-selected">
                      Please select at least one day of week
                    </p>
                  ) : null}
                  <button
                    onClick={this.handleSave.bind(this)}
                    className="btn_1 btn-success addressbook-modal-btn addressbook-submit-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.venueWizard.venues,
  venuesOpenHours: state.venueWizard.venuesOpenHours,
  loading: state.Loader.isLoading
});

const mapDispatchToProps = {
  getVenuesEffect,
  postVenueOpenHoursEffect,
  GetVenueOpenHoursEffect,
  setLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenHoursModal);
