import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

import { AppState } from "../../../store";
import { Modal } from "../../../UI/Modal";
import { formatDatemonth } from "../../../helpers/DateHelpers";
import { AuthApi } from "../../../API/AuthApi";
import { createPaymentEffect } from "../../PaymentWizard/Actions";
import { addPromoCode, setSelectedPlanEffect } from "../../SignUp/Actions";
import { useHistory } from "react-router-dom";
const AddPromoModal = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state: AppState) => state.Loader.isLoading);
  const [hasPromo, setHasPromo] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoMessageError, setPromoMessageError] = useState("");
  const hideModal = (flag: boolean) => {
    props.showHideModal(flag);
  };
  const handlePromo = () => {
    setHasPromo(true);
  };
  const handleNoPromo = async () => {
    setHasPromo(false);
    if (
      (props.user && props.user.plan_id && props.isSubscriptionCancelled) ||
      props.user!.card_status
    ) {
      await dispatch(
        createPaymentEffect({ plan_id: props.selectedPlan.id }, history, true)
      );
    }
    if (!props.user) {
      history.push("/register/select-type");
    } else {
      history.push("payment");
      await dispatch(setSelectedPlanEffect(props.selectedPlan));
    }
  };

  const handlePromoCode = (e: any) => {
    e.preventDefault();
    setPromoCodeError(false);
    setPromoCode(e.target.value);
    setPromoMessageError("");
  };
  const handleSubmit = async () => {
    if (promoCode == "" || !promoCode) {
      setPromoCodeError(true);
      setPromoMessageError("Please enter the promo code");
    } else {
      const response = await AuthApi.ValidatePromo({
        code: promoCode,
        date: formatDatemonth(new Date()),
      });
      if (response.data.valid) {
        dispatch(
          addPromoCode(
            promoCode,
            props.user,
            props.selectedPlan,
            props.isSubscriptionCancelled,
            history
          )
        );
      } else if (!response.data.valid) {
        setPromoCodeError(true);
        setPromoMessageError("Invalid or expired code");
      }
    }
  };
  return (
    <Modal>
      <div className="mblViewAdddressFormModal">
        <div
          className="congrat-box__header"
          style={{ position: "relative", minWidth: "350px" }}
        >
          <span>Promo Code</span>
          <div onClick={() => hideModal(false)} className="close-btn"></div>
        </div>
        <div className="addressbook-form">
          {!hasPromo ? (
            <>
              <div className="content-wrapper">
                <p style={{ margin: 0, fontSize: '130%' }}>Do you have a promotional code?</p>
              </div>
              <button
                className={`btn addressbook-modal-btn addressbook-submit-btn ${
                  isLoading ? " disabled  btn--loading" : ""
                }`}
                disabled={isLoading}
                onClick={handlePromo}
              >
                YES
              </button>
              <button
                className={`btn addressbook-modal-btn addressbook-submit-btn ${
                  isLoading ? " disabled  btn--loading" : ""
                }`}
                disabled={isLoading}
                onClick={handleNoPromo}
              >
                NO
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {hasPromo ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem",
              }}
            >
              <input
                className="pl-12"
                onChange={handlePromoCode}
                value={promoCode}
                name="promoCode"
                type="text"
                placeholder="Promo Code"
                style={{
                  padding: "10px",
                  marginBottom: "5px",
                  border: "1px solid #009cdc",
                  color: "#009cdc",
                  fontSize: "14px",
                  borderRadius: "4px",
                  minWidth: "200px",
                }}
              />
              {promoCodeError ? (
                <p style={{ color: " red" }}>{promoMessageError}</p>
              ) : (
                ""
              )}
              <button
                style={{ marginBottom: "1rem", width: "200px", marginTop: 20 }}
                onClick={handleSubmit}
                className={`btn wizard__btn mb-0${
                  isLoading ? " disabled" : ""
                }${isLoading ? " btn--loading" : " btn-blue"}`}
                disabled={isLoading || promoCodeError}
              >
                SUBMIT
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddPromoModal;
