import { ApiClient } from "./ApiClient";
import { User } from "./AuthApi";
import { PaymentOptionResponse } from "../store";
export class PaymentAPI {
  static CreatePayment(token: string, request: createPaymentRequest) {
    return ApiClient.post<createPaymentRequest, paymentResponse>(
      "user/purchase-plan",
      request,
      token
    );
  }

  static CreateCard(token: string, request: createCardRequest) {
    return ApiClient.post<createCardRequest, paymentResponse>(
      "user/cards",
      request,
      token
    );
  }
  static PurchaseCredits(token: string, request: createPurchaseCreditsRequest) {
    return ApiClient.post<createPurchaseCreditsRequest, paymentResponse>(
      "user/purchase-credits",
      request,
      token
    );
  }

  // static ChangePlan(token: string, request: changePlanRequest) {
  //     return ApiClient.post<changePlanRequest, paymentResponse>('user/purchase-plan', request,token);
  // }

  static UpdateCard(
    token: string,
    id: number,
    request: createUpdatedPaymentRequest
  ) {
    return ApiClient.post<
      createUpdatedPaymentRequest,
      { data: PaymentOptionResponse }
    >(`user/cards/${id}`, request, token);
  }
  static DeleteCard(token: string, id: number) {
    return ApiClient.delete(`user/cards/${id}`, token);
  }
  static CancelPlan(token: string) {
    return ApiClient.post<any, any>("user/cancel-subscription", null, token);
  }
}

export class createPurchaseCreditsRequest {
  constructor(detailsFields: Credits) {
    this.credits = detailsFields.credits;
  }
  credits: number;
}

export class createUpdatedPaymentRequest {
  constructor(detailsFields: any) {
    this.exp_month = detailsFields.exp_month;
    this.exp_year = detailsFields.exp_year;
    this.name = detailsFields.name;
    this._method = detailsFields._method;
  }
  exp_month: string;
  exp_year: string;
  _method: string;
  name: string;
}
export class createPaymentRequest {
  constructor(detailsFields: Payment) {
    this.plan_id = detailsFields.plan_id;
    this.stripe_token = detailsFields.stripe_token;
  }
  plan_id: number;
  stripe_token?: string;
}

export class createCardRequest {
  constructor(detailsFields: Card) {
    this.name = detailsFields.name;
    this.stripe_token = detailsFields.stripe_token;
  }
  name: string;
  stripe_token: string;
}

// export class changePlanRequest {
//     constructor(detailsFields: Payment) {
//         this.plan_id = detailsFields.plan_id;
//     }
//     plan_id: string
// }

export type Payment = {
  plan_id: number;
  stripe_token?: string;
};
export type Card = {
  name: string;
  stripe_token: string;
};

export type Credits = {
  credits: number;
};

export type ChangePlanData = {
  plan_id: number;
};
export type paymentResponse = {
  data: User;
  error: any;
};
