/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import moment from "moment";

import store from "../../store";
import { DashboardApi } from "../../API/DashboardApi";
import EmployerDashboardMap from "./EmployerDashboardMap";
import { getPublicVenuesEffect } from "../VenueWizard/Actions";
import AddEmployeeCostModal from "../Dashboard/modals/AddEmployeeCostModal";
import { getEmplpoyerCustomerBookings } from "../Book/Booking/Actions";
import {
  getPlansEffect,
  updateEmployeeRequirements
} from "../SignUp/Actions";
import WeeklyUtilization from "./charts/WeeklyUtilization";
import {
  formatDate,
  getDateformatedChange
} from "../../helpers/DateHelpers";
import BookingDuration_Employer from "./charts/BookingDuration_Employer";
import BookingGroupSizePie_Emp from "./charts/BookingGroupSizePie_Emp";
import BookingLocation_HeatMap from "./charts/BookingLocation_HeatMap";
import AddEmployer from "./modals/AddEmployer";
import SetCredits from "./modals/SetCredits";
import Confirmation from "./modals/Confirmation";

import VenueBookingsPie_Emp from "./charts/VenueBookingsPie_Emp";
import SpaceBookingsPie_Emp from "./charts/SpaceBookingPie_Emp";

import "./accordion.sass";
import CostOverview from "./charts/CostOvervie";
import { BookingAPI } from "../../API/BookingAPI";
class EmployerDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        first_name: "",
        last_name: "",
        avatar: "",
        organisation: {
          company_name: "",
          credits: "",
          employee_cost: "",
          logo: {},
          ohs_requirment: "",
          vaccination_requirment: "",
          dorpee_cost: ""
        }
      },
      invitedCustomersList: [],
      employersList: [],
      totalEmployees: 0,
      activeBookingsCount: 0,
      creditsSpent: 0,
      total_hours_booked: 0,
      total_hours_this_month: 0,
      creditsSpentThisMonth: 0,
      monthlyCredits: 0,
      daysLeftInCycle: 0,
      credits_used_this_week: 0,
      employer_hours_per_month: 0,
      employer_cost_per_month: 0,
      lastMonthAllBookings: [],
      mapMarkers: [],
      bookingDetail: [],
      venues: [],
      AllBooking: [],
      isModalOpen: false,
      planPrice: 0,
      totalBookings: 0,
      totalBookingsCount: 0,
      indexCol: 0,
      startDate: null,
      endDate: null,
      pieData: null,
      barData: null,
      costData: {
        cost: {},
        hours: {}
      },
      venueData: null,
      workspacesData: null,
      dateError: "",
      heatMapData: [],
      allHeatMapData: [],
      token: "",
      userDashboard: {},
      joinedlastMonth: "",
      submitted: false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  getDashboard = async () => {
    const response = await DashboardApi.GetEmployerDashboardADmin(
      this.props.param.id
    );
    this.setState({
      token: response.token,
      userDashboard: response.user
    });
  };
  componentDidUpdate(next) {
    if (next.param.id != this.props.param.id) {
      this.getDashboard();
    }
    if (next.venues.length != this.props.venues.length) {
      this.setState({ venues: this.props.venues });
      this.getMyEmployeesBookings();
    }
    if (next.plans.length != this.props.plans.length) {
      var tempId =
        this.props.userDashboard &&
        Object.keys(this.props.userDashboard).length > 0
          ? this.props.userDashboard.employer_plan_id
          : this.props.user.employer_plan_id;
      let price = this.props.plans.find(v => v.id === tempId);
      if (price) {
        this.setState({ planPrice: Number(price.price) });
      }
    }

    if (
      this.props.myTeamBookings &&
      next.myTeamBookings &&
      next.myTeamBookings.length != this.props.myTeamBookings.length
    ) {
      let currentMonth = new Date().getMonth() + 1;
      if (currentMonth < 10)
        currentMonth = "0" + currentMonth.toString();
      let totalCount = this.state.totalBookings;
      let allBookingsTotal = this.state.totalBookingsCount;
      let total_booked_hours = 0;
      let bookedbymultiple = 0;
      this.props.myTeamBookings.map(item => {
        if (item.all_bookings) {
          allBookingsTotal += item.all_bookings.length;
          let found = item.all_bookings.filter(v => {
            if (
              v.start_date.split("/")[0] === currentMonth.toString()
            ) {
              total_booked_hours += v.duration;
            }
            if (
              v.no_of_people > 1 &&
              v.start_date.split("/")[0] === currentMonth.toString()
            ) {
              bookedbymultiple += v.duration;
            }
            return (
              v.start_date.split("/")[0] ===
                currentMonth.toString() || v.no_of_people > 1
            );
          });
          if (found) {
            totalCount += found.length;
          }
        }
      });
      let totalhours = total_booked_hours / 60;

      let multiplepeoplebooked = bookedbymultiple / 60;

      let newindexCol = Math.round(
        (multiplepeoplebooked.toFixed(2) / totalhours.toFixed(2)) * 10
      );
      this.setState({
        totalBookings: totalCount,
        totalBookingsCount: allBookingsTotal,
        indexCol: newindexCol
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.user.first_name != prevState.user.first_name ||
      prevProps.user.last_name != prevState.user.last_name ||
      prevProps.user.avatar != prevState.user.avatar
    ) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          first_name: this.props.user.first_name,
          last_name: this.props.user.last_name,
          avatar: this.props.user.avatar
        }
      }));
    }
  }

  async componentDidMount() {
    this.setState({
      token: this.props.token,
      userDashboard: this.props.userDashboard
    });
    let dateprevious = this.props.registered_date.split("/");
    let beforeMonth = await moment(
      `${dateprevious[2]}-${dateprevious[1]}-${dateprevious[0]}`
    ).isBefore(new Date(), "month");
    let beforeYear = await moment(
      `${dateprevious[2]}-${dateprevious[1]}-${dateprevious[0]}`
    ).isBefore(new Date(), "year");

    if (beforeMonth || beforeYear) {
      this.setState({ joinedlastMonth: true });
    }
    this.props.getPublicVenuesEffect();
    if (this.props.token) {
      this.props.getEmplpoyerCustomerBookings(this.props.token);
    } else this.props.getEmplpoyerCustomerBookings();
    this.props.getPlansEffect();
    let userState = store.getState();
    this.getStats();
    this.getMyEmployeesBookings();
    let user = this.props.userDashboard
      ? this.props.userDashboard
      : userState.auth.user;

    if (user.organisation) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          organisation: {
            ...prevState.user.organisation,
            company_name: user.organisation.company_name,
            credits: user.organisation.credits,
            employee_cost: user.organisation.dorpee_price,
            logo: user.organisation.logo,
            ohs_requirment: user.organisation.ohs_requirment,
            vaccination_requirment:
              user.organisation.vaccination_requirment,
            dorpee_cost: user.organisation.dorpee_cost
          }
        }
      }));
    }
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.avatar
      }
    }));
    this.barChart();
    this.pieChart();
    this.costChart();
    this.bookingsData();
  }
  handleToggleModal = () => {
    this.setState({ isModalOpen: false });
  };
  showEmployeeCostModal = () => {
    this.setState({ isModalOpen: true });
  };

  async getMyEmployeesBookings() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    // if (this.props.token) {
    let response = await BookingAPI.GetMyCustomerBookings(token);
    if (response.data) {
      this.setHeapMapData(response.data);

      // if (this.state.venues.length > 0) {
      await this.setMapMarkers(response.data);
      // }
    }
  }

  async barChart() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    let response = await DashboardApi.GetBar2(token);
    if (response.data) {
      this.setState({ barData: response.data });
    }
  }

  async pieChart() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    const start_date = this.state.startDate
      ? formatDate(this.state.startDate)
      : null;
    const end_date = this.state.endDate
      ? formatDate(this.state.endDate)
      : null;
    let response = await DashboardApi.GetPie2(
      token,
      start_date,
      end_date
    );
    if (response.data) {
      this.setState({ pieData: response.data });
    }
  }
  async costChart() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    let response = await DashboardApi.GetCostOverview(token);
    if (response.data) {
      this.setState(prevState => ({
        costData: {
          ...prevState.costData,
          cost: response.data.cost,
          hours: response.data.hours
        }
      }));
      // this.setState({ costData: response.data });
      // this.setState({ costData: response.data });
    }
  }

  async bookingsData() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    const start_date = this.state.startDate
      ? formatDate(this.state.startDate)
      : null;
    const end_date = this.state.endDate
      ? formatDate(this.state.endDate)
      : null;
    let response = await DashboardApi.GetBookingPieChart(
      token,
      start_date,
      end_date
    );
    if (response.data) {
      this.setState({
        venueData: response.data,
        workspacesData: response.data
      });
    }
  }
  async getStats() {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    let response = await DashboardApi.GetEmployerDashboardStats(
      token
    );
    if (response.data) {
      this.setState({
        totalEmployees: response.data.totalEmployees,
        activeBookingsCount: response.data.activeBookingsCount,
        creditsSpent: response.data.creditsSpent,
        total_hours_booked: response.data.total_hours_booked,
        creditsSpentThisMonth: response.data.creditsSpentThisMonth,
        monthlyCredits: response.data.monthlyCredits
          ? response.data.monthlyCredits
          : 0,
        credits_used_this_week: response.data.total_credits_last_week,
        daysLeftInCycle: response.data.daysLeftInCycle,
        employer_hours_per_month:
          response.data.employer_hours_per_month,
        employer_cost_per_month: response.data.employer_cost_per_month
          ? response.data.employer_cost_per_month
          : this.state.employer_cost_per_month,
        total_hours_this_month: response.data.total_hours_this_month
          ? response.data.total_hours_this_month
          : this.state.total_hours_this_month,
        lastMonthAllBookings: response.data.lastMonthAllBookings
      });
    }
  }

  setMapMarkers(data) {
    let res = [];
    let resCurrent = [];
    let iter = 0;
    let iter1 = 0;
    for (let item of data) {
      if (item.active_bookings) {
        for (let bkgsItem of item.current_today_bookings) {
          let venue = this.props.venues.find(
            v => v.id == bkgsItem.venue_id
          );
          let tmp = {
            id: iter,
            credits: bkgsItem.credits,
            start: bkgsItem.start_time,
            end: bkgsItem.end_time,
            companyName: item.company_name,
            name: item.first_name + " " + item.last_name,
            lat: venue && venue.lat,
            lng: venue && venue.lng,
            data: bkgsItem
          };
          res.push(tmp);
          iter++;
        }
      }
      if (item.all_bookings) {
        for (let bkgsItem of item.all_bookings) {
          let venue = this.props.venues.find(
            v => v.id == bkgsItem.venue_id
          );
          let tmp = {
            id: iter1,
            credits: bkgsItem.credits,
            start: bkgsItem.start_time,
            end: bkgsItem.end_time,
            companyName: item.company_name,
            name: item.first_name + " " + item.last_name,
            lat: venue && venue.lat,
            lng: venue && venue.lng,
            data: bkgsItem
          };
          resCurrent.push(tmp);
          iter1++;
        }
      }
    }
    this.setState({
      mapMarkers: res
    });
    this.setState({
      AllBooking: resCurrent
    });
    this.setState({ bookingDetail: data });
  }
  setHeapMapData(data) {
    let resCurrent = [];
    let allData = [];
    for (let item of data) {
      if (item.all_bookings) {
        for (let bkgsItem of item.all_bookings) {
          let venue = this.props.venues.find(
            v => v.id == bkgsItem.venue_id
          );
          if (venue) {
            if (venue.lat && venue.lng) {
              let tmp = {
                date: bkgsItem.start_date,
                companyName: item.company_name,
                name: item.first_name + " " + item.last_name,
                lat: venue && venue.lat,
                lng: venue && venue.lng
              };
              resCurrent.push([venue.lat, venue.lng, "1200"]);
              allData.push(tmp);
            }
          }
        }
      }
    }
    this.setState({
      heatMapData: resCurrent,
      allHeatMapData: allData
    });
  }
  handleSelect(e) {
    e.persist();
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        organisation: {
          ...prevState.user.organisation,
          [e.target.name]: e.target.value
        }
      }
    }));
  }
  async handleUserUpdate() {
    this.props.updateEmployeeRequirements({
      vaccination_requirment:
        this.state.user.organisation.vaccination_requirment,
      ohs_requirment: this.state.user.organisation.ohs_requirment
    });
  }

  clearFilter = () => {
    if (this.state.startDate || this.state.endDate) {
      this.setState({ startDate: null, endDate: null });
      setTimeout(() => {
        this.pieChart();
        this.bookingsData();
      }, 1000);
    }
    if (this.state.startDate && this.state.endDate) {
      this.setState({
        startDate: null,
        endDate: null,
        submitted: false
      });
      setTimeout(() => {
        this.pieChart();
        this.bookingsData();
      }, 1000);
    }
  };
  handleSubmit = () => {
    if (this.state.endDate == null) {
      this.setState({ dateError: "Enter end date" });
    }
    if (this.state.endDate != null)
      if (this.state.startDate > this.state.endDate) {
        this.setState({ dateError: "invalid end date" });
      }
    if (this.state.startDate <= this.state.endDate) {
      this.setState({ dateError: "" });
      this.pieChart();
      this.bookingsData();
      this.setState({ submitted: true });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Employer Dashboard</title>
        </Helmet>
        <div className="container pd-0">
          <div className="dashboard">
            <div className="dashboard__top">
              <div className="dashboard__title">
                {this.props.userDashboard
                  ? this.props.userDashboard.company_name
                  : "My Dashboard"}
              </div>
            </div>
            <div className="dashboard__profile dashboard__profile--employer">
              {!this.props.token ? (
                <div className="dashboard__profile-info">
                  <div className="dashboard__profile-avatar">
                    <img
                      src={
                        this.state.user.avatar
                          ? this.state.user.avatar
                          : "/img/icons/user-info.svg"
                      }
                      alt=""
                    />
                  </div>

                  <div
                    className="dashboard__profile-name"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    {this.state.user.first_name}{" "}
                    {this.state.user.last_name}
                    <Link
                      to="/profile"
                      onClick={e =>
                        Cookies.set("isEditProfile", "edit")
                      }
                    >
                      <div
                        className="dashboard__edit"
                        style={{ marginLeft: "4px" }}
                      ></div>
                    </Link>
                  </div>
                </div>
              ) : null}
              <div
                className="dashboard__data"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div className="data__item">
                  <div className="data__icon">
                    <div className="data__icon-employers"></div>
                  </div>
                  <div className="data__quantity">
                    {this.state.totalEmployees}
                  </div>
                  <div className="data__title">
                    {this.state.totalEmployees == 1
                      ? "Employee"
                      : "Employees"}
                  </div>
                </div>
                <div className="data__item">
                  <div className="data__icon">
                    <div className="data__icon-bookings"></div>
                  </div>
                  <div className="data__quantity">
                    <Link to="/myteam-bookings">
                      {this.state.activeBookingsCount}
                    </Link>
                  </div>
                  <Link to="/myteam-bookings" className="data__title">
                    Upcoming
                    <br />
                    {this.state.activeBookingsCount == 1
                      ? "Booking"
                      : "Bookings"}
                  </Link>
                </div>
                <div className="data__item">
                  <div className="data__icon">
                    <div className="data__icon-credits"></div>
                  </div>
                  <div className="data__quantity">
                    {this.state.total_hours_booked}
                  </div>
                  <div className="data__title">
                    Total Dorpee
                    <br />
                    Hours Booked
                  </div>
                </div>
              </div>
              {!this.props.token ? (
                <div className="dashboard-company">
                  <div
                    className="dashboard-company__logo"
                    style={{ minWidth: 153 }}
                  >
                    <img
                      src={`${process.env.REACT_APP_HOST}storage/${
                        this.state.user &&
                        this.state.user.organisation &&
                        this.state.user.organisation.logo &&
                        this.state.user.organisation.logo.id
                      }/${
                        this.state.user &&
                        this.state.user.organisation &&
                        this.state.user.organisation.logo &&
                        this.state.user.organisation.logo.file_name
                      }`}
                      alt=""
                    />
                  </div>
                  <div className="dashboard-company__info">
                    <div
                      className="dashboard-company__title"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {this.state.user.organisation.company_name}{" "}
                    </div>
                    <div className="dashboard-company__subtitle">
                      <br />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="employer-stats">
            <div className="stats">
              <div className="stats__item stats__item--blue">
                <div className="stats__item-title">
                  {this.state.monthlyCredits}
                </div>
                <div className="stats__item-subtitle">
                  Credits
                  <br />
                  Remaining
                </div>
              </div>
              <div className="stats__item stats__item--red">
                <div className="stats__item-title">
                  {this.state.credits_used_this_week}
                </div>
                <div className="stats__item-subtitle">
                  Credits used
                  <br />
                  this week
                </div>
              </div>
              <div className="stats__item stats__item--green">
                <div className="stats__item-title">
                  {this.state.creditsSpentThisMonth}
                </div>
                <div className="stats__item-subtitle">
                  Credits used <br />
                  this month
                </div>
              </div>
              <div className="stats__item stats__item--lightOrange">
                <div className="stats__item-title">
                  {this.state.total_hours_this_month}
                </div>
                <div className="stats__item-subtitle">
                  Hours booked
                  <br />
                  this month
                </div>
              </div>
            </div>
          </div>

          <EmployerDashboardMap
            allBooking={this.state.AllBooking}
            markers={this.state.mapMarkers}
            bookingDetail={this.state.bookingDetail}
            token={this.state.token}
          />

          <div className="stats-chart__wrapper">
            <h3>How Is My Team Working?</h3>
            <div
              className="row utilization-chart__wrapper"
              style={{
                display: "flex",
                margin: 15,
                minHeight: "0px"
              }}
            >
              <div className="col-md-4">
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <label
                    style={{
                      fontWeight: 500,
                      display: "flex",
                      fontSize: 12
                    }}
                  >
                    Start Date:
                  </label>
                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      marginLeft: 1,
                      width: "100%"
                    }}
                  >
                    <DatePicker
                      onChange={e =>
                        this.setState({
                          startDate: e,
                          submitted: false
                        })
                      }
                      selected={this.state.startDate}
                      className="empDateField"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <label
                    style={{
                      fontWeight: 500,
                      display: "flex",
                      fontSize: 12
                    }}
                  >
                    End Date:
                  </label>

                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      marginLeft: 1,
                      width: "100%"
                    }}
                  >
                    <DatePicker
                      onChange={e =>
                        this.setState({
                          endDate: e,
                          submitted: false
                        })
                      }
                      selected={this.state.endDate}
                      className="empDateField"
                      style={{ width: " 17rem" }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-md-2"
                style={{ marginTop: "1.4rem" }}
              >
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <button
                    type="submit"
                    className={`btn2 btn-blue`}
                    onClick={() => {
                      this.handleSubmit();
                    }}
                    style={{ width: "100%" }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div
                className="col-md-2"
                style={{ marginTop: "1.4rem" }}
              >
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <button
                    type="submit"
                    className={`btn2 btn-blue`}
                    onClick={this.clearFilter}
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
              <span
                style={{
                  marginTop: 0,
                  marginLeft: "20px",
                  color: "red"
                }}
              >
                {this.state.dateError}
              </span>
            </div>
            {this.state.pieData &&
            !this.state.pieData.total_duration.all_zero ? (
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "100%"
                }}
              >
                <h3 style={{ marginTop: "3rem" }}>
                  Working patterns
                </h3>

                <div className="col-lg-4">
                  <div className="stats-chart__item">
                    <div className="stats-chart__item-title">
                      Booking Group Sizes
                    </div>
                    <div className="stats-chart__item-chart">
                      {this.state.pieData ? (
                        <BookingGroupSizePie_Emp
                          pie={this.state.pieData}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="stats-chart__item">
                    <div className="stats-chart__item-title">
                      BOOKING DURATION
                    </div>
                    <div className="stats-chart__item-chart">
                      {this.state.pieData ? (
                        <BookingDuration_Employer
                          pie={this.state.pieData}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : !this.state.workspacesData &&
              !this.state.venueData ? (
              <div className="pieDataMsg">
                There are no bookings in the selected date range
              </div>
            ) : null}
            {this.state.workspacesData && this.state.venueData ? (
              <div
                className="row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "100%"
                }}
              >
                {this.state.pieData &&
                !this.state.pieData.total_duration.all_zero ? null : (
                  <h3 style={{ marginTop: "3rem" }}>
                    Working patterns
                  </h3>
                )}

                <div className="col-lg-4">
                  <div className="stats-chart__item">
                    <div className="stats-chart__item-title">
                      BOOKINGS AT DIFFERENT VENUE TYPES
                    </div>
                    <div className="stats-chart__item-chart">
                      {this.state.venueData ? (
                        <VenueBookingsPie_Emp
                          pie={this.state.venueData}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="stats-chart__item">
                    <div className="stats-chart__item-title">
                      BOOKINGS AT DIFFERENT CREDIT RANGES
                    </div>
                    <div className="stats-chart__item-chart">
                      {this.state.venueData ? (
                        <SpaceBookingsPie_Emp
                          pie={this.state.venueData}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="container">
            <h3>Peak Utilisation</h3>

            <div className="utilization-chart__wrapper">
              <WeeklyUtilization
                myTeamBookings={this.props.myTeamBookings}
                submitted={this.state.submitted}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
              ></WeeklyUtilization>
            </div>
          </div>

          <div className="container" style={{ marginTop: "1rem" }}>
            <div className="utilization-chart__wrapper">
              <BookingLocation_HeatMap
                data={this.state.heatMapData}
                allHeatMapData={this.state.allHeatMapData}
              />
            </div>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <h3>What Is My Team's Dorpee Usage Last Month?</h3>
            <div
              className="employer-pricing"
              style={{ marginTop: 0 }}
            >
              {this.state.joinedlastMonth ? (
                <>
                  <div className="employer-pricing__item">
                    <div className="employer-pricing__item-title">
                      AVERAGE DORPEE HOURS
                      <br />
                      PER EMPLOYEE
                    </div>
                    <div className="employer-pricing__item-subtitle">
                      {this.state.employer_hours_per_month}
                    </div>
                  </div>
                  <div className="employer-pricing__item">
                    <div className="employer-pricing__item-title">
                      ESTIMATED DORPEE COST <br />
                      PER EMPLOYEE
                    </div>
                    <div className="employer-pricing__item-subtitle">
                      ${this.state.employer_cost_per_month}
                    </div>
                  </div>

                  <div className="employer-pricing__item">
                    <div className="employer-pricing__item-title">
                      COLLOCATION
                      <br />
                      INDEX
                    </div>
                    <div className="employer-pricing__item-subtitle">
                      {isNaN(this.state.indexCol)
                        ? 0
                        : this.state.indexCol}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center"
                    }}
                  >
                    <div
                      className="employer-pricing__item"
                      style={{ minHeight: "3rem" }}
                    >
                      <div
                        className="employer-pricing__item-title"
                        style={{ minHeight: "3rem" }}
                      >
                        AVERAGE DORPEE HOURS
                        <br />
                        PER EMPLOYEE
                      </div>
                    </div>

                    <div
                      className="employer-pricing__item"
                      style={{ minHeight: "3rem" }}
                    >
                      <div
                        className="employer-pricing__item-title"
                        style={{ minHeight: "3rem" }}
                      >
                        ESTIMATED DORPEE COST <br />
                        PER EMPLOYEE
                      </div>
                    </div>

                    <div
                      className="employer-pricing__item"
                      style={{ minHeight: "3rem" }}
                    >
                      <div
                        className="employer-pricing__item-title"
                        style={{ minHeight: "3rem" }}
                      >
                        COLLOCATION
                        <br />
                        INDEX
                      </div>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      padding: "1rem",
                      color: "white"
                    }}
                  >
                    We're busy collecting booking data - check back
                    next month to see the hours your team worked from
                    Dorpee workspaces, the cost of having each
                    employee on Dorpee and how many of your team's
                    bookings were collaborative vs solo sessions for
                    the previous month
                  </p>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: "3rem", marginBottom: "2rem" }}>
            <h3>Cost Overview</h3>
            <div className="utilization-chart__wrapper">
              <CostOverview chartData={this.state.costData} />
            </div>
          </div>
        </div>

        {this.state.showAddEmployerModal ? (
          <AddEmployer
            addEmployer={this.addEmployer}
            showHideModal={this.showHideModal}
            {...this.state}
          />
        ) : null}
        {this.state.showEditEmployerModal ? (
          <SetCredits
            updateEmployer={this.updateEmployer}
            showHideModal={this.showHideModal}
            customerId={this.state.editedCustomerID}
          />
        ) : null}
        {this.state.showConfirmationModal ? (
          <Confirmation
            acceptText={"Yes"}
            message={"Are you sure you want to delete this employee?"}
            declineText={"No"}
            accept={this.removeEmployer}
            decline={this.showHideModal.bind(
              this,
              "confirmation",
              false
            )}
            modalHeader={this.state.modalHeader}
            showButtons={true}
          />
        ) : null}
        {this.state.isModalOpen ? (
          <AddEmployeeCostModal
            handleToggleModal={this.handleToggleModal}
          ></AddEmployeeCostModal>
        ) : null}
        {/* {this.state.isEditModalOpen ? (
          <EditEmployerModal
            handleToggleModal={this.handleToggleEditModal}
          ></EditEmployerModal>
        ) : null} */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.venueWizard.publicVenues,
  user: state.auth.user,
  isLoading: state.Loader.isLoading,
  plans: state.wizard.plans,
  myTeamBookings: state.Booking.MyteamBooking,
  registered_date: state.auth.user.registered_date
});

const mapDispatchToProps = {
  getPublicVenuesEffect,
  getPlansEffect,
  getEmplpoyerCustomerBookings,
  updateEmployeeRequirements
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerDashboard);
