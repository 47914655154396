/* tslint:disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { getTransactionsEffect } from "../Auth/Actions";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Helmet } from "react-helmet";

interface Props {
  userTransaction: any[];
  getTransactionsEffect: () => void;
}
interface myState {
  defaultValues: any[];
}
class Transactions extends Component<Props, myState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      defaultValues: [{}]
    };
  }
  componentDidMount() {
    this.props.getTransactionsEffect();
  }
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Transactions</title>
        </Helmet>
        <div className="container">
          <div className="booking_table_margins">
            <div className="approval__header-title ">
              <div className="dashboard__title headerTable">
                Transactions
              </div>
            </div>
            <div className="team-list ">
              <table className="team-list-table table-style">
                <thead>
                  <tr>
                    <th className="table-head">Date</th>
                    <th className="table-head">
                      Type (Top Up Credits or Plan Renewal)
                    </th>
                    <th className="table-head">Credits </th>
                    <th className="table-head">Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.userTransaction &&
                    this.props.userTransaction.map((item: any) => (
                      <tr style={{ height: 50 }}>
                        <td className="table-style">
                          {item.created_at
                            .split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("-")}
                        </td>
                        <td className="table-style">{item.type}</td>
                        <td className="table-style">
                          {item.credits}
                        </td>
                        <td className="table-style">{item.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.Loader.isLoading,
  userTransaction: state.auth.userTransaction
});

const mapDispatchToProps = {
  getTransactionsEffect
};
export default //  withRouter(
connect(mapStateToProps, mapDispatchToProps)(Transactions);
// );
