import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BookingVenue from "./BookingVenue";
import { useDispatch, useSelector } from "react-redux";
import store, { AppState } from "../../store";
import { getPublicVenuesEffect } from "../VenueWizard/Actions";
import {
  createBookingEffect,
  EditBookingEffect
} from "../Book/Booking/Actions";
import { toast } from "react-toastify";
import {
  getMonth,
  getDate,
  getYear
} from "../../helpers/DateHelpers";
import ModalComponent from "../Dashboard/modals/AddCreditModal";
import EmployerBookingModal from "../Dashboard/modals/EmployerBookingModal";
import moment from "moment";
import VenueSteps from "../VenueWizard/VenueSteps";
import Footer from "../../footer";
import { Helmet } from "react-helmet";
import { DashboardApi } from "../../API/DashboardApi";
import EmployeeLimitModal from "../Dashboard/modals/EmployeeLimitModal";
import { setBookingVenueLoader, setLoader } from "../Loader/Actions";
import AddCrediCardModal from "../Dashboard/modals/AddCreditCardModal";
// import useDebounce from "../../hooks/useDebounce";

const BookStep3 = ({
  venueId,
  spaceId
}: {
  venueId: string;
  spaceId: any;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user);
  const cards = useSelector(
    (state: AppState) => state.dashboard.customerAllCard
  );
  const venue = useSelector(
    (state: AppState) => state.venueWizard.publicVenues
  ).find(v => v.id.toString() === venueId);
  const space =
    venue && venue.spaces.find(space => space.id === Number(spaceId));
  const booking = useSelector(
    (state: AppState) => state.Booking.BookingFields
  );
  const employer = useSelector(
    (state: AppState) => state.Booking.myEmployers
  );
  let [bookFlag, setBookFlag] = useState(false);
  const [isOpen, setModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [credit, setUserCredit] = useState(
    user && user!.total_credits
  );
  const contactId = useSelector(
    (state: AppState) => state.Contacts.contactIds
  );
  const myEmployeeContacts = useSelector(
    (state: AppState) => state.Contacts.invited_employees
  );
  const teamId = useSelector(
    (state: AppState) => state.Contacts.teamIds
  );
  const [bookAsName, setBookAsName] = useState(
    booking && booking.booking_as
  );
  const [isOpenEmployer, setisOpenEmployer] = useState(false);
  const [employerData, setemployerData] = useState(
    employer.find(emp => emp.id === booking!.employer_id)
  );
  const [isChangedToPersonal, setisChangedToPersonal] =
    useState(true);
  let [special_requests, setSpecialRequest] = useState("");
  let [limit_error, setLimitError] = useState("");
  let [limit_modal, setLimitModal] = useState(false);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  const bookingLoader = useSelector(
    (state: AppState) => state.Loader.isLoadingBooking_Venue
  );
  const [bookAsPersonal, setbookAsPersonal] = useState(false);

  // const handleBooking = useDebounce(() => {
  //   submitBooking();
  // }, 100);
  booking.teams = teamId;
  booking.contacts = contactId;
  booking.invited_employees = myEmployeeContacts;
  useEffect(() => {
    if (booking && booking._method == "PUT") {
      let total =
        user && user.total_credits + booking.previousCredits;
      if (total < booking.credits) {
        setBookFlag(true);
        if (cards && cards.last4) {
          toast.error(
            "You do not have enough credits for this booking, Please Update Payment Method"
          );
          setModalOpen(true);
        } else {
          toast.error(
            "Please add new payment method to continue with booking."
          );
        }
      } else {
        setBookFlag(false);
      }
    } else if (booking && booking.booking_as === "customer") {
      setisChangedToPersonal(true);
      if (user && user.total_credits < booking.previousCredits) {
        setBookFlag(true);
        if (cards && cards.last4) {
          toast.error(
            "You do not have enough credits for this booking, Please Update Payment Method"
          );
          setModalOpen(true);
        } else {
          toast.error(
            "Please add new payment method to continue with booking."
          );
        }
      } else {
        setBookFlag(false);
      }
    } else {
      const myEmployers = employer.find(
        emp => emp.id === booking!.employer_id
      );

      if (
        myEmployers &&
        myEmployers!!.employer_credits!! < booking.credits
      ) {
        setisChangedToPersonal(false);
        setisOpenEmployer(true);
      }
    }
    dispatch(getPublicVenuesEffect());
  }, []);
  useEffect(() => {
    if (booking && booking._method == "PUT") {
      user &&
        setUserCredit(user!.total_credits + booking.previousCredits);
    } else {
      user && setUserCredit(user!.total_credits);
    }
  }, [user, user?.total_credits!]);

  useEffect(() => {
    const myEmployers = employer.find(
      emp => emp.id === booking!.employer_id
    );
    if (myEmployers) setemployerData(myEmployers);
  }, [booking]);
  useEffect(() => {
    setModalOpen(false);
    setIsPaymentModalOpen(false);
  }, [user?.last4]);

  const togglePersonalBooking = (value: any) => {
    setisOpenEmployer(value);
    booking.booking_as = "customer";
    delete booking["employer_id"];
    setisChangedToPersonal(false);
  };
  const toggleModal = (value: boolean, credit2: any) => {
    // var sumCredit = parseInt(credit2) + credit;
    // setUserCredit(sumCredit);
    setModalOpen(value);
    setBookFlag(false);
  };
  const toggleBookModal = () => {
    setisOpenEmployer(false);
  };
  const submitBooking = async () => {
    dispatch(setBookingVenueLoader(true));
    const final_booking: any = {
      ...booking,
      special_requests
    };
    if (booking.booking_as == "employer") {
      const token: any = store.getState();
      let req: any = {
        credits: booking.credits,
        organisation_id: booking.employer_id,
        hourly_credits: booking.credits_per_hour
          ? booking.credits_per_hour
          : 0
      };
      if (booking._method === "PUT") {
        req["booking_id"] = window.localStorage.getItem("booking_Id");
      }

      const response = await DashboardApi.validateEmployeeSpendings(
        token.auth.token,
        req
      );
      if (response.valid == false) {
        if (response.hourly == false) {
          setLimitError(
            `You have reached your hourly credit limit with ${
              employerData!.company_name
            }. Please reach out to your Employer Admin if you require more credits.`
          );
          setLimitModal(true);
        }
        if (response.monthly == false) {
          setLimitError(
            `You have reached your monthly credit limit with ${
              employerData!.company_name
            }. Please reach out to your Employer Admin if you require more credits.`
          );
          setLimitModal(true);
        }
      } else {
        if (booking._method === "PUT") {
          booking._method = "PUT";
          dispatch(
            EditBookingEffect(
              final_booking,
              history,
              true,
              window.localStorage.getItem("booking_Id")
            )
          );
        } else {
          dispatch(createBookingEffect(final_booking, history, true));
        }
      }
    } else {
      if (booking._method === "PUT") {
        booking._method = "PUT";
        dispatch(
          EditBookingEffect(
            final_booking,
            history,
            true,
            window.localStorage.getItem("booking_Id")
          )
        );
      } else {
        dispatch(createBookingEffect(final_booking, history, true));
      }
    }
  };

  const SpecialRequest = (event: any) => {
    setSpecialRequest(event.target.value);
  };
  const showHideModal = () => {
    setIsPaymentModalOpen(false);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          title="Dorpee Venue Bookings"
          content="Dorpee-workspaces on demand"
        />
        <title>
          {`Dorpee - ${venue && venue.name} - ${
            space && space.name
          } - 3`}
        </title>
      </Helmet>
      <div className="body">
        <div className="container containerAddSpace">
          <VenueSteps step={3} isForBooking></VenueSteps>
          <br />
          <div className="page-wrapper">
            {booking && booking._method === "PUT" ? (
              <p
                style={{
                  color: "#009cdc",
                  marginLeft: "0px",
                  fontSize: "20px",
                  fontWeight: 400,
                  marginBottom: "10px"
                }}
              >
                {" "}
                Update Booking
              </p>
            ) : (
              ""
            )}
            <BookingVenue venue={space} isLastStep />
            <div>
              <div className="booking-search__tags-title">
                YOUR ESTIMATED CHARGE
              </div>
              <div className="bookings-process__credits">
                <div className="bookings-process__credits-title">
                  {booking && booking.credits}
                </div>
                <div className="bookings-process__credits-subtitle">
                  CREDITS
                </div>
              </div>
              {bookAsName !== "customer" ? (
                <div className="bookings-process__credits-available2">
                  <span>
                    Charge to :{" "}
                    {employerData && employerData.company_name}
                  </span>
                </div>
              ) : (
                ""
              )}
              {bookAsName === "customer" ||
              booking.booking_as === "customer" ? (
                <div>
                  <div className="bookings-process__credits-available">
                    <span>Available Credits</span>
                    <strong>{credit}</strong>
                  </div>
                  {bookAsName === "customer" ? (
                    <div className="bookings-process__credits-available2">
                      <span>Your next bill date is: </span>{" "}
                      <p>
                        &nbsp;
                        {user &&
                          user.is_subscribed &&
                          getMonth(
                            moment(
                              user.plan_expiry,
                              "DD-MM-YYYY"
                            ).format("MM/DD/YYYY")
                          )}{" "}
                        {user &&
                          user.is_subscribed &&
                          getDate(
                            moment(
                              user.plan_expiry,
                              "DD-MM-YYYY"
                            ).format("MM/DD/YYYY")
                          )}
                        {user && user.is_subscribed && ", "}
                        {user &&
                          user.is_subscribed &&
                          getYear(
                            moment(
                              user.plan_expiry,
                              "DD-MM-YYYY"
                            ).format("MM/DD/YYYY")
                          )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {bookAsName === "customer" ||
              booking.booking_as === "customer" ? (
                <div>
                  {" "}
                  {user && user.total_credits < booking.credits && (
                    <div className="filter-block">
                      <button
                        onClick={() => {
                          if (user && user.last4) setModalOpen(true);
                          else {
                            setIsPaymentModalOpen(true);
                          }
                        }}
                        className="btn btn-blue wizard__btn mb-0"
                      >
                        ADD CREDITS
                        <img
                          className="wizard__btn--img-left"
                          src="/img/icons/coins-dark-blue.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="booking-search__tags-title"
              style={{ marginTop: "20px" }}
            >
              Special Requests
            </div>
            <div className="input-container">
              <input
                onChange={e => SpecialRequest(e)}
                type="text"
                id="specialRequest"
                placeholder="Enter any Special Requests here .."
                value={special_requests}
                style={{ paddingLeft: "10px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 25,
                marginBottom: 60
              }}
            >
              <button
                onClick={() =>
                  history.push(`/book/2/${venueId}/${spaceId}`)
                }
                className={`btn btn-white wizard__btn bookings-process__btn`}
                style={{ width: "45%" }}
              >
                Back
              </button>
              {isOpenEmployer ? (
                <EmployerBookingModal
                  handleBook={() => {
                    setbookAsPersonal(true);
                  }}
                  openModal={isOpenEmployer}
                  handlePersonalBooking={togglePersonalBooking}
                  handleToggleModal={toggleBookModal}
                />
              ) : null}
              {!isChangedToPersonal ? (
                <button
                  onClick={() => {
                    if (booking.booking_as !== "customer") {
                      setisOpenEmployer(true);
                    } else {
                      if (booking._method === "PUT") {
                        booking._method = "PUT";
                        dispatch(
                          EditBookingEffect(
                            booking,
                            history,
                            true,
                            window.localStorage.getItem("booking_Id")
                          )
                        );
                      } else {
                        dispatch(
                          createBookingEffect(booking, history, false)
                        );
                      }
                    }
                  }}
                  className={
                    bookFlag
                      ? "btn bookings-process__btn btn-disable"
                      : `btn bookings-process__btn ${
                          isLoading || bookingLoader
                            ? `btn--loadingNew`
                            : `btn-blue2`
                        }`
                  }
                  style={{ width: "45%" }}
                  disabled={isLoading || bookingLoader || bookFlag}
                >
                  BOOK NOW
                </button>
              ) : (
                <button
                  disabled={isLoading || bookingLoader || bookFlag}
                  onClick={submitBooking}
                  className={
                    bookFlag
                      ? "btn bookings-process__btn btn-disable"
                      : `btn bookings-process__btn ${
                          isLoading || bookingLoader
                            ? `btn--loadingNew`
                            : `btn-blue2`
                        }`
                  }
                  style={{ width: "45%" }}
                >
                  {booking && booking._method === "PUT"
                    ? "Update Booking"
                    : "BOOK NOW"}
                </button>
              )}
              {isOpen ? (
                <ModalComponent
                  history={history}
                  openModal={isOpen}
                  handleToggleModal={toggleModal}
                  isBooking
                />
              ) : null}
              {isPaymentModalOpen ? (
                <AddCrediCardModal
                  showHideModal={showHideModal}
                  isHide={true}
                ></AddCrediCardModal>
              ) : null}
              {limit_modal ? (
                <EmployeeLimitModal
                  message={limit_error}
                  handleClose={setLimitModal}
                />
              ) : null}
            </div>
          </div>
        </div>
        <Footer
        //  isSubs
        />
      </div>
    </>
  );
};

export default BookStep3;
