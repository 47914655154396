import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import _ from "lodash";

import { AppState } from "../store";
import {
  getTypesEffect,
  getVenueEffect
} from "./VenueWizard/Actions";
import { Service } from "../API/EnumsApi";
import { Venue as VenueDto } from "../API/VenueApi";
import { locationUrl2 } from "../common/googleMaps";
import { getFormated12hTimeNew } from "../../src/helpers/DateHelpers";
import WorkspacesItem from "./VenueWizard/Component/WorkspacesItem";
import ViewMoreButton from "./VenueWizard/Component/ViewMoreButton";
import LoaderModal from "../Screens/Dashboard/modals/Loader";

import ShowRatingsModal from "./Dashboard/modals/ShowRatingsModal";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-image-lightbox/style.css";
import "../Screens/venue-style.css";
interface Props {
  venueId?: string;
}
const VenueDetailPage: FC<Props> = venueId => {
  const params = useParams<any>();
  const { state: fromSearch }: any = useLocation();
  const dispatch = useDispatch();
  const venue = useSelector(
    (state: AppState) => state.venueWizard.venue
  );
  const searchParams = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState<any>([]);
  const venueTypes = useSelector(
    (state: AppState) => state.venueWizard.types
  );
  const [venueType, setVenueType] = useState<string>("");
  const history = useHistory();
  const [openHours, setOpenHours] = useState<any>([]);
  const [index, setIndex] = useState(
    venue && venue.services.length > 5
      ? 5
      : venue && venue.services.length
  );
  const [isCollapse, setCollapse] = useState(true);
  const [searchDate, setSearchDate] = useState(0);
  const [showMoreLess, setMoreStyle] = useState(false);
  const [showButton, ShowMoreButton] = useState(true);
  const [iscloseModal, setcloseModal] = useState(false);
  const user = useSelector((state: AppState) => state.auth.user);
  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  interface Filters {
    capacity?: number;
    start_time?: any;
    end_time?: any;
    date?: any;
  }
  useEffect(() => {
    setIndex(
      venue && venue.services.length > 5
        ? 5
        : venue && venue.services.length
    );

    if (venue && venue.search_by_id) {
      if (venue.slug) {
        history.replace(`/workspace/${venue.slug}`);
      }
    }
  }, [venue]);

  useEffect(() => {
    if (searchParams.date) {
      setSearchDate(searchParams.date.getDay() - 1);
    } else {
      setSearchDate(new Date().getDay() - 1);
    }
  }, [searchParams, openHours]);
  useEffect(() => {
    if (!window.location.pathname.split("/hidden/")[1]) {
      const { capacity, start_time, end_time, date }: any =
        history.location.state ?? {};
      dispatch(
        getVenueEffect(
          params.id,
          capacity,
          start_time,
          end_time,
          date
        )
      );
    } else {
      dispatch(
        getVenueEffect(window.location.pathname.split("/hidden/")[1])
      );
    }
  }, [
    dispatch,
    params.id,
    // venueId,
    window.location.pathname.split("/hidden/")[1]
  ]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  useEffect(() => {
    if (venue) {
      let doc = document.getElementById(venue!!.id.toString());
      let line = Number(doc && doc.offsetHeight);
      if (line > 192) {
        ShowMoreButton(true);
      } else ShowMoreButton(false);
    }
  }, [venue]);
  useEffect(() => {
    dispatch(getTypesEffect());
  }, []);
  useEffect(() => {
    const grouped = _.mapValues(
      _.groupBy(venue && venue.services, "category"),
      asset => asset.map(ast => _.omit(ast, "category"))
    );
    setServices(grouped);
    window.scrollTo(0, 0);
  }, [venue]);
  useEffect(() => {
    if (!window.location.pathname.split("/hidden/")[1]) {
      if (venue) {
        if (user && user.id != venue.user_id) {
          if (
            venue.status == "Unpublished" ||
            venue.status == "Closed"
          ) {
            toast.warn("Venue Is Temporarily Unavailable");
            history.push("/workspaces");
          }
        } else if (!user) {
          if (
            venue.status == "Unpublished" ||
            venue.status == "Closed"
          ) {
            toast.warn("Venue Is Temporarily Unavailable");
            history.push("/workspaces");
          }
        }
      }
    }
  }, [venue, window.location.pathname.split("/hidden/")[1]]);
  useEffect(() => {
    const type = venueTypes.find(x => x.id == venue?.venue_type_id);
    if (type) setVenueType(type.type);
  }, [venueTypes]);
  const formatAddress = (venue: VenueDto) => {
    return [venue.address, venue.suburb, venue.postcode, venue.state]
      .filter(v => !!v)
      .join(" ");
  };

  useEffect(() => {
    var list: any = [];
    week.map(day => {
      var isFound =
        venue &&
        venue!?.availability?.availability!?.find((x: any) => {
          return x.day.toLowerCase() == day.toLowerCase();
        });
      if (isFound) {
        list.push({
          day: isFound.day,
          openingTime: isFound.openingTime,
          closingTime: isFound.closingTime,
          status: true
        });
      } else {
        list.push({
          day: day,
          openingTime: "",
          closingTime: "",
          status: false
        });
      }
    });
    setOpenHours(list);
  }, [venue]);

  const showDialog = (venue: any) => {
    setcloseModal(true);
  };
  const closeModal = () => {
    setcloseModal(false);
  };

  return (
    <>
      <div
        className="page-wrapper"
        style={{ boxShadow: " 0px 0px 0px", borderRadius: "0px" }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={venue?.description} />
          <title> {`Dorpee - ${venue?.name}`}</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-workspace">
              <div className="bl-all-workspace">
                {fromSearch && fromSearch === true ? (
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/search-results",
                        state: false
                      })
                    }
                    className={`btn btn-white wizard__btn bookings-process__btn-s`}
                  >
                    Back to search results
                  </button>
                ) : null}
                <h1>{venue && venue.name} Workspaces</h1>
                <ul>
                  {venue &&
                    venue.spaces.map(s => {
                      if (s.status != "Closed") {
                        return (
                          <WorkspacesItem
                            key={s.id}
                            workspace={s}
                            status={venue.status}
                            venueName={venue.name}
                            vUserId={venue.user_id}
                            vaccinationStatus={
                              venue.vaccination_status
                            }
                          />
                        );
                      }
                    })}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-ads">
              <div className="bl-ads-workspace">
                <div
                  className="title"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <p
                    style={{
                      margin: 0,
                      marginBottom: "-3px",
                      lineHeight: "normal"
                    }}
                  >
                    {venue && venue.name}
                  </p>
                  <div style={{ marginLeft: 3, marginTop: -3 }}>
                    {venue && venue.ohs_only ? (
                      venue && venue.ohs_only === "yes" ? (
                        <img
                          src="/img/shield-check-regular.svg"
                          alt={"OH&S Self Assessment"}
                          title={`OH&S Self Assessment was completed by on the ${
                            venue &&
                            venue?.assessment!?.completed_date!
                          }`}
                          style={{
                            paddingLeft: "2px",
                            width: "100%",
                            lineHeight: 1.5
                          }}
                        ></img>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <p
                  style={{
                    fontWeight: 400,
                    margin: "0px 0px 10px",
                    lineHeight: 1.5
                  }}
                >
                  {venue && venue.venue_type.type!}
                </p>
                <div
                  className="address"
                  style={{ marginTop: "-4px" }}
                >
                  <div
                    className="form-control"
                    style={{
                      marginBottom: "10px",
                      border: "0px solid",
                      paddingBottom: 0,
                      lineHeight: 1.5,
                      height: "auto",
                      paddingTop: 0
                    }}
                  >
                    {venue && (
                      <a
                        className="venue-address__link"
                        target="_blank"
                        href={locationUrl2(
                          venue.address,
                          venue.suburb,
                          venue.state,
                          venue.postcode
                        )}
                      >
                        <span style={{ fontSize: 15 }}>
                          {venue && formatAddress(venue)}
                        </span>
                      </a>
                    )}
                  </div>
                </div>
                {venue && venue.images ? (
                  <Carousel
                    dynamicHeight={true}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {venue.images &&
                      venue.images.map((imageUrl, index) => (
                        <div
                          key={index}
                          className="thumbnail"
                          style={{ height: 402 }}
                        >
                          <img
                            src={imageUrl}
                            alt={`${venue.name} ${index}`}
                          />
                        </div>
                      ))}
                  </Carousel>
                ) : null}
                <div className="venue-rating">
                  <div className="venue-rating__left">
                    {Number(venue?.average_rating) > 0
                      ? Array.from(
                          Array(
                            Number(venue?.average_rating) === 0
                              ? 1
                              : Math.trunc(venue?.average_rating!)
                          ),
                          (e, i) => {
                            return venue?.average_rating! > 3 ? (
                              <a
                                key={i}
                                className="rating-star"
                                style={{ cursor: "pointer" }}
                                onClick={e => {
                                  showDialog(venue);
                                }}
                              ></a>
                            ) : (
                              <a
                                key={i}
                                onClick={e => {
                                  showDialog(venue);
                                }}
                                className="rating-star"
                              ></a>
                            );
                          }
                        )
                      : null}

                    {venue?.average_rating! > 0 ? (
                      <span
                        className="rating-views"
                        onClick={e => {
                          showDialog(venue);
                        }}
                      >
                        {venue?.average_rating}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div
                  className="md-widget-sidebar"
                  style={{ marginTop: "0px" }}
                >
                  {venue && venue.bonus_offer ? (
                    <div className="venue-bonus-text">
                      <p>{venue && venue.bonus_offer}</p>
                    </div>
                  ) : null}
                  <div
                    className="sub-title"
                    style={{ marginTop: 40 }}
                  >
                    <p>About The Venue</p>
                  </div>

                  {venue && venue.description && (
                    <ViewMoreButton
                      id="venue"
                      description={venue.description}
                    />
                  )}
                  {venue &&
                  venue.vaccination_status ==
                    "Partially Vaccinated" ? (
                    <p
                      style={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                        paddingTop: "10px",
                        margin: 0
                      }}
                    >
                      This venue can only accept Dorpee guests that
                      are at least partially vaccinated.
                    </p>
                  ) : venue &&
                    venue.vaccination_status == "Fully Vaccinated" ? (
                    <p
                      style={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                        paddingTop: "10px",
                        margin: 0
                      }}
                    >
                      This venue can only accept Dorpee guests that
                      are fully vaccinated.
                    </p>
                  ) : (
                    ""
                  )}
                  {venue &&
                  venue.ohs_only == "yes" &&
                  venue.assessment &&
                  venue.assessment.result === "Pass" ? (
                    <p
                      style={{
                        overflow: "hidden",
                        whiteSpace: "normal",
                        paddingTop: "5px"
                      }}
                    >
                      This venue meets Occupational Health and Safety
                      requirements.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="md-widget-sidebar">
                  <div className="sub-title">
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center"
                      }}
                    >
                      Lead Time:
                      <span
                        style={{
                          textTransform: "none",
                          fontWeight: "normal",
                          whiteSpace: "normal",
                          paddingLeft: "5px"
                        }}
                      >
                        {venue && venue.lead_time
                          ? venue && venue.lead_time == 0
                            ? ` ${venue.lead_time} No lead time`
                            : venue.lead_time > 1
                            ? ` ${venue.lead_time} Hours`
                            : ` ${venue.lead_time} Hour`
                          : ` No lead time`}
                      </span>
                      <div
                        className="col-md-1 tooltip"
                        style={{
                          border: "none",
                          maxWidth: "20%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "20px",
                            color: "red"
                          }}
                          src="/img/info.png"
                        />
                        <span
                          className="tooltiptext"
                          style={{
                            textTransform: "none",
                            width: "20rem"
                          }}
                        >
                          Lead time is the amount of time required by
                          venues to prepare for your booking.
                        </span>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="md-widget-sidebar">
                  <div className="sub-title">
                    <p>OPEN HOURS</p>
                  </div>
                  <div className="open-hours">
                    <ul>
                      {openHours.map((item: any, index: any) => {
                        return (
                          <li className="clearfix" key={index}>
                            <span className="is-title">
                              {item.day.toUpperCase()}
                            </span>
                            {item.status ? (
                              <span className="hour">
                                {getFormated12hTimeNew(
                                  item.openingTime
                                )}{" "}
                                -{" "}
                                {getFormated12hTimeNew(
                                  item.closingTime
                                )}
                              </span>
                            ) : (
                              <span className="hour">Not Available</span>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div
                  className="md-widget-sidebar"
                  style={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    height: showMoreLess ? "100%" : "8.5rem"
                  }}
                >
                  <div
                    className="sub-title"
                    style={{ display: "flex", cursor: "pointer" }}
                  >
                    Amenities
                  </div>

                  {venue && venue.services && (
                    <ul
                      className="smd-tag amenitiesStyle"
                      style={{ margin: 0, padding: 0 }}
                      id={venue.id.toString()}
                    >
                      {venue &&
                        venue.services.map(
                          (item: Service, index: any) => {
                            if (item.status === "Active") {
                              return (
                                <li key={index}>{item.service}</li>
                              );
                            }
                          }
                        )}
                    </ul>
                  )}
                </div>

                {showButton ? (
                  <div
                    className="loadmore"
                    onClick={() => {
                      setMoreStyle(!showMoreLess);
                    }}
                  >
                    {showMoreLess ? "Load less" : "Load more"}
                  </div>
                ) : null}

                {iscloseModal ? (
                  <ShowRatingsModal
                    selectedVenue={venue}
                    decline={closeModal}
                  ></ShowRatingsModal>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
};

export default VenueDetailPage;
