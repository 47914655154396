import "whatwg-fetch";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { toast } from "react-toastify";
import ReactGA from "react-ga";

import store from "./store";
import Layout from "./Layout";

import "react-toastify/dist/ReactToastify.css";
import "./sass/bootstrap4-grid.sass";
import "./sass/main.sass";

const tagManagerArgs: any = {
  gtmId: process.env.REACT_APP_GOOGLE_GTAG
};

toast.configure();
const App: React.FC = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs || "G-EXTD1L7R6W");
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL_CODE || "883866338718503"
    );
    ReactPixel.pageView();
    ReactGA.initialize("UA-160828187-1");
    ReactGA.pageview(
      window.location.pathname + window.location.search
    );
  }, []);

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};

export default App;
