import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { Carousel } from "react-responsive-carousel";
import { getBookingByIdEffect } from "../Book/Booking/Actions";
import { PostVenueRatingEffect } from "../VenueWizard/Actions";
import Stars from "./stars";
import "../../Screens/venue-workspace.sass";

const VenueRating: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const booking = useSelector((state: AppState) => state.Booking.singleBooking);
  const venue = booking && booking.space;
  const [rating, setrating] = useState(5);
  const [comment, setcomment] = useState("");
  const [commentErr, setCommentErr] = useState("");
  const [ratingStar, setratingStar] = useState();
  const [temp_rating, settemp_rating] = useState();
  const history = useHistory();
  useEffect(() => {
    dispatch(getBookingByIdEffect(id));
  }, []);

  const validate = () => {
    let isError = false;

    let errors = "";

    if (comment === "") {
      isError = true;
      errors = "Feedback is required.";
    }

    setCommentErr(errors);
    return isError;
  };

  const postRating = async () => {
    dispatch(
      PostVenueRatingEffect(
        {
          rating: rating,
          review: comment,
          booking_id: booking.id,
        },
        history
      )
    );
  };
  const handleRating = (e: any) => {
    setrating(Number(e + 1));
  };
  const handleComment = (e: any) => {
    setcomment(e.target.value);
  };
  const handleSubmit = () => {
    if (!validate()) postRating();
  };

  return (
    <>
      <div className="container">
        <div className="book-space">
          <div className="row">
            <div className="col-lg-6">
              <div className="book-space__slider-img">
                <p
                  className="dashboard__title"
                  style={{ padding: 5, marginBottom: 15 }}
                >
                  {venue && venue.venue.name}
                </p>
                <div className="slider__wrapper">
                  <Carousel
                    dynamicHeight={true}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {venue &&
                      venue.images.map((imageUrl: any) => (
                        <div className="img-preview-container">
                          <img style={{ maxWidth: "500px" }} src={imageUrl} />
                        </div>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="book-space__info-workspace">
                <div className="order-wrapper">
                  <div style={{ marginTop: 50 }}>
                    <h3>
                      Leave Feedback
                      <br />
                    </h3>
                    <div className="filter-block">
                      <div>
                        <Stars
                          handleRating={handleRating}
                          rating={rating}
                          id="stars"
                        ></Stars>
                      </div>
                    </div>
                    <div>
                      <textarea
                        id="review"
                        placeholder="Comment"
                        onChange={(e) => handleComment(e)}
                        className="rating_textarea"
                      ></textarea>
                    </div>

                    {commentErr !== "" ? (
                      <span style={{ color: "red" }}>{commentErr}</span>
                    ) : null}
                  </div>
                  <div>
                    {booking && (
                      <input
                        type="submit"
                        onClick={(e) => handleSubmit()}
                        className="btn btn-blue"
                        style={{
                          width: 150,
                        }}
                      ></input>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueRating;
