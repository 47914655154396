import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { destroy } from "redux-form";

import { AppState } from "../../store";
import {
  workspaceWizardReset,
  loadWorkspaceForEditEffect,
  getSuggestedPriceEffect
} from "./Actions";
import { getVenuesEffect } from "../VenueWizard/Actions";
import { useHistory, useParams } from "react-router-dom";
import CreateWorkspace from "./CreateWorkspace";
import PreviewWorkspace from "./PreivewWorkspace";
import SuccessMessage from "../VenueWizard/SuccessMessage";
import { WorkspaceWizardStep } from "./Reducers";

const WorkspaceWizard = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  let detailsFields = useSelector(
    (state: AppState) => state.workspaceWizard.detailsFields
  );

  const venues = useSelector(
    (state: AppState) => state.workspaceWizard.venues
  );
  const step = useSelector(
    (state: AppState) => state.workspaceWizard.step
  );
  const user = useSelector((state: AppState) => state.auth.user);
  const history = useHistory();
  useEffect(() => {
    dispatch(workspaceWizardReset());
    if (id) {
      dispatch(loadWorkspaceForEditEffect(id));
    }
    return () => {
      dispatch(workspaceWizardReset());
      dispatch(getVenuesEffect());
      dispatch(destroy("workspaceDetails"));
      dispatch(destroy("workspaceImages"));
    };
  }, []);

  useEffect(() => {
    if (detailsFields)
      dispatch(getSuggestedPriceEffect(detailsFields.venueId));
    else if (venues.length > 0)
      dispatch(getSuggestedPriceEffect(venues[0].id));
  }, [detailsFields, venues]);
  switch (step) {
    case WorkspaceWizardStep.Success:
      return <SuccessMessage workspaceId={id} isTypeStep={false} />;
    case WorkspaceWizardStep.Preview:
      return (
        <PreviewWorkspace
          btnSave={"Save as Draft"}
          btnText={"Publish Workspace"}
          history={history}
          workspaceId={id}
          isTypeStep={false}
          venueId={detailsFields && detailsFields.venueId}
        ></PreviewWorkspace>
      );
    default:
      return (
        <CreateWorkspace
          workspaceId={id}
          initialValues={detailsFields}
          venueId={window.localStorage.getItem("venue_id")}
          history={history}
        ></CreateWorkspace>
      );
  }
};

export default WorkspaceWizard;
