import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrappedFieldProps, Field } from "redux-form";
import { saveBasicSearch } from "../Screens/VenueSearch/Actions";
import { AppState } from "../store";

export type CheckBox = {
  label?: string;
  value?: string;
};

const MultiCheckboxN: FC<
  WrappedFieldProps & {
    checkboxes: CheckBox[];
    isAssets?: any;
  }
> = props => {
  const { input } = props;
  const dispatch = useDispatch();

  let values: string[] = [];
  const searchDetails = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );

  if (input.name === "services") {
    input.value =
      Object.keys(searchDetails).length === 0
        ? []
        : searchDetails.services;
  } else if (
    input.name === "assets" &&
    !window.location.pathname.includes("edit-workspace")
  ) {
    input.value =
      Object.keys(searchDetails).length === 0
        ? []
        : searchDetails.assets;
  }

  if (input.value) {
    values = input.value;
  }
  const contains = (value: string) => {
    return values.some(
      v =>
        (searchDetails &&
          Object.keys(searchDetails).length > 0 &&
          searchDetails[input.name].some((v: any) => value === v)) ||
        value === v
    );
  };
  const onClick = (value: string) => {
    const newValues = contains(value)
      ? values.filter(v => v !== value)
      : [...values, value];
    dispatch(saveBasicSearch({ [input.name]: newValues }));
    props.input.onChange(newValues);
    props.input.onBlur(newValues);
  };
  const displayError = props.meta.touched && props.meta.error;
  return (
    <React.Fragment>
      <div className="tags-section bordered-checkbox-container">
        {props.isAssets
          ? props.checkboxes.map(c => {
              if (c) {
                return (
                  <div key={c.value!} className="bordered-checkbox ">
                    <input
                      onClick={() => onClick(c.value!)}
                      type="checkbox"
                      className="form-check-input"
                      value={c.value!}
                      checked={contains(c.value!)}
                      id={`asset${c.value!}`}
                    />
                    <label
                      style={{ marginBottom: 10 }}
                      className="form-check-label"
                      htmlFor={`asset${c.value!}`}
                    >
                      {" "}
                      {c.label}
                    </label>
                  </div>
                );
              }
            })
          : props.checkboxes.map((c, index) => {
              if (c) {
                return (
                  <div key={index} className="bordered-checkbox ">
                    <input
                      onClick={() => onClick(c.value!)}
                      type="checkbox"
                      className="form-check-input"
                      value={c.value!}
                      checked={contains(c.value!)}
                      id={`asset${c.value!}`}
                    />
                    <label
                      style={{ marginBottom: 10 }}
                      className="form-check-label"
                      htmlFor={`asset${c.value!}`}
                    >
                      {" "}
                      {c.label}
                    </label>
                  </div>
                );
              }
            })}
      </div>
      {displayError && (
        <div
          style={{
            marginTop: 10,
            alignSelf: "flex-start",
            display: "flex"
          }}
          className="validation-error"
        >
          {props.meta.error}
        </div>
      )}
    </React.Fragment>
  );
};

export default MultiCheckboxN;
