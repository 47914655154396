/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React from "react";
import "./LocationPicker.sass";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Address } from "./LocationPicker";

export const formatAddress = (address: Address) => {
  return [
    address.address,
    address.suburb,
    address.postalCode,
    address.state,
    address.country,
  ]
    .filter((v) => !!v)
    .join(" ");
};

type Props = {
  onChange: (address: Address) => void;
  value: string;
  input: any;
  home?: any;
  isAutoFocus?: boolean;
  id?: any;
};

class CityPicker extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: props.value ? formatAddress(props.value) : "",
    };
    this.activeRef = React.createRef();
  }

  handleChange = (address: any) => {
    this.setState({ address: address });
    let data = {
      suburb: address,
      postalCode: "",
      lat: "",
      lng: "",
    };

    this.props.onChange(data);
  };

  handleSelect = async (address: any, placeId: any, e) => {
    this.setState({ address });
    // if (placeId) {
    const results = await geocodeByAddress(address);
    const first = results[0];
    const latlong = await getLatLng(results[0]);

    const city = first.address_components.find(
      (c) => c.types.indexOf("locality") >= 0
    );
    const postalCode = first.address_components.find(
      (c) => c.types.indexOf("postal_code") >= 0
    );
    let data = {
      suburb: city && city.long_name ? city.long_name : address,
      postalCode: postalCode && postalCode.long_name,
      lat: latlong.lat,
      lng: latlong.lng,
    };
    this.props.onChange(data);
    // } else {
    //   let data = {
    //     suburb: address,
    //     postalCode: "",
    //     lat: "",
    //     lng: "",
    //   };
    //   this.props.onChange(data);
    // }
  };
  render() {
    const { value, onChange, ...inputProps } = this.props.input;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: ["au", "nz"] },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="autocomplete-wrapper">
            <svg
              className="icon--place"
              id="ic_location_on_24px"
              xmlns="http://www.w3.org/2000/svg"
              width="13.854"
              height="17.502"
              viewBox="0 0 13.854 17.502"
            >
              <path
                id="ic_location_on_24px-2"
                data-name="ic_location_on_24px"
                d="M11.927,2A6.922,6.922,0,0,0,5,8.927c0,5.2,6.927,10.575,6.927,10.575s6.927-5.38,6.927-10.575A6.922,6.922,0,0,0,11.927,2Zm0,9.4A2.474,2.474,0,1,1,14.4,8.927,2.475,2.475,0,0,1,11.927,11.4Z"
                transform="translate(-5 -2)"
                fill="#9ba6ac"
              />
            </svg>

            <input
              autoComplete="nope"
              type="search"
              name="location"
              {...getInputProps(inputProps)}
              onKeyDown={(e) => {
                let keyDown = { ...getInputProps(inputProps) };
                keyDown.onKeyDown(e);
                const node = this.activeRef.current;
                if (node) {
                  if (e.keyCode === 13) {
                    if (node.id) {
                      keyDown.onKeyDown(e);
                    } else {
                      keyDown.onkeyDown(e);
                    }
                  }
                } else {
                  if (e.keyCode === 13) {
                    keyDown.onkeyDown(e);
                  } else {
                    keyDown.onKeyDown(e);
                  }
                }
              }}
              placeholder={this.props.home ? "Location" : "Address"}
              className="location-search-input"
              style={{ height: "32px" }}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";

                const chunks = suggestion.matchedSubstrings
                  .map((m) => m.offset)
                  .concat(
                    suggestion.matchedSubstrings.map((m) => m.offset + m.length)
                  )
                  .sort((a, b) => a - b);
                const strings = chunks.reduce(
                  (acc: string[], current, i, array) => {
                    return [
                      ...acc,
                      suggestion.formattedSuggestion.mainText.slice(
                        array[i],
                        array[i + 1]
                      ),
                    ];
                  },
                  [suggestion.formattedSuggestion.mainText.slice(0, chunks[0])]
                );
                const mainText = strings.map((s, i) =>
                  i % 2 === 1 ? (
                    <strong key={i}>{s}</strong>
                  ) : (
                    <span key={i}>{s}</span>
                  )
                );
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    ref={this.activeRef}
                  >
                    <img
                      className="autocomplete__suggestion-icon"
                      src="/img/icons/geolocation.svg"
                      alt=""
                    />
                    <span>
                      <span className="autocomplete__main-text">
                        {mainText}
                      </span>
                      &nbsp;
                      <span className="autocomplete__secondary-text">
                        {suggestion.formattedSuggestion.secondaryText}
                      </span>
                    </span>
                  </div>
                );
              })}

              {suggestions.length ? (
                <div className="autocomplete__dropdown-footer">
                  <img
                    className="autocomplete__dropdown-footer-image"
                    src="/img/powered_by_google.png"
                    alt=""
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default CityPicker;
