import React, { useEffect } from "react";
import { reduxForm, FormErrors } from "redux-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { AppState } from "../../../store";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../../CheckoutForm";
import { Modal } from "../../../UI/Modal";

export type PaymentFields = {
  price: string;
  plan_id: string;
  stripe_token: string;
};

const AddCrediCardModal = reduxForm<
  PaymentFields,
  { showHideModal: any; isHide: boolean }
>({
  form: "paymentInfo",
  destroyOnUnmount: false,
  onSubmit: (formFields, dispatch) => {},
  validate: (values, props) => {
    const errors: FormErrors<PaymentFields> = {};
    return errors;
  }
})(props => {
  let { selectedPlan, isPlanSelected } = useSelector(
    (state: AppState) => state.wizard
  );
  const user = useSelector((state: AppState) => state.auth.user!!);
  const plan = useSelector(
    (state: AppState) => state.wizard.selectedPlan
  );
  const isSubscriptionCancelled = user && user.is_subscribed;
  const history = useHistory();
  const hideContact = (flag: boolean) => {
    props.showHideModal(flag);
    window.localStorage.removeItem("addNewCard");
  };

  return (
    <Modal>
      <div className="addressbook-form-box">
        <div className="congrat-box__header">
          <div className="search-pref__title">Add Payment Method</div>{" "}
          <div
            onClick={() => hideContact(false)}
            className="close-btn"
          ></div>
        </div>
        <div
          className="addressbook-form"
          style={{ textAlign: "start" }}
        >
          {plan && plan.name === "Free" ? (
            <p style={{ color: "#31B5CE" }}>
              While you won’t be charged for your free trial, you’ll
              be automatically converted to a paid membership plan at
              the end of the trial period. You can cancel anytime from
              your dashboard.
            </p>
          ) : null}

          <div className="content-wrapper__title">
            {!props.isHide ? `Plan: ${selectedPlan.name}` : null}
          </div>
          <StripeProvider
            apiKey="pk_test_IWiLzns3KY1WzNA4x4P63wYi"
            // apiKey="pk_live_Qeta0dy9XieLn4IjYElHyFXv00BtxZH8Uu pk_test_IWiLzns3KY1WzNA4x4P63wYi "
          >
            <Elements>
              <CheckoutForm></CheckoutForm>
            </Elements>
          </StripeProvider>
        </div>
      </div>
    </Modal>
  );
});

export default AddCrediCardModal;
