import React, { FC, useEffect } from "react";
import {
  Link,
  NavLink,
  withRouter,
  Router,
  RouteComponentProps,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  reduxForm,
  Field,
  FormErrors,
  SubmissionError,
  Form
} from "redux-form";
import { logInEffect } from "./Auth/Actions";
import IconField from "../UI/IconField";
import { User } from "../API/AuthApi";
import { AppState } from "../store";
import Footer from "../footer";
import { Helmet } from "react-helmet";
const NameField = IconField("input__icon--user");
const PasswordField = IconField("input__icon--lock");

const Login: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <div className="login-bgr">
        <LoginForm
          onSubmit={async values => {
            try {
              await dispatch(
                logInEffect(
                  values.email,
                  values.password,
                  values.keepLoggedIn,
                  history
                )
              );
            } catch (error) {
              throw new SubmissionError({ _error: error.message });
            }
          }}
        />
      </div>
    </div>
  );
};

type LoginFields = {
  email: string;
  password: string;
  keepLoggedIn: boolean;
};

const LoginForm = reduxForm<LoginFields, {}>({
  form: "login",
  validate: values => {
    const errors: FormErrors<LoginFields> = {};

    // Email
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
        values.email
      )
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password =
        "Password should be at least 8 characters long";
    }
    // newPasswordValidation
    //  else {
    //       let hasUpperCase: any = /[A-Z]/.test(values.password!);
    //       let hasLowerCase: any = /[a-z]/.test(values.password!);
    //       let hasNumbers: any = /\d/.test(values.password!);
    //       let hasNonalphas: any = /\W/.test(values.password!);
    //       if (
    //         !hasUpperCase ||
    //         !hasLowerCase ||
    //         !hasNumbers ||
    //         !hasNonalphas
    //       ) {
    //         errors.password =
    //           "Min 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
    //       } else if (values.password!?.length < 8) {
    //         errors.password = "Minimum 8 Characters";
    //       }
    //     }
    return errors;
  }
})(props => {
  const history = useHistory();
  const isSuspended = useSelector(
    (state: AppState) => state.auth.isSuspended
  );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Login</title>
      </Helmet>
      <Form onSubmit={props.handleSubmit}>
        <div className="body create">
          <div className="container containerAddSpace">
            <div className="page-wrapper" style={{ marginTop: 25 }}>
              <div
                className="registerTitle"
                style={{ marginBottom: 20 }}
              >
                <h1
                  className="register-title"
                  style={{ textAlign: "center", left: "0" }}
                >
                  Login
                </h1>
              </div>
              <div className="bl-time">
                <div
                  className="row registerRow"
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div className="col-md-10 registerRowMbl">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email"
                      component={NameField}
                    />
                  </div>
                </div>
                <div
                  className="row registerRow"
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div className="col-md-10 registerRowMbl">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      component={PasswordField}
                    />
                  </div>

                  {isSuspended ? (
                    <div
                      style={{
                        position: "relative",
                        top: 22,
                        color: "#c15a5a"
                      }}
                    >
                      Your account has been suspended. Please contact
                      us to reinstate your {isSuspended}
                      account.
                    </div>
                  ) : null}
                </div>
                {props.error ? (
                  <div
                    className="validation-error-login"
                    style={{ marginLeft: 79 }}
                  >
                    {props.error}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="row registerRow"
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <div className="col-md-10 registerRowMbl">
                    <div className="filter-block">
                      <div className="switcher">
                        <label className="switch">
                          <Field
                            name="keepLoggedIn"
                            component="input"
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                        <span>Keep me logged in</span>
                        <Link
                          className="forgot-password"
                          to="/forgot-password"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10 registerRowMbl">
                    <button
                      type="submit"
                      className={`btn ${
                        props.submitting
                          ? " btn--loading"
                          : " btn-blue2"
                      }`}
                    >
                      LOGIN
                    </button>
                    <button
                      onClick={() =>
                        history.push("register/select-type")
                      }
                      className="btn btn-white2"
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer
          // isSubs
          ></Footer>
        </div>
      </Form>
    </>
  );
});

export default Login;
