import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";

class DeleteRoleModal extends React.Component {
  constructor(props) {
    super(props);
  }

  decline() {
    this.props.decline();
  }
  handleSubmit = () => {
    this.props.accept();
  };
  handleStatus = (ev) => {
    this.setState({ status: ev.target.value });
  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>{this.props.modalHeader}</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div>{this.props.message}</div>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.handleSubmit}
              >
                {this.props.acceptText}
              </button>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.decline.bind(this)}
              >
                {this.decline.bind(this)}
                {this.props.declineText}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
});

export default connect(mapStateToProps, null)(DeleteRoleModal);
