/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { VenueSignUpEffectNew } from "../../SignUp/Actions";
import { AuthApi } from "../../../API/AuthApi";
import Footer from "../../../footer";
import { AppState } from "../../../store";
import SMSModal from "../../Dashboard/modals/SMSVerficationModal";
import { Close } from "@mui/icons-material";

interface Props {
  isLoading: boolean;
  history: any;
  VenueSignUpEffectNew: (request: any, history: any) => void;
}

interface stateProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
  mobileNumber: string;
  city: string;
  Regionstate: string;
  emailError: string;
  mobileNumberError: string;
  passwordError: string;
  repeatPasswordError: string;
  firstNameError: string;
  lastNameError: string;
  isChecked: boolean;
  isCheckedError: string;
  cityError: string;
  stateError: string;
  companyName: string;
  companyNameError: string;
  previewUrl: any;
  file: any;
  isTermsChecked: boolean;
  termsError: string;
  isSMSModalOpen: boolean;
}

class VenueRegister extends React.Component<Props, stateProps> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repeatPassword: "",
      mobileNumber: "",
      city: "",
      Regionstate: "",
      emailError: "",
      mobileNumberError: "",
      passwordError: "",
      firstNameError: "",
      lastNameError: "",
      repeatPasswordError: "",
      isCheckedError: "",
      cityError: "",
      stateError: "",
      isChecked: true,
      companyName: "",
      companyNameError: "",
      previewUrl: null,
      file: null,
      isTermsChecked: false,
      termsError: "",
      isSMSModalOpen: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleValue = (e: any) => {
    var key = e.target.name;
    var value: any = {};
    value[key] = e.target.value;
    this.setState(value);
    value = {};
    value[key.toString() + "Error"] = "";
    this.setState(value);
  };

  handlePassword = (e: any) => {
    if (e.target.value) {
      let hasUpperCase: any = /[A-Z]/.test(e.target.value);
      let hasLowerCase: any = /[a-z]/.test(e.target.value);
      let hasNumbers: any = /\d/.test(e.target.value);
      let hasNonalphas: any = /\W/.test(e.target.value);
      if (
        !hasUpperCase ||
        !hasLowerCase ||
        !hasNumbers ||
        !hasNonalphas
      ) {
        this.setState({
          passwordError:
            "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol."
        });
      } else if (!/^(?=).{8,}$/i.test(e.target.value)) {
        this.setState({
          passwordError: "Minimum 8 characters required."
        });
      } else {
        this.setState({ password: e.target.value });
        this.setState({ passwordError: "" });
      }
    }
  };

  handleEmail = async (e: any) => {
    if (e) {
      let value = e.target.value;
      var check =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
          value
        );
      this.setState({ emailError: !check ? "Invalid Email" : "" });
      const response: any = await AuthApi.GetExistingEmailStatus(
        value
      );

      if (check) {
        if (
          response.success === "This email already exists" ||
          response.success === "Email already exists!"
        ) {
          this.setState({ emailError: "This email already exists" });
        } else {
          this.setState({ emailError: "" });
          this.setState({ email: value });
        }
      }
    }
  };

  handleRepeatPassword = (e: any) => {
    this.setState({
      repeatPasswordError:
        this.state.password !== e.target.value
          ? "Password does not match "
          : ""
    });
    this.setState({ repeatPassword: e.target.value });
  };

  handlePhoneNumber = (e: any) => {
    // var check =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
    //     e.target.value
    //   );
    this.setState({
      mobileNumberError: !true ? "Invalid Mobile Number" : ""
    });
    this.setState({ mobileNumber: e.target.value });
  };

  handleCityName = (e: any) => {
    this.setState({ city: e.target.value });
    this.setState({ cityError: "" });
  };
  handlestate = (e: any) => {
    this.setState({ Regionstate: e.target.value });
    this.setState({ stateError: "" });
  };

  handleError = () => {
    let error = false;
    if (this.state.firstName === "") {
      this.setState({ firstNameError: "Required" });
      error = true;
    }

    if (this.state.lastName === "") {
      this.setState({ lastNameError: "Required" });
      error = true;
    }

    if (
      this.state.password === "" ||
      this.state.password === undefined ||
      this.state.password === null
    ) {
      this.setState({ passwordError: "Required" });
      error = true;
    } else if (this.state.password!?.length < 8) {
      this.setState({
        passwordError: "Minimum 8 characters required."
      });
      error = true;
    } else {
      let hasUpperCase: any = /[A-Z]/.test(this.state.password!);
      let hasLowerCase: any = /[a-z]/.test(this.state.password!);
      let hasNumbers: any = /\d/.test(this.state.password!);
      let hasNonalphas: any = /\W/.test(this.state.password!);

      if (
        !hasUpperCase ||
        !hasLowerCase ||
        !hasNumbers ||
        !hasNonalphas
      ) {
        this.setState({
          passwordError:
            "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol."
        });
        error = true;
      }
    }

    if (this.state.repeatPassword === "") {
      this.setState({ repeatPasswordError: "Required" });
      error = true;
    }

    if (this.state.repeatPassword !== this.state.password) {
      error = true;
    }
    if (this.state.email === "") {
      this.setState({ emailError: "Required" });
      error = true;
    }

    if (this.state.mobileNumber === "") {
      this.setState({ mobileNumberError: "Required" });
      error = true;
    }
    if (!/^\d{10}$/g.test(this.state.mobileNumber)) {
      this.setState({ mobileNumberError: "Invalid Mobile Number " });
      error = true;
    }

    if (this.state.companyName === "") {
      this.setState({ companyNameError: "Required" });
      error = true;
    }
    if (this.state.Regionstate === "") {
      this.setState({ stateError: "Required" });
      error = true;
    }
    if (this.state.city === "") {
      this.setState({ cityError: "Required" });
      error = true;
    }
    if (this.state.firstName === "") {
      this.setState({ firstNameError: "Required" });
      error = true;
    }

    if (this.state.isTermsChecked === false) {
      this.setState({
        termsError: "Please Check Terms & Conditions"
      });
      error = true;
    } else {
      this.setState({ termsError: "" });
    }

    return error;
  };
  updatePhoneNumber = (value: string) => {
    this.setState({ ...this.state, mobileNumber: value });
  };
  handleSave = () => {
    if (!this.props.isLoading) {
      if (!this.handleError()) {
        var data = this.state;
        var request = {
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          repeatPassword: data.repeatPassword,
          email: data.email,
          mobileNumber: data.mobileNumber,
          companyName: data.companyName,
          file: data.file,
          state: data.city,
          region: data.Regionstate
        };
        this.props.VenueSignUpEffectNew(request, this.props.history);
        this.setState({ isCheckedError: "" });
      }
    }
  };
  handleToggleModal = () => {
    this.setState({ isSMSModalOpen: false });
  };
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    this.setState({
      previewUrl: URL.createObjectURL(file)
    });
    this.setState({ file: file });
  };
  handleTerms = () => {
    this.setState({ isTermsChecked: !this.state.isTermsChecked });
    this.setState({ termsError: "" });
  };
  handleClick = (e: any) => {
    e.preventDefault();
    this.setState({ file: null, previewUrl: null });
  };

  render() {
    return (
      <div className="body">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Promote your venue as a Dorpee workspace on-demand to a targeted audience, fill empty seats and generate additional revenue. Registering today, for FREE!"
          />
          <title>Become a Workspace on Demand | Dorpee</title>
        </Helmet>
        <div className="container containerAddSpace">
          <div className="page-wrapper" style={{ marginTop: 25 }}>
            <div className="row">
              <div className="col-md-6">
                <h1 className="register-title">List Your Venue</h1>
                <p className="register-sub-title-2">
                  Create an account as a{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    venue owner
                  </span>
                </p>

                <div className="register-left">
                  <p>
                    <b>
                      Promote and grow your business as a workspace on
                      demand
                    </b>
                  </p>
                  <p>
                    Dorpee provides a connection between the people
                    who need a workspace, the people who support them,
                    and the new generation of workspaces (you!). With
                    Dorpee, you can:
                  </p>

                  <ul className="register-points">
                    <li>Monetize under-utilised space</li>
                    <li>Manage your offering and pricing</li>
                    <li>Market to new customers</li>
                  </ul>

                  <p>
                    Within a few simple minutes you can create your
                    free listing, and we’ll do the rest!
                  </p>
                </div>
                <div className="md-sidebar">
                  <div className="name">
                    <p className="register-tip">
                      <b>HINT:</b> You are now entering information as
                      the user / primary contact for your venue.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="wizard__user-icon">
                  <label className="upload-field-label">
                    <input
                      id={"1"}
                      type="file"
                      className="upload-field-input"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={e => {
                        this.onChange(e);
                      }}
                    />
                    {this.state.previewUrl ? (
                      <>
                        <img
                          className="upload-field-preview"
                          src={this.state.previewUrl as string}
                          alt=""
                        />
                        <div
                          onClick={e => this.handleClick(e)}
                          className="col-md-1 tooltip"
                          style={{
                            padding: 0,
                            top: "-100%",
                            right: "-100%",
                            width: "2rem",
                            height: "2rem",
                            border: "none",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Close
                            style={{
                              color: "red",
                              width: "1rem",
                              height: "1rem"
                            }}
                          />
                          <span
                            className="tooltiptext"
                            style={{
                              textTransform: "none",
                              width: "10rem"
                            }}
                          >
                            Delete profile photo
                          </span>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
                <p
                  style={{
                    marginTop: "-10px",
                    fontSize: "12px",
                    marginBottom: "20px",
                    textAlign: "center"
                  }}
                >
                  Click to upload a profile picture for your account
                </p>
                <div className="bl-time bl-timeMbl">
                  <div className="row registerRow">
                    <div className="col-md-12 registerRowMbl">
                      <input
                        type="text"
                        autoComplete="nope"
                        onChange={e => this.handleValue(e)}
                        className="form-control"
                        placeholder="Company Name"
                        name="companyName"
                      />
                      {this.state.companyNameError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.companyNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div
                      className="col-md-12 registerRowMbl"
                      style={{
                        fontWeight: 600
                        // border: "1px solid #000",
                      }}
                    >
                      Primary Contact
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-6 registerRowMbl">
                      <input
                        type="text"
                        onChange={e => this.handleValue(e)}
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                      />
                      {this.state.firstNameError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.firstNameError}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 registerRowMbl">
                      <input
                        type="text"
                        onChange={e => this.handleValue(e)}
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                      />
                      {this.state.lastNameError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.lastNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-6 registerRowMbl">
                      <input
                        type="text"
                        onBlur={e => this.handleEmail(e)}
                        className="form-control"
                        placeholder="Email"
                        name="email"
                      />
                      {this.state.emailError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.emailError}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 registerRowMbl">
                      <input
                        type="text"
                        onChange={e => this.handlePhoneNumber(e)}
                        className="form-control"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        value={this.state.mobileNumber}
                      />
                      {this.state.mobileNumberError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.mobileNumberError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-12 registerRowMbl">
                      <input
                        type="password"
                        onChange={e => this.handlePassword(e)}
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        autoComplete="new-password"
                      />
                      {this.state.passwordError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.passwordError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-12 registerRowMbl">
                      <input
                        type="password"
                        onChange={e => this.handleRepeatPassword(e)}
                        className="form-control"
                        placeholder="Repeat Password"
                        name="repeatPassword"
                      />
                      {this.state.repeatPasswordError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.repeatPasswordError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-6 registerRowMbl">
                      <input
                        name="region"
                        className="form-control"
                        onChange={e => this.handleCityName(e)}
                        type="text"
                        placeholder="City *"
                      />
                      {this.state.cityError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.cityError}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 registerRowMbl">
                      <select
                        className="form-control"
                        onChange={e => this.handlestate(e)}
                        value={this.state.Regionstate}
                        style={{
                          color:
                            this.state.Regionstate == ""
                              ? "#888"
                              : "#495057"
                        }}
                      >
                        <option value="" disabled selected>
                          Region/State *
                        </option>
                        {[
                          "Australian Capital Territory",
                          "New South Wales",
                          "Northern Territory",
                          "Queensland",
                          "South Australia",
                          "Tasmania",
                          "Victoria",
                          "Western Australia",
                          "Northland",
                          "Auckland",
                          "Waikato",
                          "Bay of Plenty",
                          "Gisborne",
                          "Hawke's Bay",
                          "Taranaki",
                          "Manawatū-Whanganui",
                          "Wellington",
                          "Tasman",
                          "Nelson",
                          "Marlborough",
                          "West Coast",
                          "Canterbury",
                          "Otago",
                          "Southland"
                        ].map((data, i) => {
                          return (
                            <option value={data} key={i}>
                              {data}
                            </option>
                          );
                        })}
                      </select>
                      {this.state.stateError !== "" && (
                        <span style={{ color: "red" }}>
                          {this.state.stateError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row registerRow">
                    <div className="col-md-12 registerRowMbl">
                      <input
                        id="chk1"
                        type="checkbox"
                        defaultChecked={this.state.isTermsChecked}
                        onChange={value => {
                          this.handleTerms();
                        }}
                        style={{ marginRight: 10 }}
                      />
                      <label
                        htmlFor="chk1"
                        style={{ color: "grey", fontSize: 13 }}
                      >
                        Clicking Get Started confirms acceptance of
                        the Dorpee Terms & Conditions{" "}
                        <a
                          href="/term-condition"
                          target="_blank"
                          title="Terms & Conditions"
                        >
                          I accept the Dorpee Terms & Conditions
                        </a>
                      </label>
                    </div>
                    <span style={{ color: "red", marginLeft: 15 }}>
                      {this.state.termsError}
                    </span>
                  </div>
                  <div className="bl-buttons">
                    <div className="col-12">
                      <a
                        href="#"
                        className={`btn ${
                          this.props.isLoading
                            ? " btn--loadingNew"
                            : "btn-successBtnRegister"
                        } `}
                        onClick={() => {
                          if (!this.handleError()) {
                            this.setState({ isSMSModalOpen: true });
                          }
                        }}
                      >
                        {" "}
                        {!this.props.isLoading && (
                          <span>Register as Venue Owner</span>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.isSMSModalOpen && (
                <SMSModal
                  handleToggleModal={this.handleToggleModal}
                  phoneNumber={this.state.mobileNumber}
                  updatePhoneNumber={this.updatePhoneNumber}
                  handleSignUpForm={this.handleSave}
                />
              )}
            </div>
          </div>
        </div>
        <Footer
        // isSubs
        ></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.Loader.isLoading,
  emailExistMessage: state.wizard.emailExistMessage
});

const mapDispatchToProps = {
  VenueSignUpEffectNew
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VenueRegister)
);
