import { Reducer } from "react";
import { DashboardState } from "../../store";
import { DashboardAction, earnThisWeekLoaded } from "./Actions";

const initialState: DashboardState = {
  dashboardData: {
    venues: {
      active_venues: 0,
      all_bookings: [],
      active_booking: [],
      past_bookings: [],
      cancelled_bookings: [],
    },
  },
  venuesStats: {
    venues: {
      total_bookings_hours: 0,
      total_bookings_revenue: 0,
      earnThisWeek: 0,
    },
  },
  earnThisWeek: 0,
  searchPreferences: [],
  customerAllCard: {},
  defaultCreditPrice: 0,
  purchasedCredit: [],
  customerInvitation: [],
  dashboardToken: "",
  roles: [],
  requirements_modal: false,
};

export const dashboardReducer: Reducer<DashboardState, DashboardAction> = (
  state: DashboardState = initialState,
  action
) => {
  switch (action.type) {
    case "@@dashboard/DASHBOARD_DATA_LOADED":
      return { ...state, dashboardData: action.data };
    case "@@dashboard/DASHBOARD_PURCHASED_CREDITS":
      return { ...state, purchasedCredit: action.data };
    case "@@dashboard/VENUES_STATS_LOADED":
      return { ...state, venuesStats: action.data };
    case "@@dashboard/SET_EARN_THIS_WEEK":
      return { ...state, earnThisWeek: action.earn };
    case "@@dashboard/SEARCH_PREFERENCES_LOADED":
      return { ...state, searchPreferences: action.data };
    case "@@dashboard/CUSTOMERS_ALL_CARDS_LOADED":
      return { ...state, customerAllCard: action.data };
    case "@@dashboard/CREDIT_PRICE":
      return { ...state, defaultCreditPrice: action.price };
    case "@@dashboard/CUSTOMERS_INVITES_LOADED":
      return { ...state, customerInvitation: action.data };
    case "@@dashboard/EMPLOYER_ROLES_LOADED":
      return { ...state, roles: action.data };
    case "@@dashboard/REQUIRMENTS_MODAL":
      return { ...state, requirements_modal: action.data };
    case "@@dashboard/INFO_MODAL":
      return { ...state, info_modal: action.data };
    case "@@dashboard/PREFERENCES_MODAL":
      return { ...state, pref_modal: action.data };
    case "@@dashboard/PRIVACY_MODAL":
      return { ...state, privacy_modal: action.data };
    case "@@dashboard/ADDRESS_MODAL":
      return { ...state, contacts_modal: action.data };

    case "@@dashboard/TOKEN":
      return { ...state, dashboardToken: action.token };

    default:
      return state;
  }
};
