import React from "react";
import Cookies from "js-cookie";

import { Modal } from "../../UI/Modal";

class VaccinationRequirementModal extends React.Component {
  constructor(props) {
    super(props);
  }

  decline() {
    this.props.decline();
  }
  accept() {
    Cookies.set("roleUser", "Customer");
    this.props.history.push({
      pathname: "/profile",
      state: {
        prevLoc: window.location.pathname,
      },
    });
  }

  render() {
    return (
      <>
        <Modal position={"fixed"}>
          <div
            className="addressbook-form-box"
            style={{ padding: "80px 17px 0px" }}
          >
            <div className="congrat-box__header">
              <span>{this.props.modalHeader}</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form" style={{ margin: 0 }}>
              <div>{this.props.message}</div>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.accept.bind(this)}
              >
                {this.props.acceptText}
              </button>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.decline.bind(this)}
              >
                {this.props.declineText}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default VaccinationRequirementModal;
