import React from "react";
import { WrappedFieldProps } from "redux-form";

const IconField = (iconClass: string) => (props: WrappedFieldProps) => {
  const { input, meta, ...rest } = props;
  const displayError = props.meta.touched && props.meta.error;

  return (
    <>
      <div
        className={`input-container${displayError ? " input-container--invalid" : ""
          }`}
      >
        <div className="input__icon">
          <div className={iconClass}></div>
        </div>

        <input type="text" {...input} {...rest} />
      </div>
      {displayError && (
        <div className="validation-error">{props.meta.error}</div>
      )}
    </>
  );
};

export default IconField;
