import React, { FC, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import moment from "moment";

import { AppState } from "../../store";
// import Privacy from "./Privacy";
// import Search from "./Search";
// import AddressBook from "./AddressBook";
import MyInvitations from "./MyInvitations";
import { getBookingEffect } from "../../Screens/Book/Booking/Actions";
import {
  compareDates,
  getDateformatedChange
} from "../../helpers/DateHelpers";
import ModalComponent from "./modals/AddCreditModal";
import {
  getSearchPreferencesEffect,
  getCreditPrice,
  getDashboardPurchasedEffect,
  getInvitesList
} from "../Dashboard/Actions";

import { getProfileEffect } from "../Auth/Actions";
import { cancelUserPlan } from "../PaymentWizard/Actions";
import Confirmation from "./modals/Confirmation";
import DownLoadModal from "../DownLoadModal";

import "./accordion.sass";

const CustomerDashboard: FC = () => {
  const user = useSelector((state: AppState) => state.auth.user!);
  const employer = useSelector(
    (state: AppState) => state.Booking.myEmployers
  );
  const bookings = useSelector(
    (state: AppState) => state.Booking.myBookings!
  );
  let planExpiry = useSelector((state: AppState) =>
    state.auth.user ? state.auth.user.plan_expiry : {}
  );
  planExpiry = planExpiry ? planExpiry.split("/") : "";
  planExpiry = `${planExpiry[2]}-${planExpiry[1]}-${planExpiry[0]}`;

  const history = useHistory();
  const dispatch = useDispatch();

  let [upComingEvents, setUpComingEvents] = useState(0);
  const [isOpen, setModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [credit, setCredit] = useState(0);
  const [isModalOpen, openCancelModal] = useState(false);
  const isLoggedIn = useSelector(
    (state: AppState) => state.auth.isLoggedIn
  );
  const toggleModal = (value: boolean, credit2: any) => {
    // let sumCredit = parseInt(credit2) + credit;
    // if (credit2) {
    //   setCredit(sumCredit);
    // }
    setModalOpen(value);
  };
  const [userPlan, setuserPlan] = useState(user.plan ? true : false);
  const [totalBookings, setTotalBooking] = useState(0);
  const state: any = useLocation();

  useEffect(() => {
    window.localStorage.removeItem("addNewCard");
    dispatch(getCreditPrice());
    dispatch(getInvitesList());
    dispatch(getDashboardPurchasedEffect());
    dispatch(getBookingEffect());
    dispatch(getSearchPreferencesEffect());
    let dateprevious = user.registered_date.split("/");
    let beforeMonth = moment(
      `${dateprevious[2]}-${dateprevious[1]}-${dateprevious[0]}`
    ).isBefore(new Date(), "month");
    if (!beforeMonth) {
      setShowPopup(true);
    }
  }, []);
  useEffect(() => {
    let events = 0;
    bookings.map(booking => {
      if (
        compareDates(
          `${booking.start_date} ${booking.start_time}`,
          new Date()
        )
      ) {
        events++;
      }
    });
    setUpComingEvents(events);
  }, [bookings]);
  useEffect(() => {
    dispatch(getProfileEffect());
  }, [user]);

  useEffect(() => {
    setCredit(user.total_credits);
  }, [user.total_credits]);
  useEffect(() => {
    const dateNow = new Date().setHours(0, 0, 0, 0);
    const filteredBookings = bookings.filter(item => {
      if (
        getDateformatedChange(item.start_date, true).getTime() >=
        dateNow
      )
        return item;
    });
    setTotalBooking(filteredBookings.length);
  }, [bookings]);
  const handleToSelectionPlan = () => {
    history.push("select-plan");
  };
  const showSuccess = (text: any) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  };
  const hideModal = () => {
    openCancelModal(false);
  };
  const confirm = () => {
    openCancelModal(false);
    dispatch(cancelUserPlan(history));
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Individual Dashboard</title>
      </Helmet>

      <div className="container pd-0">
        <div className="dashboard">
          <div className="dashboard__top">
            <div className="dashboard__title">My Dashboard</div>
          </div>
          <MyInvitations />
          {showPopup &&
          user &&
          user.device_type == null &&
          isLoggedIn ? (
            <DownLoadModal message="Don't forget to download the Dorpee App" />
          ) : null}
          <div className="dashboard__profile">
            <div className="dashboard__profile-info">
              <div className="dashboard__profile-avatar">
                <img
                  src={
                    user.avatar
                      ? user.avatar
                      : "/img/icons/user-info.svg"
                  }
                  alt=""
                />
              </div>
              <div className="dashboard__profile-name">
                {user.first_name} {user.last_name}
              </div>
            </div>
            <div className="dashboard__data"></div>
          </div>
          <div className="dasboard_container">
            <div className="subscribedPlan">
              <div className="Plan_logo">
                <img src="/img/imgs/logo-mobile.png" alt="" />
              </div>
              {user && user.plan ? (
                <div className="Plan_details">
                  <h6>{user.plan.name!} Plan</h6>
                  <div className="planDetails">
                    <p className="plan_details_text">
                      {credit ? credit : 0} credits remaining &nbsp;
                      &nbsp;
                      <span
                        className="credits_link_text"
                        onClick={() => {
                          if (user) setModalOpen(true);
                          else {
                            toast.error(
                              "Please update payment setting to purchase"
                            );
                            history.push("/payment");
                          }
                        }}
                      >
                        Add one-time credits
                      </span>
                    </p>
                    <p className="plan_expiry_text">
                      <p className="plan_details_text">
                        {user.plan.credits} credits per month &nbsp;
                        &nbsp; Expires &nbsp;
                        {user.plan_expiry}
                        &nbsp; &nbsp;
                        <span
                          className="credits_link_text"
                          onClick={() => {
                            history.push("/select-plan");
                          }}
                        >
                          My Plan
                        </span>
                      </p>
                    </p>
                  </div>
                  {userPlan && user.is_subscribed ? (
                    <div
                      className="col-8 col-md-3"
                      style={{ padding: 0, marginTop: "1rem" }}
                    >
                      <button
                        type="submit"
                        onClick={() => {
                          openCancelModal(true);
                        }}
                        className={`btn2 btn-blue`}
                        style={{ margin: 0, padding: "4px" }}
                      >
                        CANCEL PLAN
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="Plan_details">
                  <div className="Plan_details">
                    <h6>No Plan Selected</h6>
                    <div className="planDetails">
                      <p className="plan_details_text">
                        {credit ? credit : 0} &nbsp; credits remaining
                        &nbsp; &nbsp;
                        <span
                          className="credits_link_text"
                          onClick={() => {
                            if (user) setModalOpen(true);
                            else {
                              toast.error(
                                "Please update payment setting to purchase"
                              );
                              history.push("/payment");
                            }
                          }}
                        >
                          Add one-time credits
                        </span>
                      </p>
                      <p className="plan_expiry_text">
                        <p className="plan_details_text">
                          <span
                            className="credits_link_text"
                            onClick={() => {
                              history.push("/select-plan");
                            }}
                          >
                            Get Plan
                          </span>
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className="subscribedPlan"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              {user && user.plan ? (
                <div className="Plan_details">
                  <div className="planDetails">
                    <p className="plan_details_text">
                      Upcoming Plan &nbsp; &nbsp;
                      <span
                        className="credits_link_text"
                        onClick={() => handleToSelectionPlan()}
                      >
                        Change Plan
                      </span>
                    </p>
                    <p className="plan_expiry_text">
                      <p className="plan_details_text">
                        {user.plan.name == "Free"
                          ? "Intro"
                          : user.plan.name}{" "}
                        Plan &nbsp; &nbsp;
                        {user.plan.name == "Free"
                          ? "150"
                          : user.plan.credits}
                        &nbsp; credits per month &nbsp; &nbsp; Begins
                        &nbsp;
                        {user.plan_expiry}
                        &nbsp;
                      </p>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="Plan_details"></div>
              )}
            </div>
          </div>
          {isOpen ? (
            <ModalComponent
              openModal={isOpen}
              handleToggleModal={toggleModal}
              history={history}
            />
          ) : null}

          {/* <Accordion
            items={[
              {
                header: "MY LAST 5 BOOKINGS",
                iconUrl: "/img/icons/book.svg",
                component: () => <LastBookings myBookings={bookings} />,
              },
              {
                header: "MY INTERESTS",
                iconUrl: "/img/icons/interests.svg",
                component: () => <Interests showSuccess={showSuccess} />,
              },
              {
                header: "PURCHASED CREDITS",
                iconUrl: "/img/icons/coins.svg",
                component: () => <PurchasedCredits />,
              },
              {
                header: "PAYMENT OPTIONS",
                iconUrl: "/img/icons/payment.svg",
                component: () => <Payment />,
              },
              {
                header: "SEARCH PREFERENCES",
                iconUrl: "/img/icons/preferences.svg",
                component: () => <Search />,
              },
              {
                header: "PRIVACY SETTINGS",
                iconUrl: "/img/icons/settings.svg",
                component: () => <Privacy showSuccess={showSuccess} />,
              },
              {
                header: "MY ADDRESS BOOK",
                iconUrl: "/img/icons/addressbook.svg",
                component: () => <AddressBook showSuccess={showSuccess} />,
              },
            ]}
            multiple={true}
          /> */}
          {isModalOpen && (
            <Confirmation
              message={"Are you sure you want to cancel?"}
              decline={hideModal}
              accept={confirm}
              acceptText={"Yes"}
              declineText={"No"}
              modalHeader={"CANCEL PLAN"}
              showButtons={true}
            ></Confirmation>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;
