import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect, useHistory } from "react-redux";
import { updateWifiDetailVenueEffect } from "../../VenueWizard/Actions";
import { getProfileEffect } from "../../Auth/Actions";
class UpdateWifi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: this.props.openModal,
      wifi_name: " ",
      wifi_password: " ",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: nextProps.openModal });
  }
  componentDidMount() {
    this.setState({
      wifi_name: this.props.venueData.wifi_name,
      wifi_password: this.props.venueData.wifi_password,
    });
  }
  componentDidUpdate(nextProps) {
    if (nextProps.venueData.wifi_password != this.props.venueData.wifi_password)
      this.setState({
        wifi_name: this.props.venueData.wifi_name,
        wifi_password: this.props.venueData.wifi_password,
      });
  }
  handleWifiName = (event) => {
    this.setState({ wifi_name: event.target.value });
  };
  handlePassword = (event) => {
    this.setState({ wifi_password: event.target.value });
  };
  hasErrors = () => {
    if (this.state.wifi_name == "" || this.state.wifi_password == "")
      return true;
  };
  handleSubmit = () => {
    if (true) {
      var req = {
        wifi_name: this.state.wifi_name || null,
        wifi_password: this.state.wifi_password || null,
      };
      this.props.updateWifiDetailVenueEffect(this.props.venueData.venueId, req);
      this.props.handleToggleModal();
    }
  };

  render() {
    return (
      <>
        <Modal>
          <div
            className="addressbook-form-box"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <div className="congrat-box__header">
              <span>Update Wifi Details</span>
              <div
                onClick={() => this.props.handleToggleModal()}
                className="close-btn"
              ></div>
            </div>
            <p style={{ fontSize: "120%" }}>
              By doing so, your venue Wifi details will be provided to Dorpee
              guests automatically at check-in
            </p>
            <div className="addressbook-form" style={{ width: "60%" }}>
              <label htmlFor="">Wifi Name</label>
              <div className="input-container">
                <input
                  onChange={(ev) => this.handleWifiName(ev)}
                  type="text"
                  id="name"
                  placeholder="Wifi Name"
                  value={this.state.wifi_name}
                  style={{ textAlign: "center" }}
                  autoComplete="off"
                />
              </div>
              <label htmlFor="">Wifi Password</label>
              <div className="input-container">
                <input
                  onChange={(ev) => this.handlePassword(ev)}
                  type="text"
                  id="Credits"
                  placeholder="Wifi Password"
                  value={this.state.wifi_password}
                  style={{ textAlign: "center" }}
                  autoComplete="off"
                />
              </div>

              <button
                style={{ marginTop: 10 }}
                onClick={this.handleSubmit}
                className="btn btn-blue wizard__btn mb-0"
              >
                Update
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
  user: state.auth.user,
  defaultCreditPrice: state.dashboard.defaultCreditPrice,
});

const mapDispatchToProps = {
  updateWifiDetailVenueEffect,
  getProfileEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWifi);
