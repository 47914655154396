import React, { FC } from "react";
import { WrappedFieldProps } from "redux-form";
import "./RegionField.sass";

const RegionField: FC<
  WrappedFieldProps & { isRegister?: boolean }
> = props => {
  const { input, meta, isRegister, ...rest } = props;
  const displayError = props.meta.touched && props.meta.error;
  return (
    <>
      <div
        className={`employer-select employer_div ${
          displayError ? " input-container--invalid" : ""
        }`}
        style={{
          padding: 5,
          display: "flex",
          flexDirection: "column",
          border: "1px solid #a8c8d4",
          borderRadius: "4px",
          height: "45px",
          marginBottom: "8px"
        }}
      >
        <select
          style={{
            color: input.value == "" ? "#888" : "#495057",
            border: "none",
            fontSize: "16px",
            fontFamily: "RobotoRegular",
            padding: 0
          }}
          {...input}
          {...rest}
        >
          <option value="" disabled selected style={{ color: "red" }}>
            Region/State *
          </option>
          {[
            "Australian Capital Territory",
            "New South Wales",
            "Northern Territory",
            "Queensland",
            "South Australia",
            "Tasmania",
            "Victoria",
            "Western Australia",
            "Northland",
            "Auckland",
            "Waikato",
            "Bay of Plenty",
            "Gisborne",
            "Hawke's Bay",
            "Taranaki",
            "Manawatū-Whanganui",
            "Wellington",
            "Tasman",
            "Nelson",
            "Marlborough",
            "West Coast",
            "Canterbury",
            "Otago",
            "Southland"
          ].map((data, i) => {
            return (
              <option value={data} key={i}>
                {data}
              </option>
            );
          })}
        </select>
      </div>{" "}
      {displayError && (
        <div className="validation-error">{props.meta.error}</div>
      )}
    </>
  );
};

export default RegionField;
