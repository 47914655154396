/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";

interface props {
  message?: string;
}
interface myStateProps {}
class LoaderModal extends React.Component<props, myStateProps> {
  constructor(props: myStateProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="overlay" style={{ zIndex: 99999 }}>
          <div className="modal2">
            <div className={`btn btn--loadingEdit`}>
              <span>
                {this.props.message ? this.props.message : "loading..."}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { Loader: { isLoading: any } }) => ({
  isLoading: state.Loader.isLoading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderModal);
