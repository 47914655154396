import React, { Component } from "react";

export default class stars extends Component {
  constructor(props) {
    super(props);
    this.state = { rating: this.props.rating || null, temp_rating: null };
  }
  rate(rating) {
    this.setState({
      rating: rating,
      temp_rating: rating,
    });
    this.handleRating(rating);
  }
  star_over(rating) {
    this.state.temp_rating = this.state.rating;
    this.state.rating = rating;

    this.setState({
      rating: this.state.rating,
      temp_rating: this.state.temp_rating,
    });
  }
  star_out() {
    this.setState({ rating: this.state.temp_rating });
  }
  handleRating = () => {
    this.props.handleRating(this.state.rating);
  };
  render() {
    var stars = [];

    for (var i = 0; i < 5; i++) {
      var klass = "star-rating__star";

      if (this.state.rating >= i && this.state.rating != null) {
        klass += " is-selected";
      }

      stars.push(
        <label
          style={{ marginTop: -5 }}
          className={klass}
          onClick={this.rate.bind(this, i)}
          onMouseOver={this.star_over.bind(this, i)}
        >
          ★
        </label>
      );
    }

    return (
      <div className="star-rating">
        <label htmlFor="stars">Rating :{stars}</label>
      </div>
    );
  }
}
