import React, { FC } from "react";
import { WrappedFieldProps } from "redux-form";

import "./BasicField.sass";

const BasicField: FC<
  WrappedFieldProps & { isTextArea?: boolean; isRegister?: boolean }
> = props => {
  const { input, meta, isTextArea, isRegister, ...rest } = props;
  const displayError = props.meta.touched && props.meta.error;
  return (
    <>
      {isRegister ? (
        <>
          <div
            className={`input-container${
              displayError ? " input-container--invalid" : ""
            }${isTextArea ? " textarea" : ""}`}
            style={{ padding: 5 }}
          >
            {isTextArea ? (
              <textarea {...input} {...rest} />
            ) : (
              <input
                className="form-control"
                autoComplete="off"
                {...input}
                {...rest}
              />
            )}
          </div>
          {displayError && (
            <div className="validation-error">{props.meta.error}</div>
          )}
        </>
      ) : (
        <>
          <div
            className={`input-container ${
              displayError ? " input-container--invalid" : ""
            }${isTextArea ? " textarea" : ""}`}
            style={{ padding: 5 }}
          >
            {isTextArea ? (
              <textarea {...input} {...rest} />
            ) : (
              <input autoComplete="off" {...input} {...rest} />
            )}
          </div>
          {displayError && (
            <div className="validation-error">{props.meta.error}</div>
          )}
        </>
      )}
    </>
  );
};

export default BasicField;
