import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { formatDate } from "../../../helpers/DateHelpers";
const WeeklyUtilization = props => {
  const [chartData, setChartData] = useState({
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: []
  });

  useEffect(() => {
    let lessthantwohours = [0, 0, 0, 0, 0, 0, 0];
    let lessthanfivehours = [0, 0, 0, 0, 0, 0, 0];
    let greterthanfivehours = [0, 0, 0, 0, 0, 0, 0];
    const data = props.myTeamBookings ? props.myTeamBookings : [];
    if (data.length > 0) {
      data.map(async v => {
        if (v.all_bookings.length > 0) {
          v.all_bookings.map(x => {
            [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday"
            ].map((z, index) => {
              let day = new Date(x.start_date).getDay();
              if (day === index) {
                let duration = x.duration;
                let hours = duration / 60;
                if (hours <= 2) {
                  lessthantwohours[index] =
                    lessthantwohours[index] + 1;
                }
                if (hours > 2 && hours <= 5) {
                  lessthanfivehours[index] =
                    lessthanfivehours[index] + 1;
                }
                if (hours > 5) {
                  greterthanfivehours[index] =
                    greterthanfivehours[index] + 1;
                }
              }
            });
          });
        }
      });
      let values = [];
      for (let i = 0; i < 3; i++) {
        let color = getRandomColor();
        let conf = {
          label:
            i == 0
              ? "Less than 2 Hours"
              : i == 1
              ? "2-5 Hours"
              : i == 2
              ? "More than 5 Hours"
              : "",
          backgroundColor: `${color}cc`,
          borderColor: `${color}cc`,
          borderWidth: 1,
          hoverBackgroundColor: "#c4e8f7",
          hoverBorderColor: "#009cdc",
          data:
            i == 0
              ? lessthantwohours
              : i == 1
              ? lessthanfivehours
              : i == 2
              ? greterthanfivehours
              : "",
          stack: "Stack 0"
        };
        values.push(conf);
      }
      setChartData(chartData => ({
        ...chartData,
        datasets: values
      }));
    }
  }, [props.myTeamBookings]);

  useEffect(() => {
    let lessthantwohours = [0, 0, 0, 0, 0, 0, 0];
    let lessthanfivehours = [0, 0, 0, 0, 0, 0, 0];
    let greterthanfivehours = [0, 0, 0, 0, 0, 0, 0];
    if (props.submitted) {
      if (props.endDate && props.startDate) {
        let endDate = new Date(props.endDate);
        let startDate = new Date(props.startDate);
        endDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        const data = props.myTeamBookings ? props.myTeamBookings : [];
        if (data.length > 0) {
          data.map(async v => {
            if (v.all_bookings.length > 0) {
              v.all_bookings.map(x => {
                let bookingDate = new Date(x.start_date);
                bookingDate.setHours(0, 0, 0, 0);
                if (
                  bookingDate.getTime() >= startDate.getTime() &&
                  bookingDate.getTime() <= endDate.getTime()
                ) {
                  [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                  ].map((z, index) => {
                    let day = new Date(x.start_date).getDay();
                    if (day === index) {
                      let duration = x.duration;
                      let hours = duration / 60;
                      if (hours <= 2) {
                        lessthantwohours[index] =
                          lessthantwohours[index] + 1;
                      }
                      if (hours > 2 && hours <= 5) {
                        lessthanfivehours[index] =
                          lessthanfivehours[index] + 1;
                      }
                      if (hours > 5) {
                        greterthanfivehours[index] =
                          greterthanfivehours[index] + 1;
                      }
                    }
                  });
                }
              });
            }
          });
          let values = [];
          for (let i = 0; i < 3; i++) {
            let color = getRandomColor();
            let conf = {
              label:
                i == 0
                  ? "Less than 2 Hours"
                  : i == 1
                  ? "2-5 Hours"
                  : i == 2
                  ? "More than 5 Hours"
                  : "",
              backgroundColor: `${color}cc`,
              borderColor: `${color}cc`,
              borderWidth: 1,
              hoverBackgroundColor: "#c4e8f7",
              hoverBorderColor: "#009cdc",
              data:
                i == 0
                  ? lessthantwohours
                  : i == 1
                  ? lessthanfivehours
                  : i == 2
                  ? greterthanfivehours
                  : "",
              stack: "Stack 0"
            };
            values.push(conf);
          }
          setChartData(chartData => ({
            ...chartData,
            datasets: values
          }));
        }
      }
    } else {
      const data = props.myTeamBookings ? props.myTeamBookings : [];
      if (data.length > 0) {
        data.map(async v => {
          if (v.all_bookings.length > 0) {
            v.all_bookings.map(x => {
              [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ].map((z, index) => {
                let day = new Date(x.start_date).getDay();
                if (day === index) {
                  let duration = x.duration;
                  let hours = duration / 60;
                  if (hours <= 2) {
                    lessthantwohours[index] =
                      lessthantwohours[index] + 1;
                  }
                  if (hours > 2 && hours <= 5) {
                    lessthanfivehours[index] =
                      lessthanfivehours[index] + 1;
                  }
                  if (hours > 5) {
                    greterthanfivehours[index] =
                      greterthanfivehours[index] + 1;
                  }
                }
              });
            });
          }
        });
        let values = [];
        for (let i = 0; i < 3; i++) {
          let color = getRandomColor();
          let conf = {
            label:
              i == 0
                ? "Less than 2 Hours"
                : i == 1
                ? "2-5 Hours"
                : i == 2
                ? "More than 5 Hours"
                : "",
            backgroundColor: `${color}cc`,
            borderColor: `${color}cc`,
            borderWidth: 1,
            hoverBackgroundColor: "#c4e8f7",
            hoverBorderColor: "#009cdc",
            data:
              i == 0
                ? lessthantwohours
                : i == 1
                ? lessthanfivehours
                : i == 2
                ? greterthanfivehours
                : "",
            stack: "Stack 0"
          };
          values.push(conf);
        }
        setChartData(chartData => ({
          ...chartData,
          datasets: values
        }));
      }
    }
  }, [props.submitted]);

  const getRandomColor = () => {
    let letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  };
  return (
    <div className="venue-chart-big">
      <Bar
        key={Math.random()}
        data={chartData}
        width={100}
        height={50}
        legend={{ position: "bottom" }}
        options={{
          animation: {
            duration: 0
          },
          plugins: {
            title: {
              display: true,
              text: "Weekly Utilization"
            }
          },
          responsive: true,
          interaction: {
            mode: "index",
            intersect: false
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 1,
                  beginAtZero: true,
                  stepSize: 1
                }
              }
            ],
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Number of bookings"
                }
              }
            ]
          }
        }}
      />
    </div>
  );
};

export default WeeklyUtilization;
