import { PaymentFields } from "../PaymentPage";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {
  PaymentAPI,
  createPaymentRequest,
  createCardRequest,
  Payment,
  Card,
  Credits,
  createPurchaseCreditsRequest,
  createUpdatedPaymentRequest
} from "../../API/PayementAPI";
import {
  getProfileEffect,
  updateUser,
  userRole
} from "../Auth/Actions";
import { toast } from "react-toastify";
import { History } from "history";
import {
  setLoader,
  unSetLoader,
  setLoaderCredit
} from "../Loader/Actions";
import Cookies from "js-cookie";
import { AuthApi, getUserType } from "../../API/AuthApi";
export function setPaymentStatus(isLoading: boolean) {
  return <const>{
    type: "@@wizard/SET_PAYMENT_STATUS",
    isLoading
  };
}

export const createCreditEffect =
  (payment: Credits, history?: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoaderCredit(true));
    const response = await PaymentAPI.PurchaseCredits(
      state.auth.token!,
      new createPurchaseCreditsRequest(payment)
    );
    const user = response.data;
    if (user) {
      toast.success("Credits updated");
      dispatch(setLoaderCredit(false));
      dispatch(updateUser(user));
      if (history) {
        history.push("/dashboard");
      }
    } else {
      dispatch(setLoaderCredit(false));
      toast.error("Something Went Wrong");
      if (history) {
        history.push("/dashboard");
      }
    }
  };

export const createUpdateEffect =
  (id: number, payment: createUpdatedPaymentRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await PaymentAPI.UpdateCard(
      state.auth.token!,
      id,
      new createUpdatedPaymentRequest(payment)
    );
    if (response.data) {
      dispatch(unSetLoader(false));
      toast.success("Card successfully updated.");
    } else {
      toast.error("Something went wrong..");
    }
  };
export const createPaymentEffect =
  (payment: Payment, history: History, status?: boolean) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await PaymentAPI.CreatePayment(
      state.auth.token!,
      new createPaymentRequest(payment)
    );
    const user = response.data;
    const { error } = response;
    if (error) {
      dispatch(setLoader(false));
      return toast.error(error, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    if (user) {
      if (status) {
        toast.success("Plan updated", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        toast.success("Payment successful", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      dispatch(setLoader(false));

      dispatch(unSetLoader(false));
      await dispatch(updateUser(user));
      if (!window.location.pathname.includes("book/3/")) {
        history.push("/dashboard");
      }
      window.location.reload();
    }

    // dispatch(updateUser(user))
  };

export const cancelUserPlan =
  (history: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await PaymentAPI.CancelPlan(state.auth.token!);
    const message: any = response;
    if (message.message == "Unauthenticated.") {
      dispatch(setLoader(false));
      return toast.error(message.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (message && message.data) {
      dispatch(setLoader(false));
      const response = await AuthApi.GetProfile(state.auth.token!);
      let localRole = Cookies.get("roleUser");
      dispatch(updateUser(response.data));
      dispatch(
        userRole(localRole ? localRole : response.data.role[0].title)
      );
      toast.success("Plan successfully cancelled", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
export const createAddCardEffect =
  (payment: Card, history: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await PaymentAPI.CreateCard(
      state.auth.token!,
      new createCardRequest(payment)
    );
    const user = response.data;
    const { error } = response;
    if (user) {
      toast.success("Card added successfully.", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error("Oops.. Something went wrong.");
    }
    dispatch(unSetLoader(false));
    await dispatch(updateUser(user));
    if (window.localStorage.getItem("card_status")) {
      let credit = window.localStorage.getItem("credit_card");
      let payment = {
        credits: Number(credit)
      };
      const response = await PaymentAPI.PurchaseCredits(
        state.auth.token!,
        new createPurchaseCreditsRequest(payment)
      );
      const user = response.data;
      const { error } = response;
      if (error) {
        window.localStorage.removeItem("credit");
        window.localStorage.removeItem("card_status");
        window.localStorage.removeItem("credit_card");
        return toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      if (user) {
        toast.success("Credit Added", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        window.localStorage.removeItem("credit");
        window.localStorage.removeItem("card_status");
        dispatch(unSetLoader(false));
        await dispatch(updateUser(user));
        // window.location.href="/dashboard"
      }
    }
    if (!window.location.pathname.includes("book/3/")) {
      history.push("/dashboard");
    }
    // dispatch(updateUser(user))
  };

// export const changePlanEffect =(ChangePlanData: ChangePlanData,history: History) => async (dispatch: Dispatch, getState: () => AppState) => {
//     const state = getState();
//     dispatch(setLoader(true))
//     const response = await PaymentAPI.ChangePlan(state.auth.token!,new changePlanRequest(ChangePlanData) );
//     const user  = response.data
//     if(user){
//         toast.success("Payment successful", {
//             position: toast.POSITION.TOP_RIGHT
//         });
//         dispatch(unSetLoader(false))

//         history.push('/dashboard');
//     }

//     dispatch(updateUser(user))
// }
export type PaymentAction = ReturnType<typeof setPaymentStatus>;
