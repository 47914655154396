import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const CostOverview = ({ chartData }) => {
  const [chartInfo, setChartInfo] = useState();
  useEffect(() => {
    let data = createChart();
    let chartdata = {
      labels: data[0].labels,
      datasets: [data[0].datasets, data[1].datasets],
    };
    setChartInfo(chartdata);
  }, [chartData]);
  const createChart = () => {
    let values = [];

    Object.keys(chartData).map(function (data) {
      let dt = [];
      let labels = [];
      Object.keys(chartData[data]).map(function (key, index) {
        labels.push(key);
        dt.push(chartData[data][key]);
      });
      let conf = {
        type: data == "cost" ? "line" : "bar",
        label: data == "cost" ? "Dorpee Cost" : "Dorpee Hours",
        backgroundColor: data == "cost" ? "#ee7e2d" : "#c4e8f7",
        borderColor: data == "cost" ? "#ee7e2d" : "#c4e8f7",
        fill: data == "cost" ? false : null,
        borderWidth: 1,
        hoverBorderColor: "#009cdc",
        yAxisID: data == "cost" ? "cost" : "hours",
        data: dt,
      };
      const finaldata = {
        labels,
        datasets: conf,
      };
      values.push(finaldata);
    });
    return values;
  };

  return (
    <div className="venue-chart-big">
      <Bar
        data={chartInfo}
        width={100}
        height={50}
        legend={{ position: "bottom" }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Cost Over Time",
            },
          },
          scales: {
            yAxes: [
              {
                id: "cost",
                type: "linear",
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Cost $/per Month per Employee",
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 100,
                },
              },
              {
                id: "hours",
                display: true,
                position: "right",
                gridLines: {
                  display: false,
                },
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: 120,
                  beginAtZero: true,
                  stepSize: 20,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Hours/per Month per Employee",
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};
export default CostOverview;
