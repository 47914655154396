import { ApiClient } from "./ApiClient";
import { WorkspaceImagesFields } from "../Screens/WorkspaceWizard/WorkspaceImagesForm";
import { WorkspaceDetailsFields } from "../Screens/WorkspaceWizard/CreateWorkspace";
import { Workspace } from "./VenueApi";
import { type } from "os";

export class WorkspaceApi {
  static UpdateWorkspace(
    token: string,
    workspaceId: string,
    request: CreateWorkspaceRequest
  ) {
    return ApiClient.postMultipart<CreateWorkspaceRequest, { data: any }>(
      `user/spaces/${workspaceId}`,
      request,
      token
    );
  }
  static CreateWorkspace(token: string, request: CreateWorkspaceRequest) {
    return ApiClient.postMultipart<CreateWorkspaceRequest, { data: any }>(
      "user/spaces",
      request,
      token
    );
  }
  static GetWorkspace(token: string, workspaceId: string) {
    return ApiClient.get<{ data: Workspace }>(
      `user/spaces/${workspaceId}`,
      token
    );
  }
  static PostSpaceBlock(token: string, request: CreateSpaceBlockRequest) {
    return ApiClient.post<CreateSpaceBlockRequest, getSpacesBlocksResponse>(
      "user/blocked-spaces",
      request,
      token
    );
  }
  static GetSpaceBlock(token: string) {
    return ApiClient.get<getSpacesBlocksResponse>("user/blocked-spaces", token);
  }
  static GetSpaceAvailabilityById(token: string, id: number) {
    return ApiClient.get<getSingleSpaceAvailResponse>(
      `user/blocked-spaces/${id}`,
      token
    );
  }
  static deleteSpaceAvailability(token: string, id: number) {
    return ApiClient.delete(`user/blocked-spaces/${id}`, token);
  }
  static EditSpaceAvailability(
    token: string,
    request: CreateSpaceBlockRequest,
    id: number
  ) {
    return ApiClient.post<CreateSpaceBlockRequest, getSpacesBlocksResponse>(
      `user/blocked-spaces/${id}`,
      request,
      token
    );
  }
  static GetWorkspaceSuggestedPrice(venueId: string) {
    return ApiClient.get<{ data: Workspace }>(`user/suggestedprice/${venueId}`);
  }
  static GetSpaceAvailabilityRequest(spaceId: any, date: any) {
    return ApiClient.post<CreateWorkspaceAvailabilityRequest, { data: any }>(
      `spaces/availability/${spaceId}`,
      date
    );
  }

  static GetTotalSpaces() {
    return ApiClient.get<{ data: any }>(`spaces/count`);
  }
  // static GetVenues(token: string) {
  //     return ApiClient.get<VenuesResponse>('user/venues', token);
  // }
  // static GetVenue(id: string, token?: string) {
  //     return ApiClient.get<VenueResponse>(`venues/${id}`, token);
  // }
  // static GetPublicVenues() {
  //     return ApiClient.get<VenuesResponse>('venues/all');
  // }
  // static SearchVenuesBasic(request: VenueBasicSearchRequest) {
  //     return ApiClient.post<VenueBasicSearchRequest, VenuesResponse>('search/basic', request);
  // }
}

export class CreateWorkspaceRequest {
  constructor(
    detailsFields: WorkspaceDetailsFields,
    imagesFields: WorkspaceImagesFields,
    status?: String
  ) {
    this.price = detailsFields.price ? detailsFields.price : "";
    this.capacity = detailsFields.capacity;
    this.quantity = detailsFields.quantity;
    this.description = detailsFields.description;
    this.types = detailsFields.types.join(",");
    this.environments = detailsFields.environments.join(",");
    if (detailsFields.assets) {
      this.assets = detailsFields.assets.join(",");
    }
    this.cancellation_period = detailsFields.cancellation_period;
    this.images = [
      detailsFields.image1,
      detailsFields.image2,
      detailsFields.image3,
      detailsFields.image4,
    ].filter((i) => !!i) as File[];
    this.venue_id = detailsFields.venueId;
    this.name = detailsFields.name;
    this.min_booking_hours = detailsFields.min_booking_hours
      ? detailsFields.min_booking_hours
      : "";
    this.max_booking_hours = detailsFields.max_booking_hours
      ? detailsFields.max_booking_hours
      : "";
    this.free_space = detailsFields.free_space;
    this._method = detailsFields._method ? detailsFields._method : "";
    this.status = status;
  }

  name: string;
  venue_id: string;
  price: number;
  quantity?: number;
  capacity: number;
  types: string;
  environments: string;
  description: string;
  images: File[];
  free_space: any;
  _method?: any;
  assets?: string;
  min_booking_hours?: any;
  max_booking_hours?: any;
  cancellation_period?: any;
  status?: any;
}

export class CreateSpaceBlockRequest {
  constructor(detailsFields: any) {
    this.space_id = detailsFields.space_id;
    this.availability = detailsFields.availability;
  }
  space_id: number;
  availability: any[];
}

export type BlocksSpaces = {
  space_id: number;
  availability: any[];
};
export type getSpacesBlocksResponse = {
  data: BlocksSpaces[];
};
export type getSingleSpaceAvailResponse = {
  data: BlocksSpaces;
};
export class CreateWorkspaceAvailabilityRequest {
  constructor(date: any) {
    this.date = date;
  }
  date: any;
}

// export type Workspace = {
//     id: number;
//     name: string;
//     address: string;
//     suburb: string;
//     postcode: string;
//     state: string;
//     country: string;
//     price: string;
//     capacity: number;
//     description: string;
//     created_at: string;
//     updated_at: string;
//     deleted_at?: any;
//     user_id: number;
//     featured?: any;
//     phone_number?: any;
//     email?: any;
//     images: string[];
//     images_link?: any;
//     uploaded_images: {
//         file_name: string
//         mime_type: string
//     }[]
//     media: any[];
//     user: User;
//     environments: Environment[]
//     services: Service[]
//     types: VenueType[]
//     lat: string
//     lng: string
// }

// export type WorkspaceResponse = {
//     data: Workspace;
// }
