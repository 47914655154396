import React, { Component } from "react";
interface myProps {
  assets?: any[];
  selectedAssets?: string[];
  workspace?: any;
}
interface myStateProps {
  index: number;
  isCollapse: boolean;
  showMore: boolean;
  showMoreLess: boolean;
}

export default class WorkspacesAssets extends Component<myProps, myStateProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      isCollapse: true,
      index: 5,
      showMore: false,
      showMoreLess: false,
    };
  }
  getAssets = (id: string) => {
    if (this.props.assets) {
      const asset: any = this.props.assets.find(
        (a: any) => a.id === parseInt(id)
      );
      return asset ? asset.asset : "";
    } else return "";
  };
  componentDidMount() {
    if (this.props.workspace.assets) {
      this.setState({
        index:
          this.props.workspace.assets?.length > 5
            ? 5
            : this.props.workspace.assets?.length,
      });
      let doc = document.getElementById(
        this.props.workspace.id
          ? this.props.workspace.id.toString()
          : this.props.workspace.venueId.toString() + this.props.workspace.name
      );
      let line = Number(doc && doc.offsetHeight);

      if (line > 120) {
        this.setState({ showMore: true });
      } else this.setState({ showMore: false });
    }
  }
  handleAccord = () => {
    if (this.props.workspace.assets) {
      var index = this.state.index > 5 ? 5 : this.props.workspace.assets.length;
      this.setState({ isCollapse: !this.state.isCollapse, index: index });
    }
  };

  render() {
    return (
      <div>
        {this.props.selectedAssets && this.props.selectedAssets?.length > 0 ? (
          <div className="md-widget-sidebar">
            <ul
              className="smd-tag clearfix amenitiesStyle"
              style={{ marginLeft: 0 }}
            >
              {this.props.selectedAssets
                .map((obj) => this.getAssets(obj))
                .map((item: any) => {
                  return (
                    <>
                      <li style={{ marginRight: 10, marginBottom: 0 }}>
                        {item}
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
        ) : (
          <>
            {this.props.workspace.assets &&
              this.props.workspace.assets.length < 5 && (
                <div
                  className="md-widget-sidebar"
                  style={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    height: this.state.showMoreLess ? "100%" : "6.5rem",
                  }}
                >
                  <ul
                    className="smd-tag clearfix amenitiesStyle"
                    style={{ marginLeft: 0 }}
                    id={this.props.workspace.id.toString()}
                  >
                    {this.props.workspace.assets.map((obj: any, index: any) => {
                      return (
                        <li key={index} style={{ marginRight: 10 }}>
                          {obj.asset}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
          </>
        )}
        {this.state.showMore ? (
          <div
            className="loadmore"
            onClick={() => {
              this.setState({ showMoreLess: !this.state.showMoreLess });
            }}
          >
            {this.state.showMoreLess ? "Load less" : "Load more"}
          </div>
        ) : null}
      </div>
    );
  }
}
