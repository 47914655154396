/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";

import { locationUrl, locationUrl2 } from "../../common/googleMaps";
import {
  publishWorkspaceEffect,
  workspaceWizardSetStep
} from "./Actions";
import { WorkspaceWizardStep } from "./Reducers";
import { VenueWizardStep } from "../VenueWizard/Reducers";
import WorkspacesItem from "../VenueWizard/Component/WorkspacesItem";
import {
  venueWizardSetStep,
  postVenueStatusEffect
} from "../VenueWizard/Actions";
import { getFormated12hTimeNew } from "../../../src/helpers/DateHelpers";
import ShowWorkSpaces from "../../Screens/VenueWizard/showWorkspaces";
import Footer from "../../footer";
import ViewMoreButton from "../VenueWizard/Component/ViewMoreButton";
import { setLoader } from "../Loader/Actions";
import ChangeStatusModal from "../Dashboard/modals/ChangeStatusModal";

import "../../Screens/venue-style.css";
class PreivewWorkspace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venue: {},
      services: [],
      lineSize: "",
      showMore: false,
      showModal: false
    };
  }
  componentDidMount() {
    const venue = this.props.venues.find(
      v =>
        v.id ===
        Number(
          this.props.venueId !== undefined
            ? this.props.venueId
            : this.props.venue_id
        )
    );
    this.setState({ venue: venue });
    const grouped = _.mapValues(
      _.groupBy(venue && venue.services, "category"),
      asset => asset.map(ast => _.omit(ast, "category"))
    );
    this.setState({ services: grouped });
    let doc = document.getElementById("dd");
    let line = Number(doc && doc.offsetHeight);
    if (line > 50) {
      this.setState({ lineSize: "2.8em" });
      this.setState({ showMore: true });
    }
    window.scrollTo(0, 0);
  }
  formatAddress = () => {
    return [
      this.state.venue.address,
      this.state.venue.suburb,
      this.state.venue.postcode,
      this.state.venue.state,
      this.state.venue.country
    ]
      .filter(v => !!v)
      .join(" ");
  };
  mapVenueType = id => {
    const venueType = this.props.venueTypes.find(
      t => t.id === parseInt(id)
    );
    return venueType ? venueType.type : "";
  };

  mapEnvironment = id => {
    const environment = this.props.environments.find(
      t => t.id === parseInt(id)
    );
    return environment ? environment.environment : "";
  };

  getAssets = id => {
    const asset = this.props.assets.find(a => a.id === parseInt(id));
    return asset ? asset.asset : "";
  };
  handleBack = () => {
    if (this.props.isTypeStep) {
      if (this.props.venueId) {
        this.props.venueWizardSetStep(VenueWizardStep.BonusOffer);
      } else {
        this.props.venueWizardSetStep(VenueWizardStep.AddSpace);
      }
    } else {
      if (this.props.workspaceId)
        this.props.history.replace(
          `/edit-workspace/${this.props.workspaceId}`
        );
      this.props.workspaceWizardSetStep(WorkspaceWizardStep.Details);
    }
  };
  handleSave = () => {
    if (!this.props.isLoading) {
      if (this.props.isTypeStep) {
        if (this.state.venue.spaces.length <= 0) {
          this.setState({ showModal: true });
        } else {
          let req = {
            status: "Published",
            _method: "PUT"
          };
          var venueId = this.props.detailsFields
            ? this.props.detailsFields.venueId
            : this.props.venue_id;
          this.props.postVenueStatusEffect(venueId, req);
        }
        this.props.setLoader(true);
        if (this.props.detailsFields) {
          this.props.publishWorkspaceEffect(
            this.props.workspaceId,
            this.props.history
          );
        }
        toast.success("Saved as Draft");
        this.props.history.push("/venues");
      } else {
        this.props.publishWorkspaceEffect(
          this.props.workspaceId,
          this.props.history,
          "Closed"
        );
      }
    }
  };
  handlePublish = () => {
    if (!this.props.isLoading) {
      window.localStorage.setItem(
        "venue_name",
        this.state.venue.name
      );
      if (this.props.isTypeStep) {
        if (this.state.venue.spaces.length <= 0) {
          this.setState({ showModal: true });
        } else {
          let req = {
            status: "Published",
            _method: "PUT"
          };
          var venueId = this.props.detailsFields
            ? this.props.detailsFields.venueId
            : this.props.venue_id;
          this.props.postVenueStatusEffect(venueId, req);
        }
      } else {
        let req = {
          status: "Published",
          _method: "PUT"
        };
        var venueId = this.props.detailsFields
          ? this.props.detailsFields.venueId
          : this.props.venue_id;
        this.props.postVenueStatusEffect(venueId, req);

        this.props.publishWorkspaceEffect(
          this.props.workspaceId,
          this.props.history,
          "Unpublished"
        );
      }
    }
  };
  hideModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Workspace - Preview</title>
        </Helmet>
        <div className="body">
          <div className="page-main page-review page-special bodyTop">
            <div
              className="bl-buttons clearfix col-md-11"
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div className="backButton">
                <a
                  href="#"
                  onClick={() => this.handleBack()}
                  style={{
                    padding: "0px 50px 12px 64px",
                    position: "relative",
                    top: "-40px"
                  }}
                  className="btn btn-back2"
                >
                  <span style={{ color: "#fff" }}>Back</span>
                </a>
              </div>

              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "50%",
                  margin: "0"
                }}
              >
                {/* <div className="col-md-3"></div> */}
                {((this.props.detailsFields &&
                  this.props.detailsFields.isSpaceFlow) ||
                  this.props.btnSave) &&
                (!window.location.pathname.includes("edit") ||
                  !window.location.pathname.includes(
                    "edit-venue"
                  )) ? (
                  <div
                    className="col-md-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // flexDirection: "column",
                      // left: "16rem",
                      padding: "0",
                      right: "2rem"

                      // border: "1px solid #000",
                    }}
                  >
                    <a
                      onClick={() => this.handleSave()}
                      className={`btn  ${
                        !this.props.isLoading
                          ? "btn-publish"
                          : "btn--loading3"
                      }`}
                      style={{
                        position: "relative",
                        top: -32,
                        background: "transparent",
                        padding: "0 10px",
                        border: "1px solid #fff"
                      }}
                      disabled={this.props.isLoading}
                    >
                      <span
                        style={{
                          color: "#fff",
                          textTransform: "uppercase",
                          background: "none",
                          padding: "12px 16px 12px 34px",
                          textAlign: "center",
                          borderRadius: "4px"
                        }}
                      >
                        {this.props.isPublished
                          ? "Done"
                          : "Save as Draft"}
                      </span>
                    </a>
                  </div>
                ) : null}
                <div
                  className={`col-md-${
                    window.location.pathname.includes(
                      "create-workspace"
                    )
                      ? "5"
                      : "4"
                  }`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // flexDirection: "column",
                    padding: "0"
                    // border: "1px solid #000",
                  }}
                >
                  <a
                    onClick={() => this.handlePublish()}
                    className={`btn  ${
                      !this.props.isLoading
                        ? "btn-publish"
                        : "btn--loading3"
                    }`}
                    style={{
                      position: "relative",
                      top: -32,
                      padding: "0 10px"
                      // border: "1px solid #000",
                    }}
                    disabled={this.props.isLoading}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                        padding: "12px 16px 12px 44px"
                        // padding: "0px 6px 10px 44px",
                        // background: "none",
                      }}
                    >
                      {this.props.isPublished
                        ? "Done"
                        : this.props.btnText}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {this.props.isPublished && (
              <div className="md-sidebar publish-sidebar col-md-3">
                <div
                  className="name"
                  style={{
                    padding: "10px",
                    borderRadius: "8px",
                    textAlign: "left"
                  }}
                >
                  <p className="register-tip">
                    Your venue will not be published until you create
                    at least one workspace.
                  </p>
                </div>
              </div>
            )}
            <div
              className="main "
              style={{
                position: "relative",
                top: this.props.isPublished ? 30 : 1
              }}
            >
              <div className="container-fluid">
                <div
                  className="page-wrapper"
                  style={{ marginTop: -28 }}
                >
                  <div className="row">
                    <div className="col-lg-9 col-workspace">
                      <div className="bl-all-workspace">
                        <h1>
                          {this.state.venue && this.state.venue.name}
                        </h1>
                        <ul>
                          {this.props.detailsFields && (
                            <ShowWorkSpaces
                              detailsFields={this.props.detailsFields}
                              venueName={this.state.venue.name}
                            ></ShowWorkSpaces>
                          )}
                          {!this.props.detailsFields &&
                            this.state.venue &&
                            this.state.venue.spaces &&
                            this.state.venue.spaces.map(s => (
                              <WorkspacesItem
                                disabled
                                workspace={s}
                                status={this.state.venue.status}
                                venueName={this.state.venue.name}
                                vUserId={this.state.venue.user_id}
                              />
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-ads">
                      <div className="bl-ads-workspace">
                        <div className="title">
                          <p>
                            {this.state.venue &&
                              this.state.venue.name}
                          </p>
                        </div>
                        <div className="address">
                          <div
                            className="form-control"
                            style={{ border: "0px solid" }}
                          >
                            {this.state.venue && (
                              <a
                                className="venue-address__link"
                                target="_blank"
                                href={locationUrl2(
                                  this.state.venue.address,
                                  this.state.venue.suburb,
                                  this.state.venue.state,
                                  this.state.venue.postcode
                                )}
                              >
                                <span style={{ fontSize: 15 }}>
                                  {this.state.venue &&
                                    this.formatAddress(
                                      this.state.venue
                                    )}
                                </span>
                              </a>
                            )}
                          </div>
                        </div>
                        {this.state.venue && (
                          <Carousel
                            dynamicHeight={true}
                            showThumbs={false}
                            showStatus={false}
                          >
                            {this.state.venue.images &&
                              this.state.venue.images.map(
                                (imageUrl, index) => (
                                  <div
                                    className="thumbnail"
                                    style={{ height: 402 }}
                                  >
                                    <img
                                      src={imageUrl}
                                      alt={`${this.state.venue.name} ${index}`}
                                    />
                                  </div>
                                )
                              )}
                          </Carousel>
                        )}

                        <div className="md-widget-sidebar">
                          {this.state.venue &&
                          this.state.venue.bonus_offer ? (
                            <div
                              className="venue-bonus-text"
                              style={{ marginTop: 10 }}
                            >
                              <p>
                                {this.state.venue &&
                                  this.state.venue.bonus_offer}
                              </p>
                            </div>
                          ) : null}
                          <div className="sub-title">
                            <p>About The Venue</p>
                          </div>
                          {this.state.venue &&
                            this.state.venue.description && (
                              <ViewMoreButton
                                id={"space"}
                                description={
                                  this.state.venue.description
                                }
                              />
                            )}
                        </div>
                        <div className="md-widget-sidebar">
                          <div className="sub-title">
                            <p>OPEN HOURS</p>
                          </div>
                          <div className="open-hours">
                            <ul>
                              {this.state.venue &&
                                this.state.venue.availability &&
                                this.state.venue.availability.availability.map(
                                  item => {
                                    return (
                                      <li className="clearfix">
                                        <span className="is-title">
                                          {item.day}
                                        </span>
                                        <span className="hour">
                                          {this.state.venue &&
                                            getFormated12hTimeNew(
                                              item.openingTime
                                            )}{" "}
                                          -{" "}
                                          {getFormated12hTimeNew(
                                            this.state.venue &&
                                              item.closingTime
                                          )}
                                        </span>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                        <div className="md-widget-sidebar">
                          <div className="sub-title">
                            <p>Services</p>
                          </div>
                          <ul className="smd-tag clearfix amenitiesStyle">
                            {Object.keys(this.state.services).map(
                              obj => {
                                return (
                                  <>
                                    {this.state.services[obj].map(
                                      item => {
                                        return (
                                          <li>{item.service}</li>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              }
                            )}
                          </ul>
                        </div>
                        {/* {Object.keys(this.state.services).map((obj) => {
                        return (
                          <div className="md-widget-sidebar ">
                            <div className="sub-title">
                              <p style={{ paddingTop: 15 }}>{obj}</p>
                            </div>
                            <ul className="smd-tag clearfix amenitiesStyle">
                              {this.state.services[obj].map((item) => {
                                return <li>{item.service}</li>;
                              })}
                              <br />
                            </ul>
                          </div>
                        );
                      })} */}
                        {this.state.showModal && (
                          <ChangeStatusModal
                            handleModal={() => this.hideModal()}
                            modalHeader={"VENUE STATUS"}
                            id={this.state.venue.id}
                            showMessage={true}
                            history={this.props.history}
                            acceptText={"Yes"}
                            declineText={"No"}
                          ></ChangeStatusModal>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer isSubs />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.auth.user,
  workspaces: state.workspaceWizard.detailsFields,
  isLoading: state.Loader.isLoading,
  detailsFields: state.workspaceWizard.detailsFields,
  venues: state.venueWizard.venues,
  environments: state.workspaceWizard.environments,
  venueTypes: state.workspaceWizard.venueTypes,
  assets: state.workspaceWizard.assets,
  venue_id: state.venueWizard.venueId,
  spaceId: state.workspaceWizard.workspaceId,
  isPublished: state.venueWizard.isVenuePublished
});

const mapDispatchToProps = {
  publishWorkspaceEffect,
  workspaceWizardSetStep,
  venueWizardSetStep,
  postVenueStatusEffect,
  setLoader
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreivewWorkspace);
