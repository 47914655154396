import { Dispatch } from "redux";
import { AppState } from "../../../store";
import {
  PaymentAPI,
  createPaymentRequest,
  Payment
} from "../../../API/PayementAPI";
import {
  BookingFields,
  createBookingRequest,
  BookingAPI,
  MyBookings,
  SpaceAvailabilityRequest,
  SingleBooking,
  BookingCancelFields,
  createBookingCancelRequest,
  BookingStatus,
  ExtendRequest,
  createVanueBookingCancelRequest
} from "../../../API/BookingAPI";
import {
  setBookingVenueLoader,
  setLoader,
  unSetLoader
} from "../../Loader/Actions";
import { DashboardApi } from "../../../API/DashboardApi";
import { dashboardDataLoaded } from "../../Dashboard/Actions";
import Cookies from "js-cookie";
import { userRole } from "../../Auth/Actions";
// import { updateUser } from '../../Auth/Actions'
import { toast } from "react-toastify";
import { History } from "history";
export function setBooking(BookingFields: any) {
  return <const>{
    type: "@@wizard/SET_BOOKING",
    BookingFields
  };
}
export function setOrgEmployees(orgEmployees: any) {
  return <const>{
    type: "@@wizard/SET_EMPLOYEES",
    orgEmployees
  };
}

export function clearBooking() {
  return <const>{
    type: "@@wizard/CANCEL_BOOKING"
  };
}
export function setRecentBookings(MyBookings: MyBookings) {
  return <const>{
    type: "@@wizard/SET_RECENT_BOOKING",
    MyBookings
  };
}
export function setBookingSINGLE(SingleBooking: SingleBooking) {
  return <const>{
    type: "@@wizard/SET_BOOKING_SINGLE",
    SingleBooking
  };
}
export function setCustomerData(data: any) {
  return <const>{
    type: "@@wizard/SET_CUSTOMER_DATA",
    data
  };
}

export function getmyTeamBookings(data: any) {
  return <const>{
    type: "@@wizard/SET_TEAM_BOOKING",
    data
  };
}

export const createBookingEffect =
  (
    BookingFields: BookingFields,
    history: History,
    personalBooking: boolean
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setBookingVenueLoader(true));
    const state = getState();
    const response = await BookingAPI.CreateBooking(
      state.auth.token!,
      new createBookingRequest(BookingFields)
    );
    if (response.data) {
      // if (state && state.auth.user!.role[0].title !== "Customer") {
      Cookies.set("roleUser", "Customer");
      dispatch(userRole("Customer"));
      //   history.push("/dashboard");
      // } else {
      //   Cookies.set("roleUser", "Customer");
      history.push("/bookings");
      // window.location.reload();

      // }
      toast.success("Booking Completed", {
        position: toast.POSITION.TOP_RIGHT
      });
      if (!personalBooking) {
        dispatch(clearBooking());
      }
      dispatch(setBooking([]));
      dispatch(setBookingVenueLoader(false));
    } else {
      dispatch(setBookingVenueLoader(false));
      let responseData: any = response;
      if (responseData.message == "The given data was invalid.") {
        toast.error("Error Creating Booking, Try Again", {
          position: toast.POSITION.TOP_RIGHT
        });
        var url = window.location.toString();
        let newurl = url.replace("/book/3/", "/book/1/");
        setTimeout(() => {
          window.location.href = newurl;
        }, 200);
      } else {
        toast.error(responseData.error, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

export const EditBookingEffect =
  (
    BookingFields: BookingFields,
    history: History,
    personalBooking: boolean,
    id: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    dispatch(setBookingVenueLoader(true));
    const response = await BookingAPI.EditBooking(
      state.auth.token!,
      new createBookingRequest(BookingFields),
      id
    );
    if (response.data) {
      // if (state && state.auth.user!.role[0].title !== "Customer") {
      Cookies.set("roleUser", "Customer");
      dispatch(userRole("Customer"));
      //   history.push("/bookings");
      // } else {
      //   Cookies.set("roleUser", "Customer");
      history.push("/bookings");
      // window.location.reload();
      // }
      toast.success("You have successfully updated your booking", {
        position: toast.POSITION.TOP_RIGHT
      });
      if (!personalBooking) {
        dispatch(clearBooking());
      }
      dispatch(setBooking([]));
      dispatch(setBookingVenueLoader(false));
    } else {
      dispatch(setBookingVenueLoader(false));
      let responseData: any = response;
      toast.error(responseData.error, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    window.localStorage.removeItem("booking_Id");
  };

export const getBookingEffect =
  (tokenVenue?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));

    const state = getState();
    const response = await BookingAPI.GetBookings(
      tokenVenue ? tokenVenue : state.auth.token!
    );
    if (response.data) {
      dispatch(setRecentBookings(response));
    }
    dispatch(setLoader(false));
  };

export const cancelBookingEffect =
  (id: number, request: BookingCancelFields) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.CancelBooking(
      state.auth.token!,
      id,
      new createBookingCancelRequest(request)
    );
  };

export const getBookingByIdEffect =
  (booking_number: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.GetBooking(
      state.auth.token!,
      booking_number
    );
    if (response.data) {
      dispatch(setBookingSINGLE(response));
    } else {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
export const StoreBookingByIdEffect =
  (booking_number: string, history: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.GetBooking(
      state.auth.token!,
      booking_number
    );
    const data = response.data;
    let book;
    if (data) {
      book = {
        id: data.id,
        user_id: data.user ? data.user.id : 1,
        space: data!.space!,
        space_id: data.space_id,
        start_date: data.start_date,
        end_date: data.end_date,
        start_time: data.start_time,
        end_time: data.end_time,
        booking_as: data.booking_as,
        no_of_people: data.no_of_people,
        no_of_booked_spaces: data.no_of_booked_spaces,
        venue_id: data!.space!.venue_id,
        previousCredits: data.credits,
        credits: data.credits,
        teams: data.teams,
        contacts: data.contacts,
        employer_id: data.employer_id,
        employer: data.employer,
        invited_employees: data.invited_employees
          ? data.invited_employees
          : null,
        _method: "PUT"
      };
      dispatch(setBooking(book));

      // dispatch(setBookingSINGLE(response))
      history.push(
        `/book/1/${data.space && data.space.venue_id}/${
          data.space_id
        }`
      );
    } else {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

export const getCustomerEmployeesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.GetCustomerEmployee(
      state.auth.token!
    );
    if (response) {
      dispatch(setCustomerData(response));
    }
  };
export const ChangeBookingStatusEffect =
  (request: BookingStatus) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.ChangeBookingStatus(
      state.auth.token!,
      request
    );
    if (response.data) {
      const responseData = await BookingAPI.GetBookings(
        state.auth.token!
      );
      if (responseData.data) {
        if (request.status == "Checked-Out") {
          toast.success("Checkout Successful");
        } else {
          toast.success(request.status);
        }
        dispatch(setRecentBookings(responseData));
        const data = await DashboardApi.GetDashboardData(
          getState().auth.token!
        );
        dispatch(dashboardDataLoaded(data.data));
      }
    } else {
      toast.error("Oops... something went wrong.");
    }
  };
export const CancelVenueBookingEffect =
  (request: createVanueBookingCancelRequest, booking_id: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.CancelvenueBooking(
      state.auth.token!,
      booking_id,
      request
    );
    if (response.data) {
      const responseData = await BookingAPI.GetBookings(
        state.auth.token!
      );
      if (responseData.data) {
        dispatch(setRecentBookings(responseData));
        const data = await DashboardApi.GetDashboardData(
          getState().auth.token!
        );
        dispatch(dashboardDataLoaded(data.data));
      }
      toast.success(request.status);
    } else {
      toast.error("Oops... something went wrong.");
    }
  };
export const ExtendBookingEffect =
  (request: ExtendRequest, hideModal?: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.ExtendBooking(
      state.auth.token!,
      request
    );
    if (response.data) {
      const responseData = await BookingAPI.GetBookings(
        state.auth.token!
      );
      if (responseData.data) {
        dispatch(setRecentBookings(responseData));
      }
      toast.success("Booking is successfully extended.");
      hideModal();
    } else if (response.error) {
      toast.error(response.error);
    }
  };
export const getEmplpoyerCustomerBookings =
  (token?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.GetMyCustomerBookings(
      token ? token : state.auth.token!
    );
    if (response) {
      dispatch(getmyTeamBookings(response.data));
    }
  };
export const getSpaceAvailability =
  (request: SpaceAvailabilityRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await BookingAPI.GetSpaceAvailability(request);
    if (response.data) {
      if (response.data.availability) {
        Cookies.set("checkSpace", "true");
      } else {
        Cookies.set("checkSpace", "false");
      }
    }
  };
export const createEmployerBooking =
  (request: any, history: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setBookingVenueLoader(true));

    const state = getState();
    const response = await BookingAPI.EmployerBooking(
      state.auth.token!,
      request
    );
    if (response.data) {
      toast.success("Booking Completed", {
        position: toast.POSITION.TOP_RIGHT
      });
      history.push("/dashboard");
      dispatch(setBookingVenueLoader(true));
    } else if (response.error) {
      dispatch(setBookingVenueLoader(true));
      toast.error(response.error, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

export type BookingAction = ReturnType<
  | typeof setBooking
  | typeof setRecentBookings
  | typeof setBookingSINGLE
  | typeof setCustomerData
  | typeof clearBooking
  | typeof getmyTeamBookings
  | typeof setOrgEmployees
>;
