import React from "react";

import { Modal } from "../../../UI/Modal";

const RemoveEmployerAdmin = ({ employee, handleSubmit, hideModal }) => {
  const handleAccept = (id) => {
    handleSubmit(id);
  };

  const handleCloseModal = () => {
    hideModal(false);
  };

  return (
    <>
      <Modal>
        <div className="addressbook-form-box" style={{ paddingBottom: "0px" }}>
          <div className="congrat-box__header">
            <span>Employer Admin</span>
            <div onClick={handleCloseModal} className="close-btn"></div>
          </div>
          <div
            className="addressbook-form"
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span>
              {/* Are you sure to remove <b>{employee?.full_name}</b> as Employer
              Admin */}
              Are you sure you?
            </span>
            <div>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={() => handleAccept(employee.id)}
                style={{ marginTop: "25px" }}
              >
                Yes
              </button>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={handleCloseModal}
                style={{ marginTop: "25px" }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveEmployerAdmin;
