import React from 'react';
import { Modal } from '../../../UI/Modal';
import { getFormated12hTime, formatDateToMonth } from "../../../helpers/DateHelpers"
class BookingDetailsModal extends React.Component {
    decline() {
        this.props.decline();
    }

    render() {
        return (
            <>
                <Modal>
                    <div className="addressbook-form-box">
                        <div className="congrat-box__header"><span>Booking Details</span>
                            <div onClick={this.decline.bind(this)} className="close-btn"></div>
                        </div>
                        <div className="addressbook-form">
                            <div className="rating_content">
                                <div >
                                    <div>Booked By: {this.props.markerId.name} </div>
                                    <div>Venue Name: {this.props.markerId.data.space.venue.name} </div>
                                    <div>Space Name: {this.props.markerId.data.space.name} </div>
                                    <div>Status: {this.props.markerId.data.status} </div>
                                    <div>Credit: {this.props.markerId.data.credits} </div>
                                    <div> Date: {formatDateToMonth(this.props.markerId.data.start_date)}</div>
                                    <div>Start Time: {getFormated12hTime(this.props.markerId.data.start_time)}</div>
                                    <div>End Time: {getFormated12hTime(this.props.markerId.data.end_time)}</div>
                                    <div className="venue-rating__left">
                                    </div>
                                </div>
                                <p style={{ marginTop: 30, textTransform: "capitalize" }}></p>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default BookingDetailsModal;