import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmployeeRoles,
  deleteEmployeeRoles,
  getEmployeesRoles
} from "./Actions";
import AddEmployerRole from "./modals/AddEmployerRole";
import DeleteRoleModal from "./modals/DeleteRoleModal";

const RolesAccess = () => {
  const dispatch = useDispatch();
  const [employerRoleModal, setEmployerRoleModal] = useState(false);
  const [editemployerRoleModal, setEditEmployerRoleModal] =
    useState(false);
  const [deleteemployerRoleModal, setDeleteEmployerRoleModal] =
    useState(false);
  const [roleDetails, setRoleDetails] = useState({
    id: "",
    role_name: "",
    hourly_access: "",
    monthly_access: "",
    unlimited_hourly_access: false,
    unlimited_monthly_access: false
  });

  const emproles = useSelector(state => state.dashboard.roles);
  useEffect(() => {
    dispatch(getEmployeesRoles());
  }, []);
  const addRole = async data => {
    if (roleDetails && roleDetails.id) {
      let newdata = {
        hourly_access: data.unlimited_hourly_access
          ? null
          : data.hourly_access,
        monthly_access: data.unlimited_monthly_access
          ? null
          : data.monthly_access,
        role_name: data.role_name,
        _method: "PUT"
      };
      await dispatch(
        createEmployeeRoles(newdata, roleDetails.id, dispatch)
      );
    } else {
      await dispatch(createEmployeeRoles(data, null, dispatch));
    }
  };
  const handleEditRole = data => {
    setRoleDetails(data);
    setEditEmployerRoleModal(true);
  };
  const handleDeleteRole = data => {
    setRoleDetails(data);
    setDeleteEmployerRoleModal(true);
  };

  const hideModal = () => {
    setRoleDetails({});
    setEmployerRoleModal(false);
    setEditEmployerRoleModal(false);
    setDeleteEmployerRoleModal(false);
  };

  const deleteRole = () => {
    let req = {
      _method: "DELETE"
    };
    dispatch(
      deleteEmployeeRoles(
        req,
        roleDetails.id,
        dispatch,
        setDeleteEmployerRoleModal
      )
    );
  };
  return (
    <div className="container dashboard pd-0 ed">
      <div
        className="dashboard__title headerTable"
        style={{ marginBottom: 20 }}
      >
        Roles & Access
      </div>
      {emproles && emproles.length <= 0 ? (
        <h5 style={{ fontSize: "16px" }}>No Roles Added</h5>
      ) : (
        <div className="team-list">
          <table id="roles_table">
            <thead>
              <tr>
                <th>Role Name</th>
                <th>Hourly Credit Access</th>
                <th>Monthly Credit Access</th>
                <th>Number of Employees</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {emproles.map(role => {
                return (
                  <tr key={role.id} style={{ height: 50 }}>
                    <td>{role.role_name}</td>
                    <td>
                      {role.hourly_access == 0 ||
                      role.hourly_access == null
                        ? "Unlimited"
                        : role.hourly_access}
                    </td>
                    <td>
                      {role.monthly_access == 0 ||
                      role.monthly_access == null
                        ? "Unlimited"
                        : role.monthly_access}
                    </td>
                    <td>
                      {role.invited_employees_count +
                        role.customers_count}
                    </td>
                    <td>
                      <div
                        src="/img/icons/resend.png"
                        style={{
                          cursor: "pointer",
                          color: "rgb(0, 156, 220)",
                          fontWeight: 400
                        }}
                        onClick={() => {
                          let roleData = {
                            id: role.id,
                            role_name: role.role_name,
                            hourly_access: role.hourly_access,
                            monthly_access: role.monthly_access,
                            unlimited_hourly_access:
                              role.hourly_access == "0" ||
                              role.hourly_access == null ||
                              role.hourly_access == undefined
                                ? true
                                : false,
                            unlimited_monthly_access:
                              role.monthly_access == "0" ||
                              role.monthly_access == null ||
                              role.monthly_access == undefined
                                ? true
                                : false
                          };
                          handleEditRole(roleData);
                        }}
                      >
                        Edit
                      </div>
                    </td>
                    {role.invited_employees_count +
                      role.customers_count ==
                    0 ? (
                      <td>
                        <div
                          src="/img/icons/resend.png"
                          style={{
                            cursor: "pointer",
                            color: "rgb(0, 156, 220)",
                            fontWeight: 400
                          }}
                          onClick={() => {
                            let roleData = {
                              id: role.id
                            };
                            handleDeleteRole(roleData);
                          }}
                        >
                          Delete
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="Add_employee_button">
        <button
          className="btn_add_employee"
          onClick={() => setEmployerRoleModal(true)}
        >
          ADD ROLE{" "}
        </button>
      </div>
      {editemployerRoleModal ? (
        <AddEmployerRole
          addRole={addRole}
          showHideModal={hideModal}
          roleDetails={roleDetails}
        />
      ) : (
        ""
      )}
      {employerRoleModal ? (
        <AddEmployerRole
          addRole={addRole}
          showHideModal={hideModal}
        />
      ) : (
        ""
      )}
      {deleteemployerRoleModal ? (
        <DeleteRoleModal
          modalHeader="Delete Role"
          message="Are you sure you want to Delete this Role?"
          decline={hideModal}
          accept={deleteRole}
          acceptText={"Yes"}
          declineText={"No"}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default RolesAccess;
