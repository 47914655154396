import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { AppState } from "../../store";
import { getPublicVenuesEffect } from "../VenueWizard/Actions";
import {
  setTeamInvite,
  setContactIds,
  setTeamsIds,
  setTeam_ContactError,
  setContact,
  setEmployerContacts,
  setEmployeesContactIds
} from "../Contact/Actions";
import BookingVenue from "./BookingVenue";
import AddContact from "../Dashboard/modals/AddContact";
import { DashboardApi } from "../../API/DashboardApi";
import VenueSteps from "../VenueWizard/VenueSteps";
import Footer from "../../footer";
import {
  getCreditPrice,
  getCustomerCards
} from "../Dashboard/Actions";

const BookStep2 = ({
  venueId,
  spaceId
}: {
  venueId: string;
  spaceId: any;
}) => {
  const selectStyles = {
    container: (styles: any) => ({
      width: "100%"
    }),
    control: (styles: any) => ({
      ...styles,
      minHeight: "45px",
      width: "100%",
      border: "1px solid #009cdc"
    }),
    option: (styles: any) => {
      return {
        ...styles
      };
    }
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const venue = useSelector(
    (state: AppState) => state.venueWizard.publicVenues
  ).find(v => v.id.toString() === venueId);
  const orgEmployees: any = useSelector(
    (state: AppState) => state.Booking.MyorgEmployees!
  );
  const space =
    venue && venue.spaces.find(space => space.id === Number(spaceId));
  const contacts = useSelector(
    (state: AppState) => state.Contacts.myContacts
  );
  const booking = useSelector(
    (state: AppState) => state.Booking.BookingFields
  );
  const teams = useSelector(
    (state: AppState) => state.Contacts.myTeams
  );
  const [invitedContacts, setInvitedContacts]: any = useState();
  const [invitedContactss, setInvitedEmployees]: any = useState([]);
  const [invitedTeams, setInvitedTeams]: any = useState();
  const [showAddContactModal, setshowAddContactModal] =
    useState(false);
  const token = useSelector((state: AppState) => state.auth.token);
  const [teamList, setteamList] = useState([]);
  const [contactPeople, setContactPeople] = useState(contacts);
  // const preInvitedContacts = useSelector(
  //   (state: AppState) => state.Contacts.myContacts
  // );
  const [teamLength, setTeamLength] = useState(Number);
  const [invitedLength, setInvitedLength] = useState(Number);
  const [invitedContactsLength, setInvitedContactsLength] =
    useState(Number);
  const [invitedEmployeesIds, setInvitedEmployeesIds]: any =
    useState();
  const [checkSpaceCapacity, setcheckSpaceCapacity] = useState(false);
  const [allContatsList, setAllContats]: any = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inviteButtonDisable, setInviteButtonDisable] =
    useState(false);
  const [showSpaceMessage, setShowSpaceMessage] = useState(false);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const singleContact = useSelector(
    (state: AppState) => state.Contacts.singleContact
  );

  useEffect(() => {
    dispatch(getPublicVenuesEffect());
    dispatch(getCreditPrice());
    dispatch(getCustomerCards());
    hasCapacityExceeded();
  }, []);

  useEffect(() => {
    if (
      booking &&
      (orgEmployees.length > 0 || contactPeople.length > 0)
    ) {
      if (contactPeople.length > 0) {
        const filtered_contacts = contactPeople.map((item: any) => {
          item["label"] = item.full_name;
          item["value"] = item.id;
          return item;
        });
        let allContacts: any = [
          ...orgEmployees,
          ...filtered_contacts
        ];
        setAllContats(allContacts);
        dispatch(setEmployerContacts(allContacts));
      } else {
        setAllContats(orgEmployees);
        dispatch(setEmployerContacts(orgEmployees));
      }
    }
  }, [orgEmployees, contactPeople]);

  useEffect(() => {
    if (booking) {
      let invitedPeople: any = [];
      let totalLength = 0;
      if (booking && booking.contacts) {
        let conatctIds: any = [];
        var invitedContacts =
          contactPeople &&
          contactPeople.filter(
            (element: any) =>
              booking.contacts.includes(element.id) ||
              booking.contacts.some(e => e.id === element.id)
          );
        invitedContacts.forEach((element: any) => {
          conatctIds.push(element.id);
        });
        invitedPeople.push([...invitedContacts]);
        totalLength = totalLength + invitedContacts.length;
        setInvitedContactsLength(totalLength);
        setInvitedContacts(conatctIds);
      }
      if (booking && booking.teams) {
        setInvitedTeams(booking.teams);
        let teamlength = 0;
        booking.teams.forEach(teamId => {
          let index = booking.teams.indexOf(teamId);
          if (index > -1) {
            let teamCount = teams.find(v => v.id === teamId);
            teamlength += teamCount.contact_count;
          }
        });
        let totalTeamLength = teamlength;
        setTeamLength(totalTeamLength);
      }
      if (booking && booking.invited_employees) {
        let employeesIds: any = [];
        var invitedEMployees =
          orgEmployees &&
          orgEmployees.filter((element: any) =>
            booking.invited_employees.includes(element.id)
          );
        invitedEMployees.forEach((element: any) => {
          employeesIds.push(element.id);
        });

        setInvitedEmployees([
          ...invitedPeople[0],
          ...invitedEMployees
        ]);
        let contactsInvitedLenght =
          invitedEMployees.length > 0
            ? invitedEMployees.length + totalLength
            : invitedContactsLength + totalLength;

        setInvitedContactsLength(contactsInvitedLenght);

        setInvitedEmployeesIds(employeesIds);
      }
    }
  }, [booking]);

  useEffect(() => {
    getTeamList();
  }, [token]);

  useEffect(() => {
    setContactPeople(contacts);
  }, [contacts]);

  // useEffect(() => {
  //   hasCapacityExceeded();
  // }, [teamLength]);
  // useEffect(() => {
  //   hasCapacityExceeded();
  // }, [invitedLength]);

  useEffect(() => {
    hasCapacityExceeded();
  }, [invitedContactsLength, invitedLength, teamLength]);

  const addContact = async (e: any) => {
    let data = e;
    let response: any = await DashboardApi.CreateContact(
      token!,
      data
    );
    if (response.data) {
      response.data.status = true;
      dispatch(setContact(response));
      dispatch(setTeam_ContactError({ status: false, message: "" }));
      setshowAddContactModal(false);
      response.data["lable"] = response.data.full_name;
      response.data["value"] = response.data.id;

      setInvitedEmployees([...invitedContactss, response.data]);
      setInvitedContactsLength(invitedContactss.length + 1);
      setInvitedContacts([...invitedContacts, response.data.id]);

      toast.success("Contact Successfully Invited");
    } else {
      dispatch(
        setTeam_ContactError({
          status: true,
          message: "Email has already been taken."
        })
      );
    }
  };

  const showHideModal = (type: string, state: any) => {
    setshowAddContactModal(state);
  };

  const getTeamList = async () => {
    let response = await DashboardApi.GetTeams(token!);
    setteamList(response.data);
  };

  const hasCapacityExceeded = () => {
    let total =
      Number(invitedLength + teamLength + invitedContactsLength) + 1;
    let sum =
      Number(booking && booking.no_of_people) *
      Number(booking.no_of_booked_spaces);
    setcheckSpaceCapacity(total > sum ? true : false);
    if (total >= sum) {
      setInviteButtonDisable(true);
    } else {
      setInviteButtonDisable(false);
    }
    if (total > sum) {
      return true;
    }
    return false;
  };

  const handleInviteTeam = (event: any, teamId: number) => {
    let invitedTeamsIds = invitedTeams ? invitedTeams : [];
    let index = invitedTeamsIds.indexOf(teamId);

    //causingissue
    // index > -1
    //   ? invitedTeamsIds.splice(index, 1)
    //   : invitedTeamsIds.push(teamId);

    let temp = 0;
    if (index > -1) {
      let teamCount = teams.find(v => v.id === teamId);
      temp =
        teamLength !== 0 ? teamLength - teamCount.contact_count : 0;
    } else {
      let TeamContactsCount = teams.find(
        v => v.id === teamId
      ).contact_count;

      temp =
        teamLength !== 0
          ? teamLength + TeamContactsCount
          : TeamContactsCount;
    }
    let total =
      Number(invitedLength + invitedContactsLength) + 1 + temp;

    if (total > booking.no_of_people) {
      toast.error(
        "Cannot Invite Number of people exceeds workspace capacity"
      );
      setShowSpaceMessage(true);
    } else {
      if (
        event.target.classList.contains("btn-white") ||
        event.target.classList.contains("btn-grey")
      ) {
        event.target.classList.remove("btn-white");
        event.target.classList.add("btn-blue");
        event.target.innerText = "INVITED";
        toast.success("Successfully Invited");
      } else {
        event.target.classList.remove("btn-blue");
        event.target.classList.add("btn-white");
        event.target.innerText = "INVITE";
        toast.success("Successfully Uninvited");
      }
      index > -1
        ? invitedTeamsIds.splice(index, 1)
        : invitedTeamsIds.push(teamId);
      setTeamLength(temp);
      setInvitedTeams(invitedTeamsIds);
      dispatch(setTeamInvite(teamId));
    }
  };

  const handleInviteEmployees = (event: any) => {
    if (event) {
      let seletedEmployees: any = [];
      let employeesIds: any = [];
      let contactIds: any = [];
      event.forEach((element: any) => {
        seletedEmployees.push(element);
        if (element.entity_type == "employee") {
          employeesIds.push(element.id);
        }
        if (element.entity_type == "contact") {
          contactIds.push(element.id);
        }
      });

      if (seletedEmployees.length < invitedContactss.length) {
        toast.success("Successfully Uninvited");
      } else {
        if (inviteButtonDisable) {
          toast.error(
            "Cannot Invite Number of people exceeds workspace capacity"
          );
          setShowSpaceMessage(true);
          return;
        }
        if (!hasCapacityExceeded()) {
          toast.success("Successfully Invited");
        }
      }
      setInvitedEmployees(seletedEmployees);
      setInvitedContactsLength(seletedEmployees.length);
      setInvitedEmployeesIds(employeesIds);
      setInvitedContacts(contactIds);
    } else {
      setInvitedEmployees([]);
      setInvitedContactsLength(0);
      setInvitedEmployeesIds([]);
      setInvitedContacts([]);
    }
  };

  const setAddedContactsInvited = () => {
    let contactId = singleContact && singleContact.id;
    contactId = contactId + 1;
    let contactIds: any = invitedContacts ? invitedContacts : [];
    let index = contactIds.indexOf(contactId);
    index > -1
      ? contactIds.splice(index, 1)
      : contactIds.push(contactId);
    setInvitedContacts(contactIds);
    setInvitedLength(contactIds.length);
  };

  const handleSubmit = () => {
    dispatch(setTeamsIds(invitedTeams));
    dispatch(setContactIds(invitedContacts));
    dispatch(setEmployeesContactIds(invitedEmployeesIds));
    history.push(`/book/3/${venueId}/${spaceId}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          title="Dorpee Venue Bookings"
          content="Dorpee-workspaces on demand"
        />
        <title>
          {`Dorpee - ${venue && venue.name} - ${
            space && space.name
          } - 2`}
        </title>
      </Helmet>
      <div className="body">
        <div className="container containerAddSpace">
          <VenueSteps step={2} isForBooking></VenueSteps>
          <br />

          <div className="page-wrapper">
            <BookingVenue firstPage venue={space} />
            <div>
              <div className="inv-person__wrap">
                <div className="booking-search__tags-title">
                  Invite Team
                </div>
                {teams.length === 0 ? (
                  <>
                    <p>You currently do not have any teams</p>
                  </>
                ) : (
                  <>
                    {teams.map(team => {
                      if (team.contact_count > 0) {
                        return (
                          <div key={team.id} className="person-row">
                            <div className="person-row__left">
                              <div className="person-name">
                                {team.name}
                              </div>
                            </div>
                            <div className="person-row__btn">
                              {team.status ||
                              (invitedTeams &&
                                invitedTeams.some(
                                  (contactId: any) =>
                                    contactId == team.id
                                )) ? (
                                <button
                                  onClick={e =>
                                    handleInviteTeam(e, team.id)
                                  }
                                  className={
                                    "btn btn-blue wizard__btn mb-0"
                                  }
                                >
                                  {"INVITED"}
                                </button>
                              ) : (
                                <button
                                  onClick={e =>
                                    handleInviteTeam(e, team.id)
                                  }
                                  className={
                                    inviteButtonDisable
                                      ? "btn btn-grey wizard__btn mb-0"
                                      : "btn btn-white wizard__btn mb-0"
                                  }
                                >
                                  {"INVITE"}
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                )}
              </div>
              <div className="inv-person__wrap">
                <div className="booking-search__tags-title">
                  Invite Contacts{" "}
                </div>
                {allContatsList?.length === 0 ? (
                  <>
                    <p>You currently do not have Any Contacts</p>
                  </>
                ) : (
                  <div
                    style={{ position: "relative", display: "flex" }}
                  >
                    <Select
                      name="employee_id"
                      styles={selectStyles}
                      value={invitedContactss}
                      onChange={handleInviteEmployees}
                      isMulti
                      placeholder="Select Contacts"
                      options={
                        allContatsList && allContatsList.length > 0
                          ? allContatsList
                          : []
                      }
                      onMenuClose={onMenuClose}
                      onMenuOpen={onMenuOpen}
                    />
                    <div
                      className="col-md-1 tooltip"
                      style={{
                        maxWidth: "3%",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <img
                        style={{
                          maxWidth: "20px",
                          color: "red"
                        }}
                        src="/img/info.png"
                      />
                      <span
                        className="tooltiptext"
                        style={{
                          bottom: "80%",
                          maxWidth: "12rem"
                        }}
                      >
                        Selecting names here will invite them to the
                        booking
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {!checkSpaceCapacity && (
                <div className="filter-block">
                  <button
                    className={
                      inviteButtonDisable
                        ? "btn btn-grey wizard__btn mb-0"
                        : "btn btn-white wizard__btn mb-0"
                    }
                    onClick={e => {
                      if (inviteButtonDisable) {
                        toast.error(
                          "Cannot Invite Number of people exceeds workspace capacity"
                        );
                        setShowSpaceMessage(true);
                      } else {
                        setshowAddContactModal(true);
                      }
                    }}
                  >
                    Invite Attendee
                    <img
                      className="wizard__btn--img-left"
                      src="/img/icons/add-contacts.svg"
                      alt=""
                    />
                  </button>
                </div>
              )}
              {showAddContactModal ? (
                <AddContact
                  teamList={teamList}
                  isBookingContactModal={true}
                  addContact={addContact}
                  showHideModal={showHideModal}
                  setAddedContactsInvited={setAddedContactsInvited}
                  isCloseModal={false}
                  isTeamHidden
                />
              ) : null}
            </div>
            {checkSpaceCapacity ? (
              <div style={{ color: "red", marginTop: 5 }}>
                Number of invited people has exceeded total Number of
                people in Booking.{" "}
              </div>
            ) : null}
            {showSpaceMessage ? (
              <div style={{ color: "red", marginTop: 5 }}>
                Please edit number of attendees from previous step or
                select a different workspace
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 25,
                marginBottom: 60
              }}
            >
              <button
                onClick={() =>
                  history.push(`/book/1/${venueId}/${spaceId}`)
                }
                className={`btn btn-white wizard__btn bookings-process__btn`}
                style={{ width: "45%" }}
              >
                Back
              </button>
              <button
                onClick={() => handleSubmit()}
                disabled={checkSpaceCapacity}
                className={
                  !checkSpaceCapacity
                    ? `btn btn-blue2 wizard__btn bookings-process__btn`
                    : `btn btn-disable wizard__btn bookings-process__btn`
                }
                style={{ width: "45%" }}
              >
                NEXT
                <img src="/img/icons/arrow-forward.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <Footer
        //  isSubs
        />
      </div>
    </>
  );
};

export default BookStep2;
