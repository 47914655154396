import { Reducer } from 'react'
import { NotificationsState } from '../../store'
import { NotificationsActions } from './Actions'
// import { Notification } from '../../API/AuthApi'

const initialState: NotificationsState = {
    data: {
        unread: [],
        other: []
    },
}

export const notificationsReducer: Reducer<NotificationsState, NotificationsActions> = (state: NotificationsState = initialState, action) => {
    switch (action.type) {
        case '@@NOTIFICATIONS/NOTIFICATIONS_STARTED':
            return initialState
        case '@@NOTIFICATIONS/NOTIFICATIONS_LOADED':
            return { ...state, data: action.notification }
        default:
            return state
    }
}