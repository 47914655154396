/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddToCalendar from "react-add-to-calendar";
import moment from "moment-timezone";

import {
  getFormated12hTimeNew,
  getFormatedDate
} from "../../helpers/DateHelpers";
import {
  ChangeBookingStatusEffect,
  setBooking
} from "../../Screens/Book/Booking/Actions";
import Confirmation from "../Dashboard/modals/Confirmation";

interface props {
  booking: any;
  handlCheckinValdiation?: any;
  setModalData?: any;
  setshowModal?: any;
  handlEditValdiation?: any;
  openConfirmationBox?: any;
  setBookingDetails?: any;
}

const BookingCard: FC<props> = ({
  booking,
  handlCheckinValdiation,
  setModalData,
  setshowModal,
  handlEditValdiation,
  openConfirmationBox,
  setBookingDetails
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [bookingDate, setBookingDate]: any = useState();

  const hadleCalendarModal = (start_date: any) => {
    let date = start_date.split("/");
    setBookingDate(`${date[2]}-${date[0]}-${date[1]}`);
    setIsOpen(!isOpen);
  };

  const [address, setAddress] = useState("");
  // const [showEditCancel, setShowEditCancel] = useState(false);
  const [earlyCheckInModal, setEarlyCheckInModal] = useState(false);
  const [checkInModal, setCheckInModal] = useState(false);
  const [checkOutModal, setCheckOutModal] = useState(false);

  const [bookingDescriptions, setbookingDescriptions] = useState("");
  const geocoder = new window.google.maps.Geocoder();

  const latlng = {
    lat: parseFloat(booking.space.venue!?.lat!),
    lng: parseFloat(booking.space.venue!?.lng!)
  };
  const getAddress = async () => {
    return await geocoder
      .geocode({ location: latlng })
      .then((response: any) => {
        setAddress(response.results[0].formatted_address);
      })
      .catch(e => window.alert("Geocoder failed due to: " + e));
  };

  // useEffect(() => {
  //   let end_date = new Date(booking.end_date);
  //   let start_date = new Date(booking.start_date);

  //   let time = booking.end_time.split(":");
  //   let time_start = booking.start_time.split(":");
  //   end_date.setHours(time[0], time[1], time[2]);
  //   start_date.setHours(time_start[0], time_start[1], time_start[2]);
  //   const diff: any = start_date.getTime() - new Date().getTime();
  //   const minDiff = Math.round(diff / 60000);
  //   if (minDiff < 15) {
  //     setShowEditCancel(true);
  //   }
  // }, []);

  useEffect(() => {
    if (isOpen) {
      getAddress();
      setbookingDescriptions(
        `Booking credits: ${
          booking.credits ? booking.credits : 0
        } Cancellation Preiod: ${
          booking.space.cancellation_period == 0
            ? "Cancel or update anytime"
            : `Cancel in ${booking.space.cancellation_period} hrs notice`
        } Booking Number: ${
          booking.booking_number
        } Total Booked Spaces: ${
          booking.no_of_booked_spaces
        } No of people: ${booking.no_of_people}`
      );
    }
  }, [isOpen]);

  const handleCheckIn = () => {
    dispatch(
      ChangeBookingStatusEffect({
        booking_id: booking.id,
        status: "Checked-In"
      })
    );
    if (
      booking.space.venue.wifi_name !== "" &&
      booking.space.venue.wifi_password !== "" &&
      booking.space.venue.wifi_name !== null &&
      booking.space.venue.wifi_password !== null &&
      booking.status === "Scheduled"
    ) {
      setModalData({
        wifi_name: booking.space.venue.wifi_name,
        wifi_password: booking.space.venue.wifi_password
      });
      setshowModal(true);
    }
  };

  const formatAddress = (venue: any) => {
    if (venue) {
      return [
        venue && venue.address,
        venue && venue.suburb,
        venue && venue.postcode,
        venue && venue.state
      ]
        .filter(v => !!v)
        .join(" ");
    }
  };
  return (
    <div className="booking_container" key={booking.id}>
      <div
        className="booking_img"
        onClick={() =>
          history.push(`/workspace/${booking?.space?.venue?.slug}`)
        }
      >
        <img src={booking.space!.images[0]} alt="venue-image" />{" "}
        <div className="space_info">
          <p className="title">{booking.space?.venue!?.name!}</p>
          <p className="title" style={{ marginBottom: 10 }}>
            {booking.space.name}
          </p>
          <span>{formatAddress(booking.space?.venue!)}</span>
        </div>
      </div>
      <div className="booking_details">
        <div className="booking_date">
          <p>{getFormatedDate(booking.start_date)}</p>
          <p>
            {getFormated12hTimeNew(booking.start_time)} -{" "}
            {getFormated12hTimeNew(booking.end_time)}
          </p>
          <p>
            {booking.no_of_people > 1
              ? `${booking.no_of_people} people`
              : `${booking.no_of_people} person`}
          </p>
          {booking.booking_status != "past" &&
          booking.status != "Cancelled" ? (
            <p>
              {booking.space.cancellation_period == 0
                ? "Cancel or update anytime"
                : `Cancel in ${booking.space.cancellation_period} hrs notice`}
            </p>
          ) : null}
        </div>
        <div className="booking_credits">
          <span className="creditBasic">
            {booking.credits ? booking.credits : 0}
          </span>
          credits
        </div>
        <div className="booking_actions">
          {/* <p>Invitees</p> */}
          {booking.booking_status != "past" &&
          booking.status != "Cancelled" &&
          booking.status != "Checked-Out" ? (
            <>
              {!isOpen && (
                <p
                  onClick={() => {
                    hadleCalendarModal(booking.start_date);
                  }}
                >
                  Add To Calendar
                </p>
              )}
              {isOpen && (
                <>
                  <AddToCalendar
                    buttonLabel="Add To calendar"
                    event={{
                      title: `New Booking at ${booking.space?.venue?.name}`,
                      description: bookingDescriptions,
                      location: address,
                      startTime: moment
                        .tz(
                          `${bookingDate}T${booking.start_time.slice(
                            0,
                            5
                          )}`,
                          "Australia/Sydney"
                        )
                        .format(),
                      endTime: moment
                        .tz(
                          `${bookingDate}T${booking.end_time.slice(
                            0,
                            5
                          )}`,
                          "Australia/Sydney"
                        )
                        .format()
                    }}
                    listItems={[
                      { apple: "Apple Calendar" },
                      { google: "Google" },
                      { outlook: "Outlook" }
                    ]}
                    optionsOpen={true}
                  />
                </>
              )}
              {booking.status === "Scheduled" ? (
                <>
                  {/* {!showEditCancel && ( */}
                  <p
                    onClick={() => {
                      handlEditValdiation(booking);
                    }}
                  >
                    Edit
                  </p>
                  {/* )} */}
                  <div className="tooltip">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                      onClick={() => {
                        let end_date = new Date(booking.end_date);
                        let start_date = new Date(booking.start_date);

                        let time = booking.end_time.split(":");
                        let time_start =
                          booking.start_time.split(":");
                        end_date.setHours(time[0], time[1], time[2]);
                        start_date.setHours(
                          time_start[0],
                          time_start[1],
                          time_start[2]
                        );

                        if (
                          end_date.getTime() < new Date().getTime()
                        ) {
                          handlCheckinValdiation();
                        } else {
                          const diff: any =
                            start_date.getTime() -
                            new Date().getTime();

                          const minDiff = Math.round(diff / 60000);
                          if (minDiff >= 15) {
                            setEarlyCheckInModal(true);
                            return;
                          } else {
                            setCheckInModal(true);
                          }
                        }
                      }}
                    >
                      Check In
                      <span
                        className="tooltiptext"
                        style={{
                          textTransform: "none",
                          width: "12rem"
                        }}
                      >
                        This will start your booking.
                      </span>
                    </p>
                  </div>

                  {/* {!showEditCancel && ( */}
                  <p
                    onClick={() =>
                      openConfirmationBox(booking.id, booking, false)
                    }
                  >
                    Cancel
                  </p>
                  {/* )} */}
                </>
              ) : null}
              {booking.status === "Checked-In" ? (
                <>
                  <div className="tooltip">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                      onClick={() => {
                        setCheckOutModal(true);
                      }}
                    >
                      Check Out
                      <span
                        className="tooltiptext"
                        style={{
                          textTransform: "none",
                          width: "16rem"
                        }}
                      >
                        This will complete your booking.
                      </span>
                    </p>
                  </div>
                  <p
                    onClick={() => {
                      openConfirmationBox(booking.id, booking, true);
                      setBookingDetails(booking);
                    }}
                  >
                    Extend
                  </p>
                </>
              ) : (
                ""
              )}
            </>
          ) : null}

          {(booking.booking_status == "past" &&
            booking.status != "Cancelled") ||
          (booking.booking_status == "active" &&
            booking.status == "Checked-Out") ? (
            <>
              <p
                onClick={() => {
                  history.push(`/review/${booking.booking_number}`);
                }}
              >
                Review
              </p>
              <p
                onClick={() => {
                  let bookingData = {
                    start_time: booking.start_time,
                    end_time: booking.end_time,
                    booking_as: booking.booking_as,
                    no_of_people: booking.no_of_people,
                    no_of_booked_spaces: booking.no_of_booked_spaces,
                    duration: booking.duration
                  };
                  dispatch(setBooking(bookingData));
                  history.push(
                    `/book/1/${booking.space.venue_id}/${booking.space_id}`
                  );
                }}
              >
                Rebook
              </p>
            </>
          ) : null}
          {booking.status === "Cancelled" ? (
            <>
              <p style={{ color: "grey", cursor: "default" }}>
                {booking.refunded == 1 ? "Refunded" : "Paid"}
              </p>
              <p
                onClick={() => {
                  let bookingData = {
                    start_time: booking.start_time,
                    end_time: booking.end_time,
                    booking_as: booking.booking_as,
                    no_of_people: booking.no_of_people,
                    no_of_booked_spaces: booking.no_of_booked_spaces,
                    duration: booking.duration
                  };
                  dispatch(setBooking(bookingData));
                  history.push(
                    `/book/1/${booking.space.venue_id}/${booking.space_id}`
                  );
                }}
              >
                Rebook
              </p>
            </>
          ) : null}
        </div>
      </div>{" "}
      {earlyCheckInModal && (
        <Confirmation
          message={"You're early! Are you sure you want to check in?"}
          hintMessage={"This will start your booking."}
          decline={() => setEarlyCheckInModal(false)}
          accept={() => {
            setEarlyCheckInModal(false);
            handleCheckIn();
          }}
          acceptText={"Yes"}
          declineText={"No"}
          modalHeader={"Early Check In"}
          showButtons={true}
        ></Confirmation>
      )}
      {checkInModal && (
        <Confirmation
          message={"Are you sure you want to check in?"}
          hintMessage={"This will start your booking."}
          decline={() => setCheckInModal(false)}
          accept={() => {
            setCheckInModal(false);
            handleCheckIn();
          }}
          acceptText={"Yes"}
          declineText={"No"}
          modalHeader={"Check In"}
          showButtons={true}
        ></Confirmation>
      )}
      {checkOutModal && (
        <Confirmation
          message={"Are you sure you want to check out?"}
          hintMessage={"This will complete your booking."}
          decline={() => setCheckOutModal(false)}
          accept={() => {
            setCheckOutModal(false);
            dispatch(
              ChangeBookingStatusEffect({
                booking_id: booking.id,
                status: "Checked-Out"
              })
            );
          }}
          acceptText={"Yes"}
          declineText={"No"}
          modalHeader={"Check Out"}
          showButtons={true}
        ></Confirmation>
      )}
    </div>
  );
};

export default BookingCard;
