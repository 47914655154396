import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import VenueDashboard from "./VenueDashboard";
import EmployerDashboard from "./EmployerDashboard";
import CustomerDashboard from "./CustomerDashboard";
import { getProfileEffect, updateUser } from "../Auth/Actions";
import { getNotifications } from "../Notification/Actions";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { DashboardApi } from "../../API/DashboardApi";
import { getTokenDashboard } from "./Actions";
import { getCustomerEmployeesEffect } from "../Book/Booking/Actions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const params: any = useParams();

  useEffect(() => {
    if (window.location.href.indexOf("venue") > -1) {
      if (params) {
        getDashboard("venue");
      }
    }
    if (window.location.href.indexOf("employer") > -1) {
      if (params) {
        getDashboard("employee");
      }
    }
    dispatch(getProfileEffect());
  }, []);

  useEffect(() => {
    dispatch(getCustomerEmployeesEffect());
  }, []);

  const getDashboard = async (data: string) => {
    const response = await DashboardApi.GetEmployerDashboardADmin(
      params.id
    );
    if (!response.error) {
      if (data === "employee") {
        setUserDashboard(response.user);
        dispatch(updateUser(response.user));
        setToken(response.token);
        dispatch(getTokenDashboard(response.token));
      } else if (data === "venue") {
        setUserDashboard(response.user);
        setVenueToken(response.token);
        dispatch(getTokenDashboard(response.token));
      }
    }
  };

  const user = useSelector((state: AppState) => state.auth.user);
  const role = useSelector((state: AppState) => state.auth.userRoles);
  const [token, setToken]: any = useState("");
  const [venueToken, setVenueToken] = useState("");
  const [userDashboard, setUserDashboard] = useState();
  const Notification = useSelector(
    (state: AppState) => state.notifications
  );
  if (token != "") {
    return (
      <EmployerDashboard
        param={params}
        token={token}
        userDashboard={userDashboard}
      />
    );
  }
  if (venueToken != "") {
    return <VenueDashboard param={params} venueToken={venueToken} />;
  }
  if (!user) {
    return <></>;
  }

  // if (user.role.some(r => r.title === 'Venue')) {
  if (role && role === "Venue") {
    return <VenueDashboard />;
  }

  // if (user && user.role.some(r => r.title === 'Customer')) {
  if (role && role === "Customer") {
    return <CustomerDashboard />;
  }

  if (user && user.role.some(r => r.title === "Employer")) {
    if (role && role === "Employer") {
      return <EmployerDashboard param={params} />;
    }
  }
  return <></>;
};

export default Dashboard;
