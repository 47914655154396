import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
class WifiDetailsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.wifiName,
      password: this.props.wifiPassword,
    };
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box" style={{ textAlign: "center" }}>
            <div className="congrat-box__header">
              <span>Wifi Details</span>
              <div
                onClick={() => this.props.handleToggleModal(false)}
                className="close-btn"
              ></div>
            </div>
            <div className="booking-search__tags-title">
              Remember Wifi Credentials.
            </div>
            <div className="addressbook-form">
              <p>
                Wifi Name:{" "}
                <span
                  style={{ fontSize: "18px", fontWeight: 500, marginLeft: 10 }}
                >
                  {this.state.name}
                </span>
              </p>
              <div className="addressbook-form">
                <p>
                  Wifi Password:
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      marginLeft: 10,
                    }}
                  >
                    {this.state.password}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default WifiDetailsComponent;
