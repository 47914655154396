import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { VenueApi } from "../../../API/VenueApi";
import { getVenuesEffect } from "../../VenueWizard/Actions";
import Confirmation from "../../../Screens/Dashboard/modals/Confirmation";
import { toast } from "react-toastify";
class WorkspaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Are you sure you want to delete this space?",
      header: "DELETE SPACE",
      id: 0,
      isConfimrationBoxOpen: false,
    };
  }

  openConfirmationBox = async (id) => {
    this.props.openConfirmationBox(id);
  };

  render() {
    return (
      <div>
        <li class="clearfix">
          <div class="row">
            <div class="col-md-7">
              <div class="workspace">
                <div class="thumbnail">
                  <img
                    src={this.props.space.images && this.props.space.images[0]}
                    alt=""
                  />
                </div>
                <div class="name">
                  <p>{this.props.space.name}</p>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="meta">
                <div class="room">
                  <input type="text" value="2" class="form-control" />
                </div>
                <div class="number">
                  <span class="image">
                    <img src="/img/ico-people.png" alt="" />
                  </span>
                  <span class="image">
                    <img src="/img/ico-people.png" alt="" />
                  </span>
                  <span class="image">
                    <img src="/img/ico-people.png" alt="" />
                  </span>
                  <span class="is-number">{this.props.space.capacity}</span>
                </div>
                <div class="edit">
                  <button
                    onClick={() => {
                      window.localStorage.setItem("venue_id", this.props.id);
                      this.props.history.push(
                        `/edit-workspace/${this.props.space.id}`
                      );
                    }}
                    class="btn btn-edit"
                  ></button>
                </div>
                <div class="delete">
                  <button
                    onClick={() => {
                      this.openConfirmationBox(this.props.space.id);
                    }}
                    class="btn btn-delete"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.token,
});

const mapDispatchToProps = {
  getVenuesEffect,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspaceList)
);
