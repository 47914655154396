import { ApiClient } from "./ApiClient";
import { User } from "./AuthApi";
import { Environment, Service, VenueType, Asset } from "./EnumsApi";
import { SingleBooking } from "./BookingAPI";

export class VenueApi {
  static UpdateVenue(
    token: string,
    venueId: string,
    request: CreateVenueRequest
  ) {
    return ApiClient.postMultipart<CreateVenueRequest, { data: any }>(
      `user/venues/${venueId}`,
      request,
      token
    );
  }
  static UpdateVenue_vaccination(
    token: string,
    id: string,
    request: UpdateVenueVaccinationRequest
  ) {
    return ApiClient.put<
      UpdateVenueVaccinationRequest,
      { data: any }
    >(`user/venues/update-vaccination-status/${id}`, request, token);
  }
  static CreateVenue(token: string, request: CreateVenueRequest) {
    return ApiClient.postMultipart<CreateVenueRequest, { data: any }>(
      "user/venues",
      request,
      token
    );
  }

  static GetVenues(token: string) {
    return ApiClient.get<VenuesResponse>("user/venues", token);
  }
  static PostSaveSearch(
    token: string,
    request: CreateSaveSearchClickRequest
  ) {
    return ApiClient.post<CreateSaveSearchClickRequest, {}>(
      "user/search/save",
      request,
      token
    );
  }
  static GetVenuebyId(
    id: string,
    token?: string,
    capacity?: number,
    start_time?: any,
    end_time?: any,
    date?: any
  ) {
    let queryString = "?";
    if (capacity) {
      queryString += `capacity=${capacity}`;
    }
    if (start_time) {
      queryString += `&start_time=${start_time}`;
    }
    if (end_time) {
      queryString += `&end_time=${end_time}`;
    }
    if (date) {
      queryString += `&date=${date}`;
    }
    return ApiClient.get<VenueResponse>(
      `venues/${id}${queryString}`,
      token
    );
  }
  static GetVenuebyslug(
    id: string,
    token?: string,
    capacity?: number,
    start_time?: any,
    end_time?: any,
    date?: any
  ) {
    let queryString = "?";
    if (capacity) {
      queryString += `capacity=${capacity}`;
    }
    if (start_time) {
      queryString += `&start_time=${start_time}`;
    }
    if (end_time) {
      queryString += `&end_time=${end_time}`;
    }
    if (date) {
      queryString += `&date=${date}`;
    }
    return ApiClient.get<VenueResponse>(
      `venues/by-slug/${id}${queryString}`,
      token
    );
  }
  static GetPublicVenues() {
    return ApiClient.get<VenuesResponse>("venues/all");
  }
  static GetWorkspacesForYouVenues(token: string) {
    return ApiClient.post<{}, VenuesResponse>(
      "user/search/background",
      {},
      token
    );
  }
  static SearchVenuesBasic(
    request: VenueBasicSearchRequest,
    token?: string
  ) {
    if (token) {
      return ApiClient.post<VenueBasicSearchRequest, VenuesResponse>(
        "search/basic",
        request,
        token
      );
    } else {
      return ApiClient.post<VenueBasicSearchRequest, VenuesResponse>(
        "search/basic",
        request
      );
    }
  }
  static EditPreference(
    id: any,
    request: EditPreferenceRequest,
    token: string
  ) {
    return ApiClient.post<EditPreferenceRequest, any>(
      `user/search/preferences/${id}`,
      request,
      token
    );
  }
  static CloseVenueStatus(
    token: string,
    id: number,
    request: CreateVenueCloseStatusRequest
  ) {
    return ApiClient.post<
      CreateVenueCloseStatusRequest,
      SingleBooking
    >(`user/venues/change-status/${id}`, request, token);
  }
  static CloseSpaceStatus(
    token: string,
    id: number,
    request: CreateVenueCloseStatusRequest
  ) {
    return ApiClient.post<
      CreateVenueCloseStatusRequest,
      SingleBooking
    >(`user/spaces/change-status/${id}`, request, token);
  }
  static SearchAdvanceVenuesBasic(
    token: string,
    request: VenueAdvSearchRequest
  ) {
    if (token) {
      return ApiClient.post<VenueAdvSearchRequest, VenuesResponse>(
        "search/advance",
        request,
        token
      );
    } else {
      return ApiClient.post<VenueAdvSearchRequest, VenuesResponse>(
        "search/advance",
        request
      );
    }
  }
  static getVenuesStats(
    token: string,
    filter: string,
    venue_id?: number
  ) {
    var url = `venue/revenue-duration/stats?filter=${filter}`;
    if (venue_id && filter) {
      url = `venue/revenue-duration/stats?venue_id=${venue_id}&filter=${filter}`;
    }

    return ApiClient.get<VenueStatsResponse>(url, token);
  }
  static getAssessment() {
    return ApiClient.get<VenuesAssessment>("questions");
  }
  static getUserAssessment(token: string) {
    return ApiClient.get<VenuesAssessment>("venue/assessment", token);
  }
  static postAssessment(
    token: string,
    request: CreateVenueAssesRequest
  ) {
    return ApiClient.post<CreateVenueAssesRequest, {}>(
      "venue/assessment",
      request,
      token
    );
  }
  static postVaccinationStatus(
    token: string,
    request: CreateVenueVaccinationRequest
  ) {
    return ApiClient.post<CreateVenueVaccinationRequest, {}>(
      `venues/${request.venue_id}`,
      request,
      token
    );
  }
  static postVenueRating(request: CreateVenueRatingRequest) {
    return ApiClient.post<CreateVenueRatingRequest, {}>(
      "venue/rating",
      request
    );
  }
  static deleteVenue(token: string, venue_id: number) {
    return ApiClient.delete(`user/venues/${venue_id}`, token);
  }
  static deleteSpace(token: string, space_id: number) {
    return ApiClient.delete(`user/spaces/${space_id}`, token);
  }
  static venuesNearMe(lat: number, lng: number) {
    return ApiClient.get<VenuesNearmeResponse>(
      `venues-nearby?lat=${lat}&lng=${lng}`
    );
  }
  static venueOpenHours(
    token: string,
    request: CreateVenueOpenHoursRequest
  ) {
    return ApiClient.post<CreateVenueOpenHoursRequest, {}>(
      "user/venue-availabilities",
      request,
      token
    );
  }
  static getVenueOpenHours(token: string) {
    return ApiClient.get<getVenueOpenHoursResponse>(
      "user/venue-availabilities",
      token
    );
  }
  static postSpaceBlockDay(
    token: string,
    request: CreateSpaceBlockingRequest
  ) {
    return ApiClient.post<
      CreateSpaceBlockingRequest,
      { data: { message: any } }
    >("user/availabilities", request, token);
  }
  static getSpaceBlockDay(token: string) {
    return ApiClient.get<SpaceBlockdaysResponse>(
      "user/availabilities",
      token
    );
  }
  static unBlockSpaceDay(
    token: string,
    request: CreateSpaceUnBlockingRequest
  ) {
    return ApiClient.post<
      CreateSpaceUnBlockingRequest,
      SpaceBlockdaysResponse
    >("user/availabilities/unblock", request, token);
  }
  static postVenueAccontDetails(
    token: string,
    request: VenueAccountDetailsRequest
  ) {
    return ApiClient.post<
      VenueAccountDetailsRequest,
      { data: { data: any; message: any } }
    >("payment/venue", request, token);
  }
  static updateWifi(
    token: string,
    venueId: number,
    request: CreateUpdateWifiRequest
  ) {
    return ApiClient.post<
      CreateUpdateWifiRequest,
      { data: { data: any; message: any } }
    >("user/venues/update-wifi/" + venueId, request, token);
  }

  static saveSearchClick(token: string, request: SearchClickRequest) {
    return ApiClient.post<SearchClickRequest, {}>(
      "user/search/save",
      request,
      token
    );
  }
}

export class VenueBasicSearchRequest {
  constructor(
    suburb: string,
    postcode: string,
    lat: any,
    lng: any,
    keyword: string,
    capacity: number,
    types: string[],
    only_ohs: string,
    date?: string,
    start_time?: string,
    end_time?: string,
    credits?: string
  ) {
    this.suburb = suburb;
    this.lat = lat;
    this.lng = lng;
    this.postcode = postcode;
    this.keyword = keyword;
    this.no_of_people = capacity;
    this.types = types ? types.join(",") : "";
    this.only_ohs = only_ohs;
    this.date = date;
    this.start_time = start_time;
    this.end_time = end_time;
    this.credits = credits;
  }
  suburb: string;
  lat: any;
  lng: any;
  postcode: string;
  keyword: string;
  no_of_people: number;
  types: string;
  only_ohs: string;
  date?: string;
  start_time?: string;
  end_time?: string;
  credits?: string;
}

export class CreateSaveSearchClickRequest {
  constructor(field: any) {
    this.venue_id = field.venue_id;
    this.save_search_id = field.save_search_id;
  }
  venue_id?: any;
  save_search_id: number;
}
export class CreateUpdateWifiRequest {
  constructor(field: any) {
    this._method = "PUT";
    this.wifi_name = field.wifi_name;
    this.wifi_password = field.wifi_password;
  }
  wifi_name: string;
  _method: string;
  wifi_password: string;
}
export class CreateSpaceBlockingRequest {
  constructor(fields: any) {
    this.date = fields.date;
    this.opening_time = fields.opening_time;
    this.spaces = fields.spaces;
    this.closing_time = fields.closing_time;
    this.repeat = fields.repeat;
    this.repeat_type = fields.repeat_type;
    this.timeframe = fields.timeframe;
    this.quantity = fields.quantity;
    this.cancel_booking = fields.cancel_booking;
  }
  date: string;
  opening_time: string;
  spaces: string[];
  closing_time: string;
  repeat?: string;
  repeat_type?: string;
  timeframe?: number;
  quantity?: number;
  cancel_booking?: string;
}

export class VenueAccountDetailsRequest {
  constructor(fields: any) {
    this.venue_id = fields.venue_id;
    this.same_as = fields.sameAsCompany;
    this.global_payment = fields.global_payment;
    this.company_name = fields.company_legal_name;
    this.abn = fields.abn;
    this.account_name = fields.accountName;
    this.bsb = fields.bsb;
    this.account_number = fields.accountNumber;
  }
  venue_id: any;
  same_as: boolean;
  global_payment: boolean;
  company_name: string;
  abn?: string;
  account_name?: string;
  bsb?: string;
  account_number?: string;
}
export class CreateSpaceUnBlockingRequest {
  constructor(fields: any) {
    this.date = fields.date;
    this.opening_time = fields.opening_time;
    this.space_id = fields.space_id;
  }
  date: string;
  opening_time: string;
  space_id: number;
}

export class CreateVenueCloseStatusRequest {
  constructor(fields: VenueStatus) {
    this.status = fields.status;
    this._method = fields._method;
  }
  status: string;
  _method: string;
}

export class VenueAdvSearchRequest {
  constructor(
    suburb: string,
    types: string[],
    no_of_people: number,
    environments: string[],
    services: string[],
    interests: string[],
    date: string,
    start_time: string,
    end_time: string,
    credits: number,
    save_search_preferences: number,
    only_ohs: string,
    assets: string[],
    lat?: any,
    lng?: any,
    venue_types?: any,
    nearby_radius?: any
  ) {
    this.suburb = suburb;
    this.lat = lat;
    this.lng = lng;
    this.no_of_people = no_of_people;
    this.environments = environments ? environments.join(",") : "";
    this.services = services ? services.join(",") : "";
    this.interests = interests ? interests.join(",") : "";
    this.types = types ? types.join(",") : "";
    this.date = date;
    this.start_time = start_time;
    this.end_time = end_time;
    this.credits = credits;
    this.save_search_preferences = save_search_preferences;
    this.only_ohs = only_ohs;
    this.assets = assets
      ? typeof assets === "string"
        ? assets
        : assets.join(",")
      : "";
    this.venue_types = venue_types ? venue_types.join(",") : "";
    this.nearby_radius = nearby_radius ? nearby_radius : "";
  }
  suburb: string;
  lat: any;
  lng: any;
  no_of_people: number;
  environments: string;
  services: string;
  interests: string;
  types: string;
  date: string;
  start_time: string;
  end_time: string;
  credits: number;
  save_search_preferences: number;
  only_ohs: string;
  assets: any;
  venue_types: string;
  nearby_radius: any;
}

export class EditPreferenceRequest {
  constructor(
    types: string[],
    environments: string[],
    services: string[],
    interests: string[],
    assets: string[],
    venue_types: any
  ) {
    this.environments = environments ? environments.join(",") : "";
    this.services = services ? services.join(",") : "";
    this.interests = interests ? interests.join(",") : "";
    this.types = types ? types.join(",") : "";

    this.assets = assets
      ? typeof assets === "string"
        ? assets
        : assets.join(",")
      : "";
    this.venue_types = venue_types ? venue_types.join(",") : "";
  }

  environments: string;
  services: string;
  interests: string;
  types: string;
  assets: any;
  venue_types: string;
}
export class VenueStatsRequest {
  constructor(venue_id: number, filter: string) {
    this.venue_id = venue_id;
    this.filter = filter;
  }
  venue_id?: number;
  filter?: string;
}

export class CreateVenueRequest {
  constructor(detailsFields: any, imagesFields: any) {
    this.name = detailsFields.name;
    this.address = detailsFields.location.address;
    this.suburb = detailsFields.location.suburb;
    this.state = detailsFields.location.state;
    this.country = detailsFields.location.country;
    this.postcode = detailsFields.location.postalCode;
    this.description = detailsFields.description;
    this.services = detailsFields.services
      ? detailsFields.services.join(",")
      : "";
    this.images = [
      imagesFields.image1,
      imagesFields.image2,
      imagesFields.image3,
      imagesFields.image4
    ].filter(i => !!i) as File[];
    this.lat = detailsFields.location.lat;
    this.lng = detailsFields.location.lng;
    this.bonus_offer = detailsFields.bonus_offer;
    this.venue_type_id = detailsFields.venue_type_id;
    this.email = detailsFields.email;
    this._method = detailsFields._method;
    this.phone_number = detailsFields.phone_number;
    this.lead_time = detailsFields.lead_time;
    this.vaccination_status = detailsFields.vaccination_status;
    this.status = detailsFields.status;
  }
  name: string;
  email?: string;
  address?: string;
  suburb?: string;
  postcode?: string;
  state?: string;
  country?: string;
  services?: string;
  description: string;
  images: File[];
  lat?: number;
  lng?: number;
  bonus_offer?: string;
  venue_type_id?: number;
  _method?: string;
  phone_number: string;
  lead_time?: number;
  vaccination_status?: string;
  status?: string;
}

export class UpdateVenueVaccinationRequest {
  constructor(vaccination_status: any) {
    this.vaccination_status = vaccination_status;
  }
  vaccination_status?: string;
}

export class CreateVenueAssesRequest {
  constructor(detailsFields: any) {
    this.assessments = detailsFields.assessments;
    this.venue_id = detailsFields.venue_id;
  }
  assessments: Assesment[];
  venue_id: any;
}

export class CreateVenueVaccinationRequest {
  constructor(detailsFields: any) {
    this.vaccination_status = detailsFields.vaccination_status;
    this.venue_id = detailsFields.venue_id;
  }
  vaccination_status?: string;
  venue_id: any;
}
export class SearchClickRequest {
  constructor(detailsFields: any) {
    this.save_search_id = detailsFields.save_search_id;
    this.venue_id = detailsFields.venue_id;
  }
  save_search_id: number;
  venue_id: number;
}
export type Assesment = {
  question_id: number;
  answer: string;
};
export type Availability = {
  day: string;
  openingTime: string;
  closingTime: string;
};
export type VenueDeleteResponse = {
  data: any;
};

export type SpaceBlockdaysResponse = {
  data: any;
};
export class CreateVenueRatingRequest {
  constructor(detailsFields: any) {
    this.rating = detailsFields.rating;
    this.review = detailsFields.review;
    this.booking_id = detailsFields.booking_id;
  }
  rating: number;
  review: string;
  booking_id: number;
}
export class CreateVenueOpenHoursRequest {
  constructor(detailsFields: any) {
    this.availability = detailsFields.availability;
    this.venue_id = detailsFields.venue_id;
  }
  availability: Availability[];
  venue_id: number;
}

export type getVenueOpenHoursResponse = {
  data: OpenHours[];
};
export type OpenHours = {
  availability: Availability[];
  venue_id: number;
};
export type VenueStatus = {
  status: string;
  _method: string;
};

export type Stats = {
  venues: {
    total_bookings_hours: number;
    total_bookings_revenue: number;
  };
};

export type Questions = {
  id: number;
  question: string;
  options: [];
  answer: string;
};
export type UserAssessment = {
  id: number;
  user_id: number;
  answers: [];
  result: string;
  completed_date?: string;
};
export type Venue = {
  id: number;
  name: string;
  address: string;
  suburb: string;
  postcode: string;
  state: string;
  country: string;
  price: string;
  lowest_price: string;
  capacity: number;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  user_id: number;
  featured?: any;
  phone_number?: any;
  email?: any;
  images: string[];
  images_link?: any;
  uploaded_images: {
    file_name: string;
    mime_type: string;
  }[];
  media: any[];
  user: User;
  environments: Environment[];
  services: Service[];
  types: VenueType[];
  lat: string;
  lng: string;
  spaces: Workspace[];
  assessment: any;
  distance_from_city?: string;
  bonus_offer?: string;
  average_rating?: number;
  ratings?: any[];
  status?: string;
  // assets: Asset[]
  distance_from_searched_suburb?: string;
  availability?: any;
  venue_type_id: number;
  venue_type: VenueType;
  save_search_id?: number;
  pdf_link: string;
  lead_time?: number;
  wifi_name?: string;
  wifi_password?: string;
  vaccination_status?: string;
  ohs_only?: string;
  slug?: any;
  search_by_id?: boolean;
};

export type VenueResponse = {
  data: Venue;
};

export type VenuesResponse = {
  data: Venue[];
  save_search_id?: number;
};
export type VenuesNearmeResponse = {
  data: VenueData;
};
export type VenueData = {
  nearBy: Venue[];
  featured: Venue[];
};
export type VenuesAssessment = {
  data: UserAssessment;
};

export type VenueStatsResponse = {
  data: Stats;
};

export interface UploadedImage {
  id: number;
  model_type: string;
  model_id: number;
  collection_name: string;
  name: string;
  file_name: string;
  mime_type: string;
  disk: string;
  size: number;
  manipulations: any[];
  custom_properties: any[];
  responsive_images: any[];
  order_column: number;
  created_at: string;
  updated_at: string;
}

export interface Media {
  id: number;
  model_type: string;
  model_id: number;
  collection_name: string;
  name: string;
  file_name: string;
  mime_type: string;
  disk: string;
  size: number;
  manipulations: any[];
  custom_properties: any[];
  responsive_images: any[];
  order_column: number;
  created_at: string;
  updated_at: string;
}

export interface Workspace {
  id: number;
  venue_id: number;
  name: string;
  price: string;
  capacity: number;
  quantity: number;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  images: string[];
  images_link: string;
  uploaded_images: UploadedImage[];
  media: Media[];
  environments: Environment[];
  types: VenueType[];
  venue?: any;
  assets: Asset[];
  start_time?: any;
  end_time?: any;
  free_space?: any;
  status?: string;
  min_booking_hours?: any;
  max_booking_hours?: Number;
  cancellation_period?: any;
  slug?: any;
}
