import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { inviteFriendAction } from "../Auth/Actions";
import useForm from "../../hooks/useForm";

const InviteFriend = ({ getInvitedList }) => {
  let data = {
    name: "",
    email: ""
  };
  const dispatch = useDispatch();
  const { form, setForm, handleChange } = useForm(data);

  const [errors, setErrors] = useState({
    nameError: "",
    emailError: ""
  });

  const hasFormError = () => {
    let error = false;
    let errorsSet = {};
    if (!form.name || form.name == "") {
      errorsSet["nameError"] = "Required";

      error = true;
    }
    if (!form.email || form.email == "") {
      errorsSet["emailError"] = "Required";
      error = true;
    } else if (
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
        form.email
      )
    ) {
      errorsSet["emailError"] = "Please enter a valid Email Address";
      error = true;
    }
    setErrors(errorsSet);
    return error;
  };
  const handleTitleChange = event => {
    let error = { ...errors };
    error[event.target.name + "Error"] = "";
    setErrors(error);
    handleChange(event);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!hasFormError()) {
      const req = { email: form.email, name: form.name };
      dispatch(inviteFriendAction(req, setForm));
    }
  };
  return (
    <div>
      <form>
        <div
          className="utilization-chart__wrapper container "
          style={{ minHeight: "1rem" }}
        >
          <div className="details-wrapper">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div className="col-md-5 col-lg-5">
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <label style={{ display: "flex", fontWeight: 500 }}>
                    {" "}
                    Name:{" "}
                  </label>

                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      marginLeft: 1,
                      width: "100%"
                    }}
                  >
                    <input
                      style={{ padding: 10 }}
                      name="name"
                      placeholder="Name"
                      value={form.name}
                      type="text"
                      onChange={handleTitleChange}
                    />
                  </div>
                  {errors.nameError && errors.nameError != "" && (
                    <div
                      className="validation-error"
                      style={{ textAlign: "start" }}
                    >
                      {errors.nameError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div
                  className="stats-chart__item"
                  style={{ marginBottom: "14px" }}
                >
                  <label
                    style={{
                      display: "flex",
                      fontWeight: 500,
                      marginRight: 10
                    }}
                  >
                    {" "}
                    Email:{" "}
                  </label>
                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      marginLeft: 1,
                      width: "100%"
                    }}
                  >
                    <input
                      name="email"
                      placeholder="Email"
                      type="text"
                      value={form.email}
                      onChange={handleTitleChange}
                      style={{ padding: 10 }}
                    />
                  </div>
                  {errors.emailError && errors.emailError != "" && (
                    <div
                      className="validation-error"
                      style={{ textAlign: "start" }}
                    >
                      {errors.emailError}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-2">
                <div
                  className="stats-chart__item"
                  style={{
                    marginBottom: "14px",
                    top: "1.4rem",
                    position: "relative"
                  }}
                >
                  <button
                    type="submit"
                    className={`btn2 btn-blue`}
                    onClick={handleSubmit}
                    style={{ width: "100%" }}
                  >
                    Invite
                  </button>
                </div>
              </div>
              <span
                style={{
                  marginTop: 0,
                  marginLeft: "20px",
                  color: "red"
                }}
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteFriend;
