/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../Screens/useOnClickOutside";
const MapMarkerComponent = marker => {
  let filterRef = useRef(null);
  const openModal2 = e => {
    setisOpen(!isOpen);
  };
  const [isOpen, setisOpen] = useState(false);
  useOnClickOutside(filterRef, () => {
    setisOpen(false);
  });
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const roleUser = useSelector(state => state.auth.userRoles);
  const isVenueFree = marker.marker.spaces.some(
    s => s.free_space === "No"
  );

  const goWorkspace = (venueId, slug) => {
    if (roleUser !== "Venue") {
      if (!isVenueFree) {
        if (!user) {
          history.push("/pricing");
        } else {
          if (user.plan == null) {
            history.push("/select-plan");
          } else if (user.plan.free_space === "No") {
            history.push("/select-plan");
          } else {
            history.push({
              pathname: `/workspace/${slug}`,
              state: venueId
            });
          }
        }
      } else {
        history.push({
          pathname: `/workspace/${slug}`,
          state: venueId
        });
      }
    } else {
      history.push({
        pathname: `/workspace/${slug}`,
        state: venueId
      });
    }
  };
  useEffect(() => {}, [marker]);
  if (marker.marker.spaces.length > 0) {
    if (!marker.cord) {
      return (
        <>
          <div
            className="employer__right"
            onClick={e => openModal2(e)}
          >
            <div className="employer__stats2">
              <div
                className="stats__item"
                style={{
                  borderRadius: 26,
                  boxShadow: isOpen ? "0px 0px " : "1px 1px",
                  background:
                    marker.marker.id == marker.hover
                      ? "#000"
                      : !isOpen
                      ? "#fff"
                      : "#000",
                  color:
                    marker.marker.id == marker.hover
                      ? "#fff"
                      : !isOpen
                      ? "#000"
                      : "#fff",
                  zIndex: marker.marker.id == marker.hover ? 1 : ""
                }}
              >
                {/* {!marker.isPrice ? ( */}
                <div className="stats__item-subtitle">
                  {marker.marker &&
                  !isNaN(
                    Math.ceil(
                      parseInt(marker.marker.lowest_price) / 0.3
                    )
                  )
                    ? Math.ceil(
                        parseInt(marker.marker.lowest_price) / 0.3
                      )
                    : "0"}{" "}
                  {Math.ceil(
                    parseInt(marker.marker.lowest_price) / 0.3
                  ) > 1
                    ? "credits"
                    : "credit"}
                  /hour
                </div>
                {/* ) : (
                  <div className="stats__item-subtitle">
                    $ {marker.marker && Math.ceil(marker.marker.lowest_price)}
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {isOpen ? (
            <a
              onClick={() => {
                if (
                  marker.marker.status != "Unpublished" ||
                  marker.marker.status != "Closed" ||
                  marker.marker.status != "Draft"
                ) {
                  goWorkspace(marker.marker.id, marker.marker.slug);
                }
              }}
            >
              <div ref={filterRef} className="markerDiv">
                <img
                  className="markerImg"
                  src={
                    marker.marker.images && marker.marker.images[0]
                  }
                  alt=""
                />
                <div
                  style={{
                    fontSize: "14px"
                  }}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      margin: 5
                    }}
                  >
                    <div className="marker-location">
                      {marker.marker.suburb.toUpperCase()}
                    </div>
                    <div className="marker-subtitle">
                      {marker.marker.name.toUpperCase()}{" "}
                    </div>
                    <span style={{ paddingLeft: 5 }}>
                      <div className="venue-rating__left">
                        <span className={"from"}>From</span>{" "}
                        <span className={"creditCount"}>
                          {marker.marker &&
                          !isNaN(
                            Math.ceil(
                              parseInt(marker.marker.lowest_price) /
                                0.3
                            )
                          )
                            ? Math.ceil(
                                parseInt(marker.marker.lowest_price) /
                                  0.3
                              )
                            : "0"}
                        </span>
                        / <span className={"hour"}>hr</span>
                      </div>
                      {marker.marker.status == "Unpublished" ||
                      marker.marker.status == "Closed" ||
                      marker.marker.status == "Draft" ? (
                        <div
                          style={{
                            marginTop: "2rem",
                            width: "100%",
                            textAlign: "Center",
                            backgroundColor: "#f44e74",
                            color: "#fff",
                            padding: "1rem"
                          }}
                        >
                          <span>Temporarily Unavailable</span>
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            goWorkspace(
                              marker.marker.id,
                              marker.marker.slug
                            );
                          }}
                          className="btn btn-blue"
                          style={{ marginTop: "1rem" }}
                        >
                          BOOK NOW
                        </button>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </a>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <div style={{ fontWeight: "bold", color: "red" }}>
            {marker.key}
            <img alt="" src="/img/marker.png"></img>
          </div>
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default MapMarkerComponent;
