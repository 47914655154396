/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  reduxForm,
  Field,
  FormErrors,
  WrappedFieldProps
} from "redux-form";
import { toast } from "react-toastify";
import { Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import UploadField from "../../UI/UploadField/UploadField";
import BasicField from "../../UI/BasicField/BasicField";
import { AuthApi } from "../../API/AuthApi";
import { UserType } from "../../store";

import {
  submitUserForm,
  getExistingEmailStatus,
  completeSignUpEffect,
  toggleSMSModal,
  profileImagesDelete
} from "./Actions";
import { AppState } from "../../store";
import Footer from "../../footer";
import SMSModal from "../Dashboard/modals/SMSVerficationModal";
import RegionField from "../../UI/RegionField/RegionField";
import ResetPasswordNew from "../ResetPasswordNew";

export type UserInfoFields = {
  firstName: string;
  lastName: string;
  company_name?: string;
  email: string;
  phoneNumber: string;
  password?: string;
  repeatPassword?: string;
  repeatnewPassword?: string;
  file?: File;
  invite_number?: string;
  ohs_only?: any;
  interests?: string[];
  userType?: UserType;
  terms?: any;
  company_logo?: any;
  logo_deleted?: number;
  invitedCode?: string;
  friends_invite_code?: string;
  // vaccination_status?: string;
  vaccination_certificate_deleted?: number;
  vaccination_certificate?: File;
  logo?: File;
  promoCode?: string;
  region?: string;
  state?: string;
  prevLocationState?: any;

  // work_choice?: string;
};
const normalizePhone = (value: any, previousValue: any) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums;
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + onlyNums.slice(3);
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    onlyNums.slice(3, 6) +
    onlyNums.slice(6, 10)
  );
};

export const UserInfoForm = reduxForm<
  UserInfoFields,
  {
    personalSettingsField: any;
    user: boolean;
    history: any;
    emailExistMessage?: any;
    promoMessage?: any;
  }
>({
  form: "userInfo",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (formFields, dispatch, props) => {
    if (window.location.href.indexOf("employer/invite") !== -1) {
      const parts = window.location.href.split("employer/invite/");
      formFields.invite_number = parts[1];
    }

    if (!formFields.userType) {
      if (window.location.href.includes("register/employer")) {
        formFields["userType"] = UserType.Employer;
      }
      if (window.location.href.includes("register/customer")) {
        formFields["userType"] = UserType.Customer;
        var code;
        if (
          window.location.href.includes(
            "register/customer?invitedCode="
          )
        ) {
          code = window.location.href.split(
            "/register/customer?invitedCode="
          )[1];
          formFields["friends_invite_code"] = code;
        }
      }
    }

    delete formFields["terms"];

    dispatch(submitUserForm(formFields));
    if (!props.user) {
      await dispatch(
        completeSignUpEffect(
          props.history,
          props.values!.prevLocationState!
        )
      );
    } else {
      dispatch(toggleSMSModal(true));
    }
  },

  validate: (values, props) => {
    const errors: FormErrors<UserInfoFields> = {};
    // Company
    if (props.user) {
      if (values.userType !== UserType.Customer) {
        if (!values.company_name) {
          errors.company_name = "Required";
        }
      }
    }

    if (props.user) {
      if (!values.userType) {
        errors.userType = "Required";
      }
    }

    // First Name
    if (!values.firstName) {
      errors.firstName = "Required";
    } else {
      if (/^\s+$/.test(values.firstName)) {
        errors.firstName = "Required";
      }
    }

    // Last Name
    if (!values.lastName) {
      errors.lastName = "Required";
    } else {
      if (/^\s+$/.test(values.lastName)) {
        errors.lastName = "Required";
      }
    }

    // Phone number
    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }
    //Password
    if (props.user) {
      if (!values.password) {
        errors.password = "Required";
      }
    }
    // Email
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
        values.email
      )
    ) {
      errors.email = "Invalid email address";
    }

    if (values.phoneNumber && !/^\d{10}$/g.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number";
    }

    if (!values.userType) {
      errors.userType = "Select User Type";
    }

    // if (!values.vaccination_status) {
    //   errors.vaccination_status = "Please select vaccination status";
    // }
    if (props.user) {
      if (Object.keys(values).length < 1) {
        if (!values.password || !values.repeatPassword) {
          errors.password = "Password Required";
          errors.repeatPassword = "Password Required";
        }
      } else if (values.password!?.length < 8) {
        errors.password =
          "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
      } else {
        let hasUpperCase: any = /[A-Z]/.test(values.password!);
        let hasLowerCase: any = /[a-z]/.test(values.password!);
        let hasNumbers: any = /\d/.test(values.password!);
        let hasNonalphas: any = /\W/.test(values.password!);

        if (
          !hasUpperCase ||
          !hasLowerCase ||
          !hasNumbers ||
          !hasNonalphas
        ) {
          errors.password =
            "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
        }
      }

      if (values.repeatPassword !== values.password) {
        errors.repeatPassword = "Passwords do not match";
      }
    }

    if (props.user) {
      if (!values.terms) {
        errors.terms = "Please agree to terms and conditions";
      }
    }
    if (!values.region) {
      errors.region = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }
    if (values.password && values.repeatnewPassword) {
      if (!/^(?=).{8,}$/i.test(values.password)) {
        errors.password =
          "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
      } else {
        let hasUpperCase: any = /[A-Z]/.test(values.password);
        let hasLowerCase: any = /[a-z]/.test(values.password);
        let hasNumbers: any = /\d/.test(values.password);
        let hasNonalphas: any = /\W/.test(values.password);

        if (
          !hasUpperCase ||
          !hasLowerCase ||
          !hasNumbers ||
          !hasNonalphas
        ) {
          errors.password =
            "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
        }
      }
      if (values.password !== values.repeatnewPassword) {
        errors.repeatnewPassword = "Passwords do not match";
      }
    }
    return errors;
  }
})(props => {
  const dispatch = useDispatch();

  const checkEmailExistStatus = async (e: any) => {
    if (
      e.target.value != userDetails?.email &&
      userDetails?.id == null
    ) {
      await dispatch(getExistingEmailStatus(e.target.value));
    }
  };
  const { state: prevLocationState } = useLocation();

  const userDetails = useSelector(
    (state: AppState) => state.auth.user
  );
  const selectedType = useSelector(
    (state: AppState) => state.auth.selectedType
  );
  const wizardInfo = useSelector(
    (state: AppState) => state.wizard.userInfoFields
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );

  const userType = useSelector(
    (state: AppState) => state.wizard.userType
  );
  const [isEmp, setisEmp] = useState(false);
  const [checkboxValue, setCheckboxValue]: any = useState(false);
  const [termsError, setError] = useState("");
  const [logo_name, setLogo_name] = useState("");
  const [vaccination_certificate, setvaccination_certificate] =
    useState("");
  const [invitedUser, setInvitedUser] = useState<any>({});
  const isSMSModalOpen = useSelector(
    (state: AppState) => state.wizard.isOpen
  );
  const [isInviteValid, setInviteVal] = useState(false);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] =
    useState<Boolean>(false);

  useEffect(() => {
    var code;
    if (
      window.location.href.includes("register/customer?invitedCode=")
    ) {
      code = window.location.href.split(
        "/register/customer?invitedCode="
      )[1];
      var data = getFriendInfo(code);
      data.then((item: any) => {
        props.initialize({
          firstName: item.first_name,
          lastName: item.last_name,
          email: item.email,
          userType: UserType.Customer,
          friends_invite_code: item.code,
          prevLocationState
        });
      });
    }
    var path = window.location.pathname.split("/employer/invite/");
    var data = getInfo(path[1]);
    if (selectedType && selectedType != null) {
      props.initialize({
        userType: selectedType,
        prevLocationState
      });
    }

    if (window.location.pathname.includes("employer/invite")) {
      data.then((item: any) => {
        setInvitedUser(item);
        props.initialize({
          firstName: item.first_name,
          lastName: item.last_name,
          company_name: item.company_name,
          email: item.email,
          userType: UserType.Customer,
          prevLocationState
        });
      });
    } else {
      userDetails &&
        props.initialize({
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
          company_name: userDetails.company_name,
          email: userDetails.email,
          phoneNumber: userDetails.phone_number,
          userType: userType,
          file: wizardInfo && wizardInfo.file,
          // vaccination_status: userDetails.vaccination_status,
          company_logo: userDetails.company_logo,
          region: userDetails.region,
          state: userDetails.state,
          prevLocationState
        });
      if (userDetails && userDetails.vaccination_certificate) {
        setvaccination_certificate(
          userDetails.vaccination_certificate.file_name
        );
      }
    }
    if (userDetails && userDetails.company_logo) {
      setLogo_name(userDetails.company_logo.file_name);
    }
  }, [props.personalSettingsField]);
  useEffect(() => {
    setisEmp(
      window.location.href.includes("register/employer")
        ? true
        : false
    );
  }, []);
  async function getInfo(number: string) {
    var data = {};
    const response = await AuthApi.GetInviteeInfo(number);
    if (Object.keys(response.data).length > 0) {
      const last_name = response.data.full_name.split(" ");
      data = {
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        email: response.data.email,
        company_name: response.data.employer.company_name
      };
      return data;
    }
    return data;
  }
  async function getFriendInfo(number: string) {
    var data = {};

    const response = await AuthApi.GetInviteeFriendInfo(number);
    if (Object.keys(response.data).length > 0) {
      if (response.data.status == "Invited") {
        const fullName = response.data.name.split(" ");
        data = {
          first_name: fullName[0],
          last_name: fullName[1],
          email: response.data.email,
          code: response.data.code
        };
        return data;
      } else {
        setInviteVal(true);
        toast.error(
          "The invitation is invalid or already registered"
        );
      }
    }

    return data;
  }
  const handleSubmit = (values: any) => {
    if (!props.emailExistMessage) {
      props.handleSubmit(values);
    }
  };

  const handleCheckBox = (e: any) => {
    setCheckboxValue(!checkboxValue);
    setError(!checkboxValue ? "" : "Please Agree Terms & Conditions");
  };
  const handleToggleModal = () => {
    dispatch(toggleSMSModal(!isSMSModalOpen));
  };
  const handleSignUpForm = async () => {
    await dispatch(completeSignUpEffect(props.history));
  };
  return (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <div className="body create">
        <div className="container containerAddSpace">
          <div className="page-wrapper" style={{ marginTop: 25 }}>
            {Object.keys(invitedUser).length > 0 && (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 18,
                  fontWeight: 400
                }}
              >
                You have been invited to join Dorpee as an{" "}
                <span
                  style={{ margin: "0 5px 0 5px", fontWeight: 600 }}
                >
                  {" "}
                  {invitedUser.company_name.toUpperCase()}{" "}
                </span>{" "}
                user
              </p>
            )}

            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {!isEmp && userType == "employer" ? (
                <div className={`col-md-6`}>
                  <div
                    className="registerTitle"
                    style={{ marginBottom: 20 }}
                  >
                    <h1
                      className="register-title"
                      style={{ textAlign: "start", left: "0" }}
                    >
                      {props.user
                        ? "Create an account"
                        : "Update account"}
                    </h1>
                  </div>

                  <div className="register-left">
                    <p>
                      <b>
                        Unlimited flexible workspace possibilities
                        with a simple monthly membership.
                      </b>
                    </p>
                    <p>
                      Recreating the old world of the village, we want
                      to base ourselves around community, embrace
                      diversity, and move and change our ideal
                      workspace to suit our needs for the day.
                    </p>
                    <p>Dorpee provides</p>
                    <ul className="register-points">
                      <li>A cost-effective way to find space</li>
                      <li>Inspiration and variety in locations</li>
                      <li>
                        Filters to search based on what you need today
                      </li>
                    </ul>
                  </div>
                  <div></div>
                </div>
              ) : (
                <>
                  {window.location.href.includes(
                    "register/customer"
                  ) ? (
                    <div className={`col-md-6`}>
                      <div
                        className="registerTitle"
                        style={{ marginBottom: 20 }}
                      >
                        <h1
                          className="register-title"
                          style={{ textAlign: "start", left: "0" }}
                        >
                          {props.user
                            ? "Create an account"
                            : "Update account"}
                        </h1>
                      </div>

                      <div className="register-left">
                        <p>
                          <b>
                            Unlimited flexible workspace possibilities
                            with a simple monthly membership.
                          </b>
                        </p>
                        <p>
                          Recreating the old world of the village, we
                          want to base ourselves around community,
                          embrace diversity, and move and change our
                          ideal workspace to suit our needs for the
                          day.
                        </p>
                        <p>Dorpee provides</p>
                        <ul className="register-points">
                          <li>A cost-effective way to find space</li>
                          <li>
                            Inspiration and variety in locations
                          </li>
                          <li>
                            Filters to search based on what you need
                            today
                          </li>
                        </ul>
                      </div>
                      <div></div>
                    </div>
                  ) : null}
                </>
              )}
              <div className={`col-md-${!isEmp ? "6" : "12"} `}>
                <div className="wizard__user-icon">
                  <Field
                    name="file"
                    component={UploadField}
                    type="file"
                  />
                </div>
                <p
                  style={{
                    marginTop: "-10px",
                    fontSize: "12px",
                    marginBottom: "20px",
                    textAlign: "center"
                  }}
                >
                  Click to upload a profile picture for your account
                </p>
                <div className="bl-time">
                  {isEmp ||
                  (userType !== "Customer" && !props.user) ? (
                    //
                    <div className="row registerRow">
                      <div className="col-md-12 registerRowMbl">
                        <Field
                          name="company_name"
                          component={BasicField}
                          isRegister={isEmp}
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                  ) : null}
                  {isEmp ? (
                    // ||
                    // selectedType == "employer" ||
                    // userType === "employer"
                    <div className="row registerRow">
                      <div className="col-md-12 registerRowMbl ">
                        <Field
                          name="company_logo"
                          placeholder="Choose File"
                          component={(props: WrappedFieldProps) => {
                            const displayError =
                              props.meta.touched && props.meta.error;
                            return (
                              <>
                                {/*<label>Upload Company logo</label>*/}
                                <div
                                  className="form-control"
                                  style={{
                                    marginTop: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    height: "45px",
                                    cursor: "pointer",
                                    border: "1px solid #a8c8d4"
                                  }}
                                >
                                  {logo_name === "" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        maxHeight: "max-content",
                                        position: "absolute",
                                        alignItems: "center"
                                      }}
                                    >
                                      <p
                                        style={{
                                          border: "0.5px solid black",
                                          backgroundColor: "#efefef",
                                          borderRadius: "0.2rem",
                                          whiteSpace: "nowrap",
                                          position: "relative",
                                          padding: "2px 5px",
                                          margin: 0
                                        }}
                                      >
                                        Choose File
                                      </p>
                                      <p
                                        style={{
                                          margin: 0,
                                          color: "#888888",
                                          fontSize: "16px",
                                          marginLeft: "2px"
                                        }}
                                      >
                                        Upload Company logo
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <input
                                    id="files"
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      zIndex: 999,
                                      opacity: 0,
                                      display:
                                        props.input.value.name ||
                                        logo_name != ""
                                          ? "none"
                                          : "block"
                                    }}
                                    onChange={e => {
                                      setLogo_name("");
                                      e.preventDefault();
                                      if (e.target.files) {
                                        props.input.onChange(
                                          e.target.files[0]
                                        );
                                        setLogo_name(
                                          e.target.files[0].name
                                        );
                                      }
                                    }}
                                  />
                                  {logo_name != "" ? (
                                    <label
                                      htmlFor="files"
                                      className="com-logo"
                                    >
                                      {logo_name}
                                      <span
                                        onClick={e => {
                                          setLogo_name("");
                                          e.preventDefault();
                                          props.input.onChange(null);

                                          dispatch(
                                            profileImagesDelete({
                                              logo_deleted: 1,
                                              photo_deleted: 0,
                                              vaccination_certificate_deleted: 0
                                            })
                                          );
                                        }}
                                      >
                                        X
                                      </span>
                                    </label>
                                  ) : null}
                                  {displayError && (
                                    <div className="validation-error">
                                      {props.meta.error}
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="row registerRow">
                    <div className="col-md-6 registerRowMbl">
                      <Field
                        name="firstName"
                        component={BasicField}
                        isRegister={!isEmp}
                        type="text"
                        placeholder="First Name *"
                      />
                    </div>
                    <div className="col-md-6 registerRowMbl">
                      <Field
                        name="lastName"
                        isRegister={!isEmp}
                        component={BasicField}
                        type="text"
                        placeholder="Last Name *"
                      />
                    </div>
                  </div>

                  <div className="row registerRow">
                    <div className="col-md-6 registerRowMbl">
                      <Field
                        name="email"
                        isRegister={!isEmp}
                        component={BasicField}
                        type="email"
                        placeholder="Email *"
                        onBlur={(e: any) => {
                          checkEmailExistStatus(e);
                        }}
                        emailError={props.emailExistMessage}
                      />{" "}
                      {props.emailExistMessage && (
                        <span style={{ color: "red" }}>
                          {props.emailExistMessage}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 registerRowMbl">
                      <Field
                        isRegister={!isEmp}
                        name="phoneNumber"
                        component={BasicField}
                        type="text"
                        placeholder="Phone Number *"
                        normalize={normalizePhone}
                      />
                    </div>
                  </div>

                  {props.user ? (
                    <>
                      <div className="row registerRow">
                        <div className="col-md-12 registerRowMbl">
                          <Field
                            isRegister={!isEmp}
                            name="password"
                            component={BasicField}
                            type={showPassword ? "text" : "password"}
                            placeholder="Password *"
                            autoComplete="new-password"
                          />
                          <div
                            className="visibility_icon"
                            onClick={() =>
                              setShowPassword(!showPassword)
                            }
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row registerRow">
                        <div className="col-md-12 registerRowMbl">
                          <Field
                            isRegister={!isEmp}
                            name="repeatPassword"
                            component={BasicField}
                            type={
                              showRepeatPassword ? "text" : "password"
                            }
                            placeholder="Repeat Password *"
                          />
                          <div
                            className="visibility_icon"
                            onClick={() =>
                              setShowRepeatPassword(
                                !showRepeatPassword
                              )
                            }
                          >
                            {showRepeatPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {userType !== "venue" ? (
                    <>
                      <div className="row registerRow">
                        <div className="col-md-6 registerRowMbl">
                          <Field
                            name="region"
                            isRegister={!isEmp}
                            component={BasicField}
                            type="text"
                            placeholder="City *"
                          />
                        </div>
                        <div className="col-md-6 registerRowMbl">
                          <Field
                            isRegister={!isEmp}
                            name="state"
                            component={RegionField}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="row registerRow">
                    {props.user ? (
                      <div className="col-md-12 registerRowMbl">
                        <Field
                          name="terms"
                          component={(props: WrappedFieldProps) => {
                            const displayError =
                              props.meta.touched && props.meta.error;
                            return (
                              <>
                                <input
                                  id="chk1"
                                  type="checkbox"
                                  defaultChecked={props.input.value}
                                  onChange={value => {
                                    props.input.onChange(value);
                                  }}
                                  style={{ marginRight: 10 }}
                                />
                                <label
                                  htmlFor="chk1"
                                  style={{
                                    color: "grey",
                                    fontSize: 13
                                  }}
                                >
                                  Clicking Get Started confirms
                                  acceptance of the Dorpee Terms &
                                  Conditions{" "}
                                  <a
                                    href="/term-condition"
                                    target="_blank"
                                    title="Terms & Conditions"
                                  >
                                    I accept the Dorpee Terms &
                                    Conditions
                                  </a>
                                </label>
                                {displayError && (
                                  <div
                                    className="validation-error"
                                    style={{ marginTop: 0 }}
                                  >
                                    {props.meta.error}
                                  </div>
                                )}
                              </>
                            );
                          }}
                        />
                      </div>
                    ) : null}
                  </div>

                  {!props.user &&
                  userDetails &&
                  userDetails.id != null &&
                  userDetails.id != undefined ? (
                    <>
                      <div className="row registerRow">
                        <div className="col-md-12 registerRowMbl">
                          <Field
                            id="autofill"
                            isRegister={!isEmp}
                            name="password"
                            component={BasicField}
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            autoComplete="new-password"
                          />
                          <div
                            className="visibility_icon"
                            onClick={() =>
                              setShowPassword(!showPassword)
                            }
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row registerRow">
                        <div className="col-md-12 registerRowMbl">
                          <Field
                            id="autofill1"
                            isRegister={!isEmp}
                            name="repeatnewPassword"
                            component={BasicField}
                            type={
                              showRepeatPassword ? "text" : "password"
                            }
                            placeholder="Repeat New Password"
                            autoComplete="new-password"
                          />
                          <div
                            className="visibility_icon"
                            onClick={() =>
                              setShowRepeatPassword(
                                !showRepeatPassword
                              )
                            }
                          >
                            {showRepeatPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {!isInviteValid && (
                    <div
                      className="bl-buttons"
                      style={{ marginBottom: 30 }}
                    >
                      <div className="col-12">
                        <button
                          type="submit"
                          className={`btn ${
                            isLoading
                              ? " btn--loadingNew"
                              : "btn-successBtnRegister"
                          } `}
                          style={{
                            background: isLoading
                              ? ""
                              : "no-repeat right 330px center #f5597f"
                          }}
                          disabled={isLoading}
                        >
                          {" "}
                          {!isLoading && (
                            <span style={{ padding: 2 }}>
                              {props.user
                                ? "Get Started"
                                : "Update Details"}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
        //  isSubs
        ></Footer>
        {isSMSModalOpen && (
          <SMSModal
            handleToggleModal={handleToggleModal}
            handleSignUpForm={handleSignUpForm}
          />
        )}
      </div>
    </Form>
  );
});
