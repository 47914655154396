export function setLoader(isLoading: boolean) {
  return <const>{
    type: "@@wizard/SET_LOADER",
    isLoading
  };
}
export function setBookingVenueLoader(isLoading: boolean) {
  return <const>{
    type: "@@wizard/SET_BOOKING_VENUE_LOADER",
    isLoading
  };
}

export function setLoaderCredit(isLoading: boolean) {
  return <const>{
    type: "@@wizard/SET_LOADER_CREDIT",
    isLoading
  };
}
export function setLoaderSearch(isLoading: boolean) {
  return <const>{
    type: "@@wizard/SET_LOADER_SEARCH",
    isLoading
  };
}
export function unSetLoader(isLoading: boolean) {
  return <const>{
    type: "@@wizard/UNSET_LOADER",
    isLoading
  };
}

export type LoaderAction = ReturnType<
  | typeof setLoader
  | typeof unSetLoader
  | typeof setLoaderCredit
  | typeof setLoaderSearch
  | typeof setBookingVenueLoader
>;
