import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '../../../UI/Modal';
import { toast } from "react-toastify/index";
import { setEditIdName } from "../../Contact/Actions"
class AddTeam extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teamName: '',
            error: false,
            editedTeamId: 0,
            editedTeamName: '',
            showEdit: false


        };
        this.saveTeam = this.saveTeam.bind(this);
        this.updateTeam = this.updateTeam.bind(this);
    };

    setTeamName(e) {
        this.setState({
            teamName: e.target.value,
            error: false
        });
    }

    toSimpleDate(date) {
        let tmp = date.split(' ')[0];
        let parts = tmp.split('-');
        return parts[2] + '/' + parts[1] + '/' + parts[0];
    }

    saveTeam() {
        if (this.state.teamName.length > 0 && this.state.teamName.length < 100) {
            let data = {
                name: this.state.teamName
            };

            this.props.addTeam(data);
            this.ErrorTeamName(this.props.errorInfo)
        }
        else this.setState({ error: true });
    }

    removeTeam(id) {
        this.props.removeTeam(id);
    }

    hideTeam() {
        this.props.showHideModal('addTeam', false);
    }

    changeEditedTeamId(id, name) {
        if (id !== 0)
            this.props.setEditIdName({ id: id, name: name })
        this.setState({
            editedTeamId: id,
            editedTeamName: name
        });
        this.setState({ showEdit: true })
    }
    ErrorTeamName = (err) => {
        this.setState({ errorName: err })

    }
    changeEditedTeamName(e) {
        this.setState({ editedTeamName: e.target.value });
    }

    cancelTeamEdit() {
        this.setState({ teamName: '' });
        this.changeEditedTeamId(0, '');
    }

    updateTeam() {
        let id = this.state.editedTeamId;
        let data = {
            name: this.state.editedTeamName
        };

        this.props.updateTeam(data, id);
        if (this.props.errors.status) {
            this.setState({ showEdit: true })
        }
        else {
            this.setState({ showEdit: false })
        }
        if (!this.props.errors.status)
            this.setState({
                teamName: ''
            });
    }

    render() {
        return (
            <>
                <Modal>
                    <div className="addressbook-form-box">
                        <div className="congrat-box__header"><span>Add Team</span>
                            <div onClick={this.hideTeam.bind(this)} className="close-btn"></div>
                        </div>
                        {!this.state.showEdit && !this.props.errors.status ?
                            <div className="addressbook-form">
                                <div className="input-container">
                                    <div className="input__icon">
                                        <div className="input__icon--people"></div>
                                    </div>
                                    <input autoComplete="off" className="addressbook-form-input" type="text" placeholder="Team Name" value={this.state.teamName} id="team_name" onChange={this.setTeamName.bind(this)} />
                                </div>
                                {this.state.error ? <p className="addressbook-form-error">{this.state.errorName.status ? this.state.errorName.message : "Invalid name"}</p> : null}
                                {this.props.errors.status ? <p style={{ marginTop: -2 }} className="addressbook-form-error">{this.props.errors.message}</p> : null}
                                <button className="btn addressbook-modal-btn addressbook-submit-btn" onClick={this.saveTeam.bind(this)} disabled={this.state.teamName == ''}>Add</button>
                            </div>
                            :
                            <div className="addressbook-form">
                                <div className="input-container">
                                    <div className="input__icon">
                                        <div className="input__icon--people"></div>
                                    </div>
                                    <input autoComplete="off" className="addressbook-form-input" type="text" placeholder="Team Name" id="team_name" value={this.state.editedTeamName} onChange={this.changeEditedTeamName.bind(this)} />
                                </div>
                                {this.state.error ? <p className="addressbook-form-error">Invalid name</p> : null}
                                {this.props.errors.status ? <p style={{ marginTop: -2 }} className="addressbook-form-error">{this.props.errors.message}</p> : null}
                                <button className="btn addressbook-modal-btn addressbook-submit-btn" onClick={this.updateTeam.bind(this)}>Save</button>
                                <button className="btn addressbook-modal-btn addressbook-submit-btn" onClick={this.cancelTeamEdit.bind(this)}>Cancel</button>
                            </div>
                        }
                        <div className="team-list">
                            {this.props.teamList.length > 0 ?
                                <table className="team-list-table">
                                    <thead>
                                        <tr>
                                            <th className="team-head">Name</th>
                                            <th className="team-head">Created At</th>
                                            <th className="team-head-actions"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.teamList ? this.props.teamList.map((item) =>
                                            <tr key={item.id}>
                                                <td>{item.name}
                                                </td>
                                                <td>{this.toSimpleDate(item.created_at)}
                                                </td>
                                                <td>
                                                    <img className="team-actions-icon" onClick={this.changeEditedTeamId.bind(this, item.id, item.name)} src="/img/icons/icon-edit.svg" alt="" />
                                                    <img className="team-actions-icon" onClick={this.removeTeam.bind(this, item.id)} src="/img/icons/cross-white.svg" alt="" />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                                : <p className="team-empty">You don't have teams yet</p>
                            }
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

    errors: state.Contacts.error,
    editName: state.Contacts.updatedTeam

})
const mapDispatchToProps = {
    // setTeam_ContactError,
    setEditIdName

}

export default connect(mapStateToProps, mapDispatchToProps)(AddTeam);