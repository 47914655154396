/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { VenueWizardStep, venueWizardReducer } from "./Reducers";
import { VenueImagesFields } from "./VenueImagesForm";
import { VenueDetailsFields } from "./VenueBasicInfo";
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {
  VenueApi,
  CreateVenueRequest,
  Venue,
  CreateVenueAssesRequest,
  CreateVenueRatingRequest,
  CreateVenueOpenHoursRequest,
  CreateSpaceBlockingRequest,
  CreateVenueCloseStatusRequest,
  CreateSpaceUnBlockingRequest,
  VenueAccountDetailsRequest,
  CreateUpdateWifiRequest,
  CreateVenueVaccinationRequest,
  UpdateVenueVaccinationRequest
} from "../../API/VenueApi";
import {
  EnumsApi,
  VenueType,
  Environment,
  Service,
  Interest,
  Asset
} from "../../API/EnumsApi";
import {
  postVenueOpenHoursEffect,
  publishWorkspaceEffect
} from "../WorkspaceWizard/Actions";

import { createFile } from "../../common/createFile";
import { toast } from "react-toastify";
import { History } from "history";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Address } from "../../UI/LocationPicker/LocationPicker";
import VenueAmenities from "./VenueAmenities";
import { setBookingVenueLoader, setLoader } from "../Loader/Actions";
import {
  CreateWorkspaceRequest,
  WorkspaceApi
} from "../../API/WorkspaceApi";
import { getDashboardDataEffect } from "../Dashboard/Actions";

export type VenueDetailsFieldsUpated = {
  name: string;
  location: Address;
  description: string;
  services: string[];
  bonus_offer: string;
  vaccination_status?: string;
  _method: string;
};
export function venueWizardReset() {
  return <const>{
    type: "@@VENUE/RESET"
  };
}

export function venueWizardSetStep(step: VenueWizardStep) {
  return <const>{
    type: "@@VENUE/SET_STEP",
    step
  };
}
export function venuesLoaded(venues: Venue[]) {
  return <const>{
    type: "@@VENUE/VENUES_LOADED",
    venues
  };
}

export const venueTypesLoaded = (types: VenueType[]) =>
  <const>{
    type: "@@wizard/VENUE_TYPES_LOADED",
    types
  };

export const typesLoaded = (types: VenueType[]) =>
  <const>{
    type: "@@wizard/TYPES_LOADED",
    types
  };
export const editVenueLoaded = (venue: Venue) =>
  <const>{
    type: "@@wizard/EDIT_VENUE_LOADED",
    venue
  };

export const environmentsLoaded = (environments: Environment[]) =>
  <const>{
    type: "@@wizard/ENVIRONMENTS_LOADED",
    environments
  };

export const assetsLoaded = (assets: Asset[]) =>
  <const>{
    type: "@@wizard/ASSETS_LOADED",
    assets
  };
export const servicesLoaded = (services: Service[]) =>
  <const>{
    type: "@@wizard/SERVICES_LOADED",
    services
  };
export const interestLoaded = (interests: Interest[]) =>
  <const>{
    type: "@@wizard/INTEREST_LOADED",
    interests
  };

export function venueLoaded(venue: Venue) {
  return <const>{
    type: "@@VENUE/VENUE_LOADED",
    venue
  };
}

export function publicVenuesLoaded(venues: Venue[]) {
  return <const>{
    type: "@@VENUE/PUBLIC_VENUES_LOADED",
    venues
  };
}
export function workspacesForYouLoaded(venues: Venue[]) {
  return <const>{
    type: "@@VENUE/WORKSPACES_FOR_YOU",
    venues
  };
}
export function isVenueLoaded(loading: boolean) {
  return <const>{
    type: "@@VENUE/VENUE_LOADING",
    loading
  };
}

export function venueSubmitDetailsForm(
  fields: VenueDetailsFieldsUpated
) {
  return <const>{
    type: "@@VENUE/SUBMIT_DETAILS_FORM",
    fields
  };
}

export function venueDetailsForm(fields: VenueDetailsFields) {
  return <const>{
    type: "@@VENUE/UPDATE_SUBMIT_DETAILS_FORM",
    fields
  };
}
export function venueSubmitImagesForm(fields: VenueImagesFields) {
  return <const>{
    type: "@@VENUE/SUBMIT_IMAGES_FORM",
    fields
  };
}

export function venueSubmitOpenHoursForm(fields: any) {
  return <const>{
    type: "@@VENUE/SUBMIT_OPEN_HOURS_FORM",
    fields
  };
}

export function venueNearMe(fields: any) {
  return <const>{
    type: "@@VENUE/NEAR_ME",
    fields
  };
}
export function venuesOpenHours(fields: any) {
  return <const>{
    type: "@@VENUE/OPEN_HOURS",
    fields
  };
}
export function venuesAmenitiesForm(fields: string[]) {
  return <const>{
    type: "@@VENUE/AMENITIES",
    fields
  };
}
export function venueTypeForm(fields: Number) {
  return <const>{
    type: "@@VENUE/TYPE_FORM",
    fields
  };
}
export function venueBonusForm(fields: string) {
  return <const>{
    type: "@@VENUE/BONUS_FORM",
    fields
  };
}

export function setVenueIdFields(id: number) {
  return <const>{
    type: "@@VENUE/SET_VENUE_ID",
    id
  };
}
export function isVenueOpenHourSkip(id: boolean) {
  return <const>{
    type: "@@VENUE/SET_VENUE_OPEN_HOUR_SKIP",
    id
  };
}

export const getServicesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetServices();
    dispatch(servicesLoaded(response.data));
  };
export const getInterestEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetInterests();
    dispatch(interestLoaded(response.data));
  };
export const getEnvironmentsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetEnvironments();
    dispatch(environmentsLoaded(response.data));
  };
export const getAssetsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetAssets();
    dispatch(assetsLoaded(response.data));
  };
export const getVenueTypesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetVenueTypes();
    dispatch(venueTypesLoaded(response.data));
  };
export const getTypesEffect = () => async (dispatch: Dispatch) => {
  const response = await EnumsApi.GetTypes();
  dispatch(typesLoaded(response.data));
};

export const publishVenueEffect =
  (history: any, id?: string, vaccination_status?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const state = getState();
    let isSkip = state.venueWizard.isOpenHourSkip;
    let VenueBasicInfo = state.venueWizard.detailsFieldsVenue!;
    const service = state.venueWizard.venuesAmenities;
    const bonus_offer = state.venueWizard.venueBonus;
    const availability = getState().venueWizard.availability;
    let data: any = {
      name: VenueBasicInfo.name,
      location: VenueBasicInfo.location,
      description: VenueBasicInfo.description,
      services: service,
      bonus_offer: bonus_offer,
      venue_type_id: Number(VenueBasicInfo.venue_type_id),
      email: VenueBasicInfo.email,
      phone_number: VenueBasicInfo.phone_number,
      lead_time: VenueBasicInfo.lead_time,
      vaccination_status: vaccination_status
        ? vaccination_status
        : VenueBasicInfo.vaccination_status,

      _method: ""
    };
    let arrayImages: VenueImagesFields = {};
    if (VenueBasicInfo.hasOwnProperty("image1")) {
      arrayImages.image1 = VenueBasicInfo.image1;
    }
    if (VenueBasicInfo.hasOwnProperty("image2")) {
      arrayImages.image2 = VenueBasicInfo.image2;
    }
    if (VenueBasicInfo.hasOwnProperty("image3")) {
      arrayImages.image3 = VenueBasicInfo.image3;
    }
    if (VenueBasicInfo.hasOwnProperty("image4")) {
      arrayImages.image4 = VenueBasicInfo.image4;
    }
    let allRequest;

    if (id) {
      let request = data;
      request._method = "PUT";
      const response = await VenueApi.UpdateVenue(
        state.auth.token!,
        id,
        new CreateVenueRequest(request, arrayImages)
      );
      allRequest = response;
    } else {
      data["status"] = "Unpublished";
      const response = await VenueApi.CreateVenue(
        state.auth.token!,
        new CreateVenueRequest(data, arrayImages)
      );
      allRequest = response;
    }
    if (allRequest) {
      if (allRequest.data) {
        let req = {
          venue_id: allRequest.data.id,
          availability
        };
        if (availability.length > 0 && isSkip) {
          await VenueApi.venueOpenHours(
            getState().auth.token!,
            new CreateVenueOpenHoursRequest(req)
          );
        }
        const response = await VenueApi.GetVenues(
          getState().auth.token!
        );
        if (response.data) {
          dispatch(venuesLoaded(response.data));
          dispatch(setLoader(false));
          if (vaccination_status) {
            toast.success("Vaccination Status Updated");
            history.push("/dashboard");
          }
          dispatch(setBookingVenueLoader(false));
        }
        if (id) {
          dispatch(venueWizardSetStep(VenueWizardStep.Preview));
        } else {
          dispatch(venueWizardSetStep(VenueWizardStep.AddSpace));
        }
        window.localStorage.setItem("venue_id", allRequest.data.id);
        dispatch(setVenueIdFields(allRequest.data.id));
        dispatch(setBookingVenueLoader(false));
        dispatch(setLoader(false));
      } else {
        let data: any = allRequest;
        toast.error(data.message!);
        dispatch(setLoader(false));
        dispatch(setBookingVenueLoader(false));
      }
    }
    // dispatch(publishVenue(true));
  };

export const DraftVenueEffect =
  (history: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const state = getState();
    let isSkip = state.venueWizard.isOpenHourSkip;
    let VenueBasicInfo = state.venueWizard.detailsFieldsVenue!;
    const service = state.venueWizard.venuesAmenities;
    const bonus_offer = state.venueWizard.venueBonus;
    const availability = getState().venueWizard.availability;
    let data = {
      name: VenueBasicInfo.name,
      location: VenueBasicInfo.location,
      description: VenueBasicInfo.description,
      services: service,
      bonus_offer: bonus_offer,
      venue_type_id: Number(VenueBasicInfo.venue_type_id),
      email: VenueBasicInfo.email,
      phone_number: VenueBasicInfo.phone_number,
      lead_time: VenueBasicInfo.lead_time,
      vaccination_status: VenueBasicInfo.vaccination_status,
      _method: "",
      status: "Draft"
    };
    let arrayImages: VenueImagesFields = {};
    if (VenueBasicInfo.hasOwnProperty("image1")) {
      arrayImages.image1 = VenueBasicInfo.image1;
    }
    if (VenueBasicInfo.hasOwnProperty("image2")) {
      arrayImages.image2 = VenueBasicInfo.image2;
    }
    if (VenueBasicInfo.hasOwnProperty("image3")) {
      arrayImages.image3 = VenueBasicInfo.image3;
    }
    if (VenueBasicInfo.hasOwnProperty("image4")) {
      arrayImages.image4 = VenueBasicInfo.image4;
    }
    let request;
    const response = await VenueApi.CreateVenue(
      state.auth.token!,
      new CreateVenueRequest(data, arrayImages)
    );
    request = response;

    if (request) {
      if (request.data) {
        let req = {
          venue_id: request.data.id,
          availability
        };
        if (availability.length > 0 && isSkip) {
          const response: any = await VenueApi.venueOpenHours(
            getState().auth.token!,
            new CreateVenueOpenHoursRequest(req)
          );
          if (response.data) {
            dispatch(setLoader(false));
            toast.success("Saved as Draft");
            history.push("/venues");
          } else {
            dispatch(setLoader(false));
            toast.error("Something went wrong");
          }
        }
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong");
      }
    } else {
      dispatch(setLoader(false));
      toast.error("Something went wrong");
    }
  };

export const postVenueVaccinationEffect =
  (history: any, id: string, vaccination_status?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const state = getState();
    const response = await VenueApi.UpdateVenue_vaccination(
      state.auth.token!,
      id,
      new UpdateVenueVaccinationRequest(vaccination_status)
    );
    if (response.data) {
      dispatch(setLoader(false));
      toast.success("Vaccination Status Updated");
      history.push("/dashboard");
    } else {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    }
  };

export const getVenuesEffect =
  (token?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.GetVenues(
      token ? token : getState().auth.token!
    );
    dispatch(venuesLoaded(response.data));
  };
export const getVenueEffectByID =
  (
    id: string,
    capacity?: number,
    start_time?: any,
    end_time?: any,
    date?: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.GetVenuebyId(
      id,
      getState().auth.token!,
      capacity,
      start_time,
      end_time,
      date
    );
    dispatch(venueLoaded(response.data));
  };

export const getVenueEffect =
  (
    id: string,
    capacity?: number,
    start_time?: any,
    end_time?: any,
    date?: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.GetVenuebyslug(
      id,
      getState().auth.token!,
      capacity,
      start_time,
      end_time,
      date
    );
    dispatch(venueLoaded(response.data));
  };

export const getPublicVenuesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await VenueApi.GetPublicVenues();
    if (response.data) {
      dispatch(publicVenuesLoaded(response.data));
      dispatch(setLoader(false));
    }
  };

export const getWorkspacesForYouEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.GetWorkspacesForYouVenues(
      getState().auth.token!
    );
    dispatch(workspacesForYouLoaded(response.data));
  };

export const postVenueAccountDetails =
  (
    request: VenueAccountDetailsRequest,
    isEdit: boolean,
    state: any,
    history: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.postVenueAccontDetails(
      getState().auth.token!,
      request
    );
    if (response.data) {
      if (isEdit) {
        toast.success("Account details successfully updated.");
        if (state == "Success") {
          history.push({ pathName: "success", state: "" });
        } else {
          history.push("/dashboard");
        }
      } else {
        toast.success("Account details successfully added.");
      }
      if (state == "Success") {
        history.push({ pathName: "success", state: "" });
      } else {
        history.push("/dashboard");
      }
    } else {
      toast.error("Something went wrong.");
    }
  };
export const getNearMeVenuesEffect =
  (lat: number, lng: number) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.venuesNearMe(lat, lng);
    dispatch(venueNearMe(response.data));
  };
//assessment
export const PostVenueAssessmentEffect =
  (
    assessment: CreateVenueAssesRequest,
    history: History,
    historyState: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.postAssessment(
      state.auth.token!,
      assessment
    );
    if (response) {
      toast.success("Form successfully sent", {
        position: toast.POSITION.TOP_RIGHT
      });
      // dispatch(getDashboardDataEffect());
      if (historyState.prevLocation == "Success") {
        history.push({ pathname: "/success", state: "" });
      } else {
        history.push("/dashboard");
      }
    }

    // dispatch(publicVenuesLoaded(response.data))
  };

export const PostVenueVaccinationEffect =
  (vaccination_status: CreateVenueVaccinationRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.postVaccinationStatus(
      state.auth.token!,
      vaccination_status
    );
    if (response) {
      toast.success("Form successfully sent", {
        position: toast.POSITION.TOP_RIGHT
      });
      // history.push("/dashboard");
    }

    // dispatch(publicVenuesLoaded(response.data))
  };

export const GetVenueAssessmentEffect =
  (history?: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.getUserAssessment(
      state.auth.token!
    );
    if (response) {
      toast.success("Form successfully sent", {
        position: toast.POSITION.TOP_RIGHT
      });
      // history.push("/dashboard")
    }
    // dispatch(publicVenuesLoaded(response.data))
  };
export const PostVenueRatingEffect =
  (rating: CreateVenueRatingRequest, history: History) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response: any = await VenueApi.postVenueRating(rating);
    if (response.data) {
      history.push("/bookings");
      toast.success("Thank you for feedback", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error("Something went wrong");
    }
    // dispatch(publicVenuesLoaded(response.data))
  };

export const GetVenueOpenHoursEffect =
  (venueToken?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.getVenueOpenHours(
      venueToken ? venueToken : state.auth.token!
    );
    if (response) dispatch(venuesOpenHours(response));
  };
export const postSpaceBlockDays =
  (request: CreateSpaceBlockingRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.postSpaceBlockDay(
      state.auth.token!,
      request
    );
  };

export const getSpaceBlockDaysEffect =
  (request: CreateSpaceBlockingRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.getSpaceBlockDay(
      state.auth.token!
    );
  };

export const postVenueStatusEffect =
  (
    id: number,
    request: CreateVenueCloseStatusRequest,
    message?: string,
    showMessage?: any,
    history?: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const state = getState();
    const response = await VenueApi.CloseVenueStatus(
      state.auth.token!,
      id,
      new CreateVenueCloseStatusRequest(request)
    );
    if (response.data) {
      dispatch(setLoader(false));
      if (message) {
        toast.success(message);
        dispatch(venueWizardSetStep(VenueWizardStep.Success));
      } else {
        dispatch(venueWizardSetStep(VenueWizardStep.Success));
      }
      if (showMessage) {
        history.push("/venues");
      }
    } else {
      dispatch(setLoader(false));

      toast.error("Oops something went wrong");
    }
  };

export const updateWifiDetailVenueEffect =
  (venueId: number, request: CreateUpdateWifiRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.updateWifi(
      state.auth.token!,
      venueId,
      new CreateUpdateWifiRequest(request)
    );
    if (response.data) {
      toast.success("WIFI Details Updated");
    } else {
      toast.error("Oops something went wrong");
    }
  };

export const postUnBlockSpace =
  (request: CreateSpaceUnBlockingRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.unBlockSpaceDay(
      state.auth.token!,
      new CreateSpaceUnBlockingRequest(request)
    );
    if (response) {
      toast.success("Unblocked successfully ");
    } else {
      toast.error("Oops something went wrong");
    }
  };

export const loadVenueForEditEffect =
  (id: string, status?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await VenueApi.GetVenuebyId(id);
    const venue = response.data;
    const promises = venue.images.map(
      async (image, i) =>
        await createFile(
          image,
          venue.uploaded_images[i].file_name,
          venue.uploaded_images[i].mime_type
        )
    );
    const files = await Promise.all(promises);
    let venueDetailsFields: any = {
      description: venue.description,
      name: venue.name,
      email: venue.email,
      phone_number: venue.phone_number,
      lead_time: venue.lead_time,
      location: {
        address: venue.address,
        suburb: venue.suburb,
        postalCode: venue.postcode,
        state: venue.state,
        country: venue.country,
        lat: parseFloat(venue.lat),
        lng: parseFloat(venue.lng)
      },
      bonus_offer: venue.bonus_offer ? venue.bonus_offer : "",
      image1: files[0],
      image2: files[1],
      image3: files[2],
      image4: files[3],
      venue_type_id: venue.venue_type_id,
      wifi_name: venue.wifi_name,
      wifi_password: venue.wifi_password,
      vaccination_status: venue.vaccination_status,
      _method: "PUT"
    };
    dispatch(
      venuesAmenitiesForm(venue.services.map(e => e.id.toString()))
    );
    dispatch(venueDetailsForm(venueDetailsFields));
    dispatch(
      venueSubmitOpenHoursForm(
        venue.availability && venue.availability.availability!
          ? venue.availability.availability
          : ""
      )
    );
    dispatch(
      venueBonusForm(venue.bonus_offer ? venue.bonus_offer : "")
    );

    let venueImagesFields: VenueImagesFields = {
      image1: files[0],
      image2: files[1],
      image3: files[2],
      image4: files[3]
    };
    dispatch(venueSubmitImagesForm(venueImagesFields));
    dispatch(setLoader(false));
  };

export function publishVenue(field: boolean) {
  return <const>{
    type: "@@VENUE/PUBLISH_VENUE",
    field
  };
}

export type VenueWizardAction = ReturnType<
  | typeof venueWizardSetStep
  | typeof publishVenue
  | typeof venueSubmitDetailsForm
  | typeof venueSubmitImagesForm
  | typeof venueSubmitOpenHoursForm
  | typeof venuesLoaded
  | typeof venueLoaded
  | typeof environmentsLoaded
  | typeof assetsLoaded
  | typeof servicesLoaded
  | typeof venueTypesLoaded
  | typeof publicVenuesLoaded
  | typeof isVenueLoaded
  | typeof editVenueLoaded
  | typeof venueWizardReset
  | typeof interestLoaded
  | typeof venueNearMe
  | typeof venuesOpenHours
  | typeof venueDetailsForm
  | typeof venuesAmenitiesForm
  | typeof venueTypeForm
  | typeof venueBonusForm
  | typeof setVenueIdFields
  | typeof typesLoaded
  | typeof isVenueOpenHourSkip
  | typeof workspacesForYouLoaded
>;
