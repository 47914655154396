import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import useBodyClass from "../hooks/useBodyClass";
import { useDispatch } from "react-redux";
import { reduxForm, Field, FormErrors, SubmissionError } from "redux-form";
import { resetPasswordEffect } from "./Auth/Actions";
import IconField from "../UI/IconField";

const NameField = IconField("input__icon--user");
const PasswordField = IconField("input__icon--lock");

type Props = { email?: string; token?: string };

const ResetPassword: FC<Props> = (props) => {
  useBodyClass("bgr-blue");
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="wrapper wrapper-login">
      <a className="logo-login" href="/">
        <img src="/img/logo.png" alt="Dorpee" />
      </a>
      <div className="login-bgr">
        <ResetPasswordForm
          initialValues={{ email: props.email, token: props.token }}
          onSubmit={async (values) => {
            try {
              await dispatch(
                resetPasswordEffect(
                  values.email,
                  values.password,
                  values.repeatPassword,
                  values.token,
                  history
                )
              );
            } catch (error) {
              throw new SubmissionError({ _error: error.message });
            }
          }}
        />
      </div>
    </div>
  );
};

type ResetPasswordFields = {
  email: string;
  password: string;
  repeatPassword: string;
  token: string;
};

const ResetPasswordForm = reduxForm<ResetPasswordFields, {}>({
  form: "resetPassword",
  validate: (values) => {
    const errors: FormErrors<ResetPasswordFields> = {};

    // Email
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    // Password
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 6) {
      errors.password = "Password should be at least 6 characters long";
    }
    // Repeat password
    if (!values.repeatPassword) {
      errors.repeatPassword = "Required";
    } else if (values.repeatPassword !== values.password) {
      errors.repeatPassword = "Repeat password should match original password";
    }

    return errors;
  },
})((props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="login-form">
        <Field
          type="text"
          name="email"
          placeholder="Email"
          component={NameField}
        />
        <Field
          type="password"
          name="password"
          placeholder="Password"
          component={PasswordField}
        />
        <Field
          type="password"
          name="repeatPassword"
          placeholder="Repeat password"
          component={PasswordField}
        />

        {props.error ? (
          <div className="validation-error">{props.error}</div>
        ) : (
          <></>
        )}

        <button
          type="submit"
          className={`btn btn-blue${props.submitting ? " btn--loading" : ""}`}
        >
          Update password
        </button>
      </div>
    </form>
  );
});

export default ResetPassword;
