import { ArrowForward } from "@mui/icons-material";
import React from "react";
import { useDispatch } from "react-redux";

import { Modal } from "../UI/Modal";
import { LoggedIn } from "./Auth/Actions";

const DownLoadModal = props => {
  const dispatch = useDispatch();

  const { fromDashboard, message, showAppModal } = props;
  const hideModal = () => {
    if (fromDashboard) {
      sessionStorage.setItem("appMessage", "NotShow");
      showAppModal(false);
    } else {
      dispatch(LoggedIn(false));
    }
  };

  return (
    <Modal width={500}>
      <div
        className="addressbook-form-box"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "visible"
        }}
      >
        <div
          className="congrat-box__header"
          style={{ background: "#ff567a" }}
        >
          <span>DownLoad APP</span>
          <div onClick={hideModal} className="close-btn"></div>
        </div>

        <div
          className="my-invitation-block"
          style={{ flexDirection: "column" }}
        >
          <div>{message}</div>
          <div className="display-flex">
            <a
              href="https://apps.apple.com/us/app/id1517539910"
              target="_blank"
              style={{ paddingRight: "10px" }}
            >
              <img
                src="img/homePageImg/appstore.svg"
                className="img-fluid h-51"
                alt="appstore_icon"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.droid.dorpee"
              target="_blank"
            >
              <img
                src="img/homePageImg/gplay.svg"
                className="img-fluid h-51"
                alt="playstore_icon"
              />
            </a>
          </div>
          {fromDashboard && (
            <div className="mobile_continue" onClick={hideModal}>
              Continue to mobile website{" "}
              <ArrowForward id="forwardicon" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default DownLoadModal;
