import { ApiClient } from "./ApiClient";

export class EnumsApi {
  static GetInterests() {
    return ApiClient.get<Response<Interest[]>>("interests");
  }
  static GetVenueTypes() {
    return ApiClient.get<Response<VenueType[]>>("types");
  }
  static GetTypes() {
    return ApiClient.get<Response<VenueType[]>>("venue_types");
  }
  static GetServices() {
    return ApiClient.get<Response<Service[]>>("services");
  }
  static GetEnvironments() {
    return ApiClient.get<Response<Environment[]>>("environments");
  }
  static GetPlans() {
    return ApiClient.get<Response<Plan[]>>("plans");
  }
  static GetAssets() {
    return ApiClient.get<Response<Asset[]>>("assets");
  }
  static GetFAQs() {
    return ApiClient.get<Response<FAQs[]>>("faqs");
  }
}

type Response<T> = {
  data: T;
};

export type VenueType = {
  id: number;
  type: string;
};

export type Interest = {
  id: number;
  interest: string;
};

export type Environment = {
  id: number;
  environment: string;
};
export type Asset = {
  id: number;
  asset: string;
};
export type FAQs = {
  id: number;
  question: string;
  answer: string;
  category: string;
  create_at: string;
  updated_at: string;
};
export type Service = {
  id: number;
  service: string;
  status?: any;
};

export type Plan = {
  id: number;
  name: string;
  price: string;
  credits: number;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  type: string;
  free_space?: string;
};

export type Book = {
  user_id: number;
  space_id: number;
  start_date: string;
  end_date: string;
  start_time: any;
  end_time: any;
  booking_as: string;
  no_of_people: number;
  totalCredit: number;
};
