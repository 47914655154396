/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {
  PaymentAPI,
  createPaymentRequest,
  Payment,
} from "../../API/PayementAPI";
import {
  DashboardApi,
  ContactResponse,
  Contactdata,
  ContactIds,
  createContactRequest,
} from "../../API/DashboardApi";
// import { updateUser } from '../../Auth/Actions'
import { toast } from "react-toastify";
import { History } from "history";

export function setContacts(Contacts: Contactdata) {
  return <const>{
    type: "@@wizard/SET_CONTACTS",
    Contacts,
  };
}
export function setEmployerContacts(Contacts: Contactdata) {
  return <const>{
    type: "@@wizard/SET_EMPLOYER_CONTACTS",
    Contacts,
  };
}

export function setContact(Contacts: Contactdata) {
  return <const>{
    type: "@@wizard/SET_CONTACT",
    Contacts,
  };
}

export function setContactInvite(contactId: number) {
  return <const>{
    type: "@@wizard/SET_CONTACT_INVITE",
    contactId,
  };
}
export function setEmployeeInvite(contactId: number) {
  return <const>{
    type: "@@wizard/SET_EMPLOYEE_INVITE",
    contactId,
  };
}

export function setTeamInvite(teamId: number) {
  return <const>{
    type: "@@wizard/SET_TEAM_INVITE",
    teamId,
  };
}

export function setTeams(Teams: Contactdata) {
  return <const>{
    type: "@@wizard/SET_TEAMS",
    Teams,
  };
}
export function setContactIds(contactIds: any[]) {
  return <const>{
    type: "@@wizard/SET_CONTACT_IDS",
    contactIds,
  };
}
export function setEmployeesContactIds(employeesIds: any[]) {
  return <const>{
    type: "@@wizard/SET_EMPLOYEES_CONTACT_IDS",
    employeesIds,
  };
}
export function setTeamsIds(teamsIds: any[]) {
  return <const>{
    type: "@@wizard/SET_TEAM_IDS",
    teamsIds,
  };
}

export function setTeam_ContactError(error: any) {
  return <const>{
    type: "@@wizard/SET_ERRORS",
    error,
  };
}
export function setEditIdName(info: any) {
  return <const>{
    type: "@@wizard/SET_TEAM_ID_NAME",
    info,
  };
}

export const getContactEffects =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await DashboardApi.GetContacts(state.auth.token!);
    if (response) {
      dispatch(setContacts(response));
    }
  };

export const getTeamEffects =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await DashboardApi.GetTeams(state.auth.token!);
    if (response) {
      dispatch(setTeams(response));
    }
  };

export const AddContacts =
  (data: createContactRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();

    const response = await DashboardApi.CreateContact(
      state.auth.token!,
      new createContactRequest(data)
    );
    if (response.data) {
      response.data.status = true;
      dispatch(setContact(response));
      dispatch(setTeam_ContactError({ status: false, message: "" }));
      toast.success("Contact successfully created ");
    } else {
      dispatch(
        setTeam_ContactError({
          status: true,
          message: "Email has already been taken.",
        })
      );
    }
  };

export type BookingAction = ReturnType<
  | typeof setContacts
  | typeof setTeams
  | typeof setContactIds
  | typeof setTeamsIds
  | typeof setContact
  | typeof setTeam_ContactError
  | typeof setEditIdName
  | typeof setContactInvite
  | typeof setEmployeeInvite
  | typeof setTeamInvite
  | typeof setEmployerContacts
  | typeof setEmployeesContactIds
>;
