/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";

import {
  venueWizardSetStep,
  venueTypeForm,
  venueBonusForm,
  getVenueEffectByID,
} from "./Actions";
import { workspaceWizardSetStep } from "../WorkspaceWizard/Actions";
import VenueSteps from "./VenueSteps";
import Helmet from "react-helmet";
import Footer from "../../footer";
import UpdateWifi from "../Dashboard/modals/UpdateWifi";

class SuccessMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bonus_offer: "",
      bonus_offer_error: "",
      isWifiModalOpen: false,
      venueDataWifi: {
        wifi_name: this.props.wifi_name !== "" ? this.props.wifi_name : "",
        wifi_password:
          this.props.wifi_password !== "" ? this.props.wifi_password : "",
        venueId: this.props.venueId
          ? this.props.venueId
          : window.localStorage.getItem("venue_id"),
      },
    };
  }
  componentDidMount() {
    this.setState({ bonus_offer: this.props.bonus_offer });
    if (this.props.venueId) {
      this.props.getVenueEffectByID(this.props.venueId);
    } else {
      this.props.getVenueEffectByID(window.localStorage.getItem("venue_id"));
    }
    window.scrollTo(0, 0);
  }
  handleBonusOffer = (e) => {
    this.setState({ bonus_offer: e.target.value });
  };
  handleNext = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/account-details`,
      state: { prevLocation: "Success" },
    });
    // window.location.href = "/account-details";
  };
  handleBack = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/venue-assessment`,
      state: { prevLocation: "Success" },
    });

    // window.location.href = "/venue-assessment";
  };
  redirectToDashboad = () => {
    window.location.href = "/dashboard";
  };
  redirectToCreateWorkspace = () => {
    window.location.href = "/create-workspace";
  };
  handleWifidetails = () => {
    this.setState({ isWifiModalOpen: true });
  };
  closeModalWifi = () => {
    this.setState({ isWifiModalOpen: false });
  };

  render() {
    return (
      <div className="body">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Dorpee-workspaces on demand" />
          <title>
            {window.location.href.indexOf("edit-workspace") > -1
              ? "Dorpee - Publish WorkSpace"
              : "Dorpee - Publish Venue"}
          </title>
        </Helmet>
        <div className="containerBody bodyTop">
          <VenueSteps
            step={this.props.isEdit ? 5 : 7}
            end={this.props.isEdit ? true : false}
          ></VenueSteps>
          <div className="page-wrapper" style={{ marginTop: 25 }}>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2>Success!</h2>
                <img
                  src="/img/checklg.png"
                  alt=""
                  style={{ marginBottom: 20 }}
                ></img>
                {this.props.isPublished ? (
                  <p
                    className="register-sub-title"
                    style={{ textAlign: "center" }}
                  >
                    Your venue The {window.localStorage.getItem("venue_name")}{" "}
                    is now created, however not published. Please add at least
                    one workspace to publish the venue
                  </p>
                ) : (
                  <p
                    className="register-sub-title"
                    style={{ textAlign: "center" }}
                  >
                    Your venue The {window.localStorage.getItem("venue_name")}{" "}
                    is published!
                  </p>
                )}
              </div>
            </div>
            <div className="bl-time">
              <div className="bl-buttons">
                <div style={{ marginBottom: 20 }}>
                  <a
                    href="#"
                    onClick={(e) => this.handleNext(e)}
                    className=" btn-successBtnNext2"
                  >
                    {" "}
                    <span>Enter Your Bank Details</span>
                  </a>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <a
                    href="#"
                    onClick={(e) => this.handleBack(e)}
                    className=" btn-successBtnNext2 "
                  >
                    {" "}
                    <span> Complete OH&S Self Assessment</span>
                  </a>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <a
                    href="#"
                    className=" btn-successBtnNext2 "
                    onClick={() => this.handleWifidetails()}
                  >
                    {" "}
                    <span> Add Venue Wifi Details</span>
                  </a>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <a
                    href="#"
                    onClick={() => this.redirectToCreateWorkspace()}
                    className="btn btn-successBtn "
                  >
                    {" "}
                    <span> Add Your Next Workspace Type to this Venue</span>
                  </a>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <a
                    href="#"
                    onClick={() => this.redirectToDashboad()}
                    className="btn btn-successBtn "
                  >
                    {" "}
                    <span> Go To Your Venue Dashboard</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer isSubs></Footer>
        {this.state.isWifiModalOpen ? (
          <UpdateWifi
            venueData={this.state.venueDataWifi}
            handleToggleModal={this.closeModalWifi}
          ></UpdateWifi>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bonus_offer: state.venueWizard.venueBonus,
  spaceId: state.workspaceWizard.workspaceId,
  isPublished: state.venueWizard.isVenuePublished,
  wifi_name:
    state.venueWizard.detailsFieldsVenue.wifi_name !== ""
      ? state.venueWizard.detailsFieldsVenue.wifi_name
      : "",
  wifi_password:
    state.venueWizard.detailsFieldsVenue.wifi_password !== ""
      ? state.venueWizard.detailsFieldsVenue.wifi_password
      : "",
});

const mapDispatchToProps = {
  venueWizardSetStep,
  venueTypeForm,
  venueBonusForm,
  workspaceWizardSetStep,
  getVenueEffectByID,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SuccessMessage));
