import React, { FC } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useSelector, useDispatch } from "react-redux";
import {
  AssignmentTurnedIn,
  InsertInvitationSharp,
  PersonOutline
} from "@mui/icons-material";

import { User } from "../API/AuthApi";
import { Venue } from "../API/VenueApi";
import {
  handlePreferrencesModal,
  handlePrivacyModal
} from "../Screens/Dashboard/Actions";
import { AppState } from "../store";
import PrivacyModal from "../Screens/Dashboard/modals/PrivacyModal";
import PreferencesModal from "../Screens/Dashboard/modals/PreferencesModal";

interface Props {
  isVenueUser: boolean;
  user?: User;
  filteredVenues: number;
  isEmpUser: boolean;
  venues: Venue[];
}

const MenuLinks: FC<Props> = ({ isVenueUser, user, venues }) => {
  const dispatch = useDispatch();

  const handleListItem = (text: any) => {
    if (text == "My Search Preferences") {
      dispatch(handlePreferrencesModal(true));
    }
    // else if (text == "Privacy Settings") {
    //   dispatch(handlePrivacyModal(true));
    // }
    //  else if (text == "My Address Book") {
    //   dispatch(handleAddressModal(true));
    // }
  };

  const prefModal = useSelector(
    (state: AppState) => state.dashboard.pref_modal
  );

  const privacy_modal = useSelector(
    (state: AppState) => state.dashboard.privacy_modal
  );

  const handleprivModal = () => {
    dispatch(handlePrivacyModal(false));
  };

  const showSuccess = (text: any) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  };

  return (
    <>
      <ul className="clearfix">
        {user ? (
          isVenueUser ? (
            <>
              {[
                {
                  text: "My Dashboard",
                  link: "dashboard",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-credits"></div>
                    </div>
                  )
                },
                {
                  text: "My Venues & Workspaces",
                  link: "venues",
                  icon: (
                    <PersonOutline
                      style={{
                        color: "rgb(19 160 222)",
                        marginLeft: "-1px"
                      }}
                    ></PersonOutline>
                  )
                },
                {
                  text: "My Bookings",
                  link: "bookings",
                  icon: (
                    <div
                      className="bookings-icon"
                      style={{ width: "15px", marginLeft: "2px" }}
                    ></div>
                  )
                },
                {
                  text: "Today's Bookings",
                  link: "booking's-today",
                  icon: (
                    <AssignmentTurnedIn
                      style={{
                        color: "rgb(19 160 222)",
                        marginLeft: "-1px"
                      }}
                    ></AssignmentTurnedIn>
                  )
                },
                {
                  text: "My Transactions",
                  link: "transactions",
                  icon: (
                    <div
                      className="transaction-icon"
                      style={{ width: "20px", marginLeft: "-1px" }}
                    ></div>
                  )
                },
                {
                  text: "Payment Details",
                  link: "account-details",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-payments"></div>
                    </div>
                  )
                },
                {
                  text: "Block Out Times",
                  link: "space-availability",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-addressBook"></div>
                    </div>
                  )
                }
              ].map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    selected={
                      window.location.href.indexOf(`${item.link}`) >
                      -1
                        ? // window.location.pathname.includes(
                          //   `${item.link}`
                          // )
                          true
                        : false
                    }
                    component={Link}
                    to={`/${item.link}`}
                    sx={{
                      borderBottom: "1px solid #f0f0f0"
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                );
              })}

              {venues && venues.length > 0 ? (
                <ListItem
                  selected={
                    window.location.pathname.includes(
                      "manage/availability"
                    )
                      ? true
                      : false
                  }
                  component={Link}
                  to={`/manage/availability`}
                  sx={{
                    borderBottom: "1px solid #f0f0f0"
                  }}
                >
                  <ListItemIcon>
                    <img
                      className="clock-icon"
                      src="/img/icons/clock.svg"
                      alt="workspace availability link"
                      title={"Workspace availability"}
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: 0
                      }}
                    ></img>
                  </ListItemIcon>
                  <ListItemText primary={"Workspaces Availability"} />
                </ListItem>
              ) : null}
              <ListItem
                selected={
                  window.location.pathname.includes("invite-friend")
                    ? true
                    : false
                }
                component={Link}
                to={`/invite-friend`}
                sx={{
                  borderBottom: "1px solid #f0f0f0"
                }}
              >
                <ListItemIcon>
                  <InsertInvitationSharp
                    style={{
                      color: "rgb(19 160 222)",
                      marginLeft: "-1px"
                    }}
                  ></InsertInvitationSharp>
                </ListItemIcon>
                <ListItemText primary={"Invite Friends"} />
              </ListItem>
            </>
          ) : (
            <>
              {[
                {
                  text: "My Dashboard",
                  link: "dashboard",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-credits"></div>
                    </div>
                  )
                },
                {
                  text: "Update My Account",
                  link: "profile",
                  icon: (
                    <PersonOutline
                      style={{
                        color: "rgb(19 160 222)",
                        marginLeft: "-1px"
                      }}
                    ></PersonOutline>
                  )
                },
                {
                  text: "My Bookings",
                  link: "bookings",
                  icon: (
                    <div
                      className="bookings-icon"
                      style={{ width: "15px", marginLeft: "2px" }}
                    ></div>
                  )
                },
                {
                  text: "My Transactions",
                  link: "transactions",
                  icon: (
                    <div
                      className="transaction-icon"
                      style={{ width: "20px", marginLeft: "-1px" }}
                    ></div>
                  )
                },
                {
                  text: "My Payment Details",
                  link: "my-paymentOptions",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-payments"></div>
                    </div>
                  )
                },
                {
                  text: "My Address Book",
                  link: "my-contacts",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-addressBook"></div>
                    </div>
                  )
                },
                {
                  text: "My Search Preferences",
                  icon: (
                    <div
                      className="data__icon"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="data__icon-prefernces"></div>
                    </div>
                  )
                },
                {
                  text: "Invite Friends",
                  link: "invite-friend",
                  icon: (
                    <InsertInvitationSharp
                      style={{
                        color: "rgb(19 160 222)",
                        marginLeft: "-1px"
                      }}
                    ></InsertInvitationSharp>
                  )
                }
              ].map((item, index) => {
                if (
                  item.text == "My Search Preferences"
                  // || item.text == "Privacy Settings"
                ) {
                  return (
                    <ListItem
                      key={index}
                      selected={
                        window.location.pathname.includes(
                          `${item.link}`
                        )
                          ? true
                          : false
                      }
                      sx={{
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer"
                      }}
                      onClick={() => handleListItem(item.text)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={index}
                      selected={
                        window.location.pathname.includes(
                          `${item.link}`
                        )
                          ? true
                          : false
                      }
                      component={Link}
                      to={`/${item.link}`}
                      sx={{
                        borderBottom: "1px solid #f0f0f0"
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItem>
                  );
                }
              })}
            </>
          )
        ) : (
          <>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className="block2">
              <Link to="/login">Login</Link>
            </li>
            <li className="block2">
              <Link to="/register/select-type">Get Started</Link>
            </li>
          </>
        )}

        {prefModal ? <PreferencesModal /> : null}
        {privacy_modal ? (
          <PrivacyModal
            handleprivacyModal={handleprivModal}
            showSuccess={showSuccess}
          />
        ) : null}
        {/* {contactsModal ? <AddressBookModal /> : null} */}
      </ul>
    </>
  );
};
export default MenuLinks;
