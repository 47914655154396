import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";

import UploadFieldVenueSpace from "../../../UI/UploadFieldVenueWorkspace/UploadVenueSpace";
import { AppState } from "../../../store";

interface OwnProps {
  submitFailed?: boolean;
  error?: any;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const selector = formValueSelector("venueDetails");

const WorkspacePhotosUploader: FC<Props> = ({
  submitFailed,
  error,
  image1,
  image2,
  image3,
  image4
}) => {
  const [haveImage, setHaveImage] = useState(false);

  useEffect(() => {
    if (image1 || image2 || image3 || image4) {
      setHaveImage(true);
    } else {
      setHaveImage(false);
    }
  }, [image1, image2, image3, image4]);

  return (
    <div className="md-workspace-upload">
      <div className="field-input is-field-upload">
        <div className="file-upload">
          {!haveImage && (
            <label
              className="btn btn-outline-primary"
              htmlFor="file-uploader"
            >
              Upload Venue Photos
            </label>
          )}
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            className="input-file-hidden"
          />
        </div>
      </div>
      <div className="all-item">
        <div className="row">
          <div className="col-sm-6 col-md-3 col-12">
            <div className="item venueImg">
              <Field
                id="file-uploader"
                name="image1"
                component={UploadFieldVenueSpace}
                type="file"
              />
              {(image2 || image3 || image4) && !image1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#fff"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-12">
            <div className="item venueImg">
              <Field
                name="image2"
                component={UploadFieldVenueSpace}
                type="file"
              />
              {image1 && !image2 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#fff"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-12">
            <div className="item venueImg">
              <Field
                name="image3"
                component={UploadFieldVenueSpace}
                type="file"
              />
              {image1 && image2 && !image3 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#fff"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-12">
            <div className="item venueImg">
              <Field
                name="image4"
                component={UploadFieldVenueSpace}
                type="file"
              />
              {image1 && image2 && image3 && !image4 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#fff"
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div>Max size 10mb. Accepted formats JPG and PNG</div>
      {submitFailed && error ? (
        <div className="validation-error">{error}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    image1: selector(state, "image1"),
    image2: selector(state, "image2"),
    image3: selector(state, "image3"),
    image4: selector(state, "image4")
  };
};

export default connect(mapStateToProps)(WorkspacePhotosUploader);
