import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AppState } from "../../store";
import { PostVenueAssessmentEffect, getVenuesEffect } from "./Actions";
import { VenueApi, Assesment } from "../../API/VenueApi";
import { Container, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const VenueAssessment: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const token = useSelector((state: AppState) => state.auth.token!);
  const venues = useSelector((state: AppState) => state.venueWizard.venues!);
  const venueFirst = venues.find((v) => v.assessment == null);
  const [userAssessment, setuserAssessment] = useState({
    user_id: 0,
    answers: [{ answer: "" }],
    result: "",
    venue_id: 0,
  });
  const [questions, setQuestions]: any = useState();
  const [assessment, setAssessment] = useState([
    { question: "", answer: "", id: 0, options: [] },
  ]);
  const [checkboxValue, setchechboxValue] = useState(false);
  const [venue_id, setvenueId]: any = useState();
  const [checkquestionStatus, setcheckquestionStatus] = useState([
    { question_id: 0, isMatched: false },
  ]);
  const [errMessage, seterrMessage] = useState({
    errorMessage: "",
    isError: false,
  });
  const [isVenueEmpty, setisVenueEmpty] = useState(true);

  useEffect(() => {
    getQuestion();
    dispatch(getVenuesEffect());

    //dispatch(GetVenueAssessmentEffect());
  }, []);
  useEffect(() => {
    if (venueFirst) {
      venues && getUserassessment(venueFirst.id);
      venues && setvenueId(venueFirst.id);
    } else {
      setisVenueEmpty(false);
    }
  }, [venues]);
  const getUserassessment = async (id: number) => {
    hasVenues();

    let response: any = await VenueApi.getUserAssessment(token);
    if (response) {
      let data = response.data;
      for (var i = 0; i < data.length; i++) {
        if (data[i].venue_id == id) {
          let assessmentData = {
            user_id: data[i].user_id,
            answers: data[i].answers,
            result: data[i].result,
            venue_id: data[i].venue_id,
          };
          setuserAssessment(assessmentData);
        }
      }
    }
  };
  const hasVenues = () => {
    let availableVenuesCounts = 0;
    venues.map((item) => {
      if (item.assessment != null) {
        availableVenuesCounts += 1;
      }
    });
    if (availableVenuesCounts < 0) {
      setisVenueEmpty(false);
    } else {
      setisVenueEmpty(true);
    }
  };
  const getQuestion = async () => {
    let response: any = await VenueApi.getAssessment();
    setAssessment(response.data);
  };
  const handleCheckBox = () => {
    setchechboxValue(!checkboxValue);
  };
  const handleUserInput = (e: any, id: number) => {
    let obj = { question_id: id, answer: e.target.value };
    let array: Assesment[] = questions ? questions : [];
    const hasId = array.find((e) => e.question_id == id);
    if (hasId) {
      var index = array.findIndex((e) => e.question_id == id);
      questions[index] = obj;
    } else array.push(obj);
    setQuestions(array);
  };
  const handleVenueName = (value: any) => {
    setvenueId(value.target.value);
    getUserassessment(value.target.value);
  };

  const hangleSubmit = async (id: any) => {
    let isMatched = true;

    if (questions && questions.length === assessment.length) {
      if (userAssessment.result != undefined && userAssessment.venue_id == id) {
        let answersMatchedList = {};
        let answersMatchedArray: any[] = [];
        userAssessment.answers.forEach((prevasn) => {
          for (var i = 0; i < questions.length; i++) {
            if (prevasn.answer === questions[i].answer) {
              answersMatchedList = {
                question_id: questions[i].question_id,
                isMatched: true,
              };
              let index = answersMatchedArray.find(
                (v) => v.question_id === questions[i].question_id
              );
              if (!index) {
                answersMatchedArray.push(answersMatchedList);
              }
            } else {
              answersMatchedList = {
                question_id: questions[i].question_id,
                isMatched: false,
              };
              let index = answersMatchedArray.find(
                (v) => v.question_id === questions[i].question_id
              );
              if (!index) {
                answersMatchedArray.push(answersMatchedList);
              }
              isMatched = false;
            }
          }
        });
        setcheckquestionStatus(answersMatchedArray);
      }
      if (isMatched) {
        dispatch(
          PostVenueAssessmentEffect(
            { assessments: questions, venue_id: venue_id },
            history,
            state
          )
        );
      } else {
        toast.error("Please answer all questions correctly");
      }
    } else {
      seterrMessage({
        errorMessage: "Please answer all questions",
        isError: true,
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Dorpee-workspaces on demand" />
        <title>Dorpee Venue</title>
      </Helmet>
      <div className="container pd-0">
        <div className="dashboard">
          <div className="dashboard__top">
            <div className="dashboard__title">OH&S Self-Assessment</div>
          </div>
        </div>

        <div>
          <div
            className="utilization-chart__wrapper"
            style={{ marginBottom: 145 }}
          >
            <div className="col-md-12">
              {isVenueEmpty ? (
                <div>
                  {isVenueEmpty ? (
                    <div className="col-md-3" style={{ marginLeft: 15 }}>
                      <label htmlFor="">Choose your venue</label>
                      <div className="input-container">
                        <div className="input-select pl-12">
                          <select
                            // style={{ width: 100, height: 25 }}
                            value={venue_id}
                            onChange={(e) => handleVenueName(e)}
                          >
                            {venues.map((item) => {
                              return item.assessment == null ? (
                                <option value={item.id}>{item.name}</option>
                              ) : null;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <Container>
                    <Row>
                      {assessment.map((item) => (
                        <Col xs="12">
                          <p style={{ marginLeft: 20, marginTop: 20 }}>
                            <div
                              className="questions_text"
                              style={{
                                color: checkquestionStatus.find(
                                  (v) =>
                                    v.question_id == item.id && !v.isMatched
                                )
                                  ? "red"
                                  : "",
                              }}
                            >
                              {item && item!.question}
                            </div>
                            <div
                              style={{
                                marginTop: 30,
                              }}
                            >
                              <input
                                name={`rd${item.id}`}
                                value={item.options[0]}
                                type="radio"
                                id={`rd${item.id}`}
                                onChange={(e) => {
                                  handleUserInput(e, item!.id);
                                }}
                              />
                              <label htmlFor={`rd${item.id}`}>
                                {item.options[0]}
                              </label>
                              <input
                                name={`rd${item.id}`}
                                value={item.options[1]}
                                type="radio"
                                id={`rdno${item.id}`}
                                onChange={(e) => {
                                  handleUserInput(e, item!.id);
                                }}
                              />
                              <label
                                style={{ marginLeft: 10 }}
                                htmlFor={`rdno${item.id}`}
                              >
                                {item.options[1]}
                              </label>
                              {item.options.find((e) => e == "NA") ? (
                                <>
                                  <input
                                    name={`rd${item.id}`}
                                    value="NA"
                                    type="radio"
                                    id={`rdna${item.id}`}
                                    onChange={(e) => {
                                      handleUserInput(e, item!.id);
                                    }}
                                  />
                                  <label
                                    style={{ marginLeft: 10 }}
                                    htmlFor={`rdna${item.id}`}
                                  >
                                    NA
                                  </label>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </p>
                        </Col>
                      ))}
                    </Row>
                  </Container>

                  <div style={{ marginLeft: 36 }}>
                    <div style={{ marginTop: 80 }}>
                      <input
                        id="chk1"
                        type="checkbox"
                        defaultChecked={checkboxValue}
                        onChange={(e) => handleCheckBox()}
                        style={{ marginRight: 10 }}
                      />
                      <label htmlFor="chk1">
                        I confirm that all the information I have provided is
                        true and correct to the best of my knowledge
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <input
                        type="submit"
                        className={
                          checkboxValue
                            ? `btn btn-blue`
                            : "btn btn grey disabled"
                        }
                        onClick={(e) => hangleSubmit(venue_id)}
                        disabled={!checkboxValue}
                        style={{
                          width: 150,
                          marginTop: 10,
                        }}
                      ></input>
                    </div>
                    <div style={{ color: "red" }}>
                      {errMessage.isError ? errMessage.errorMessage : ""}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 100,
                  }}
                  className="dashboard__title"
                >
                  You have completed the OH&S self-assessment.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueAssessment;
