import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

import { Venue, VenueApi } from "../../API/VenueApi";
import { AppState } from "../../store";
import ChangeSpaceStatusModal from "../Dashboard/modals/ChangeSpaceStatus";
import ChangeStatusModal from "../Dashboard/modals/ChangeStatusModal";
import Confirmation from "../Dashboard/modals/Confirmation";
import SpaceAvailabilityModal from "../Dashboard/modals/SpaceAvailabilityModal";
import {
  GetSpaceAvailabilityByIdEffect,
  GetSpaceAvailabilityEffect,
  getVenuesEffect,
  loadWorkspaceForEditEffect
} from "../WorkspaceWizard/Actions";
import { setLoader } from "../Loader/Actions";

const VenueWorkspaceItems = ({
  workspace,
  venueId
}: {
  workspace: Venue;
  venueId: number;
}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const userToken = useSelector((state: AppState) => state.auth);
  const [isConfimrationBoxOpen, setisConfimrationBoxOpen] =
    useState(false);
  const [isConfimrationBoxOpen2, setisConfimrationBoxOpen2] =
    useState(false);
  const [spaceId, setId] = useState(0);
  const [openSpaceAvailability, setOpenSpaceAvailability] =
    useState(false);
  const [id, setid] = useState<Number>(0);
  const hideModal = () => {
    setMessage("");
    setisConfimrationBoxOpen(false);
    setisConfimrationBoxOpen2(false);
    setOpenSpaceAvailability(false);
  };
  const [isCloseStatus, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setheader] = useState("");
  useEffect(() => {
    dispatch(GetSpaceAvailabilityEffect());
  }, []);
  const openConfirmationBox = async (id: number) => {
    // handleDeleteVenue(id);
    setid(id);
    setMessage("Are you sure you want to delete this workspace");
    setheader("DELETE WORKSPACE");
    setisConfimrationBoxOpen(true);
  };
  const openConfirmationBox2 = async (id: any) => {
    setid(id);
    setheader("Update WORKSPACE");
    setTimeout(() => {
      setisConfimrationBoxOpen2(true);
    }, 500);
  };
  const handleDeleteVenue = async (id: number) => {
    if (isConfimrationBoxOpen) {
      const response: any = await VenueApi.deleteSpace(
        userToken.token!,
        id
      );
      dispatch(setLoader(true));
      if (response.success) {
        dispatch(getVenuesEffect());
        toast.success("Space Deleted");
        setisConfimrationBoxOpen(false);
        dispatch(setLoader(false));
      } else {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.error("Something Went Wrong");
        }
        dispatch(setLoader(false));
      }
    }
  };
  const handleEdit = (id: any) => {
    window.localStorage.setItem("editSpaceAvailability", id);
    dispatch(GetSpaceAvailabilityByIdEffect(id));
    setTimeout(() => {
      setId(id);
      setOpenSpaceAvailability(true);
    }, 1000);
  };
  return (
    <div className="booking_table_margins">
      <>
        <div className="team-list ">
          <table className="team-list-table table-style">
            <thead>
              <tr>
                <th className="table-head-venue">
                  Workspace Type Name
                </th>
                <th className="table-head-venue">
                  Number of Workspaces
                </th>
                <th className="table-head-venue">
                  Capacity Per Workspace
                </th>
                <th className="table-head-venue">Price </th>
                <th className="table-head-venue">Actions</th>
                <th className="table-head-venue">Status</th>
              </tr>
            </thead>
            <tbody>
              {workspace.spaces.map((s, index) => (
                <tr key={index} style={{ height: 50 }}>
                  <td
                    className="table-style"
                    style={{
                      fontWeight: "bold",
                      minWidth: "2rem",
                      maxWidth: "7rem"
                    }}
                  >
                    <Link to={`workspace/${venueId}`}>{s.name}</Link>
                  </td>
                  <td
                    className="table-style"
                    style={{ minWidth: "2rem", maxWidth: "7rem" }}
                  >
                    {s.quantity}
                  </td>
                  <td
                    className="table-style"
                    style={{ minWidth: "2rem", maxWidth: "7rem" }}
                  >
                    {s.capacity}
                  </td>
                  {s.price == null ? (
                    <td
                      className="table-style"
                      style={{ minWidth: "2rem", maxWidth: "7rem" }}
                    >
                      0.00
                    </td>
                  ) : (
                    <td
                      className="table-style"
                      style={{ minWidth: "2rem", maxWidth: "7rem" }}
                    >
                      ${s.price}
                    </td>
                  )}
                  <td
                    className="table-style"
                    style={{ minWidth: "2rem", maxWidth: "7rem" }}
                  >
                    <div
                      style={{
                        height: 20,
                        flexDirection: "row",
                        display: "flex"
                      }}
                    >
                      <a
                        className="content-title__edit"
                        href={`edit-workspace/${s.id}`}
                        title="Edit Workspace Type"
                        onClick={() => {
                          window.localStorage.setItem(
                            "venue_id",
                            venueId.toString()
                          );
                        }}
                      >
                        <div className="dashboard__edit edit-mbl"></div>
                      </a>
                      <a
                        onClick={() => {
                          handleEdit(s.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="clock-icon edit-mbl"
                          src="/img/icons/clock.svg"
                          alt=""
                          title={"Workspace Type Availability"}
                        ></img>
                      </a>
                      <a
                        className="content-title__edit"
                        title="Delete Workspace"
                        onClick={e => openConfirmationBox(s.id)}
                      >
                        <div className="dashboard__delete edit-mbl"></div>
                      </a>
                    </div>
                  </td>

                  <td
                    className="table-style"
                    style={{ minWidth: "2rem", maxWidth: "7rem" }}
                  >
                    <div
                      style={{
                        height: 20,
                        flexDirection: "row",
                        display: "flex"
                      }}
                    >
                      <span
                        style={{
                          color:
                            s.status === "Closed"
                              ? "#F5597F"
                              : "#42D68B"
                        }}
                      >
                        {s.status === "Closed"
                          ? "Draft"
                          : "Published"}
                      </span>
                      <a
                        className="content-title__edit"
                        title="Edit Workspace Type"
                        onClick={() => {
                          openConfirmationBox2(s.id);
                        }}
                      >
                        <div className="dashboard__edit edit-mbl"></div>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
      {isConfimrationBoxOpen ? (
        <Confirmation
          message={message}
          decline={hideModal}
          accept={handleDeleteVenue}
          id={id}
          modalHeader={header}
          acceptText={"Yes"}
          declineText={"No"}
          showButtons={true}
        ></Confirmation>
      ) : null}
      {openSpaceAvailability ? (
        <SpaceAvailabilityModal
          spaceId={spaceId}
          CloseModal={hideModal}
        />
      ) : null}
      {isConfimrationBoxOpen2 ? (
        <ChangeSpaceStatusModal
          message={message}
          decline={hideModal}
          history={history}
          flag={true}
          id={id}
          modalHeader={header}
          acceptText={"Yes"}
          declineText={"No"}
        />
      ) : null}
    </div>
  );
};

export default VenueWorkspaceItems;
