import React from "react";
import { Modal } from "../../../UI/Modal";

class ShowRatingsModal extends React.Component {
  constructor(props) {
    super(props);
  }

  accept() {
    this.props.accept(this.props.id);
  }

  decline() {
    this.props.decline();
  }
  render() {
    return (
      <>
        <Modal>
          <div
            className="addressbook-form-box"
            style={{ maxHeight: "85vh", overflow: "auto" }}
          >
            <div className="congrat-box__header">
              <span>{this.props.selectedVenue.name}'s Ratings</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              {this.props.selectedVenue.ratings?.map(item => {
                return (
                  <div className="rating_content">
                    <div className="rating-text">
                      <div>{item.user.first_name} </div>
                      <div className="venue-rating__left">
                        {Array.from(
                          Array(
                            Number(item.rating) === 0
                              ? 1
                              : Math.trunc(item.rating)
                          ),
                          (e, i) => {
                            return (
                              <div className="rating-star"></div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <p style={{ marginTop: 30 }}>{item.review}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ShowRatingsModal;
