import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";

const EmployeeLimitModal = (props) => {
  return (
    <Modal>
      <div className="addressbook-form-box">
        <div className="congrat-box__header">
          <span>Limit Exceed</span>
          <div
            className="close-btn"
            onClick={() => props.handleClose(false)}
          ></div>
        </div>
        <div className="addressbook-form">
          <div>{props.message}</div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeLimitModal;
