import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import {
  getFormated12hTime,
  getDateformated
} from "../helpers/DateHelpers";
import { getDashboardDataEffect } from "../Screens/Dashboard/Actions";
import { getProfileEffect } from "../Screens/Auth/Actions";
import moment from "moment";
import { ChangeBookingStatusEffect } from "../Screens/Book/Booking/Actions";
import Helmet from "react-helmet";

const VenueTodayBookings: FC = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector(
    (state: AppState) => state.dashboard.dashboardData
  );
  const activeBookings = dashboardData.venues.active_booking;
  let currentDate = new Date().setHours(0, 0, 0, 0);
  let activeBookingsChagned = activeBookings.filter(
    (v: any) =>
      getDateformated(v.start_date).setHours(0, 0, 0, 0) ===
      currentDate
  );

  useEffect(() => {
    dispatch(getProfileEffect());
    dispatch(getDashboardDataEffect());
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Bookings</title>
      </Helmet>
      <div className="container pd-0 booking_table_margins">
        <div className="approval__header-title ">
          <div className="dashboard__title headerTable">
            Today's Bookings
          </div>
        </div>
        <div className="team-list ">
          <table className="team-list-table table-style">
            <thead>
              <tr>
                <th className="table-head">Workspace (Venue)</th>
                <th className="table-head">Booked Workspaces</th>
                <th className="table-head">Date/Time</th>
                <th className="table-head">Customer</th>
                <th className="table-head">Status</th>
              </tr>
            </thead>
            <tbody>
              {activeBookingsChagned.map((activeBooking: any) => (
                <tr style={{ height: 50 }}>
                  <td className="table-style">
                    {activeBooking.space.name}(
                    {activeBooking.space.venue.name})
                  </td>
                  <td className="table-style">
                    {activeBooking.no_of_booked_spaces}
                    {activeBooking.no_of_booked_spaces
                      ? " workspaces"
                      : "N/A"}
                  </td>
                  <td className="table-style">
                    {moment(
                      activeBooking.start_date,
                      "MM-DD-YYYY"
                    ).format("DD-MM-YYYY")}{" "}
                    {getFormated12hTime(activeBooking.start_time)} -{" "}
                    {getFormated12hTime(activeBooking.end_time)}
                  </td>
                  <td className="table-style">
                    {activeBooking.user.first_name}
                    {activeBooking.user.last_name}
                    <br />
                    {activeBooking.user.phone_number}
                  </td>
                  <td className="table-style">
                    {" "}
                    {activeBooking.status != "Checked-Out" ? (
                      <button
                        style={{ padding: 0, width: "100%" }}
                        type="submit"
                        onClick={() =>
                          dispatch(
                            ChangeBookingStatusEffect({
                              booking_id: activeBooking.id,
                              status:
                                activeBooking.status === "Scheduled"
                                  ? "Checked-In"
                                  : "Checked-Out"
                            })
                          )
                        }
                        className={`btn2 btn-blue`}
                      >
                        <span className="check-in-text">
                          {activeBooking.status === "Scheduled"
                            ? "Check In"
                            : "Check Out"}
                        </span>
                      </button>
                    ) : (
                      "Checked Out"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default VenueTodayBookings;
