import React, { FC, useEffect } from 'react';
import { Link, NavLink, withRouter, Router, RouteComponentProps, useHistory } from 'react-router-dom';
import useBodyClass from '../hooks/useBodyClass';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field, FormErrors, FormState, WrappedFieldProps, SubmissionError } from 'redux-form';
import { logInEffect, forgotPasswordEffect } from './Auth/Actions';
import IconField from '../UI/IconField';
import { isError } from '../API/AuthApi';


const NameField = IconField('input__icon--user');

const ForgotPassword: FC = () => {
  useBodyClass('bgr-blue');
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="wrapper wrapper-login"><a className="logo-login" href="/"><img src="/img/logo.png" alt="Dorpee" /></a>
      <div className="login-bgr">
        <LoginForm onSubmit={async (values) => {
          const result = await dispatch(forgotPasswordEffect(values.email, history));
          if (isError(result)) {
            throw new SubmissionError<ForgotPasswordFields>({ email: result.error });
          } else {
            history.push('/forgot-password-sent');
          }
        }} />
      </div>
    </div>
  );
}

type ForgotPasswordFields = {
  email: string
}

const LoginForm = reduxForm<ForgotPasswordFields, {}>({
  form: 'login',
  validate: values => {
    const errors: FormErrors<ForgotPasswordFields> = {};

    // Email
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }
})(
  (props) => {
    const history = useHistory();


    return <form onSubmit={props.handleSubmit}>
      <div className="login-form">
        <Field type="text" name="email" placeholder="Email" component={NameField} />

        {props.error ? <div className="validation-error">{props.error}</div> : <></>}


        <button type="submit" className={`btn btn-blue${props.submitting ? ' btn--loading' : ''}`}>Reset password</button>
      </div>
    </form >
  })


export default ForgotPassword;
