/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Dispatch } from "redux";
import { toast } from "react-toastify";

import { WorkspaceImagesFields } from "./WorkspaceImagesForm";
import { WorkspaceDetailsFields } from "./CreateWorkspace";
import { AppState } from "../../store";
import {
  VenueApi,
  Workspace,
  Venue,
  CreateVenueOpenHoursRequest,
  CreateVenueCloseStatusRequest
} from "../../API/VenueApi";
import {
  EnumsApi,
  VenueType,
  Environment,
  Service,
  Asset
} from "../../API/EnumsApi";
import { WorkspaceWizardStep } from "./Reducers";
import {
  CreateWorkspaceRequest,
  WorkspaceApi,
  CreateSpaceBlockRequest,
  BlocksSpaces,
  CreateWorkspaceAvailabilityRequest
} from "../../API/WorkspaceApi";
import { VenueWizardStep } from "../VenueWizard/Reducers";
import { createFile } from "../../common/createFile";
import { setLoader } from "../Loader/Actions";
import { venueWizardSetStep } from "../VenueWizard/Actions";
import { formatedDateNew } from "../../helpers/DateHelpers";

export function workspaceWizardReset() {
  return <const>{
    type: "@@workspaceWizard/RESET"
  };
}

export function workspaceFilterLoad(filter: any) {
  return <const>{
    type: "@@workspaceWizard/FILTER_LOAD",
    filter
  };
}
export function workspaceFilterTypeLoad(filter: any) {
  return <const>{
    type: "@@workspaceWizard/FILTER_TYPE_LOAD",
    filter
  };
}
export function workspaceFilterEnvLoad(filter: any) {
  return <const>{
    type: "@@workspaceWizard/FILTER_ENV_LOAD",
    filter
  };
}
export function workspaceFilterSortLoad(filter: any) {
  return <const>{
    type: "@@workspaceWizard/FILTER_SORT_NAME",
    filter
  };
}

export function workspaceWizardSetStep(step: WorkspaceWizardStep) {
  return <const>{
    type: "@@workspace/SET_STEP",
    step
  };
}
export function venuesLoaded(venues: Venue[]) {
  return <const>{
    type: "@@workspace/VENUES_LOADED",
    venues
  };
}

export const venueTypesLoaded = (types: VenueType[]) =>
  <const>{
    type: "@@workspaceWizard/VENUE_TYPES_LOADED",
    types
  };

export const editVenueLoaded = (workspace: Workspace) =>
  <const>{
    type: "@@workspaceWizard/EDIT_VENUE_LOADED",
    workspace
  };

export const environmentsLoaded = (environments: Environment[]) =>
  <const>{
    type: "@@workspaceWizard/ENVIRONMENTS_LOADED",
    environments
  };

export const assetsLoaded = (assets: Asset[]) =>
  <const>{
    type: "@@workspaceWizard/ASSETS_LOADED",
    assets
  };
export const servicesLoaded = (services: Service[]) =>
  <const>{
    type: "@@workspaceWizard/SERVICES_LOADED",
    services
  };

export function venueLoaded(workspace: Workspace) {
  return <const>{
    type: "@@workspace/VENUE_LOADED",
    workspace
  };
}

export function publicVenuesLoaded(venues: Workspace[]) {
  return <const>{
    type: "@@workspace/PUBLIC_VENUES_LOADED",
    venues
  };
}

export function workspaceSubmitDetailsForm(
  fields: WorkspaceDetailsFields
) {
  return <const>{
    type: "@@workspace/SUBMIT_DETAILS_FORM",
    fields
  };
}

export function isAutoFocus(field: boolean) {
  return <const>{
    type: "@@workspace/AUTOFOCUS",
    field
  };
}
export function workspaceSubmitImagesForm(
  fields: WorkspaceImagesFields
) {
  return <const>{
    type: "@@workspace/SUBMIT_IMAGES_FORM",
    fields
  };
}

export function getWorkspacesBlocks(fields: BlocksSpaces[]) {
  return <const>{
    type: "@@workspace/BLOCKED",
    fields
  };
}
export function storeSpaceAvailability(fields: BlocksSpaces) {
  return <const>{
    type: "@@workspace/Availability",
    fields
  };
}
export function workspaceSuggestedPrice(fields: any) {
  return <const>{
    type: "@@WORKSPACE/SUGGESTED_PRICE",
    fields
  };
}
export function setSpaceID(id: any) {
  return <const>{
    type: "@@WORKSPACE/SET_ID",
    id
  };
}

export const setSpaceFlow = () => {
  return <const>{
    type: "@@workspaceWizard/SPACE_FLOW"
  };
};

export function workspaceAvailabilityLoad(data: any) {
  return <const>{
    type: "@@workspace/AVAILABILITYUPDATE",
    data
  };
}

export function totalWorkSpaceLoad(data: any) {
  return <const>{
    type: "@@workspace/TOTALSPACES",
    data
  };
}

export const getServicesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetServices();
    dispatch(servicesLoaded(response.data));
  };
export const getEnvironmentsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetEnvironments();
    dispatch(environmentsLoaded(response.data));
  };
export const getAssetsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetAssets();
    dispatch(assetsLoaded(response.data));
  };
export const getVenueTypesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetVenueTypes();
    dispatch(venueTypesLoaded(response.data));
  };

export const publishWorkspaceEffect =
  (id?: string, history?: any, status?: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    let response;
    dispatch(setLoader(true));
    localStorage.removeItem("number_of_workspace");
    if (id) {
      response = await WorkspaceApi.UpdateWorkspace(
        state.auth.token!,
        id,
        new CreateWorkspaceRequest(
          state.workspaceWizard.detailsFields!,
          state.workspaceWizard.imagesFields!,
          status
        )
      );
    } else {
      response = await WorkspaceApi.CreateWorkspace(
        state.auth.token!,
        new CreateWorkspaceRequest(
          state.workspaceWizard.detailsFields!,
          state.workspaceWizard.imagesFields!,
          status ? status : ""
        )
      );
    }
    if (response.data) {
      dispatch(setSpaceID(response.data.id));
      window.localStorage.setItem("venue_id", response.data.venue_id);
      dispatch(setLoader(false));
      if (
        response.data.status !== "New" &&
        response.data.status === "Closed"
      ) {
        toast.success("Saved as Draft");
        history.push("/venues");
      } else {
        dispatch(workspaceWizardSetStep(WorkspaceWizardStep.Success));
        dispatch(venueWizardSetStep(VenueWizardStep.Success));
        dispatch(publishWorkspace());
      }
    } else {
      let data: any = response;
      toast.error(data.message!);
    }
  };

export const getVenuesEffect =
  (venueToken?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.GetVenues(
      venueToken ? venueToken : getState().auth.token!
    );
    dispatch(venuesLoaded(response.data));
  };

export const getSuggestedPriceEffect =
  (venue_id: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await WorkspaceApi.GetWorkspaceSuggestedPrice(
      venue_id
    );
    if (response.data) {
      dispatch(workspaceSuggestedPrice(response.data.price));
    }
  };

export const postVenueOpenHoursEffect =
  (availability: CreateVenueOpenHoursRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.venueOpenHours(
      getState().auth.token!,
      new CreateVenueOpenHoursRequest(availability)
    );
    dispatch(setLoader(true));
    if (response) {
      toast.success("Hours successfully submitted.");
    }
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 12000);
  };

export const postCreateVenueOpenHoursEffect =
  (availability: CreateVenueOpenHoursRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.venueOpenHours(
      getState().auth.token!,
      new CreateVenueOpenHoursRequest(availability)
    );
    dispatch(setLoader(true));
    if (response) {
      toast.success("Hours successfully submitted.");
    }
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 12000);
  };

export const PostSpaceAvailabilityEffect =
  (availability: CreateSpaceBlockRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await WorkspaceApi.PostSpaceBlock(
      getState().auth.token!,
      new CreateSpaceBlockRequest(availability)
    );
    dispatch(setLoader(true));
    if (response.data) {
      toast.success("Space's availability successfully set.");
      const response = await WorkspaceApi.GetSpaceBlock(
        getState().auth.token!
      );
      if (response) {
        dispatch(getWorkspacesBlocks(response.data));
      }
    } else toast.error("Oops something went wrong...");
  };

export const GetSpaceAvailabilityEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await WorkspaceApi.GetSpaceBlock(
      getState().auth.token!
    );
    if (response) {
      dispatch(getWorkspacesBlocks(response.data));
    }
  };

export const GetSpaceAvailabilityByIdEffect =
  (id: number) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await WorkspaceApi.GetSpaceAvailabilityById(
      getState().auth.token!,
      id
    );
    if (response) {
      dispatch(storeSpaceAvailability(response.data));
    }
  };

export const DeleteSpaceAvailabilityEffect =
  (id: number) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    await WorkspaceApi.deleteSpaceAvailability(
      getState().auth.token!,
      id
    );
    const response = await WorkspaceApi.GetSpaceBlock(
      getState().auth.token!
    );
    toast.success("Space availability successfully deleted.");
    if (response) {
      dispatch(getWorkspacesBlocks(response.data));
    }
  };

export const EditSpaceAvailabilityEffect =
  (availability: CreateSpaceBlockRequest, id: number) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await WorkspaceApi.EditSpaceAvailability(
      getState().auth.token!,
      new CreateSpaceBlockRequest(availability),
      id
    );
    dispatch(setLoader(true));
    if (response) {
      toast.success("Space's availability successfully edited.");
      const response = await WorkspaceApi.GetSpaceBlock(
        getState().auth.token!
      );
      if (response) {
        dispatch(getWorkspacesBlocks(response.data));
      }
    }
  };

export const loadWorkspaceForEditEffect =
  (id: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await WorkspaceApi.GetWorkspace(
      getState().auth.token!,
      id
    );
    const workspace = response.data;
    const promises = workspace.images.map(
      async (image, i) =>
        await createFile(
          image,
          workspace.uploaded_images[i].file_name,
          workspace.uploaded_images[i].mime_type
        )
    );
    const files = await Promise.all(promises);
    let venueDetailsFields: WorkspaceDetailsFields = {
      capacity: workspace.capacity,
      quantity: workspace.quantity,
      environments: workspace.environments.map(e => e.id.toString()),
      types: workspace.types.map(e => e.id.toString()),
      name: workspace.name,
      price: parseFloat(workspace.price),
      venueId: workspace.venue_id.toString(),
      free_space: workspace.free_space,
      image1: files[0],
      image2: files[1],
      image3: files[2],
      image4: files[3],
      max_booking_hours: workspace.max_booking_hours,
      min_booking_hours: workspace.min_booking_hours,
      description: workspace.description,
      cancellation_period: workspace.cancellation_period
    };
    if (workspace.assets.length > 0)
      venueDetailsFields["assets"] = workspace.assets.map(e =>
        e.id.toString()
      );
    dispatch(workspaceSubmitDetailsForm(venueDetailsFields));
    dispatch(setLoader(false));
  };

export function publishWorkspace() {
  return <const>{
    type: "@@workspace/PUBLISH_WORKSPACE"
  };
}
export const postSpaceStatusEffect =
  (id: number, request: CreateVenueCloseStatusRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.CloseSpaceStatus(
      state.auth.token!,
      id,
      new CreateVenueCloseStatusRequest(request)
    );
    if (response.data) {
      if (response.data.status === "Closed") {
        toast.success("Status Changed to Draft");
      } else {
        toast.success("Status Changed to Published");
      }
    } else {
      toast.error("Oops something went wrong");
    }
  };

export const GetSpaceAvailability =
  (date: any, spaceId: any) => async (dispatch: Dispatch) => {
    const formatteddate = await formatedDateNew(date);
    const response = await WorkspaceApi.GetSpaceAvailabilityRequest(
      spaceId,
      new CreateWorkspaceAvailabilityRequest(formatteddate)
    );
    if (response.data) {
      dispatch(workspaceAvailabilityLoad(response.data.availability));
      dispatch(setLoader(false));
    } else {
      toast.error("Oops something went wrong");
      dispatch(workspaceAvailabilityLoad([]));

      dispatch(setLoader(false));
    }
  };

export const GetTotalSpaces = () => async (dispatch: Dispatch) => {
  const response = await WorkspaceApi.GetTotalSpaces();
  if (response.data) {
    dispatch(totalWorkSpaceLoad(response.data.count));
    dispatch(setLoader(false));
  } else {
    // toast.error("Oops something went wrong");
    dispatch(totalWorkSpaceLoad(null));
    dispatch(setLoader(false));
  }
};

export type WorkspaceWizardAction = ReturnType<
  | typeof workspaceWizardSetStep
  | typeof publishWorkspace
  | typeof workspaceSubmitDetailsForm
  | typeof workspaceSubmitImagesForm
  | typeof venuesLoaded
  | typeof venueLoaded
  | typeof environmentsLoaded
  | typeof assetsLoaded
  | typeof servicesLoaded
  | typeof venueTypesLoaded
  | typeof publicVenuesLoaded
  | typeof editVenueLoaded
  | typeof workspaceWizardReset
  | typeof getWorkspacesBlocks
  | typeof storeSpaceAvailability
  | typeof workspaceSuggestedPrice
  | typeof setSpaceID
  | typeof workspaceFilterLoad
  | typeof workspaceFilterTypeLoad
  | typeof workspaceFilterEnvLoad
  | typeof workspaceFilterSortLoad
  | typeof setSpaceFlow
  | typeof isAutoFocus
  | typeof workspaceAvailabilityLoad
  | typeof totalWorkSpaceLoad
>;
