import React, { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  PersonOutline,
  AssignmentTurnedIn,
  SupervisorAccount
} from "@mui/icons-material";

import { User } from "../API/AuthApi";
import {
  handleInfoModal,
  handleRequirementsModal
} from "../Screens/Dashboard/Actions";
import { AppState } from "../store";
import SafetyRequirementsModal from "../Screens/Dashboard/modals/SafteyRequirementsModal";
import { UserInfoModal } from "../Screens/Dashboard/modals/UserInfoModal";

interface Props {
  user?: User;
}

const EmployerMenuLinks: FC<Props> = ({ user }) => {
  const userRole = useSelector((state: any) => state.auth.userRoles);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleListItem = (text: any) => {
    if (text == "Venue Restrictions") {
      dispatch(handleRequirementsModal(true));
    } else {
      dispatch(handleInfoModal(true));
    }
  };
  const handleClose = (text: any) => {
    dispatch(handleInfoModal(false));
  };
  const isModalOpen = useSelector(
    (state: AppState) => state.dashboard.requirements_modal
  );
  const isInfoModalOpen = useSelector(
    (state: AppState) => state.dashboard.info_modal
  );

  return (
    <>
      <ul className="clearfix">
        {user ? (
          <>
            {userRole === "Employer" ? (
              <>
                {[
                  {
                    text: "Employer Dashboard",
                    link: "dashboard",
                    icon: (
                      <div
                        className="dashboard-icon"
                        style={{ width: "20px" }}
                      ></div>
                    )
                  },
                  {
                    text: "Roles & Access",
                    link: "roles_access",
                    icon: (
                      <div
                        className="roles-icon"
                        style={{ width: "15px", marginLeft: "2px" }}
                      ></div>
                    )
                  },
                  {
                    text: "Employees",
                    link: "my_employees",
                    icon: (
                      <PersonOutline
                        style={{
                          color: "rgb(19 160 222)",
                          marginLeft: "-1px"
                        }}
                      ></PersonOutline>
                    )
                  },
                  {
                    text: "Employer Admins",
                    link: "employer-admins",
                    icon: (
                      <SupervisorAccount
                        style={{
                          color: "rgb(19 160 222)",
                          marginLeft: "-1px"
                        }}
                      ></SupervisorAccount>
                    )
                  },
                  {
                    text: "Team Bookings",
                    link: "myteam-bookings",
                    icon: (
                      <div
                        className="bookings-icon"
                        style={{ width: "15px", marginLeft: "2px" }}
                      ></div>
                    )
                  },
                  {
                    text: "Make Admin Bookings",
                    link: "employer-booking",
                    icon: (
                      <AssignmentTurnedIn
                        style={{
                          color: "rgb(19 160 222)",
                          marginLeft: "-1px"
                        }}
                      ></AssignmentTurnedIn>
                    )
                  },
                  {
                    text: "Credits History",
                    link: "transactions",
                    icon: (
                      <div
                        className="transaction-icon"
                        style={{ width: "20px", marginLeft: "-1px" }}
                      ></div>
                    )
                  },
                  {
                    text: "Venue Restrictions",
                    link: "safety_requirements",
                    icon: (
                      <div
                        className="safteyRequirements-icon"
                        style={{ width: "15px", marginLeft: "2px" }}
                      ></div>
                    )
                  },
                  {
                    text: "Update Account",
                    link: "account_modal",
                    icon: (
                      <div
                        className="dashboard__edit"
                        style={{ width: "20px", marginLeft: "-1px" }}
                      ></div>
                    )
                  }
                ].map((item, index) => {
                  if (
                    item.text == "Venue Restrictions" ||
                    item.text == "Update Account"
                  ) {
                    return (
                      <ListItem
                        key={index}
                        selected={
                          window.location.pathname.includes(
                            `${item.link}`
                          )
                            ? true
                            : false
                        }
                        sx={{
                          borderBottom: "1px solid #f0f0f0",
                          cursor: "pointer"
                        }}
                        onClick={() => handleListItem(item.text)}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    );
                  } else {
                    return (
                      <ListItem
                        key={index}
                        selected={
                          window.location.pathname.includes(
                            `${item.link}`
                          )
                            ? true
                            : false
                        }
                        component={Link}
                        to={`/${item.link}`}
                        sx={{
                          borderBottom: "1px solid #f0f0f0"
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    );
                  }
                })}
              </>
            ) : null}
          </>
        ) : null}
        {isModalOpen ? <SafetyRequirementsModal /> : null}
        {isInfoModalOpen ? (
          <UserInfoModal
            handleToggleModal={handleClose}
            history={history}
          />
        ) : null}
      </ul>
    </>
  );
};
export default EmployerMenuLinks;
