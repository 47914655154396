import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
const AboutUs: FC = () => {
  const history = useHistory();
  const user = useSelector((state: AppState) => state.auth.user);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee gives companies the tools to manage a hybrid workforce and freelancers and entrepreneurs the freedom to work from wherever they want."
        />
        <title>About Us | Dorpee</title>
      </Helmet>
      <div className="container-fluid pd-0">
        <div className="dashboard">
          <div className="dashboard__top"></div>
        </div>

        <div className="boutus_row">
          <div className="column">
            <div className="col-md-12 p-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 order-2 order-md-1">
                    <h2 className="about-heading mt-10">About Us</h2>
                    <p className="about-bnr_txt">
                      The root word of Dorpee in many languages means
                      “The Village”.
                    </p>
                  </div>
                  <div className="col-md-8 order-1 order-md-2">
                    <img
                      src="/img/about_us.png"
                      alt=""
                      title={"Image"}
                      width={"95%"}
                    ></img>
                  </div>
                </div>
              </div>
              <div className="how_it_work_bg py-5">
                <div className="container">
                  <div className="row">
                    <div className="offset-md-2 col-md-8">
                      <h3 className="heading_text my-3">
                        We want to live our lives to their fullest
                      </h3>
                      <p className="p-txt">
                        At Dorpee, we believe our work plays such a
                        significant part in our lives. We want to live
                        our lives to their fullest and be amongst the
                        world outside of the office block. We want to
                        wake up in the morning and look forward to
                        where we will be working that day. We don’t
                        want to waste precious time and money on
                        crowded trains or sitting unnecessarily in
                        traffic just to arrive at the same vanilla
                        office. Instead, we want the flexibility to
                        work wherever we want to produce the best
                        results we can and love the work we do.{" "}
                      </p>
                    </div>
                    <div className="offset-md-2 col-md-8">
                      <h3 className="heading_text my-3">
                        How Dorpee helps
                      </h3>
                      <p className="p-txt p_btm">
                        Providing a solution for flexible working
                        arrangements that can embrace this diversity
                        is difficult. That’s why we created Dorpee!
                        Described as the Airbnb of workspaces - Dorpee
                        uses an integrated flexible working platform
                        to disrupt the monotony, empower your people,
                        and enhance human potential. We leverage the
                        shared economy, using specifically designed,
                        best of breed technologies and artificial
                        intelligence, to create an on demand sharing
                        platform that connects workers looking for
                        flexible work locations with venues who are
                        looking to fill their under-utilized space.
                        All this, while providing confidence and
                        compliance to employer organizations. In the
                        Dorpee village, we are creating a model where
                        everybody wins.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src="/img/join-us.png"
                      alt=""
                      title={"Image"}
                      width={"95%"}
                    ></img>
                  </div>
                  <div className="col-md-6">
                    <h3 className="heading_text my-3">
                      Join us in growing our village!
                    </h3>
                    <p className="p-txt">
                      That’s why we want everyone to join us in
                      growing our “village” – to take us out of the
                      geographically-fixed concrete towers into a
                      world of infinite choice, where we swarm
                      together to collaborate, and escape to our
                      Fortresses of Solitude when we need
                      contemplation.
                    </p>
                    <div className="row">
                      <div className="col text-center">
                        <button
                          style={{
                            cursor: "pointer",
                            width: 256,
                            height: 50
                          }}
                          className="btn btn-blue"
                          onClick={() => {
                            user
                              ? history.push("/workspaces")
                              : history.push("/register/select-type");
                          }}
                        >
                          Get Started!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="offset-md-2 col-md-4">
                    <p className="p-txt">
                      Caring for our village is also very important to
                      us all. We want our cafes, restaurants and other
                      workspaces to be cared for as well. They work
                      hard to accommodate us and, as a village, we
                      need to look after them too. We are creating a
                      community that looks after each other and helps
                      each other thrive.
                    </p>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="offset-md-2 col-md-8">
                    <h3 className="heading_text my-3 mb-5">
                      It takes a village - Dorpee!
                    </h3>
                    {/*<img*/}
                    {/*  src="/img/image.png"*/}
                    {/*  alt=""*/}
                    {/*  title={"Image"}*/}
                    {/*  width={"100%"}*/}
                    {/*></img>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
