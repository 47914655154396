import { Reducer } from "react";
import { VenueWizardState } from "../../store";
import { VenueWizardAction } from "./Actions";

export enum VenueWizardStep {
  Details,
  OpenHours,
  Amenities,
  // Types,
  AddSpace,
  CreateSpace,
  BonusOffer,
  Preview,
  Success,
}

const initialState: VenueWizardState = {
  step: VenueWizardStep.Details,
  isVenuePublished: false,
  venues: [],
  publicVenues: [],
  venueTypes: [],
  environments: [],
  services: [],
  assets: [],
  interests: [],
  availability: [],
  VenuesNearMe: { nearBy: [], featured: [] },
  venuesOpenHours: [],
  isEditVenue: false,
  venuesAmenities: [],
  venueType: 1,
  venueBonus: "",
  detailsFieldsVenue: {},
  venueId: 0,
  types: [],
  isOpenHourSkip: false,
  workspacesForYou: [],
};

export const venueWizardReducer: Reducer<VenueWizardState, VenueWizardAction> =
  (state: VenueWizardState = initialState, action) => {
    switch (action.type) {
      case "@@VENUE/RESET":
        return initialState;
      case "@@VENUE/SET_STEP":
        return { ...state, step: action.step };
      case "@@VENUE/PUBLISH_VENUE":
        return { ...state, isVenuePublished: action.field };
      case "@@VENUE/SUBMIT_DETAILS_FORM":
        return { ...state, detailsFields: action.fields };
      case "@@VENUE/UPDATE_SUBMIT_DETAILS_FORM":
        return { ...state, detailsFieldsVenue: action.fields };
      case "@@VENUE/SUBMIT_IMAGES_FORM":
        return { ...state, imagesFields: action.fields };
      case "@@VENUE/SUBMIT_OPEN_HOURS_FORM":
        return { ...state, availability: action.fields };
      case "@@VENUE/VENUES_LOADED":
        return { ...state, venues: action.venues };
      case "@@VENUE/PUBLIC_VENUES_LOADED":
        return { ...state, publicVenues: action.venues };
      case "@@VENUE/VENUE_LOADING":
        return { ...state, isEditVenue: action.loading };
      case "@@VENUE/VENUE_LOADED":
        return { ...state, venue: action.venue };
      case "@@wizard/VENUE_TYPES_LOADED":
        return { ...state, venueTypes: action.types };
      case "@@wizard/TYPES_LOADED":
        return { ...state, types: action.types };
      case "@@wizard/ENVIRONMENTS_LOADED":
        return { ...state, environments: action.environments };
      case "@@wizard/ASSETS_LOADED":
        return { ...state, assets: action.assets };
      case "@@wizard/SERVICES_LOADED":
        return { ...state, services: action.services };
      case "@@wizard/INTEREST_LOADED":
        return { ...state, interests: action.interests };
      case "@@VENUE/NEAR_ME":
        return { ...state, VenuesNearMe: action.fields };
      case "@@VENUE/OPEN_HOURS":
        return { ...state, venuesOpenHours: action.fields.data };
      case "@@VENUE/AMENITIES":
        return { ...state, venuesAmenities: action.fields };
      case "@@VENUE/TYPE_FORM":
        return { ...state, venueType: action.fields };
      case "@@VENUE/BONUS_FORM":
        return { ...state, venueBonus: action.fields };
      case "@@VENUE/SET_VENUE_ID":
        return { ...state, venueId: action.id };
      case "@@VENUE/SET_VENUE_OPEN_HOUR_SKIP":
        return { ...state, isOpenHourSkip: action.id };
      case "@@VENUE/WORKSPACES_FOR_YOU":
        return { ...state, workspacesForYou: action.venues };
      default:
        return state;
    }
  };
