/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnvironmentsEffect,
  getVenuesEffect,
  venueWizardSetStep,
  getServicesEffect,
  venuesAmenitiesForm
} from "./Actions";
import WorkspaceAmenities from "../../Screens/WorkspaceWizard/components/WorkspaceAmenities";
import { VenueWizardStep } from "./Reducers";
import VenueSteps from "./VenueSteps";
import Footer from "../../footer";
import { Helmet } from "react-helmet";
import VenueDraftButton from "./venueDraftComponent";
export const VenueAmenities = reduxForm({
  form: "venueAmen",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch) => {
    dispatch(venuesAmenitiesForm(values.amenities));
    dispatch(venueWizardSetStep(VenueWizardStep.BonusOffer));
  },
  validate: values => {
    const errors = {};
    if (!values.amenities || values.amenities.length === 0) {
      errors.amenities = "Select at least one";
    }
    return errors;
  }
})(props => {
  const dispatch = useDispatch();
  const services = useSelector(state => state.venueWizard.services);
  const [eminitiesList, setList] = useState([]);
  const venueDetails = useSelector(
    state => state.venueWizard.venuesAmenities
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getServicesEffect());
  }, []);
  useEffect(() => {
    dispatch(getEnvironmentsEffect());
    dispatch(getVenuesEffect());
  }, []);
  useEffect(() => {
    let grouped = _.mapValues(
      _.groupBy(services, "category"),
      asset => asset.map(ast => _.omit(ast, "category"))
    );
    setList(grouped);
  }, [services]);

  useEffect(() => {
    props.initialize({
      amenities: venueDetails
    });
  }, [venueDetails]);
  const handleSkip = () => {
    dispatch(venueWizardSetStep(VenueWizardStep.OpenHours));
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - List Your Venue - 3</title>
      </Helmet>
      <form onSubmit={props.handleSubmit}>
        <div className="body">
          <div className="container containerAddSpace">
            <VenueSteps
              step={3}
              end={venueDetails.length > 0 ? true : false}
            ></VenueSteps>
            <br />
            <div className="page-wrapper">
              <div className="bl-form-register">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                    // border: "1px solid #000",
                  }}
                >
                  <div
                    className="col-md-8"
                    // style={{
                    // border: "1px solid #000",
                    // paddingLeft: "0",
                    // maxWidth: "50%"
                    // }}
                  >
                    <h1 className="register-title">
                      List Your Venue
                    </h1>
                    <p className="register-sub-title">
                      3. Venue Amenities
                    </p>
                    <p className="register-sub-text">
                      Select all that apply.
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="md-sidebar">
                      <div className="name">
                        <p style={{ fontSize: 12, fontWeight: 400 }}>
                          <b>HINT:</b> You can always change the
                          amenities for your venue at anytime using
                          your Venue Dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="form-register"
                  style={{ marginTop: "15px" }}
                >
                  <WorkspaceAmenities
                    isService={true}
                    assets={eminitiesList}
                  ></WorkspaceAmenities>
                </div>
              </div>
              <div className="bl-buttons">
                <div className="row">
                  <div className="col-4">
                    <a
                      onClick={() =>
                        dispatch(
                          venueWizardSetStep(
                            VenueWizardStep.OpenHours
                          )
                        )
                      }
                      className="btn btn-back"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                  <div className="col-8">
                    <button
                      className={"btn btn-next"}
                      style={{ paddingLeft: 0 }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <VenueDraftButton />
            </div>
          </div>
          <Footer isSubs />
        </div>
      </form>
    </>
  );
});
export default VenueAmenities;
