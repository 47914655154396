import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Calendar from "react-calendar";

import { Modal } from "../../../UI/Modal";
import { GetSpaceAvailability } from "../../WorkspaceWizard/Actions";
import { dayMonth, formatDate } from "../../../helpers/DateHelpers";
import LoaderModal from "../../../Screens/Dashboard/modals/Loader";
import { setLoader } from "../../Loader/Actions";
import { setBooking } from "../../Book/Booking/Actions";

const AvailabilityCalendarModal = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = useState(new Date());
  const [morning, setMorning] = useState(false);
  const [afterNoon, setAfterNoon] = useState(false);
  const [evening, setEvening] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(GetSpaceAvailability(new Date(), props.workspace.id));
  }, []);
  const availability = useSelector(
    state => state.workspaceWizard.availability.availability
  );
  const isLoading = useSelector(state => state.Loader.isLoading);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    availability &&
      Object.keys(availability).map((i, key) => {
        if (availability[i].time.substring(0, 2) <= 12) {
          setMorning(true);
        } else if (availability[i].time.substring(0, 2) <= 18) {
          setAfterNoon(true);
        } else if (availability[i].time.substring(0, 2) <= 24) {
          setEvening(true);
        } else {
          setMorning(false);
          setEvening(false);
          setAfterNoon(false);
        }
      });
  }, [availability]);
  const onChange = async e => {
    dispatch(setLoader(true));
    setAfterNoon(false);
    setEvening(false);
    setMorning(false);
    setValue(e);
    dispatch(GetSpaceAvailability(e, props.workspace.id));
  };
  const handleClick = (startTime, endTime, date, availableSpaces) => {
    if (user && user.id) {
      // if (
      //   (props.vaccinationStatus &&
      //     props.vaccinationStatus == "Fully Vaccinated" &&
      //     (user?.vaccination_status !== "Fully Vaccinated" ||
      //       user?.vaccination_status == null ||
      //       user?.vaccination_status == undefined)) ||
      //   (props.vaccinationStatus == "Partially Vaccinated" &&
      //     ((user?.vaccination_status !== "Fully Vaccinated" &&
      //       user?.vaccination_status !== "Partially Vaccinated") ||
      //       user?.vaccination_status == null ||
      //       user?.vaccination_status == undefined))
      // ) {
      // props.setMessage(
      //   "You do not currently meet this venue's vaccination requirements. Please ensure your COVID-19 vaccination status is up to date in your Dorpee profile."
      // );
      // props.setShowVaccinationDetails(true);
      // }
      //  else {
      dispatch(
        setBooking({
          start_time: startTime,
          end_time: endTime,
          end_date: formatDate(date),
          start_date: formatDate(date),
          availableSpaces: Number(availableSpaces)
        })
      );
      history.push(
        `/book/1/${props.workspace.venue_id}/${props.workspace.id}`
      );
      // }
    } else {
      history.push(`/login`);
    }
  };

  return (
    <>
      <Modal>
        <div
          className="addressbook-form-box block-day-modal space-modal"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <div className="congrat-box__header">
            <span>Workspace Availability</span>
            <div
              className="close-btn"
              onClick={props.CloseModal}
            ></div>
          </div>
          <div style={{ display: "flex", maxWidth: "100%" }}>
            <div style={{ flex: "0 0 60%", padding: "5px" }}>
              <Calendar
                className="availability_calendar"
                onChange={onChange}
                value={value}
                minDate={new Date()}
                tileContent={({ date, view }) => {
                  return (
                    <div
                      className="calendar_date"
                      style={{ minWidth: "30px" }}
                    >
                      <p>{date.getDate()}</p>
                    </div>
                  );
                }}
              />
            </div>

            <div
              className="availability_time"
              style={{
                flex: "0 0 40%",
                maxHeight: "65vh",
                overflow: "auto",
                padding: "0.5rem"
              }}
            >
              <h4 className="availability_date">{dayMonth(value)}</h4>
              <div className="availability_info">
                {availability ? (
                  Object.keys(availability).map((i, key) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.2rem",
                          cursor: "pointer"
                        }}
                        key={key}
                        onClick={() => {
                          handleClick(
                            `${availability[i].time}:00`,
                            `${availability[i].close}:00`,
                            value,
                            availability[i].quantity
                          );
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: "12px"
                          }}
                        >
                          {`${availability[i].time}  -  ${availability[i].close}`}
                        </span>
                        <p>
                          Available Spaces{" "}
                          <span style={{ fontWeight: 600 }}>
                            {availability[i].quantity}
                          </span>
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p style={{ fontWeight: 500 }}>
                    No Space Available
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};

export default AvailabilityCalendarModal;
