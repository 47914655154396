import React from "react";
import { Pie } from "react-chartjs-2";

class BookingGroupSizePie_Emp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: ["1", "2", "3-4", "5-10", "10-20", "20+"],
      datasets: [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          options: {
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.pie.no_of_people.eqone !==
        this.props.pie.no_of_people.eqone ||
      nextProps.pie.no_of_people.gttwenty !==
        this.props.pie.no_of_people.gttwenty ||
      nextProps.pie.no_of_people.eqtentotwenty !==
        this.props.pie.no_of_people.eqtentotwenty ||
      nextProps.pie.no_of_people.eqfivetoten !==
        this.props.pie.no_of_people.eqfivetoten ||
      nextProps.pie.no_of_people.eqthreefour !==
        this.props.pie.no_of_people.eqthreefour ||
      nextProps.pie.no_of_people.eqtwo !==
        this.props.pie.no_of_people.eqtwo ||
      nextProps.pie.no_of_people.eqone !==
        this.props.pie.no_of_people.eqone
    ) {
      const datasets = [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          options: {
            legend: {
              position: "bottom"
            }
          }
        }
      ];

      this.setState({
        datasets
      });
    }
  }

  getLabels() {
    let labels = [];
    let data = this.props.pie.no_of_people;

    for (let i in data) {
      i = this.replaceLabel(i);
      labels.push(i);
    }
    return labels;
  }

  getData() {
    let values = [];
    let data = this.props.pie.no_of_people;

    for (let i in data) {
      values.push(data[i]);
    }

    return values;
  }

  replaceLabel(label) {
    label = label.replace("eq", "");
    label = label.replace("gt", "");
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }

  render() {
    let options = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              data["labels"][tooltipItem["index"]] +
              ": " +
              data["datasets"][0]["data"][tooltipItem["index"]] +
              "%"
            );
          }
        }
      }
    };
    return (
      <div className="venue-chart-small">
        <Pie
          height={250}
          data={this.state}
          options={options}
          legend={{
            labels: {
              boxWidth: 15
            },
            position: "bottom"
          }}
        />
      </div>
    );
  }
}

export default BookingGroupSizePie_Emp;
