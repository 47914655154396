import { ApiClient } from "./ApiClient";
import { BookingFields, MyContact } from "./BookingAPI";
import { SearchPreference, PaymentOptionResponse } from "../store";
import { User } from "./AuthApi";

export class DashboardApi {
  static GetDashboardData(token: string) {
    return ApiClient.get<Response<DashboardData>>(
      "venue/dashboard",
      token
    );
  }
  static GetDashboardPurchasedCredits(token: string) {
    return ApiClient.get<Response<DashboardPurchasedCredits>>(
      "user/purchase-credits",
      token
    );
  }

  static UpdatePrivacySettings(
    token: string,
    request: createUpdatePrivacySettingsRequest
  ) {
    return ApiClient.post<
      createUpdatePrivacySettingsRequest,
      PrivacySettingsResponse
    >("profile", request, token);
  }

  static UpdateInterests(
    token: string,
    request: createUpdateInterestsRequest
  ) {
    return ApiClient.post<
      createUpdateInterestsRequest,
      InterestsResponse
    >("profile", request, token);
  }

  static CreateTeam(token: string, request: createTeamRequest) {
    return ApiClient.post<createTeamRequest, TeamResponse>(
      "user/teams",
      request,
      token
    );
  }

  static UpdateTeam(
    token: string,
    id: string,
    request: createTeamRequest
  ) {
    return ApiClient.put<createTeamRequest, TeamResponse>(
      "user/teams/" + id,
      request,
      token
    );
  }

  static GetContacts(token: string) {
    return ApiClient.get<Contactdata>("user/contacts", token);
  }

  static GetTeams(token: string) {
    return ApiClient.get<Contactdata>("user/teams", token);
  }
  static GetBar(token: string) {
    return ApiClient.get("venue/bar-chart", token);
  }
  static GetPie(token: string) {
    return ApiClient.get("venue/pie-chart", token);
  }

  static GetBar2(token: string) {
    return ApiClient.get("employer/bar-chart", token);
  }
  static GetPie2(
    token: string,
    start_date: string,
    end_date: string
  ) {
    let queryString: string = "";
    if (start_date && end_date) {
      queryString = `start_date=${start_date}&end_date=${end_date}`;
    }
    return ApiClient.get(`employer/pie-chart?${queryString}`, token);
  }
  static GetCostOverview(token: string) {
    return ApiClient.get(`employer/cost-chart`, token);
  }

  static GetBookingPieChart(
    token: string,
    start_date: string,
    end_date: string
  ) {
    let queryString: string = "";
    if (start_date && end_date) {
      queryString = `start_date=${start_date}&end_date=${end_date}`;
    }
    return ApiClient.get(
      `employer/bookings-pie-chart?${queryString}`,
      token
    );
  }

  static CreateContact(token: string, request: createContactRequest) {
    return ApiClient.post<createContactRequest, ContactResponse>(
      "user/contacts",
      request,
      token
    );
  }

  static DeleteTeam(token: string, id: string) {
    return ApiClient.delete("user/teams/" + id, token);
  }

  static CreateEmployer(
    token: string,
    request: createEmployerRequest
  ) {
    return ApiClient.post<createEmployerRequest, EmployerResponse>(
      "user/contacts",
      request,
      token
    );
  }

  static DeleteContact(token: string, id: string) {
    return ApiClient.delete("user/contacts/" + id, token);
  }

  static InviteEmployer(
    token: string,
    request: createEmployerRequest
  ) {
    return ApiClient.post<createEmployerRequest, EmployerResponse>(
      "employer/invite",
      request,
      token
    );
  }
  static EditInvitedEmployer(
    token: string,
    request: createEmployerRequest,
    id: string
  ) {
    return ApiClient.post<createEmployerRequest, EmployerResponse>(
      `employer/my-employee/${id}`,
      request,
      token
    );
  }

  static GetInvitesList(token: string) {
    return ApiClient.get<{ data: any }>("my-invites", token);
  }

  static AcceptRejectInvitation(
    token: string,
    inviteNumber: string,
    request: createAcceptRejectInvitationRequest
  ) {
    return ApiClient.post<
      createAcceptRejectInvitationRequest,
      AcceptRejectInvitationResponse
    >("employer/invite/" + inviteNumber, request, token);
  }

  static GetCustomers(token: string) {
    return ApiClient.get("employer/invited-customers", token);
  }

  static SetCredits(token: string, request: setCreditsRequest) {
    return ApiClient.post<setCreditsRequest, SetCreditsResponse>(
      "employer/set/credits",
      request,
      token
    );
  }

  static RemoveAcceptedCustomer(
    token: string,
    request: removeCustomerRequest
  ) {
    return ApiClient.post<removeCustomerRequest, SetCreditsResponse>(
      "employer/remove-customer",
      request,
      token
    );
  }

  static GetMyCustomers(token: string) {
    return ApiClient.get("employer/my-customers", token);
  }
  static GetMyAdmins(token: string) {
    return ApiClient.get("employer/admins", token);
  }
  static AddEmployerAdmin(request: any, token: string) {
    return ApiClient.post("employer/admins/add", request, token);
  }
  static RemoveEmployerAdmin(request: any, token: string) {
    return ApiClient.post("employer/admins/remove", request, token);
  }

  static GetAvailableVenuesForEmployer(request: any, token: string) {
    return ApiClient.post("search/basic", request, token);
  }

  static GetEmployerDashboardStats(token: string) {
    return ApiClient.get("employer/dashboard-stats", token);
  }
  //admin
  static GetEmployerDashboardADmin(token: string) {
    return ApiClient.post<any, any>("get-impersonate-token", {
      token: token
    });
  }

  static GetMyEmployeesActiveBookings(token: string) {
    return ApiClient.get(
      "employer/my-employees-active-bookings",
      token
    );
  }

  static GetSearchPreferences(token: string) {
    return ApiClient.get<Response<{ data: SearchPreference[] }>>(
      "user/search/preferences",
      token
    );
  }
  static DeleteSearchPreferences(token: string, id: any) {
    return ApiClient.delete(`user/search/preferences/${id}`, token);
  }
  static GetCustomerAllCards(token: string) {
    return ApiClient.get<Response<{ data: PaymentOptionResponse[] }>>(
      "user/cards",
      token
    );
  }
  static GetCreditPrice() {
    return ApiClient.get<Response<{ data: any }>>("credit-price");
  }
  static resendInvitation(token: string, id: string) {
    return ApiClient.post(
      "employer/resend-invite/" + id,
      null,
      token
    );
  }
  static getEmployerTransactionStats(token: string, data?: any) {
    let url = `employer/transactions`;
    if (
      data &&
      !Object.values(data).every(o => o === null || o === "")
    ) {
      let urlfilters = "";
      if (data.user) {
        urlfilters = urlfilters + `?user=${data.user}`;
      } else {
        urlfilters = urlfilters + `?user=`;
      }
      if (data.transaction_start) {
        urlfilters =
          urlfilters + `&transaction_start=${data.transaction_start}`;
      }
      if (data.transaction_end) {
        urlfilters =
          urlfilters + `&transaction_end=${data.transaction_end}`;
      }
      if (data.booking_start) {
        urlfilters =
          urlfilters + `&booking_start=${data.booking_start}`;
      }
      if (data.booking_end) {
        urlfilters = urlfilters + `&booking_end=${data.booking_end}`;
      }
      url = `employer/transactions${urlfilters}`;
    }
    return ApiClient.get<any>(url, token);
  }
  static postEmployerRoles(
    token: string,
    request: AddEmployeeRoles,
    id?: any
  ) {
    var url = `employer/employee-roles`;
    if (id) {
      url = `employer/employee-roles/${id}`;
    }
    return ApiClient.post<AddEmployeeRoles, any>(url, request, token);
  }
  static getEmployerRoles(token: string) {
    var url = "employer/employee-roles";
    return ApiClient.get<any>(url, token);
  }
  static validateEmployeeSpendings(token: string, request: any) {
    var url = `employer/validate-monthly-spendings`;

    return ApiClient.post<any, any>(url, request, token);
  }
  static validateEmployerSpendings(token: string, request: any) {
    var url = `employer/validate-employee-monthly-spendings`;

    return ApiClient.post<any, any>(url, request, token);
  }
}

type Response<T> = {
  data: T;
};

export type DashboardData = {
  venues: {
    active_venues: number;
    all_bookings: any;
    active_booking: any;
    past_bookings: any;
    active_spaces?: any;
    cancelled_bookings?: any;
  };
};
export type DashboardPurchasedCredits = {
  data: PurchasedCredits[];
};

export type PurchasedCredits = {
  id: number;
  user_id: number;
  purchase_count: number;
  available: number;
  expiry_date: string;
  user: User;
  purchased_date: string;
};
/*-------------- Privacy Settings -----------------------*/

export class createUpdatePrivacySettingsRequest {
  constructor(privacyFields: PrivacySettings) {
    this.privacy_linkedin = privacyFields.privacy_linkedin;
    this.privacy_name = privacyFields.privacy_name;
    this.privacy_interests = privacyFields.privacy_interests;
    this.linkedin = privacyFields.linkedin;
  }
  privacy_linkedin: string;
  privacy_name: string;
  privacy_interests: string;
  linkedin: string;
}

export type PrivacySettings = {
  privacy_linkedin: string;
  privacy_name: string;
  privacy_interests: string;
  linkedin: string;
};

export type PrivacySettingsResponse = {
  privacy_linkedin: string;
  privacy_name: string;
  privacy_interests: string;
};

/*-------------- Interests -----------------------*/

export class createUpdateInterestsRequest {
  constructor(privacyFields: Interests) {
    this.interests = privacyFields.interests;
  }
  interests: string;
}

export type Interests = {
  interests: string;
};

export type InterestsResponse = {
  interests: any;
};

/*-------------- Create Team -----------------------*/

export class createTeamRequest {
  constructor(privacyFields: Team) {
    this.name = privacyFields.name;
  }
  name: string;
}

export type Team = {
  name: string;
};

export type TeamResponse = {
  created_at: string;
  customer_id: number;
  id: number;
  name: string;
  updated_at: string;
};

/*-------------- Create Contact -----------------------*/

export class createContactRequest {
  constructor(privacyFields: Contact) {
    this.first_name = privacyFields.first_name;
    this.last_name = privacyFields.last_name;
    this.email = privacyFields.email;
    this.team_id = privacyFields.team_id;
  }
  first_name: string;
  last_name: string;
  email: string;
  team_id: string;
}

export type Contact = {
  first_name: string;
  last_name: string;
  email: string;
  team_id: string;
};

export type ContactResponse = {
  data: {
    created_at?: string;
    customer_id?: number;
    id?: number;
    name?: string;
    updated_at?: string;
    status?: boolean;
  };
};
export type Contactdata = {
  data: any;
};
export type ContactIds = {
  contactIds: any[];
};

/*-------------- Create Employer -----------------------*/

export class createEmployerRequest {
  constructor(privacyFields: Employee) {
    this.first_name = privacyFields.first_name;
    this.last_name = privacyFields.last_name;
    this.email = privacyFields.email;
    this.employee_role_id = privacyFields.employee_role_id;
  }
  first_name: string;
  last_name: string;
  email: string;
  employee_role_id?: Number;
}

export type Employee = {
  first_name: string;
  last_name: string;
  email: string;
  employee_role_id?: Number;
};

export type EmployerResponse = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
};

/*-------------- Accept/Reject Invitation -----------------------*/

export class createAcceptRejectInvitationRequest {
  constructor(privacyFields: AcceptRejectInvitation) {
    this.status = privacyFields.status;
  }
  status: string;
}

export type AcceptRejectInvitation = {
  status: string;
};

export type AcceptRejectInvitationResponse = {
  id: number;
};

/*-------------- Set Credits Limit -----------------------*/

export class setCreditsRequest {
  constructor(privacyFields: SetCredits) {
    this.customer_id = privacyFields.customer_id;
    this.credit = privacyFields.credit;
  }
  customer_id: number;
  credit: string;
}

export type SetCredits = {
  customer_id: number;
  credit: string;
};

export type SetCreditsResponse = {
  id: number;
};

/*-------------- Remove Customer -----------------------*/

export class removeCustomerRequest {
  constructor(privacyFields: RemoveCustomer) {
    this.customer_id = privacyFields.customer_id;
  }
  customer_id: number;
}

export type RemoveCustomer = {
  customer_id: number;
};

/*-------------- Add Role Employee -----------------------*/

export class AddEmployeeRoles {
  constructor(roles_fields: AddRoles) {
    this.role_name = roles_fields.role_name;
    this.monthly_access = roles_fields.monthly_access;
    this.hourly_access = roles_fields.hourly_access;
  }
  role_name: string;
  monthly_access: number;
  hourly_access: number;
}

export type AddRoles = {
  role_name: string;
  monthly_access: number;
  hourly_access: number;
};
