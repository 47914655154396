/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Helmet from "react-helmet";
import _ from "lodash";

import ShowRatingsModal from "../../Screens/Dashboard/modals/ShowRatingsModal";
import { AppState } from "../../store";
import { Venue } from "../../API/VenueApi";
import VenuesModal from "../../Screens/Dashboard/modals/VenuesModal";
import MapMarkerComponent from "../../Screens/MapMarkerComponent";
import MapButtonComponent from "../../Screens/MapButtonComponent";
import SearchComponent from "./SearchComponent";
import WorkspaceItem from "../../Screens/VenueSearch/WorkspaceItem";
import { getTypesEffect } from "../VenueWizard/Actions";
import { saveAdvSearchResultClick, saveScrollState } from "./Actions";
import {
  workspaceFilterEnvLoad,
  workspaceFilterTypeLoad,
  workspaceFilterSortLoad,
  workspaceFilterLoad,
  isAutoFocus,
  getEnvironmentsEffect
} from "../WorkspaceWizard/Actions";

enum SortBy {
  NameAsc = "NameAsc",
  NameDesc = "NameDesc",
  PriceAsc = "PriceAsc",
  PriceDesc = "PriceDesc"
}
const VenueSearchResults: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const venues = useSelector(
    (state: AppState) => state.venueSearch.venues
  );
  const user = useSelector((state: AppState) => state.auth.user!);
  const search_id = useSelector(
    (state: AppState) => state.venueSearch.searchId
  );
  const scrollPosition: any = useSelector(
    (state: AppState) => state.venueSearch.scrollState
  );

  const Location = useSelector(
    (state: AppState) => state.venueSearch!.basic_search!.location!
  );

  const venueTypes = useSelector(
    (state: AppState) => state.workspaceWizard.venueTypes
  );

  const venueEnvs = useSelector(
    (state: AppState) => state.workspaceWizard.environments
  );

  const filterType = useSelector(
    (state: AppState) => state.workspaceWizard.filterType
  );
  const filterEnv = useSelector(
    (state: AppState) => state.workspaceWizard.filterEnv
  );
  const filterSort = useSelector(
    (state: AppState) => state.workspaceWizard.filterSort
  );

  const autoFocus = useSelector(
    (state: AppState) => state.workspaceWizard.isAutoFocus
  );

  const filterRef = useRef<HTMLDivElement>(null);
  const sortRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLDivElement>(null);
  const outSideClickRef = useRef<any>(null);

  const [selectedVenue, setselectedVenue] = useState<Venue>();
  const [iscloseModal, setcloseModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [data, setData] = useState({});
  const [showMap, setShowMap] = useState(false);
  const [visibleVenues, setVisibleVenues] = useState(venues);
  const [tempVenues, setTempVenues] = useState(venues);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isSortOpened, setIsSortOpened] = useState(false);
  const [selectedFilterBy, setselectedFilterBy] = useState("");

  const [filterBy, setFilterBy] = useState<number[]>(
    filterType ? filterType : []
  );

  const [filterByVenue, setFilterByVenue] = useState<number[]>(
    filterEnv ? filterEnv : []
  );

  const [filterByService, setFilterByService] = useState<number[]>(
    []
  );
  const [sortBy, setSortBy] = useState<SortBy | undefined>(
    filterSort
  );

  const [center, setCenter] = useState({
    lat: -37.813629,
    lng: 144.963058
  });
  const [mapCenterNew, setCenterNew]: any = useState();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    dispatch(getEnvironmentsEffect());
    dispatch(getTypesEffect());
  }, []);
  useEffect(() => {
    if (scrollPosition != 0) {
      window.scrollTo(0, scrollPosition);
    }
  }, []);
  useEffect(() => {
    venues &&
      venues.length === 1 &&
      setCenter({
        lat: Number(venues[0].lat),
        lng: Number(venues[0].lng)
      });
    if (Location && Location.lat && Location && Location.lng) {
      setCenterNew({ lat: Location.lat, lng: Location.lng });
    }
    setTempVenues(venues);
    setVisibleVenues(venues);
  }, [venues]);
  useEffect(() => {
    if (Location && Location.lat && Location && Location.lng) {
      setCenterNew({ lat: Location.lat, lng: Location.lng });
    }
  }, [Location]);

  useEffect(() => {
    function outSideClick(e: MouseEvent) {
      if (
        outSideClickRef &&
        !outSideClickRef.current?.contains(e.target)
      ) {
        dispatch(isAutoFocus(true));
      }
    }

    document.body.addEventListener("click", outSideClick);
    return () => {
      document.body.removeEventListener("click", outSideClick);
    };
  }, [autoFocus]);

  // useEffect(() => {
  //   function reset(this: HTMLElement, e: MouseEvent) {
  //     if (
  //       (filterRef.current && !filterRef.current.contains(e.target as Node)) ||
  //       (sortRef.current && !sortRef.current.contains(e.target as Node))
  //     ) {
  //       setIsFilterOpened(false);
  //       setIsSortOpened(false);
  //     }
  //   }

  //   document.body.addEventListener("click", reset);
  //   return () => {
  //     document.body.removeEventListener("click", reset);
  //   };
  // }, [isFilterOpened, isSortOpened]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    dispatch(saveScrollState(position));
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };
  const closeModal = () => {
    setcloseModal(false);
  };
  const showDialog = (venue: Venue) => {
    setcloseModal(true);
    setselectedVenue(venue);
  };
  const openModal = (data: any) => {
    setData(data);
    setIsModalOpen(true);
  };

  const changeMarkerPosition = (e: any) => {
    const { nw, se, ne, sw } = e;
    let filteredVenues = tempVenues.filter(v => {
      if (
        v.lat > se.lat &&
        sw.lat &&
        v.lat < ne.lat &&
        nw.lat &&
        v.lng > nw.lng &&
        sw.lng &&
        v.lng < ne.lng &&
        se.lng
      ) {
        return true;
      } else return false;
    });
    setVisibleVenues(filteredVenues);
    // localStorage.setItem("venues", JSON.stringify(filteredVenues));
  };

  const handleMap = () => {
    if (showMap) {
      setVisibleVenues(tempVenues);
      // localStorage.setItem("venues", JSON.stringify(tempVenues));
    }
    setShowMap(!showMap);
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSearchSave = (venue_id: any) => {
    if (search_id && search_id != 0) {
      dispatch(
        saveAdvSearchResultClick({
          save_search_id: search_id || 0,
          venue_id: venue_id
        })
      );
    }
  };

  const filter = (
    type?: string,
    sortBy?: string,
    byFilter?: any[]
  ) => {
    let vis = tempVenues
      .filter(v => {
        // if (type === "services") {
        //   return filterByServices(v);
        // } else
        if (type === "env") {
          return filterByEnv(v, byFilter!);
        } else if (type === "type") {
          return filterByType(v, byFilter!);
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        let aValue = Math.ceil(parseInt(a.lowest_price) / 0.3);
        let bValue = Math.ceil(parseInt(b.lowest_price) / 0.3);
        if (sortBy === SortBy.PriceAsc) {
          return aValue - bValue;
        }

        if (sortBy === SortBy.PriceDesc) {
          return bValue - aValue;
        }
        if (sortBy === SortBy.NameAsc) {
          return a.name.localeCompare(b.name);
        }
        if (sortBy === SortBy.NameDesc) {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    setTempVenues(vis);
    setVisibleVenues(vis);
    dispatch(workspaceFilterLoad(vis));
    // localStorage.setItem("venues", JSON.stringify(vis));
    if ((byFilter && byFilter.length === 0) || sortBy === "") {
      setTempVenues(venues);
      setVisibleVenues(venues);
      dispatch(workspaceFilterLoad(venues));
      // localStorage.setItem("venues", JSON.stringify(venues));
    }
  };

  const toggleFilter = (id: number) => {
    setselectedFilterBy("type");
    const newFilterBy =
      filterBy.indexOf(id) >= 0
        ? filterBy.filter(i => i !== id)
        : [...filterBy, id];
    dispatch(workspaceFilterTypeLoad(newFilterBy));
    setFilterBy(newFilterBy);
    filter("type", undefined, newFilterBy);
  };

  const toggleFilterEnv = (id: number) => {
    setselectedFilterBy("env");
    const newFilterBy =
      filterByVenue.indexOf(id) >= 0
        ? filterByVenue.filter(i => i !== id)
        : [...filterByVenue, id];
    setFilterByVenue(newFilterBy);
    dispatch(workspaceFilterEnvLoad(newFilterBy));
    filter("env", undefined, newFilterBy);
  };

  const filterByType = (v: any, filterBy: any[]) => {
    if (filterBy.length < 1) {
      return true;
    } else {
      let matched = false;
      v.spaces.some((space: { types: any[] }) => {
        space.types.some((type: { id: number }) => {
          if (filterBy.includes(type.id)) {
            matched = true;
          }
        });
      });

      return matched;
    }
  };

  // const filterByServices = (v: any) => {
  //   if (filterByService.length < 1) {
  //     return true;
  //   } else {
  //     let matched = false;
  //     v.services.some((service: { id: number }) => {
  //       if (filterByService.includes(service.id)) {
  //         matched = true;
  //       }
  //     });
  //     return matched;
  //   }
  // };

  const filterByEnv = (v: any, filterByVenue: any[]) => {
    if (filterByVenue.length < 1) {
      return true;
    } else {
      let matched = false;
      v.spaces.some((env: { environments: any[] }) => {
        env.environments.some((environment: { id: number }) => {
          if (filterByVenue.includes(environment.id)) {
            matched = true;
          }
        });
      });

      return matched;
    }
  };

  const toggleSortName = () => {
    var sort;
    if (sortBy !== SortBy.NameAsc && sortBy !== SortBy.NameDesc) {
      setSortBy(SortBy.NameAsc);
      sort = SortBy.NameAsc;
    } else if (sortBy === SortBy.NameAsc) {
      sort = SortBy.NameDesc;
      setSortBy(SortBy.NameDesc);
    } else if (sortBy === SortBy.NameDesc) {
      sort = "";
      setSortBy(undefined);
    }
    dispatch(workspaceFilterSortLoad(sort));
    filter("", sort);
  };

  const toggleSortPrice = () => {
    let sort;
    if (sortBy !== SortBy.PriceAsc && sortBy !== SortBy.PriceDesc) {
      sort = SortBy.PriceAsc;
      setSortBy(SortBy.PriceAsc);
    } else if (sortBy === SortBy.PriceAsc) {
      sort = SortBy.PriceDesc;
      setSortBy(SortBy.PriceDesc);
    } else if (sortBy === SortBy.PriceDesc) {
      sort = "";
      setSortBy(undefined);
    }
    dispatch(workspaceFilterSortLoad(sort));
    filter("", sort);
  };

  const searchWorkPlace = (event: any) => {
    dispatch(isAutoFocus(false));
    // setSearchWorkPlace(event.target.value);
    const keyword = event.target.value;
    if (event.target.value != "") {
      let finalVenues = venues.filter(v => {
        if (keyword && keyword != "") {
          if (
            v.suburb &&
            v.suburb
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (
            v.name &&
            v.name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (v.services) {
            var foundService = _.find(v.services, function (o) {
              return o.service
                .toLocaleLowerCase()
                .includes(keyword.toLocaleLowerCase());
            });
            if (foundService) return v;
          }
          if (
            v.address &&
            v.address
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (
            v.description &&
            v.description
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
        }
      });
      setVisibleVenues(finalVenues);
      setTempVenues(finalVenues);
      // localStorage.setItem("venues", JSON.stringify(finalVenues));
    } else {
      setVisibleVenues(venues);
      setTempVenues(venues);
      // localStorage.setItem("venues", JSON.stringify(venues));
    }
  };
  const handlMaplink = () => {
    window.location.href = "/";
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Search Results</title>
      </Helmet>
      <div className="row">
        <div
          className={`container ${
            showMap ? "block_view" : ""
          } col-sm-${showMap ? "6" : "10"}`}
          style={{
            paddingLeft: showMap ? "2rem" : "2rem",
            minHeight: "90vh",
            paddingRight: "1rem"
          }}
        >
          <div className="space-list" style={{ marginTop: 60 }}>
            <br />
            {venues && venues.length > 0 ? (
              <div>
                <div
                  className="filter_panel_conatiner"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    flexWrap: showMap ? "wrap" : "nowrap"
                  }}
                >
                  <div
                    className="col-md-4"
                    style={{
                      top: 0,
                      padding: 0,
                      minWidth: showMap ? "100%" : ""
                    }}
                  >
                    <h2
                      className="section__title "
                      style={{
                        marginBottom: "10px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      SEARCH RESULTS
                    </h2>
                    <div className="count">
                      <b>
                        {Location?.suburb != "" &&
                        Location?.suburb != undefined &&
                        Location?.suburb != null
                          ? `${venues.length} workspaces around ${Location?.suburb}`
                          : `${venues.length} workspaces`}
                      </b>
                    </div>
                  </div>
                  <div
                    className="filter-panel-div col-md-8"
                    style={{
                      minWidth: showMap ? "100%" : "",
                      padding: showMap ? 0 : ""
                    }}
                  >
                    <div className="filter-panel">
                      <div
                        className="filter-btn__wrapper"
                        style={{ zIndex: 3 }}
                      >
                        <button
                          onClick={() => {
                            setIsSortOpened(false);
                            setIsFilterOpened(!isFilterOpened);
                          }}
                          className="btn btn-white wizard__btn mb-0"
                        >
                          FILTER
                          {filterBy.length && filterByVenue.length ? (
                            <>
                              {" "}
                              (
                              {filterBy.length + filterByVenue.length}
                              )
                            </>
                          ) : filterBy.length ? (
                            <>({filterBy.length})</>
                          ) : filterByVenue.length ? (
                            <>({filterByVenue.length})</>
                          ) : (
                            <></>
                          )}
                          <img
                            className="wizard__btn--img-left"
                            src="/img/icons/filter.svg"
                            alt=""
                          />
                        </button>

                        {isFilterOpened && (
                          <div
                            className="filter-popup filter-popup--filter"
                            style={{ left: 0 }}
                          >
                            <div className="tags-section">
                              <div
                                style={{
                                  marginRight: 120,
                                  marginBottom: 10
                                }}
                              >
                                Type
                              </div>
                              {venueTypes.map(t => (
                                <div
                                  key={t.id}
                                  onClick={() => toggleFilter(t.id)}
                                  className={`tags-item${
                                    filterBy.indexOf(t.id) >= 0
                                      ? " active"
                                      : ""
                                  }`}
                                >
                                  <p>{t.type}</p>
                                </div>
                              ))}
                              <div
                                style={{
                                  marginRight: 120,
                                  marginBottom: 10,
                                  marginTop: 20
                                }}
                              >
                                Environments
                              </div>
                              {venueEnvs.map(t => (
                                <div
                                  key={t.id}
                                  onClick={() =>
                                    toggleFilterEnv(t.id)
                                  }
                                  className={`tags-item${
                                    filterByVenue.indexOf(t.id) >= 0
                                      ? " active"
                                      : ""
                                  }`}
                                >
                                  <p>{t.environment}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="filter-btn__wrapper"
                        style={{ zIndex: 2 }}
                      >
                        <button
                          onClick={() => {
                            setIsFilterOpened(false);
                            setIsSortOpened(!isSortOpened);
                          }}
                          className="btn btn-white wizard__btn mb-0"
                        >
                          SORT
                          <img
                            className="wizard__btn--img-left"
                            src="/img/icons/sort.svg"
                            alt=""
                          />
                        </button>
                        {isSortOpened && (
                          <div
                            ref={sortRef}
                            className="filter-popup filter-popup--right"
                          >
                            <div
                              onClick={() => toggleSortName()}
                              className={`tags-item${
                                sortBy === SortBy.NameAsc ||
                                sortBy === SortBy.NameDesc
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <p>
                                Name
                                {(sortBy === SortBy.NameAsc ||
                                  sortBy === SortBy.NameDesc) && (
                                  <img
                                    alt=""
                                    src={
                                      sortBy === SortBy.NameAsc
                                        ? "img/icons/up.svg"
                                        : "img/icons/down.svg"
                                    }
                                  />
                                )}
                              </p>
                            </div>
                            <div
                              onClick={() => toggleSortPrice()}
                              className={`tags-item${
                                sortBy === SortBy.PriceAsc ||
                                sortBy === SortBy.PriceDesc
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <p>
                                Price
                                {(sortBy === SortBy.PriceAsc ||
                                  sortBy === SortBy.PriceDesc) && (
                                  <img
                                    alt=""
                                    src={
                                      sortBy === SortBy.PriceAsc
                                        ? "img/icons/up.svg"
                                        : "img/icons/down.svg"
                                    }
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="filter-btn__wrapper">
                        <MapButtonComponent
                          showMapView={false}
                          showMap={showMap}
                          handleMap={handleMap}
                        ></MapButtonComponent>
                      </div>
                      <div className="filter-btn__wrapper">
                        <input
                          type="text"
                          onChange={e => searchWorkPlace(e)}
                          id="searchWorkplace"
                          ref={outSideClickRef}
                          autoFocus={true}
                          placeholder="Enter a keyword.."
                          style={{
                            borderRadius: "4px",
                            paddingLeft: "18%",
                            border: "1px solid #009CDC",
                            position: "relative",
                            height: "42px",
                            color: "#009CDC"
                          }}
                        />
                        <img
                          src="/img/icons/searchIcon.png"
                          style={{
                            position: "absolute",
                            top: "25%",
                            left: "5%",
                            width: "18px"
                          }}
                          alt="searchIcon"
                        />
                      </div>
                    </div>

                    <div
                      className="filter-panel-price"
                      style={{ zIndex: 1, paddingTop: 0 }}
                    >
                      <div className="price__wrapper">
                        <div className="price_tag_blue"></div>
                        <p>
                          <b>Basic: </b>0-50 credits/hr
                        </p>
                      </div>
                      <div className="price__wrapper">
                        <div className="price_tag_purple"></div>
                        <p>
                          <b>Standard: </b>51-170 credits/hr
                        </p>
                      </div>
                      <div className="price__wrapper">
                        <div className="price_tag_yellow"></div>
                        <p>
                          <b>Premium: </b>170 + credits/hr
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {iscloseModal ? (
                  <ShowRatingsModal
                    selectedVenue={selectedVenue}
                    decline={closeModal}
                  ></ShowRatingsModal>
                ) : null}
                <div
                  className="row "
                  style={{ marginTop: isSortOpened ? "6%" : "" }}
                >
                  {visibleVenues &&
                    visibleVenues.map(v => {
                      return (
                        <>
                          {v.spaces.length > 0 && v.images != null ? (
                            <div
                              className={`col-sm-${showMap ? 6 : 4}`}
                              onClick={() => handleSearchSave(v.id)}
                            >
                              <WorkspaceItem
                                fromSearch={true}
                                venue={v}
                                user={user}
                                showDialog={showDialog}
                              />
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                </div>
              </div>
            ) : (
              <SearchComponent
                scrollTop={scrollTop}
                history={history}
              ></SearchComponent>
            )}
          </div>
        </div>
        {showMap ? (
          <div
            className="container col-sm-6"
            style={{ marginTop: 60 }}
          >
            <div className="mapBtn">
              <MapButtonComponent
                showMapView
                showMap={showMap}
                handleMap={handleMap}
              ></MapButtonComponent>
            </div>
            <div
              className="map"
              style={{ top: 125, position: "sticky" }}
            >
              <div className="mapVenue">
                <Link
                  target="blank"
                  to="/mapView"
                  onClick={() => {
                    localStorage.setItem(
                      "venues",
                      JSON.stringify(visibleVenues)
                    );
                  }}
                  className="mapView_link"
                >
                  <p>Open In New Window</p>
                </Link>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk"
                  }}
                  center={mapCenterNew ? mapCenterNew : center}
                  zoom={11}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onChange={({ center, bounds }) => {
                    if (
                      center.lat != mapCenter.lat ||
                      center.lng != mapCenter.lng
                    ) {
                      setCenter(center);
                      setCenterNew(center);
                    }
                    if (mapCenter.lat != 0)
                      changeMarkerPosition(bounds);
                    setMapCenter(center);
                  }}
                  options={maps => ({
                    zoomControl: true,
                    zoomControlOptions: {
                      position: maps.ControlPosition.TOP_LEFT
                    }
                  })}
                >
                  {visibleVenues.map(marker => {
                    return (
                      <MapMarkerComponent
                        lat={marker.lat}
                        lng={marker.lng}
                        key={marker.id}
                        marker={marker}
                        openModal={openModal}
                      />
                    );
                  })}
                </GoogleMapReact>
                {isModalOpen ? (
                  <VenuesModal decline={hideModal} venue={data} />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default VenueSearchResults;
