import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  saveBasicSearch,
  submitVenueAdvanceSearchEffect,
  submitVenueSearchEffect
} from "../../VenueSearch/Actions";
import { DashboardApi } from "../../../API/DashboardApi";
import {
  getSearchPreferencesEffect,
  handlePreferrencesModal
} from "../Actions";
import { Modal } from "../../../UI/Modal";
import { useState } from "react";
import EditPreferenceModal from "./EditPrefernceModal";
import {
  getAssetsEffect,
  getEnvironmentsEffect,
  getInterestEffect,
  getServicesEffect,
  getTypesEffect
} from "../../VenueWizard/Actions";

function PreferencesModal() {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = useSelector(state => state.auth.token);
  const searchPreferences = useSelector(
    state => state.dashboard.searchPreferences
  );

  const [editPreference, setEditPreference] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState();

  const handleDelete = async id => {
    const data = await DashboardApi.DeleteSearchPreferences(
      token,
      id
    );
    toast.success("Successfully deleted");
    dispatch(getSearchPreferencesEffect());
  };
  useEffect(() => {
    dispatch(getEnvironmentsEffect());
    dispatch(getServicesEffect());
    dispatch(getInterestEffect());
    dispatch(getAssetsEffect());
    dispatch(getTypesEffect());
  }, []);
  useEffect(() => {}, [searchPreferences]);

  const handleEdit = preference => {
    preference.assets =
      typeof preference.assets == "string"
        ? preference.assets && preference.assets.length > 1
          ? preference.assets.split(",")
          : preference.assets
        : "";
    setSelectedPreference(preference);
    dispatch(saveBasicSearch(preference));
    setEditPreference(true);
  };

  const searchPreferenceResult = values => {
    if (values.environments && values.environments[0] == "") {
      values.environments = values.environments.shift();
    }
    if (values.types && values.types[0] == "") {
      values.types = values.types.shift();
    }
    if (values.services && values.services[0] == "") {
      values.services = values.services.shift();
    }
    if (values.venue_types && values.venue_types[0] == "") {
      values.venue_types = values.venue_types.shift();
    }
    if (values.assets && values.assets[0] == "") {
      values.assets = values.assets.shift();
    }
    if (values.interests && values.interests[0] == "") {
      values.interests = values.interests.shift();
    }

    dispatch(
      submitVenueAdvanceSearchEffect(
        values.suburb ? values.suburb : "",
        values.no_of_people ? values.no_of_people : "",
        values.types,
        values.environments,
        values.services,
        values.interests,
        values.date,
        values.start_time,
        values.end_time,
        values.credits,
        0,
        "no",
        history,
        values.assets,
        "",
        "",
        values.venue_types
      )
    );
  };

  return (
    <Modal>
      <div
        className="addressbook-form-box"
        style={{ maxHeight: "60vh", padding: 0, overflow: "auto" }}
      >
        <div
          className="congrat-box__header"
          style={{ paddingTop: 0, position: "sticky", zIndex: 999 }}
        >
          <div className="search-pref__title">
            MY SEARCH PREFERENCE
          </div>
          <div
            onClick={() => dispatch(handlePreferrencesModal(false))}
            className="close-btn"
          ></div>
        </div>
        <div
          className="addressbook-form"
          style={{ textAlign: "start", padding: "10px 14px" }}
        >
          {searchPreferences && searchPreferences.length > 0 ? (
            searchPreferences.map(preference => {
              return (
                <div
                  className="search-pref__block"
                  style={{
                    position: "relative",
                    marginBottom: "10px",
                    justifyContent: "flex-start"
                  }}
                >
                  <div className="assignment-icon"></div>
                  <span
                    style={{ cursor: "pointer", overflow: "auto" }}
                    onClick={e => searchPreferenceResult(preference)}
                  >
                    Workspace Type: {preference.typesText.join(",  ")}{" "}
                    <br />
                    Workspace Environments:{" "}
                    {preference.environmentsText.join(",  ")} <br />
                    Venue Amenities:{" "}
                    {preference.servicesText.join(",  ")} <br />
                    Workspace Amenities:{" "}
                    {preference.assetsText.join(",  ")} <br />
                  </span>
                  <div
                    className="dashboard__delete"
                    style={{
                      width: 13,
                      position: "absolute",
                      top: 0,
                      right: 5
                    }}
                    onClick={() => handleDelete(preference.id)}
                  ></div>
                  <div
                    className="dashboard__edit"
                    style={{
                      width: 13,
                      position: "absolute",
                      top: 0,
                      right: 20
                    }}
                    onClick={() => handleEdit(preference)}
                  ></div>
                </div>
              );
            })
          ) : (
            <p>No Preferences Saved</p>
          )}{" "}
        </div>
      </div>
      {editPreference ? (
        <EditPreferenceModal
          selectedPreference={selectedPreference}
          id={selectedPreference.id}
          handlePreferrencesModal={setEditPreference}
        />
      ) : null}
    </Modal>
  );
}

export default PreferencesModal;
