/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { VenueWizardStep } from "./Reducers";
import {
  venueWizardSetStep,
  venueTypeForm,
  venueBonusForm,
  publishVenueEffect,
} from "./Actions";
import VenueSteps from "./VenueSteps";
import Footer from "../../footer";
import { Helmet } from "react-helmet";
import { setLoader, setBookingVenueLoader } from "../Loader/Actions";
class VenueBonusOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bonus_offer: "",
    };
  }
  componentDidMount() {
    this.setState({ bonus_offer: this.props.bonus_offer });
    window.scrollTo(0, 0);
  }
  handleBonusOffer = (e) => {
    this.setState({ bonus_offer: e.target.value });
  };
  handleSave = () => {
    this.props.setBookingVenueLoader(true);
    this.props.venueBonusForm(this.state.bonus_offer);
    window.localStorage.setItem("number_of_workspace", 1);
    if (this.props.venueId) {
      this.props.setLoader(true);
      this.props.publishVenueEffect(this.props.history, this.props.venueId);
    } else {
      this.props.isEdit
        ? this.props.publishVenueEffect(this.props.history, this.props.venueId)
        : this.props.publishVenueEffect(this.props.history);
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="Dorpee-workspaces on demand" />
          <title>Dorpee - List Your Venue - 4</title>
        </Helmet>
        <div className="body">
          <div className="container containerAddSpace">
            <VenueSteps step={4} end={this.props.venueId ? true : false}>
              {" "}
            </VenueSteps>
            <div className="page-wrapper" style={{ marginTop: 25 }}>
              <div className="md-title">
                <div className="row">
                  <div className="col-md-10">
                    <div className="is-title">
                      <h1 className="register-title">List Your Venue</h1>
                      <p className="register-sub-title">4. Bonus Offer</p>
                      <p style={{ fontSize: "14px" }}>
                        This is a special little something you are offering to
                        Dorpee guests. Examples might be "25% off all Food &
                        Beverages" or "One Free Coffee/Tea per Hour".
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bl-time"
                style={{ marginTop: 20, marginLeft: 0, marginBottom: 40 }}
              >
                <div className="row ">
                  <div className="col-md-10">
                    <input
                      type="text"
                      onChange={(e) => this.handleBonusOffer(e)}
                      Value={this.state.bonus_offer}
                      className="form-control"
                      placeholder="e.g. Free hot drink of your choice"
                      style={{
                        background: "url('/img/percen.png')no-repeat",
                        backgroundSize: 30,
                        paddingLeft: 40,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="bl-buttons">
                <div className="row">
                  <div className="col-4">
                    <a
                      onClick={() =>
                        this.props.venueWizardSetStep(VenueWizardStep.Amenities)
                      }
                      className="btn btn-back"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                  <div className="col-8">
                    <button
                      onClick={() => this.handleSave()}
                      className={`btn  ${
                        !this.props.isLoading || !this.props.bookin_venue_Loder
                          ? "btn-next"
                          : "btn--loadingNext"
                      }`}
                      disabled={
                        this.props.isLoading || this.props.bookin_venue_Loder
                      }
                      style={{ paddingLeft: 0 }}
                    >
                      {!this.props.venueId ? "Add Workspace Types" : "Preview"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer isSubs></Footer>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bonus_offer: state.venueWizard.venueBonus,
  isLoading: state.Loader.isLoading,
  bookin_venue_Loder: state.Loader.isLoadingBooking_Venue,
});

const mapDispatchToProps = {
  venueWizardSetStep,
  venueTypeForm,
  venueBonusForm,
  publishVenueEffect,
  setLoader,
  setBookingVenueLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueBonusOffer);
