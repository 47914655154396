import React from "react";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
import {
  getVenuesEffect,
  postSpaceBlockDays
} from "../../VenueWizard/Actions";
import { Modal } from "../../../UI/Modal";
import { VenueApi } from "../../../API/VenueApi";
import Confirmation from "../../Dashboard/modals/Confirmation";

import "react-datepicker/dist/react-datepicker.css";

const selectStyles = {
  control: styles => ({
    ...styles,
    minHeight: "45px",
    border: "1px solid rgb(0, 156, 220)",
    textAlign: "initial",
    fontFamily: "RobotoRegular",
    fontSize: "16px"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles
    };
  },
  IndicatorsContainer: () => ({
    padding: 0
  }),
  indicatorSeparator: () => ({ display: "none" })
};
const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div className="input-select"></div>
      </components.DropdownIndicator>
    )
  );
};
class BlockDays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmationsBoxOpen: false,
      opening_time: "",
      closing_time: "",
      date: "",
      errors: {
        opening_time: false,
        closing_time: false,
        valid_time: false
      },
      openingTimeError: "",
      closingTimeError: "",
      openingTimeOpen: false,
      closingTimeOpen: false,
      selectedVenue: 0,
      selectedSpace: null,
      spaces: [],
      isChecked: false,
      repeat_type: "none",
      timeframe: 0,
      quantity: 0,
      timeframeError: false,
      spaceError: false,
      quantityError: false,
      quantityExceeds: false,
      selectedSpaceTemp: {},
      hasBooking: 0
    };
    this.CloseModal = this.CloseModal.bind(this);
    this.handleCloseTime = this.handleCloseTime.bind(this);
    this.handleOpenTime = this.handleOpenTime.bind(this);
    this.openTime = this.openTime.bind(this);
    this.closeTime = this.closeTime.bind(this);

    this.handleBlock = this.handleBlock.bind(this);
    this.getSpaceSelect = this.getSpaceSelect.bind(this);
  }

  componentDidMount() {
    this.props.getVenuesEffect();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.venues.length > 0 &&
      prevProps.venues !== this.props.venues
    ) {
      let spaces = [];
      this.props.venues
        .find(x => x.id == parseInt(this.props.venues[0].id))
        .spaces.map(space => {
          spaces.push({ value: space.id, label: space.name });
        });
      this.setState({ spaces: spaces });
      this.setState({ selectedVenue: this.props.venues[0].id });
    }
  }

  isFormValid() {
    let timeOpening = this.state.opening_time.split(":");
    let date = new Date();
    let selectedDate = new Date(this.props.block_date);
    let selectedDay = selectedDate.getDate();
    let month = selectedDate.getMonth();
    let year = selectedDate.getFullYear();
    let finalDate = new Date(
      year,
      month,
      selectedDay,
      Number(timeOpening[0]),
      Number(timeOpening[1]),
      timeOpening[2]
    );
    let res = true;
    let tmp = this.state.errors;

    if (
      this.state.opening_time > this.state.closing_time ||
      this.state.opening_time === this.state.closing_time
    ) {
      tmp.opening_time = true;
      this.setState({
        errors: tmp,
        openingTimeError: "Please select a valid time"
      });
      res = false;
    }
    if (this.state.opening_time.length === 0) {
      tmp.opening_time = true;
      this.setState({
        openingTimeError: "Please select a start time"
      });
      res = false;
    }
    if (this.state.closing_time.length === 0) {
      tmp.closing_time = true;
      this.setState({
        errors: tmp,
        closingTimeError: "Please select a end time"
      });
      res = false;
    }

    if (this.state.selectedSpace == null) {
      this.setState({ spaceError: true });
      res = false;
    }
    if (
      this.state.repeat_type != "none" &&
      (this.state.timeframe === 0 || this.state.timeframe === "")
    ) {
      this.setState({ timeframeError: true });
      res = false;
    }
    return res;
    // if (
    //   this.state.opening_time.length === 0 ||
    //   !moment(finalDate).isAfter(date)
    // ) {
    //   toast.error("Please select a future date and time", {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   tmp.opening_time = true;
    // }

    // if (tmp.opening_time || tmp.closing_time || tmp.valid_time)
    //   res = false;
    // this.setState({ errors: tmp });
    // if (this.state.isChecked && this.state.timeframe === 0) {
    //   this.setState({ timeframeError: true });
    //   res = false;
    // }
    // if (this.state.selectedSpace == null) {
    //   this.setState({ spaceError: true });
    //   res = false;
    // }
    // return res;
  }

  CloseModal() {
    this.props.CloseModal();
  }

  hideModal = () => {
    this.setState({ isConfirmationsBoxOpen: false });
  };

  handleOpenTime(event) {
    if (!event) {
      this.setState({
        opening_time: ""
      });
      return;
    }

    let date = moment(event._d).format("L");
    let openTime = event._d.toString().split(" ");
    let cloningData = this.state.errors;
    cloningData.opening_time = false;
    this.setState({
      opening_time: openTime[4],
      date: date,
      errors: cloningData
    });
  }

  handleCloseTime(event) {
    if (!event) {
      this.setState({
        closing_time: ""
      });
      return;
    }

    let date = moment(event._d).format("L");
    let closeTime = event._d.toString().split(" ");
    let cloningData = this.state.errors;
    cloningData.closing_time = false;
    if (this.state.opening_time < this.state.closing_time)
      cloningData.valid_time = false;
    this.setState({
      closing_time: closeTime[4],
      date: date,
      errors: cloningData
    });
  }

  setSelectedSpace = async selectedSpace => {
    if (selectedSpace) {
      this.setState({ spaceError: false });
    }
    this.setState({ selectedSpace });
    let hasQuantity = await this.props.venues.find(v => {
      return v.id === Number(this.state.selectedVenue);
    });
    if (hasQuantity) {
      hasQuantity = await hasQuantity.spaces.find(
        s => s.id === selectedSpace.value
      );

      this.setState({ selectedSpaceTemp: hasQuantity });
      let hasQuantityError =
        Number(hasQuantity.quantity) < 1 ? true : false;
      this.setState({ quantityError: hasQuantityError });
      this.setState({
        quantity:
          Number(hasQuantity.quantity) == 1
            ? 1
            : Number(hasQuantity.quantity)
      });
    }
  };

  handleVenueChange = event => {
    let spaces = [];
    this.props.venues
      .find(x => x.id == parseInt(event.target.value))
      .spaces.map(space => {
        spaces.push({ value: space.id, label: space.name });
      });
    this.setState({ selectedVenue: event.target.value, spaces });
  };

  async handleBlock() {
    if (this.isFormValid()) {
      let { opening_time, closing_time } = this.state;
      opening_time = opening_time.split(":");
      opening_time = opening_time[0] + ":" + opening_time[1] + ":00";
      closing_time = closing_time.split(":");
      closing_time = closing_time[0] + ":" + closing_time[1] + ":00";
      let spaces = [];
      spaces.push(this.state.selectedSpace.value);
      let request = {
        date: moment(this.props.block_date).format("MM/DD/Y"),
        spaces,
        opening_time,
        closing_time,
        repeat: this.state.repeat_type != "none" ? "yes" : "no",
        cancel_booking: "no",
        quantity: Number(this.state.quantity)
      };
      if (this.state.isConfirmationsBoxOpen) {
        request.cancel_booking = "yes";
      }

      request.repeat_type =
        this.state.repeat_type != "none"
          ? this.state.repeat_type
          : "";
      request.timeframe = this.state.timeframe;
      const response = await VenueApi.postSpaceBlockDay(
        this.props.token,
        request
      );
      if (response.data) {
        if (response.data.message == "Bookings Exist") {
          this.setState({ isConfirmationsBoxOpen: true });
        } else {
          this.setState({ isConfirmationsBoxOpen: false });
          this.props.CloseModal("block");
        }
      } else if (response.message) {
        toast.error(response.message);
      } else {
        this.setState({ isConfirmationsBoxOpen: false });
        this.props.CloseModal("block");
      }
    }
  }

  handleCheckBox = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleTypeChange = ev => {
    this.setState({ repeat_type: ev.target.value });
  };

  handleTimeFrame = ev => {
    if (ev.target.value !== "")
      this.setState({ timeframeError: false });
    this.setState({ timeframe: ev.target.value });
  };

  handleQuantity = ev => {
    if (ev.target.value) {
      this.setState({ quantity: ev.target.value });
    }
  };

  getSpaceSelect = spacesCount => {
    let content = [];
    if (spacesCount == "1") {
      content.push(
        <option selected key={1}>
          1
        </option>
      );
    } else {
      for (let i = 0; i < spacesCount; i++) {
        if (i == 0) {
          content.push(
            <option
              selected={this.state.quantity == spacesCount}
              key={spacesCount}
            >
              All
            </option>
          );
        } else {
          content.push(
            <option selected={this.state.quantity == i} key={i}>
              {i}
            </option>
          );
        }
      }
    }
    return content;
  };

  openTime(value) {
    this.setState({ openingTimeOpen: value });
  }

  closeTime(value) {
    this.setState({ closingTimeOpen: value });
  }

  render() {
    return (
      <>
        {!this.state.isConfirmationsBoxOpen ? (
          <Modal>
            <div className="addressbook-form-box block-day-modal space-modal-block">
              <div className="congrat-box__header">
                <span>Block Day/Time</span>
                <div
                  className="close-btn"
                  onClick={this.CloseModal.bind(this, "aaa")}
                ></div>
              </div>
              <div className="addressbook-form">
                {this.props.venues.length > 0 ? (
                  <div
                    className="row"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className="col-md-6">
                      <label
                        style={{ fontSize: 15, display: "flex" }}
                      >
                        Select Venue:
                      </label>
                      <div
                        style={{
                          border: "1px solid #009cdc",
                          width: "100%"
                        }}
                        className="input-container"
                      >
                        <div className="input-select">
                          <select
                            style={{ color: "#009cdc" }}
                            className="pl-12"
                            onChange={e => this.handleVenueChange(e)}
                          >
                            {this.props.venues &&
                              this.props.venues.map(venue => (
                                <option
                                  key={venue.id}
                                  value={venue.id}
                                >
                                  {venue.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {this.state.spaces.length > 0 ? (
                      <div className="col-md-6">
                        <label
                          style={{ fontSize: 15, display: "flex" }}
                        >
                          Select Space:
                        </label>
                        <Select
                          components={{ DropdownIndicator }}
                          styles={selectStyles}
                          value={this.state.selectedSpace}
                          onChange={this.setSelectedSpace}
                          options={this.state.spaces}
                        />
                        {this.state.spaceError ? (
                          <div
                            className="addressbook-form"
                            style={{
                              textAlign: "start",
                              marginBottom: "0.5rem"
                            }}
                          >
                            <div className=" input-container_block ">
                              <p className="contact-form-error-block">
                                Select a space
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <>
                        <div
                          className="col-md-6"
                          style={{ color: "#009cdc", marginTop: 30 }}
                        >
                          No Spaces Available
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div style={{ color: "#21c2ff" }}>Loading...</div>
                )}
                <div
                  className="row filter-time__group"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <div
                    className="col-md-6"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left"
                    }}
                  >
                    <label>From</label>
                    <div
                      className="input-container input-container_t input-container_block"
                      style={{
                        position: "relative",
                        border: "1px solid rgb(0, 156, 220)",
                        width: "100%"
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {" "}
                        <img
                          src="/img/icons/clock.svg"
                          alt="From"
                          style={{
                            position: "absolute",
                            right: "1%"
                          }}
                          onClick={() => {
                            if (!this.state.openingTimeOpen)
                              this.setState({
                                openingTimeOpen: true
                              });
                          }}
                        />
                        <TimePicker
                          prefixCls="input-container-prefix"
                          popupStyle={{ zIndex: "9999" }}
                          minuteStep={15}
                          open={this.state.openingTimeOpen}
                          onOpen={this.openTime.bind(this, true)}
                          onClose={this.openTime.bind(this, false)}
                          onChange={this.handleOpenTime.bind(this)}
                          showSecond={false}
                          placeholder="Start Time"
                        />
                      </span>
                    </div>
                    {this.state.errors.opening_time ||
                    this.state.errors.valid_time ? (
                      <p style={{ color: "red" }}>
                        {this.state.openingTimeError}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left"
                    }}
                  >
                    <label>To</label>
                    <div
                      className="input-container input-container_t input-container_block"
                      style={{
                        position: "relative",
                        border: "1px solid rgb(0, 156, 220)",
                        width: "100%"
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <img
                          src="/img/icons/clock.svg"
                          alt="To"
                          style={{
                            position: "absolute",
                            right: "1%"
                          }}
                          onClick={() => {
                            if (!this.state.closingTimeOpen)
                              this.setState({
                                closingTimeOpen: true
                              });
                          }}
                        />
                        <TimePicker
                          prefixCls="input-container-prefix"
                          popupStyle={{ zIndex: "9999" }}
                          minuteStep={15}
                          open={this.state.closingTimeOpen}
                          onOpen={this.closeTime.bind(this, true)}
                          onClose={this.closeTime.bind(this, false)}
                          onChange={this.handleCloseTime.bind(this)}
                          showSecond={false}
                          placeholder="End Time"
                        />
                      </span>
                    </div>
                    {this.state.errors.closing_time ? (
                      <p style={{ color: "red" }}>
                        {this.state.closingTimeError}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <div className="col-md-6">
                    <label style={{ fontSize: 15, display: "flex" }}>
                      Repeat
                    </label>
                    <div
                      style={{
                        border: "1px solid #009cdc",
                        width: "100%"
                      }}
                      className="input-container"
                    >
                      <div className="input-select">
                        <select
                          style={{ color: "#009cdc" }}
                          className="pl-12"
                          onChange={e => this.handleTypeChange(e)}
                        >
                          <option value={"none"} selected>
                            None
                          </option>
                          <option value={"daily"}>Every Day</option>
                          <option value={"weekly"}>Every Week</option>
                          <option value={"monthly"}>
                            Every Month
                          </option>
                          <option value={"yearly"}>Every Year</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label style={{ fontSize: 15, display: "flex" }}>
                      Number of Repeats
                    </label>
                    <div
                      style={{
                        border: "1px solid #009cdc",
                        width: "100%"
                      }}
                      className="input-container pl-12"
                    >
                      <input
                        className={"noButtons"}
                        autoComplete="off"
                        type="number"
                        onChange={e => this.handleTimeFrame(e)}
                        placeholder="Number of Repeats"
                      />
                    </div>
                    {this.state.timeframeError ? (
                      <p style={{ color: "red" }}>
                        Please enter number of repetitions
                      </p>
                    ) : null}
                  </div>
                </div>
                \
                <div
                  className="row"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <div className="col-md-6">
                    <label style={{ fontSize: 15, display: "flex" }}>
                      Number of Workspaces
                    </label>
                    <div
                      style={{
                        border: "1px solid #009cdc",
                        width: "100%"
                      }}
                      className="input-container"
                    >
                      <div className="input-select">
                        <select
                          style={{ color: "#009cdc" }}
                          className="pl-12"
                          onChange={this.handleQuantity}
                          readOnly={!this.state.quantityError}
                        >
                          <>
                            {!this.state.quantityError && (
                              <option>Number Of Workspaces</option>
                            )}
                          </>

                          {this.getSpaceSelect(
                            Number(
                              this.state.selectedSpaceTemp.quantity
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addressbook-form">
                  <div className=" input-container_block ">
                    {this.state.quantityExceeds ? (
                      <p
                        className="contact-form-error-block"
                        style={{ left: -100 }}
                      >
                        Space quantity exceeds.
                      </p>
                    ) : null}
                  </div>
                </div>
                <button
                  onClick={this.handleBlock.bind(this)}
                  disabled={
                    this.state.spaces.length > 0 ? false : true
                  }
                  style={
                    this.state.spaces.length > 0 &&
                    this.state.opening_time &&
                    this.state.closing_time &&
                    this.state.selectedSpace
                      ? { color: "#009cdc" }
                      : { color: "grey" }
                  }
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                >
                  Block
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          <Confirmation
            message={
              "A booking already exists on the given date & time, cancel booking if you want to block space?"
            }
            decline={this.hideModal}
            accept={this.handleBlock}
            id={0}
            acceptText={"Yes"}
            declineText={"No"}
            modalHeader={""}
            showButtons={true}
          ></Confirmation>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.venueWizard.venues,
  token: state.auth.token
});

const mapDispatchToProps = {
  getVenuesEffect,
  postSpaceBlockDays
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockDays);
