import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Masonry from "react-masonry-css";
import _ from "lodash";

import { EditPreferencesEffect } from "../../VenueSearch/Actions";
import MultiCheckboxN from "../../../UI/MulticheckboxN";
import MultiCheckbox from "../../../UI/MultiCheckBox";
import { AppState } from "../../../store";
import { Modal } from "../../../UI/Modal";

import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";

export type EditPreferenceModal = {
  types: string[];
  interests: string[];
  services: string[];
  environments: string[];
  assets: string[];
  venue_types: string[];
};

const EditPreferenceModal = reduxForm<
  EditPreferenceModal,
  { id: any; selectedPreference: any; handlePreferrencesModal: any }
>({
  form: "search",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    dispatch(
      EditPreferencesEffect(
        values,
        props.id,
        props.handlePreferrencesModal,
        dispatch
      )
    );
  },
})((props) => {
  const venueTypes = useSelector((state: AppState) => state.venueWizard.types);
  const spaceTypes = useSelector(
    (state: AppState) => state.workspaceWizard.venueTypes
  );
  const assets = useSelector((state: AppState) => state.venueWizard.assets);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoadingSearch
  );
  const [servicesList, setList] = useState<any>([]);
  const [assetsList, setAssetsList] = useState<any>([]);
  const services = useSelector((state: AppState) => state.venueWizard.services);
  const environments = useSelector(
    (state: AppState) => state.venueWizard.environments
  );

  const [showServices, setShowServices] = useState<any>(false);
  const [showAmenities, setShowAmenities] = useState<any>(false);

  useEffect(() => {
    let grouped = _.mapValues(_.groupBy(services, "category"), (service) =>
      service.map((ast) => _.omit(ast, "category"))
    );
    setList(grouped);
  }, [services]);

  useEffect(() => {
    let grouped = _.mapValues(_.groupBy(assets, "category"), (asset) =>
      asset.map((ast) => _.omit(ast, "category"))
    );
    setAssetsList(grouped);
  }, [assets]);

  useEffect(() => {
    props.initialize({
      types: props.selectedPreference.types,
      interests: props.selectedPreference.interests,
      services: props.selectedPreference.services,
      environments: props.selectedPreference.environments,
      assets:
        props.selectedPreference && props.selectedPreference.assets.length > 1
          ? props.selectedPreference.assets
          : "",
      venue_types: props.selectedPreference.venueTypes,
    });
  }, []);

  return (
    <Modal style={{ maxWidth: "80%", maxHeight: "70vh", overflow: "auto" }}>
      <div className="addressbook-form-box">
        <div className="congrat-box__header">
          <div className="search-pref__title">MY SEARCH PREFERENCE</div>{" "}
          <div
            onClick={() => {
              props.handlePreferrencesModal(false);
            }}
            className="close-btn"
          ></div>
        </div>
        <div className="addressbook-form" style={{ textAlign: "start" }}>
          <form onSubmit={props.handleSubmit}>
            <div className="booking-search__tags">
              <div className="booking-search__tags-title">Venue TYPE</div>
              <div className="tags-section">
                <Field
                  type="checkbox"
                  name="venue_types"
                  checkboxes={venueTypes.map((t) => ({
                    label: t.type,
                    value: t.id.toString(),
                  }))}
                  component={MultiCheckbox}
                />
              </div>
            </div>

            <div className="booking-search__tags">
              <div className="booking-search__tags-title">SPACE TYPE</div>
              <div className="tags-section">
                <Field
                  type="checkbox"
                  name="types"
                  checkboxes={spaceTypes.map((t) => ({
                    label: t.type,
                    value: t.id.toString(),
                  }))}
                  component={MultiCheckbox}
                />
              </div>
            </div>

            <div className="filter-block">
              <div className="booking-search__tags-title">ENVIRONMENT</div>
              <div className="tags-section">
                <Field
                  type="checkbox"
                  name="environments"
                  checkboxes={environments.map((t) => ({
                    label: t.environment,
                    value: t.id.toString(),
                  }))}
                  component={MultiCheckbox}
                />
              </div>
            </div>

            <div className="filter-block">
              <div
                className="booking-search__tags-title"
                onClick={() => setShowServices(!showServices)}
                style={{ cursor: "pointer" }}
              >
                Venue Amenities
              </div>

              <Masonry
                breakpointCols={2}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {Object.keys(servicesList).map((obj: any) => {
                  return (
                    <div className="amenities-item">
                      <p className="title bold-title">{obj}</p>
                      <div className="all-item">
                        <Field
                          type="checkbox"
                          name="services"
                          checkboxes={servicesList[obj].map((data: any) => ({
                            label: data.service,
                            value: data.id.toString(),
                          }))}
                          component={MultiCheckboxN}
                        />
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </div>

            <div className="filter-block">
              <div
                className="booking-search__tags-title"
                onClick={() => setShowAmenities(!showAmenities)}
                style={{ cursor: "pointer" }}
              >
                Workspace Amenities
              </div>

              <div
                className=""
                style={{ display: `${showAmenities ? true : false}` }}
              >
                <Masonry
                  breakpointCols={2}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {Object.keys(assetsList).map((obj: any) => {
                    return (
                      <div className="amenities-item">
                        <p className="title bold-title">{obj}</p>
                        <div className="all-item">
                          <Field
                            type="checkbox"
                            name="assets"
                            checkboxes={assetsList[obj].map((data: any) => ({
                              label: data.asset,
                              value: data.id.toString(),
                            }))}
                            component={MultiCheckboxN}
                            isAssets
                          />
                        </div>
                      </div>
                    );
                  })}
                </Masonry>
              </div>
            </div>

            <button
              className={
                isLoading ? "btn btn--loadingNew" : "booking-search__btn"
              }
              style={{
                backgroundColor: "rgb(245, 89, 127)",
              }}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
});
export default EditPreferenceModal;
