import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrappedFieldProps } from "redux-form";

import { saveBasicSearch } from "../Screens/VenueSearch/Actions";
import { AppState } from "../store";

export type CheckBox = {
  label: string;
  value: string;
};

const MultiCheckbox: FC<
  WrappedFieldProps & { checkboxes: CheckBox[] }
> = props => {
  const { input } = props;
  const searchDetails = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );
  const dispatch = useDispatch();

  let values: string[] = [];
  if (Object.keys(searchDetails).length > 0) {
    if (input.name === "types") {
      input.value =
        Object.keys(searchDetails).length === 0
          ? []
          : searchDetails.types;
    } else if (input.name === "environments") {
      input.value =
        Object.keys(searchDetails).length === 0
          ? []
          : searchDetails.environments;
    } else if (input.name === "venue_types") {
      input.value =
        Object.keys(searchDetails).length === 0
          ? []
          : searchDetails.venue_types;
    }
  }

  if (input.value) {
    values = input.value;
  }
  const contains = (value: string) =>
    values.some(
      v =>
        value === v ||
        (searchDetails &&
          searchDetails[input.name].some((v: any) => value === v))
    );

  const onClick = (value: string) => {
    const newValues = contains(value)
      ? values.filter(v => v !== value)
      : [...values, value];
    props.input.onChange(newValues);
    props.input.onBlur(newValues);
    dispatch(saveBasicSearch({ [input.name]: newValues }));
  };
  const displayError = props.meta.touched && props.meta.error;

  return (
    <>
      {displayError && (
        <div className="validation-error">{props.meta.error}</div>
      )}
      <div className="tags-section">
        {props.checkboxes.map(c => {
          return (
            <div
              key={c.value}
              onClick={() => onClick(c.value)}
              className={`tags-item${
                contains(c.value) ? " active" : ""
              }`}
            >
              <p>{c.label}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MultiCheckbox;
