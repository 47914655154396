import React, { FC, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { Carousel } from "react-responsive-carousel";
import { locationUrl } from "../../common/googleMaps";
import { getBookingByIdEffect } from "../Book/Booking/Actions";
import "../../Screens/venue-workspace.sass";
import {
  getDay,
  getDate,
  getMonth,
  getYear,
  getFormated12hTime
} from "../../helpers/DateHelpers";

const InviteBooking: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const booking = useSelector(
    (state: AppState) => state.Booking.singleBooking
  );
  const space = booking && booking.space;
  const history = useHistory();
  useEffect(() => {
    dispatch(getBookingByIdEffect(id));
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
      </Helmet>
      <div className="container">
        <div className="book-space">
          <div className="row">
            <div className="col-lg-7">
              <div className="book-space__slider-img">
                <p style={{ padding: 5 }}>
                  Not a Dorpee customer? Sign up today to receive free
                  credits.{" "}
                  <button
                    onClick={() =>
                      history.push("/register/select-type")
                    }
                    className="btn btn-white  col-lg-2"
                  >
                    Sign Up
                  </button>
                </p>

                <div className="slider__wrapper">
                  <Carousel
                    dynamicHeight={true}
                    showThumbs={false}
                    showStatus={false}
                  >
                    {space &&
                      space.images.map((imageUrl: any) => (
                        <div className="img-preview-container">
                          <img src={imageUrl} />
                        </div>
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="book-space__info-workspace">
                <div className="order-wrapper">
                  <div className="invite venue-info">
                    <h3>
                      Meeting Details for your invite from
                      <br /> {space &&
                        space.venue.user.first_name}{" "}
                      {space && space.venue.user.last_name} are listed
                      below.
                    </h3>

                    <p>
                      Date:
                      <span className="venue-info__title">
                        {" "}
                        {booking && getDay(booking!.created_at)}{" "}
                        {booking && getDate(booking!.start_date)},{" "}
                        {booking && getYear(booking!.start_date)}{" "}
                      </span>
                    </p>
                    <p>
                      Time:
                      <span className="venue-info__title">
                        {" "}
                        {booking &&
                          getFormated12hTime(booking.start_time)}{" "}
                        -{" "}
                        {booking &&
                          getFormated12hTime(
                            booking && booking.end_time
                          )}
                      </span>
                    </p>
                    <p>
                      Venue:{" "}
                      <span className="venue-info__title">
                        {" "}
                        {space && space.venue.name}{" "}
                      </span>{" "}
                    </p>
                    <p>
                      Workspace:{" "}
                      <span className="venue-info__title">
                        {space && space.name}{" "}
                      </span>
                    </p>
                  </div>
                  <div className="venue-address">
                    <div className="venue-distance">
                      4km from city center
                    </div>
                    <div className="venue-address__info">
                      <div className="venue-address__icon"></div>
                      <span>
                        {space && space.venue.address}{" "}
                        {space && space.venue.suburb}{" "}
                        {space && space.venue.country}
                      </span>
                    </div>
                    {booking && (
                      <a
                        className="venue-address__link"
                        target="_blank"
                        href={locationUrl(
                          space && space.venue.lat,
                          space && space.venue.lng
                        )}
                      >
                        View on Map
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteBooking;
