import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
import moment from "moment";
import _ from "lodash";

import { AppState } from "../../store";
import { getProfileEffect } from "../Auth/Actions";
import { getDashboardDataEffect } from "../Dashboard/Actions";
import { getDateformatedChange } from "../../helpers/DateHelpers";
import { getBookingEffect } from "../../Screens/Book/Booking/Actions";
import { getEmplpoyerCustomerBookings } from "../Book/Booking/Actions";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const EmployerBooking: FC = () => {
  const dispatch = useDispatch();
  const myBookings = useSelector(
    (state: AppState) => state.Booking.MyteamBooking
  );
  const user = useSelector((state: AppState) => state.auth.user);

  var role = false;
  user &&
    user.role.forEach(r => {
      if (r.title === "Employer") {
        role = true;
        return role;
      }
    });
  const [endDate, setEndDate] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [activeSort, setActiveSort] = useState<any>(null);
  const [pastSort, setPastSort] = useState<any>(null);
  const [sortBy, setSortBy]: any = useState("");

  useEffect(() => {
    dispatch(getEmplpoyerCustomerBookings());
    dispatch(getBookingEffect());
    dispatch(getProfileEffect());
    dispatch(getDashboardDataEffect());
  }, []);

  const [activeBookings, setActiveBookings] = useState<any>([]);
  const [visibleActiveBookings, setVisibleActiveBookings] =
    useState<any>([]);
  const [pastBookings, setPastBookings] = useState<any>([]);
  const [visiblePastBookings, setVisiblePastBookings] = useState<any>(
    []
  );
  const [employeeName, setName] = useState("");
  useEffect(() => {
    var past: any = [];
    var active: any = [];
    if (myBookings && myBookings.length > 0) {
      for (var i = 0; i < myBookings.length; i++) {
        past = past.concat(myBookings[i].past_bookings);
        if (myBookings[i].cancelled_bookings)
          past = past.concat(myBookings[i].cancelled_bookings);
        if (
          myBookings[i].active_booking &&
          myBookings[i].active_booking.length > 0
        ) {
          for (
            var j = 0;
            j < myBookings[i].active_booking.length;
            j++
          ) {
            let end_date = new Date(
              myBookings[i].active_booking[j].end_date
            );
            let time =
              myBookings[i].active_booking[j].end_time.split(":");
            end_date.setHours(time[0], time[1], time[2], 0);
            let booking_time =
              end_date.getTime() > new Date().getTime();
            if (booking_time) {
              active = active.concat(myBookings[i].active_booking[j]);
            }
          }
        }
      }
    }
    active.sort((a: any, b: any) => {
      let adate: any = new Date(a.start_date);
      let atime = a.start_time.split(":");
      let bdate: any = new Date(b.start_date);
      let btime = b.start_time.split(":");

      adate.setHours(atime[0], atime[1], atime[2]);
      bdate.setHours(btime[0], btime[1], btime[2]);
      return bdate - adate;
    });
    past.sort((a: any, b: any) => {
      let adate: any = new Date(a.start_date);
      let atime = a.start_time.split(":");
      let bdate: any = new Date(b.start_date);
      let btime = b.start_time.split(":");

      adate.setHours(atime[0], atime[1], atime[2]);
      bdate.setHours(btime[0], btime[1], btime[2]);
      return bdate - adate;
    });
    setActiveBookings(active);
    setVisibleActiveBookings(active);
    setPastBookings(past);
    setVisiblePastBookings(past);
  }, [myBookings]);

  const handleSort = async (
    sortValue: any,
    type: any,
    sortBy: any
  ) => {
    let sortedList: any = [];
    // if (
    //   (type === "active" && sortValue === activeSort) ||
    //   (type === "past" && sortValue === pastSort)
    // ) {
    //   return null;
    // }
    if (type === "active") {
      if (sortValue === "empName") {
        sortedList = await activeBookings.sort((a: any, b: any) => {
          let fa = a.user!?.first_name.toLowerCase(),
            fb = b.user!?.first_nameto.LowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "name") {
        sortedList = await activeBookings.sort((a: any, b: any) => {
          let fa = a.space?.venue?.name.toLowerCase(),
            fb = b.space?.venue?.name.toLowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "dateTime") {
        sortedList = await activeBookings.sort((a: any, b: any) => {
          let adate: any = new Date(a.start_date);
          let atime = a.start_time.split(":");
          let bdate: any = new Date(b.start_date);
          let btime = b.start_time.split(":");

          adate.setHours(atime[0], atime[1], atime[2]);
          bdate.setHours(btime[0], btime[1], btime[2]);
          if (sortBy === "smallest") {
            return bdate - adate;
          } else {
            return adate - bdate;
          }
        });
      } else if (sortValue === "credits") {
        sortedList = await activeBookings.sort((a: any, b: any) => {
          if (sortBy === "smallest") {
            return a.credits - b.credits;
          } else {
            return b.credits - a.credits;
          }
        });
      } else {
        sortedList = activeBookings;
      }
      setSortBy(sortBy);
      setActiveSort(sortValue);
      setVisibleActiveBookings(sortedList);
    } else if (type === "past") {
      if (sortValue === "empName") {
        sortedList = await pastBookings.sort((a: any, b: any) => {
          let fa = a.user!?.first_name.toLowerCase(),
            fb = b.user!?.first_name.toLowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "name") {
        sortedList = await pastBookings.sort((a: any, b: any) => {
          let fa = a.space?.venue?.name.toLowerCase(),
            fb = b.space?.venue?.name.toLowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "dateTime") {
        sortedList = await pastBookings.sort((a: any, b: any) => {
          let adate: any = new Date(a.start_date);
          let atime = a.start_time.split(":");
          let bdate: any = new Date(b.start_date);
          let btime = b.start_time.split(":");

          adate.setHours(atime[0], atime[1], atime[2]);
          bdate.setHours(btime[0], btime[1], btime[2]);
          if (sortBy === "smallest") {
            return bdate - adate;
          } else {
            return adate - bdate;
          }
        });
      } else if (sortValue === "credits") {
        sortedList = await pastBookings.sort((a: any, b: any) => {
          if (sortBy === "smallest") {
            return a.credits - b.credits;
          } else {
            return b.credits - a.credits;
          }
        });
      } else {
        sortedList = pastBookings;
      }
      setSortBy(sortBy);
      setPastSort(sortValue);
      setVisiblePastBookings(sortedList);
    }
  };

  const handleSubmit = async () => {
    if (myBookings && myBookings.length > 0) {
      var tempBooking = { ...myBookings };
      var data: any = [];
      var pastBookingData: any = [];
      await Object.keys(myBookings).map(async (c, index) => {
        let newdata: any = [];
        let newpastBookingData: any = [];

        if (startDate && endDate && employeeName) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                startDate.getTime() <=
                  getDateformatedChange(item.start_date).getTime() &&
                endDate.getTime() >=
                  getDateformatedChange(item.start_date).getTime() &&
                item.user.full_name
                  .toLowerCase()
                  .includes(employeeName.toLowerCase())
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              startDate.getTime() <=
                getDateformatedChange(item.start_date).getTime() &&
              endDate.getTime() >=
                getDateformatedChange(item.start_date).getTime() &&
              item.user.full_name
                .toLowerCase()
                .includes(employeeName.toLowerCase())
            ) {
              return item;
            }
          });
        } else if (startDate && employeeName) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                startDate.getTime() <=
                  getDateformatedChange(item.start_date).getTime() &&
                item.user.full_name
                  .toLowerCase()
                  .includes(employeeName.toLowerCase())
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              startDate.getTime() <=
                getDateformatedChange(item.start_date).getTime() &&
              item.user.full_name
                .toLowerCase()
                .includes(employeeName.toLowerCase())
            ) {
              return item;
            }
          });
        } else if (endDate && employeeName) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                endDate.getTime() >=
                  getDateformatedChange(item.start_date).getTime() &&
                item.user.full_name
                  .toLowerCase()
                  .includes(employeeName.toLowerCase())
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              endDate.getTime() >=
                getDateformatedChange(item.start_date).getTime() &&
              item.user.full_name
                .toLowerCase()
                .includes(employeeName.toLowerCase())
            ) {
              return item;
            }
          });
        } else if (startDate && endDate) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                startDate.getTime() <=
                  getDateformatedChange(item.start_date).getTime() &&
                endDate.getTime() >=
                  getDateformatedChange(item.start_date).getTime()
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              startDate.getTime() <=
                getDateformatedChange(item.start_date).getTime() &&
              endDate.getTime() >=
                getDateformatedChange(item.start_date).getTime()
            ) {
              return item;
            }
          });
        } else if (employeeName) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                item.user.full_name
                  .toLowerCase()
                  .includes(employeeName.toLowerCase())
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              item.user.full_name
                .toLowerCase()
                .includes(employeeName.toLowerCase())
            ) {
              return item;
            }
            // if (item.credits == 34) {
            //   return item;
            // }
          });
        } else if (startDate) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                startDate.getTime() <=
                getDateformatedChange(item.start_date).getTime()
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              startDate.getTime() <=
              getDateformatedChange(item.start_date).getTime()
            ) {
              return item;
            }
          });
        } else if (endDate) {
          newdata = await tempBooking[index].active_booking.filter(
            (item: any) => {
              if (
                endDate.getTime() >=
                getDateformatedChange(item.start_date).getTime()
              ) {
                return item;
              }
            }
          );
          newpastBookingData = await tempBooking[
            index
          ].past_bookings.filter((item: any) => {
            if (
              endDate.getTime() >=
              getDateformatedChange(item.start_date).getTime()
            ) {
              return item;
            }
          });
        }
        if (newpastBookingData.length > 0) {
          pastBookingData = pastBookingData.concat(
            newpastBookingData
          );
        }
        if (newdata.length > 0) {
          data = data.concat(newdata);
        }
        setPastBookings(pastBookingData);
        setActiveBookings(data);
      });
    }
  };

  const clearFilter = () => {
    if (startDate || endDate || employeeName != "") {
      setStartDate(null);
      setEndDate(null);
      setName("");
      dispatch(getEmplpoyerCustomerBookings());
      dispatch(getBookingEffect());
      dispatch(getProfileEffect());
      dispatch(getDashboardDataEffect());
    }
  };

  const searchActiveBookings = (event: any) => {
    const keyword = event.target.value;
    if (event.target.value != "") {
      if (keyword && keyword != "") {
        let filteredBookings =
          activeBookings &&
          activeBookings.filter((booking: any) => {
            if (
              (booking.space.venue.name &&
                booking.space.venue.name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.space.name &&
                booking.space.name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.space.venue.name &&
                booking.space.venue.name
                  .toLocaleLowerCase()
                  .concat(
                    "(" + booking.space.name &&
                      booking.space.name.toLocaleLowerCase() + ")"
                  )
                  .includes(keyword.toLocaleLowerCase()))
            ) {
              return booking;
            }
            if (
              (booking.user.first_name &&
                booking.user.first_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.user.last_name &&
                booking.user.last_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.user.full_name &&
                booking.user.full_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase()))
            ) {
              return booking;
            }
          });
        setVisibleActiveBookings(filteredBookings);
      } else setVisibleActiveBookings(activeBookings);
    }
  };

  const searchPastBookings = (event: any) => {
    const keyword = event.target.value;
    if (event.target.value != "") {
      if (keyword && keyword != "") {
        let filteredBookings =
          pastBookings &&
          pastBookings.filter((booking: any) => {
            if (
              (booking.space.venue.name &&
                booking.space.venue.name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.space.name &&
                booking.space.name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.space.venue.name &&
                booking.space.venue.name
                  .toLocaleLowerCase()
                  .concat(
                    "(",
                    booking.space.name &&
                      booking.space.name.toLocaleLowerCase(),
                    ")"
                  )
                  .includes(keyword.toLocaleLowerCase()))
            ) {
              return booking;
            }
            if (
              (booking.user.first_name &&
                booking.user.first_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.user.last_name &&
                booking.user.last_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase())) ||
              (booking.user.full_name &&
                booking.user.full_name
                  .toLocaleLowerCase()
                  .includes(keyword.toLocaleLowerCase()))
            ) {
              return booking;
            }
          });
        setVisiblePastBookings(filteredBookings);
      } else setVisiblePastBookings(pastBookings);
    }
  };

  if (role) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Employer Bookings</title>
        </Helmet>
        <div
          style={{
            paddingLeft: "5rem",
            paddingRight: "5rem",
            paddingTop: "60px"
          }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div className="col-md-3 col-lg-3">
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label style={{ display: "flex", fontWeight: 500 }}>
                  {" "}
                  Start Date:{" "}
                </label>
                <div
                  className="input-container "
                  style={{
                    justifyContent: "space-between",
                    marginLeft: 1,
                    width: "100%"
                  }}
                >
                  <DatePicker
                    onChange={e => setStartDate(e)}
                    selected={startDate}
                    className="empDateField"
                    dateFormat="dd/MM/yyyy"
                  />

                  <img
                    className="emp-date-icon"
                    src="/img/icons/from.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label
                  style={{
                    display: "flex",
                    fontWeight: 500,
                    marginRight: 10
                  }}
                >
                  End Date:{" "}
                </label>
                <div
                  className="input-container "
                  style={{
                    justifyContent: "space-between",
                    marginLeft: 1,
                    width: "100%"
                  }}
                >
                  <DatePicker
                    onChange={e => setEndDate(e)}
                    selected={endDate}
                    className="empDateField"
                    dateFormat="dd/MM/yyyy"
                  />

                  <img
                    className="emp-date-icon"
                    src="/img/icons/from.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label style={{ display: "flex", fontWeight: 500 }}>
                  Employee Name:
                </label>
                <div
                  className="input-container "
                  style={{
                    justifyContent: "space-between",
                    marginLeft: 1,
                    width: "100%"
                  }}
                >
                  <input
                    value={employeeName}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="stats-chart__item"
                style={{
                  marginBottom: "14px",
                  top: "1.4rem",
                  position: "relative"
                }}
              >
                <button
                  type="submit"
                  className={`btn2 btn-blue`}
                  onClick={handleSubmit}
                  style={{ width: "100%" }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="stats-chart__item"
                style={{
                  marginBottom: "14px",
                  top: "1.4rem",
                  position: "relative"
                }}
              >
                <button
                  type="submit"
                  className={`btn2 btn-blue`}
                  onClick={clearFilter}
                  style={{ width: "100%" }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
            <span
              style={{
                marginTop: 0,
                marginLeft: "20px",
                color: "red"
              }}
            ></span>
          </div>
          <div className="approval__header-title ">
            <div
              className="dashboard__title headerTable"
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              Active Bookings
              <div className="filter-btn__wrapper">
                <input
                  type="text"
                  onChange={e => searchActiveBookings(e)}
                  id="searchActiveBookings"
                  autoFocus={true}
                  placeholder="Enter a keyword.."
                  style={{
                    display: "flex",
                    borderRadius: "4px",
                    paddingLeft: "18%",
                    border: "1px solid #009CDC",
                    position: "relative",
                    height: "42px",
                    color: "#009CDC",
                    maxWidth: "100%"
                  }}
                />
                <img
                  src="/img/icons/searchIcon.png"
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "5%",
                    width: "18px"
                  }}
                  alt="searchIcon"
                />
              </div>
            </div>
          </div>
          <div className="team-list ">
            <table className="team-list-table table-style">
              <thead>
                <tr>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        activeSort === "empName" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("empName", "active", "smallest");
                      } else {
                        handleSort("empName", "active", "largest");
                      }
                    }}
                  >
                    Employee
                    {activeSort === "empName" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        activeSort === "name" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("name", "active", "smallest");
                      } else {
                        handleSort("name", "active", "largest");
                      }
                    }}
                  >
                    Venue Name
                    {activeSort === "name" && sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        activeSort === "dateTime" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("dateTime", "active", "smallest");
                      } else {
                        handleSort("dateTime", "active", "largest");
                      }
                    }}
                  >
                    Date/Time
                    {activeSort === "dateTime" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th className="table-head">Number of People</th>
                  <th className="table-head">Cancellation Period</th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        activeSort === "credits" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("credits", "active", "smallest");
                      } else {
                        handleSort("credits", "active", "largest");
                      }
                    }}
                  >
                    Credits Spent{" "}
                    {activeSort === "credits" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {visibleActiveBookings &&
                visibleActiveBookings.length > 0 ? (
                  visibleActiveBookings.map((activeBooking: any) => (
                    <tr style={{ height: 50 }}>
                      <td className="table-style">
                        {activeBooking.user!?.first_name}{" "}
                        {activeBooking.user!?.last_name}
                      </td>
                      <td className="table-style">
                        {activeBooking.space!?.venue!?.name} (
                        {activeBooking.space!?.name})
                      </td>
                      <td className="table-style">
                        {moment(
                          activeBooking.start_date,
                          "MM-DD-YYYY"
                        ).format("DD-MM-YYYY")}{" "}
                        {activeBooking.start_time.split(":")[0]}:
                        {activeBooking.start_time.split(":")[1]}
                        {"-"}
                        {activeBooking.end_time.split(":")[0]}:
                        {activeBooking.end_time.split(":")[1]}
                      </td>
                      <td className="table-style">
                        {activeBooking.no_of_people}
                      </td>
                      <td className="table-style">
                        {activeBooking.space!?.cancellation_period ==
                        0
                          ? "Cancel or update anytime"
                          : activeBooking.space!?.cancellation_period}
                      </td>
                      <td className="table-style">
                        {activeBooking.credits}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="tableRowData">
                    No upcoming bookings
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div
            className="approval__header-title"
            style={{ marginTop: 100 }}
          >
            <div
              className="dashboard__title headerTable"
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              Past Bookings
              <div className="filter-btn__wrapper">
                <input
                  type="text"
                  onChange={e => searchPastBookings(e)}
                  id="searchPastBookings"
                  autoFocus={true}
                  placeholder="Enter a keyword.."
                  style={{
                    display: "flex",
                    borderRadius: "4px",
                    paddingLeft: "18%",
                    border: "1px solid #009CDC",
                    position: "relative",
                    height: "42px",
                    color: "#009CDC",
                    maxWidth: "100%"
                  }}
                />
                <img
                  src="/img/icons/searchIcon.png"
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "5%",
                    width: "18px"
                  }}
                  alt="searchIcon"
                />
              </div>
            </div>
          </div>
          <div className="team-list">
            <table className="team-list-table table-style">
              <thead>
                <tr>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        pastSort === "empName" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("empName", "past", "smallest");
                      } else {
                        handleSort("empName", "past", "largest");
                      }
                    }}
                  >
                    Employee
                    {pastSort === "empName" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        pastSort === "name" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("name", "past", "smallest");
                      } else {
                        handleSort("name", "past", "largest");
                      }
                    }}
                  >
                    Venue Name
                    {pastSort === "name" && sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        pastSort === "dateTime" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("dateTime", "past", "smallest");
                      } else {
                        handleSort("dateTime", "past", "largest");
                      }
                    }}
                  >
                    Date/Time
                    {pastSort === "dateTime" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th className="table-head">Number of People</th>
                  <th
                    className="table-head table_sort"
                    onClick={() => {
                      if (
                        pastSort === "credits" &&
                        sortBy !== "smallest"
                      ) {
                        handleSort("credits", "past", "smallest");
                      } else {
                        handleSort("credits", "past", "largest");
                      }
                    }}
                  >
                    Credits Spent
                    {pastSort === "credits" &&
                    sortBy === "largest" ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </th>
                  <th className="table-head">Cancelled</th>
                </tr>
              </thead>
              <tbody>
                {visiblePastBookings &&
                visiblePastBookings.length > 0 ? (
                  visiblePastBookings.map((pastBooking: any) => (
                    <tr style={{ height: 50 }}>
                      <td className="table-style">
                        {pastBooking.user!?.first_name}{" "}
                        {pastBooking.user!?.last_name}
                      </td>
                      <td className="table-style">
                        {pastBooking.space!?.venue!?.name} (
                        {pastBooking.space!?.name})
                      </td>
                      <td className="table-style">
                        {moment(
                          pastBooking.start_date,
                          "MM-DD-YYYY"
                        ).format("DD-MM-YYYY")}{" "}
                        {pastBooking.start_time.split(":")[0]}:
                        {pastBooking.start_time.split(":")[1]}
                        {"-"}
                        {pastBooking.end_time.split(":")[0]}:
                        {pastBooking.end_time.split(":")[1]}
                      </td>
                      <td className="table-style">
                        {pastBooking.no_of_people}
                      </td>
                      <td className="table-style">
                        {pastBooking.credits}
                      </td>
                      <td className="table-style">
                        {pastBooking.status == "Cancelled"
                          ? "YES"
                          : " NO"}{" "}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="tableRowData">No past bookings</tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          title="Dorpee Employer Bookings"
          content="Dorpee-workspaces on demand"
        />
      </Helmet>
      <div className="container pd-0">
        <div className="approval__header-title">
          <div className="dashboard__title">My Bookings</div>
        </div>

        {/* <div className="approval">
          <div className="content-title">WAITING APPROVAL</div>
          <div className="row">
            <div className="col-md-4">
              <div className="approval__item">
                <div className="venue-dropdown__item">
                  <div className="venue-dropdown__icon"><img src="/img/anna.png" alt=""/></div>
                  <div className="venue-dropdown__info">
                    <div className="venue-dropdown__time">1hr ago</div>
                    <div className="venue-dropdown__message">Anna requested to book <span>My Venue 1 </span>on <strong>July 30, 12:30 - 18:30</strong></div>
                    <div className="approval__availb">Check availability</div>
                  </div>
                </div>
                <div className="approval__item-btns">
                  <button className="btn btn-grey mb-0">REJECT</button>
                  <button className="btn btn-blue mb-0">ACCEPT</button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="approval__item">
                <div className="venue-dropdown__item">
                  <div className="venue-dropdown__icon"><img src="/img/anna.png" alt=""/></div>
                  <div className="venue-dropdown__info">
                    <div className="venue-dropdown__time">1hr ago</div>
                    <div className="venue-dropdown__message">Anna requested to book <span>My Venue 1 </span>on <strong>July 30, 12:30 - 18:30</strong></div>
                    <div className="approval__availb">Check availability</div>
                  </div>
                </div>
                <div className="approval__item-btns">
                  <button className="btn btn-grey mb-0">REJECT</button>
                  <button className="btn btn-blue mb-0">ACCEPT</button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="approval__item">
                <div className="venue-dropdown__item">
                  <div className="venue-dropdown__icon"><img src="/img/anna.png" alt=""/></div>
                  <div className="venue-dropdown__info">
                    <div className="venue-dropdown__time">1hr ago</div>
                    <div className="venue-dropdown__message">Anna requested to book <span>My Venue 1 </span>on <strong>July 30, 12:30 - 18:30</strong></div>
                    <div className="approval__availb">Check availability</div>
                  </div>
                </div>
                <div className="approval__item-btns">
                  <button className="btn btn-grey mb-0">REJECT</button>
                  <button className="btn btn-blue mb-0">ACCEPT</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EmployerBooking;
