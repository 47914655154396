import { ApiClient } from "./ApiClient";
import { User } from "./AuthApi";
import { Workspace, Venue } from "./VenueApi";
import { timingSafeEqual } from "crypto";
import SpaceAvailability from "../Screens/SpaceAvailability";
export class BookingAPI {
  static CreateBooking(token: string, request: createBookingRequest) {
    return ApiClient.post<createBookingRequest, BookingResponse>(
      "user/bookings",
      request,
      token
    );
  }
  static EditBooking(
    token: string,
    request: createBookingRequest,
    id: number
  ) {
    return ApiClient.post<createBookingRequest, BookingResponse>(
      `user/bookings/${id}`,
      request,
      token
    );
  }
  static ChangeBookingStatus(
    token: string,
    request: ChangeStatusRequest
  ) {
    return ApiClient.post<ChangeStatusRequest, BookingResponse>(
      "bookings/changestatus",
      request,
      token
    );
  }
  static CancelvenueBooking(
    token: string,
    booking_id: any,
    request: createVanueBookingCancelRequest
  ) {
    return ApiClient.post<
      createVanueBookingCancelRequest,
      BookingResponse
    >(`venue/bookings/cancel/${booking_id}`, request, token);
  }
  static ExtendBooking(token: string, request: ExtendRequest) {
    return ApiClient.post<ExtendRequest, BookingResponse>(
      "bookings/extend",
      request,
      token
    );
  }
  static CancelBooking(
    token: string,
    booking_number: number,
    request: createBookingCancelRequest
  ) {
    return ApiClient.post<createBookingCancelRequest, MyBookings>(
      `user/bookings/change-status/${booking_number}`,
      request,
      token
    );
  }
  static getContacts(token: string) {
    return ApiClient.get<ContactFields>("user/contacts", token);
  }
  static GetBookings(token: string) {
    return ApiClient.get<MyBookings>("user/bookings", token);
  }
  static GetBooking(token: string, booking_number: string) {
    return ApiClient.get<SingleBooking>(
      `bookings/${booking_number}`,
      token
    );
  }
  static GetCustomerEmployee(token: string) {
    return ApiClient.get<CustomerEmployees>(
      "customer/my-employers",
      token
    );
  }
  static GetSpaceAvailability(request: SpaceAvailabilityRequest) {
    return ApiClient.post<
      SpaceAvailabilityRequest,
      SpaceAvailabilityResponse
    >("user/check-availability", request);
  }
  static GetMyCustomerBookings(token: string) {
    return ApiClient.get<EmployerMyCustomerBookings>(
      "employer/my-customers?with_bookings=1",
      token
    );
  }
  static BookingsValidations(request: SpaceAvailabilityRequest) {
    return ApiClient.post<
      SpaceAvailabilityRequest,
      SpaceAvailabilityResponse
    >("user/booking-validate", request);
  }
  static EmployerBooking(token: any, request: any) {
    return ApiClient.post<any, any>(
      "user/bookings/by-employer",
      request,
      token
    );
  }
  static EmployerList(token: any) {
    return ApiClient.get<any>(
      `user/all-organisation-customers`,
      token
    );
  }
}

export class createBookingRequest {
  constructor(detailsFields: BookingFields) {
    this.user_id = detailsFields.user_id;
    this.space_id = detailsFields.space_id;
    this.start_date = detailsFields.start_date;
    this.end_date = detailsFields.end_date;
    this.start_time = detailsFields.start_time;
    this.end_time = detailsFields.end_time;
    this.booking_as = detailsFields.booking_as;
    this.no_of_people = detailsFields.no_of_people;
    this.no_of_booked_spaces = detailsFields.no_of_booked_spaces;
    this.credits = detailsFields.credits;
    this.venue_id = detailsFields.venue_id;
    this.teams = detailsFields.teams;
    this.contacts = detailsFields.contacts;
    this.employer_id = detailsFields.employer_id;
    this._method = detailsFields._method;
    this.special_requests = detailsFields.special_requests;
    this.invited_employees = detailsFields.invited_employees;
  }
  user_id: number;
  space_id: number;
  start_date: string;
  end_date: string;
  start_time: any;
  end_time: any;
  booking_as: string;
  no_of_people: number;
  no_of_booked_spaces: number;
  venue_id: number;
  credits?: number;
  teams?: any[];
  contacts?: any[];
  employer_id: any;
  _method?: any;
  special_requests?: string;
  invited_employees?: any;
}
export class ChangeStatusRequest {
  constructor(detailsFields: BookingStatus) {
    this.booking_id = detailsFields.booking_id;
    this.status = detailsFields.status;
  }

  booking_id: number;
  status: string;
}
export class ExtendRequest {
  constructor(detailsFields: number) {
    this.booking_id = detailsFields;
  }

  booking_id: number;
}

export class createBookingCancelRequest {
  constructor(detailsFields: BookingCancelFields) {
    this.status = detailsFields.status;
    this._method = detailsFields._method;
  }
  status: string;
  _method: string;
}
export class createVanueBookingCancelRequest {
  constructor(detailsFields: BookingCancelFields) {
    this.status = detailsFields.status;
    this._method = detailsFields._method;
  }
  status: string;
  _method: string;
}
export class SpaceAvailabilityRequest {
  constructor(detailsFields: Availability) {
    this.date = detailsFields.date;
    this.opening_time = detailsFields.opening_time;
    this.closing_time = detailsFields.closing_time;
    this.space_id = detailsFields.space_id;
    this.no_of_spaces = detailsFields.no_of_spaces;
    this.booking_id = detailsFields.booking_id;
  }
  date: string;
  opening_time: string;
  closing_time: string;
  space_id: number;
  no_of_spaces?: number;
  booking_id?: number;
}
type Response<T> = {
  data: T;
};
export type Availability = {
  date: string;
  opening_time: string;
  closing_time: string;
  space_id: number;
  no_of_spaces?: number;
  booking_id?: number;
};
export type AvailabilityTime = {
  opening_time: string;
  closing_time: string;
  no_of_spaces?: number;
};
export type EmployerMyCustomerBookings = {
  data: any;
};
export type BookingStatus = {
  booking_id: number;
  status: string;
};
export type BookingFields = {
  id?: number;
  user_id: number;
  space_id: number;
  start_date: string;
  end_date: string;
  start_time: any;
  end_time: any;
  booking_as: string;
  no_of_people: number;
  no_of_booked_spaces: number;
  credits: number;
  venue_id: number;
  space?: Workspace;
  special_requests?: string;
  created_at?: string;
  teams: any[];
  contacts: any[];
  user?: User;
  venue?: Venue;
  employer_id?: number;
  employer?: any;
  status?: string;
  _method?: string;
  booking_status?: string;
  credits_per_hour?: any;
  invited_employees?: any;
  availableSpaces?: any;
  previousCredits?: any;
};
export type BookingCancelFields = {
  status: string;
  _method: string;
  booking_id?: any;
};

export type ContactFields = {
  id: number;
  first_name: string;
  last_name: string;
  user_id: number;
  team_id: number;
};

export type MyBookings = {
  data: BookingFields[];
};
export type SingleBooking = {
  data: BookingFields;
};
export type CustomerEmployees = {
  data: User;
};
export type MyContact = {
  data: ContactFields[];
};
export type ChangePlanData = {
  plan_id: number;
};
export type BookingResponse = {
  data: BookingFields;
  error?: string;
};

export type ContactsResponse = {
  data: ContactFields[];
};
export type SpaceAvailabilityResponse = {
  data: AvailabilityCheck;
};
export type AvailabilityCheck = {
  availability: boolean;
  message: string;
  max_available?: any;
};
