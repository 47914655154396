import React from "react";
import store from "../../store";
import { DashboardApi } from "../../API/DashboardApi";
import { toast } from "react-toastify";
import AddEmployer from "./modals/AddEmployer";
import Confirmation from "./modals/Confirmation";

class InvitedCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invitedCustomers: [],
      visibleInvitedCustomers: [],
      acceptedCustomers: [],
      visibleAcceptedCustomers: [],
      activeTab: "accepted",
      addEmployerModal: false,
      showConfirmationModal: false,
      showEditEmployerModal: false,
      invitedCustomersList: [],
      employersList: [],
      modalHeader: "",
      editedCustomerID: "",
      confirmationType: "",
      invitationNumber: "",
      employerDetails: {
        first_name: "",
        last_name: "",
        email: "",
        employee_role_id: ""
      },
      edit_id: "",
      disable: false
    };
    this.showHideModal = this.showHideModal.bind(this);
    this.updateEmployersList = this.updateEmployersList.bind(this);
    this.addEmployer = this.addEmployer.bind(this);
    this.removeEmployer = this.removeEmployer.bind(this);
  }
  componentDidMount() {
    this.getCustomers();
  }

  async getCustomers() {
    const token = this.props.token
        ? this.props.token
        : store.getState().auth.token;
    let customersResponse = await DashboardApi.GetMyCustomers(token);
    if (customersResponse.data) {
      let acceptedCustomers = this.getMyCustomers(
          customersResponse.data
      );
      this.setState({
        acceptedCustomers: acceptedCustomers,
        visibleAcceptedCustomers: acceptedCustomers
      });
      let response = await DashboardApi.GetCustomers(token);
      if (response.data) {
        let invitedCustomers = this.getInvitedCustomers(
            response.data
        );
        this.setState({
          invitedCustomers: invitedCustomers,
          visibleInvitedCustomers: invitedCustomers
        });
      }
    }
  }
  resendInvitationAction = async id => {
    const token = this.props.token
        ? this.props.token
        : store.getState().auth.token;
    let response = await DashboardApi.resendInvitation(token, id);
    if (response.data)
      toast.success("Invitation successfully re-sent.");
  };
  getInvitedCustomers(data) {
    let res = [];
    for (let item of data) {
      if (!item.status) res.push(item);
    }
    return res;
  }

  getMyCustomers(data) {
    let res = [];
    for (let item of data) {
      res.push(item);
    }
    return res;
  }

  changeTab(type) {
    this.setState({
      activeTab: type
    });
  }

  deleteEmployer(header, id, type, invitationNumber) {
    this.setState({ showConfirmationModal: true });
    this.setState({
      modalHeader: header,
      editedCustomerID: id,
      confirmationType: type,
      invitationNumber: invitationNumber
    });
  }

  editCustomer(id) {
    this.props.openUpdateCustomerModal(id);
  }

  updateEmployersList(data) {
    this.setState({
      invitedCustomersList: data.invitations,
      employersList: data.customers
    });
  }

  showHideModal(type, state) {
    if (type === "addEmployer")
      this.setState({ addEmployerModal: state });
    if (type === "editEmployer")
      this.setState({ showEditEmployerModal: state });
    if (type === "confirmation")
      this.setState({ showConfirmationModal: state });
    if (state === false) {
      this.setState({
        employerDetails: {
          first_name: "",
          last_name: "",
          email: "",
          employee_role_id: ""
        },
        showEditEmployerModal: false,
        showConfirmationModal: false,
        addEmployerModal: false,
        edit_id: ""
      });
    }
  }

  async addEmployer(data) {
    const token = this.props.token
        ? this.props.token
        : store.getState().auth.token;
    if (this.state.edit_id !== "") {
      let response = await DashboardApi.EditInvitedEmployer(
          token,
          data,
          this.state.edit_id
      );

      if (response.errors) {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        this.getCustomers(token);
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({ showEditEmployerModal: false });

        window.location.reload();
      }
    } else {
      let response = await DashboardApi.InviteEmployer(token, data);
      this.getCustomers(token);
      if (response.error) {
        toast.error(response.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      } else {
        toast.success("Invite successfully sent", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    }
  }

  async removeEmployer() {
    const token = this.props.token
        ? this.props.token
        : store.getState().auth.token;
    let data = {
      customer_id: this.state.editedCustomerID
    };
    this.setState({ disable: true });
    if (this.state.confirmationType === "invitation") {
      data = {
        status: "Rejected"
      };
      let invite_number = this.state.invitationNumber;
      await DashboardApi.AcceptRejectInvitation(
          token,
          invite_number,
          data
      );
      this.getCustomers(token);
      toast.success("Invitation successfully Removed", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      this.setState({ disable: false });
      this.showHideModal("confirmation", false);
    }
    if (this.state.confirmationType === "employer") {
      await DashboardApi.RemoveAcceptedCustomer(token, data);
      let customersResponse = await DashboardApi.GetMyCustomers(
          token
      );
      if (customersResponse.data) {
        let acceptedCustomers = this.getMyCustomers(
            customersResponse.data
        );
        this.setState({
          acceptedCustomers: acceptedCustomers,
          visibleAcceptedCustomers: acceptedCustomers
        });
        toast.success("Employee successfully removed");
        this.showHideModal("confirmation", false);
      }
      this.setState({ disable: false });
    }
  }

  searchCustomers(event) {
    const keyword = event.target.value;
    if (event.target.value != "") {
      if (this.state.activeTab === "accepted") {
        if (keyword && keyword != "") {
          let FilteredCustomers =
              this.state.acceptedCustomers &&
              this.state.acceptedCustomers.length > 0 &&
              this.state.acceptedCustomers.filter(customer => {
                if (
                    (customer.first_name &&
                        customer.first_name
                            .toLocaleLowerCase()
                            .includes(keyword.toLocaleLowerCase())) ||
                    (customer.last_name &&
                        customer.last_name
                            .toLocaleLowerCase()
                            .includes(keyword.toLocaleLowerCase()))
                ) {
                  return customer;
                }
                if (
                    customer.email &&
                    customer.email
                        .toLocaleLowerCase()
                        .includes(keyword.toLocaleLowerCase())
                ) {
                  return customer;
                }
              });
          this.setState({
            visibleAcceptedCustomers: FilteredCustomers
          });
        } else {
          this.setState({
            visibleAcceptedCustomers: this.state.acceptedCustomers
          });
        }
      }
      if (this.state.activeTab === "invited") {
        if (keyword && keyword != "") {
          let FilteredCustomers =
              this.state.invitedCustomers &&
              this.state.invitedCustomers.length > 0 &&
              this.state.invitedCustomers.filter(customer => {
                if (
                    (customer.first_name &&
                        customer.first_name
                            .toLocaleLowerCase()
                            .includes(keyword.toLocaleLowerCase())) ||
                    (customer.last_name &&
                        customer.last_name
                            .toLocaleLowerCase()
                            .includes(keyword.toLocaleLowerCase()))
                ) {
                  return customer;
                }
                if (
                    customer.email &&
                    customer.email
                        .toLocaleLowerCase()
                        .includes(keyword.toLocaleLowerCase())
                ) {
                  return customer;
                }
              });
          this.setState({
            visibleInvitedCustomers: FilteredCustomers
          });
        } else {
          this.setState({
            visibleInvitedCustomers: this.state.invitedCustomers
          });
        }
      }
    }
  }

  render() {
    return (
        <>
          <div
              className="dashboard__title headerTable"
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "space-between"
              }}
          >
            Employees
            <div className="filter-btn__wrapper">
              <input
                  type="text"
                  onChange={e => this.searchCustomers(e)}
                  id="searchCustomers"
                  // ref={outSideClickRef}
                  autoFocus={true}
                  placeholder="Enter a keyword.."
                  style={{
                    display: "flex",
                    borderRadius: "4px",
                    paddingLeft: "18%",
                    border: "1px solid #009CDC",
                    position: "relative",
                    height: "42px",
                    color: "#009CDC",
                    maxWidth: "100%"
                  }}
              />
              <img
                  src="/img/icons/searchIcon.png"
                  style={{
                    position: "absolute",
                    top: "25%",
                    left: "5%",
                    width: "18px"
                  }}
                  alt="searchIcon"
              />
            </div>
          </div>
          <div className="add-employer__heading invitation-tab-heading">
            <div
                className={
                  "dashboard__title tab-title " +
                  (this.state.activeTab === "accepted" ? "active" : "")
                }
                onClick={this.changeTab.bind(this, "accepted")}
            >
              Employees
            </div>
            <div
                className={
                  "dashboard__title tab-title " +
                  (this.state.activeTab === "invited" ? "active" : "")
                }
                onClick={this.changeTab.bind(this, "invited")}
            >
              Invited
            </div>
          </div>
          {this.state.visibleInvitedCustomers.length > 0 &&
          this.state.activeTab === "invited" ? (
              <>
                <div className="employer__wrapper">
                  <div className="employer__wrapper">
                    <table id="customers">
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.visibleInvitedCustomers.map(item => (
                          <tr key={item.id} style={{ height: 50 }}>
                            <td>
                              {item.first_name + " " + item.last_name ||
                              "-"}
                            </td>
                            <td>{item.email}</td>
                            <td>{item.employee_role?.role_name}</td>
                            <td
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end"
                                }}
                            >
                              <div className="employer__right">
                                <div
                                    className="employer__btns"
                                    style={{
                                      marginRight: 10,
                                      marginLeft: 10
                                    }}
                                >
                                  <div
                                      src="/img/icons/resend.png"
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(0, 156, 220)",
                                        fontWeight: 400
                                      }}
                                      onClick={() =>
                                          this.resendInvitationAction(
                                              item.invite_number
                                          )
                                      }
                                  >
                                    {" "}
                                    Resend Email
                                  </div>
                                </div>

                                <span>|</span>
                                <div
                                    className="employer__btns"
                                    style={{
                                      marginRight: 10,
                                      marginLeft: 10
                                    }}
                                >
                                  <div
                                      src="/img/icons/resend.png"
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(0, 156, 220)",
                                        fontWeight: 400
                                      }}
                                      onClick={this.deleteEmployer.bind(
                                          this,
                                          "Delete Invite",
                                          item.customer_id,
                                          "invitation",
                                          item.invite_number
                                      )}
                                  >
                                    Cancel Invitation
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
          ) : this.state.activeTab === "invited" ? (
              <p style={{ marginTop: 15, fontSize: 17 }}>
                You currently do not have any pending invitations{" "}
              </p>
          ) : (
              ""
          )}
          {this.state.visibleAcceptedCustomers.length > 0 &&
          this.state.activeTab === "accepted" ? (
              <>
                <div className="employer__wrapper">
                  <table id="customers">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Credit Spent This Cycle</th>
                      <th>Working Hours This Cycle</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.visibleAcceptedCustomers.map(item => {
                      return (
                          <tr key={item.id} style={{ height: 50 }}>
                            {item.invited_first_name ||
                            item.invited_last_name ? (
                                <td>
                                  {item.invited_first_name +
                                  " " +
                                  item.invited_last_name || "-"}
                                </td>
                            ) : (
                                <td>
                                  {item.first_name + " " + item.last_name ||
                                  "-"}
                                </td>
                            )}
                            <td>{item.email}</td>
                            <td>
                              {item.pivot.employee_role
                                  ? item.pivot.employee_role.role_name
                                  : ""}
                            </td>
                            <td>{item.credits_spend}</td>
                            <td> {item.working_hours}</td>
                            <td
                                style={{
                                  color: "#429bd6",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  let empdata = {
                                    first_name: item.first_name,
                                    last_name: item.last_name,
                                    email: item.email,
                                    employee_role_id: item.pivot
                                        .employee_role
                                        ? item.pivot.employee_role.id
                                        : ""
                                  };
                                  this.setState({
                                    employerDetails: empdata,
                                    edit_id: item.id
                                  });

                                  this.showHideModal("editEmployer", true);
                                }}
                            >
                              Edit
                            </td>
                            <td
                                style={{
                                  color: "#429bd6",
                                  cursor: "pointer"
                                }}
                                onClick={this.deleteEmployer.bind(
                                    this,
                                    "Confirm Employer Remove",
                                    item.id,
                                    "employer",
                                    0
                                )}
                            >
                              Remove
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </>
          ) : null}
          <div className="Add_employee_button">
            <button
                className="btn_add_employee"
                onClick={this.showHideModal.bind(
                    this,
                    "addEmployer",
                    true
                )}
            >
              INVITE EMPLOYEE
            </button>
          </div>

          {this.state.addEmployerModal ? (
              <AddEmployer
                  message="Add Employee"
                  addEmployer={this.addEmployer}
                  showHideModal={this.showHideModal}
                  {...this.state}
              />
          ) : null}
          {this.state.showConfirmationModal ? (
              <Confirmation
                  acceptText={"Yes"}
                  message={"Are you sure you want to remove this employee?"}
                  declineText={"No"}
                  accept={this.removeEmployer}
                  decline={this.showHideModal.bind(
                      this,
                      "confirmation",
                      false
                  )}
                  modalHeader={"Delete Employee"}
                  showButtons={true}
                  disable={this.state.disable}
              />
          ) : null}
          {this.state.showEditEmployerModal ? (
              <AddEmployer
                  message="Edit Employee"
                  addEmployer={this.addEmployer}
                  showHideModal={this.showHideModal}
                  employerDetails={this.state.employerDetails}
                  {...this.state}
              />
          ) : null}
        </>
    );
  }
}

export default InvitedCustomers;
