import React, { Component } from "react";
interface myProps {
  id: string;
  description: string;
  workspace?: any;
}
interface myStateProps {
  lineSize: string;
  showMore: boolean;
}
export default class ViewMoreButton extends Component<myProps, myStateProps> {
  constructor(props: myProps) {
    super(props);
    this.state = {
      lineSize: "",
      showMore: false,
    };
  }
  componentDidMount() {
    let doc = document.getElementById(this.props.id);
    let line = Number(doc && doc.offsetHeight);
    if (line > 50) {
      this.setState({ lineSize: "2.8em" });
      this.setState({ showMore: true });
    }
  }

  render() {
    return (
      <div>
        <div className="excerpt ">
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "normal",
              height: this.state.lineSize,
            }}
            id={this.props.id}
          >
            {this.props.description}
          </p>
        </div>
        {this.state.showMore ? (
          this.state.lineSize !== "auto" ? (
            <div className="view-more">
              <p
                onClick={() => this.setState({ lineSize: "auto" })}
                style={{ cursor: "pointer", color: "#31B5CE" }}
              >
                View more...
              </p>
            </div>
          ) : (
            <div className="view-more">
              <p
                onClick={() => this.setState({ lineSize: "2.8em" })}
                style={{ cursor: "pointer", color: "#31B5CE" }}
              >
                view less..
              </p>
            </div>
          )
        ) : null}
        <div>
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            {this.props.workspace && this.props.workspace.min_booking_hours
              ? `Min ${this.props.workspace.min_booking_hours} ${
                  this.props.workspace.min_booking_hours > 1 ? "Hours" : "Hour"
                } |`
              : ""}
            {this.props.workspace && this.props.workspace.max_booking_hours
              ? ` Max ${this.props.workspace.max_booking_hours} ${
                  this.props.workspace.max_booking_hours > 1 ? "Hours" : "Hour"
                } `
              : ""}
          </span>
        </div>
        <div>
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: 10,
            }}
          >
            {this.props.workspace && this.props.workspace.cancellation_period
              ? `Cancel at ${this.props.workspace.cancellation_period} Hour's Notice`
              : this.props.workspace
              ? "Cancel anytime"
              : ""}
          </span>
        </div>
      </div>
    );
  }
}
