import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./Screens/Home";
import MenuLayout from "./MenuLayout";
import AccountDetail from "./Screens/AccountDetail/AccountDetail";
import Login from "./Screens/Login";
import SignUp from "./Screens/SignUp/SignUp";
import SelectPlan from "./Screens/SignUp/SelectPlan";
import AddVenue from "./Screens/SignUp/AddVenue";
import SubscriptionPlans from "./Screens/SubscriptionPlans";
import Workspaces from "./Screens/Workspaces";
import BookStep1 from "./Screens/Book/BookStep1";
import BookStep2 from "./Screens/Book/BookStep2";
import BookStep3 from "./Screens/Book/BookStep3";
import Bookings from "./Screens/Bookings";
import DashboardVenue from "./Screens/Dashboard/VenueDashboard";
import Venues from "./Screens/Venues";
import VenueSearchResults from "./Screens/VenueSearch/SearchResults";
import { RouteChildrenProps } from "react-router";

import { useHistory } from "react-router-dom";
import VenueWizard from "./Screens/VenueWizard/VenueWizard";
import WorkspaceWizard from "./Screens/WorkspaceWizard/WorkspaceWizard";
import Dashboard from "./Screens/Dashboard/Dashboard";
import PaymentPge from "./Screens/PaymentPage";
import { useDispatch } from "react-redux";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { AppState } from "./store";
import ForgotPassword from "./Screens/ForgotPassword";
import ResetPassword1 from "./Screens/ResetPassword1";
import ForgotPasswordSent from "./Screens/ForgotPasswordSent";
import InviteBooking from "./Screens/Book/InviteBooking";
import { getProfileEffect } from "./Screens/Auth/Actions";
import ResetPassword from "./Screens/ResetPassword";
import { parseQuery } from "./common/parseQuery";
import SpaceAvailability from "./Screens/SpaceAvailability";
import Assessment from "./Screens/VenueWizard/VenueAssessment";
import { VenueVaccinationStatus } from "./Screens/VenueWizard/venueVaccinationStatus";
import Rating from "./Screens/VenueWizard/VenueRating";
import EmployerBooking from "./Screens/Dashboard/EmployerBooking";
import TermsAndCondition from "./Screens/TermsAndCondition";
import PrivacyAndPolicy from "./Screens/PrivacyAndPolicy";
import AboutUs from "./Screens/AboutUs";
import Pricing from "./Screens/Pricing";
import VenueTodayBookings from "./Screens/VenueTodayBookings";
import FAQ from "./Screens/FAQ";
import WorkspaceAvailability from "./Screens/WorkspaceWizard/WorkspaceAvailability";
import PreviewVenuePage from "./Screens/VenueWizard/PreviewVenuePage";
import NearByVenuesMap from "./Screens/NearByVenuesMap";
import VenueDetailPage from "./Screens/VenueDetailPage";
import AddWorkspace from "./Screens/VenueWizard/AddWorkspace";
import SuccessMessage from "./Screens/VenueWizard/SuccessMessage";
import Transactions from "./Screens/Customers/Transactions";
import VenueRegister from "./Screens/VenueWizard/Component/VenueRegister";
import { connect } from "react-redux";
import { SelectType } from "./Screens/SignUp/SelectType";
import InviteFriend from "./Screens/InviteFriend/InviteFriend";
import EmployerDashboardLayout from "./EmploayerDashboardLayout";
import EmployeeList from "./Screens/Dashboard/Employees";
import RolesAccess from "./Screens/Dashboard/EmployerRoles";
import EmployerTransactions from "./Screens/Dashboard/EmployerTransactions";
import MakeEmployerBooking from "./Screens/Dashboard/MakeEmployerBooking";
import EmployerAdmins from "./Screens/Dashboard/EmployerAdmin";
import IndividualUserLayout from "./individualLayout";
import PaymentOptions from "./Screens/Dashboard/Payment";
import AddressBook from "./Screens/Dashboard/AddressBook";
import FullMapView from "./Screens/FullMap";

function Layout(auth: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userRoles, user } = auth.auth;
  useEffect(() => {
    // getData();
    dispatch(getProfileEffect());
  }, []);

  // const getData = async () => {
  //   // const response = await DashboardApi.GetEmployerDashboardADmin("e09d22c3-4b7b-492f-8e4e-45e39d8721ab")
  // };

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <MenuLayout>
              <Home />
            </MenuLayout>
          </Route>
          <Route exact path="/term-condition">
            <MenuLayout>
              <TermsAndCondition />
            </MenuLayout>
          </Route>
          <Route exact path="/privacy-policy">
            <MenuLayout>
              <PrivacyAndPolicy />
            </MenuLayout>
          </Route>
          <Route exact path="/dashboard/:id?">
            {userRoles !== "Venue" && userRoles !== "Customer" ? (
              <EmployerDashboardLayout>
                <Dashboard />
              </EmployerDashboardLayout>
            ) : (
              <IndividualUserLayout>
                <Dashboard />
              </IndividualUserLayout>
            )}
          </Route>
          <Route exact path="/roles_access">
            <EmployerDashboardLayout>
              <RolesAccess />
            </EmployerDashboardLayout>
          </Route>
          {/* <Route exact path="/safety_requirements">
            <EmployerDashboardLayout>
              <SafetyRequirements />
            </EmployerDashboardLayout>
          </Route> */}
          <Route exact path="/employer/dashboard/:id?">
            <MenuLayout>
              <Dashboard />
            </MenuLayout>
          </Route>
          <Route exact path="/venue/dashboard/:id?">
            <MenuLayout>
              <Dashboard />
            </MenuLayout>
          </Route>
          <Route exact path="/transactions">
            {userRoles !== "Venue" &&
            userRoles !== "Customer" &&
            userRoles == "Employer" ? (
              <EmployerDashboardLayout>
                <EmployerTransactions />
              </EmployerDashboardLayout>
            ) : (
              <IndividualUserLayout>
                <Transactions />
              </IndividualUserLayout>
            )}
          </Route>
          <Route path="/my_employees">
            <EmployerDashboardLayout>
              <EmployeeList />
            </EmployerDashboardLayout>
          </Route>
          <Route exact path="/venue-register">
            <MenuLayout hideFooter>
              <VenueRegister />
            </MenuLayout>
          </Route>
          <Route exact path="/myteam-bookings">
            {userRoles !== "Venue" && userRoles !== "Customer" ? (
              <EmployerDashboardLayout>
                <EmployerBooking />
              </EmployerDashboardLayout>
            ) : (
              <IndividualUserLayout>
                <EmployerBooking />
              </IndividualUserLayout>
              // ) : (
              //   <MenuLayout>
              //     <EmployerBooking />
              //   </MenuLayout>
            )}
          </Route>
          <Route exact path="/employer-admins">
            {userRoles !== "Venue" && userRoles !== "Customer" ? (
              <EmployerDashboardLayout>
                <EmployerAdmins />
              </EmployerDashboardLayout>
            ) : null}
          </Route>
          <Route exact path="/booking's-today">
            <IndividualUserLayout>
              {userRoles === "Venue" ? (
                <VenueTodayBookings />
              ) : (
                <Home />
              )}
            </IndividualUserLayout>
          </Route>
          <Route exact path="/invite-friend">
            <IndividualUserLayout>
              {user ? <InviteFriend /> : <Home />}
            </IndividualUserLayout>
          </Route>
          <Route exact path="/venue-assessment">
            {user && userRoles === "Venue" ? (
              <MenuLayout>
                <Assessment />
              </MenuLayout>
            ) : (
              <Login />
            )}
          </Route>
          <Route exact path="/venue-vaccination">
            {user && userRoles === "Venue" ? (
              <MenuLayout>
                <VenueVaccinationStatus history={history} />
              </MenuLayout>
            ) : (
              <Login />
            )}
          </Route>
          <Route exact path="/review/:id">
            <MenuLayout>
              <Rating />
            </MenuLayout>
          </Route>
          <Route path="/dashboard/venue">
            <MenuLayout>
              <DashboardVenue />
            </MenuLayout>
          </Route>
          <Route path="/workspaces">
            <MenuLayout isSubs>
              <Workspaces />
            </MenuLayout>
          </Route>
          <Route path="/nearby-venues/:id">
            <MenuLayout isSubs>
              <NearByVenuesMap />
            </MenuLayout>
          </Route>
          <Route path="/venue-preview/:id">
            <MenuLayout hideFooter>
              <PreviewVenuePage />
            </MenuLayout>
          </Route>
          <Route path="/success/">
            <MenuLayout hideFooter>
              <SuccessMessage isEdit={true} />
            </MenuLayout>
          </Route>
          <Route path="/search-results">
            <MenuLayout isSubs>
              <VenueSearchResults />
            </MenuLayout>
          </Route>
          <Route exact path="/bookings/:id?">
            {userRoles !== "Venue" &&
            userRoles !== "Customer" &&
            user ? (
              <EmployerDashboardLayout>
                <Bookings />
              </EmployerDashboardLayout>
            ) : (
              <IndividualUserLayout>
                <Bookings />
              </IndividualUserLayout>
            )}
          </Route>
          <Route path="/venues">
            <IndividualUserLayout>
              <Venues />
            </IndividualUserLayout>
          </Route>
          <Route path="/space-availability">
            <IndividualUserLayout>
              <SpaceAvailability />
            </IndividualUserLayout>
          </Route>
          <Route path="/workspace/:id">
            <MenuLayout>
              <VenueDetailPage />
            </MenuLayout>
          </Route>
          <Route path="/workspace/hidden/:id?">
            <MenuLayout>
              <VenueDetailPage />
            </MenuLayout>
          </Route>
          <Route path="/invitation/:id">
            <MenuLayout>
              <InviteBooking />
            </MenuLayout>
          </Route>
          <Route path="/account-details">
            <IndividualUserLayout>
              <AccountDetail />
            </IndividualUserLayout>
          </Route>
          <Route path="/about-Us">
            <MenuLayout>
              <AboutUs />
            </MenuLayout>
          </Route>
          <Route path="/pricing">
            <MenuLayout>
              <Pricing />
            </MenuLayout>
          </Route>

          <Route path="/book/1/:id/:sid">
            {(
              match: RouteChildrenProps<{ id?: string; sid?: any }>
            ) => (
              <MenuLayout hideFooter>
                <BookStep1
                  venueId={match.match!.params.id!}
                  spaceId={match.match!.params.sid!}
                />
              </MenuLayout>
            )}
          </Route>
          <Route path="/book/2/:id/:sid">
            {(
              match: RouteChildrenProps<{ id?: string; sid?: any }>
            ) => (
              <MenuLayout hideFooter>
                <BookStep2
                  venueId={match.match!.params.id!}
                  spaceId={match.match!.params.sid!}
                />
              </MenuLayout>
            )}
          </Route>
          <Route path="/book/3/:id/:sid">
            {(
              match: RouteChildrenProps<{ id?: string; sid?: any }>
            ) => (
              <MenuLayout hideFooter>
                <BookStep3
                  venueId={match.match!.params.id!}
                  spaceId={match.match!.params.sid!}
                />
              </MenuLayout>
            )}
          </Route>
          <Route exact path="/employer-booking">
            <EmployerDashboardLayout>
              <MakeEmployerBooking />
            </EmployerDashboardLayout>
          </Route>

          <Route path="/select-plan">
            <MenuLayout>
              <SubscriptionPlans />
            </MenuLayout>
          </Route>
          <Route path="/login" exact>
            <MenuLayout hideFooter>
              {user == undefined ? <Login /> : <Home />}
            </MenuLayout>
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/change-password">
            <MenuLayout>
              <ResetPassword1 />
            </MenuLayout>
          </Route>
          <Route path="/password-reset">
            {(
              match: RouteChildrenProps<{
                email?: string;
                token?: string;
              }>
            ) => (
              <ResetPassword
                email={parseQuery(match.location.search).email}
                token={parseQuery(match.location.search).token}
              />
            )}
          </Route>
          <Route path="/forgot-password-sent">
            <ForgotPasswordSent />
          </Route>
          <Route path="/signup/select-plan">
            <SelectPlan />
          </Route>
          <Route path="/signup/add-venue">
            <AddVenue />
          </Route>

          <Route path="/create-venue" exact>
            <MenuLayout hideFooter>
              {user && userRoles === "Venue" ? (
                <VenueWizard />
              ) : (
                <Home />
              )}
            </MenuLayout>
          </Route>
          <Route path="/workspaces-list/:id">
            <MenuLayout>
              <AddWorkspace />
            </MenuLayout>
          </Route>
          <Route exact path="/create-workspace">
            <MenuLayout hideFooter>
              {userRoles === "Venue" ? <WorkspaceWizard /> : <Home />}
            </MenuLayout>
          </Route>
          <Route path="/edit-workspace/:id?" exact>
            <MenuLayout hideFooter>
              <WorkspaceWizard />
            </MenuLayout>
          </Route>
          <Route exact path="/edit-venue/:id">
            <MenuLayout hideFooter>
              <VenueWizard />
            </MenuLayout>
          </Route>
          <Route path="/register/profile-details">
            <MenuLayout hideFooter>
              <SignUp />
            </MenuLayout>
          </Route>
          <Route path="/register/employer">
            <MenuLayout hideFooter>
              <SignUp />
            </MenuLayout>
          </Route>
          <Route path="/register/customer">
            <MenuLayout hideFooter>
              <SignUp />
            </MenuLayout>
          </Route>
          <Route path="/register/select-type">
            <MenuLayout hideFooter>
              <SelectType history={history} />
            </MenuLayout>
          </Route>
          <Route path="/employer/invite/:id">
            <MenuLayout hideFooter>
              <SignUp />
            </MenuLayout>
          </Route>
          <Route path="/profile">
            {userRoles !== "Venue" && userRoles !== "Customer" ? (
              <EmployerDashboardLayout hideFooter>
                <SignUp />
              </EmployerDashboardLayout>
            ) : (
              <IndividualUserLayout hideFooter>
                <SignUp />
              </IndividualUserLayout>
            )}
          </Route>
          <Route path="/payment">
            <MenuLayout>
              <PaymentPge />
            </MenuLayout>
          </Route>
          <Route path="/my-paymentOptions">
            <IndividualUserLayout>
              <PaymentOptions />
            </IndividualUserLayout>
          </Route>
          <Route path="/my-contacts">
            <IndividualUserLayout>
              <AddressBook />
            </IndividualUserLayout>
          </Route>
          <Route path="/mapView">
            <MenuLayout>
              <FullMapView />
            </MenuLayout>
          </Route>

          <Route path="/FAQs">
            <MenuLayout>
              <FAQ />
            </MenuLayout>
          </Route>
          <Route path="/manage/availability">
            <IndividualUserLayout>
              <WorkspaceAvailability />
            </IndividualUserLayout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Layout);
