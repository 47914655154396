import React, { FC } from "react";
import Masonry from "react-masonry-css";
import { Field } from "redux-form";

import MultiCheckboxN from "../../../UI/MulticheckboxN";

interface Props {
  assets: any[];
  isService: boolean;
}

const WorkspaceAmenities: FC<Props> = ({ assets, isService }) => {
  return (
    <div className="md-amenities">
      {!isService ? (
        <div className="title">
          <p>Workspace Amenities</p>
        </div>
      ) : null}
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {Object.keys(assets).map((obj: any) => {
          return (
            <div className="amenities-item">
              <p className="title">{obj}</p>
              <div className="all-item">
                {isService ? (
                  <Field
                    type="checkbox"
                    name="amenities"
                    checkboxes={assets[obj].map((data: any) => ({
                      label: data.service,
                      value: data.id.toString()
                    }))}
                    component={MultiCheckboxN}
                  />
                ) : (
                  <Field
                    type="checkbox"
                    name="assets"
                    checkboxes={assets[obj].map((data: any) => ({
                      label: data.asset,
                      value: data.id.toString()
                    }))}
                    component={MultiCheckboxN}
                  />
                )}
              </div>
            </div>
          );
        })}
      </Masonry>
    </div>
  );
};

export default WorkspaceAmenities;
