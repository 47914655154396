import React from "react";
import "../Screens/Dashboard/accordion.sass";
import FAQComponent from "./Dashboard/FAQComponent";
import { toast } from "react-toastify";
import { EnumsApi } from "../API/EnumsApi";
import _ from "lodash";
interface MyProps {}

interface MyState {
  FAQs: any[];
  VenuesFAQ: any[];
  EmployerFAQ: any[];
  EmployeeFAQ: any[];
}
class FAQ extends React.Component<MyProps, MyState> {
  constructor(props: Readonly<MyProps>) {
    super(props);
    this.state = {
      FAQs: [],
      VenuesFAQ: [],
      EmployerFAQ: [],
      EmployeeFAQ: [],
    };
  }
  showSuccess = (text: any) => {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getFAQs();
  }
  getFAQs = async () => {
    const response = await EnumsApi.GetFAQs();
    let grouped = _.mapValues(_.groupBy(response.data, "category"), (asset) =>
      asset.map((ast) => _.omit(ast, "category"))
    );
    this.setState({
      VenuesFAQ: grouped.Venues,
      EmployeeFAQ: grouped.Employee,
      EmployerFAQ: grouped.Employers,
    });
    this.setState({ FAQs: response.data });
  };

  render() {
    return (
      <>


            <div className="container pd-0" style={{marginTop:50}}>
              <div className="dashboard">
                <div className="dashboard__top" style={{marginBottom:8}}>
                  <div className="dashboard__title2" style={{fontSize:22}}>Employee FAQs</div>
                </div>
              </div>

              <div>
                <div
                  style={{ marginBottom: 0, minHeight: 50 }}
                >
                  <div className="col-md-12">
                    <div>
                      <FAQComponent data={this.state.EmployeeFAQ} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pd-0">
              <div className="dashboard">
                <div className="dashboard__top" style={{marginBottom:8}}>
                  <div className="dashboard__title2" style={{fontSize:22}}>Employer FAQs</div>
                </div>
              </div>

              <div>
                <div
                  style={{ marginBottom: 0, minHeight: 50 }}
                >
                  <div className="col-md-12">
                    <div>
                      <FAQComponent data={this.state.EmployerFAQ} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pd-0">
              <div className="dashboard">
                <div className="dashboard__top" style={{marginBottom:8}}>
                  <div className="dashboard__title2" style={{fontSize:22}}>Venue FAQs</div>
                </div>
              </div>

              <div>
                <div
                  style={{ marginBottom: 0, minHeight: 50 }}
                >
                  <div className="col-md-12">
                    <div>
                      <FAQComponent data={this.state.VenuesFAQ} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

      </>
    );
  }
}
export default FAQ;
