import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { subscribeEffect } from "./Screens/Auth/Actions";
import { toast } from "react-toastify";
class Footer extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      error: "",
      isModalOpen: false
    };
  }
  handleEmail = value => {
    this.setState({ email: value });
  };
  isFormValid = () => {
    if (this.state.email != "") {
      if (
        !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
          this.state.email
        )
      ) {
        this.setState({ error: "Invalid email" });
        return true;
      }
    } else {
      this.setState({ error: "Email required" });
      return true;
    }
    this.setState({ error: "" });
    return false;
  };
  hideModal = () => {
    this.setState({ isModalOpen: false });
  };
  handleSubmit = event => {
    event.preventDefault();
    if (!this.isFormValid()) {
      let response = this.props.subscribeEffect({
        email: this.state.email
      });
      response
        .then(msg => {
          if (msg.success) {
            toast.success(msg.message);
            window.scrollTo(0, 0);
          }
        })
        .catch(msg => {
          toast.success(msg.message);
        });
    }
  };
  render() {
    return (
      <footer>
        <div className="container-fluid footer-color">
          <div className="container">
            <div className="row pt-10">
              <div className="col-lg-3 col-md-6 col-12">
                <h5 className="foot-color">SOLUTIONS</h5>
                <ul className="footer-list p-0 mt-3">
                  <li>
                    <a href="https://register.dorpee.com/individuals">
                      INDIVIDUALS
                    </a>
                  </li>
                  <li>
                    <a href="https://register.dorpee.com/employers">
                      TEAMS
                    </a>
                  </li>
                  <li>
                    <a href="https://register.dorpee.com/venues">
                      BECOME A VENUE{" "}
                    </a>
                  </li>{" "}
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <h5 className="foot-color">COMMUNITY</h5>
                <ul className="footer-list p-0 mt-3">
                  <li>
                    <a
                      href="http://dorpee-19902068.hs-sites.com/dorpee"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      BLOG
                    </a>
                  </li>
                  <li>
                    <Link to="/FAQs">FAQ's</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">PRIVACY</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <h5 className="foot-color">DORPEE</h5>
                <ul className="footer-list p-0 mt-3">
                  <li>
                    <Link to="/about-us">ABOUT</Link>
                  </li>
                  <li>
                    <a href="mailto:support@dorpee.com">CONTACT US</a>
                  </li>
                  <li>
                    <a href="https://dorpee.com/term-condition">
                      TERMS & CONDITIONS
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-12 icon-size">
                <a
                  className="in"
                  target="_blank"
                  href="https://www.linkedin.com/company/dorpee/about/"
                >
                  {" "}
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a
                  className="in"
                  target="_blank"
                  href="https://www.facebook.com/DorpeeWorkspaces/"
                >
                  <i className="fa-brands fa-facebook-square "></i>
                </a>
                <a
                  className="in"
                  target="_blank"
                  href="https://www.instagram.com/dorpeeworkspaces/"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a
                  className="in"
                  target="_blank"
                  href="https://twitter.com/DorpeeWorkspace"
                >
                  <i className="fa-brands fa-twitter-square"></i>
                </a>
                <a
                  className="in"
                  target="_blank"
                  href="https://www.youtube.com/channel/UC95P-oobkV6tTfT4600Ot9A"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
                <div className="col-12 p-0 my-4">
                  <a
                    href="https://apps.apple.com/us/app/id1517539910"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="img/homePageImg/appstore.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>{" "}
                </div>
                <div className="col-12 p-0">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.droid.dorpee"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="img/homePageImg/gplay.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.auth.user,
  auth: state.auth
});

const mapDispatchToProps = {
  subscribeEffect
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
