/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Dispatch } from "redux";
import { AppState } from "../../store";
import { AddEmployeeRoles, DashboardApi } from "../../API/DashboardApi";
import { VenueApi } from "../../API/VenueApi";
import { type } from "os";
import { resolve } from "dns";
import { toast } from "react-toastify";
import { setLoader } from "../Loader/Actions";
import { userTransaction } from "../Auth/Actions";
export function dashboardDataLoaded(data: any) {
  return <const>{
    type: "@@dashboard/DASHBOARD_DATA_LOADED",
    data,
  };
}

export function purchasedCreditsLoaded(data: any) {
  return <const>{
    type: "@@dashboard/DASHBOARD_PURCHASED_CREDITS",
    data,
  };
}

export function venueStatsLoaded(data: any) {
  return <const>{
    type: "@@dashboard/VENUES_STATS_LOADED",
    data,
  };
}
export function earnThisWeekLoaded(earn: number) {
  return <const>{
    type: "@@dashboard/SET_EARN_THIS_WEEK",
    earn,
  };
}

export function storeSearchPreferences(data: any) {
  return <const>{
    type: "@@dashboard/SEARCH_PREFERENCES_LOADED",
    data,
  };
}
export function storeCustomerAllCard(data: any) {
  return <const>{
    type: "@@dashboard/CUSTOMERS_ALL_CARDS_LOADED",
    data,
  };
}
export function storeCreditPrice(price: any) {
  return <const>{
    type: "@@dashboard/CREDIT_PRICE",
    price,
  };
}

export function storeInviteLists(data: any) {
  return <const>{
    type: "@@dashboard/CUSTOMERS_INVITES_LOADED",
    data,
  };
}
export function getTokenDashboard(token: any) {
  return <const>{
    type: "@@dashboard/TOKEN",
    token,
  };
}
export function getEmployerTransactions(token: any) {
  return <const>{
    type: "@@dashboard/TRANSACTIONS",
    token,
  };
}
export function employerRolesLoaded(data: any) {
  return <const>{
    type: "@@dashboard/EMPLOYER_ROLES_LOADED",
    data,
  };
}

export function handleRequirementsModal(data: boolean) {
  return <const>{
    type: "@@dashboard/REQUIRMENTS_MODAL",
    data,
  };
}
export function handleInfoModal(data: boolean) {
  return <const>{
    type: "@@dashboard/INFO_MODAL",
    data,
  };
}

export function handlePreferrencesModal(data: boolean) {
  return <const>{
    type: "@@dashboard/PREFERENCES_MODAL",
    data,
  };
}
export function handlePrivacyModal(data: boolean) {
  return <const>{
    type: "@@dashboard/PRIVACY_MODAL",
    data,
  };
}
export function handleAddressModal(data: boolean) {
  return <const>{
    type: "@@dashboard/ADDRESS_MODAL",
    data,
  };
}
export const getDashboardDataEffect =
  (venueToken?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetDashboardData(
      venueToken ? venueToken : getState().auth.token!
    );
    dispatch(dashboardDataLoaded(response.data));
  };

export const getDashboardPurchasedEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetDashboardPurchasedCredits(
      getState().auth.token!
    );
    dispatch(purchasedCreditsLoaded(response.data));
  };
export const getCreditPrice =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetCreditPrice();
    dispatch(storeCreditPrice(response.data));
  };
export const getSearchPreferencesEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetSearchPreferences(
      getState().auth.token!
    );
    dispatch(storeSearchPreferences(response.data));
  };

export const getCustomerCards =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetCustomerAllCards(
      getState().auth.token!
    );
    dispatch(storeCustomerAllCard(response.data));
  };

export const getInvitesList =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.GetInvitesList(getState().auth.token!);
    dispatch(storeInviteLists(response.data));
  };
export const getVenuesStatsEffect =
  (filter: any, venue_id?: any, venueToken?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.getVenuesStats(
      venueToken ? venueToken : getState().auth.token!,
      filter,
      venue_id
    );
    if (filter === "weekly") {
      dispatch(earnThisWeekLoaded(response.data.venues.total_bookings_revenue));
    }
    dispatch(venueStatsLoaded(response.data));
  };
export const getEmployeesTransactionEffect =
  (data?: any) => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await DashboardApi.getEmployerTransactionStats(
      getState().auth.token!,
      data
    );
    if (response.data) {
      dispatch(userTransaction(response.data));
    } else {
      toast.error("Oops... something went wrong.");
    }
  };
export const createEmployeeRoles =
  (data: AddEmployeeRoles, id?: any, Dispatch?: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await DashboardApi.postEmployerRoles(
      getState().auth.token!,
      data,
      id
    );
    if (response.data) {
      Dispatch(getEmployeesRoles());
      toast.success("Roles Added");
      dispatch(setLoader(false));
    } else {
      dispatch(setLoader(false));
      toast.error("Oops... something went wrong.");
    }
  };
export const deleteEmployeeRoles =
  (method: AddEmployeeRoles, id: any, Dispatch: any, modal: any) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await DashboardApi.postEmployerRoles(
      getState().auth.token!,
      method,
      id
    );
    if (response.success == "Deleted Successfully") {
      Dispatch(getEmployeesRoles());
      toast.success("Role Deleted");
      dispatch(setLoader(false));
      modal(false);
    } else {
      dispatch(setLoader(false));
      toast.error("Oops... something went wrong.");
    }
  };

export const getEmployeesRoles =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoader(true));
    const response = await DashboardApi.getEmployerRoles(
      getState().auth.token!
    );
    if (response.data) {
      dispatch(employerRolesLoaded(response.data));
      dispatch(setLoader(false));
    } else {
      toast.error("Oops... something went wrong.");
      dispatch(setLoader(false));
    }
  };

export type DashboardAction = ReturnType<
  | typeof dashboardDataLoaded
  | typeof venueStatsLoaded
  | typeof earnThisWeekLoaded
  | typeof storeSearchPreferences
  | typeof storeCustomerAllCard
  | typeof storeCreditPrice
  | typeof purchasedCreditsLoaded
  | typeof storeInviteLists
  | typeof getTokenDashboard
  | typeof getEmployerTransactions
  | typeof employerRolesLoaded
  | typeof handleRequirementsModal
  | typeof handleInfoModal
  | typeof handlePreferrencesModal
  | typeof handlePrivacyModal
  | typeof handleAddressModal
>;
