import React, { useState, FC, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import GoogleMapReact from "google-map-react";
import MapMarkerComponent from "./MapMarkerComponent";
import store, { AppState } from "../store";
import Footer from "../footer";
import {
  getPublicVenuesEffect,
  getNearMeVenuesEffect,
} from "./VenueWizard/Actions";
import { Venue, VenueApi } from "../API/VenueApi";
import { Helmet } from "react-helmet";
import { getVenuesEffect } from "./WorkspaceWizard/Actions";

const NearByVenuesMap: FC = () => {
  const [bound, setBounds] = useState({
    ne: { lat: 0, lng: 0 },
    nw: { lat: 0, lng: 0 },
    se: { lat: 0, lng: 0 },
    sw: { lat: 0, lng: 0 },
  });
  const { id } = useParams<{ id: string }>();
  const venues = useSelector((state: AppState) =>
    state.venueWizard.venues ? state.venueWizard.venues : []
  );

  const token = useSelector((state: AppState) => state.auth.token);
  const user = useSelector((state: AppState) => state.auth.user!);
  const venueNearMe = useSelector(
    (state: AppState) => state.venueWizard.VenuesNearMe.nearBy
  ).filter((v: any) => v.user_id !== user.id);

  const [visibleVenues, setVisibleVenues] = useState(venueNearMe);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapCenter, setCenter] = useState({
    lat: venueNearMe.length > 0 ? Number(venueNearMe[0].lat) : -37.813629,
    lng: venueNearMe.length > 0 ? Number(venueNearMe[0].lng) : 144.963058,
  });
  const [data, setData] = useState({});
  const [currentVenue, setCurrentVenue] = useState<Venue>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (venues && id && token) {
      let venue = venues.find((v) => {
        return v.id === Number(id);
      });
      setCurrentVenue(venue);
      setTimeout(() => {
        venue &&
          dispatch(getNearMeVenuesEffect(Number(venue.lat), Number(venue.lng)));
        getLatLng(venue);
      }, 5000);
    }
  }, [venues]);

  const getLatLng = async (venue: any) => {
    if (venue) {
      let response = await VenueApi.venuesNearMe(
        Number(venue.lat),
        Number(venue.lng)
      );
      let res = response.data.nearBy[0];
      if (res) {
        setCenter({
          lat: Number(res.lat),
          lng: Number(res.lng),
        });
        setVisibleVenues(response.data.nearBy);
      } else {
        setCenter({
          lat: Number(venue.lat),
          lng: Number(venue.lng),
        });
      }
    }
  };

  // useEffect(() => {
  //   venueNearMe &&
  //     setVisibleVenues(venueNearMe.filter((v: any) => v.user_id !== user.id));
  // }, []);
  useEffect(() => {
    dispatch(getVenuesEffect());
    dispatch(getPublicVenuesEffect());
  }, []);
  const changeMarkerPosition = (e: any) => {
    const { nw, se, ne, sw } = e;
    let filteredVenues = venueNearMe.filter((v) => {
      if (
        v.lat > se.lat &&
        sw.lat &&
        v.lat < ne.lat &&
        nw.lat &&
        v.lng > nw.lng &&
        sw.lng &&
        v.lng < ne.lng &&
        se.lng
      ) {
        return true;
      } else return false;
    });
    setVisibleVenues(filteredVenues);
  };
  const hideModal = () => {
    setIsModalOpen(false);
  };

  const openModal = (data: any) => {
    setData(data);
    setIsModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          title="Dorpee Near Venues"
          content="Dorpee-workspaces on demand"
        />
      </Helmet>
      <div className="mapNearVenue">
        <div style={{ height: "100vh" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk",
            }}
            center={{
              lat: mapCenter.lat,
              lng: mapCenter.lng,
            }}
            zoom={14}
            yesIWantToUseGoogleMapApiInternals={true}
            onChange={({ center, zoom, bounds, marginBounds }) => {
              setBounds(bounds);
              setCenter(center);
              // dispatch(getNearMeVenuesEffect(center.lat, center.lng));
              changeMarkerPosition(bounds);
            }}
          >
            {visibleVenues.map((marker: any) => {
              return (
                <MapMarkerComponent
                  lat={marker.lat}
                  lng={marker.lng}
                  key={marker.id}
                  marker={marker}
                  openModal={openModal}
                  isPrice
                />
              );
            })}

            {/* {currentVenue && (
              <MapMarkerComponent
                lat={currentVenue!!.lat}
                lng={currentVenue!!.lng}
                key={currentVenue!!.id}
                marker={currentVenue}
                openModal={openModal}
                isPrice
              />
            )} */}
          </GoogleMapReact>
        </div>
      </div>
      {/* <Footer isSubs isSticky></Footer> */}
    </>
  );
};
export default NearByVenuesMap;
