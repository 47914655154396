import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  getMonth,
  getDate,
  getFormated12hTimeNew
  // getFormated12hTime,
} from "../../helpers/DateHelpers";
import { AppState } from "../../store";
import { Venue, Workspace } from "../../API/VenueApi";

const BookingVenue = ({
  venue,
  firstPage,
  availabilityCheck,
  isLastStep
}: {
  venue?: Workspace;
  firstPage?: boolean;
  availabilityCheck?: any;
  isLastStep?: boolean;
}) => {
  const booking = useSelector(
    (state: AppState) => state.Booking.BookingFields
  );
  return (
    <div className="bookings-wrapper">
      <div className="bookings-left" style={{ marginBottom: 45 }}>
        <div className="bookings-img">
          <img
            src={venue && venue.images && venue.images[0]}
            alt=""
          />
        </div>
        <div className="bookings-info">
          <div className="bookings-info__heading">
            <span>{venue && venue.name}</span>
          </div>
          <div className="venue-rating__right">
            <span className={"creditCount"}>
              {venue && venue.price
                ? Math.ceil(parseFloat(venue.price) / 0.3)
                : 0}
            </span>
            <span className={"hour"}>hr</span>
          </div>
        </div>
      </div>
      {availabilityCheck ===
      "Please check minimum and maximum booking hours" ? (
        <span
          style={{
            color: "red",
            fontSize: "14px",
            position: "absolute",
            fontWeight: 400,
            top: 127
          }}
        >
          {availabilityCheck ===
            "Please check minimum and maximum booking hours" &&
            venue &&
            `Min ${venue.min_booking_hours} | Max ${venue.max_booking_hours} Booking Hours`}
        </span>
      ) : (
        <span
          style={{
            fontSize: "14px",
            position: "absolute",
            top: 127,
            fontWeight: 400,
            marginTop: "10px"
          }}
        >
          {venue && venue.min_booking_hours != null
            ? `Min ${venue.min_booking_hours} |`
            : ""}
          {venue && venue.max_booking_hours != null
            ? ` Max ${venue.max_booking_hours} Bookings Hours `
            : ""}
          {venue &&
          venue.cancellation_period &&
          venue.cancellation_period > 0 ? (
            <span>
              | Cancel at {venue.cancellation_period} Hour's Notice
            </span>
          ) : (
            <span>| Cancel anytime</span>
          )}
        </span>
      )}

      {!firstPage && (
        <div className="bookings-right">
          <div className="bookings-month">
            {getMonth(booking.start_date)}{" "}
            {getDate(booking.start_date)}
          </div>
          <div className="bookings-time">
            {getFormated12hTimeNew(booking.start_time)} -{" "}
            {getFormated12hTimeNew(booking.end_time)}
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingVenue;
