import { Dispatch } from "redux";
import { History } from "history";
import {
  AuthApi,
  User,
  isErrorResponse,
  UpdateUserDetails,
  SubscribeRequest,
  InviteRequest
} from "../../API/AuthApi";
import { AppState } from "../../store";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { setLoader } from "../Loader/Actions";

import { saveBasicSearchId } from "../VenueSearch/Actions";

export function signIn(token: string, user: User) {
  return <const>{
    type: "@@auth/SIGN_IN",
    token,
    user
  };
}

export function updateUser(user: User) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/UPDATE_USER",
    user
  };
}

export function signOut() {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/SIGN_OUT"
  };
}

export function isSuspended(field: boolean) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/IS_SUSPENDED",
    field
  };
}
export function LoggedIn(field: boolean) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <any>{
    type: "@@auth/IS_LOGGEDIN",
    field
  };
}

export function userRole(user: any) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/USER_ROLE",
    user
  };
}

export function userTransaction(user: any) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/TRANSACTIONS_USER",
    user
  };
}
export function selectType(field: any) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/SELECT_TYPE",
    field
  };
}
export function storeInvitedList(data: any) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/INVITED_LIST",
    data
  };
}
export function isLoading(check: boolean) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <const>{
    type: "@@auth/INVITE_LOADING",
    check
  };
}
export const changeUserRole =
  (history: any, role: any) => async (dispatch: Dispatch) => {
    dispatch(userRole(role));
    const roleUser = Cookies.get("roleUser");
    if (roleUser !== role) {
      toast.success(`Role changed to ${role}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    Cookies.set("roleUser", role);
    history.push("/dashboard");
    // window.location.href = "/dashboard";
  };
export const getProfileEffect =
  (venueToken?: string) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const token = venueToken ? venueToken : getState().auth.token;
    if (!token) {
      return;
    }
    const response = await AuthApi.GetProfile(token);
    let localRole = Cookies.get("roleUser");
    dispatch(updateUser(response.data));
    dispatch(
      userRole(localRole ? localRole : response.data.role[0].title)
    );
  };
export const postAccountDetailsEffect =
  (
    request: UpdateUserDetails,
    isEdit: boolean,
    state: any,
    history: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const token = getState().auth.token;
    if (!token) {
      return;
    }
    const response: any = await AuthApi.UpdateAccountDetails(
      request,
      token
    );
    if (response.data) {
      if (isEdit) {
        toast.success("Account details successfully updated.");
        if (state && state.prevLocation == "Success") {
          history.push({ pathname: "/success", state: "" });
        } else {
          history.push("/dashboard");
        }
      } else {
        toast.success("Account details successfully added.");
        if (state && state.prevLocation == "Success") {
          history.push({ pathname: "/success", state: "" });
        } else {
          history.push("/dashboard");
        }
      }
    } else {
      toast.success("Server Error");
    }
  };

export const forgotPasswordEffect =
  (email: string, history: History) =>
  async (
    dispatch: (dispatch: (dispatch: Dispatch) => void) => void,
    getState: () => AppState
  ) => {
    const response = await AuthApi.ForgotPassword({ email });
    return response;
  };

export const changePasswordEffect =
  (new_password: string, resetForm: any) =>
  async (dispatch: any, getState: () => AppState) => {
    dispatch(isLoading(true));
    const token = getState().auth.token!;
    const response = await AuthApi.ResetPasswordProfile(
      {
        new_password
      },
      token
    );
    if (response.success === "true") {
      dispatch(isLoading(false));
      toast.success("Password has been successfully changed");
      resetForm();
    } else {
      dispatch(isLoading(false));
      toast.error(response.message);
    }
    return response;
  };

export const inviteFriendAction =
  (request: InviteRequest, setForm: any) =>
  async (dispatch: any, getState: () => AppState) => {
    const token = getState().auth.token!;
    dispatch(isLoading(true));
    const response: any = await AuthApi.InviteFriend(request, token);
    if (response.success === "Invited Successfully") {
      dispatch(isLoading(false));
      dispatch(getInviteFriend());
      toast.success("Your friend has been successfully invited");
      setForm({
        name: "",
        email: ""
      });
    } else {
      dispatch(isLoading(false));
      toast.error(response.errors.email[0]);
    }
    return response;
  };
export const getInviteFriend =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const token = getState().auth.token!;
    const response: any = await AuthApi.getInviteFriendsList(token);
    if (response.data) {
      dispatch(storeInvitedList(response.data));
    }

    return response;
  };
export const resetPasswordEffect =
  (
    email: string,
    password: string,
    repeatPassword: string,
    token: string,
    history: History
  ) =>
  async (
    dispatch: (dispatch: (dispatch: Dispatch) => void) => void,
    getState: () => AppState
  ) => {
    const response = await AuthApi.ResetPassword({
      email: email,
      password: password,
      password_confirmation: repeatPassword,
      token: token
    });

    if (isErrorResponse(response)) {
      throw new Error(response.error);
    } else {
      history.push("/login");
    }
    return response;
  };

export const logInEffect =
  (
    email: string,
    password: string,
    keepLoggedIn: boolean,
    history: History
  ) =>
  async (
    dispatch: (dispatch: (dispatch: Dispatch<any>) => void) => void,
    getState: () => AppState
  ) => {
    const response = await AuthApi.LogIn({ email, password });

    if (isErrorResponse(response)) {
      throw new Error(response.error);
    } else {
      if (response.errors) {
        throw new Error(response.errors.email[0]);
      } else {
        dispatch(
          signInEffect(
            response.token,
            response.user,
            keepLoggedIn,
            response.user.status
          )
        );

        if (response.user.status !== "Suspended") {
          Cookies.set("roleUser", response.user.role[0].title);
          if (window.localStorage.getItem("gotoBooking")) {
            history.push(
              `/${window.localStorage.getItem("gotoBooking")}`
            );
          } else {
            dispatch(LoggedIn(true));
            const historyState = {
              pathname: "/",
              state: { login: true }
            };
            history.push(historyState);
          }
          // window.location.reload(false);
        }
      }
    }
  };

export const signInEffect =
  (
    token: string,
    user: User,
    keepLoggedIn: boolean = false,
    status?: any
  ) =>
  async (dispatch: Dispatch) => {
    if (status !== "Suspended") {
      if (keepLoggedIn) {
        Cookies.set("auth", token, { expires: 365 });
      } else {
        Cookies.set("auth", token);
      }
      dispatch(isSuspended(false));

      dispatch(signIn(token, user));
      window.localStorage.setItem("token", token);
    } else {
      dispatch(isSuspended(true));
    }
    dispatch(setLoader(false));
    // dispatch(userRole(user.role[0].title))
  };

export const getTransactionsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await AuthApi.GetProfileTransactions(
      state.auth.token!
    );
    if (response.data) {
      dispatch(userTransaction(response.data));
    } else toast.error("Oops something went wrong...");
  };
export const subscribeEffect =
  (request: SubscribeRequest) =>
  async (
    dispatch: (dispatch: (dispatch: Dispatch) => void) => void,
    getState: () => AppState
  ) => {
    let response = await AuthApi.Subscribe(
      new SubscribeRequest(request)
    );
    if (response) return response;
    // dispatch(userRole(user.role[0].title))
  };

export const signOutEffect =
  (history: History) => (dispatch: Dispatch) => {
    Cookies.remove("auth");
    dispatch(signOut());
    Cookies.remove("roleUser");
    history.push("/");
    Cookies.remove("isEditProfile");
    dispatch(saveBasicSearchId({ save_search_id: 0 }));
    window.localStorage.removeItem("venue_id");
    window.localStorage.removeItem("vid");
    window.localStorage.removeItem("token");
  };

export type AuthAction = ReturnType<
  | typeof signIn
  | typeof signOut
  | typeof updateUser
  | typeof userRole
  | typeof isSuspended
  | typeof userTransaction
  | typeof selectType
  | typeof storeInvitedList
  | typeof isLoading
  | typeof LoggedIn
>;
