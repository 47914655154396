/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "../../Screens/venue-style.css";
import { connect } from "react-redux";
import { locationUrl2 } from "../../common/googleMaps";
import Helmet from "react-helmet";
import { workspaceWizardSetStep } from "../WorkspaceWizard/Actions";
import WorkspacesItem from "../VenueWizard/Component/WorkspacesItem";
import { venueWizardSetStep } from "../VenueWizard/Actions";
import { getFormated12hTime } from "../../../src/helpers/DateHelpers";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import ShowWorkSpaces from "../../Screens/VenueWizard/showWorkspaces";
import Footer from "../../footer";
import ViewMoreButton from "../VenueWizard/Component/ViewMoreButton";
import { Carousel } from "react-responsive-carousel";
class PreviewVenuePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venue: {},
      services: []
    };
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    const venue = this.props.venues.find(v => v.id === Number(id));
    this.setState({ venue: venue });
    const grouped = _.mapValues(
      _.groupBy(venue && venue.services, "category"),
      asset => asset.map(ast => _.omit(ast, "category"))
    );
    this.setState({ services: grouped });
    window.scrollTo(0, 0);
  }
  componentDidUpdate(nextProps) {
    let id = this.props.match.params.id;
    if (nextProps.venues.length != this.props.venues.length) {
      const venue = this.props.venues.find(v => v.id === Number(id));
      this.setState({ venue: venue });
      const grouped = _.mapValues(
        _.groupBy(venue && venue.services, "category"),
        asset => asset.map(ast => _.omit(ast, "category"))
      );
      this.setState({ services: grouped });
    }
  }

  formatAddress = () => {
    return [
      this.state.venue.address,
      this.state.venue.suburb,
      this.state.venue.postcode,
      this.state.venue.state,
      this.state.venue.country
    ]
      .filter(v => !!v)
      .join(" ");
  };
  mapVenueType = id => {
    const venueType = this.props.venueTypes.find(
      t => t.id === parseInt(id)
    );
    return venueType ? venueType.type : "";
  };

  mapEnvironment = id => {
    const environment = this.props.environments.find(
      t => t.id === parseInt(id)
    );
    return environment ? environment.environment : "";
  };

  getAssets = id => {
    const asset = this.props.assets.find(a => a.id === parseInt(id));
    return asset ? asset.asset : "";
  };
  handleBack = e => {
    e.preventDefault();
    // this.props.history.push(`/workspaces-list/${this.props.match.params.id}`);
    this.props.history.goBack();
  };
  handlePublish = () => {
    window.localStorage.setItem("venue_name", this.state.venue.name);
    window.localStorage.setItem(
      "venue_id",
      this.props.match.params.id
    );
    this.props.history.push("/success");
  };
  render() {
    return (
      <div className="body">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Venue</title>
        </Helmet>
        <div className="page-main page-review page-special bodyTop">
          <div
            className="bl-buttons clearfix col-md-11"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="backButton">
              <a
                onClick={e => this.handleBack(e)}
                style={{
                  padding: "0px 50px 12px 64px",
                  position: "relative",
                  top: "-40px"
                }}
                className="btn btn-back2"
              >
                <span style={{ color: "#fff" }}>Back</span>
              </a>
            </div>
            <div className=" ">
              <a
                onClick={() => this.handlePublish()}
                style={{ position: "relative", top: -32 }}
                className={`btn  ${
                  !this.props.isLoading
                    ? "btn-publish"
                    : "btn--loading3"
                }`}
                disabled={this.props.isLoading}
              >
                <span>PUBLISH WORKSPACE from Venue</span>
              </a>
            </div>
          </div>
          <div className="main ">
            <div className="container-fluid">
              <div
                className="page-wrapper"
                style={{ marginTop: -25 }}
              >
                <div className="row">
                  <div className="col-lg-9 col-workspace">
                    <div className="bl-all-workspace">
                      <h1>
                        {this.state.venue && this.state.venue.name}{" "}
                        Workspaces
                      </h1>
                      <ul>
                        {this.state.venue &&
                          this.state.venue.spaces &&
                          this.state.venue.spaces.map(s => (
                            <WorkspacesItem
                              disabled
                              workspace={s}
                              status={this.state.venue.status}
                              venueName={this.state.venue.name}
                              vUserId={this.state.venue.user_id}
                            />
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-ads">
                    <div className="bl-ads-workspace">
                      <div className="title">
                        <p>
                          {this.state.venue && this.state.venue.name}
                        </p>
                      </div>
                      <div className="address">
                        <div
                          className="form-control"
                          style={{ border: "0px solid" }}
                        >
                          {this.state.venue && (
                            <a
                              className="venue-address__link"
                              target="_blank"
                              href={locationUrl2(
                                this.state.venue.address,
                                this.state.venue.suburb,
                                this.state.venue.state,
                                this.state.venue.postcode
                              )}
                            >
                              <span style={{ fontSize: 15 }}>
                                {this.state.venue &&
                                  this.formatAddress(
                                    this.state.venue
                                  )}
                              </span>
                            </a>
                          )}
                        </div>
                      </div>
                      {this.state.venue && this.state.venue.images ? (
                        <Carousel
                          dynamicHeight={true}
                          showThumbs={false}
                          showStatus={false}
                        >
                          {this.state.venue.images &&
                            this.state.venue.images.map(
                              (imageUrl, index) => (
                                <div
                                  className="thumbnail"
                                  // style={{ height: 402 }}
                                >
                                  <img
                                    src={imageUrl}
                                    alt={`${this.state.venue.name} ${index}`}
                                  />
                                </div>
                              )
                            )}
                        </Carousel>
                      ) : null}
                      <div className="md-widget-sidebar">
                        {this.state.venue &&
                        this.state.venue.bonus_offer ? (
                          <div
                            className="venue-bonus-text"
                            style={{ marginTop: 10 }}
                          >
                            <p>
                              {this.state.venue &&
                                this.state.venue.bonus_offer}
                            </p>
                          </div>
                        ) : null}
                        <div className="sub-title">
                          <p>About The Venue</p>
                        </div>
                        {this.state.venue && (
                          <ViewMoreButton
                            id="d"
                            description={this.state.venue.description}
                          />
                        )}
                      </div>
                      <div className="md-widget-sidebar">
                        <div className="sub-title">
                          <p>OPEN HOURS</p>
                        </div>
                        <div className="open-hours">
                          <ul>
                            {this.state.venue &&
                              this.state.venue.availability &&
                              this.state.venue.availability.availability.map(
                                item => {
                                  return (
                                    <li className="clearfix">
                                      <span className="is-title">
                                        {item.day}
                                      </span>
                                      <span className="hour">
                                        {this.state.venue &&
                                          getFormated12hTime(
                                            item.openingTime
                                          )}
                                        -{" "}
                                        {getFormated12hTime(
                                          this.state.venue &&
                                            item.closingTime
                                        )}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </div>
                      </div>
                      <div className="md-widget-sidebar">
                        <div className="sub-title">
                          <p>Services</p>
                        </div>
                        <ul className="smd-tag clearfix amenitiesStyle">
                          {Object.keys(this.state.services).map(
                            obj => {
                              return (
                                <>
                                  {this.state.services[obj].map(
                                    item => {
                                      return <li>{item.service}</li>;
                                    }
                                  )}
                                </>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer isSubs />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.auth.user,
  workspaces: state.workspaceWizard.detailsFields,
  isLoading: state.Loader.isLoading,
  detailsFields: state.workspaceWizard.detailsFields,
  venues: state.venueWizard.venues,
  environments: state.workspaceWizard.environments,
  venueTypes: state.workspaceWizard.venueTypes,
  assets: state.workspaceWizard.assets,
  venue_id: state.venueWizard.venueId,
  spaceId: state.workspaceWizard.workspaceId
});

const mapDispatchToProps = {
  workspaceWizardSetStep,
  venueWizardSetStep
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewVenuePage)
);
