import { createStore, combineReducers, applyMiddleware } from "redux";
import { wizardReducer, SignUpStep } from "./Screens/SignUp/Reducers";
import { authReducer } from "./Screens/Auth/Reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { User, Role } from "./API/AuthApi";
import { UserInfoFields } from "./Screens/SignUp/UserInfoForm";
import {
  Interest,
  VenueType,
  Environment,
  Service,
  Plan,
  Asset
} from "./API/EnumsApi";
import {
  VenueWizardStep,
  venueWizardReducer
} from "./Screens/VenueWizard/Reducers";
import { VenueDetailsFields } from "./Screens/VenueWizard/VenueDetailsForm";
import { VenueImagesFields } from "./Screens/VenueWizard/VenueImagesForm";
import { Venue, VenueData, OpenHours } from "./API/VenueApi";
import {
  DashboardData,
  DashboardPurchasedCredits
} from "./API/DashboardApi";
import { dashboardReducer } from "./Screens/Dashboard/Reducer";
import { venueSearchReducer } from "./Screens/VenueSearch/Reducer";
import { WorkspaceImagesFields } from "./Screens/WorkspaceWizard/WorkspaceImagesForm";
import {
  WorkspaceWizardStep,
  workspaceWizardReducer
} from "./Screens/WorkspaceWizard/Reducers";
import { WorkspaceDetailsFields } from "./Screens/WorkspaceWizard/CreateWorkspace";
import { Payment, paymentResponse } from "./API/PayementAPI";
import { paymentReducer } from "./Screens/PaymentWizard/Reducer";
import { loaderReducer } from "./Screens/Loader/Reducer";
import { BookingReducer } from "./Screens/Book/Booking/Reducer";
import { ContactsReducer } from "./Screens/Contact/Reducer";
import { BlocksSpaces } from "./API/WorkspaceApi";
import { ContactResponse } from "./API/DashboardApi";
import { Stats } from "./API/VenueApi";
import {
  Availability,
  AvailabilityTime,
  BookingFields,
  MyBookings,
  MyContact
} from "./API/BookingAPI";
import { PersonalSettingsFields } from "./Screens/SignUp/PersonalSettingsForm";
import Cookies from "js-cookie";
import { notificationsReducer } from "./Screens/Notification/Reducers";

const reducers = combineReducers({
  wizard: wizardReducer,
  auth: authReducer,
  form: formReducer,
  venueWizard: venueWizardReducer,
  workspaceWizard: workspaceWizardReducer,
  dashboard: dashboardReducer,
  venueSearch: venueSearchReducer,
  // payments:paymentReducer,
  Loader: loaderReducer,
  Booking: BookingReducer,
  Contacts: ContactsReducer,
  notifications: notificationsReducer
} as any);

// const auth = localStorage.getItem('auth') || sessionStorage.getItem('auth');

const token = Cookies.get("auth");
const store = createStore(
  reducers,
  token ? { auth: { token } } : {},
  composeWithDevTools(applyMiddleware(thunk))
);

export type AppState = {
  wizard: WizardState;
  auth: AuthState;
  venueWizard: VenueWizardState;
  workspaceWizard: WorkspaceWizardState;
  dashboard: DashboardState;
  notifications: NotificationsState;
  venueSearch: VenueSearchState;
  Loader: LoaderState;
  Booking: BookingState;
  Contacts: ContactState;
  free_space: boolean;
  form: any;
};
export type formState = {
  free_space: string;
};

export type VenueSearchState = {
  venues: Venue[];
  basic_search: any;
  searchId?: number;
  scrollState?: number;
};

export type DashboardState = {
  dashboardData: DashboardData;
  venuesStats: any;
  earnThisWeek: number;
  searchPreferences: any[];
  customerAllCard: PaymentOptionResponse;
  defaultCreditPrice: number;
  purchasedCredit: DashboardPurchasedCredits[];
  customerInvitation: any[];
  dashboardToken?: string;
  roles?: any[];
  requirements_modal?: Boolean;
  info_modal?: Boolean;
  pref_modal?: Boolean;
  privacy_modal?: Boolean;
  contacts_modal?: Boolean;
};

export type SearchPreference = {
  id?: number;
  user_id?: number;
  types?: string;
  interests?: string;
  environments?: string;
  services?: string;
  credits?: string;
  no_of_people?: string;
  suburb?: string;
  date?: string;
  start_time?: string;
  end_time?: string;
  created_at?: string;
  updated_at?: string;
};

export type PaymentOptionResponse = {
  id?: number;
  user_id?: number;
  name?: string;
  exp_month?: string;
  exp_year?: string;
  last4?: string;
  brand?: string;
  created_at?: string;
  updated_at?: string;
  message?: string;
};
export type AuthState = {
  token?: string;
  user?: User;
  userRoles?: any;
  isSuspended: boolean;
  userTransaction: any[];
  selectedType?: UserType;
  invitedList?: [];
  inviteLoading?: boolean;
  isLoggedIn?: any;
};

export type WizardState = {
  step: number;
  isDisabled: boolean;
  userType?: UserType;
  userInfoFields?: UserInfoFields;
  personalSettingsFields?: PersonalSettingsFields;
  interests: Interest[];
  plans: Plan[];
  isPlanSelected: boolean;
  selectedPlan: Plan;
  signUpStep: SignUpStep;
  emailExistMessage: any;
  promoStatus: any;
  haspromo: boolean;
  isOpen?: boolean;
  logo_deleted?: number;
  photo_deleted?: number;
  vaccination_certificate_deleted?: number;
};

export type WorkspaceWizardState = {
  step: WorkspaceWizardStep;
  isWorkspacePublished: boolean;
  detailsFields?: WorkspaceDetailsFields;
  imagesFields?: WorkspaceImagesFields;
  venues: Venue[];
  publicVenues: Venue[];
  venue?: Venue;
  venueTypes: VenueType[];
  services: Service[];
  environments: Environment[];
  isFreeSpacedSelected: boolean;
  assets: Asset[];
  workSpacesBlocks: BlocksSpaces[];
  singleSpaceAvailability: BlocksSpaces;
  suggested_price: Number;
  workspaceId: any;
  filterResult: Venue[];
  filterType: number[];
  filterEnv: number[];
  filterSort: any;
  isSpaceFlow?: boolean;
  isAutoFocus?: boolean;
  availability?: AvailabilityTime[];
  totalSpaces?: string;
};

export type VenueWizardState = {
  step: VenueWizardStep;
  isVenuePublished: boolean;
  detailsFields?: VenueDetailsFields;
  imagesFields?: VenueImagesFields;
  venues: Venue[];
  publicVenues: Venue[];
  venue?: Venue;
  venueTypes: VenueType[];
  services: Service[];
  environments: Environment[];
  interests: Interest[];
  VenuesNearMe: VenueData;
  venuesOpenHours: OpenHours[];
  availability: availabilityType[];
  isEditVenue: boolean;
  assets?: Asset[];
  venuesAmenities: string[];
  venueType: Number;
  venueBonus: string;
  detailsFieldsVenue: any;
  venueId: Number;
  types: VenueType[];
  isOpenHourSkip: boolean;
  workspacesForYou: Venue[];
};

export type availabilityType = {
  openingTime: string;
  closingTime: string;
  day: string;
};

export type LoaderState = {
  isLoading: boolean;
  isLoadingCredit?: boolean;
  isLoadingSearch?: boolean;
  isLoadingBooking_Venue?: boolean;
  promoMessage?: any;
};

export type BookingState = {
  BookingFields: BookingFields;
  myBookings: BookingFields[];
  singleBooking: any;
  myEmployers: User[];
  MyteamBooking: any[];
  MyorgEmployees?: any[];
};

export type ContactState = {
  myContacts: any[];
  myEmployeeContacts?: any[];
  myTeams: any[];
  contactIds: any[];
  invited_employees: any[];
  teamIds: any[];
  singleContact: any;
  error: any;
  updatedTeam: any;
};
export type NotificationsState = {
  data: notification;
};
export type notification = {
  unread?: any[];
  other?: any[];
};
export type BookingDetailsState = {
  BookingFields: BookingFields;
};
export enum UserType {
  Customer = "Customer",
  Employer = "employer",
  Venue = "venue"
}

export default store;
