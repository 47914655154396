/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {
  reduxForm,
  FormErrors,
  Field,
  WrappedFieldProps
} from "redux-form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../src/store";
import {
  getEnvironmentsEffect,
  getVenuesEffect,
  venueWizardSetStep,
  venueDetailsForm
} from "./Actions";
import LocationPicker, {
  Address
} from "../../UI/LocationPicker/LocationPicker";
import BasicField from "../../UI/BasicField/BasicField";
import LoaderModal from "../../Screens/Dashboard/modals/Loader";
import VenueSteps from "./VenueSteps";
import Footer from "../../footer";
import { VenueWizardStep } from "./Reducers";
import VenuePhotoUploader from "./Component/VenuePhotoUploader";
import { Helmet } from "react-helmet";
import VenueDraftButton from "./venueDraftComponent";
const normalizePhone = (value: any, previousValue: any) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums;
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + onlyNums.slice(3);
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + onlyNums.slice(3);
  }
  return (
    onlyNums.slice(0, 3) +
    onlyNums.slice(3, 6) +
    onlyNums.slice(6, 10)
  );
};
export type VenueDetailsFields = {
  name: string;
  location: Address;
  description: string;
  venue_type_id: any;
  email?: string;
  image1?: File;
  image2?: File;
  image3?: File;
  image4?: File;
  _method?: string;
  phone_number?: string;
  lead_time?: number;
  wifi_password?: string;
  wifi_name?: string;
  // vaccination_status?: string;
};

export const VenueBasicInfo = reduxForm<
  VenueDetailsFields,
  { isEdit: any; userEmail: any }
>({
  form: "venueDetails",
  destroyOnUnmount: false,

  onSubmit: (values, dispatch) => {
    dispatch(venueDetailsForm(values));
    dispatch(venueWizardSetStep(VenueWizardStep.OpenHours));
  },
  validate: values => {
    const errors: FormErrors<VenueDetailsFields> = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.location) {
      errors.location = "Required";
    }
    if (!values.email) {
      errors.email = "Please enter Venue Email Address";
    }

    if (
      values.location &&
      !values.location.country &&
      !values.location.suburb
    ) {
      errors.location = "Country is required";
    }
    if (values.location && !values.location.suburb) {
      errors.location = "Suburb is required";
    }
    if (values.location && !values.location.postalCode) {
      errors.location = "Postcode is required";
    }
    if (values.location && !values.location.state) {
      errors.location = "State is required";
    }
    if (values.location && !values.location.address) {
      errors.location = "Please enter a valid Address";
    }

    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.venue_type_id || values.venue_type_id === "0") {
      errors.venue_type_id = "Required";
    }
    if (
      !values.image1 &&
      !values.image2 &&
      !values.image3 &&
      !values.image4
    ) {
      errors._error = "Please upload at least one Venue image";
    }
    if (!values.phone_number) {
      errors.phone_number = "Phone number required";
    }
    // if (!values.vaccination_status) {
    //   values.vaccination_status = "Not Vaccinated";
    // }
    if (
      values.phone_number &&
      !/^\d{10}$/g.test(values.phone_number)
    ) {
      errors.phone_number = "Invalid phone number";
    }
    if (
      values.email &&
      !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,20})$/i.test(
        values.email
      )
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  }
})(props => {
  const dispatch = useDispatch();
  const venueDetailsFields = useSelector(
    (state: AppState) => state.venueWizard.detailsFieldsVenue
  );
  const types = useSelector(
    (state: AppState) => state.venueWizard.types
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getEnvironmentsEffect());
    dispatch(getVenuesEffect());
  }, []);

  useEffect(() => {
    if (Object.keys(venueDetailsFields).length > 0) {
      venueDetailsFields &&
        props.initialize({
          name: venueDetailsFields.name,
          location: venueDetailsFields.location,
          description: venueDetailsFields.description,
          image1:
            venueDetailsFields.image1 && venueDetailsFields.image1,
          image2:
            venueDetailsFields.image2 && venueDetailsFields.image2,
          image3:
            venueDetailsFields.image3 && venueDetailsFields.image3,
          image4:
            venueDetailsFields.image4 && venueDetailsFields.image4,
          venue_type_id: venueDetailsFields.venue_type_id,
          _method: venueDetailsFields._method,
          email: venueDetailsFields.email,
          phone_number: venueDetailsFields.phone_number,
          lead_time: venueDetailsFields.lead_time,
          wifi_name: venueDetailsFields.wifi_name,
          wifi_password: venueDetailsFields.wifi_password
          // vaccination_status: venueDetailsFields.vaccination_status
        });
    } else {
      props.initialize({
        email: props.userEmail
      });
    }
  }, [venueDetailsFields]);
  const history = useHistory();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - List Your Venue - 1</title>
      </Helmet>

      <form onSubmit={props.handleSubmit}>
        <div className="body">
          <div className="container containerAddSpace">
            <VenueSteps
              step={1}
              end={props.isEdit ? true : false}
            ></VenueSteps>
            <br />
            <div className="page-wrapper">
              <div className="bl-form-register">
                <h1 className="register-title">List Your Venue</h1>
                <p className="register-sub-title">
                  1. Venue Main Information
                </p>
                <div
                  className="images"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "-80px"
                  }}
                >
                  <img src="/img/ico-price.png" alt="" />
                </div>
                <div
                  className="form-register"
                  style={{ marginTop: "35px" }}
                >
                  <div className="md-workspace-form">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="all-item">
                          <div className="item">
                            <Field
                              name="venue_type_id"
                              component={(
                                props: WrappedFieldProps
                              ) => {
                                const displayError =
                                  props.meta.touched &&
                                  props.meta.error;
                                return (
                                  <>
                                    <div
                                      className={`input-container${
                                        displayError
                                          ? " input-container--invalid"
                                          : ""
                                      }`}
                                      style={{
                                        height: 45
                                      }}
                                    >
                                      <select
                                        style={{ padding: 5 }}
                                        {...props.input}
                                        onChange={e => {
                                          props.input.onChange(e);
                                        }}
                                      >
                                        <option value={0}>
                                          Select Venue Type
                                        </option>

                                        {types &&
                                          types
                                            .sort((type1, type2) =>
                                              type1.type > type2.type
                                                ? 1
                                                : -1
                                            )
                                            .map(v => (
                                              <option value={v.id}>
                                                {v.type}
                                              </option>
                                            ))}
                                      </select>
                                    </div>
                                    {displayError && (
                                      <div className="validation-error">
                                        {props.meta.error}
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div>
                          <div className="item">
                            <Field
                              name="name"
                              component={BasicField}
                              type="text"
                              placeholder="Venue Name"
                            />
                          </div>
                          <div className="details-wrapper">
                            <Field
                              name="location"
                              component={(
                                props: WrappedFieldProps
                              ) => {
                                const displayError =
                                  props.meta.touched &&
                                  props.meta.error;
                                return (
                                  <>
                                    <div
                                      className={`input-container${
                                        displayError
                                          ? " input-container--invalid"
                                          : ""
                                      }`}
                                    >
                                      <div className="input__icon">
                                        <div className="input__icon--location"></div>
                                      </div>
                                      <LocationPicker
                                        isAutoFocus
                                        input={{
                                          ...props.input,
                                          onBlur: () => {}
                                        }}
                                        value={props.input.value}
                                        onChange={address => {
                                          props.input.onChange(
                                            address
                                          );
                                        }}
                                      />
                                    </div>
                                    {displayError && (
                                      <div className="validation-error">
                                        {props.meta.error}
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div>
                          <div
                            className="item"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              position: "relative"
                            }}
                          >
                            <Field
                              name="description"
                              component={BasicField}
                              isTextArea={true}
                              placeholder="Venue Description"
                            />
                            <div
                              className="tooltip"
                              style={{
                                maxWidth: "5%",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                right: "-25px"
                              }}
                            >
                              {" "}
                              <img
                                style={{
                                  maxWidth: "20px",
                                  zIndex: 100
                                }}
                                src="/img/icons/info.png"
                              />
                              <span className="tooltiptext">
                                Venue Description: Introduce your
                                venue and describe why anyone would be
                                lucky to work from here.{" "}
                              </span>
                            </div>
                          </div>
                          <div
                            className="item"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              position: "relative"
                            }}
                          >
                            <div className="title">
                              <p>Lead Time </p>
                            </div>
                            <Field
                              className={"noButtons"}
                              name="lead_time"
                              component={BasicField}
                              type="number"
                              placeholder="Lead Time (In hours)"
                            />
                            <div
                              className="tooltip"
                              style={{
                                maxWidth: "5%",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                right: "-25px",
                                top: "30px"
                              }}
                            >
                              <img
                                style={{
                                  maxWidth: "20px",
                                  zIndex: 100
                                }}
                                src="/img/icons/info.png"
                              />
                              <span className="tooltiptext">
                                You can decide the time needed between
                                a Dorpee guest making or updating
                                their booking and the booking
                                beginning-for example you may need
                                1hours’ notice to prepare the space.
                              </span>
                            </div>
                          </div>
                          <div className="item">
                            <div className="title">
                              <p>Contact Email Address</p>
                            </div>
                            <Field
                              name="email"
                              component={BasicField}
                              type="text"
                              placeholder="Contact Email"
                            />
                          </div>
                          <div className="item">
                            <div className="title">
                              <p>Contact Phone Number</p>
                            </div>
                            <Field
                              name="phone_number"
                              component={BasicField}
                              type="text"
                              placeholder="Contact Phone Number"
                              normalize={normalizePhone}
                            />
                          </div>
                          {/* <div className="item">
                            <div className="title">
                              <p>Vaccination Requirement</p>
                            </div>
                            <div>
                              {" "}
                              <div
                                className="create_vacc"
                                style={{
                                  display: "flex",
                                  position: "relative",
                                  alignItems: "center"
                                }}
                              >
                                {" "}
                                <Field
                                  name="vaccination_status"
                                  component="select"
                                  className="form-control"
                                  style={{
                                    height: "45px",
                                    border: "1px solid #a8c8d4"
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Vaccination Status
                                  </option>
                                  {[
                                    "Not Vaccinated",
                                    "Partially Vaccinated",
                                    "Fully Vaccinated"
                                  ].map((data, i) => {
                                    return (
                                      <option value={data} key={i}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <div
                                  className="tooltip"
                                  style={{
                                    maxWidth: "5%",
                                    border: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "absolute",
                                    right: "-25px"
                                  }}
                                >
                                  <img
                                    style={{
                                      maxWidth: "20px",
                                      zIndex: 100
                                    }}
                                    src="/img/icons/info.png"
                                  />
                                  <span className="tooltiptext">
                                    If you have vaccination
                                    requirements for your venue's
                                    guests, you can outline these
                                    here.
                                  </span>
                                </div>
                              </div>{" "}
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="md-sidebar">
                          <div className="name">
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 400
                              }}
                            >
                              <b>HINT:</b> Here you can add
                              information that relates to the entire
                              venue rather than to aparticular
                              workspace. For example, venue name,
                              description of the building,wi-fi
                              availabilityor F&B options.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <VenuePhotoUploader {...props}></VenuePhotoUploader>
                </div>
              </div>
              <div className="bl-buttons">
                <div className="row">
                  <div className="col-md-4">
                    <a
                      onClick={() => history.goBack()}
                      className="btn btn-back"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                  <div className="col-md-8">
                    <button
                      className={"btn btn-next"}
                      style={{ paddingLeft: 0 }}
                    >
                      Set Venue Open Hours
                    </button>
                  </div>
                </div>
              </div>
              <VenueDraftButton />
            </div>
          </div>
          {isLoading ? <LoaderModal /> : null}
          <Footer
          // isSubs
          ></Footer>
        </div>
      </form>
    </>
  );
});
export default VenueBasicInfo;
