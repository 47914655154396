import React, { Component } from "react";
import { Field, WrappedFieldProps } from "redux-form";
import { UserType } from "../../../store";
interface Props {
  selectedType: any;
  handleCompanyName: (value: boolean) => void;
}
interface myStateProps {
  selectedType: string;
}
export default class UserSelectType extends Component<Props, myStateProps> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      selectedType: "",
    };
  }

  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Field
          name="userType"
          component={(props: WrappedFieldProps) => {
            const { input } = props;
            const displayError = props.meta.touched && props.meta.error;
            let userType: UserType | undefined;
            if (input.value) {
              userType = input.value as UserType;
              this.props.handleCompanyName(
                userType === UserType.Employer ? true : false
              );
              props.input.onChange(
                userType === UserType.Employer
                  ? UserType.Employer
                  : UserType.Customer
              );
            }
            const onChange = (value: UserType) => {
              value === userType
                ? props.input.onChange(undefined)
                : props.input.onChange(value);
            };

            return (
              <div className="user-types col-md-8">
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", maxWidth: "45%", fontWeight: 400 }}>
                  <div
                    className={`user-type iconType`}
                    style={{
                      backgroundColor:
                        userType === UserType.Customer ? " #6762B9" : "",
                    }}
                    onClick={() => {
                      this.props.handleCompanyName(false);
                      onChange(UserType.Customer);
                    }}
                  >
                    <div className="user-type__icon">
                      <img
                        style={{ width: "60%" }}
                        src={
                          userType === UserType.Customer
                            ? "/img/icons/customer-active.svg"
                            : "/img/icons/customer.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      className="user-type__title"
                      style={{
                        color: userType === UserType.Customer ? " #fff" : "", fontWeight: 400
                      }}
                    >
                      Individual
                    </div>
                  </div>
                  <p>I’m using Dorpee for myself.</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", maxWidth: "45%", fontWeight: 400 }}>
                  <div
                    className={`user-type iconType`}
                    style={{
                      backgroundColor:
                        userType === UserType.Employer ? " #6762B9" : "",
                    }}
                    onClick={() => {
                      this.props.handleCompanyName(true);
                      onChange(UserType.Employer);
                    }}
                  >
                    <div className="user-type__icon">
                      <img
                        style={{ width: "84%" }}
                        src={
                          userType === UserType.Employer
                            ? "/img/icons/employer-active.svg"
                            : "/img/icons/employer.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div
                      className="user-type__title"
                      style={{
                        color: userType === UserType.Employer ? " #fff" : "", fontWeight: 400
                      }}
                    >
                      Employer
                    </div>
                  </div>
                  <p>I’m using Dorpee to manage my team.</p>
                </div>
                {displayError && (
                  <div
                    style={{ position: "absolute", top: "100%" }}
                    className="validation-error"
                  >
                    {props.meta.error}
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
    );
  }
}
