import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";

import { getPublicVenuesEffect } from "./VenueWizard/Actions";
import MapMarkerComponent from "./MapMarkerComponent";
import MapMarkerComponentNew from "./MapMarkerNew";
import { VenueApi } from "../API/VenueApi";

const FullMapView = () => {
  const [bound, setBounds] = useState({
    ne: { lat: 0, lng: 0 },
    nw: { lat: 0, lng: 0 },
    se: { lat: 0, lng: 0 },
    sw: { lat: 0, lng: 0 }
  });

  const allvenues = useSelector(state =>
    state.venueWizard.publicVenues
      ? state.venueWizard.publicVenues
      : []
  );

  const [visibleVenues, setVisibleVenues] = useState();
  const [venuesToShow, setVenuesToShow] = useState();
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [zoom, setZoom] = useState(14);
  const [simpleView, setSimpleView] = useState(true);
  const [data, setData] = useState();

  const [filterByPrice, setFilterByPrice] = useState([
    { id: 1, value: "0-50" },
    { id: 2, value: "51-171" },
    { id: 3, value: "171-300" },
    { id: 4, value: "300>" }
  ]);

  const [mapCenter, setCenter] = useState({
    lat: allvenues.length > 0 ? Number(allvenues[0].lat) : -37.813629,
    lng: allvenues.length > 0 ? Number(allvenues[0].lng) : 144.963058
  });

  const [mapCenterNew, setCenterNew] = useState();

  const [nearBy, setNearBy] = useState(false);
  useEffect(() => {
    if (allvenues.length > 0) {
      setVisibleVenues(allvenues);
      setVenuesToShow(allvenues);
    }
  }, [allvenues]);

  window.onbeforeunload = function () {
    window.onunload = function () {
      if (window.localStorage.isMySessionActive !== "true") {
        localStorage.removeItem("venues");
      }
      window.localStorage.isMySessionActive = "false";
    };
    return undefined;
  };
  window.onload = function () {
    window.localStorage.isMySessionActive = "true";
  };

  useEffect(() => {
    let savedVenues = JSON.parse(localStorage.getItem("venues"));
    setVenuesToShow(savedVenues);
    setVisibleVenues(savedVenues);

    if (
      (savedVenues && savedVenues.length == 1) ||
      (savedVenues && savedVenues.length < 5)
    ) {
      setZoom(14);
      setCenterNew({
        lat: Number(savedVenues[0].lat),
        lng: Number(savedVenues[0].lng)
      });
    } else if (savedVenues && savedVenues.length >= 5) {
      setCenterNew({
        lat: Number(savedVenues[0].lat),
        lng: Number(savedVenues[0].lng)
      });
      setZoom(5);
    }
  }, []);

  // useEffect(() => {
  //   if (mapCenterNew) {
  //     setCenter(mapCenterNew);
  //   }
  // }, [mapCenterChange]);
  // useEffect(() => {
  //   let savedVenues = JSON.parse(localStorage.getItem("venues"));
  //   setVenuesToShow(savedVenues);
  //   setVisibleVenues(savedVenues);
  //   if (savedVenues && savedVenues.length > 0) {
  //     setCenter({
  //       lat: Number(savedVenues[0].lat),
  //       lng: Number(savedVenues[0].lng)
  //     });
  //   }
  // }, [localStorage.getItem("venues")]);

  // useEffect(() => {
  //   setCenter({
  //     lat: Number(mapCenterNew.lat),
  //     lng: Number(mapCenterNew.lng)
  //   });
  // }, [mapCenterNew]);

  const posError = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async res => {
          if (res.state === "denied") {
            let response = await VenueApi.venuesNearMe(
              -37.813629,
              144.963058
            );
            let res = response.data.nearBy[0];
            if (res) {
              setVisibleVenues(response.data.nearBy);
              setVenuesToShow(response.data.nearBy);
            }
            setNearBy(false);
            alert("Enable Location Permission for Dorpee");
          }
        });
    }
  };

  const showPosition = async position => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    setCenter({
      lat: Number(lat),
      lng: Number(lng)
    });
    setCenterNew({
      lat: Number(lat),
      lng: Number(lng)
    });
    let response = await VenueApi.venuesNearMe(
      Number(lat),
      Number(lng)
    );
    let res = response.data.nearBy[0];
    if (res) {
      if (res.data.nearBy.length > 0) {
        setVisibleVenues(response.data.nearBy);
      } else {
        setVisibleVenues(response.data.featured);
      }
    }
  };

  const changeMarkerPosition = e => {
    const { nw, se, ne, sw } = e;
    if (filterByPrice.length > 0) {
      return;
    } else {
      let filteredVenues = visibleVenues.filter(v => {
        if (
          v.lat > se.lat &&
          sw.lat &&
          v.lat < ne.lat &&
          nw.lat &&
          v.lng > nw.lng &&
          sw.lng &&
          v.lng < ne.lng &&
          se.lng
        ) {
          return true;
        } else return false;
      });
      setVenuesToShow(filteredVenues);
    }
  };

  const openModal = data => {
    setData(data);
  };

  const getNearVenues = () => {
    if (nearBy != true) {
      setNearBy(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          showPosition,
          posError
        );
      }
    } else {
      setNearBy(false);
    }
  };

  const togglePrice = price => {
    const newFilterBy =
      filterByPrice.indexOf(price.id) >= 0
        ? filterByPrice.filter(i => i !== price.id)
        : [price.id];
    setFilterByPrice(newFilterBy);
    setIsFilterOpened(false);
    filter(
      newFilterBy.length == 0 ? [] : price,
      newFilterBy.length == 0 ? false : true
    );
  };

  const filter = (priceRange, check) => {
    let range =
      priceRange && check > 0 && priceRange.id != 4
        ? priceRange.value.split("-")
        : priceRange && priceRange.id == 4
        ? priceRange.value.split(">")
        : [0, 50];

    let newVenues = visibleVenues.filter(v => {
      let price = !isNaN(Math.ceil(parseInt(v.lowest_price) / 0.3))
        ? Math.ceil(parseInt(v.lowest_price) / 0.3)
        : 0;
      if (check) {
        if (
          price > Number(range[0]) &&
          (range[1] != "" ? price < Number(range[1]) : true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    if (newVenues.length > 0) {
      // setVisibleVenues(newVenues);
      setVenuesToShow(newVenues);
      if (newVenues.length > 0) {
        setCenterNew({
          lat: Number(newVenues[0].lat),
          lng: Number(newVenues[0].lng)
        });
        setCenter({
          lat: Number(newVenues[0].lat),
          lng: Number(newVenues[0].lng)
        });
      }
    } else {
      // setVisibleVenues([]);
      setVenuesToShow([]);
    }
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - All Venues</title>
      </Helmet>
      <div className="mapNearVenue">
        <div className="map_filters">
          <button
            className="map_filter_button"
            onClick={getNearVenues}
            style={{
              background: nearBy ? "black" : "",
              color: nearBy ? "white" : ""
            }}
          >
            Venues Near Me
          </button>
          <div style={{ position: "relative" }}>
            {/* <button
              onClick={() => {
                setIsFilterOpened(!isFilterOpened);
              }}
              className="map_filter_button"
              style={{
                background:
                  filterByPrice.length === 1 ? "Black" : "white",
                color: filterByPrice.length === 1 ? "white" : ""
              }}
            >
              <img
                className="wizard__btn--img-left"
                style={{
                  background:
                    filterByPrice.length === 1 ? "" : "white"
                }}
                src="/img/icons/filter.svg"
                alt=""
              />
              FILTER
            </button>
            {isFilterOpened && ( */}
            {/* <div
              className="filter-popup filter-popup--filter"
              style={{ left: "-90px", top: "40px" }}
            > */}
            <div className="tags-section">
              {/* <div
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    display: "block"
                  }}
                >
                  Price Filter
                </div>
                <br /> */}
              {[
                { id: 1, value: "0-50", color: "#42c0d6" },
                { id: 2, value: "51-171", color: "#4956a2" },
                { id: 3, value: "171-300", color: "#ffbe00" },
                { id: 4, value: "300>", color: "#f5597f" }
              ].map(t => (
                <div
                  key={t.id}
                  onClick={() => togglePrice(t)}
                  className={`tags-item${
                    filterByPrice.indexOf(t.id) >= 0 ? " active" : ""
                  }`}
                  style={{
                    background:
                      filterByPrice.indexOf(t.id) >= 0
                        ? `${t.color}`
                        : `${t.color}45`,
                    padding: "8px",
                    margin: "0px 10px 0px 0px"
                  }}
                >
                  <p>{t.value}</p>
                </div>
              ))}
            </div>
            {/* </div> */}
            {/* )} */}
          </div>
          <button
            className="map_filter_button"
            onClick={() => {
              setSimpleView(!simpleView);
            }}
            style={{
              background: !simpleView ? "black" : "",
              color: !simpleView ? "white" : ""
            }}
          >
            Toggle View
          </button>
        </div>
        <div style={{ height: "80vh" }}>
          {simpleView ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk"
              }}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals={true}
              center={mapCenterNew ? mapCenterNew : mapCenter}
              onChange={({ center, zoom, bounds, marginBounds }) => {
                setBounds(bounds);
                if (
                  center.lat != mapCenter.lat ||
                  center.lng != mapCenter.lng
                ) {
                  setCenter(center);
                  setCenterNew(center);
                }
                changeMarkerPosition(bounds);
                // dispatch(getNearMeVenuesEffect(center.lat, center.lng));
              }}
              options={maps => ({
                zoomControl: true,
                zoomControlOptions: {
                  position: maps.ControlPosition.TOP_LEFT
                },
                fullscreenControl: false
              })}
            >
              {venuesToShow &&
                venuesToShow.map(marker => {
                  return (
                    <MapMarkerComponent
                      lat={marker.lat}
                      lng={marker.lng}
                      key={marker.id}
                      marker={marker}
                      openModal={openModal}
                      isPrice
                    />
                  );
                })}
            </GoogleMapReact>
          ) : (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk"
              }}
              center={mapCenterNew ? mapCenterNew : mapCenter}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals={true}
              onChange={({ center, zoom, bounds, marginBounds }) => {
                setBounds(bounds);
                if (
                  center.lat != mapCenter.lat ||
                  center.lng != mapCenter.lng
                ) {
                  setCenter(center);
                  setCenterNew(center);
                }
                changeMarkerPosition(bounds);
                // dispatch(getNearMeVenuesEffect(center.lat, center.lng));
              }}
              options={maps => ({
                zoomControl: true,
                zoomControlOptions: {
                  position: maps.ControlPosition.TOP_LEFT
                },
                fullscreenControl: false
              })}
            >
              {venuesToShow &&
                venuesToShow.map(marker => {
                  return (
                    <MapMarkerComponentNew
                      lat={marker.lat}
                      lng={marker.lng}
                      key={marker.id}
                      marker={marker}
                      openModal={openModal}
                      isPrice
                    />
                  );
                })}
            </GoogleMapReact>
          )}
        </div>
      </div>
    </div>
  );
};
export default FullMapView;
