/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  reduxForm,
  Field,
  FormErrors,
  WrappedFieldProps
} from "redux-form";
import { UserType } from "../../store";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { completeSignUpEffect } from "./Actions";
import { Form } from "semantic-ui-react";
import { AppState } from "../../store";
import UserSelectType from "../SignUp/Component/UserSelectType";

import { useHistory } from "react-router-dom";
import { selectType } from "../Auth/Actions";
import Footer from "../../footer";

export type SelecTypeFields = {
  userType: UserType;
};

export const SelectType = reduxForm<
  SelecTypeFields,
  {
    history: any;
  }
>({
  form: "userInfo",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (formFields, dispatch, props) => {
    dispatch(selectType(formFields.userType));
    // props.history.push("/register/select-type")
  },

  validate: (values, props) => {
    const errors: FormErrors<SelecTypeFields> = {};
    if (values.userType !== UserType.Customer) {
      if (!values.userType) {
        errors.userType = "Required";
      }
    }

    return errors;
  }
})(props => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );

  const userType = useSelector(
    (state: AppState) => state.wizard.userType
  );

  const history = useHistory();
  const [selectedType, setSelectType] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCompanyName = (value: boolean) => {
    setSelectType(value);
  };

  const handleSubmit = (values: any) => {
    props.handleSubmit(values);
    selectedType
      ? history.push("/register/employer")
      : history.push("/register/customer");
  };

  const handleSignUpForm = async () => {
    await dispatch(completeSignUpEffect(history));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-register as user or employer"
        />
        <title>Dorpee - select user type</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <div className="body create" style={{ height: "45rem" }}>
          <div
            className="container containerAddSpace "
            style={{ width: "50rem" }}
          >
            <div className="page-wrapper" style={{ marginTop: 25 }}>
              <div
                className="registerTitle"
                style={{ marginBottom: 20 }}
              >
                <h1
                  className="register-title"
                  style={{ textAlign: "center", left: "0" }}
                >
                  Select Type
                </h1>
              </div>

              <UserSelectType
                selectedType={userType}
                handleCompanyName={handleCompanyName}
              />
              <div
                className="bl-time"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="bl-buttons"
                  style={{ marginBottom: 30, width: "20rem" }}
                >
                  <div className="col-12">
                    <button
                      type="submit"
                      className={`btn ${
                        isLoading
                          ? " btn--loadingNew"
                          : "btn-successBtnRegister"
                      } `}
                      style={{
                        background: isLoading
                          ? ""
                          : "url(/img/ico-next.png) no-repeat right 330px center #f5597f"
                      }}
                      disabled={isLoading}
                    >
                      {" "}
                      {!isLoading && (
                        <span style={{ padding: 2 }}>Next</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Footer
      // isSubs
      ></Footer>
    </>
  );
});
