import React from "react";
import { Pie } from "react-chartjs-2";

class SpaceBookingsPie_Emp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: ["Bar/Cellar", "Cafe", "Hotel", "Office", "Others"],
      datasets: [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          options: {
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.pie.venue_types["Bar/Cellar"] !==
        nextProps.pie.venue_types["Bar/Cellar"] ||
      nextProps.pie.venue_types.Cafe !==
        this.props.pie.venue_types.Cafe ||
      nextProps.pie.venue_types.Hotel !==
        this.props.pie.venue_types.Hotel ||
      nextProps.pie.venue_types.Office !==
        this.props.pie.venue_types.Office ||
      nextProps.pie.venue_types.Others !==
        this.props.pie.venue_types.Others
    ) {
      const datasets = [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
            "#CCCCFF"
          ],
          options: {
            legend: {
              position: "bottom"
            }
          }
        }
      ];

      this.setState({
        datasets
      });
    }
  }

  getData() {
    let values = [];
    let data = this.props.pie.venue_types;
    ["Bar/Cellar", "Cafe", "Hotel", "Office", "Others"].map(label => {
      for (let i in data) {
        if (i == label) {
          values.push(data[i]);
          return;
        }
      }
    });

    return values;
  }

  render() {
    let options = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              data["labels"][tooltipItem["index"]] +
              ": " +
              data["datasets"][0]["data"][tooltipItem["index"]] +
              "%"
            );
          }
        }
      }
    };

    return (
      <div className="venue-chart-small">
        <Pie
          height={250}
          data={this.state}
          options={options}
          legend={{
            labels: {
              boxWidth: 15
            },
            position: "bottom"
          }}
        />
      </div>
    );
  }
}

export default SpaceBookingsPie_Emp;
