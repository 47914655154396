import React, { Component, useselector } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import { Field } from "redux-form";
import BasicField from "../UI/BasicField/BasicField";
import {
  createPaymentEffect,
  createAddCardEffect
} from "./PaymentWizard/Actions";
import { setLoader } from "./Loader/Actions";
import { withRouter } from "react-router-dom";
import { AppState, AuthState } from "../store";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class CheckoutForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cardName: "",
      card_error: false
    };
  }
  // componentDidMount() {
  //   this.props.userName &&
  //     this.setState({ cardName: this.props.userName.first_name });
  // }

  handleSubmit = async ev => {
    ev.preventDefault();
    this.props.setLoader(true);
    window.localStorage.removeItem("addNewCard");
    const { history } = this.props;
    let token;
    let plan = this.props.selectedPlan;
    if (this.state.cardName) {
      token = await this.props.stripe.createToken({
        name: this.state.cardName
      });
      token = token.token;
      // plan = token.selectedPlan;
    } else {
      this.props.setLoader(false);

      toast.error("Name on card is required", {
        position: toast.POSITION.TOP_RIGHT
      });
      return null;
    }
    if (this.state.card_error) {
      this.props.setLoader(false);

      toast.error("Please add valid card number", {
        position: toast.POSITION.TOP_RIGHT
      });
      return null;
    }
    if (token) {
      this.props.setLoader(false);

      const id =
        this.props.selectedPlan.id == 0
          ? this.props.plan_id.plan_id
          : this.props.selectedPlan.id;
      !this.props.isPlanSelected
        ? this.props.createAddCardEffect(
            { name: this.state.cardName, stripe_token: token.id },
            history
          )
        : this.props.createPaymentEffect(
            { plan_id: id, stripe_token: token.id },
            history
          );
    } else {
      this.props.setLoader(false);
      toast.error("Something Went wrong, Try again.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  handleCardName = ev => {
    this.setState({ cardName: ev.target.value });
  };
  handleChange = async event => {
    if (event.complete === false) {
      this.setState({ card_error: true });
    } else {
      this.setState({ card_error: false });
    }
  };
  render() {
    // let plan_id = useSelector((state) => state.auth.user);
    return (
      <>
        <form>
          <div className="checkout">
            <div className="filter-block">
              <Field
                className="pl-12"
                onChange={ev => this.handleCardName(ev)}
                value={this.state.cardName}
                name="card_name"
                component={BasicField}
                type="text"
                placeholder="Card Name"
              />
            </div>
            <div
              className="filter-block"
              style={{
                border: "1px solid #ccc",
                padding: 12,
                borderRadius: 5
              }}
            >
              <CardElement
                hidePostalCode={true}
                onChange={event => this.handleChange(event)}
              />
            </div>
            {window.localStorage.getItem(
              "addNewCard"
            ) ? null : !window.location.pathname.includes(
                "book/3/"
              ) ? (
              <div className="credit-price__row">
                <span>Price:</span>
                {window.localStorage.getItem("credit") ? (
                  <div className="credit-price">
                    $ {window.localStorage.getItem("credit")}{" "}
                  </div>
                ) : (
                  <div className="credit-price">
                    ${" "}
                    {this.props.selectedPlan.price == ""
                      ? this.props.plan_id &&
                        this.props.plan_id.price != null &&
                        this.props.plan_id.plan.price
                      : this.props.selectedPlan.price}
                  </div>
                )}
              </div>
            ) : null}
            <div className="credit-price__btn">
              <button
                onClick={this.handleSubmit}
                disabled={this.props.isLoading}
                className={`btn  wizard__btn mb-0${
                  this.props.isLoading ? " disabled" : ""
                }${
                  this.props.isLoading ? " btn--loading" : " btn-blue"
                }`}
              >
                {window.location.pathname == "/payment"
                  ? `${"PAY WITH STRIPE" || "Next"}`
                  : `${"Add New Payment Method" || "Next"}`}
                <img src="/img/icons/arrow-forward.svg" alt="" />
                <img
                  className="wizard__btn--img-left"
                  src="https://dorpee.herokuapp.com/static/img/icons/stripe.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedPlan: state.wizard.selectedPlan,
  isLoading: state.Loader.isLoading,
  plan_id: state.auth.user,
  isPlanSelected: state.wizard.isPlanSelected,
  userName: state.auth.user
});

const mapDispatchToProps = {
  createPaymentEffect,
  createAddCardEffect,
  setLoader
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectStripe(CheckoutForm))
);
