import React from "react";
import { toast } from "react-toastify";

import { Modal } from "../../../UI/Modal";

class AddEmployerRole extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: "",
        role_name: "",
        monthly_access: "",
        hourly_access: "",
        unlimited_hourly_access: false,
        unlimited_monthly_access: false
      },
      errors: {
        role_name: false,
        monthly_access: false,
        hourly_access: false
      }
    };
  }
  componentDidMount() {
    if (this.props.roleDetails) {
      this.setState({ form: this.props.roleDetails });
    }
  }

  changeForm(field, e) {
    let tmp = this.state.form;
    let disabledErrors = {
      role_name: false,
      monthly_access: false,
      hourly_access: false
    };
    if (
      field == "unlimited_monthly_access" ||
      field == "unlimited_hourly_access"
    ) {
      tmp[field] = e.target.checked;
    } else {
      tmp[field] = e.target.value;
    }
    this.setState({
      form: tmp,
      errors: disabledErrors
    });
  }
  isFormValid() {
    let res = true;
    let tmp = this.state.errors;
    if (
      this.state.form.role_name.length === 0 ||
      this.state.form.role_name.length >= 100
    )
      tmp.role_name = true;

    if (tmp.role_name) res = false;
    this.setState({ errors: tmp });
    return res;
  }

  hideModal() {
    this.props.showHideModal(false);
  }

  addRole() {
    if (this.isFormValid()) {
      let data = this.state.form;
      this.props.addRole(data);
      setTimeout(() => {
        this.hideModal();
      }, 500);
    }
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>
                {this.props.roleDetails
                  ? "Edit Role & Access"
                  : "Add Role & Access"}
              </span>
              <div
                onClick={this.hideModal.bind(this, false)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container contact-form-block">
                <div
                  className="input__icon"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src="/img/icons/roles_access.png"
                    alt=""
                    style={{
                      width: "25px",
                      backgroundSize: "100%",
                      height: "70%",
                      margin: "0 auto"
                    }}
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="Role Name"
                  id="role_name"
                  defaultValue={this.state.form.role_name}
                  onInput={this.changeForm.bind(this, "role_name")}
                />
                {this.state.errors.role_name ? (
                  <p className="contact-form-error">
                    Please enter Role Name
                  </p>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  height: "20px",
                  alignItems: "center"
                }}
              >
                <input
                  id="monthly_access"
                  type="checkbox"
                  checked={this.state.form.unlimited_monthly_access}
                  onChange={this.changeForm.bind(
                    this,
                    "unlimited_monthly_access"
                  )}
                  style={{ marginRight: 10, cursor: "pointer" }}
                />
                <label
                  htmlFor="monthly_access"
                  style={{ color: "grey", fontSize: 13 }}
                >
                  Unlimited Monthly Access
                </label>
              </div>
              {this.state.form.unlimited_monthly_access ===
              true ? null : (
                <div className="input-container contact-form-block">
                  <div
                    className="input__icon"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src="/img/icons/coinsBlue.svg"
                      alt=""
                      style={{
                        width: "25px",
                        backgroundSize: "100%",
                        height: "70%",
                        margin: "0 auto"
                      }}
                    />
                  </div>
                  <input
                    autoComplete="off"
                    className="addressbook-form-input noButtons"
                    type="number"
                    placeholder="Monthly Access"
                    id="monthly_access"
                    value={this.state.form.monthly_access}
                    min="1"
                    onInput={e => {
                      if (e.target.validity.valid) {
                        this.changeForm("monthly_access", e);
                      } else {
                        if (e.target.value == 0) {
                          toast.error(
                            "Must be Greater than 0 or check Unlimited-Access"
                          );
                        }
                      }
                    }}
                  />
                  {this.state.errors.monthly_access ? (
                    <p className="contact-form-error">
                      Invalid value
                    </p>
                  ) : null}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  height: "20px",
                  alignItems: "center"
                }}
              >
                <input
                  id="hourly_access"
                  type="checkbox"
                  checked={this.state.form.unlimited_hourly_access}
                  onChange={this.changeForm.bind(
                    this,
                    "unlimited_hourly_access"
                  )}
                  style={{ marginRight: 10, cursor: "pointer" }}
                />
                <label
                  htmlFor="hourly_access"
                  style={{ color: "grey", fontSize: 13 }}
                >
                  Unlimited Hourly Access
                </label>
              </div>
              {this.state.form.unlimited_hourly_access ===
              true ? null : (
                <div className="input-container contact-form-block">
                  <div
                    className="input__icon"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src="/img/icons/coinsBlue.svg"
                      alt=""
                      style={{
                        width: "25px",
                        backgroundSize: "100%",
                        height: "70%",
                        margin: "0 auto"
                      }}
                    />
                  </div>
                  <input
                    autoComplete="off"
                    className=" addressbook-form-input noButtons"
                    type="number"
                    placeholder="Hourly Access"
                    id="hourly_access"
                    value={this.state.form.hourly_access}
                    min="1"
                    onInput={e => {
                      if (e.target.validity.valid) {
                        this.changeForm("hourly_access", e);
                      } else {
                        toast.error(
                          "Must be Greater than 0 or check Unlimited-Access"
                        );
                      }
                    }}
                  />
                  {this.state.errors.hourly_access ? (
                    <p className="contact-form-error">
                      Invalid value
                    </p>
                  ) : null}
                </div>
              )}
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.addRole.bind(this)}
              >
                {this.props.roleDetails ? "Save" : "ADD"}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddEmployerRole;
