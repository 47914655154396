import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import store from "../store";
import "./main.scss";
import { connect } from "react-redux";
import { BookingAPI } from "../API/BookingAPI";
import OpenHoursModal from "./Dashboard/modals/OpenHours";
import BlockDays from "./Dashboard/modals/BlockDaysModal";
import { getFormatedTime, formatDate } from "../helpers/DateHelpers";
import { VenueApi } from "../API/VenueApi";
import {
  GetVenueOpenHoursEffect,
  getSpaceBlockDaysEffect,
  getVenuesEffect,
  postUnBlockSpace
} from "./VenueWizard/Actions";
import Confirmation from "../Screens/Dashboard/modals/Confirmation";
import { toast } from "react-toastify";
import Helmet from "react-helmet";

class SpaceAvailability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arr: [],
      spaceName: [],
      filerArray: [],
      showHoursModal: false,
      showBlockDaysModal: false,
      block_date: "",
      availableWorkspaces: [],
      isConfimrationBoxOpen: false,
      blockData: {}
    };
    this.handleEvent = this.handleEvent.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
    this.showHideBlockModal = this.showHideBlockModal.bind(this);
  }

  componentDidMount() {
    const token = store.getState().auth.token;
    this.GetBooking(token);
    this.props.GetVenueOpenHoursEffect();
    this.props.getVenuesEffect();
    // this.dateClick = this.dateClick.bind(this);
  }

  async GetBooking(token) {
    let arr = [];
    let start;
    let end;
    let object = {};
    let name = "";
    let venue_id;
    let space_id;
    let spaceName = [];
    let spaceObj = {};
    let all_day;
    let booking = await BookingAPI.GetBookings(token);
    let blockedTimes = await VenueApi.getSpaceBlockDay(token);
    let availableWorkspaces = await VenueApi.GetVenues(token);

    this.setState({ availableWorkspaces });

    for (let item of booking.data) {
      start = this.reverseDate(item.start_date);
      end = this.reverseDate(item.end_date);
      name = item.space.name;

      venue_id = item.space.id;
      spaceObj = {
        venue_id: venue_id,
        name: name
      };
      spaceName.push(name);
      spaceName.push(venue_id);
      object = {
        start: start,
        end: end,
        title:
          name +
          " - " +
          item.user.first_name +
          " " +
          item.user.last_name +
          " - " +
          getFormatedTime(item.start_time) +
          " - " +
          getFormatedTime(item.end_time),
        color: "#3fd3db",
        venue_id: venue_id,
        space_id: venue_id,
        opening_time: item.start_time
      };
      spaceName.push(spaceObj);
      arr.push(object);
    }
    this.setState({ spaceName: spaceName });

    for (let item of blockedTimes.data) {
      start = this.reverseDate(item.date);
      end = this.reverseDate(item.date);
      name = item.space.name;
      venue_id = item.space.venue_id;
      space_id = item.space.id;
      all_day = item.all_day.toUpperCase();
      let object = {
        start,
        end,
        title: `All Day: ${all_day}
          Space ${name} 
          StartTime: ${getFormatedTime(item.opening_time)}
          EndTime: ${getFormatedTime(item.closing_time)}
          Repeat: No`,
        color: "rgb(0,0,0)",
        venue_id,
        space_id,
        opening_time: item.opening_time
      };
      arr.push(object);
    }

    this.setState({
      arr: arr,
      filterArray: arr,
      spaceName: this.removeDuplicates(spaceName)
    });
  }

  reverseDate(date) {
    let tmp = date.split("/");
    return tmp[2] + "-" + tmp[0] + "-" + tmp[1];
  }

  randomColor() {
    return (
      "rgb(" +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      "," +
      Math.floor(Math.random() * 256) +
      ")"
    );
  }

  removeDuplicates(spaceName) {
    let uniq = {};
    return spaceName
      .filter(
        obj => !uniq[obj.venue_id] && (uniq[obj.venue_id] = true)
      )
      .splice(1);
  }

  handleEvent(e) {
    if (e.target.value !== "0") {
      this.filterSpaceById(e);
    } else {
      this.setState({
        filterArray: this.state.arr
      });
    }
  }

  filterSpaceById(e) {
    let filterSpace = [];
    for (let item of this.state.arr) {
      if (item.venue_id === parseInt(e.target.value)) {
        filterSpace.push(item);
      }
    }
    this.setState({
      filterArray: filterSpace
    });
  }

  showHideModal() {
    this.setState({
      showHoursModal: !this.state.showHoursModal
    });
  }
  hideModal = () => {
    this.setState({ isConfimrationBoxOpen: false });
  };
  openConfirmationBox = info => {
    this.setState({ isConfimrationBoxOpen: true });
    this.setState({ blockData: info });
  };
  showHideBlockModal(arg) {
    this.setState({
      showBlockDaysModal: !this.state.showBlockDaysModal
    });
    this.GetBooking(store.getState().auth.token);
  }

  handleUnblockDay = blockInfo => {
    const date = new Date(blockInfo.start);
    const month = date.getMonth();
    const day = date.getDay();
    const year = date.getFullYear();
    let opening_time = blockInfo.extendedProps.opening_time;

    let req = {
      date: formatDate(date),
      space_id: blockInfo.extendedProps.space_id,
      opening_time: opening_time
    };
    this.props.postUnBlockSpace(req);
    const token = store.getState().auth.token;
    this.GetBooking(token);
    this.setState({ isConfimrationBoxOpen: false });
  };
  handleDateClick = (arg, calDate) => {
    let date = new Date();
    date = date.setHours(0, 0, 0, 0);
    let selectedDate = calDate.date.getTime();
    if (selectedDate >= date) {
      this.setState({
        showBlockDaysModal: !this.state.showBlockDaysModal,
        block_date: calDate.dateStr
      });
    } else {
      toast.error("Past dates can not be selected");
    }
  };

  render() {
    let { availableWorkspaces } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
        </Helmet>
        <div className="container">
          <div className="add-employer__heading add-employer_space">
            <div className="statistics statistics_space pd-0 modal-open-hours">
              <div className="select-group">
                <div
                  style={{ border: "1px solid #009cdc" }}
                  className="input-container"
                >
                  <div
                    className="input-select"
                    style={{ width: 335 }}
                  >
                    <select
                      style={{ color: "#009cdc" }}
                      className="pl-12"
                      onChange={this.handleEvent}
                    >
                      <option value={0}>Filter By Venues</option>
                      {availableWorkspaces.data &&
                        availableWorkspaces.data.map(data => (
                          <option key={data.id} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showHoursModal ? (
            <OpenHoursModal CloseModal={this.showHideModal} />
          ) : null}
          {this.state.showBlockDaysModal ? (
            <BlockDays
              CloseModal={this.showHideBlockModal}
              block_date={this.state.block_date}
            />
          ) : null}

          <div
            style={{
              lineHeight: "22px",
              fontSize: "18px",
              marginBottom: 10
            }}
          >
            <p>Click on a day to block out times.</p>
          </div>

          <FullCalendar
            dateClick={e => {
              this.handleDateClick(this, e);
            }}
            plugins={[dayGridPlugin, interactionPlugin]}
            defaultView="dayGridMonth"
            weekends={true}
            header={{
              left: "title",
              center: "",
              right: "prev,next"
            }}
            eventClick={info => {
              this.openConfirmationBox(info.event);
            }}
            events={this.state.filterArray}
          />
        </div>
        {this.state.isConfimrationBoxOpen ? (
          <Confirmation
            message={"Do you want to unblock this space?"}
            decline={this.hideModal}
            accept={this.handleUnblockDay}
            id={this.state.blockData}
            acceptText={"Yes"}
            declineText={"No"}
            modalHeader={"UNBLOCK SPACE"}
            showButtons={true}
          ></Confirmation>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = state => ({
  venues: state.venueWizard.venues,
  isLoading: state.Loader.isLoading
});

const mapDispatchToProps = {
  GetVenueOpenHoursEffect,
  getSpaceBlockDaysEffect,
  getVenuesEffect,
  postUnBlockSpace
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceAvailability);
