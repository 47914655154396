/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TimePicker from "rc-time-picker";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import {
  formatedTimeMin,
  formatedTimeMin2
} from "../../helpers/DateHelpers";
import { VenueWizardStep } from "./Reducers";
import {
  getVenuesEffect,
  GetVenueOpenHoursEffect,
  venueWizardSetStep,
  venueSubmitOpenHoursForm,
  isVenueOpenHourSkip
} from "./Actions";
import VenueSteps from "./VenueSteps";
import {
  postVenueOpenHoursEffect,
  postCreateVenueOpenHoursEffect
} from "../WorkspaceWizard/Actions";
import moment from "moment";
import { setLoader } from "../Loader/Actions";
import Footer from "../../footer";
import { Helmet } from "react-helmet";
import VenueDraftButton from "./venueDraftComponent";
import {
  CreateVenueOpenHoursRequest,
  CreateVenueRequest,
  VenueApi
} from "../../API/VenueApi";
import { toast } from "react-toastify";
const week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];
class VenueOpenHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      venuesOpenHours: [],
      selectedVenuesHours: {},
      venueWizardStep: "",
      venue_id: 0,
      days: [],
      opening_time: null,
      closing_time: null,
      no_day_checked: false,
      opening_time_error: "",
      closing_time_error: "",
      applyAll: false,
      weekDays: {
        Monday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Tuesday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Wednesday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Thursday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Friday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Saturday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        },
        Sunday: {
          date: "",
          opening_time: "",
          closing_time: "",
          checked: false,
          error_opening: false,
          error_closing: false,
          valid_format: false
        }
      }
    };
  }

  componentDidMount() {
    let array = [];
    let time = {};
    let weekdays = this.state.weekDays;
    if (this.props.availability) {
      this.props.availability.map(item => {
        if (
          (item.openingTime !== "" && item.openingTime !== null) ||
          (item.closingTime !== "" && item.closingTime !== null)
        ) {
          array.push(item.day);
          weekdays[item.day].opening_time = formatedTimeMin2(
            item.openingTime
          );
          weekdays[item.day].closing_time = formatedTimeMin2(
            item.closingTime
          );
          weekdays[item.day].checked = true;
        }
      });
      this.setState({
        days: array,
        applyAll: array.length === 7 ? true : false,
        weekDays: weekdays
      });
    }
    window.scrollTo(0, 0);
  }
  contains = value => this.state.days.some(v => value === v);

  onClick = value => {
    let cloneObject = this.state.weekDays;
    if (this.contains(value)) {
      cloneObject[value].opening_time = "";
      cloneObject[value].closing_time = "";
      cloneObject[value].checked = false;
      this.setState({ weekDays: cloneObject });
    } else {
      this.handleFillDays(value);
    }
    this.setState({ no_day_checked: false });
    this.setState({ applyAll: false });
    const newValues = this.contains(value)
      ? this.state.days.filter(v => v !== value)
      : [...this.state.days, value];
    this.setState({ days: newValues });
  };
  isOneDayChecked() {
    let dayChecked = false;
    if (this.state.days.length > 0) {
      dayChecked = true;
    }
    if (!dayChecked) this.setState({ no_day_checked: true });
    return dayChecked;
  }

  resetErrors() {
    let cloneObject = this.state.weekDays;
    for (let day in this.state.weekDays) {
      cloneObject[day].error_opening = false;
      cloneObject[day].error_closing = false;
      cloneObject[day].valid_format = false;
    }
    this.setState({
      weeresetErrorskDays: cloneObject,
      no_day_checked: false
    });
  }

  isFormValid() {
    if (!this.isOneDayChecked()) return false;
    this.resetErrors();
    let cloneObject = this.state.weekDays;
    let valid = true;

    this.state.days.map(day => {
      if (
        this.state.weekDays[day].opening_time === "" ||
        this.state.weekDays[day].closing_time === ""
      ) {
        valid = false;
        cloneObject[day].valid_format = true;
        return 0;
      }
    });
    this.setState({ weekDays: cloneObject });
    return valid;
  }

  handleApplyAllDays = (value, isAll) => {
    let cloneObject = this.state.weekDays;
    this.setState({
      weekDays: cloneObject
    });
    if (isAll) {
      for (let key in this.state.weekDays) {
        if (this.state.weekDays[value].opening_time) {
          cloneObject[key].opening_time =
            this.state.weekDays[value].opening_time;
          cloneObject[key].checked = true;
          this.setState({ weekDays: cloneObject });
        }
        if (this.state.weekDays[value].closing_time) {
          cloneObject[key].closing_time =
            this.state.weekDays[value].closing_time;
          cloneObject[key].checked = true;
          this.setState({ weekDays: cloneObject });
        }
      }
    } else {
      for (let key in this.state.weekDays) {
        if (key !== value) {
          cloneObject[key].opening_time = "";
          this.setState({ weekDays: cloneObject });
          cloneObject[key].closing_time = "";
          this.setState({ weekDays: cloneObject });
        }
      }
    }
  };
  handleFillDays = value => {
    let cloneObject = this.state.weekDays;
    cloneObject[value].checked = !this.state.weekDays[value].checked;
    this.setState({
      weekDays: cloneObject
    });
    if (this.state.weekDays[value].checked) {
      for (let key in this.state.weekDays) {
        if (this.state.weekDays[key].opening_time) {
          cloneObject[value].opening_time =
            this.state.weekDays[key].opening_time;
          this.setState({ weekDays: cloneObject });
        }
        if (this.state.weekDays[key].closing_time) {
          cloneObject[value].closing_time =
            this.state.weekDays[key].closing_time;
          this.setState({ weekDays: cloneObject });
        }
      }
    } else {
      for (let key in this.state.weekDays) {
        if (this.state.weekDays[key].opening_time) {
          cloneObject[value].opening_time = "";
          this.setState({ weekDays: cloneObject });
        }
        if (this.state.weekDays[key].closing_time) {
          cloneObject[value].closing_time = "";
          this.setState({ weekDays: cloneObject });
        }
      }
    }
  };
  handleOpenTime(e, day) {
    let cloneObject = this.state.weekDays;
    cloneObject[day].opening_time = e._d;
    cloneObject[day].error_opening = false;
    cloneObject[day].valid_format = false;
    if (
      this.state.weekDays[day].closing_time !== "" &&
      cloneObject[day].opening_time.getTime() >
        this.state.weekDays[day].closing_time.getTime()
    ) {
      cloneObject[day].valid_format = true;
      cloneObject[day].error_opening = true;
    }
    this.setState({ weekDays: cloneObject });
  }

  handleCloseTime(e, day) {
    let cloneObject = this.state.weekDays;
    cloneObject[day].closing_time = e._d;
    cloneObject[day].error_closing = false;
    cloneObject[day].valid_format = false;
    if (
      (this.state.weekDays[day].opening_time !== "" &&
        this.state.weekDays[day].opening_time.getTime() >
          cloneObject[day].closing_time.getTime()) ||
      (this.state.weekDays[day].opening_time !== "" &&
        this.state.weekDays[day].opening_time.getTime() ==
          cloneObject[day].closing_time.getTime())
    ) {
      cloneObject[day].error_closing = true;
      cloneObject[day].valid_format = true;
    }
    this.setState({ weekDays: cloneObject });
  }
  handleChecked = () => {
    var indexValue = "Monday";
    let isAll = false;
    if (!this.state.applyAll) {
      this.setState({ days: week });
      isAll = true;
      week.some((v, index) => {
        if (this.state.weekDays[v].opening_time !== "") {
          indexValue = v;
          return true;
        }
      });
    } else {
      if (
        (this.state.weekDays[indexValue].opening_time != "" &&
          this.state.weekDays[indexValue].opening_time != null) ||
        (this.state.weekDays[indexValue].closing_time != "" &&
          this.state.weekDays[indexValue].closing_time != null)
      )
        this.setState({ days: [indexValue] });
      else {
        this.setState({ days: [] });
      }
    }

    this.handleApplyAllDays(indexValue, isAll);
    this.setState({ applyAll: !this.state.applyAll });
  };

  handleSkip = () => {
    this.props.isVenueOpenHourSkip(false);
    this.props.venueWizardSetStep(VenueWizardStep.BonusOffer);
  };
  handleSave = async fromDraft => {
    if (this.isFormValid()) {
      let { weekDays } = this.state;
      let availability = week.map(day => {
        if (this.state.days.some(v => v == day)) {
          weekDays[day].opening_time =
            weekDays[day].opening_time
              .toString()
              .split(" ")[4]
              .split(":", 2)
              .join(":") + ":00";
          weekDays[day].closing_time =
            weekDays[day].closing_time
              .toString()
              .split(" ")[4]
              .split(":", 2)
              .join(":") + ":00";
        } else {
          weekDays[day].opening_time = "";
          weekDays[day].closing_time = "";
        }
        return {
          day,
          openingTime: weekDays[day].opening_time,
          closingTime: weekDays[day].closing_time
        };
      });
      let request = {
        venue_id: parseInt(this.state.venue_id),
        availability
      };
      if (fromDraft) {
        this.props.setLoader(true);
        await this.props.venueSubmitOpenHoursForm(availability);
        let request = {
          name: this.props.VenueBasicInfo.name,
          location: this.props.VenueBasicInfo.location,
          description: this.props.VenueBasicInfo.description,
          venue_type_id: Number(
            this.props.VenueBasicInfo.venue_type_id
          ),
          email: this.props.VenueBasicInfo.email,
          phone_number: this.props.VenueBasicInfo.phone_number,
          lead_time: this.props.VenueBasicInfo.lead_time,
          vaccination_status:
            this.props.VenueBasicInfo.vaccination_status,
          status: "Draft"
        };

        let arrayImages = {};
        if (this.props.VenueBasicInfo.hasOwnProperty("image1")) {
          arrayImages.image1 = this.props.VenueBasicInfo.image1;
        }
        if (this.props.VenueBasicInfo.hasOwnProperty("image2")) {
          arrayImages.image2 = this.props.VenueBasicInfo.image2;
        }
        if (this.props.VenueBasicInfo.hasOwnProperty("image3")) {
          arrayImages.image3 = this.props.VenueBasicInfo.image3;
        }
        if (this.props.VenueBasicInfo.hasOwnProperty("image4")) {
          arrayImages.image4 = this.props.VenueBasicInfo.image4;
        }
        let allRequest;
        const response = await VenueApi.CreateVenue(
          this.props.token,
          new CreateVenueRequest(request, arrayImages)
        );
        allRequest = response;
        if (allRequest.data) {
          let req = {
            venue_id: allRequest.data.id,
            availability
          };
          if (availability.length > 0) {
            const responseOpenHours = await VenueApi.venueOpenHours(
              this.props.token,
              new CreateVenueOpenHoursRequest(req)
            );
            if (responseOpenHours.data) {
              this.props.setLoader(false);
              toast.success("Saved as Draft");
              this.props.history.push("/venues");
            } else {
              this.props.setLoader(false);
              toast.erro("Something went wrong, try again");
            }
          }
        } else {
          this.props.setLoader(false);
          toast.error("Something went wron, try again");
        }
        return;
      } else {
        this.props.isVenueOpenHourSkip(true);
        await this.props.venueSubmitOpenHoursForm(availability);
        this.props.venueWizardSetStep(VenueWizardStep.Amenities);
      }
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - List Your Venue - 2</title>
        </Helmet>
        <div className="body">
          <div className="container containerAddSpace">
            <VenueSteps
              step={2}
              end={this.props.venueId ? true : false}
            ></VenueSteps>
            <div className="page-wrapper" style={{ marginTop: 25 }}>
              <div className="md-title">
                <div className="row">
                  <div className="col-md-10">
                    <div className="is-title">
                      <h1 className="register-title">
                        List Your Venue
                      </h1>
                      <p className="register-sub-title">
                        2. Venue Trading Hours
                      </p>
                    </div>
                  </div>
                  {this.props.venueId ? (
                    <div className="col-md-2">
                      <div className="link">
                        {/*<a  onClick={()=>{this.handleSkip()}} className="skipText" href="#">Skip for now</a>*/}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div className="col-md-8">
                    <div className="ui toggle checkbox">
                      <label className="switch">
                        <input
                          defaultChecked={this.state.applyAll}
                          checked={this.state.applyAll}
                          type="checkbox"
                          onChange={e =>
                            this.handleChecked(e.target.value)
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                      <label>
                        Select any day and apply to all days
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4  ">
                    <div className="md-sidebar">
                      <div
                        className="name"
                        style={{
                          marginBottom: "10px",
                          fontWeight: 400
                        }}
                      >
                        <p style={{ fontSize: 12 }}>
                          <b>HINT:</b> These are the hours your venue
                          is open to Dorpee guests. You can set the
                          availability of each individual Workspace
                          Type later.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-10">
                  <p
                    style={{
                      marginLeft: "2px",
                      fontSize: "16px",
                      marginBottom: "6px"
                    }}
                  >
                    Click on the day to toggle open/closed
                  </p>
                </div>
                <div className="bl-all-days clearfix col-md-4">
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column"
                    }}
                  >
                    {week.map(c => {
                      return (
                        <li
                          style={{ paddingBottom: 10 }}
                          key={c}
                          onClick={() => this.onClick(c)}
                          className={`${
                            this.contains(c) ? " active" : ""
                          }`}
                        >
                          <span>{c}</span>
                        </li>
                      );
                    })}
                  </ul>
                  <span style={{ color: "red" }}>
                    {this.state.no_day_checked ? "Select a day" : ""}
                  </span>
                </div>
                <div
                  className="bl-time col-md-8"
                  style={{ marginTop: "2px" }}
                >
                  {week.map((i, index) => {
                    return (
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginBottom: "0.6rem"
                        }}
                      >
                        <div className="col-sm-6">
                          <div className="md-time">
                            <TimePicker
                              minuteStep={15}
                              value={
                                this.state.weekDays[i].opening_time
                                  ? moment(
                                      this.state.weekDays[i]
                                        .opening_time
                                    )
                                  : ""
                              }
                              showSecond={false}
                              onChange={e =>
                                this.handleOpenTime(e, i)
                              }
                              className="form-control "
                              disabled={
                                !this.state.weekDays[i].checked
                              }
                            />
                            <button className="icon"></button>
                          </div>
                          {this.state.weekDays[i].error_opening ? (
                            <p
                              className="contact-form-error-block"
                              style={{ marginTop: 12, left: "15px" }}
                            >
                              Invalid time
                            </p>
                          ) : null}
                        </div>
                        <div className="col-sm-6">
                          <div className="md-time">
                            <TimePicker
                              minuteStep={15}
                              value={
                                this.state.weekDays[i].closing_time
                                  ? moment(
                                      this.state.weekDays[i]
                                        .closing_time
                                    )
                                  : ""
                              }
                              showSecond={false}
                              onChange={e =>
                                this.handleCloseTime(e, i)
                              }
                              className="form-control"
                              disabled={
                                !this.state.weekDays[i].checked
                              }
                            />
                            <button className="icon"></button>
                          </div>
                          {this.state.weekDays[i].error_closing ||
                          this.state.weekDays[i].valid_format ? (
                            <p
                              className="contact-form-error-block"
                              style={{ marginTop: 12, left: "15px" }}
                            >
                              Invalid time
                            </p>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="bl-buttons">
                <div className="row">
                  <div className="col-4">
                    <a
                      onClick={() => {
                        this.props.venueWizardSetStep(
                          VenueWizardStep.Details
                        );
                      }}
                      className="btn btn-back"
                    >
                      <span>Back</span>
                    </a>
                  </div>
                  <div className="col-8">
                    <a
                      onClick={() => this.handleSave()}
                      className="btn btn-next"
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <span> Set Venue Amenities</span>
                    </a>
                  </div>
                </div>
              </div>
              <VenueDraftButton formValid={this.handleSave} />
            </div>
          </div>
          <Footer isSubs></Footer>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.venueWizard.venues,
  venuesOpenHours: state.venueWizard.venuesOpenHours,
  VenueBasicInfo: state.venueWizard.detailsFieldsVenue,
  loading: state.Loader.isLoading,
  venueWizardStep: state.venueWizard.step,
  token: state.auth.token,
  availability: state.venueWizard.availability
});

const mapDispatchToProps = {
  getVenuesEffect,
  postVenueOpenHoursEffect,
  postCreateVenueOpenHoursEffect,
  GetVenueOpenHoursEffect,
  setLoader,
  venueWizardSetStep,
  venueSubmitOpenHoursForm,
  isVenueOpenHourSkip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueOpenHours);
