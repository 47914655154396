import React, { FC } from "react";
import { useHistory } from "react-router-dom";
interface Props {
  onClick: () => void;
}

const WorkspaceFooter: FC<Props> = ({ onClick }) => {
  const history = useHistory();
  return (
    <div className="bl-buttons">
      <div className="row">
        <div className="col-4">
          <span
            onClick={() => {
              history.goBack();
              // history.push(
              //   `/workspaces-list/${window.localStorage.getItem("venue_id")}`
              // );
            }}
            className="btn btn-back"
          >
            <span>Back</span>
          </span>
        </div>
        <div className="col-8">
          <button
            className={"btn btn-next"}
            style={{ paddingLeft: 0 }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceFooter;
