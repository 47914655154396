import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store";
import {
  getVenuesEffect,
  postVenueVaccinationEffect
} from "./Actions";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

export const VenueVaccinationStatus = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const venues = useSelector(
    (state: AppState) => state.venueWizard.venues!
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  const venueFirst = venues.find(v => v.vaccination_status == null);

  const [venue_id, setvenueId]: any = useState();

  const [venueStatus, setVenueStatus] = useState("");
  useEffect(() => {
    dispatch(getVenuesEffect());
  }, []);

  useEffect(() => {
    if (venueFirst) {
      venues && setvenueId(venueFirst.id);

      venues && setvenueId(venueFirst.id);
    } else {
      if (venues.length > 0) {
        venues && venues && setvenueId(venues[0].id);
        venues && setVenueStatus(venues[0].vaccination_status!);
      }
    }
  }, [venues]);

  const handleVenueName = (event: any) => {
    const selectedVenue = venues.find(
      venue => venue.id == event.target.value
    );
    setvenueId(event.target.value);
    setVenueStatus(
      selectedVenue?.vaccination_status != null
        ? selectedVenue.vaccination_status
        : ""
    );
  };
  const handleVenueStatus = (event: any) => {
    setVenueStatus(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (venueStatus == "" || venueStatus == null) {
      toast.error("Please Select Vaccination Status for Venue");
    } else {
      dispatch(
        postVenueVaccinationEffect(history, venue_id, venueStatus)
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Venue</title>
      </Helmet>
      {venues && venues.length > 0 ? (
        <div className="container pd-0">
          <div className="dashboard">
            <div className="dashboard__top">
              <div className="dashboard__title">
                Venue Vaccination Status
              </div>
            </div>
          </div>
          <div
            className="utilization-chart__wrapper"
            style={{ marginBottom: 145 }}
          >
            <div className="col-md-12">
              <div className="col-md-4" style={{ marginLeft: 15 }}>
                <div className="input-container">
                  <div className="input-select pl-12">
                    <select
                      value={venue_id}
                      onChange={e => handleVenueName(e)}
                    >
                      {venues.map(item => {
                        return (
                          <option value={item.id}>{item.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div
                className="col-md-4"
                style={{ marginLeft: 15, marginTop: "1rem" }}
              >
                <div className="input-container">
                  <div className="input-select pl-12 pr-12 m-0 ">
                    <select
                      value={venueStatus}
                      onChange={e => handleVenueStatus(e)}
                    >
                      <option value="" disabled>
                        Select Vaccination Status
                      </option>
                      {[
                        "Not Vaccinated",
                        "Partially Vaccinated",
                        "Fully Vaccinated"
                      ].map((value, i) => {
                        return <option value={value}>{value}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 36 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start"
                  }}
                >
                  <input
                    type="submit"
                    className={`btn btn-blue wizard__btn mb-0${
                      isLoading ? "btn--loadingNew" : " btn-blue"
                    }`}
                    disabled={isLoading}
                    onClick={e => handleSubmit(e)}
                    style={{
                      width: 150,
                      marginTop: 10
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard">
          <div className="dashboard__top">
            <div className="dashboard__title">
              Please Add Venue First
            </div>
          </div>
        </div>
      )}
    </>
  );
};
