import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";

import { AppState } from "../../store";
import { getEmployeesTransactionEffect } from "./Actions";
import { formatDate } from "../../helpers/DateHelpers";
import useForm from "../../hooks/useForm";
import moment from "moment";

const EmployerTransactions = () => {
  let data = {
    user: "",
    transaction_start: null,
    transaction_end: null,
    booking_start: null,
    booking_end: null
  };
  const dispatch = useDispatch();
  const { form, setForm, handleChange, resetForm }: any =
    useForm(data);

  const [transaction_date_Error, setTansactionDateError]: any =
    useState();
  const [booking_date_Error, setBookingDateError]: any = useState();

  useEffect(() => {
    dispatch(getEmployeesTransactionEffect());
  }, []);
  const userTransactions = useSelector(
    (state: AppState) => state.auth.userTransaction
  );
  const [transactions, setTransaction] = useState<any>([]);
  const userDate = useSelector(
    (state: AppState) => state.auth?.user?.created_at
  );
  useEffect(() => {
    if (
      (userDate != "" && userDate != null) ||
      userDate != undefined
    ) {
      let start_date: any = userDate.split(" ");
      let new_start_date: any = start_date[0].split("-");
      setForm(() => ({
        transaction_start: new Date(
          `${new_start_date[1]}/
          ${new_start_date[2]}/
          ${new_start_date[0]}`
        ),
        transaction_end: new Date(),
        booking_start: new Date(
          `${new_start_date[1]}/
          ${new_start_date[2]}/
          ${new_start_date[0]}`
        ),
        booking_end: new Date()
      }));
    }
  }, [userDate]);
  useEffect(() => {
    if (userTransactions && userTransactions.length > 0) {
      userTransactions.sort(function (a: any, b: any) {
        let adate: any = new Date(a.created_at);
        let bdate: any = new Date(b.created_at);
        return bdate - adate;
      });
      setTransaction(userTransactions);
    }
  }, [userTransactions]);
  const clearFilter = () => {
    resetForm();
    dispatch(getEmployeesTransactionEffect());
  };
  const handleSubmit = () => {
    if (!Object.values(form).every(o => o === null || o === "")) {
      if (
        form.transaction_start &&
        (!form.transaction_end || form.transaction_end == "")
      ) {
        setTansactionDateError("Transaction End Date Required");
        return;
      }
      if (
        form.transaction_start !== "" &&
        form.transaction_end !== "" &&
        form.transaction_end < form.transaction_start
      ) {
        setTansactionDateError("Invalid Transaction Dates");
        return;
      }
      if (
        form.booking_start !== "" &&
        form.booking_end !== "" &&
        form.booking_end < form.booking_start
      ) {
        setBookingDateError("Invalid Booking Dates");
        return;
      }
      if (
        form.booking_start &&
        (!form.booking_end || form.booking_end == "")
      ) {
        setBookingDateError("Booking End Date Required");
        return;
      } else {
        let data: any = {};
        if (form.user) {
          data["user"] = form.user;
        }
        if (form.transaction_start) {
          data["transaction_start"] = formatDate(
            form.transaction_start
          );
        }
        if (form.transaction_end) {
          data["transaction_end"] = formatDate(form.transaction_end);
        }
        if (form.booking_start) {
          data["booking_start"] = formatDate(form.booking_start);
        }
        if (form.booking_end) {
          data["booking_end"] = formatDate(form.booking_end);
        }
        dispatch(getEmployeesTransactionEffect(data));
      }
    } else {
    }
  };
  const handleDateChange = (field: string, date: any) => {
    setForm((form: any) => ({
      ...form,
      [field]: date
    }));
    setBookingDateError("");
    setTansactionDateError("");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Transactions</title>
      </Helmet>
      <div className="container dashboard ed">
        <div className="booking_table_margins">
          <div
            className="approval__header-title"
            style={{ marginTop: 0 }}
          >
            <div
              className="dashboard__title headerTable"
              style={{ marginBottom: 20 }}
            >
              Credits History
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap"
            }}
          >
            <div className="col-md-2" style={{ padding: 0 }}>
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    fontSize: 12
                  }}
                >
                  User
                </label>
                <div
                  className="input-container"
                  style={{
                    justifyContent: "space-between",
                    marginLeft: 1,
                    width: "100%"
                  }}
                >
                  <input
                    placeholder="User"
                    value={form.user}
                    onChange={(e: any) => {
                      handleDateChange("user", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4" style={{ padding: 0 }}>
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    fontSize: 12
                  }}
                >
                  Transaction Date Range
                </label>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      margin: 0
                    }}
                  >
                    <DatePicker
                      placeholderText={"Start Date"}
                      isClearable
                      clearButtonTitle="clear"
                      dateFormat="dd-MM-yyyy"
                      selected={form.transaction_start}
                      onChange={(date: any) => {
                        handleDateChange("transaction_start", date);
                      }}
                    />
                    <hr
                      style={{
                        height: "100%",
                        border: "1px solid #a8c8d4"
                      }}
                    />
                    <DatePicker
                      placeholderText={"End Date"}
                      isClearable
                      clearButtonTitle="clear"
                      dateFormat="dd-MM-yyyy"
                      selected={form.transaction_end}
                      onChange={(date: any) => {
                        handleDateChange("transaction_end", date);
                      }}
                    />
                  </div>
                </div>

                <p style={{ color: "red" }}>
                  {transaction_date_Error}
                </p>
              </div>
            </div>

            <div className="col-md-4" style={{ padding: 0 }}>
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <label
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    fontSize: 12
                  }}
                >
                  Booking Date Range
                </label>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    className="input-container "
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                      margin: 0
                    }}
                  >
                    <DatePicker
                      placeholderText={"Booking Start Date"}
                      isClearable
                      clearButtonTitle="clear"
                      dateFormat="dd-MM-yyyy"
                      selected={form.booking_start}
                      onChange={(date: any) => {
                        handleDateChange("booking_start", date);
                      }}
                    />
                    <hr
                      style={{
                        height: "100%",
                        border: "1px solid #a8c8d4"
                      }}
                    />

                    <DatePicker
                      placeholderText={"End Date"}
                      isClearable
                      clearButtonTitle="clear"
                      dateFormat="dd-MM-yyyy"
                      selected={form.booking_end}
                      onChange={(date: any) => {
                        handleDateChange("booking_end", date);
                      }}
                    />
                  </div>
                </div>
                <p style={{ color: "red" }}>{booking_date_Error}</p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            <div className="col-md-2" style={{ marginTop: "1.4rem" }}>
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <button
                  type="submit"
                  className={`btn2 btn-blue`}
                  onClick={handleSubmit}
                  style={{ width: "100%" }}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="col-md-2" style={{ marginTop: "1.4rem" }}>
              <div
                className="stats-chart__item"
                style={{ marginBottom: "14px" }}
              >
                <button
                  type="submit"
                  className={`btn2 btn-blue`}
                  onClick={clearFilter}
                  style={{ width: "100%", cursor: "pointer" }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>

          <div className="team-list ">
            <table className="team-list-table table-style">
              <thead>
                <tr>
                  <th className="table-head">User</th>
                  <th className="table-head">Credits Added</th>
                  <th className="table-head">Credit Used</th>
                  <th className="table-head">Credit Balance </th>
                  <th className="table-head">Transaction Date</th>
                  <th className="table-head">Transaction Time</th>
                  <th className="table-head">Booked Venue</th>
                  <th className="table-head">Number of People</th>
                  <th className="table-head">
                    Booking Date And Time
                  </th>
                  <th className="table-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.map((item: any, index: any) => (
                    <tr style={{ height: 50 }} key={index}>
                      <td className="table-style">
                        {item.created_by?.full_name!}
                      </td>
                      <td className="table-style">{item.income}</td>
                      <td className="table-style">{item.spending}</td>
                      <td className="table-style">{item.total}</td>
                      <td className="table-style">
                        {item.updated_at
                          .split(" ")[0]
                          .split("-")
                          .reverse()
                          .join("-")}
                      </td>
                      <td className="table-style">
                        {item.updated_at.split(" ")[1]}
                      </td>
                      <td className="table-style">
                        {item.booking
                          ? item.booking.space.venue.name
                          : ""}
                      </td>
                      <td className="table-style">
                        {item.booking
                          ? item.booking.no_of_people
                          : ""}
                      </td>
                      <td className="table-style">
                        {item.booking ? (
                          <>
                            <p style={{ padding: 0, margin: 0 }}>
                              {moment(
                                item.booking.start_date,
                                "MM-DD-YYYY"
                              ).format("DD-MM-YYYY")}
                            </p>
                            <p style={{ padding: 0, margin: 0 }}>
                              {item.booking.start_time.split(":")[0]}:
                              {item.booking.start_time.split(":")[1]}
                              {" - "}
                              {item.booking.end_time.split(":")[0]}:
                              {item.booking.end_time.split(":")[1]}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="table-style">
                        {item.log ? item.log : ""}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerTransactions;
