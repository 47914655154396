import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";

import { getDateformated } from "../../../helpers/DateHelpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookingLocation_HeatMap = ({ data, allHeatMapData }) => {
  const [map, setMap] = React.useState(null);
  const [instance, setInstance] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
    setInstance(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const [selectedDateFrom, setDate] = useState(null);
  const [selectedDateto, setDateTo] = useState(null);
  const [dateError, setDateError] = useState("");
  const [heatMapData, setMapData] = useState(data);
  const [mapp, setMapp] = useState();
  useEffect(() => {
    if (data.length > 0 && !loaded) {
      var map2 = mapp;
      const points = data
        ? data.map((p) => {
            return [p[0], p[1], p[2]];
          })
        : [];
      L.heatLayer(points).addTo(map2);
      setMapp(map2);
      setLoaded(true);
    }
  }, [data]);
  useEffect(() => {
    var map;
    map = L.map("map").setView([-33.8780617, 151.1060213], 3);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    setMapp(map);
  }, []);

  const handleFromDate = (e) => {
    setDate(e);
  };
  const handleToDate = (e) => {
    setDateTo(e);
  };

  const handleSubmit = () => {
    if (selectedDateto == null) {
      setDateError("Enter end date");
    }
    if (selectedDateto != null) {
      if (selectedDateFrom > selectedDateto) {
        setDateError("invalid end date");
      } else setDateError("");
    }
    if (selectedDateFrom != null && selectedDateto != null) {
      var map2 = mapp;
      if (map2 != undefined) {
        map2.eachLayer(function (layer) {
          if (!layer._url) map2.removeLayer(layer);
        });
      }

      let heatMapData = [];
      let filteredData = allHeatMapData;
      filteredData = filteredData.filter((v) => {
        return (
          selectedDateFrom <= getDateformated(v.date) &&
          selectedDateto >= getDateformated(v.date)
        );
      });
      if (filteredData.length > 0) {
        filteredData.map((item) => {
          heatMapData.push([item.lat, item.lng, "1200"]);
        });
        map2 = mapp.setView([heatMapData[0][0], heatMapData[0][1]], 2);
        const points = heatMapData
          ? heatMapData.map((p) => {
              return [p[0], p[1], p[2]];
            })
          : [];
        L.heatLayer(points).addTo(map2);
        setMapp(map2);
        setMapData(heatMapData);
      }
    }
  };

  const clearFilter = () => {
    if (selectedDateFrom && selectedDateto) {
      setDate(null);
      setDateTo(null);
      var map2 = mapp;
      if (map2 != undefined) {
        map2.eachLayer(function (layer) {
          if (!layer._url) map2.removeLayer(layer);
        });
      }
      map2 = mapp.setView([-33.8780617, 151.1060213], 3);
      const points = data
        ? data.map((p) => {
            return [p[0], p[1], p[2]];
          })
        : [];
      L.heatLayer(points).addTo(map2);
      setMapp(map2);
    }
  };
  return (
    <div>
      <div className="content-title">BOOKINGS HEAT MAP</div>
      <div className="row">
        <div className="col-md-4" style={{ marginBottom: 10 }}>
          <label style={{ fontWeight: 500, display: "flex", fontSize: 12 }}>
            Start Date:
          </label>
          <div
            className="input-container "
            style={{
              justifyContent: "space-between",
              marginLeft: 1,
              width: "100%",
            }}
          >
            <DatePicker
              onChange={handleFromDate}
              selected={selectedDateFrom}
              className="empDateField"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="col-md-4">
          <label style={{ fontWeight: 500, display: "flex", fontSize: 12 }}>
            End Date:
          </label>
          <div
            className="input-container "
            style={{
              justifyContent: "space-between",
              marginLeft: 1,
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <DatePicker
              onChange={handleToDate}
              selected={selectedDateto}
              className="empDateField"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <div className="col-md-2">
          <div
            className="stats-chart__item"
            style={{ marginBottom: "14px", marginTop: "1.4rem" }}
          >
            <button
              type="submit"
              className={`btn2 btn-blue`}
              onClick={() => {
                handleSubmit();
              }}
              style={{ width: "100%" }}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="col-md-2">
          <div
            className="stats-chart__item"
            style={{ marginBottom: "14px", marginTop: "1.4rem" }}
          >
            <button
              type="submit"
              className={`btn2 btn-blue`}
              onClick={() => {
                clearFilter();
              }}
              style={{ width: "100%" }}
            >
              Clear Filter
            </button>
          </div>
        </div>
        <span
          style={{
            marginTop: 0,
            marginLeft: "20px",
            color: "red",
          }}
        >
          {dateError}
        </span>
      </div>
      <div id="map" style={{ height: "60vh", zIndex: 0 }}></div>
    </div>
  );
};
export default BookingLocation_HeatMap;
