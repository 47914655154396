import React from "react";


class FAQComponent  extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleAnswers = (elementId) => {
    var v = document.getElementById(`id${elementId}`);
    var img = document.getElementById(`img${elementId}`);
    var img2 = document.getElementById(`img2${elementId}`);
    if (!v.classList.contains("hide")) {
      v.classList.add("hide")
    }
    else {
      v.classList.remove("hide")
      v.classList.add("show")
    }
    if (!img.classList.contains("hide")){
      img.classList.add("hide")
      img2.classList.remove("hide")
      img2.classList.add("show")
      
    }
    else {
      img.classList.remove("hide")
      img.classList.add("show")
      img2.classList.add("hide")
    }
  }
  render() {
    return (
      <>
        {this.props.data.map(item => {
          return (
            <div >
                <div className="switcher faq-questions-header" onClick={(e) => this.handleAnswers(item.id)}>
                  <span style={{color:"rgb(52, 182, 206)",fontSize:18}}>
                   {item.question}
                  </span>
                  <img style={{ marginLeft: 20 }} id={`img${item.id}`} className="hide" src={`/img/icons/arrow-down-blue2.svg`} alt={"Click to see answer"} />
                  <img style={{ marginLeft: 20 }} id={`img2${item.id}`} className="show" src={`/img/icons/arrow-right.svg`} alt={"Click to see answer"} />
                </div>
                <div id={`id${item.id}`} className="hide" style={{ marginTop: -30 }} >
                  <div className=" answers" >
                    <p style={{fontFamily: 'RobotoMedium', fontSize: 15}}>
                      {item.answer}
                    </p>
                  </div>
                </div>
            </div>
          )
        })
        }
      </>
    );
  }
}

export default FAQComponent;
