/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { VenueWizardStep } from "./Reducers";
import { venueWizardSetStep, venueTypeForm } from "./Actions";
import AddSpaceComponent from "./AddSpaceComponent";
import VenueSteps from "./VenueSteps";
import Footer from "../../footer";
import WorkspacesList from "../WorkspaceWizard/components/WorkspaceList";
import { VenueApi } from "../../API/VenueApi";
import {
  getVenuesEffect,
  publishVenue
} from "../VenueWizard/Actions";
import Confirmation from "../../Screens/Dashboard/modals/Confirmation";
import { toast } from "react-toastify";
import { setSpaceFlow } from "../WorkspaceWizard/Actions";
import { Helmet } from "react-helmet";
class AddWorkspaceStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number_of_workspace: Number(
        window.localStorage.getItem("number_of_workspace")
      ),
      venue: null,
      vid: this.props.venueId,
      message: "Are you sure you want to delete this space?",
      header: "DELETE SPACE",
      id: 0,
      isConfimrationBoxOpen: false
    };
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.venues.length !== this.props.venues.length ||
      this.check(nextProps)
    ) {
      let venue = this.props.venues.find(
        v => v.id === Number(this.props.venueId)
      );
      this.setState({ venue: venue });
    }
  }
  componentDidMount() {
    let venue = this.props.venues.find(
      v => v.id === Number(this.props.venueId)
    );
    this.setState({ venue: venue });
    window.localStorage.removeItem("vid");
  }
  check = nextProps => {
    let venue1 = this.props.venues.find(
      v => v.id === Number(this.props.venueId)
    );
    let nextVenue = nextProps.venues.find(
      v => v.id === Number(this.props.venueId)
    );
    if (venue1 && venue1.spaces.length !== nextVenue.spaces.length) {
      return true;
    }
    return false;
  };

  handleHide = () => {
    this.setState({ isConfimrationBoxOpen: false });
  };
  openConfirmationBox = async id => {
    this.setState({ id: id });
    this.setState({ isConfimrationBoxOpen: true });
  };
  handleDeleteSpace = async id => {
    if (this.state.isConfimrationBoxOpen) {
      await VenueApi.deleteSpace(this.props.user, id);
      this.props.getVenuesEffect();
      toast.success("Space Deleted");
      this.setState({ isConfimrationBoxOpen: false });
    }
  };
  handleAddMore = () => {
    this.setState({
      number_of_workspace: Number(this.state.number_of_workspace) + 1
    });
    window.localStorage.setItem(
      "number_of_workspace",
      Number(this.state.number_of_workspace + 1)
    );
  };
  handleAddSpace = () => {
    let number_ws =
      Number(window.localStorage.getItem("number_of_workspace")) - 1;
    window.localStorage.setItem("number_of_workspace", number_ws);
    this.props.setSpaceFlow();
    this.props.history.push({
      pathname: "/create-workspace",
      state: { detail: "some_value" }
    });
  };
  handleBack = () => {
    // this.props.venueWizardSetStep(VenueWizardStep.Types);
  };
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>
            {window.location.href.indexOf("edit-workspace") > -1
              ? "Dorpee - WorkSpace - 1 "
              : "Dorpee - List Your Venue - Workspace Type"}
          </title>
        </Helmet>
        <div className="body">
          <div className="container containerAddSpace bodyTop">
            <VenueSteps step={5}></VenueSteps>
            <div
              class="page-wrapper"
              style={{
                marginTop: 25,
                boxShadow: "1px 3px 8px #336873"
              }}
            >
              <div class="md-title">
                <div
                  class="row"
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div class="col-md-8">
                    <div class="is-title">
                      <h1>Add each of your Workspace Types</h1>
                      <p
                        style={{
                          color: "#7872fc",
                          fontSize: 21,
                          fontWeight: 500
                        }}
                      >
                        Specify Workspaces
                      </p>
                    </div>
                  </div>
                  {/* <div
                    class="col-md-4"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div class="link" style={{ textAlign: "right" }}>
                      <a
                        className="skipText"
                        onClick={() => {
                          this.props.publishVenue(true);
                          this.props.venueWizardSetStep(
                            VenueWizardStep.Preview
                          );
                        }}
                        href="#"
                        style={{ color: "#09324D" }}
                      >
                        Skip for now
                      </a>
                      <div className="md-sidebar">
                        <div
                          className="name"
                          style={{
                            padding: "10px",
                            borderRadius: "8px",
                            textAlign: "right",
                          }}
                        >
                          <p className="register-tip">
                            If you choose to skip, your venue will not be
                            published until you create at least one workspace
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="bl-list-workspace">
                <ul>
                  {this.state.venue &&
                    this.state.venue.spaces.map(item => {
                      return (
                        <WorkspacesList
                          openConfirmationBox={
                            this.openConfirmationBox
                          }
                          space={item}
                          id={this.props.venueId}
                        ></WorkspacesList>
                      );
                    })}

                  {Number(this.state.number_of_workspace) > 0
                    ? Array.from(
                        Array(
                          Number(this.state.number_of_workspace) === 0
                            ? 1
                            : Math.trunc(
                                this.state.number_of_workspace
                              )
                        ),
                        (e, i) => {
                          return (
                            <AddSpaceComponent
                              index={i}
                              handleAddSpace={this.handleAddSpace}
                            ></AddSpaceComponent>
                          );
                        }
                      )
                    : null}
                </ul>
                {/* <div class="add-more clearfix">
                  <button
                    onClick={() => this.handleAddMore()}
                    class="btn btn-add-more"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    Add one more
                  </button>
                </div>
            */}
              </div>
              <div class="bl-buttons clearfix">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-8">
                    <a
                      href="#"
                      onClick={() => {
                        this.props.venueWizardSetStep(
                          VenueWizardStep.Preview
                        );
                        // this.props.history.push(`/venue-preview/${this.props.venueId}`)
                      }}
                      class="btn btn-next"
                    >
                      {/* <span style={{ padding: " 12px 30px 12px 70px" }}>
                      Preview 
                    </span> */}
                      <span>Preview Listing</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isConfimrationBoxOpen ? (
            <Confirmation
              message={this.state.message}
              decline={this.handleHide}
              accept={this.handleDeleteSpace}
              id={this.state.id}
              modalHeader={this.state.header}
              acceptText={"Yes"}
              declineText={"No"}
              showButtons={true}
            ></Confirmation>
          ) : null}
          <Footer isSubs />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  number_of_space: state.venueWizard.venueType,
  venues: state.venueWizard.venues,
  user: state.auth.token
});

const mapDispatchToProps = {
  venueWizardSetStep,
  venueTypeForm,
  getVenuesEffect,
  publishVenue,
  setSpaceFlow
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddWorkspaceStep);
