/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { VenueWizardStep } from "./Reducers";
import { venueWizardSetStep, venueTypeForm } from "./Actions";
import AddSpaceComponent from "./AddSpaceComponent";
import WorkspacesList from "../WorkspaceWizard/components/WorkspaceList";
import { withRouter } from "react-router-dom";
import { VenueApi } from "../../API/VenueApi";
import { getVenuesEffect } from "../VenueWizard/Actions";
import Confirmation from "../../Screens/Dashboard/modals/Confirmation";
import { toast } from "react-toastify";
import { setSpaceFlow } from "../WorkspaceWizard/Actions";
class AddWorkspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number_of_workspace: Number(
        window.localStorage.getItem("number_of_workspace")
      ),
      venue: null,
      vid: this.props.match.params.id,
      message: "Are you sure you want to delete this space?",
      header: "DELETE SPACE",
      id: 0,
      isConfimrationBoxOpen: false,
    };
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.venues.length !== this.props.venues.length ||
      this.check(nextProps)
    ) {
      let venue = this.props.venues.find(
        (v) => v.id === Number(this.props.match.params.id)
      );
      this.setState({ venue: venue });
    }
  }
  componentDidMount() {
    let venue = this.props.venues.find(
      (v) => v.id === Number(this.props.match.params.id)
    );
    this.setState({ venue: venue });
    window.localStorage.removeItem("vid");
  }
  check = (nextProps) => {
    let venue1 = this.props.venues.find(
      (v) => v.id === Number(this.props.match.params.id)
    );
    let nextVenue = nextProps.venues.find(
      (v) => v.id === Number(this.props.match.params.id)
    );
    if (venue1 && venue1.spaces.length !== nextVenue.spaces.length) {
      return true;
    }
    return false;
  };

  handleHide = () => {
    this.setState({ isConfimrationBoxOpen: false });
  };
  openConfirmationBox = async (id) => {
    this.setState({ id: id });
    this.setState({ isConfimrationBoxOpen: true });
  };
  handleDeleteSpace = async (id) => {
    if (this.state.isConfimrationBoxOpen) {
      await VenueApi.deleteSpace(this.props.user, id);
      this.props.getVenuesEffect();
      toast.success("Space Deleted");
      this.setState({ isConfimrationBoxOpen: false });
    }
  };
  handleAddMore = () => {
    this.setState({
      number_of_workspace: Number(this.state.number_of_workspace) + 1,
    });
    window.localStorage.setItem(
      "number_of_workspace",
      Number(this.state.number_of_workspace + 1)
    );
  };
  handleAddSpace = () => {
    let number_ws =
      Number(window.localStorage.getItem("number_of_workspace")) - 1;
    window.localStorage.setItem("number_of_workspace", number_ws);
    window.localStorage.setItem("venue_id", this.props.match.params.id);
    this.props.setSpaceFlow();
    this.props.history.push("/create-workspace");
  };
  handleBack = () => {
    // this.props.venueWizardSetStep(VenueWizardStep.Types);
  };
  render() {
    return (
      <div style={{ position: "relative", top: 50 }}>
        <div class="container ">
          {this.state.venue ? (
            <div
              class="page-wrapper"
              style={{ marginTop: 25, boxShadow: "1px 3px 8px #336873" }}
            >
              <div class="md-title">
                <div
                  class="row"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div class="col-md-8">
                    <div class="is-title">
                      <h1 className="register-title">List Your Venue</h1>
                      <p className="register-sub-title">Specify Workspaces</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bl-list-workspace">
                <ul>
                  {this.state.venue &&
                    this.state.venue.spaces.map((item) => {
                      return (
                        <WorkspacesList
                          openConfirmationBox={this.openConfirmationBox}
                          space={item}
                          id={this.props.match.params.id}
                        ></WorkspacesList>
                      );
                    })}

                  {Number(this.state.number_of_workspace) > 0
                    ? Array.from(
                        Array(
                          Number(this.state.number_of_workspace) === 0
                            ? 1
                            : Math.trunc(this.state.number_of_workspace)
                        ),
                        (e, i) => {
                          return (
                            <AddSpaceComponent
                              index={i}
                              handleAddSpace={this.handleAddSpace}
                            ></AddSpaceComponent>
                          );
                        }
                      )
                    : null}
                </ul>
                <div class="add-more clearfix">
                  <button
                    onClick={() => this.handleAddMore()}
                    class="btn btn-add-more"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {" "}
                    + Add one more
                  </button>
                </div>
              </div>
              <div class="bl-buttons clearfix">
                <div class="row">
                  <div class="col-4"></div>
                  <div class="col-8">
                    <a
                      href="#"
                      onClick={() => {
                        this.props.venueWizardSetStep(VenueWizardStep.Preview);
                        this.props.history.push(
                          `/venue-preview/${this.props.match.params.id}`
                        );
                      }}
                      class="btn btn-next"
                    >
                      <span style={{ padding: " 12px 30px 12px 70px" }}>
                        Preview
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              404 Not Found{" "}
            </h1>
          )}
        </div>
        {this.state.isConfimrationBoxOpen ? (
          <Confirmation
            message={this.state.message}
            decline={this.handleHide}
            accept={this.handleDeleteSpace}
            id={this.state.id}
            modalHeader={this.state.header}
            acceptText={"Yes"}
            declineText={"No"}
            showButtons={true}
          ></Confirmation>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  number_of_space: state.venueWizard.venueType,
  venues: state.venueWizard.venues,
  user: state.auth.token,
});

const mapDispatchToProps = {
  venueWizardSetStep,
  venueTypeForm,
  getVenuesEffect,
  setSpaceFlow,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddWorkspace)
);
