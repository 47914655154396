import React from "react";
import useBodyClass from "../../hooks/useBodyClass";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { User } from "../../API/AuthApi";

const AddVenue = () => {
  useBodyClass("bgr-blue");
  const history = useHistory();
  const user = useSelector<AppState, User>(app => app.auth.user!);

  return (
    <>
      <div className="wrapper wrapper-login">
        <Link className="logo-login" to="/">
          <img src="/img/logo.png" alt="Dorpee" />
        </Link>
        <div className="wizard pt-40 mt-65">
          <div className="wizard-avatar">
            <img
              src={user.avatar ? user.avatar : "/img/icons/user-info.svg"}
              alt=""
            />
          </div>
          <div className="wizard-greetings">
            <h2>Hi, {user.first_name}!</h2>
            <span>You have signed up as a venue owner</span>
          </div>
          <button
            onClick={() => history.push("/create-venue")}
            className="btn btn-blue wizard__btn mb-0"
          >
            ADD VENUE
            <img
              className="wizard__btn--img-left"
              src="/img/icons/add-venue.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default AddVenue;
