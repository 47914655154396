import React, { FC } from "react";
import { Field } from "redux-form";

import MultiCheckBoxNew from "../../../UI/MultiCheckBoxNew";

interface Props {
  environments: any[];
}

const WorkspaceEnvironment: FC<Props> = ({ environments }) => {
  return (
    <div className="md-environment">
      <Field
        type="checkbox"
        name="environments"
        checkboxes={environments.map((t) => ({
          label: t.environment,
          value: t.id.toString(),
        }))}
        component={MultiCheckBoxNew}
        type1={"Environment"}
      />
    </div>
  );
};

export default WorkspaceEnvironment;
