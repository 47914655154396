import React from "react";
import { Modal } from "../../../UI/Modal";
import store from "../../../store";
import { DashboardApi } from "../../../API/DashboardApi";
import { toast } from "react-toastify/index";

class SetCredits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      credits: 0,
      errors: false,
    };
  }

  changeCredits(e) {
    this.setState({
      credits: e.target.value,
    });
  }

  isFormValid() {
    let res = true;
    if (!/^\d+$/.test(this.state.credits)) {
      this.setState({ errors: true });
      res = false;
    }
    return res;
  }

  hideModal() {
    this.props.showHideModal("editEmployer", false);
  }

  updateEmployer() {
    if (this.isFormValid()) {
      let data = {
        credit: this.state.credits,
        customer_id: this.props.customerId,
      };
      this.props.updateEmployer(data);
      this.hideModal();
    }
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>CREDIT LIMIT</span>
              <div
                onClick={this.hideModal.bind(this, "editEmployer", false)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <div className="input__icon--coins"></div>
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input noButtons"
                  type="number"
                  placeholder="Credits"
                  id="credits"
                  onInput={this.changeCredits.bind(this)}
                />
                {this.state.errors ? (
                  <p className="contact-form-error">Invalid value</p>
                ) : null}
              </div>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.updateEmployer.bind(this)}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default SetCredits;
