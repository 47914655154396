import React, { useEffect } from "react";
import { destroy } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { AppState } from "../../store";
import { VenueWizardStep } from "./Reducers";
import {
  GetVenueOpenHoursEffect,
  getVenuesEffect,
  getTypesEffect
} from "./Actions";
import SuccessMessage from "./SuccessMessage";
import {
  loadVenueForEditEffect,
  venueWizardReset,
  isVenueLoaded
} from "./Actions";
import VenueOpenHours from "./VenueOpenHours";
import VenueBasicInfo from "./VenueBasicInfo";
import VenueAmenities from "./VenueAmenities";
// import VenueWorkspaceType from "./VenueWorkspaceType";
import VenueBonusOffer from "./VenueBonusOffer";
import AddWorkspaceStep from "./AddWorkspaceStep";
import CreateWorkspace from "../../Screens/WorkspaceWizard/CreateWorkspace";
import PreviewWorkspace from "../../Screens/WorkspaceWizard/PreivewWorkspace.jsx";

import "../../Screens/venue-style.css";

const VenueWizard = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const step = useSelector(
    (state: AppState) => state.venueWizard.step
  );
  let venueDetailsFields = useSelector(
    (state: AppState) => state.venueWizard.detailsFieldsVenue
  );
  const amenities = useSelector(
    (state: AppState) => state.venueWizard.venuesAmenities
  );
  const user = useSelector((state: AppState) => state.auth.user);
  const userRoles = useSelector(
    (state: AppState) => state.auth.userRoles
  );
  const history = useHistory();
  useEffect(() => {
    dispatch(getTypesEffect());
    dispatch(GetVenueOpenHoursEffect());
    dispatch(getVenuesEffect());
  }, []);
  useEffect(() => {
    dispatch(venueWizardReset());
    if (id) {
      dispatch(isVenueLoaded(true));
      dispatch(loadVenueForEditEffect(id));
    }
    return () => {
      dispatch(venueWizardReset());
      dispatch(destroy("venueDetails"));
      dispatch(destroy("venueImages"));
    };
  }, []);
  useEffect(() => {
    if (userRoles) {
      if (userRoles !== "Venue") {
        history.push("/");
      }
    }
  }, [userRoles]);
  const getCurrentStep = () => {
    // if (userRoles && userRoles == "Venue") {
    switch (step) {
      // case VenueWizardStep.Types:
      //   return (
      //     <VenueWorkspaceType
      //       venueId={id}
      //       isEdit={venueDetailsFields._method === "PUT" ? true : false}
      //       history={history}
      //     ></VenueWorkspaceType>
      //   );
      case VenueWizardStep.BonusOffer:
        return (
          <VenueBonusOffer
            venueId={id}
            history={history}
            isEdit={
              venueDetailsFields._method === "PUT" ? true : false
            }
          ></VenueBonusOffer>
        );
      case VenueWizardStep.Amenities:
        return (
          <VenueAmenities initialValues={{ amenities: amenities }} />
        );
      case VenueWizardStep.AddSpace:
        return <AddWorkspaceStep venueId={id} history={history} />;
      case VenueWizardStep.OpenHours:
        return <VenueOpenHours venueId={id} history={history} />;
      case VenueWizardStep.CreateSpace:
        return <CreateWorkspace venueId={id} history={history} />;
      case VenueWizardStep.Preview:
        return (
          <PreviewWorkspace
            venueId={id}
            isTypeStep={true}
            history={history}
            btnSave={"Save as Draft"}
            btnText={"Publish Venue"}
          ></PreviewWorkspace>
        );
      case VenueWizardStep.Success:
        return (
          <SuccessMessage
            venueId={id ? id : ""}
            isEdit={id ? true : false}
            isTypeStep={true}
          />
        );
      default:
        return (
          <VenueBasicInfo
            isEdit={id ? true : false}
            // initialValues={venueDetailsFields}
            userEmail={user && user.email}
          ></VenueBasicInfo>
        );
    }
    // } else {
    //   return <>{history.push("/home")}</>;
    // }
  };
  return getCurrentStep();
};

export default VenueWizard;
