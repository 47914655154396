import React, { Component } from "react";
import FilePreview from "react-preview-file";
import "../../Screens/venue-style.css";
import { connect } from "react-redux";
import _ from "lodash";
import ViewMoreButton from "../VenueWizard/Component/ViewMoreButton"
import WorkspacesAssets from "../VenueWizard/Component/WorkspacesAssets"
class showWorkspaces extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  mapVenueType = id => {
    const venueType = this.props.venueTypes.find(t => t.id === parseInt(id));
    return venueType ? venueType.type : "";
  };

  mapEnvironment = id => {
    const environment = this.props.environments.find(
      t => t.id === parseInt(id)
    );

    return environment ? environment.environment : "";
  };

  getAssets = id => {
    const asset = this.props.assets.find(a => a.id === parseInt(id));
    return asset ? asset.asset : "";
  };
  getServices = id => {
    const asset = this.props.services.find(a => a.id === parseInt(id));
    return asset ? asset.service : "";
  };
  render() {
    return (
      <li>
        <div className="row">
          <div className="col-md-6">
            {this.props.detailsFields.image1 &&
              <FilePreview file={this.props.detailsFields.image1}>
                {(preview) => (
                  <div className="thumbnail">
                    <img src={preview} alt="Workspace Image" />
                  </div>
                )}
              </FilePreview>
            }
          </div>
          <div className="col-md-6">
            <div className="workspace-detail">
              <div className="sub-title">
                <p>{this.props.venueName && this.props.venueName}</p>
              </div>
              <div className="title">
                <p>{this.props.detailsFields.name}</p>
              </div>
              <div className="meta clearfix">
                <div className="row">
                  <div className="col-lg-8">
                    <ul className="smd-tag-normal clearfix">
                      {this.props.detailsFields.types
                        .map(this.mapVenueType)
                        .map((e) => {
                          return (
                            <li className="active">
                              <a href="#">
                                <span>
                                  <img src="/img/ico-type-07.png" alt="" />
                                </span>
                                <span>{e}</span>
                              </a>
                            </li>
                          );
                        })}
                      {this.props.detailsFields.environments
                        .map(this.mapEnvironment)
                        .map((e) => {
                          return (
                            <li className="">
                              <a href="#">
                                <span>{e}</span>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="col-lg-4">
                    <div className="room-detail">
                      <div className="room-number">
                        <span>{this.props.detailsFields.quantity}</span>
                      </div>
                      <div className="number">
                        <span className="image">
                          <img src="/img/ico-people.png" alt="" />
                        </span>
                        <span className="image">
                          <img src="/img/ico-people.png" alt="" />
                        </span>
                        <span className="image">
                          <img src="/img/ico-people.png" alt="" />
                        </span>
                        <span className="is-number">
                          {this.props.detailsFields.capacity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ViewMoreButton id="newSpace" assets={this.props.assets} selectedAssets={this.props.detailsFields.assets} description={this.props.detailsFields.description} />
              <WorkspacesAssets workspace={this.props.detailsFields} assets={this.props.assets} selectedAssets={this.props.detailsFields.assets}></WorkspacesAssets>
              {/* <div className="meta-bottom">
                <div className="row">
                  <div className="col-md-7 col-lg-5">
                    <div className="point">
                      <span className="number">
                        {" "}
                        {this.props.detailsFields.price &&
                        !isNaN(
                          Math.ceil(
                            parseInt(this.props.detailsFields.price) / 0.3
                          )
                        )
                          ? Math.ceil(
                              parseInt(this.props.detailsFields.price) / 0.3
                            )
                          : "0"}{" "}
                      </span>
                      <span className="slash">credits /</span>
                      <span>per hour</span>
                      <span className="tooltip">
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </li>
    );
  }
}
const mapStateToProps = state => ({
  venues: state.workspaceWizard.venues,
  environments: state.workspaceWizard.environments,
  venueTypes: state.workspaceWizard.venueTypes,
  assets: state.workspaceWizard.assets,
  services: state.venueWizard.services

});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(showWorkspaces);
