import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Modal } from "../../../UI/Modal";

const AddEmployerAdmin = ({ handleSubmit, hideModal }) => {
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [loading, setLoading] = useState();

  const employeeList = useSelector(
    (state) => state.auth.user.organisation.customers
  );

  const handleCloseModal = () => {
    hideModal(false);
  };

  const handleAccept = async () => {
    setLoading(true);
    let empid;
    if (!selectedEmployee) {
      empid = employeeList[0].id;
    } else {
      empid = selectedEmployee;
    }
    handleSubmit(empid);
  };

  const handleSelect = (e) => {
    setSelectedEmployee(e.target.value);
  };
  return (
    <>
      <Modal>
        <div className="addressbook-form-box">
          <div className="congrat-box__header">
            <span>Employer Admin</span>
            <div onClick={handleCloseModal} className="close-btn"></div>
          </div>
          <div className="addressbook-form">
            <select
              name="employee_role_id"
              className="btn btn-white wizard__btn mb-0 pl-12"
              onChange={handleSelect}
            >
              <option value={0} disabled>
                Select Employee
              </option>
              {employeeList &&
                employeeList.map((data, i) => {
                  return (
                    <option
                      value={data.id}
                      key={i}
                      selected={selectedEmployee === data.id}
                    >
                      {data.full_name}
                    </option>
                  );
                })}
            </select>
            <button
              className={
                loading
                  ? "btn btn-disable wizard__btn btn--loadingNew"
                  : "btn addressbook-modal-btn addressbook-submit-btn"
              }
              onClick={handleAccept}
              style={{ marginTop: "25px" }}
            >
              Yes
            </button>
            <button
              className="btn addressbook-modal-btn addressbook-submit-btn"
              onClick={handleCloseModal}
              style={{ marginTop: "25px" }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEmployerAdmin;
