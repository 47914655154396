/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getVenuesEffect } from "../../Screens/VenueWizard/Actions";
import SpaceAvailabilityModal from "../Dashboard/modals/SpaceAvailabilityModal";
import {
  GetSpaceAvailabilityEffect,
  DeleteSpaceAvailabilityEffect,
  GetSpaceAvailabilityByIdEffect
} from "../WorkspaceWizard/Actions";
import { getFormated12hTime } from "../../../src/helpers/DateHelpers";
import Confirmation from "../../Screens/Dashboard/modals/Confirmation";
class WorkspaceAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isConfimrationBoxOpen: false,
      id: 0,
      quantity: 0
    };
  }
  handleModal = () => {
    window.localStorage.removeItem("editSpaceAvailability");
    this.setState({ isModalOpen: true });
  };
  CloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    this.props.GetSpaceAvailabilityEffect();
  }
  openConfirmationBox = id => {
    this.setState({ id: id });
    this.setState({ isConfimrationBoxOpen: true });
  };
  hideDeleteModal = () => {
    this.setState({ isConfimrationBoxOpen: false });
  };
  handleDeleteSpaceAvailability = () => {
    this.setState({ isConfimrationBoxOpen: false });
    this.props.DeleteSpaceAvailabilityEffect(this.state.id);
  };
  handleEdit = id => {
    window.localStorage.setItem("editSpaceAvailability", id);
    this.props.GetSpaceAvailabilityByIdEffect(id);
    setTimeout(() => {
      this.setState({ id: id, isModalOpen: true });
    }, 800);
  };
  handleQuantity = id => {
    let value;
    this.props.venues.map(v => {
      v.spaces.map(s => {
        if (s.id === parseInt(id)) {
          value = s.quantity;
        }
      });
    });
    return value;
  };
  render() {
    return (
      <div className="container pd-0">
        <div className="container" style={{ marginTop: 30 }}>
          <div className="row pt-4">
            <div className="col-md-8 content-title">
              {" "}
              WORKSPACE TYPE AVAILABILITY
            </div>
            {/* <div className="col-md-4">
              <button type="submit" onClick={() => this.handleModal()} className={`btn btn-blue-venue`}>
              Add Workspace Availability
                </button>
            </div> */}
          </div>
        </div>
        <div className="booking_table_margins">
          <div className="team-list ">
            <table className="team-list-table table-style">
              <thead>
                <tr>
                  <th className="table-head">
                    Workspace Type Name (Venue)
                  </th>
                  <th className="table-head">Number of Workspaces</th>
                  <th className="table-head">Days</th>
                  <th className="table-head">Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.props.workspacesBlock.map(item => {
                  return (
                    <tr>
                      <td
                        style={{ height: 50 }}
                        className="table-style"
                      >
                        {item.space.name}({item.space.venue.name})
                      </td>
                      <td className="table-style">
                        {this.handleQuantity(item.space_id)}
                      </td>

                      <td className="table-style">
                        {Object.keys(item.availability[0])[0]}-{" "}
                        {
                          Object.keys(
                            item.availability[
                              item.availability.length - 1
                            ]
                          )[0]
                        }
                      </td>
                      <td className="table-style">
                        <div
                          style={{
                            height: 20,
                            flexDirection: "row",
                            display: "flex"
                          }}
                        >
                          <a
                            onClick={() => {
                              this.handleEdit(item.space_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              className="clock-icon"
                              src="/img/icons/clock.svg"
                              alt=""
                              title={"Workspace availability"}
                            ></img>
                          </a>
                          <p
                            className="content-title__edit"
                            style={{ cursor: "pointer" }}
                            onClick={_e =>
                              this.openConfirmationBox(item.id)
                            }
                          >
                            {/*<div className="dashboard__delete"></div>*/}
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {this.state.isModalOpen ? (
          <SpaceAvailabilityModal
            spaceId={this.state.id}
            CloseModal={this.CloseModal}
          />
        ) : null}
        {this.state.isConfimrationBoxOpen ? (
          <Confirmation
            message={"Are you sure you want to delete this?"}
            decline={this.hideDeleteModal}
            accept={this.handleDeleteSpaceAvailability}
            id={this.state.id}
            modalHeader={"DELETE SPACE AVAILABILITY"}
            acceptText={"Yes"}
            declineText={"No"}
            showButtons={true}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  venues: state.venueWizard.venues,
  workspacesBlock: state.workspaceWizard.workSpacesBlocks
});

const mapDispatchToProps = {
  getVenuesEffect,
  GetSpaceAvailabilityEffect,
  DeleteSpaceAvailabilityEffect,
  GetSpaceAvailabilityByIdEffect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceAvailability);
