import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import { createCreditEffect } from "../../PaymentWizard/Actions";

class EmployerBookingModal extends Component {
  constructor(props) {
    super(props);
  }

  togglePersonalBooking = () => {
    this.props.handlePersonalBooking(false);
    this.props.handleBook();
  };

  render() {
    return (
      <>
        <Modal>
          <div className="employer-alert-box">
            <div className="congrat-box__header">
              <span>Confirmation</span>
            </div>
            <div className="booking-search__tags-title">
              Employer does not have enough credits to place the
              booking.
            </div>
            <div className="button-style">
              <button
                onClick={this.togglePersonalBooking}
                className="btn btn-blue wizard__btn mb-0 btn-width book-personal-btn"
              >
                Book As Personal
              </button>
              <button
                onClick={() => this.props.handleToggleModal(false)}
                className="btn btn-white wizard__btn mb-0 btn-width "
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading
});

const mapDispatchToProps = {
  createCreditEffect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerBookingModal);
