/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Helmet from "react-helmet";

import {
  getVenuesEffect
  // setVenueIdFields
} from "./VenueWizard/Actions";
import { AppState } from "../store";
import { VenueApi } from "./../API/VenueApi";
import Confirmation from "../Screens/Dashboard/modals/Confirmation";
import ChangeStatusModal from "../Screens/Dashboard/modals/ChangeStatusModal";
import VenueWorkspaceItems from "../Screens/Component/VenueWorkspaceItems";
import UpdateWifi from "./Dashboard/modals/UpdateWifi";
import { setLoader } from "./Loader/Actions";

const Venues: FC = () => {
  const dispatch = useDispatch();
  const venues = useSelector(
    (state: AppState) => state.venueWizard.venues
  );

  const userToken = useSelector((state: AppState) => state.auth);
  const isVenueUser =
    useSelector((state: AppState) => state.auth.userRoles) === "Venue"
      ? true
      : false;
  const isAuthenticated = !!useSelector(
    (state: AppState) => state.auth.user
  );
  const [isConfimrationBoxOpen, setisConfimrationBoxOpen] =
    useState(false);
  const [isStatusModalOpen, setisConfimrationBoxOpen2] =
    useState(false);
  const [id, setid] = useState<Number>(0);
  const [canPublish, setCanPublish] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setheader] = useState("");
  const history = useHistory();
  const [venueDataWifi, setVenueData] = useState({
    wifi_name: "",
    wifi_password: "",
    venueId: 0
  });
  const [isWifiModalOpen, setWifiModal] = useState(false);

  useEffect(() => {
    dispatch(getVenuesEffect());
  }, [isStatusModalOpen, isWifiModalOpen]);

  const hideModal = () => {
    setMessage("");
    setisConfimrationBoxOpen(false);
    setisConfimrationBoxOpen2(false);
  };

  const openConfirmationBox = async (id: any) => {
    setid(id);
    setMessage("Are you sure you want to delete this venue");
    setheader("DELETE VENUE");
    setisConfimrationBoxOpen(true);
  };

  const openConfirmationBox2 = async (id: number, spaces: any) => {
    setid(id);
    setCanPublish(spaces);
    setheader("VENUE STATUS");
    setisConfimrationBoxOpen2(true);
  };
  const handleDeleteVenue = async (venueDetails: any) => {
    if (isConfimrationBoxOpen) {
      dispatch(setLoader(true));
      if (venueDetails.spaces.length >= 1) {
        toast.error("Cannot Delete Venue having Space");
        dispatch(setLoader(false));
        return;
      }
      const response: any = await VenueApi.deleteVenue(
        userToken.token!,
        venueDetails.id
      );
      if (response.success == "Deleted Successfully") {
        setisConfimrationBoxOpen(false);
        toast.success("Venue Deleted");
        dispatch(getVenuesEffect());
        dispatch(setLoader(false));
      } else {
        if (response.error === "Booking Exist.") {
          toast.error(
            "Cannot Delete Venue, Booking Exists for Venue"
          );
        } else {
          toast.error("Something Went Wrong");
        }
        dispatch(setLoader(false));
      }
    }
  };
  const openUpdateWifiModal = (v: any) => {
    setVenueData({
      wifi_name: v.wifi_name,
      wifi_password: v.wifi_password,
      venueId: v.id
    });
    setWifiModal(true);
  };
  const closeModalWifi = () => {
    setWifiModal(false);
    dispatch(getVenuesEffect());
  };
  if (isAuthenticated && isVenueUser) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Venue Dashboard - Active Venues</title>
        </Helmet>
        <div className="container" style={{ marginTop: 70 }}>
          <div className="row pt-4">
            <div className="col-md-6 content-title">
              {" "}
              MY VENUES & WORKSPACES
            </div>
            <div className="col-md-6">
              <div
                className="row myvenues-btns"
                style={{
                  display: "flex",
                  justifyContent:
                    venues && venues.length > 0
                      ? "flex-start"
                      : "flex-end"
                }}
              >
                <div className="col-6 ">
                  <Link to="/create-venue">
                    <button
                      type="submit"
                      style={{
                        fontSize: 14
                      }}
                      className={`btn btn-blue`}
                    >
                      Create Venue
                    </button>
                  </Link>
                </div>
                {venues && venues.length > 0 ? (
                  <div className="col-6">
                    <button
                      onClick={() => {
                        window.localStorage.removeItem("venue_id");
                        history.push("/create-workspace");
                      }}
                      type="submit"
                      style={{
                        fontSize: 14
                      }}
                      className={`btn btn-blue`}
                    >
                      <span className="add-space-mbl">
                        {" "}
                        Add Workspace Type To A Venue
                      </span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginTop: 10 }}>
          <div className="bookings-wrap">
            <div>
              {venues && (
                <>
                  {venues.map((v, i) => (
                    <>
                      <div
                        key={i}
                        className="content-title venue-list-title"
                      >
                        <span className="venue-titles">{v.name}</span>
                        <a
                          className="content-title__edit"
                          href={`edit-venue/${v.id}`}
                          title="Edit Venue"
                        >
                          <div className="dashboard__edit edit-mbl"></div>
                        </a>
                        <a
                          className="content-title__edit"
                          title="Delete Venue"
                          onClick={e => openConfirmationBox(v)}
                        >
                          <div className="dashboard__delete edit-mbl"></div>
                        </a>
                        <div
                          onClick={e => openUpdateWifiModal(v)}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: 10
                          }}
                        >
                          <img
                            src={`/img/icons/${
                              v.wifi_name != null
                                ? "wifi-active.svg"
                                : "wifi-grey.svg"
                            }`}
                            alt=""
                            style={{
                              width: "30px",
                              cursor: "pointer"
                            }}
                            title={`Wifi Details`}
                          ></img>
                        </div>
                        <div>
                          {v.assessment! && v.assessment ? (
                            v && v!.assessment!.result === "Pass" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginLeft: 10
                                }}
                              >
                                <img
                                  src="/img/icons/shield-green.png"
                                  alt=""
                                  title={`OH&S Self Assessment was completed on the ${v!
                                    .assessment!.completed_date!}`}
                                ></img>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginLeft: "8px",
                                  marginTop: "-5px"
                                }}
                              >
                                <Link to={"/venue-assessment"}>
                                  <img
                                    src="/img/icons/shield-red.png"
                                    alt=""
                                    title={"Click to Complete OH&S"}
                                  ></img>
                                </Link>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginLeft: "8px",
                                marginTop: "-5px"
                              }}
                            >
                              <Link to={"/venue-assessment"}>
                                <img
                                  src="/img/icons/shield-red.png"
                                  alt=""
                                  title={"Click to Complete OH&S"}
                                ></img>
                              </Link>
                            </div>
                          )}
                        </div>
                        {/* {v.pdf_link ? (
                          <div
                            style={{
                              marginTop: -5,
                              left: 10,
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/img/scanner.png"
                              alt=""
                              title="Click to open the QR file"
                              onClick={() => {
                                if (v.pdf_link)
                                  window.open(v.pdf_link, "_blank");
                              }}
                            ></img>
                          </div>
                        ) : null} */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginLeft: "25px"
                          }}
                        >
                          <span
                            style={{
                              color:
                                v.spaces.length <= 0
                                  ? "#F5597F"
                                  : v.status === "Closed"
                                  ? "#FFCA18"
                                  : v.status === "Published"
                                  ? "#42D68B"
                                  : v.status === "Unpublished"
                                  ? "#F5597F"
                                  : ""
                            }}
                          >
                            Status:
                            {v.spaces.length <= 0
                              ? v.status == "Draft"
                                ? v.status
                                : "Unpublished"
                              : v.status}
                          </span>
                          <a
                            title="Change Venue Status"
                            className="dashboard__edit dashboard__extra"
                            onClick={e =>
                              openConfirmationBox2(
                                v.id,
                                v.spaces.length
                              )
                            }
                          ></a>
                        </div>
                      </div>
                      <VenueWorkspaceItems
                        venueId={v.slug}
                        key={v.id}
                        workspace={v}
                      />
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          {isConfimrationBoxOpen ? (
            <Confirmation
              message={message}
              decline={hideModal}
              accept={handleDeleteVenue}
              modalHeader={header}
              id={id}
              acceptText={"Yes"}
              declineText={"No"}
              showButtons={true}
            ></Confirmation>
          ) : null}

          {isStatusModalOpen ? (
            <ChangeStatusModal
              message={message}
              decline={hideModal}
              modalHeader={header}
              id={id}
              canPublish={canPublish}
              acceptText={"Yes"}
              declineText={"No"}
            ></ChangeStatusModal>
          ) : null}

          {isWifiModalOpen ? (
            <UpdateWifi
              venueData={venueDataWifi}
              handleToggleModal={closeModalWifi}
            ></UpdateWifi>
          ) : null}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default Venues;
