import React from "react";
import { Pie } from "react-chartjs-2";

class BookingDuration_Employer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [
        "< 30 mins",
        "1-2 hour",
        "3-4 hours",
        "> 5 hours",
        "multi-day"
      ],
      datasets: [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2"
            // "#5AAF10",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2"
            // "#5AAF10",
          ]
        }
      ]
    };
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.pie.total_duration !== this.props.pie.total_duration
    ) {
      const datasets = [
        {
          data: this.getData(),
          tooltips: {
            callbacks: {
              title: function (tooltipItem, data) {
                return "sad";
              },
              label: function (tooltipItem, data) {
                return "asdads";
              },
              afterLabel: function (tooltipItem, data) {
                var dataset = data["datasets"][0];
                var percent = Math.round(
                  (dataset["data"][tooltipItem["index"]] /
                    dataset["_meta"][0]["total"]) *
                    100
                );
                return "asd";
              }
            }
          },
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2"
            // "#5AAF10",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2"
            // "#5AAF10",
          ]
        }
      ];

      this.setState({
        datasets
      });
    }
  }

  getLabels() {
    let labels = [];

    let data = this.props.pie.total_duration;
    for (let i in data) {
      i = this.replaceLabel(i);
      labels.push(i);
    }
    return labels;
  }

  replaceLabel(label) {
    label = label.replace("duration_btw", "");
    label = label.replace("duration", "");
    label = label.replace("gt", "");
    label = label.split("_").join(" ");
    label = label.replace("  ", " ");
    label = label.trim();
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }

  getData() {
    let values = [];
    let data = this.props.pie.total_duration;
    for (let i in data) {
      if (i !== "all_zero") values.push(data[i]);
    }
    return values;
  }

  render() {
    let options = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              data["labels"][tooltipItem["index"]] +
              ": " +
              data["datasets"][0]["data"][tooltipItem["index"]] +
              "%"
            );
          }
        }
      }
    };
    return (
      <div className="venue-chart-small">
        <Pie
          height={250}
          data={this.state}
          options={options}
          legend={{
            labels: {
              boxWidth: 15
            },
            position: "bottom"
          }}
        />
      </div>
    );
  }
}

export default BookingDuration_Employer;
