import React, { FC, useEffect, useState } from "react";
import {
  getPlansEffect,
  setSelectedPlanEffect
} from "./SignUp/Actions";
import {
  cancelUserPlan,
  createPaymentEffect
} from "./PaymentWizard/Actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../store";
import { Plan } from "../API/EnumsApi";
import PlansItem from "./Component/PlansItem";
import PlansItemSub from "./Component/PlanItemSub";
import { Helmet } from "react-helmet";
import AddPromoModal from "./Dashboard/modals/AddPromoModal";

const SubscriptionPlans: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const plans = useSelector((state: AppState) => state.wizard.plans);
  const token = useSelector((state: AppState) => state.auth.token);
  const user = useSelector((state: AppState) => state.auth.user!);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  const [addPromo, setAddPromo] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const isSubscriptionCancelled = user && user.is_subscribed;
  useEffect(() => {
    dispatch(getPlansEffect());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.localStorage.removeItem("credit");
    window.localStorage.removeItem("card_status");
    window.localStorage.removeItem("isPlanName");
  }, []);
  const handlePlanSelection = async (plan: Plan) => {
    if (plan.name == "Intro" || plan.name == "Regular") {
      setAddPromo(true);
      setSelectedPlan(plan);
    } else {
      if (
        (user && user.plan_id && isSubscriptionCancelled) ||
        user!.card_status
      ) {
        await dispatch(
          createPaymentEffect({ plan_id: plan.id }, history, true)
        );
      } else {
        history.push("/payment");
        await dispatch(setSelectedPlanEffect(plan));
      }
    }
  };

  const handleCancelPlanSelection = async (plan: Plan) => {
    if (token) {
      await dispatch(cancelUserPlan(history));
    }
  };

  const showHideModal = (value: boolean) => {
    setAddPromo(value);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Dorpee Subscription" />
        <title>Monthly Workspace Memberships | Dorpee</title>
      </Helmet>
      <div className="container subs-plans__wrapper">
        <div className="content-wrapper__title">
          <h2 className="section__title">Subscriptions</h2>
        </div>
        <div className="pricing_desc">
          <h5>How It Works!</h5>
          <p>
            Dorpee is all about creating a system where everyone can
            thrive.
          </p>
          <ul className="pricing_list">
            <li>
              Subscribe to monthly credits, or top up required, and
              use these to book your workspaces.
            </li>
            <li>
              Filter according to your needs each day and select a
              spot that meets that for you.
            </li>
            <li>
              Workspaces set their own pricing and vary in credit
              value based on offering.
            </li>
          </ul>
          <p> Change or cancel your plan at any time!</p>
        </div>
        <div className="row no-gutters justify-content-center">
          {plans.map((plan: Plan, i: number) => {
            return user && user.plan ? (
              // plan.name !== "Free" &&
              // (user.plan.free_plan_status !== "used" ||
              //   user.plan.name !== "Free") ? (
              <PlansItem
                plan={plan}
                handlePlanSelection={handlePlanSelection}
                handleCancelPlanSelection={handleCancelPlanSelection}
                isLoading={isLoading}
                isSubscriptionCancelled={isSubscriptionCancelled}
                index={i}
                user={user}
              ></PlansItem>
            ) : (
              // ) : null
              <PlansItem
                index={i}
                isSubscriptionCancelled={isSubscriptionCancelled}
                plan={plan}
                handlePlanSelection={handlePlanSelection}
                handleCancelPlanSelection={handleCancelPlanSelection}
                isLoading={isLoading}
                user={user}
              ></PlansItem>
            );
          })}
        </div>
        <div
          className="plans"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 40,
            marginTop: 40
          }}
        >
          <PlansItemSub
            history={history}
            title={"Credit Purchase or Top Up"}
            description={
              "I want to buy credits as I need them, or I need to top up my plan with extra credits. From $49."
            }
            buttonText={"BUY CREDIT"}
          ></PlansItemSub>
          <PlansItemSub
            history={history}
            title={"Employer Plan"}
            description={
              "We have an organisation, business or team and we would like to bring our employees into the Dorpee Village."
            }
            isEnquireBtn
            buttonText={"Enquire Now"}
          ></PlansItemSub>
        </div>
        <div className="pricing-text" style={{ marginTop: 30 }}>
          <p style={{ margin: 0 }}>
            *Based on the average per/hour credit rate.
          </p>
          <p>
            **Rollover up to the total number of credits in your
            upcoming plan.
          </p>
        </div>
      </div>
      {addPromo ? (
        <>
          <AddPromoModal
            showHideModal={showHideModal}
            isSubscriptionCancelled={isSubscriptionCancelled}
            selectedPlan={selectedPlan}
            user={user && user}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SubscriptionPlans;
