/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Helmet from "react-helmet";
import InvitedCustomers from "./InvitedCustomers";
import AddEmployer from "./modals/AddEmployer";

import "./accordion.sass";

const EmployeeList = () => {
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [employerEditModal, setEmployerEditModal] = useState(false);
  const [deleteEmployeModal, setDeleteEmployeModal] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Employer Dashboard</title>
      </Helmet>
      <div className="container dashboard pd-0">
        <div
          className="container employer-stats__wrapper ed"
          style={{ marginBottom: "70px" }}
        >
          <InvitedCustomers
            openUpdateCustomerModal={setEmployerEditModal}
            deleteEmployer={setDeleteEmployeModal}
          />
        </div>
      </div>
      {addEmployeeModal ? (
        <AddEmployer
          addEmployer={addEmployeeModal}
          showHideModal={setAddEmployeeModal}
          {...this.state}
        />
      ) : null}
    </>
  );
};

export default EmployeeList;
