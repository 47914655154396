/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { History } from "history";
import {
  reduxForm,
  Field,
  FormErrors,
  WrappedFieldProps
} from "redux-form";

import { AppState } from "../../../store";
import {
  profileImagesDelete,
  updateEmployerCompanyInfo
} from "../../SignUp/Actions";
import { Modal } from "../../../UI/Modal";
import BasicField from "../../../UI/BasicField/BasicField";

export type OrganisationInfo = {
  company_name: string;
  logo?: File;
  _method: "PUT";
};

export const UserInfoModal = reduxForm<
  OrganisationInfo,
  {
    history: History;
    handleToggleModal: any;
  }
>({
  form: "OrganisationInfo",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: async (formFields, dispatch, props) => {
    dispatch(updateEmployerCompanyInfo(formFields));
    setTimeout(() => {
      props.handleToggleModal(false);
      if (window.location.pathname.includes("dashboard")) {
        window.location.reload(false);
      }
    }, 500);
  },

  validate: (values, props) => {
    const errors: FormErrors<OrganisationInfo> = {};
    // Company
    if (!values.company_name) {
      errors.company_name = "Required";
    }

    return errors;
  }
})(props => {
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: AppState) => state.auth.user
  );
  const personalSettingsFields = useSelector(
    (state: AppState) => state.wizard.personalSettingsFields
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );

  const [logo_name, setLogo_name] = useState("");

  useEffect(() => {
    userDetails!.organisation &&
      props.initialize({
        company_name: userDetails!.organisation.company_name,
        _method: "PUT"
      });
    if (
      userDetails &&
      userDetails.organisation &&
      userDetails.organisation.logo
    ) {
      setLogo_name(userDetails.organisation.logo.file_name);
    }
  }, [personalSettingsFields]);
  const handleSubmit = (values: any) => {
    props.handleSubmit(values);
  };
  return (
    <Modal>
      <div className="addressbook-form-box">
        <div className="congrat-box__header">
          <span>Edit Employer Info.</span>
          <div
            onClick={() => props.handleToggleModal(false)}
            className="close-btn"
          ></div>
        </div>
        <div
          className="addressbook-form"
          style={{ textAlign: "start" }}
        >
          <Form onSubmit={handleSubmit}>
            <label>Company Name</label>
            <Field
              name="company_name"
              component={BasicField}
              isRegister={true}
              type="text"
              placeholder="Company Name"
            />

            <div
              style={{
                marginBottom: "2rem",
                textAlign: "start"
              }}
            >
              <Field
                name="logo"
                placeholder="Choose File"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <>
                      <label>Upload Company logo</label>
                      <div
                        className="input-container"
                        style={{ marginTop: 5, padding: "5px" }}
                      >
                        {logo_name === "" ? (
                          <div
                            style={{
                              display: "flex",
                              maxHeight: "max-content",
                              position: "absolute",
                              alignItems: "center"
                            }}
                          >
                            <p
                              style={{
                                border: "0.5px solid black",
                                backgroundColor: "#efefef",
                                borderRadius: "0.2rem",
                                whiteSpace: "nowrap",
                                position: "relative",
                                padding: "2px 5px",
                                margin: 0
                              }}
                            >
                              Choose File
                            </p>
                            <p
                              style={{
                                margin: 0,
                                color: "#888888",
                                fontSize: "16px",
                                marginLeft: "2px"
                              }}
                            >
                              Upload Company logo
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <input
                          id="files"
                          type="file"
                          placeholder="Choose File"
                          accept="image/png, image/jpeg, image/jpg"
                          style={{
                            width: "100%",
                            zIndex: 999,
                            opacity: 0,
                            cursor: "pointer",
                            display:
                              props.input.value.name ||
                              logo_name != ""
                                ? "none"
                                : "block"
                          }}
                          onChange={e => {
                            setLogo_name("");
                            e.preventDefault();
                            if (e.target.files) {
                              props.input.onChange(e.target.files[0]);
                              setLogo_name(e.target.files[0].name);
                            }
                          }}
                        />
                        {logo_name != "" ? (
                          <label htmlFor="files" className="com-logo">
                            {logo_name}
                            <span
                              onClick={e => {
                                setLogo_name("");
                                e.preventDefault();
                                props.input.onChange(null);

                                dispatch(
                                  profileImagesDelete({
                                    logo_deleted: 1,
                                    photo_deleted: 0,
                                    vaccination_certificate_deleted: 0
                                  })
                                );
                              }}
                            >
                              X
                            </span>
                          </label>
                        ) : null}
                        {displayError && (
                          <div className="validation-error">
                            {props.meta.error}
                          </div>
                        )}
                      </div>
                    </>
                  );
                }}
              />
            </div>

            <button
              style={{ marginBottom: "20px" }}
              type="submit"
              className={`btn ${
                isLoading ? "btn--loadingNew " : "btn-blue"
              } wizard__btn mb-0`}
              disabled={isLoading}
            >
              {" "}
              {!isLoading && (
                <span style={{ padding: 2 }}>Update Details</span>
              )}
            </button>
          </Form>
        </div>
      </div>
    </Modal>
  );
});
