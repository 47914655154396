import React, { FC, useEffect, useState } from "react";
import {
  getPlansEffect,
  setSelectedPlanEffect
} from "./SignUp/Actions";
import { createPaymentEffect } from "./PaymentWizard/Actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppState } from "../store";
import { Plan } from "../API/EnumsApi";
import PlansItemSub from "./Component/PlanItemSub";
import AddPromoModal from "./Dashboard/modals/AddPromoModal";
import { Helmet } from "react-helmet";
const Pricing: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const plans = useSelector((state: AppState) => state.wizard.plans);
  const user = useSelector((state: AppState) => state.auth.user);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  const isSubscriptionCancelled = user && user.is_subscribed;
  const [addPromo, setAddPromo] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();

  useEffect(() => {
    dispatch(getPlansEffect());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePlanSelection = async (plan: Plan) => {
    if (plan.name == "Introa" || plan.name == "Regulara") {
      setAddPromo(true);
      setSelectedPlan(plan);
    } else {
      if (user && user.plan_id && isSubscriptionCancelled) {
        await dispatch(
          createPaymentEffect({ plan_id: plan.id }, history, true)
        );
      }
      if (!user) {
        history.push("/register/select-type");
      } else {
        history.push("payment");
        await dispatch(setSelectedPlanEffect(plan));
      }
    }
  };
  const showHideModal = (value: boolean) => {
    setAddPromo(value);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Dorpee Pricing" />
        <title>Monthly Workspace Memberships | Dorpee</title>
      </Helmet>
      <div className="container subs-plans__wrapper">
        <div className="content-wrapper__title">
          <h2 className="section__title">Individual Plans</h2>
        </div>

        <div className="pricing_desc">
          <h5>How It Works!</h5>
          <p>
            Dorpee is all about creating a system where everyone can
            thrive.
          </p>
          <ul className="pricing_list">
            <li>
              Subscribe to monthly credits, or top up required, and
              use these to book your workspaces.
            </li>
            <li>
              Filter according to your needs each day and select a
              spot that meets that for you.
            </li>
            <li>
              Workspaces set their own pricing and vary in credit
              value based on offering.
            </li>
          </ul>
          <p> Change or cancel your plan at any time!</p>
        </div>
        <div className="row no-gutters justify-content-center">
          {plans.map((plan: Plan, i: number) => {
            return (
              <div key={i} className="col-6 col-md-4 col-lg">
                <div
                  className={
                    user &&
                    user.plan &&
                    user.plan_id == plan.id &&
                    isSubscriptionCancelled &&
                    !user &&
                    i == 0
                      ? "plan-item active"
                      : "plan-item"
                  }
                >
                  <div className="plan-item__header">{plan.name}</div>
                  <div
                    className="plan-item__content"
                    style={{
                      padding: `${
                        plan && plan.name === "Free"
                          ? "10px 10px"
                          : "42px 10px 15px"
                      }`
                    }}
                  >
                    <div
                      className="plan-item__content-block"
                      style={{ marginTop: -12 }}
                    >
                      <span className="plan-item__content-price">
                        ${plan.price}
                      </span>
                      <span className="plan-item__content-price-subtitle">
                        {" "}
                        {plan && plan.name === "Free"
                          ? "for your first 2 weeks"
                          : "per month"}
                      </span>
                    </div>
                    <div className="plan-item__content-block">
                      <span
                        className="plan-item__content-credit"
                        style={{
                          marginTop: `${
                            plan && plan.name === "Free" ? "10px" : ""
                          }`
                        }}
                      >
                        {plan.credits}
                      </span>
                      <span
                        className="plan-item__content-credit-subtitle"
                        style={{
                          marginBottom: `${
                            plan && plan.name === "Free" ? "10px" : ""
                          }`
                        }}
                      >
                        credits
                      </span>
                      <span className="plan-item__content-credit-description">
                        {plan.description}
                      </span>
                      {plan && plan.name === "Free" ? (
                        <span
                          className="plan-item__content-credit-description"
                          style={{
                            fontSize: "12px",
                            marginTop: "0.5rem"
                          }}
                        >
                          When your free trial concludes you will
                          automatically roll over to our $69 intro
                          plan. You can change or cancel your plan in
                          the dashboard.
                        </span>
                      ) : null}
                    </div>
                    <div className="bottom_content">
                      {plan && plan.free_space === "Yes" ? (
                        <div className="free_space_text">
                          Includes Free Space Access
                        </div>
                      ) : null}
                      <button
                        className={`btn btn-blue wizard__btn${
                          isLoading ? " disabled" : ""
                        }${isLoading ? " btn--loading" : ""}`}
                        onClick={() => handlePlanSelection(plan)}
                      >
                        {plan && plan.name === "Free" ? (
                          <div className="free_space_text">
                            TRY DORPEE FOR FREE
                          </div>
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="plan-item__active">ACTIVE</div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="plans"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: 40,
            marginTop: 40
          }}
        >
          <PlansItemSub
            history={history}
            title={"One-time Credits or Top Up"}
            description={
              "I want to buy credits as I need them, or I need to top up my plan with extra credits. From $49."
            }
            buttonText={"BUY / TOP UP CREDITS"}
          ></PlansItemSub>
          <PlansItemSub
            history={history}
            title={"Employer Plan"}
            description={
              "We have an organisation, business or team and we would like to bring our employees into the Dorpee Village."
            }
            buttonText={"ENQUIRE NOW"}
          ></PlansItemSub>
        </div>
        <div className="pricing-text" style={{ marginTop: 30 }}>
          <p style={{ margin: 0 }}>
            *Based on the average per/hour credit rate.
          </p>
          <p>
            **Rollover up to the total number of credits in your
            upcoming plan.
          </p>
        </div>
      </div>

      {addPromo ? (
        <>
          <AddPromoModal
            showHideModal={showHideModal}
            isSubscriptionCancelled={isSubscriptionCancelled}
            selectedPlan={selectedPlan}
            user={user && user}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Pricing;
