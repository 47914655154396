import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import { postSpaceStatusEffect } from "../../WorkspaceWizard/Actions";
import { getVenuesEffect } from "../../VenueWizard/Actions";
class ChangeSpaceStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "Published",
    };
  }
  decline() {
    this.props.decline();
  }
  handleSubmit = () => {
    if (this.props.id) {
      if (this.state.status === "Draft") {
        let req = {
          status: "Closed",
          _method: "PUT",
        };
        this.props.postSpaceStatusEffect(this.props.id, req);
      } else {
        let req = {
          status: "New",
          _method: "PUT",
        };
        this.props.postSpaceStatusEffect(this.props.id, req);
      }
      this.props.getVenuesEffect();
      this.decline();
    }
  };
  handleStatus = (ev) => {
    this.setState({ status: ev.target.value });
  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>{this.props.modalHeader}</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="col-md-10 status-modal-head">
                <div className="status-label">Status</div>
                <div
                  style={{ border: "1px solid #009cdc", width: "100%" }}
                  className="input-container"
                >
                  <div className="input-select">
                    <select
                      style={{ color: "#009cdc" }}
                      className="pl-12"
                      onChange={(e) => this.handleStatus(e)}
                      value={this.state.status}
                    >
                      <option key={1}>Publish</option>
                      <option key={2}>Draft</option>
                    </select>
                  </div>
                </div>
              </div>
              <div>{this.props.message}</div>
              <button
                className={`btn addressbook-modal-btn addressbook-submit-btn ${
                  this.props.isLoading ? "btn-blue btn--loading" : ""
                }`}
                onClick={this.handleSubmit}
                // disabled={this.props.isLoading ? true : false}
                style={{
                  background: this.props.isLoading ? "#e5e5e5" : "transparent",
                }}
              >
                {this.props.acceptText}
              </button>
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.decline.bind(this)}
              >
                {this.decline.bind(this)}
                {this.props.declineText}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
  user: state.auth.token,
});

const mapDispatchToProps = {
  getVenuesEffect,
  postSpaceStatusEffect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSpaceStatusModal);
