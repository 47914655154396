import React from "react";
import { Bar, Chart, ctx } from "react-chartjs-2";
class VenueWeeklyUtilization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      datasets: this.props.bar ? this.getData() : []
    };
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.bar &&
      nextProps.bar &&
      nextProps.bar != this.props.bar
    ) {
      this.setState({ datasets: this.getData() });
    }
    if (!this.props.bar) {
      if (
        nextProps.myTeamBookings &&
        this.props.myTeamBookings &&
        nextProps.myTeamBookings.length !=
          this.props.myTeamBookings.length
      ) {
        let today = new Date();
        let week = [];

        for (let i = 1; i <= 7; i++) {
          let first = today.getDate() - today.getDay() + i;
          let day = new Date(today.setDate(first))
            .toISOString()
            .slice(0, 10);
          week.push(day);
        }
        var currentWeek = [];
        week.map(day => {
          let format = day.split("-");
          currentWeek.push(
            format[1] + "/" + format[2] + "/" + format[0]
          );
        });
        let allData = [0, 0, 0, 0, 0, 0, 0];
        const data = this.props.myTeamBookings;
        data.map(v => {
          if (v.all_bookings.length > 0) {
            v.all_bookings.map(x => {
              currentWeek.map((z, index) => {
                if (z === x.start_date) {
                  allData[index] = allData[index] + 1;
                }
              });
            });
          }
        });
        let values = [];
        let color = this.getRandomColor();
        let conf = {
          label: "Employees' Bookings ",
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          hoverBackgroundColor: "#c4e8f7",
          hoverBorderColor: "#009cdc",
          data: allData
        };
        values.push(conf);
        this.setState({ datasets: values });
      }
    }
  }

  getData() {
    let values = [];
    let data = null;
    if (this.props.bar.data) {
      data = this.props.bar.data.venues;
    }

    for (let i in data) {
      let dt = [];
      for (let val in data[i]) {
        dt.push(data[i][val]);
      }
      let color = this.getRandomColor();
      let conf = {
        label: i,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        hoverBackgroundColor: "#c4e8f7",
        hoverBorderColor: "#009cdc",
        data: dt
      };
      values.push(conf);
    }
    return values;
  }

  getRandomColor() {
    let letters = "0123456789ABCDEF".split("");
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  render() {
    return (
      <div className="venue-chart-big">
        <Bar
          data={this.state}
          width={100}
          height={50}
          legend={{ position: "bottom" }}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  stacked: true
                }
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Number of bookings"
                  },
                  ticks: {
                    min: 0,
                    stepSize: 1
                  }
                }
              ]
            }
          }}
        />
      </div>
    );
  }
}

export default VenueWeeklyUtilization;
