import React from "react";
import { Modal } from "../../../UI/Modal";
import { DashboardApi } from "../../../API/DashboardApi";
import store from "../../../store";

class PrivacyModal extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      privacyLinkedin: "off",
      privacyName: "off",
      privacyInterests: "off",
      isPrivacyLinkedinChecked: false,
      isPrivacyNameChecked: false,
      isPrivacyInterestsChecked: false,
      linkedinUrl: "",
      error: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const token = store.getState().auth.token;
    let response = await DashboardApi.UpdatePrivacySettings(
      token,
      []
    );
    if (this._isMounted && response.data) {
      this.setState({
        privacyLinkedin: response.data
          ? response.data.privacy_linkedin
          : "off",
        privacyName: response.data
          ? response.data.privacy_name
          : "off",
        privacyInterests: response.data.privacy_interests
          ? response.data.privacy_interests
          : "off",
        isPrivacyLinkedinChecked: response.data.privacy_linkedin
          ? response.data.privacy_linkedin === "on"
          : false,
        isPrivacyNameChecked: response.data.privacy_name
          ? response.data.privacy_name === "on"
          : false,
        isPrivacyInterestsChecked: response.data.privacy_interests
          ? response.data.privacy_interests === "on"
          : false,
        linkedinUrl: response.data.linkedin
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async updatePrivacySettings() {
    if (this.state.error) return;
    let data = {
      privacy_linkedin: this.state.privacyLinkedin,
      privacy_name: this.state.privacyName,
      privacy_interests: this.state.privacyInterests,
      linkedin: this.state.linkedinUrl
    };
    const token = store.getState().auth.token;
    await DashboardApi.UpdatePrivacySettings(token, data);
    this.props.handleprivacyModal(false);
    this.props.showSuccess("Settings successfully updated");
  }

  changePrivacySettings(type, e) {
    if (type === "privacyLinkedin")
      this.setState({
        privacyLinkedin: e.target.checked ? "on" : "off",
        isPrivacyLinkedinChecked: e.target.checked
      });
    if (type === "privacyName")
      this.setState({
        privacyName: e.target.checked ? "on" : "off",
        isPrivacyNameChecked: e.target.checked
      });
    if (type === "privacyInterests")
      this.setState({
        privacyInterests: e.target.checked ? "on" : "off",
        isPrivacyInterestsChecked: e.target.checked
      });
  }

  changeLikedinUrl(e) {
    let url = "https://www.linkedin.com/";
    if (
      e.target.value.length > 0 &&
      (e.target.value.indexOf(url) === -1 ||
        e.target.value.replace(url, "").length === 0)
    )
      this.setState({ error: true });
    else {
      this.setState({ error: false });
    }
    this.setState({
      linkedinUrl: e.target.value
    });
  }

  render() {
    return (
      <Modal>
        <div className="addressbook-form-box">
          <div className="congrat-box__header">
            <div className="search-pref__title">Privacy Settings</div>{" "}
            <div
              onClick={this.props.handleprivacyModal.bind(
                this,
                false
              )}
              className="close-btn"
            ></div>
          </div>
          <div
            className="addressbook-form"
            style={{ textAlign: "start", whiteSpace: "break-spaces" }}
          >
            {/* <div className="privacy-settings__wrapper">
              <div className="filter-block">
                <div className="switcher">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.changePrivacySettings.bind(
                        this,
                        "privacyName"
                      )}
                      checked={this.state.isPrivacyNameChecked}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>
                    Other people can see my First Name and Interests when I am
                    at a Venue
                  </span>
                </div>
              </div>
              <div className="filter-block">
                <div className="switcher">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.changePrivacySettings.bind(
                        this,
                        "privacyInterests"
                      )}
                      checked={this.state.isPrivacyInterestsChecked}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>People can see only my Interests</span>
                </div>
              </div>
              <div className="filter-block">
                <div className="switcher">
                  <label className="switch">
                    <input
                      type="checkbox"
                      onChange={this.changePrivacySettings.bind(
                        this,
                        "privacyLinkedin"
                      )}
                      checked={this.state.isPrivacyLinkedinChecked}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>People can see my LinkedIn profile</span>
                </div>
              </div>
              <div className="filter-block position-relative">
                <div className="input-container">
                  <div className="input__icon">
                    <div className="input__icon--linkedin"></div>
                  </div>
                  <input
                    type="email"
                    value={this.state.linkedinUrl}
                    onChange={this.changeLikedinUrl.bind(this)}
                    placeholder="https://www.linkedin.com/myname"
                  />
                  {this.state.error ? (
                    <p className="addressbook-form-error">Invalid Value</p>
                  ) : null}
                </div>
              </div>
              <button
                className="btn addressbook-modal-btn"
                onClick={this.updatePrivacySettings.bind(this)}
              >
                Save
              </button>
            </div> */}
          </div>
        </div>
      </Modal>
    );
  }
}

export default PrivacyModal;
