/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getCustomerCards } from "../Dashboard/Actions";
import { AppState } from "../../store";
import EditCardModal from "../../Screens/Dashboard/modals/EditCardModal";
import Confirmation from "../../Screens/Dashboard/modals/Confirmation";
import { PaymentAPI } from "../../API/PayementAPI";
import { setLoader } from "../../Screens/Loader/Actions";
import { toast } from "react-toastify";
import { getProfileEffect } from "../Auth/Actions";
import AddCrediCardModal from "../../Screens/Dashboard/modals/AddCreditCardModal";
import { Helmet } from "react-helmet";
function PaymentOptions() {
  const dispatch = useDispatch();
  const cards = useSelector(
    (state: AppState) => state.dashboard.customerAllCard
  );
  const userToken = useSelector((state: AppState) => state.auth.token);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedCard, setSelectedCard]: any = useState();
  const [isConfimrationBoxOpen, setisConfimrationBoxOpen] = useState(false);

  const [id, setid]: any = useState();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  useEffect(() => {
    dispatch(getCustomerCards());
  }, []);

  const hideModal = () => {
    setisConfimrationBoxOpen(false);
  };
  const showHideModal = (value: boolean) => {
    setIsPaymentModalOpen(false);
  };
  const openConfirmationBox = async (idd: number) => {
    setid(idd);
    setisConfimrationBoxOpen(true);
  };
  const handleToggleModal = () => {
    setIsEditOpen(false);
  };
  const handleDeleteCard = async () => {
    dispatch(setLoader(true));
    setisConfimrationBoxOpen(false);
    let response = await PaymentAPI.DeleteCard(userToken!, id);
    toast.success("Card is successfully deleted.");
    dispatch(setLoader(false));
    dispatch(getCustomerCards());
    dispatch(getProfileEffect());
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Dorpee-workspaces on demand" />
        <title>Dorpee - Payment Options</title>
      </Helmet>
      <div className="search-pref__wrapper" style={{ marginTop: "60px" }}>
        <div className="search-pref__heading">
          {cards && cards.id != null ? (
            <div className="search-pref__title">PAYMENT OPTION</div>
          ) : (
            " "
          )}
          {cards && cards.id == null ? (
            <>
              <p className="search-pref__title">No Credit Cards Found</p>
              <div className="payment__add-btn">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.localStorage.setItem("addNewCard", "true");
                    setIsPaymentModalOpen(true);
                  }}
                >
                  + Add New{" "}
                </a>
              </div>
            </>
          ) : (
            <div className="payment__add-btn">
              <Link to="/payment">Update Credit Card </Link>
            </div>
          )}
        </div>
        {cards && cards.id ? (
          <div className="search-pref__block">
            <div className="assignment-icon"></div>
            <span className="col-md-8">
              Type: {cards.brand}, Card Number: {cards.last4}
            </span>
            <div
              style={{ cursor: "pointer" }}
              className="dashboard__edit "
              onClick={(e) => {
                setSelectedCard(cards);
                setIsEditOpen(true);
              }}
            ></div>
            <img
              onClick={(e) => openConfirmationBox(cards.id!)}
              className="team-actions-icon"
              src="/img/icons/cross-white.svg"
              alt=""
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {isPaymentModalOpen ? (
        <AddCrediCardModal
          showHideModal={showHideModal}
          isHide={true}
        ></AddCrediCardModal>
      ) : null}
      {isEditOpen ? (
        <EditCardModal
          handleToggleModal={handleToggleModal}
          data={selectedCard}
        ></EditCardModal>
      ) : null}
      {isConfimrationBoxOpen ? (
        <Confirmation
          message={
            "Your subscription will be cancelled if the credit card is removed from your account. Please confirm if you want to proceed"
          }
          decline={hideModal}
          accept={handleDeleteCard}
          modalHeader={"Are You Sure?"}
          id={id}
          acceptText={"Confirm"}
          declineText={"Cancel"}
          showButtons={true}
        ></Confirmation>
      ) : null}
    </>
  );
}

export default PaymentOptions;
