/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { AppState } from "../store";
import {
  getPublicVenuesEffect,
  getVenueTypesEffect,
  getServicesEffect,
  getEnvironmentsEffect,
  getTypesEffect
} from "./VenueWizard/Actions";
import WorkspaceItem from "../Screens/VenueSearch/WorkspaceItem";
import MapMarkerComponent from "../Screens/MapMarkerComponent";
import { Venue } from "../API/VenueApi";
import ShowRatingsModal from "../Screens/Dashboard/modals/ShowRatingsModal";
import VenuesModal from "../Screens/Dashboard/modals/VenuesModal";
import MapButtonComponent from "./MapButtonComponent";
import { getProfileEffect } from "./Auth/Actions";
import {
  workspaceFilterLoad,
  workspaceFilterEnvLoad,
  workspaceFilterSortLoad,
  isAutoFocus,
  GetTotalSpaces
} from "./WorkspaceWizard/Actions";
import LoaderModal from "../Screens/Dashboard/modals/Loader";

enum SortBy {
  NameAsc = "NameAsc",
  NameDesc = "NameDesc",
  PriceAsc = "PriceAsc",
  PriceDesc = "PriceDesc"
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};
const Workspaces: FC = () => {
  const dispatch = useDispatch();
  let amenitites: any = [
    { id: 32, service: "Free WiFi" },
    { id: 59, service: "Print / Scan / Copy" },
    { id: 37, service: "Power - at table" },
    { id: 38, service: "Power - Communal" },
    { id: 39, service: "Power - Phone charger" },
    {
      id: 16,
      service: "Free drinking water"
    },
    { id: 70, service: "Pet-friendly" },
    { id: 40, service: "Mobile phone friendly" }
  ];

  const filterType = useSelector(
    (state: AppState) => state.workspaceWizard.filterType
  );
  const filterEnv = useSelector(
    (state: AppState) => state.workspaceWizard.filterEnv
  );
  const filterSort = useSelector(
    (state: AppState) => state.workspaceWizard.filterSort
  );
  const venues = useSelector((state: AppState) =>
    state.venueWizard.publicVenues
      ? state.venueWizard.publicVenues
      : []
  );
  const filterResult = useSelector(
    (state: AppState) => state.workspaceWizard.filterResult
  );
  const autoFocus = useSelector(
    (state: AppState) => state.workspaceWizard.isAutoFocus
  );
  const roleUser = useSelector(
    (state: AppState) => state.auth.userRoles
  );
  const user = useSelector((state: AppState) => state.auth.user!);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  const totalSpaces = useSelector(
    (state: AppState) => state.workspaceWizard.totalSpaces
  );
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isSortOpened, setIsSortOpened] = useState(false);
  const [selectedFilterBy, setselectedFilterBy] = useState("");
  const [visibleVenues, setVisibleVenues] = useState(
    filterResult ? filterResult : venues
  );
  const [tempVenues, setTempVenues] = useState(
    filterResult ? filterResult : venues
  );
  const [filterBy, setFilterBy] = useState<number[]>(
    filterType ? filterType : []
  );
  const [filterByVenue, setFilterByVenue] = useState<number[]>(
    filterEnv ? filterEnv : []
  );
  const [filterByService, setFilterByService] = useState<number[]>(
    []
  );
  const [sortBy, setSortBy] = useState<SortBy | undefined>(
    filterSort
  );
  const [selectedVenue, setselectedVenue] = useState<Venue>();
  const [iscloseModal, setcloseModal] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [bound, setBounds] = useState({
    ne: { lat: 0, lng: 0 },
    nw: { lat: 0, lng: 0 },
    se: { lat: 0, lng: 0 },
    sw: { lat: 0, lng: 0 }
  });
  const [mapCenter, setCenter] = useState({ lat: 0, lng: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [i, setIndex] = useState(20);
  const [isOpen, setisOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const filterRef = useRef<HTMLDivElement>(null);
  const sortRef = useRef<HTMLDivElement>(null);
  const outSideClickRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getPublicVenuesEffect());
    dispatch(getEnvironmentsEffect());
    dispatch(getVenueTypesEffect());
    dispatch(getServicesEffect());
    dispatch(getProfileEffect());
    dispatch(getTypesEffect());
    dispatch(GetTotalSpaces());
  }, []);

  useEffect(() => {
    if (visibleVenues.length === 0) {
      setTempVenues(venues);
      setVisibleVenues(venues);
      // localStorage.setItem("venues", JSON.stringify(venues));
    }
  }, [venues]);

  useEffect(() => {
    function outSideClick(e: MouseEvent) {
      if (
        outSideClickRef &&
        !outSideClickRef.current.contains(e.target)
      ) {
        dispatch(isAutoFocus(true));
      }
    }

    document.body.addEventListener("click", outSideClick);
    return () => {
      document.body.removeEventListener("click", outSideClick);
    };
  }, [autoFocus]);

  useEffect(() => {
    let offset: any = document.getElementById("test");
    if (offset) {
      offset = offset.offsetHeight;
    }
  });

  // useEffect(() => {
  //   let f = visibleVenues.filter(v => v.images == null);
  // }, [visibleVenues]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterByType = (v: any, filterBy: any[]) => {
    if (filterBy.length < 1) {
      return true;
    } else {
      let matched = false;
      v.spaces.some((space: { types: any[] }) => {
        space.types.some((type: { id: number }) => {
          if (filterBy.includes(type.id)) {
            matched = true;
          }
        });
      });

      return matched;
    }
  };

  const filterByEnv = (v: any, filterByVenue: any[]) => {
    if (filterByVenue.length < 1) {
      return true;
    } else {
      let matched = false;
      v.spaces.some((env: any) => {
        env.assets.some((asset: any) => {
          if (filterByVenue.includes(asset.id)) {
            matched = true;
          }
        });
      });
      v.services.some((service: any) => {
        if (filterByVenue.includes(service.id)) {
          matched = true;
        }
      });

      return matched;
    }
  };

  const filterByServices = (v: any) => {
    if (filterByService.length < 1) {
      return true;
    } else {
      let matched = false;
      v.services.some((service: { id: number }) => {
        if (filterByService.includes(service.id)) {
          matched = true;
        }
      });
      return matched;
    }
  };

  const filter = (
    type?: string,
    sortBy?: string,
    byFilter?: any[]
  ) => {
    let vis = tempVenues
      .filter(v => {
        if (type === "services") {
          return filterByServices(v);
        } else if (type === "env") {
          return filterByEnv(v, byFilter!);
        } else if (type === "type") {
          return filterByType(v, byFilter!);
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        let aValue = Math.ceil(parseInt(a.lowest_price) / 0.3);
        let bValue = Math.ceil(parseInt(b.lowest_price) / 0.3);

        if (sortBy === SortBy.PriceAsc) {
          return aValue - bValue;
        }

        if (sortBy === SortBy.PriceDesc) {
          return bValue - aValue;
        }
        if (sortBy === SortBy.NameAsc) {
          return a.name.localeCompare(b.name);
        }
        if (sortBy === SortBy.NameDesc) {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });

    setVisibleVenues(vis);
    setTempVenues(vis);
    // localStorage.setItem("venues", JSON.stringify(vis));

    dispatch(workspaceFilterLoad(vis));
    if ((byFilter && byFilter.length === 0) || sortBy === "") {
      setVisibleVenues(venues);
      setTempVenues(venues);
      // localStorage.setItem("venues", JSON.stringify(venues));

      dispatch(workspaceFilterLoad(venues));
    }
  };

  const toggleFilterEnv = (id: number) => {
    setselectedFilterBy("env");
    const newFilterBy =
      filterByVenue.indexOf(id) >= 0
        ? filterByVenue.filter(i => i !== id)
        : [...filterByVenue, id];
    dispatch(workspaceFilterEnvLoad(newFilterBy));
    setFilterByVenue(newFilterBy);
    filter("env", undefined, newFilterBy);
  };

  const toggleSortName = () => {
    var sort;
    if (sortBy !== SortBy.NameAsc && sortBy !== SortBy.NameDesc) {
      setSortBy(SortBy.NameAsc);
      sort = SortBy.NameAsc;
    } else if (sortBy === SortBy.NameAsc) {
      sort = SortBy.NameDesc;
      setSortBy(SortBy.NameDesc);
    } else if (sortBy === SortBy.NameDesc) {
      sort = "";
      setSortBy(undefined);
    }
    dispatch(workspaceFilterSortLoad(sort));
    filter("", sort);
  };

  const toggleSortPrice = () => {
    let sort;
    if (sortBy !== SortBy.PriceAsc && sortBy !== SortBy.PriceDesc) {
      sort = SortBy.PriceAsc;
      setSortBy(SortBy.PriceAsc);
    } else if (sortBy === SortBy.PriceAsc) {
      sort = SortBy.PriceDesc;
      setSortBy(SortBy.PriceDesc);
    } else if (sortBy === SortBy.PriceDesc) {
      sort = "";
      setSortBy(undefined);
    }
    dispatch(workspaceFilterSortLoad(sort));
    filter("", sort);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setcloseModal(false);
  };

  const openModal = (data: any) => {
    setisOpen(true);
  };

  const changeMarkerPosition = (e: any) => {
    const { nw, se, ne, sw } = e;
    let filteredVenues = tempVenues.filter(v => {
      if (
        v.lat > se.lat &&
        sw.lat &&
        v.lat < ne.lat &&
        nw.lat &&
        v.lng > nw.lng &&
        sw.lng &&
        v.lng < ne.lng &&
        se.lng
      ) {
        return true;
      } else return false;
    });
    // setVenues(filteredVenues);

    let isVenueFree;
    let data = filteredVenues;
    tempVenues.map(v => {
      isVenueFree = v.spaces.some(s => s.free_space === "No");
      if (roleUser !== "Venue") {
        if (!isVenueFree && user) {
          if (user.plan == null || user.plan.free_space === "No") {
            data = filteredVenues.filter(v => {
              return v.spaces.some(s => s.free_space === "No");
            });
          }
        }
      }
    });
    setVisibleVenues(data);
    // localStorage.setItem("venues", JSON.stringify(data));
  };

  const showDialog = (venue: Venue) => {
    setcloseModal(true);
    setselectedVenue(venue);
  };

  const searchWorkPlace = (event: any) => {
    dispatch(isAutoFocus(false));
    const keyword = event.target.value;
    if (event.target.value != "") {
      let finalVenues = venues.filter(v => {
        if (keyword && keyword != "") {
          if (
            v.suburb &&
            v.suburb
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (
            v.name &&
            v.name
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (v.services) {
            var foundService = _.find(v.services, function (o) {
              return o.service
                .toLocaleLowerCase()
                .includes(keyword.toLocaleLowerCase());
            });
            if (foundService) return v;
          }
          if (
            v.address &&
            v.address
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
          if (
            v.description &&
            v.description
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          ) {
            return v;
          }
        }
      });
      setVisibleVenues(finalVenues);
      setTempVenues(finalVenues);
      // localStorage.setItem("venues", JSON.stringify(finalVenues));
    } else {
      setVisibleVenues(venues);
      setTempVenues(venues);
      // localStorage.setItem("venues", JSON.stringify(venues));
    }
  };

  const handleMap = () => {
    if (showMap) {
      setVisibleVenues(tempVenues);
      setShowMap(!showMap);
      setCenter({ lat: 0, lng: 0 });
      // localStorage.setItem("venues", JSON.stringify(tempVenues));
    }
    setShowMap(!showMap);
  };

  const showMore = (e: any) => {
    if (i < visibleVenues.length) {
      var index = i + 20;
      e.stopPropagation();
      setVisibleVenues(visibleVenues);
      setTempVenues(visibleVenues);
      // localStorage.setItem("venues", JSON.stringify(visibleVenues));

      setIndex(
        index >= visibleVenues.length
          ? index - (index - visibleVenues.length)
          : index
      );
    }
  };
  const handleHoverMarker = (id: any) => {
    setIsHovered(id);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Search hundreds of flexible workspace locations and work where you're most productive. Work from a cafe one day and a cool co-working space the next."
        />
        <title>Flexible Workspace Locations | Dorpee</title>
      </Helmet>
      <div className="row" style={{ marginRight: 0 }}>
        <div
          className={`container ${
            showMap ? "block_view" : ""
          } col-sm-${showMap ? "6" : "12"}`}
          id="test"
          style={{
            paddingLeft: 20,
            minHeight: "90vh",
            position: "relative"
          }}
        >
          <div
            className="container"
            style={{ marginTop: showMap ? 90 : 60 }}
          >
            <div
              className="filter_panel_conatiner"
              style={{
                display: "flex",
                alignItems: "baseline",
                flexWrap: showMap ? "wrap" : "nowrap"
              }}
            >
              <div
                className={`col-4 ${
                  showMap
                    ? "workspacesSubText"
                    : "workspacesSubTextMbl"
                }`}
                style={{
                  top: 0,
                  padding: 0,
                  minWidth: showMap ? "100%" : ""
                }}
              >
                <h2
                  className="section__title"
                  style={{ marginBottom: "10px" }}
                >
                  Workspaces
                </h2>
                <div
                  className="countk"
                  style={{ marginBottom: "10px" }}
                >
                  <b>
                    {totalSpaces && totalSpaces != ""
                      ? `${totalSpaces} workspaces on Dorpee`
                      : ""}
                  </b>
                </div>
                <p
                  style={{
                    textAlign: "left",
                    lineHeight: "125%",
                    position: "relative"
                  }}
                >
                  Can’t find a venue near you? Drop us an email at{" "}
                  <a
                    href="mailto:hello@dorpee.com"
                    style={{ color: "#4956A2" }}
                  >
                    hello@dorpee.com
                  </a>{" "}
                  and we will work to find you a space.
                </p>{" "}
              </div>

              <div
                className="filter-panel-div col-8"
                style={{
                  minWidth: showMap ? "100%" : ""
                }}
              >
                <div className="filter-panel">
                  <div
                    className="filter-btn__wrapper"
                    style={{ zIndex: 3 }}
                  >
                    <button
                      onClick={() => {
                        setIsSortOpened(false);
                        setIsFilterOpened(!isFilterOpened);
                      }}
                      className="btn btn-white wizard__btn mb-0"
                    >
                      FILTER
                      {filterBy.length && filterByVenue.length ? (
                        <>
                          {" "}
                          ({filterBy.length + filterByVenue.length})
                        </>
                      ) : filterBy.length ? (
                        <>({filterBy.length})</>
                      ) : filterByVenue.length ? (
                        <>({filterByVenue.length})</>
                      ) : (
                        <></>
                      )}
                      <img
                        className="wizard__btn--img-left"
                        src="/img/icons/filter.svg"
                        alt=""
                      />
                    </button>

                    {isFilterOpened && (
                      <div
                        className="filter-popup filter-popup--filter"
                        style={{ left: 0 }}
                      >
                        <div className="tags-section">
                          {/* <div style={{ marginRight: 120, marginBottom: 10 }}>
                            Type
                          </div>
                          {venueTypes.map((t) => (
                            <div
                              key={t.id}
                              onClick={() => toggleFilter(t.id)}
                              className={`tags-item${
                                filterBy.indexOf(t.id) >= 0 ? " active" : ""
                              }`}
                            >
                              <p>{t.type}</p>
                            </div>
                          ))} */}
                          <div
                            style={{
                              marginRight: 120,
                              marginBottom: 10,
                              marginTop: 20,
                              width: "100%",
                              display: "block"
                            }}
                          >
                            Amenities
                          </div>
                          <br />
                          {amenitites.map((t: any) => (
                            <div
                              key={t.id}
                              onClick={() => toggleFilterEnv(t.id)}
                              className={`tags-item${
                                filterByVenue.indexOf(t.id) >= 0
                                  ? " active"
                                  : ""
                              }`}
                            >
                              <p>{t.service}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="filter-btn__wrapper"
                    style={{ zIndex: 2 }}
                  >
                    <button
                      onClick={() => {
                        setIsFilterOpened(false);
                        setIsSortOpened(!isSortOpened);
                      }}
                      className="btn btn-white wizard__btn mb-0"
                    >
                      SORT
                      <img
                        className="wizard__btn--img-left"
                        src="/img/icons/sort.svg"
                        alt=""
                      />
                    </button>
                    {isSortOpened && (
                      <div
                        ref={sortRef}
                        className="filter-popup filter-popup--right"
                      >
                        <div
                          onClick={() => toggleSortName()}
                          className={`tags-item${
                            sortBy === SortBy.NameAsc ||
                            sortBy === SortBy.NameDesc
                              ? " active"
                              : ""
                          }`}
                        >
                          <p>
                            Name
                            {(sortBy === SortBy.NameAsc ||
                              sortBy === SortBy.NameDesc) && (
                              <img
                                alt=""
                                src={
                                  sortBy === SortBy.NameAsc
                                    ? "img/icons/up.svg"
                                    : "img/icons/down.svg"
                                }
                              />
                            )}
                          </p>
                        </div>
                        <div
                          onClick={() => toggleSortPrice()}
                          className={`tags-item${
                            sortBy === SortBy.PriceAsc ||
                            sortBy === SortBy.PriceDesc
                              ? " active"
                              : ""
                          }`}
                        >
                          <p>
                            Price
                            {(sortBy === SortBy.PriceAsc ||
                              sortBy === SortBy.PriceDesc) && (
                              <img
                                alt=""
                                src={
                                  sortBy === SortBy.PriceAsc
                                    ? "img/icons/up.svg"
                                    : "img/icons/down.svg"
                                }
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="filter-btn__wrapper">
                    <MapButtonComponent
                      showMapView={false}
                      showMap={showMap}
                      handleMap={handleMap}
                    ></MapButtonComponent>
                  </div>
                  <div className="filter-btn__wrapper">
                    <input
                      type="text"
                      onChange={e => searchWorkPlace(e)}
                      id="searchWorkplace"
                      ref={outSideClickRef}
                      autoFocus={true}
                      placeholder="Enter a keyword.."
                      style={{
                        borderRadius: "4px",
                        paddingLeft: "18%",
                        border: "1px solid #009CDC",
                        position: "relative",
                        height: "42px",
                        color: "#009CDC"
                      }}
                    />
                    <img
                      src="/img/icons/searchIcon.png"
                      style={{
                        position: "absolute",
                        top: "25%",
                        left: "5%",
                        width: "18px"
                      }}
                      alt="searchIcon"
                    />
                  </div>
                </div>
                <div
                  className="filter-panel-price"
                  style={{ zIndex: 1, paddingTop: 0 }}
                >
                  <div className="price__wrapper">
                    <div className="price_tag_blue"></div>
                    <p>
                      <b>Basic: </b>0-50 credits/hr
                    </p>
                  </div>
                  <div className="price__wrapper">
                    <div className="price_tag_purple"></div>
                    <p>
                      <b>Standard: </b>51-170 credits/hr
                    </p>
                  </div>
                  <div className="price__wrapper">
                    <div className="price_tag_yellow"></div>
                    <p>
                      <b>Premium: </b>170 + credits/hr
                    </p>
                  </div>

                  {/* <div className="price__wrapper">
                    <div className="price_tag_pink">
                      <span>Day Rate</span>
                    </div>
                    <p>credits/per day</p>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="space-list ">
              <div className="row">
                {visibleVenues.length > 0 ? (
                  visibleVenues
                    .slice(
                      0,
                      i < visibleVenues.length
                        ? i
                        : visibleVenues.length
                    )
                    .map(
                      (v, index) =>
                        v.spaces.length > 0 &&
                        v.images != null && (
                          <div
                            key={index}
                            className={`col-sm-${
                              showMap && windowDimensions.width <= 880
                                ? 12
                                : showMap
                                ? 6
                                : 4
                            }`}
                          >
                            <WorkspaceItem
                              venue={v}
                              user={user}
                              showDialog={showDialog}
                              handleHoverMarker={handleHoverMarker}
                            />
                          </div>
                        )
                    )
                ) : !isLoading && visibleVenues.length < 0 ? (
                  <h2 style={{ marginLeft: 16 }} className="txt">
                    No Result Found
                  </h2>
                ) : null}
              </div>
              {i < visibleVenues.length && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{ marginTop: "1.6rem", width: "20%" }}
                    className="searchFieldMbl"
                  >
                    <button
                      onClick={showMore}
                      className={
                        isLoading
                          ? "btn btn--loadingNew"
                          : "booking-search__btn"
                      }
                      style={{
                        padding: "12px 20px",
                        marginTop: 0,
                        backgroundColor: "rgb(245, 89, 127)"
                      }}
                    >
                      Load more...
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {iscloseModal ? (
            <ShowRatingsModal
              selectedVenue={selectedVenue}
              decline={closeModal}
            ></ShowRatingsModal>
          ) : null}
        </div>
        {showMap ? (
          <div className="container col-sm-6">
            {showMap ? (
              <div className="mapBtn">
                <MapButtonComponent
                  showMapView
                  showMap={showMap}
                  handleMap={handleMap}
                ></MapButtonComponent>
              </div>
            ) : null}
            <div
              className="map"
              style={{ top: 150, position: "sticky" }}
            >
              <aside className="mapVenue">
                <Link
                  onClick={() => {
                    localStorage.setItem(
                      "venues",
                      JSON.stringify(visibleVenues)
                    );
                  }}
                  target="blank"
                  to="/mapView"
                  className="mapView_link"
                >
                  <p>Open In New Window</p>
                </Link>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk"
                  }}
                  center={{
                    lat: Number(-37.813629),
                    lng: Number(144.963058)
                  }}
                  zoom={11}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onChange={({
                    center,
                    zoom,
                    bounds,
                    marginBounds
                  }) => {
                    if (mapCenter.lat !== 0)
                      changeMarkerPosition(bounds);
                    setBounds(bounds);
                    setCenter(center);
                  }}
                  options={maps => ({
                    zoomControl: true,
                    zoomControlOptions: {
                      position: maps.ControlPosition.TOP_LEFT
                    }
                  })}
                >
                  {visibleVenues.map((marker: any) => {
                    return (
                      <MapMarkerComponent
                        lat={marker.lat}
                        lng={marker.lng}
                        key={marker.id}
                        marker={marker}
                        hover={isHovered}
                        openModal={openModal}
                      />
                    );
                  })}
                </GoogleMapReact>
                {isModalOpen ? (
                  <VenuesModal decline={hideModal} venue={data} />
                ) : null}
              </aside>
            </div>
          </div>
        ) : null}

        {isLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
};

export default Workspaces;
