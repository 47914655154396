import React, { Component } from "react";
import { Venue } from "../../../API/VenueApi";
import Select from "react-select";
interface myProps {
  venues: Venue[];
  handleVenueChange: (e: any) => void;
  selectedSpace: any;
  spaces: any[];
  setSelectedSpace: () => void;
  venueId: number;
  spaceId: any;
}
interface myPropsState {
  venueId: number;
  spaceId: any;
}
export default class SpaceAvailableSelector extends Component<
  myProps,
  myPropsState
  > {
  constructor(props: myProps) {
    super(props);
    this.state = {
      venueId: 0,
      spaceId: {},
    };
  }
  componentDidUpdate(nextProps: any) {
    if (nextProps.venueId !== this.props.venueId) {
      this.setState({ venueId: this.props.venueId });
    }
  }

  render() {
    return (
      <div>
        {this.props.venues.length > 0 ? (
          <div className="row">
            <div className="col-md-6">
              <div className="input-container spaceSelector">
                <div className="input-select">
                  <select
                    disabled={
                      window.localStorage.getItem("editSpaceAvailability")
                        ? true
                        : false
                    }
                    style={{ color: "#009cdc" }}
                    className="pl-12"
                    onChange={(e) => this.props.handleVenueChange(e)}
                    value={this.state.venueId}
                  >
                    {this.props.venues &&
                      this.props.venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>
                          {venue.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            {this.props.spaces.length > 0 ? (
              <div className="col-md-6">
                <Select
                  isDisabled={
                    window.localStorage.getItem("editSpaceAvailability")
                      ? true
                      : false
                  }
                  value={this.props.selectedSpace}
                  defaultValue={this.props.selectedSpace}
                  onChange={this.props.setSelectedSpace}
                  options={this.props.spaces}
                />
              </div>
            ) : (
                <div
                  className="col-md-6"
                  style={{ color: "#009cdc", marginTop: 10 }}
                >
                  No Spaces Available
                </div>
              )}
          </div>
        ) : (
            <div style={{ color: "#21c2ff" }}>Loading...</div>
          )}
      </div>
    );
  }
}
