import React from "react";
import { connect } from "react-redux";
import { getEmployeesRoles } from "../Actions";
import { Modal } from "../../../UI/Modal";

class AddEmployer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        employee_role_id:
          this.props.roles && this.props.length > 0
            ? this.props.roles[0].id
            : ""
      },
      errors: {
        first_name: false,
        last_name: false,
        email: false,
        emailInvited: false,
        emailAccepted: false,
        selectEmployee: false
      }
    };
  }

  changeForm(field, e) {
    let tmp = this.state.form;
    let disabledErrors = {
      first_name: false,
      last_name: false,
      email: false,
      emailInvited: false,
      emailAccepted: false
    };
    tmp[field] = e.target.value;

    this.setState({
      form: tmp,
      errors: disabledErrors
    });
  }
  componentDidMount() {
    this.props.getEmployeesRoles();
    if (this.props.employerDetails) {
      this.setState({ form: this.props.employerDetails });
    }
  }

  isFormValid() {
    let res = true;
    let tmp = this.state.errors;
    if (
      this.state.form.first_name.length === 0 ||
      this.state.form.first_name.length >= 100
    )
      tmp.first_name = true;
    if (
      this.state.form.last_name.length === 0 ||
      this.state.form.last_name.length >= 100
    )
      tmp.last_name = true;
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.form.email))
      tmp.email = true;
    tmp.emailInvited = this.checkEmail("invited");
    tmp.emailAccepted = this.checkEmail("accepted");

    if (
      tmp.first_name ||
      tmp.last_name ||
      tmp.email ||
      tmp.emailAccepted ||
      tmp.emailInvited
    )
      res = false;
    this.setState({ errors: tmp });
    return res;
  }

  checkEmail(type) {
    let used = false;
    if (type === "invited") {
      for (let item of this.props.invitedCustomersList) {
        if (item.email === this.state.form.email) used = true;
      }
    } else {
      for (let item of this.props.employersList) {
        if (item.email === this.state.form.email) used = true;
      }
    }
    return used;
  }

  hideModal() {
    this.props.showHideModal("addEmployer", false);
  }

  addEmployerData() {
    if (this.isFormValid()) {
      let data = this.state.form;

      if (
        data.employee_role_id === "" &&
        this.props.roles.length > 0
      ) {
        data["employee_role_id"] = this.props.roles[0].id;
      }
      if (data.employee_role_id == "") {
        this.setState(prevState => ({
          errors: {
            ...prevState.user,
            selectEmployee: true
          }
        }));
        return;
      }
      this.props.addEmployer(data);
      setTimeout(() => {
        this.hideModal();
      }, 500);
    }
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>{this.props.message}</span>
              <div
                onClick={this.hideModal.bind(
                  this,
                  "addEmployer",
                  false
                )}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <img
                    className="input__icon--user"
                    src="/img/icons/user.svg"
                    alt=""
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="First Name"
                  id="first_name"
                  value={this.state.form.first_name}
                  onInput={this.changeForm.bind(this, "first_name")}
                  style={{ textTransform: "capitalize" }}
                />
                {this.state.errors.first_name ? (
                  <p className="contact-form-error">
                    Please enter First Name
                  </p>
                ) : null}
              </div>
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <img
                    className="input__icon--user"
                    src="/img/icons/user.svg"
                    alt=""
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="Last Name"
                  id="last_name"
                  value={this.state.form.last_name}
                  onInput={this.changeForm.bind(this, "last_name")}
                  style={{ textTransform: "capitalize" }}
                />
                {this.state.errors.last_name ? (
                  <p className="contact-form-error">
                    Please enter Last Name
                  </p>
                ) : null}
              </div>
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <img
                    className="input__icon--email"
                    src="/img/icons/email.svg"
                    alt=""
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="Email"
                  id="email"
                  value={this.state.form.email}
                  onInput={this.changeForm.bind(this, "email")}
                />
                {this.state.errors.email ? (
                  <p className="contact-form-error">
                    Please enter Email Address
                  </p>
                ) : null}
                {this.state.errors.emailAccepted ? (
                  <p className="contact-form-error">
                    This user already accepted invitation
                  </p>
                ) : null}
                {this.state.errors.emailInvited ? (
                  <p className="contact-form-error">
                    Invitation is already sent to this user
                  </p>
                ) : null}
              </div>
              <div>
                <div
                  className="employer-select"
                  style={{ marginTop: "1rem", minWidth: "335px" }}
                >
                  <select
                    name="employee_role_id"
                    className="btn btn-white wizard__btn mb-0 pl-12"
                    onChange={this.changeForm.bind(
                      this,
                      "employee_role_id"
                    )}
                  >
                    {this.props.roles &&
                      this.props.roles.map((data, i) => {
                        return (
                          <option
                            value={data.id}
                            key={i}
                            selected={
                              Number(
                                this.state.form.employee_role_id
                              ) === data.id
                            }
                          >
                            {data.role_name}
                          </option>
                        );
                      })}
                  </select>
                  {this.state.errors.selectEmployee ? (
                    <p
                      className="contact-form-error"
                      style={{ marginTop: "10px" }}
                    >
                      Select Employee Role
                    </p>
                  ) : null}
                </div>
              </div>

              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.addEmployerData.bind(this)}
                style={{ marginTop: "25px" }}
              >
                SAVE
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.dashboard.roles
});
const mapDispatchToProps = {
  getEmployeesRoles
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEmployer);
