import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import { getProfileEffect } from "../../Auth/Actions";
import { updateEmployeeCost } from "../../SignUp/Actions";
import { AppState } from "../../../store";

interface myProps {
  openModal: boolean;
  getProfileEffect: () => void;
  updateEmployeeCost: (value: number) => void;
  handleToggleModal: (value: boolean) => void;
  isLoading: boolean;
}
interface myState {
  cost: number;
  costError: boolean;
  modalIsOpen: boolean;
}
class AddEmployeeCostModal extends Component<myProps, myState> {
  constructor(props: myProps) {
    super(props);

    this.state = {
      modalIsOpen: this.props.openModal,
      costError: false,
      cost: 0,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({ modalIsOpen: nextProps.openModal });
  }

  componentDidMount() {
    this.props.getProfileEffect();
  }
  handleCost = (event: any) => {
    this.setState({ costError: false });
    this.setState({ cost: event.target.value });
  };
  hasErrors = () => {
    if (this.state.cost == 0) {
      this.setState({ costError: true });
      return true;
    }
    return false;
  };
  handleSubmit = () => {
    if (!this.hasErrors() && !this.props.isLoading) {
      this.props.updateEmployeeCost(this.state.cost);
      if (!this.props.isLoading) {
        setTimeout(() => {
          this.props.handleToggleModal(false);
        }, 2000);
      }
    }
  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>Add Employee Cost</span>
              <div
                onClick={() => this.props.handleToggleModal(false)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container">
                <input
                  className={"noButtons"}
                  onChange={(ev) => this.handleCost(ev)}
                  type="number"
                  id="emp_cost"
                  placeholder="Employee Cost"
                  style={{ textAlign: "center" }}
                />
              </div>
              <div className="credit-price__row">
                {this.state.costError ? "Invalid" : ""}
              </div>
              <button
                style={{ marginTop: -20 }}
                onClick={this.handleSubmit}
                className={`btn ${
                  this.props.isLoading ? "btn--loadingNew " : "btn-blue"
                } wizard__btn mb-0`}
              >
                ADD EMPLOYEE COST
                <img
                  className="wizard__btn--img-left"
                  src={
                    !this.props.isLoading
                      ? `/img/icons/coins-dark-blue.svg`
                      : ""
                  }
                  alt=""
                />
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  user: state.auth.user,
  isLoading: state.Loader.isLoading,
});

const mapDispatchToProps = {
  getProfileEffect,
  updateEmployeeCost,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEmployeeCostModal);
