import React, { Component } from "react";

export default class MapButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMap: false
    };
  }
  handleMap = () => {
    this.props.handleMap();
  };
  render() {
    return (
      <>
        <button
          style={{
            height: "42px",
            width: this.props.search ? "128px" : "100%"
          }}
          className={`btn btn-white wizard__btn mb-0  ${
            this.props.showMapView ? "block_view2 mblMap" : ""
          }`}
          onClick={() => this.handleMap()}
        >
          <span
            style={{
              left: this.props.search ? 3 : 0,
              position: this.props.search ? "relative" : ""
            }}
          >
            <svg
              aria-hidden="true"
              role="presentation"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                display: "block",
                fill: "none",
                height: "16px",
                stroke: "currentcolor",
                strokeWidth: 2,
                overflow: "visible",
                marginLeft: "2px"
              }}
              className={`${this.props.search ? "" : "svgMapIcon"} ${
                this.props.showMapView ? "mblMapOpen" : ""
              }`}
            >
              <g fill="none">
                <path d="m2.78306954 4.82598455 8.21693046-1.82598455 10 2 7.7830695-1.72957101c.5391333-.11980738 1.0733102.22012338 1.1931176.7592566.0158277.0712249.0238129.14396811.0238129.21693046v21.95121235c0 .4686986-.325532.874512-.7830695.9761871l-8.2169305 1.8259845-10-2-7.78306954 1.729571c-.53913323.1198074-1.07331014-.2201234-1.19311752-.7592566-.01582776-.0712249-.02381294-.1439681-.02381294-.2169305v-21.95121229c0-.46869865.32553199-.87451205.78306954-.97618706z"></path>
                <path
                  d="m9 17h24z"
                  transform="matrix(0 1 -1 0 38 -4)"
                ></path>
                <path
                  d="m-1 15h24z"
                  transform="matrix(0 1 -1 0 26 4)"
                ></path>
              </g>
            </svg>
          </span>
          <span
            style={{
              position: "relative",
              top: this.props.search ? "-19px" : "-17px",
              left: this.props.search ? 8 : 0
            }}
          >
            {!this.props.showMap ? " Show Map" : " Close Map"}
          </span>
        </button>
      </>
    );
  }
}
