import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "../../../UI/Modal";
import { updateEmployeeRequirements } from "../../SignUp/Actions";
import { handleRequirementsModal } from "../Actions";

const SafetyRequirementsModal = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const [ohs_requirements, set_ohs_requirements] = useState("No Restriction");
  const [vaccination_requirment, set_vaccination_requirment] =
    useState("No Restriction");
  const handleUpdate = () => {
    dispatch(
      updateEmployeeRequirements({
        vaccination_requirment,
        ohs_requirment: ohs_requirements,
      })
    );
  };
  useEffect(() => {
    if (user && user.organisation) {
      set_ohs_requirements(user.organisation.ohs_requirment);
      set_vaccination_requirment(user.organisation.vaccination_requirment);
    }
  }, [user]);
  const hideModal = () => {
    dispatch(handleRequirementsModal(false));
  };
  return (
    <Modal width={500}>
      <div
        className="addressbook-form-box"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "visible",
        }}
      >
        <div className="congrat-box__header">
          <span>Venue Restrictions</span>
          <div onClick={hideModal} className="close-btn"></div>
        </div>
        <div
          className="addressbook-form"
          style={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "0.2rem",
            }}
          >
            <div style={{ minWidth: "335px" }}>
              <label
                htmlFor=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="img/ohs.png"
                  style={{ width: "20px", marginRight: "2px" }}
                />
                OH&S Restrictions
                <div
                  className="col-md-1 tooltip"
                  style={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "2rem",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "20px",
                      marginLeft: "0.5rem",
                      color: "red",
                    }}
                    src="/img/info.png"
                  />
                  <span className="tooltiptext" style={{ width: "33rem" }}>
                    Choose to provide your team with access to OH&S approved
                    venues only,
                    <br /> or allow them explore the entire Dorpee Village
                  </span>
                </div>
              </label>
              <div className="employer-select" style={{ marginTop: "1rem" }}>
                <select
                  name="ohs_requirment"
                  className="btn btn-white wizard__btn mb-0 pl-12"
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    textAlign: "center",
                    paddingRight: "25px",
                    textOverflow: "ellipsis",
                  }}
                  onChange={(e) => set_ohs_requirements(e.target.value)}
                >
                  {[
                    "All venues can be booked",
                    "Only OH&S approved venues can be booked",
                  ].map((data, i) => {
                    return (
                      <option
                        value={data}
                        key={i}
                        selected={ohs_requirements === data ? true : false}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <div style={{ minWidth: "335px" }}>
              <label
                htmlFor="Vaccination Requirements"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src="img/vaccination.png" style={{ width: "20px" }} />
                Vaccination Requirements
              </label>
              <div className="employer-select" style={{ marginTop: "1rem" }}>
                <select
                  name="vaccination_requirment"
                  className="btn btn-white wizard__btn mb-0 pl-12"
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    textAlign: "center",
                    paddingRight: "12px",
                    textOverflow: "ellipsis",
                  }}
                  onChange={(e) => set_vaccination_requirment(e.target.value)}
                >
                  {[
                    "No Restriction",
                    "Partially or Fully Vaccinated",
                    "Fully Vaccinated",
                  ].map((data, i) => {
                    return (
                      <option
                        value={data}
                        key={i}
                        selected={
                          vaccination_requirment === data ? true : false
                        }
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
                <div
                  className="col-md-1 tooltip"
                  style={{
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ maxWidth: "20px", marginLeft: "0.5rem" }}
                    src="/img/info.png"
                  />
                  <span className="tooltiptext" style={{ bottom: "80%" }}>
                    Choose to provide your team with access to venues with
                    vaccination requirements only, or allow them to explore the
                    entire Dorpee Village
                  </span>
                </div>
              </div>
            </div>
          </div>
           */}
          <button
            style={{ maxWidth: "100px", marginTop: "20px" }}
            className={`btn btn-white wizard__btn mb-0`}
            onClick={handleUpdate}
          >
            <a
              style={{ color: "#009cdc", fontsize: 15, marginLeft: 0 }}
              title="Edit Vaccination and OH&S Requirements"
            >
              ADD
            </a>
            <img
              className="wizard__btn--img-left"
              src="static/img/add-contacts.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default SafetyRequirementsModal;
