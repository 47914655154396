import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import {
  getDashboardDataEffect,
  getVenuesStatsEffect
} from "./Actions";
import { getBookingEffect } from "../Book/Booking/Actions";
import { Link, useHistory } from "react-router-dom";
import VenueWeeklyUtilization from "./charts/venueWeeklyUtilization";
import NewVrsReturnedCustomers from "./charts/NewVrsReturnedCustomers";
import BookedGroupSize from "./charts/BookedGroupSize";
import BookingDuration from "./charts/BookingDuration";
import { getVenuesEffect } from "../../Screens/WorkspaceWizard/Actions";
import { DashboardApi } from "../../API/DashboardApi";
import { getProfileEffect } from "../Auth/Actions";
import Cookies from "js-cookie";
import store from "../../store";
import Checklist from "./Checklist";
import { GetVenueOpenHoursEffect } from "../VenueWizard/Actions";
import { Helmet } from "react-helmet";

const VenueDashboard = ({
  param,
  venueToken
}: {
  param?: any;
  venueToken?: string;
}) => {
  const currentDate = new Date();
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.auth.user!);
  const token = useSelector((state: AppState) => state.auth.token!);
  const bookings = useSelector(
    (state: AppState) => state.Booking.myBookings!
  );
  const dashboardData = useSelector(
    (state: AppState) => state.dashboard.dashboardData
  );
  const earnThisWeek = useSelector(
    (state: AppState) => state.dashboard.earnThisWeek
  );
  const venues = useSelector(
    (state: AppState) => state.workspaceWizard.venues
  );
  const AllvenueStats = useSelector(
    (state: AppState) => state.dashboard.venuesStats
  );
  const [bookedHours, setBookedHours] = useState(0);
  const [earnedValue, setEarnedValue] = useState(0);

  const [venueID, setVenueID] = useState(0);
  const [durationType, setdurationType] = useState("weekly");
  const [venueTitle, setvenueTitle] = useState("All Venues");

  const [data, setData] = useState(null);
  const [allVenuesData, setAllVenuesData] = useState(null);
  const [pieData, setPieData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (venueToken) {
      dispatch(getProfileEffect(venueToken));
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (venueToken) {
        dispatch(getBookingEffect(venueToken));
        dispatch(getDashboardDataEffect(venueToken));
        dispatch(getVenuesEffect(venueToken));
        dispatch(getVenuesStatsEffect(durationType));
        dispatch(GetVenueOpenHoursEffect(venueToken));
        barChart();
        pieChart();
      } else {
        dispatch(getBookingEffect());
        dispatch(getDashboardDataEffect());
        dispatch(getVenuesEffect());
        dispatch(getVenuesStatsEffect(durationType));
        dispatch(GetVenueOpenHoursEffect());
        barChart();
        pieChart();
      }
    }
    if (venueToken) {
      dispatch(getBookingEffect(venueToken));
      dispatch(getDashboardDataEffect(venueToken));
      dispatch(getVenuesEffect(venueToken));
      dispatch(getVenuesStatsEffect(durationType, null, venueToken));
      dispatch(GetVenueOpenHoursEffect(venueToken));
      barChart(venueToken);
      pieChart(venueToken);
    }
  }, []);

  // useEffect(() => {}, [venues]);
  const barChart = async (venueToken?: any) => {
    let response: any = await DashboardApi.GetBar(
      venueToken ? venueToken : token
    );
    setAllVenuesData(response);
    setData(response);
  };
  const pieChart = async (venueToken?: any) => {
    let response: any = await DashboardApi.GetPie(
      venueToken ? venueToken : token
    );
    setPieData(response);
  };

  useEffect(() => {
    AllvenueStats &&
      setEarnedValue(AllvenueStats.venues.total_bookings_revenue);
    if (venueTitle != "All Venues") {
      AllvenueStats.venues["barChartData"] = {
        data: {
          venues: AllvenueStats.venues["barChartData"]
        }
      };
      setData(AllvenueStats.venues["barChartData"]);
    }
    if (venueTitle == "All Venues") {
      setData(allVenuesData);
    }
    AllvenueStats &&
      setBookedHours(AllvenueStats.venues.total_bookings_hours);
  }, [AllvenueStats]);

  const handleEvent = (event: any) => {
    setVenueID(event.target.value);
    var index = event.nativeEvent.target.selectedIndex;
    let venuename = event.nativeEvent.target[index].text;
    setvenueTitle(venuename);
    if (event.target.value == 0) {
      dispatch(getVenuesStatsEffect(durationType));
    } else if (durationType) {
      dispatch(
        getVenuesStatsEffect(durationType, event.target.value)
      );
    }
  };
  const handleEventDuration = (event: any) => {
    setdurationType(event.target.value);
    if (venueID == 0) {
      dispatch(getVenuesStatsEffect(event.target.value));
    } else if (venueID) {
      dispatch(getVenuesStatsEffect(event.target.value, venueID));
    }
  };
  if (!user) return <></>;
  else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Venue Dashboard</title>
        </Helmet>
        <div className="container pd-0">
          <div className="dashboard">
            <div className="dashboard__top">
              <div className="dashboard__title">Venue Dashboard</div>
              {venueToken ? (
                ""
              ) : (
                <a
                  href="/profile"
                  title="Edit Profile"
                  onClick={e => {
                    Cookies.set("isEditProfile", "edit");
                  }}
                >
                  <div className="dashboard__edit"></div>
                </a>
              )}
            </div>
            <div className="dashboard__profile">
              <div className="dashboard__profile-info">
                <div className="dashboard__profile-avatar">
                  <img
                    src={
                      user && user.avatar
                        ? user.avatar
                        : "/img/icons/user-info.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="dashboard__profile-name">
                  {user && user.first_name} {user && user.last_name}
                </div>
              </div>
              <div className="dashboard__data">
                <Link
                  onClick={e => {
                    if (venueToken) {
                      e.preventDefault();
                    }
                  }}
                  to="/venues"
                  className="data__item"
                >
                  <div className="data__icon">
                    <div className="data__icon-active-venues"></div>
                  </div>
                  <div className="data__quantity">
                    {dashboardData &&
                      dashboardData.venues.active_venues}
                  </div>
                  <div className="data__title">
                    My
                    <br />
                    {dashboardData &&
                    dashboardData.venues.active_venues == 1
                      ? "Venue"
                      : "Venues"}
                  </div>
                  {!venueToken ? (
                    <Link
                      to="/create-venue"
                      className="venue-dash-links"
                    >
                      + ADD VENUE
                    </Link>
                  ) : (
                    <></>
                  )}
                </Link>
                <Link
                  to="/venues"
                  onClick={e => {
                    if (venueToken) {
                      e.preventDefault();
                    }
                  }}
                  className="data__item"
                >
                  <div className="data__icon">
                    <div className="data__icon-active-workspaces"></div>
                  </div>
                  <div className="data__quantity">
                    {dashboardData &&
                      dashboardData.venues.active_spaces}
                  </div>
                  <div className="data__title">
                    Active
                    <br />
                    {dashboardData &&
                    dashboardData.venues.active_spaces == 1
                      ? "Workspace"
                      : "Workspaces"}
                  </div>
                  {!venueToken ? (
                    <>
                      {" "}
                      {venues && venues.length > 0 ? (
                        <Link
                          to="/create-workspace"
                          className="venue-dash-links"
                        >
                          <div
                            onClick={() => {
                              window.localStorage.removeItem(
                                "venue_id"
                              );
                            }}
                            style={{
                              textAlign: "center",
                              lineHeight: "130%"
                            }}
                          >
                            + ADD WORKSPACE TYPE
                            <br />
                            TO A VENUE
                          </div>
                        </Link>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                </Link>

                {/* <div className="data__item">
                  <div className="data__icon">
                    <div className="data__icon-bookings"></div>
                  </div>
                  <Link
                    to="/bookings"
                    onClick={(e) => {
                      if (venueToken) {
                        e.preventDefault();
                      }
                    }}
                    className="data__item"
                  >
                    <div className="data__quantity">
                      {dashboardData &&
                        dashboardData.venues.active_booking.length}
                    </div>
                    <div className="data__title">
                      Active
                      <br />
                      {dashboardData &&
                      dashboardData.venues.active_booking.length == 1
                        ? "Booking"
                        : "Bookings"}
                    </div>
                  </Link>
                </div> */}

                <div className="data__item">
                  <div className="data__icon">
                    <div className="data__icon-total-bookings"></div>
                  </div>
                  <Link
                    to="/bookings"
                    onClick={e => {
                      if (venueToken) {
                        e.preventDefault();
                      }
                    }}
                    className="data__item"
                  >
                    <div className="data__quantity">
                      {dashboardData &&
                        dashboardData.venues.all_bookings.length}
                    </div>
                    <div className="data__title">
                      Total
                      <br />
                      {dashboardData &&
                      dashboardData.venues.all_bookings.length == 1
                        ? "Booking"
                        : "Bookings"}
                    </div>
                  </Link>
                </div>
              </div>
              <div className="dashboard__personal dashboard__venue">
                {venueToken ? (
                  ""
                ) : (
                  <Link to="/venues" style={{ color: "#fff" }}>
                    MY VENUES & WORKSPACES
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="overview">
            <Checklist
              venueToken={venueToken ? venueToken : null}
              history={history}
            />

            <div className="overview__block overview__block__margin-top">
              <div className="overview__block-row">
                <span>Earned this week:</span>
                <span className="overview__earned">
                  ${earnThisWeek ? earnThisWeek : 0}
                </span>
              </div>
              <div
                className="overview__block-row"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center"
                }}
              >
                {/* <span>Next payment date:</span> */}
                <span style={{ whiteSpace: "nowrap" }}>
                  Payment period end:
                </span>
                <span
                  className="overview__date"
                  style={{ whiteSpace: "nowrap", paddingLeft: 5 }}
                >
                  {months[currentDate.getMonth()]} 30
                </span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="statistics">
              <div className="content-title">STATISTICS</div>
              <div className="select-group">
                <div className="input-container">
                  <div className="input-select">
                    <select
                      onChange={e => handleEvent(e)}
                      className="pl-12"
                    >
                      <option value={0}>All Venues</option>
                      {venues &&
                        venues.map(venue => {
                          return (
                            <option key={venue.id} value={venue.id}>
                              {venue.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-select">
                    <select
                      className="pl-12"
                      onChange={e => handleEventDuration(e)}
                    >
                      <option defaultValue={"weekly"} value="weekly">
                        This Week
                      </option>
                      <option value="monthly">This Month</option>
                      <option value="yearly">This Year</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="all-venues">
            <div className="dashboard__title">{venueTitle}</div>
            <div className="stats">
              <div className="stats__item stats__item--blue">
                <div className="stats__item-title">{bookedHours}</div>
                <div className="stats__item-subtitle">
                  hours booked
                </div>
              </div>
              <div className="stats__item stats__item--green">
                <div className="stats__item-title">
                  ${earnedValue}
                </div>
                <div className="stats__item-subtitle">earned</div>
              </div>
            </div>
            <div className="content-title">
              UTILIZATION OVER A WEEK
            </div>
            <div className="utilization-chart__wrapper">
              {data ? <VenueWeeklyUtilization bar={data} /> : null}
            </div>
          </div>
          <div className="stats-chart__wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="stats-chart__item">
                  <div className="stats-chart__item-title">
                    {/* NEW VRS RETURNED CUSTOMERS */}
                    {/* NEW VRS RETURNED Workers*/}
                    New vs Old Users
                  </div>
                  <div className="stats-chart__item-chart">
                    {pieData ? (
                      <NewVrsReturnedCustomers pie={pieData} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="stats-chart__item">
                  <div className="stats-chart__item-title">
                    BOOKED GROUP SIZE
                  </div>
                  <div className="stats-chart__item-chart">
                    {pieData ? (
                      <BookedGroupSize pie={pieData} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="stats-chart__item">
                  <div className="stats-chart__item-title">
                    BOOKING DURATION
                  </div>
                  <div className="stats-chart__item-chart">
                    {pieData ? (
                      <BookingDuration pie={pieData} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default VenueDashboard;
