/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Dispatch } from "redux";
import { AppState } from "../../store";
import {
  Venue,
  VenueApi,
  VenueBasicSearchRequest,
  VenueAdvSearchRequest,
  CreateSaveSearchClickRequest,
  EditPreferenceRequest
} from "../../API/VenueApi";
import { History } from "history";
import { setLoaderSearch } from "../Loader/Actions";
import { getSearchPreferencesEffect } from "../Dashboard/Actions";
import { toast } from "react-toastify";

export function venueSearchResultsLoaded(venues: Venue[]) {
  return <const>{
    type: "@@venue_search/SEARCH_RESULTS_LOADED",
    venues
  };
}

export function saveBasicSearch(venues: any) {
  return <const>{
    type: "@@venue_search/BASIC_SEARCH",
    venues
  };
}

export function saveBasicSearch2(venues: any) {
  return <const>{
    type: "@@venue_search/BASIC_SEARCH_2",
    venues
  };
}
export function saveBasicSearchId(id: any) {
  return <const>{
    type: "@@venue_search/BASIC_SEARCH_ID",
    id
  };
}
export function saveScrollState(position: any) {
  return <const>{
    type: "@@venue_search/SAVE_LOADER_STATE",
    position
  };
}

export const submitVenueSearchEffect =
  (
    suburb: string,
    postcode: string,
    lat: any,
    lng: any,
    keyword: string,
    capacity: number,
    types: string[],
    only_ohs: string,
    history: History,
    date?: string,
    start_time?: string,
    end_time?: string,
    credits?: string
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoaderSearch(true));
    const response = await VenueApi.SearchVenuesBasic(
      new VenueBasicSearchRequest(
        suburb,
        postcode,
        lat,
        lng,
        keyword,
        capacity,
        types,
        only_ohs,
        date,
        start_time,
        end_time,
        credits
      ),
      getState().auth.token!
    );
    dispatch(setLoaderSearch(false));
    dispatch(venueSearchResultsLoaded(response.data));
    if (response.save_search_id)
      dispatch(saveBasicSearchId(response.save_search_id));
    history.push({
      pathname: "/search-results",
      state: { capacity, start_time, end_time, date }
    });
  };

export const EditPreferencesEffect =
  (
    request: any,
    id: any,
    handlePreferrencesModal: any,
    Dispacth: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(setLoaderSearch(true));
    const response = await VenueApi.EditPreference(
      id,
      new EditPreferenceRequest(
        request.types
          ? request.types && request.types.length > 0
            ? request.types
            : []
          : [],
        request.environments
          ? request.environments && request.environments.length > 0
            ? request.environments
            : []
          : [],
        request.services
          ? request.services && request.services.length > 0
            ? request.services
            : []
          : [],
        request.interests
          ? request.interests && request.interests.length > 0
            ? request.interests
            : []
          : [],
        request.assets
          ? request.assets && request.assets.length > 0
            ? request.assets
            : []
          : [],
        request.venue_types
          ? request.venue_types && request.venue_types.length > 0
            ? request.venue_types
            : []
          : []
      ),
      getState().auth.token!
    );
    if (response.data.success) {
      dispatch(setLoaderSearch(false));
      handlePreferrencesModal(false);
      Dispacth(getSearchPreferencesEffect());
      dispatch(venueSearchResultsLoaded(response.data));
      toast.success("Preference Updated");
    } else {
      toast.error("Something went wrong");
    }
  };
export const saveAdvSearchResultClick =
  (request: CreateSaveSearchClickRequest) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await VenueApi.PostSaveSearch(
      getState().auth.token!,
      request
    );
  };

export const submitVenueAdvanceSearchEffect =
  (
    suburb: string,
    no_of_people: number,
    types: string[],
    environments: string[],
    services: string[],
    interests: string[],
    date: string,
    start_time: string,
    end_time: string,
    credits: number,
    save_search_preferences: number,
    only_ohs: string,
    history: History,
    assets: string[],
    lat?: any,
    lng?: any,
    venue_types?: any,
    nearby_radius?: any
  ) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const response = await VenueApi.SearchAdvanceVenuesBasic(
      state.auth.token!,
      new VenueAdvSearchRequest(
        suburb,
        types
          ? types
          : state.venueSearch.basic_search.types
          ? state.venueSearch.basic_search.types
          : [],
        no_of_people,
        environments
          ? environments
          : state.venueSearch.basic_search.environments
          ? state.venueSearch.basic_search.environments
          : [],
        services
          ? services
          : state.venueSearch.basic_search.services
          ? state.venueSearch.basic_search.services
          : [],
        interests,
        date,
        start_time,
        end_time,
        credits,
        save_search_preferences,
        only_ohs,
        assets
          ? assets
          : state.venueSearch.basic_search.assets
          ? state.venueSearch.basic_search.assets
          : [],
        lat,
        lng,
        venue_types
          ? venue_types
          : state.venueSearch.basic_search.venue_types
          ? state.venueSearch.basic_search.venue_types
          : [],
        nearby_radius
      )
    );
    dispatch(saveBasicSearchId(response.save_search_id));
    dispatch(venueSearchResultsLoaded(response.data));
    history.push("/search-results");
  };
export type VenueSearchAction = ReturnType<
  | typeof venueSearchResultsLoaded
  | typeof saveBasicSearch
  | typeof saveBasicSearch2
  | typeof saveBasicSearchId
  | typeof saveScrollState
>;
