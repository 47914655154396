import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import "rc-time-picker/assets/index.css";
import { AppState } from "../store";
import _ from "lodash";
import moment from "moment";
import {
  Field,
  reduxForm,
  WrappedFieldProps,
  FormErrors
} from "redux-form";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import RouteIcon from "@mui/icons-material/Route";
import { History } from "history";
import {
  formatedTimeMin,
  formatDate,
  formatedTime
} from "../helpers/DateHelpers";
import MultiCheckbox from "../UI/MultiCheckBox";
import { Address } from "../UI/LocationPicker/LocationPicker";

import {
  saveBasicSearch,
  submitVenueAdvanceSearchEffect
} from "./VenueSearch/Actions";
import {
  getEnvironmentsEffect,
  getServicesEffect,
  getInterestEffect,
  getAssetsEffect
} from "../Screens/VenueWizard/Actions";
import MultiCheckboxN from "../UI/MulticheckboxN";
import Masonry from "react-masonry-css";

export type BasicSearchFields = {
  location: Address;
  no_of_people: number;
  types: string[];
  date: any;
  end_time: any;
  start_time: any;
  interests: string[];
  services: string[];
  environments: string[];
  venue_types: string[];
  total_credit: number;
  save_search_preferences: number;
  only_ohs: any;
  assets: string[];
  searchDetails?: any;
  nearby_radius?: any;
  credits: any;
};
const SearchAdvancedNew = reduxForm<
  BasicSearchFields,
  {
    history: History;
    scrollTop?: any;
    setIsAdvancedSearch?: any;
    location?: any;
    start_time?: any;
    end_time?: any;
    date?: any;
    capacity?: any;
    setSearchError?: any;
    setTimeError?: any;
    assets?: any;
    environments?: any;
    services?: any;
    types?: any;
    venue_types?: any;
    searchDetails?: any;
  }
>({
  form: "search",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values: any, dispatch: any, props: any) => {
    window.localStorage.setItem("searchType", "adv");
    if (values.end_time || values.start_time) {
      let start_time = values.start_time
        ? values.start_time._d
        : new Date();
      let end_time = values.end_time
        ? values.end_time._d
        : formatedTimeMin(true);
      if (end_time.getTime() < start_time.getTime()) {
        props.setIsAdvancedSearch(false);
        props.setTimeError("Must be greater than start time");
        return;
      }
    }
    // values.date =
    //   values.date == null || values.date == "" ? new Date() : values.date;
    // if (
    //   values.start_time === undefined ||
    //   values.start_time === null ||
    //   values.start_time === ""
    // ) {
    //   values.start_time = moment(formatedTimeMin());
    // }
    // if (
    //   values.end_time === undefined ||
    //   values.end_time === null ||
    //   values.end_time === ""
    // ) {
    //   values.end_time = moment(formatedTimeMin(true));
    // }
    // if (!values.location) {
    //   props.setIsAdvancedSearch(false);
    //   props.setSearchError("Location Required");
    //   return;
    // }
    props.setIsAdvancedSearch(false);
    dispatch(
      submitVenueAdvanceSearchEffect(
        values.location ? values.location.suburb || "" : "",
        values.no_of_people,
        values.types,
        values.environments,
        values.services,
        values.interests,
        values.date &&
          values.date != "" &&
          values.date !== null &&
          values.start_time !== undefined
          ? formatDate(values.date)
          : "",
        values.start_time !== undefined &&
          values.start_time !== null &&
          values.start_time !== ""
          ? formatedTime(values.start_time._d)
          : "",
        values.end_time !== undefined &&
          values.end_time != null &&
          values.end_time !== ""
          ? formatedTime(values.end_time._d)
          : "",
        values.credits ? values.credits : "",
        values.save_search_preferences,
        values.only_ohs ? "yes" : "no",
        props.history,
        values.assets,
        values.location ? values.location.lat || "" : "",
        values.location ? values.location.lng || "" : "",
        values.venue_types ? values.venue_types : "",
        values.nearby_radius ? values.nearby_radius : ""
      )
    );
    var datatobesaved = {
      location: values.location ? values.location.suburb || "" : "",
      capacity: values.no_of_people,
      date:
        values.date && values.date != "" && values.date !== null
          ? formatDate(values.date)
          : "",
      start_time:
        values.start_time !== undefined &&
        values.start_time !== null &&
        values.start_time !== ""
          ? formatedTime(values.start_time._d)
          : "",
      end_time:
        values.end_time !== undefined &&
        values.end_time != null &&
        values.end_time !== ""
          ? formatedTime(values.end_time._d)
          : "",
      types: values.types,
      total_credit: values.total_credit,
      environments: values.environments,
      services: values.services,
      interests: values.interests,
      assets: values.assets,
      venue_types: values.venue_types,
      credits: values.credits ? values.credits : ""
    };
    dispatch(saveBasicSearch(datatobesaved));
    if (props.scrollTop) {
      props.scrollTop();
    }
  },
  validate: (values, props) => {
    const errors: FormErrors<BasicSearchFields> = {};

    if (values.end_time || values.start_time) {
      let start_time = values.start_time
        ? values.start_time._d
        : new Date();
      let end_time = values.end_time
        ? values.end_time._d
        : formatedTimeMin(true);
      if (end_time.getTime() < start_time.getTime())
        errors.end_time = "Invalid time";
    }

    return errors;
  }
})(props => {
  const dispatch = useDispatch();

  const [servicesList, setList] = useState<any>([]);
  const [assetsList, setAssetsList] = useState<any>([]);
  const [showServices, setShowServices] = useState<any>(false);
  const [showAmenities, setShowAmenities] = useState<any>(false);

  const services = useSelector(
    (state: AppState) => state.venueWizard.services
  );
  const venueTypes = useSelector(
    (state: AppState) => state.workspaceWizard.venueTypes
  );
  const assets = useSelector(
    (state: AppState) => state.venueWizard.assets
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoadingSearch
  );
  const environments = useSelector(
    (state: AppState) => state.venueWizard.environments
  );
  const searchDetails = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );
  const venueType = useSelector(
    (state: AppState) => state.venueWizard.types
  );

  useEffect(() => {
    let grouped = _.mapValues(
      _.groupBy(services, "category"),
      service => service.map(ast => _.omit(ast, "category"))
    );
    setList(grouped);
  }, [services]);

  useEffect(() => {
    let grouped = _.mapValues(_.groupBy(assets, "category"), asset =>
      asset.map(ast => _.omit(ast, "category"))
    );
    setAssetsList(grouped);
  }, [assets]);

  useEffect(() => {
    dispatch(getEnvironmentsEffect());
    dispatch(getServicesEffect());
    dispatch(getAssetsEffect());
    dispatch(getInterestEffect());
  }, []);
  useEffect(() => {
    props &&
      props.initialize({
        location: props.location ? props.location : null,
        start_time: props.start_time ? props.start_time : null,
        end_time: props.end_time ? props.end_time : null,
        date: props.date ? props.date : null,
        no_of_people: props.capacity ? props.capacity : null,
        credits: searchDetails.credits ? searchDetails.credits : ""
      });
  }, [
    props.location,
    props.start_time,
    props.end_time,
    props.date,
    props.capacity
  ]);

  useEffect(() => {
    if (searchDetails) {
      searchDetails &&
        props.initialize({
          credits: searchDetails.credits ? searchDetails.credits : "",
          location:
            props.location && props.location.suburb != ""
              ? props.location
              : searchDetails.location,
          start_time: searchDetails.start_time,
          end_time: searchDetails.end_time,
          date: searchDetails.date,
          no_of_people: searchDetails.capacity,
          only_ohs: searchDetails.only_ohs === "true" ? true : false
        });
    }
  }, [searchDetails]);

  // const minValue = (min: any) => (value: any) =>
  //   value && value < min ? `Must be at least ${min}` : undefined;
  // const minValue1 = minValue(1);

  // const number = (value: any) =>
  //   value && isNaN(Number(value)) ? "Must be a number" : undefined;

  return (
    <div className="advanced_search_new clear">
      <div style={{ position: "absolute", right: "0rem", zIndex: 2 }}>
        <div
          onClick={() => {
            window.localStorage.setItem("searchType", "basic");
            props.setIsAdvancedSearch(false);
          }}
          className="close-btn-red"
        ></div>
      </div>
      <form
        onSubmit={props.handleSubmit}
        style={{
          position: "relative",
          overflow: "hidden"
        }}
      >
        <div style={{ maxHeight: "55%", overflow: "scroll" }}>
          {/* <div
          className="booking-search__tags"
          style={{ marginTop: "1rem", textAlign: "left" }}
        >
          <label style={{ textAlign: "justify" }}>Radius</label>
          <Field
            name="nearby_radius"
            type="text"
            component={(props: WrappedFieldProps) => {
              const displayError = props.meta.touched && props.meta.error;
              return (
                <>
                  <div
                    className={`input-container${
                      displayError ? " input-container--invalid" : ""
                    }`}
                  >
                    <div
                      className="input__icon"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RouteIcon
                        className="input__icon--keyword"
                        style={{
                          color: "#009cdc",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </div>
                    <input
                      placeholder="Radius (Km)"
                      onChange={(event) => {
                        props.input.onChange(event);
                      }}
                      value={props.input.value ? props.input.value : 5}
                      type="number"
                      style={{
                        height: "auto",
                        padding: "0px",
                        paddingLeft: "4px",
                        border: "none",
                      }}
                    />
                  </div>
                  {displayError && (
                    <div className="validation-error">{props.meta.error}</div>
                  )}
                </>
              );
            }}
            validate={[number, minValue1]}
            normalize={(val: any, prevVal: any) => {
              if (val) {
                return /^\d+$/.test(val) ? val : prevVal;
              }
              return val;
            }}
            inputmode="numeric"
          />
        </div>
        */}

          <div className="booking-search__tags">
            <div className="booking-search__tags-title">
              CREDITS RANGE
            </div>
            <div className="tags-section">
              <Field
                name="credits"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <span
                      className={"rangeSpan"}
                      style={{
                        backgroundColor:
                          props.input.value !== "0-50"
                            ? "#42c0d640"
                            : "#42c0d6",
                        position: "relative"
                      }}
                    >
                      <input
                        type="radio"
                        onClick={() => {
                          if (props.input.value == "0-50") {
                            dispatch(
                              saveBasicSearch({ credits: "" })
                            );
                            props.input.onChange("");
                          } else {
                            dispatch(
                              saveBasicSearch({ credits: "0-50" })
                            );
                            props.input.onChange("0-50");
                          }
                        }}
                        style={{
                          opacity: 0,
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          right: 0,
                          cursor: "pointer"
                        }}
                      ></input>
                      0-50 credits/hr
                    </span>
                  );
                }}
              />
              <Field
                name="credits"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <span
                      className={"rangeSpan"}
                      style={{
                        backgroundColor:
                          props.input.value !== "51-170"
                            ? "#4956a240"
                            : "#4956a2",
                        position: "relative"
                      }}
                    >
                      <input
                        type="radio"
                        onClick={() => {
                          if (props.input.value == "51-170") {
                            dispatch(
                              saveBasicSearch({ credits: "" })
                            );
                            props.input.onChange("");
                          } else {
                            dispatch(
                              saveBasicSearch({ credits: "51-170" })
                            );
                            props.input.onChange("51-170");
                          }
                        }}
                        style={{
                          opacity: 0,
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          right: 0,
                          cursor: "pointer"
                        }}
                      ></input>
                      51-170 credits/hr
                    </span>
                  );
                }}
              />
              <Field
                name="credits"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <span
                      className={"rangeSpan"}
                      style={{
                        backgroundColor:
                          props.input.value !== "171>"
                            ? "#ffbe0040"
                            : "#ffbe00",
                        position: "relative"
                      }}
                    >
                      <input
                        type="radio"
                        onClick={() => {
                          if (props.input.value == "171>") {
                            dispatch(
                              saveBasicSearch({ credits: "" })
                            );
                            props.input.onChange("");
                          } else {
                            dispatch(
                              saveBasicSearch({ credits: "171>" })
                            );
                            props.input.onChange("171>");
                          }
                        }}
                        style={{
                          opacity: 0,
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          right: 0,
                          cursor: "pointer"
                        }}
                      ></input>
                      170 + credits/hr
                    </span>
                  );
                }}
              />
            </div>
          </div>

          <div className="booking-search__tags">
            <div className="booking-search__tags-title">
              VENUE TYPE
            </div>
            <div className="tags-section">
              <Field
                type="checkbox"
                name="venue_types"
                checkboxes={venueType.map(t => ({
                  label: t.type,
                  value: t.id.toString()
                }))}
                component={MultiCheckbox}
              />
            </div>
          </div>
          <div className="booking-search__tags">
            <div className="booking-search__tags-title">
              SPACE TYPE
            </div>
            <div className="tags-section">
              <Field
                type="checkbox"
                name="types"
                checkboxes={venueTypes.map(t => ({
                  label: t.type,
                  value: t.id.toString()
                }))}
                component={MultiCheckbox}
              />
            </div>
          </div>

          <div className="filter-block">
            <div className="booking-search__tags-title">
              ENVIRONMENT
            </div>
            <div className="tags-section">
              <Field
                type="checkbox"
                name="environments"
                checkboxes={environments.map(t => ({
                  label: t.environment,
                  value: t.id.toString()
                }))}
                component={MultiCheckbox}
              />
            </div>
          </div>

          <div className="filter-block">
            <div
              className="booking-search__tags-title"
              onClick={() => setShowServices(!showServices)}
              style={{ cursor: "pointer" }}
            >
              Venue Amenities
              {showServices ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>

            <div
              className=""
              style={{ display: `${showServices ? true : false}` }}
            >
              {showServices ? (
                <Masonry
                  breakpointCols={2}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {Object.keys(servicesList).map(
                    (obj: any, index: any) => {
                      return (
                        <div className="amenities-item" key={index}>
                          <p className="title bold-title">{obj}</p>
                          <div className="all-item">
                            <Field
                              type="checkbox"
                              name="services"
                              checkboxes={servicesList[obj].map(
                                (data: any) => {
                                  if (data.venues_count > 0) {
                                    return {
                                      label: data.service,
                                      value: data.id.toString()
                                    };
                                  }
                                }
                              )}
                              component={MultiCheckboxN}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </Masonry>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="filter-block">
            <div
              className="booking-search__tags-title"
              onClick={() => setShowAmenities(!showAmenities)}
              style={{ cursor: "pointer" }}
            >
              Workspace Amenities
              {showAmenities ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>

            <div
              className=""
              style={{ display: `${showAmenities ? true : false}` }}
            >
              {showAmenities ? (
                <Masonry
                  breakpointCols={2}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {Object.keys(assetsList).map(
                    (obj: any, index: any) => {
                      return (
                        <div className="amenities-item" key={index}>
                          <p className="title bold-title">{obj}</p>
                          <div className="all-item">
                            <Field
                              type="checkbox"
                              name="assets"
                              checkboxes={assetsList[obj].map(
                                (data: any) => {
                                  if (data.spaces_count > 0) {
                                    return {
                                      label: data.asset,
                                      value: data.id.toString()
                                    };
                                  }
                                }
                              )}
                              component={MultiCheckboxN}
                              isAssets
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </Masonry>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "5px",
            width: "100%",
            maxHeight: "45%",
            overflow: "auto"
          }}
        >
          {" "}
          <div className="filter-block mb-0">
            <div className="switcher">
              <Field
                name="save_search_preferences"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <>
                      <label className="switch">
                        <input
                          value={props.input.value}
                          type="checkbox"
                          onChange={e => props.input.onChange(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                      {displayError && (
                        <div className="validation-error">
                          {props.meta.error}
                        </div>
                      )}
                    </>
                  );
                }}
              />
              <span>Save my search preferences</span>
            </div>
            <div className="switcher">
              <Field
                name="only_ohs"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <>
                      <label className="switch">
                        <input
                          value={props.input.value}
                          type="checkbox"
                          checked={props.input.value}
                          onChange={e => props.input.onChange(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                      {displayError && (
                        <div className="validation-error">
                          {props.meta.error}
                        </div>
                      )}
                    </>
                  );
                }}
              />

              <span>Search Only OH&S Verified Venues</span>
            </div>
          </div>
          <button
            className={
              isLoading
                ? "btn btn--loadingNew"
                : "booking-search__btn"
            }
            style={{
              backgroundColor: "rgb(245, 89, 127)"
            }}
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
});
export default SearchAdvancedNew;
