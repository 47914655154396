import { ApiClient } from "./ApiClient";
import { UserType, NotificationsState } from "../store";
import { UserInfoFields } from "../Screens/SignUp/UserInfoForm";
import { Interest } from "./EnumsApi";
import { UploadedImage, UserAssessment } from "./VenueApi";
import { AccountDetailFields } from "../Screens/AccountDetail/AccountDetail";
import { type } from "os";
import { Subscribe } from "react-facebook-pixel";
import Cookies from "js-cookie";

export class AuthApi {
  static SignUp(request: SignUpRequest) {
    return ApiClient.postMultipart<
      SignUpRequest,
      SignInResponse | ErrorsResponse
    >("register", request);
  }

  static UpdateEmployeeCost(employee_cost: number, token: string) {
    return ApiClient.put<{ employee_cost: number }, {}>(
      "organisations/update",
      { employee_cost: employee_cost },
      token
    );
  }

  static UpdateEmployeeVaccinationOhs(
    request: VaccinationOHSReq,
    token: string
  ) {
    return ApiClient.put<VaccinationOHSReq, {}>(
      "organisations/update",
      request,
      token
    );
  }

  static UpdateEmployerCompanyInfo(
    request: OrganisationInfoReq,
    token: string
  ) {
    return ApiClient.postMultipart<OrganisationInfoReq, {}>(
      "organisations/update",
      request,
      token
    );
  }

  static LogIn(request: LogInRequest) {
    return ApiClient.post<
      LogInRequest,
      SignInResponse | ErrorResponse
    >("login", request);
  }

  static ForgotPassword(request: ForgotPasswordRequest) {
    return ApiClient.post<ForgotPasswordRequest, {}>(
      "forgot-password",
      request
    );
  }

  static ResetPassword(request: ResetPasswordRequest) {
    return ApiClient.post<ResetPasswordRequest, {}>(
      "reset-password",
      request
    );
  }

  static ResetPasswordProfile(
    request: ChangePasswordRequest,
    token: string
  ) {
    return ApiClient.post<
      ChangePasswordRequest,
      { success: any; message: any }
    >("changepassword", request, token);
  }

  static UpdateProfile(request: UpdateUserRequest, token: string) {
    return ApiClient.postMultipart<UpdateUserRequest, {}>(
      "profile",
      request,
      token
    );
  }
  static AddPromoCode(request: any, token: string) {
    return ApiClient.postMultipart<UpdateUserRequest, {}>(
      "updateProfile",
      request,
      token
    );
  }

  static UpdateVaccinationCertificate(
    request: VaccinationOHSRequest,
    token: string
  ) {
    return ApiClient.postMultipart<VaccinationOHSRequest, {}>(
      "vaccination",
      request,
      token
    );
  }

  static UpdateAccountDetails(
    request: UpdateUserDetails,
    token: string
  ) {
    return ApiClient.postMultipart<UpdateUserDetails, {}>(
      "profile",
      request,
      token
    );
  }
  static Subscribe(request: SubscribeRequest) {
    return ApiClient.post<SubscribeRequest, SubscribeResponse>(
      "subscribe",
      request
    );
  }
  static GetProfile(token: string) {
    return ApiClient.get<{ data: User }>("profile", token);
  }
  static GetInviteeInfo(number: string) {
    return ApiClient.get<{ data: any }>(
      `customer/invite-detail/${number}`
    );
  }
  static GetInviteeFriendInfo(code: string) {
    return ApiClient.get<{ data: any }>(
      `user/invite-friends/${code}`
    );
  }
  static GetProfileTransactions(token: string) {
    return ApiClient.get<{ data: any }>("user/transactions", token);
  }
  static GetNotifications(token: string) {
    return ApiClient.get<{ data: Notification }>(
      "user/notifications",
      token
    );
  }
  static GetExistingEmailStatus(email: String) {
    return ApiClient.get(`check/email?email=${email}`);
  }
  static GetSMSVerification(request: SMSVerificationRequest) {
    return ApiClient.post<SMSVerificationRequest, { data: any }>(
      "twilio/get_token",
      request
    );
  }
  static ResendSMSVerification(request: SMSVerificationRequest) {
    return ApiClient.post<SMSVerificationRequest, { data: any }>(
      "twilio/resend_verification_token",
      request
    );
  }
  static SMSVerification(request: SendSMSVerificationRequest) {
    return ApiClient.post<SendSMSVerificationRequest, { data: any }>(
      "twilio/verify_token",
      request
    );
  }

  static InviteFriend(request: InviteRequest, token: string) {
    return ApiClient.post<
      InviteRequest,
      { data: any } | ErrorResponse
    >("user/invite-friend", request, token);
  }
  static getInviteFriendsList(token: string) {
    return ApiClient.get<{ data: any }>(
      "user/invited-friends",
      token
    );
  }
  static ValidatePromo(request: any) {
    return ApiClient.post<any, any>("promo_codes/validate", request);
  }
}

type ForgotPasswordRequest = {
  email: string;
};
type SMSVerificationRequest = {
  phone_number: string;
};
type SendSMSVerificationRequest = {
  phone_number: string;
  verification_code: string;
};

type Error = {
  error: string;
};

export function isError(value: any): value is Error {
  return value && value.error;
}

type ResetPasswordRequest = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

type ChangePasswordRequest = {
  // current_password: string;
  new_password: string;
};

type LogInRequest = {
  email: string;
  password: string;
};

type VaccinationOHSRequest = {
  vaccination_requirment: string;
  ohs_requirment: string;
};

type OrganisationInfoRequest = {
  company_name: string;
  logo: any;
  _method: any;
};

type InviteRequestData = {
  email: string;
  name?: string;
};

export type ErrorResponse = {
  error: string;
  errors: { [field: string]: string[] };
};

export type ErrorsResponse = {
  errors: { [field: string]: string[] };
};

export function isErrorResponse(value: any): value is ErrorResponse {
  return !!value.error;
}

export function isErrorsResponse(
  value: any
): value is ErrorsResponse {
  return !!value.errors;
}

export class UpdateUserRequest {
  constructor(
    userInfoFields: UserInfoFields,
    logo_deleted?: number,
    photo_deleted?: number,
    vaccination_certificate_deleted?: number
  ) {
    this.first_name = userInfoFields.firstName;
    this.password = userInfoFields.password;
    this.last_name = userInfoFields.lastName;
    this.email = userInfoFields.email;
    this.phone_number = userInfoFields.phoneNumber || "";
    this.company_name = userInfoFields.company_name || "";
    this.interests = userInfoFields.interests
      ? userInfoFields.interests.join(",")
      : "";
    // this.vaccination_status = userInfoFields.vaccination_status || "";
    this.file = userInfoFields.file;
    this.vaccination_certificate =
      userInfoFields.vaccination_certificate;
    this.ohs_only = userInfoFields.ohs_only;
    this.company_logo = userInfoFields.logo;
    this.region = userInfoFields.region || "";
    this.state = userInfoFields.state || "";
    // this.work_choice = userInfoFields.work_choice || "";
    this.logo_deleted = logo_deleted;
    this.photo_deleted = photo_deleted;
    this.vaccination_certificate_deleted =
      vaccination_certificate_deleted;
  }

  "first_name": string;
  "last_name": string;
  "email": string;
  "phone_number"?: string;
  "company_name": string;
  "interests"?: string;
  "linkedin": string;
  "privacy_linkedin": string;
  "privacy_name": string;
  "privacy_interests": string;
  "plan_id": number;
  "vaccination_status": string;
  "region": string;
  "state": string;
  "password"?: string;
  file?: File;
  ohs_only?: string;
  company_logo?: File;
  logo_deleted?: number;
  photo_deleted?: number;
  vaccination_certificate_deleted?: number;
  vaccination_certificate?: File;
}

export class UpdateUserDetails {
  constructor(AccountDetailFields: AccountDetailFields) {
    this.bsb = AccountDetailFields.bsb;
    this.account_name = AccountDetailFields.accountName;
    this.account_number = AccountDetailFields.accountNumber;
    this.abn = AccountDetailFields.abn;
    this.company_legal_name = AccountDetailFields.company_legal_name;
  }
  bsb: Number;
  account_name: string;
  account_number: Number;
  abn: Number;
  company_legal_name: string;
}
export class VaccinationOHSReq {
  constructor(VaccinationOhsFields: VaccinationOHSRequest) {
    this.ohs_requirment = VaccinationOhsFields.ohs_requirment;
    this.vaccination_requirment =
      VaccinationOhsFields.vaccination_requirment;
  }
  ohs_requirment: string;
  vaccination_requirment: string;
}

export class OrganisationInfoReq {
  constructor(
    OrganisationInfoFields: OrganisationInfoRequest,
    logo_deleted: number
  ) {
    this.company_name = OrganisationInfoFields.company_name;
    this.logo = OrganisationInfoFields.logo;
    this.logo_deleted = logo_deleted;
    this._method = OrganisationInfoFields._method;
  }
  company_name: string;
  logo: File;
  logo_deleted: number;
  _method: string;
}

export class InviteRequest {
  constructor(AccountDetailFields: InviteRequestData) {
    this.name = AccountDetailFields.name;
    this.email = AccountDetailFields.email;
  }
  name?: string;
  email: string;
}
export class SubscribeRequest {
  constructor(field: any) {
    this.email = field.email;
  }
  email: string;
}
export type SubscribeResponse = {
  success: boolean;
  message: string;
};

export class SignUpRequest {
  constructor(userInfoFields: UserInfoFields, userType: UserType) {
    this.email = userInfoFields.email;
    this.first_name = userInfoFields.firstName;
    this.last_name = userInfoFields.lastName;
    this.company_name = userInfoFields.company_name || "";
    this.password = userInfoFields.password!;
    this.password_confirmation = userInfoFields.repeatPassword!;
    this.phone_number = userInfoFields.phoneNumber || "";
    this.user_type =
      userInfoFields.userType &&
      userInfoFields.userType.toLowerCase();
    this.interests = userInfoFields.interests
      ? userInfoFields.interests.join(",")
      : "";
    // this.vaccination_status = userInfoFields.vaccination_status || "";

    this.file = userInfoFields.file;
    this.ohs_only = userInfoFields.ohs_only;
    this.company_logo = userInfoFields.company_logo;
    this.invite_number = userInfoFields.invite_number;
    this.friends_invite_code = userInfoFields.friends_invite_code;
    this.vaccination_certificate =
      userInfoFields.vaccination_certificate;
    this.state = userInfoFields.state || "";
    this.region = userInfoFields.region || "";
    // this.work_choice = userInfoFields.work_choice || "";
  }
  first_name: string;
  last_name: string;
  email: string;
  company_name: string;
  password: string;
  password_confirmation: string;
  phone_number: string;
  user_type?: string;
  interests?: string;
  invite_number?: string;
  file?: File;
  ohs_only?: string;
  company_logo?: File;
  friends_invite_code?: string;
  vaccination_status?: string;
  vaccination_certificate?: File;
  state?: string;
  region?: string;
  // work_choice?: string;
}

export type SignInResponse = {
  user: User;
  token: string;
  token_type: string;
  expires_at: string;
  errors?: { [field: string]: string[] };
};

export type Pivot = {
  user_id: number;
  role_id: number;
};

export type Role = {
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
  pivot: Pivot;
};

export function getUserType(roles: Role[]) {
  if (
    roles.some(r => r.title === "Venue") &&
    Cookies.get("roleUser") === "Venue"
  ) {
    return UserType.Venue;
  }
  if (
    roles.some(r => r.title === "Employer") &&
    Cookies.get("roleUser") === "Employer"
  ) {
    return UserType.Employer;
  }

  return UserType.Customer;
}

export type User = {
  id: number;
  first_name: string;
  email: string;
  email_verified_at?: any;
  created_at: string;
  updated_at: string;
  last_name: string;
  phone_number: string;
  photo?: UploadedImage;
  plan_expiry?: any;
  credit?: any;
  total_credits?: any;
  linkedin?: any;
  privacy_linkedin?: any;
  privacy_name?: any;
  privacy_interests?: any;
  plan_id?: any;
  company_name?: any;
  photo_link?: any;
  media: any[];
  interests: Interest[];
  role: Role[];
  plan?: any;
  avatar?: string;
  assessment?: UserAssessment;
  is_subscribed?: boolean;
  bookings_count?: number;
  bsb?: number;
  account_name?: string;
  account_number?: Number;
  status?: String;
  current_subscription: any;
  stripe_customer_id?: any;
  stripe_id?: any;
  card_last_four?: any;
  company_legal_name?: string;
  abn?: number;
  card_status?: boolean;
  ohs_only?: any;
  free_plan_status?: string;
  purchased_credits: string;
  employer_credits?: number;
  employee_cost?: number;
  employer_plan_id?: any;
  company_logo_link?: any;
  company_logo: any;
  friends_invite_code?: string;
  vaccination_status?: string;
  ohs_requirment?: string;
  vaccination_Requirements?: string;
  vaccination_certificate?: any;
  vaccination_requirment?: string;
  organisation?: any;
  region?: string;
  state?: string;
  subscriptions?: any;
  last4?: any;
  registered_date?: any;
  device_type?: any;
  // work_choice?: string;
};

// export type Notification = {
//   id?: number;
//   unread?: any[];
//   other?: any[];
// };
