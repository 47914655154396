/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Helmet from "react-helmet";
import moment from "moment";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

import { getFormated12hTimeNew } from "../../helpers/DateHelpers";
import {
  ChangeBookingStatusEffect,
  CancelVenueBookingEffect
} from "../../Screens/Book/Booking/Actions";
interface Props {
  activeBookings: any[];
  pastBookings: any[];
  cancelled_bookings: any[];
}

const VenueBookings: FC<Props> = ({
  activeBookings,
  pastBookings,
  cancelled_bookings
}) => {
  const dispatch = useDispatch();
  const [activeSort, setActiveSort]: any = useState(null);
  const [pastSort, setPastSort]: any = useState(null);
  const [cancelSort, setCancelSort]: any = useState(null);
  const [sortBy, setSortBy]: any = useState("");
  const [activeBookingList, setActiveBookingList]: any = useState([]);
  const [pastBookingList, setPastBookingList]: any = useState([]);
  const [cancelBookingList, setCancelBookingList]: any = useState([]);

  useEffect(() => {
    if (activeBookings.length > 0) {
      setActiveBookingList(activeBookings);
    }
    if (pastBookings.length > 0) {
      setPastBookingList(pastBookings);
    }
    if (cancelled_bookings.length > 0) {
      setCancelBookingList(cancelled_bookings);
    }
  }, [
    activeBookings.length,
    pastBookings.length,
    cancelled_bookings.length
  ]);

  const handleSort = async (
    sortValue: any,
    type: any,
    sortBy: any
  ) => {
    let sorted: any;
    // if (
    //   (type === "active" && sortValue === activeSort) ||
    //   (type === "past" && sortValue === pastSort)
    // ) {
    //   return null;
    // }
    if (type === "active") {
      if (sortValue === "name") {
        sorted = await activeBookings.sort((a, b): any => {
          let fa = a.space?.name.toLowerCase(),
            fb = b.space?.name.toLowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "dateTime") {
        sorted = await activeBookings.sort((a, b): any => {
          let adate: any = new Date(a.start_date);
          let atime = a.start_time.split(":");
          let bdate: any = new Date(b.start_date);
          let btime = b.start_time.split(":");
          adate.setHours(atime[0], atime[1], atime[2]);
          bdate.setHours(btime[0], btime[1], btime[2]);
          if (sortBy === "smallest") {
            return bdate - adate;
          } else {
            return adate - bdate;
          }
        });
      } else {
        sorted = activeBookings;
      }
      setSortBy(sortBy);
      setActiveSort(sortValue);
      setActiveBookingList(sorted);
    } else if (type === "past") {
      if (sortValue === "name") {
        sorted = await pastBookings.sort((a, b): any => {
          let fa = a.space?.name.toLowerCase(),
            fb = b.space?.name.toLowerCase();

          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "dateTime") {
        sorted = await pastBookings.sort((a, b): any => {
          let adate: any = new Date(a.start_date);
          let atime = a.start_time.split(":");
          let bdate: any = new Date(b.start_date);
          let btime = b.start_time.split(":");
          adate.setHours(atime[0], atime[1], atime[2]);
          bdate.setHours(btime[0], btime[1], btime[2]);
          if (sortBy === "smallest") {
            return bdate - adate;
          } else {
            return adate - bdate;
          }
        });
      } else {
        sorted = pastBookings;
      }
      setSortBy(sortBy);
      setPastSort(sortValue);
      setPastBookingList(sorted);
    } else if (type === "cancelled") {
      if (sortValue === "name") {
        sorted = await cancelled_bookings.sort((a, b): any => {
          let fa = a.space?.name.toLowerCase(),
            fb = b.space?.name.toLowerCase();
          if (sortBy === "smallest") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
      } else if (sortValue === "dateTime") {
        sorted = await cancelled_bookings.sort((a, b): any => {
          let adate: any = new Date(a.start_date);
          let atime = a.start_time.split(":");
          let bdate: any = new Date(b.start_date);
          let btime = b.start_time.split(":");
          adate.setHours(atime[0], atime[1], atime[2]);
          bdate.setHours(btime[0], btime[1], btime[2]);
          if (sortBy === "smallest") {
            return bdate - adate;
          } else {
            return adate - bdate;
          }
        });
      } else {
        sorted = cancelled_bookings;
      }
      setSortBy(sortBy);
      setCancelSort(sortValue);
      setCancelBookingList(sorted);
    }
  };

  return (
    <>
      <div className="container pd-0">
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            title="Dorpee Venue Bookings"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Bookings</title>
        </Helmet>
      </div>
      <div className="container">
        <div className="bookings-wrap">
          <div className="row justify-content-between to-book">
            <div className="booking_table_margins">
              <div className="approval__header-title ">
                <div className="dashboard__title headerTable">
                  Upcoming Bookings
                </div>
              </div>

              <div className="team-list ">
                <table className="team-list-table table-style">
                  <thead>
                    <tr>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            activeSort === "name" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort("name", "active", "smallest");
                          } else {
                            handleSort("name", "active", "largest");
                          }
                        }}
                      >
                        Workspace (Venue)
                        {activeSort === "name" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>
                      <th className="table-head">
                        Booked Workspaces
                      </th>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            activeSort === "dateTime" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort(
                              "dateTime",
                              "active",
                              "smallest"
                            );
                          } else {
                            handleSort(
                              "dateTime",
                              "active",
                              "largest"
                            );
                          }
                        }}
                      >
                        Date/Time
                        {activeSort === "dateTime" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>

                      <th className="table-head">Customer</th>
                      <th className="table-head">
                        Cancellation Period
                      </th>
                      <th className="table-head">Status</th>
                      <th className="table-head">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeBookingList &&
                      activeBookingList.map((activeBooking: any) => (
                        <tr style={{ height: 50 }}>
                          <td className="table-style">
                            {activeBooking.space?.name}(
                            {activeBooking.space?.venue?.name})
                          </td>
                          <td className="table-style">
                            {activeBooking.no_of_booked_spaces}
                            {activeBooking.no_of_booked_spaces
                              ? activeBooking.no_of_booked_spaces > 1
                                ? " workspaces"
                                : " workspace"
                              : "N/A"}
                          </td>
                          <td className="table-style">
                            {moment(
                              activeBooking.start_date,
                              "MM-DD-YYYY"
                            ).format("DD-MM-YYYY")}{" "}
                            <br />
                            {getFormated12hTimeNew(
                              activeBooking.start_time
                            )}{" "}
                            -{" "}
                            {getFormated12hTimeNew(
                              activeBooking.end_time
                            )}
                          </td>
                          <td className="table-style">
                            {activeBooking.user.first_name}
                            {activeBooking.user.last_name}
                            <br />
                            {activeBooking.user.phone_number}
                          </td>
                          <td className="table-style">
                            {activeBooking.space
                              .cancellation_period == 0
                              ? "Cancel or update anytime"
                              : activeBooking.space
                                  .cancellation_period}
                          </td>

                          <td className="table-style">
                            {" "}
                            {activeBooking.status != "Checked-Out" ? (
                              <button
                                style={{ padding: 0, width: "100%" }}
                                type="submit"
                                onClick={() =>
                                  dispatch(
                                    ChangeBookingStatusEffect({
                                      booking_id: activeBooking.id,
                                      status:
                                        activeBooking.status ===
                                        "Scheduled"
                                          ? "Checked-In"
                                          : "Checked-Out"
                                    })
                                  )
                                }
                                className={`btn2 btn-blue`}
                              >
                                <span className="check-in-text">
                                  {activeBooking.status ===
                                  "Scheduled"
                                    ? "Check In"
                                    : "Check Out"}
                                </span>
                              </button>
                            ) : (
                              "Checked Out"
                            )}
                          </td>
                          <td className="table-style">
                            <button
                              style={{
                                padding: 0,
                                width: "100%",
                                background: "#e30e0e"
                              }}
                              type="submit"
                              onClick={() => {
                                let booking_id = activeBooking.id;
                                let request = {
                                  status: "Cancelled",
                                  _method: "PUT"
                                };
                                dispatch(
                                  CancelVenueBookingEffect(
                                    request,
                                    booking_id
                                  )
                                );
                              }}
                              className={`btn2`}
                            >
                              <span className="check-in-text">
                                Cancel Booking
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div
                className="approval__header-title"
                style={{ marginTop: 100 }}
              >
                <div className="dashboard__title headerTable">
                  Past Bookings
                </div>
              </div>

              <div className="team-list">
                <table className="team-list-table table-style">
                  <thead>
                    <tr>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            pastSort === "name" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort("name", "past", "smallest");
                          } else {
                            handleSort("name", "past", "largest");
                          }
                        }}
                      >
                        Workspace (Venue)
                        {pastSort === "name" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>
                      <th className="table-head">
                        Booked Workspaces
                      </th>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            pastSort === "dateTime" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort(
                              "dateTime",
                              "past",
                              "smallest"
                            );
                          } else {
                            handleSort("dateTime", "past", "largest");
                          }
                        }}
                      >
                        Date/Time
                        {pastSort === "dateTime" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>
                      <th className="table-head">Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastBookingList &&
                      pastBookingList.map((pastBooking: any) => (
                        <tr style={{ height: 50 }}>
                          <td className="table-style">
                            {pastBooking.space?.name}({" "}
                            {pastBooking.space?.venue?.name})
                          </td>
                          <td className="table-style">
                            {pastBooking.no_of_booked_spaces}
                            {pastBooking.no_of_booked_spaces
                              ? pastBooking.no_of_booked_spaces > 1
                                ? " workspaces"
                                : " workspace"
                              : "N/A"}
                          </td>
                          <td className="table-style">
                            {moment(
                              pastBooking.start_date,
                              "MM-DD-YYYY"
                            ).format("DD-MM-YYYY")}
                            <br />
                            {getFormated12hTimeNew(
                              pastBooking.start_time
                            )}{" "}
                            -{" "}
                            {getFormated12hTimeNew(
                              pastBooking.end_time
                            )}
                          </td>
                          <td className="table-style">
                            {pastBooking.user?.first_name!}
                            {pastBooking.user?.last_name!}
                            <br />
                            {pastBooking.user?.phone_number!}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div
                className="approval__header-title"
                style={{ marginTop: 100 }}
              >
                <div className="dashboard__title headerTable">
                  Cancelled Bookings
                </div>
              </div>

              <div className="team-list">
                <table className="team-list-table table-style">
                  <thead>
                    <tr>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            cancelSort === "name" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort(
                              "name",
                              "cancelled",
                              "smallest"
                            );
                          } else {
                            handleSort(
                              "name",
                              "cancelled",
                              "largest"
                            );
                          }
                        }}
                      >
                        Workspace (Venue)
                        {cancelSort === "name" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>
                      <th className="table-head">
                        Booked Workspaces
                      </th>
                      <th
                        className="table-head table_sort"
                        onClick={() => {
                          if (
                            cancelSort === "dateTime" &&
                            sortBy !== "smallest"
                          ) {
                            handleSort(
                              "dateTime",
                              "cancelled",
                              "smallest"
                            );
                          } else {
                            handleSort(
                              "dateTime",
                              "cancelled",
                              "largest"
                            );
                          }
                        }}
                      >
                        Date/Time
                        {cancelSort === "dateTime" &&
                        sortBy === "largest" ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </th>
                      <th className="table-head">Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cancelBookingList &&
                      cancelBookingList.map(
                        (cancelled_booking: any) => (
                          <tr style={{ height: 50 }}>
                            <td className="table-style">
                              {cancelled_booking.space?.name}({" "}
                              {cancelled_booking.space?.venue?.name})
                            </td>
                            <td className="table-style">
                              {cancelled_booking.no_of_booked_spaces}
                              {cancelled_booking.no_of_booked_spaces
                                ? cancelled_booking.no_of_booked_spaces >
                                  1
                                  ? " workspaces"
                                  : " workspace"
                                : "N/A"}
                            </td>
                            <td className="table-style">
                              {moment(
                                cancelled_booking.start_date,
                                "MM-DD-YYYY"
                              ).format("DD-MM-YYYY")}{" "}
                              <br />
                              {getFormated12hTimeNew(
                                cancelled_booking.start_time
                              )}{" "}
                              -{" "}
                              {getFormated12hTimeNew(
                                cancelled_booking.end_time
                              )}
                            </td>
                            <td className="table-style">
                              {cancelled_booking.user.first_name}
                              {cancelled_booking.user.last_name}
                              <br />
                              {cancelled_booking.user.phone_number}
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VenueBookings;
