import { reduxForm, Field, FormErrors } from "redux-form";
import React, { useEffect, useState } from "react";
import BasicField from "../UI/BasicField/BasicField";
import { useHistory } from "react-router-dom";

import { Form, FormField } from "semantic-ui-react";
import { changePasswordEffect } from "./Auth/Actions";
import { isPropsEqual } from "@fullcalendar/core";
import { AppState } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export type ResetPassword1Fields = {
  current_password: string;
  new_password: string;
  confirmed_password: string;
};

const ResetPassword1 = reduxForm<ResetPassword1Fields>({
  form: "passwordReset",
  destroyOnUnmount: false,
  onSubmit: (formFields, dispatch) => {
    let current_password = formFields.current_password;
    let new_password = formFields.new_password;
    // dispatch(changePasswordEffect(current_password, new_password));
  },
  validate: (values, props) => {
    const errors: FormErrors<ResetPassword1Fields> = {};
    if (!values.current_password) {
      errors.current_password = "Required";
    }
    if (!values.new_password) {
      errors.new_password = "Required";
    }
    if (!values.confirmed_password) {
      errors.confirmed_password = "Required";
    }
    if (
      values.new_password &&
      !/^(?=).{8,}$/i.test(values.new_password!)
    ) {
      errors.new_password =
        "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
    } else {
      let hasUpperCase: any = /[A-Z]/.test(values.new_password!);
      let hasLowerCase: any = /[a-z]/.test(values.new_password!);
      let hasNumbers: any = /\d/.test(values.new_password!);
      let hasNonalphas: any = /\W/.test(values.new_password!);

      if (
        !hasUpperCase ||
        !hasLowerCase ||
        !hasNumbers ||
        !hasNonalphas
      ) {
        errors.new_password =
          "Minimum 8 characters including at least 1 capital letter, 1 number and 1 symbol.";
      }
    }

    if (values.new_password !== values.confirmed_password) {
      errors.confirmed_password = "Confirm password does not match";
    }
    if (
      values.new_password &&
      values.new_password === values.current_password
    ) {
      errors.new_password =
        "New matches old password, please choose a new password";
    }
    return errors;
  }
})(props => {
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<Boolean>(false);
  const [showNewPassword, setShowNewPassword] =
    useState<Boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] =
    useState<Boolean>(false);

  return (
    <Form onSubmit={props.handleSubmit}>
      <div className="wrapper wrapper-login">
        <div
          className="wizard"
          style={{
            boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.49)"
          }}
        >
          <div className="wizard__title">Reset Password</div>
          <div style={{ position: "relative" }}>
            <Field
              name="current_password"
              component={BasicField}
              type={showCurrentPassword ? "text" : "password"}
              placeholder="Current Password"
            />
            <div
              className="visibility_icon"
              onClick={() =>
                setShowCurrentPassword(!showCurrentPassword)
              }
            >
              {showCurrentPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <Field
              name="new_password"
              component={BasicField}
              type={showNewPassword ? "text" : "password"}
              placeholder="New Password"
            />{" "}
            <div
              className="visibility_icon"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <Field
              name="confirmed_password"
              component={BasicField}
              type={showRepeatPassword ? "text" : "password"}
              placeholder="Confirm New Password"
            />
            <div
              className="visibility_icon"
              onClick={() =>
                setShowRepeatPassword(!showRepeatPassword)
              }
            >
              {showRepeatPassword ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )}
            </div>
          </div>
          <button className="btn btn-blue wizard__btn mb-0">
            Submit
          </button>
        </div>
      </div>
    </Form>
  );
});

export default ResetPassword1;
