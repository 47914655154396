import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import store from "../../store";
import { DashboardApi } from "../../API/DashboardApi";
import { setTeam_ContactError } from "../Contact/Actions";

import AddContact from "./modals/AddContact";
import AddTeam from "./modals/AddTeam";
class AddressBook extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      showAddContactModal: false,
      showAddTeamModal: false,
      teamList: [],
      contactList: [],
      contactForm: {
        first_name: "",
        last_name: "",
        email: "",
        team_id: ""
      }
    };
    this.addTeam = this.addTeam.bind(this);
    this.showHideModal = this.showHideModal.bind(this);
    this.addContact = this.addContact.bind(this);
    this.removeTeam = this.removeTeam.bind(this);
    this.updateTeam = this.updateTeam.bind(this);
  }

  showHideModal(type, state) {
    if (type === "addTeam")
      this.setState({ showAddTeamModal: state });
    if (type === "addContact")
      this.setState({ showAddContactModal: state });
    this.forceUpdate();
  }

  async getTeamList() {
    const token = store.getState().auth.token;
    let response = await DashboardApi.GetTeams(token);
    if (this._isMounted) {
      this.setState({
        teamList: response.data
      });
    }
  }

  async getContactList() {
    const token = store.getState().auth.token;
    let response = await DashboardApi.GetContacts(token);
    if (this._isMounted) {
      this.setState({
        contactList: response.data
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.getTeamList();
    this.getContactList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showSuccessNotification(text) {
    toast.success(text);
  }

  async addTeam(e) {
    let data = e;
    const token = store.getState().auth.token;
    let response = await DashboardApi.CreateTeam(token, data);
    if (response.errors) {
      let err = { status: true, message: response.errors.name[0] };
      this.props.setTeam_ContactError(err);
    } else {
      this.props.setTeam_ContactError({ status: false, message: "" });
      this.showSuccessNotification("Team Created");
    }

    this.getTeamList();
  }

  async updateTeam(e, id) {
    const token = store.getState().auth.token;
    let response = await DashboardApi.UpdateTeam(
      token,
      id.toString(),
      e
    );
    if (response && response.message) {
      this.props.setTeam_ContactError({
        status: true,
        message: response.errors.name[0]
      });
    } else {
      this.showSuccessNotification("Contact Updated");
      this.props.setTeam_ContactError({ status: false, message: "" });
    }
    this.getTeamList();
  }

  async addContact(e) {
    let data = e;
    const token = store.getState().auth.token;
    let response = await DashboardApi.CreateContact(token, data);
    if (response.errors) {
      let err = { status: true, message: response.errors.email };
      this.props.setTeam_ContactError(err);
    } else {
      this.props.setTeam_ContactError({ status: false, message: "" });
      this.setState({
        showAddContactModal: !this.state.showAddContactModal
      });
      this.showSuccessNotification("Contact Created");
    }
    this.getContactList();
  }

  async removeTeam(id) {
    const token = store.getState().auth.token;
    await DashboardApi.DeleteTeam(token, id);
    this.showSuccessNotification("Team Removed");
    this.getTeamList();
  }

  async removeContact(id) {
    const token = store.getState().auth.token;
    await DashboardApi.DeleteContact(token, id);
    this.showSuccessNotification("Contact Removed");
    this.getContactList();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="Dorpee-workspaces on demand"
          />
          <title>Dorpee - Address Book</title>
        </Helmet>
        <div className="inv-person__wrap">
          <div className="Address-book__title">My Address Book</div>
          <div className="Address-book__inner">
            {this.state.contactList &&
            this.state.contactList.length > 0 ? (
              this.state.contactList.map(item => (
                <div className="person-row" key={item.id}>
                  <div className="person-row__left">
                    <div className="person-avatar person-avatar-default wizard__user-icon"></div>
                    <div className="person-name">
                      {item.first_name +
                        " " +
                        item.last_name +
                        "  - " +
                        item.email}
                      <img
                        className="team-actions-icon"
                        onClick={this.removeContact.bind(
                          this,
                          item.id
                        )}
                        src="/img/icons/cross-white.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="person-row__btn">
                    <div className="person-name">
                      {item.team ? item.team.name : null}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Contacts, Please Create Contact</p>
            )}
          </div>
          <div className="addressbook__wrapper">
            <div className="addressbook-actions">
              <button
                className="btn addressbook-modal-btn wizard__btn mb-0"
                onClick={this.showHideModal.bind(
                  this,
                  "addTeam",
                  true
                )}
                style={{
                  color: "#009cdc",
                  border: "1px solid #009cdc"
                }}
              >
                <img
                  className="wizard__btn--img-left"
                  src="/img/icons/people.svg"
                  alt=""
                />
                TEAMS
              </button>
              <button
                className="btn addressbook-modal-btn wizard__btn mb-0"
                onClick={this.showHideModal.bind(
                  this,
                  "addContact",
                  true
                )}
                style={{
                  color: "#009cdc",
                  border: "1px solid #009cdc"
                }}
              >
                <img
                  className="wizard__btn--img-left"
                  src="/img/icons/add-contacts.svg"
                  alt=""
                />
                Add Contact
              </button>
            </div>
          </div>

          {this.state.showAddContactModal ? (
            <AddContact
              addContact={this.addContact}
              showHideModal={this.showHideModal}
              {...this.state}
            />
          ) : null}
          {this.state.showAddTeamModal ? (
            <AddTeam
              removeTeam={this.removeTeam}
              addTeam={this.addTeam}
              updateTeam={this.updateTeam}
              errorInfo={this.state.errorTeam}
              showHideModal={this.showHideModal}
              {...this.state}
            />
          ) : null}
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  errors: state.Contacts.error
});

const mapDispatchToProps = {
  setTeam_ContactError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressBook);
