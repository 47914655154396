import React, { FC, useState } from "react";
import { Field, WrappedFieldProps } from "redux-form";
import { Helmet } from "react-helmet";

import BasicField from "../../../UI/BasicField/BasicField";
import { Venue } from "../../../API/VenueApi";

interface Props {
  isVenueFlow: any;
  venues: Venue[];
  handleVenueId: (e: any) => void;
  workspaceId?: string;
}

const WorkspaceForm: FC<Props> = ({
  isVenueFlow,
  venues,
  handleVenueId,
  workspaceId
}) => {
  const [isFreeSpace, setIsFreeSpace] = useState(false);

  const getSwitchValue = (value: any) => {
    setIsFreeSpace(value);
    return value;
  };

  const minValue = (min: any) => (value: any) =>
    value && value < min ? `Must be at least ${min}` : undefined;
  const minValue1 = minValue(0);

  const number = (value: any) =>
    value && isNaN(Number(value)) ? "Must be a number" : undefined;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>
          {window.location.href.indexOf("edit-workspace") > -1
            ? "Dorpee - WorkSpace - 1 "
            : "Dorpee - List Your Venue - Workspace Type"}
        </title>
      </Helmet>
      <div className="col-lg-8">
        <div className="all-item">
          {Object.keys(isVenueFlow).length > 0 ||
          workspaceId ||
          window.localStorage.getItem("venue_id") ? null : (
            <div className="item">
              <Field
                name="venueId"
                component={(props: WrappedFieldProps) => {
                  const displayError =
                    props.meta.touched && props.meta.error;
                  return (
                    <>
                      <div
                        className={`input-container${
                          displayError
                            ? " input-container--invalid"
                            : ""
                        }`}
                        style={{
                          height: 35
                        }}
                      >
                        <select
                          style={{ padding: 5 }}
                          // {...props.input}
                          onChange={e => {
                            props.input.onChange(e);
                            handleVenueId(e.target.value);
                          }}
                          value={props.input.value}
                        >
                          <option value="">Select Venue</option>

                          {venues &&
                            venues.map(v => (
                              <option value={v.id}>{v.name}</option>
                            ))}
                        </select>
                      </div>
                      {displayError && (
                        <div className="validation-error">
                          {props.meta.error}
                        </div>
                      )}
                    </>
                  );
                }}
              />
            </div>
          )}
          <div className="item">
              <label style={{ fontSize: 14 }}>
                  Workspace Name
              </label>
            <Field
              name="name"
              component={BasicField}
              type="text"
              placeholder="e.g. Window seat / Private dining room."
            />
          </div>
          <div className="item">
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <div className="col-md-6">
                <div
                  className="ui toggle checkbox"
                  style={{ marginTop: 9 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative"
                    }}
                  >
                    <Field
                      name="free_space"
                      component={(props: WrappedFieldProps) => {
                        const displayError =
                          props.meta.touched && props.meta.error;
                        return (
                          <>
                            <label className="switch">
                              <input
                                defaultChecked={props.input.value}
                                type="checkbox"
                                checked={getSwitchValue(
                                  props.input.value
                                )}
                                onChange={e => {
                                  props.input.onChange(e);
                                  setIsFreeSpace(!isFreeSpace);
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            {displayError && (
                              <div className="validation-error">
                                {props.meta.error}
                              </div>
                            )}
                          </>
                        );
                      }}
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        maxWidth: "max-content"
                      }}
                    >
                      This is a free workspace
                      <div
                        className="tooltip"
                        style={{
                          border: "none",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            zIndex: 100
                          }}
                          src="/img/icons/info.png"
                        />
                        <span className="tooltiptext">
                          If you choose this option, only Dorpee
                          guests who subscribe to a Regular plan or
                          above plan will have access to this
                          workspace.
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isFreeSpace ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      position: "relative"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        marginRight: "4px"
                      }}
                    >
                      ${" "}
                    </span>
                    <span
                      style={{
                        width: "60%"
                        // display: "flex"
                      }}
                    >
                      <Field
                        name="price"
                        component={BasicField}
                        className={"noButtons"}
                        type="number"
                        onKeyDown={(e: { keyCode: number }) => {
                          return e.keyCode !== 69;
                        }}
                        placeholder="Price per hour"
                        style={{ height: "30px" }}
                      />
                    </span>
                    <p
                      style={{
                        fontSize: "14px",
                        marginLeft: "4px",
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        maxWidth: "max-content"
                      }}
                    >
                      per hour{" "}
                      <span
                        className="tooltip"
                        style={{
                          border: "none",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "20px",
                            zIndex: 100
                          }}
                          src="/img/icons/info.png"
                        />
                        <span className="tooltiptext">
                          If you choose this option, Dorpee guests can
                          book this workspace by the hour, at the
                          price you decide, during your opening hours.{" "}
                        </span>
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{
              position: "relative"
            }}
          >
            <div className="title">
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  maxWidth: "max-content"
                }}
              >
                Set the minimum and maximum booking hours
                <div
                  className="tooltip"
                  style={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                      zIndex: 100
                    }}
                    src="/img/icons/info.png"
                  />
                  <span className="tooltiptext">
                    You can decide the minimum and maximum hours
                    Dorpee customers can book this workspace for.
                  </span>
                </div>
              </p>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mgb-field-item">
                      <Field
                        name="min_booking_hours"
                        component={BasicField}
                        className={"noButtons"}
                        type="text"
                        placeholder="Min Hour"
                        validate={[number, minValue1]}
                        normalize={(val: any, prevVal: any) => {
                          if (val) {
                            return /^\d+$/.test(val) ? val : prevVal;
                          }
                          return val;
                        }}
                        inputmode="numeric"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mgb-field-item">
                      <Field
                        name="max_booking_hours"
                        component={BasicField}
                        className={"noButtons"}
                        type="text"
                        placeholder="Max Hour"
                        validate={[number, minValue1]}
                        normalize={(val: any, prevVal: any) => {
                          if (val) {
                            return /^\d+$/.test(val) ? val : prevVal;
                          }
                          return val;
                        }}
                        inputmode="numeric"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{
              position: "relative"
            }}
          >
            <Field
              name="description"
              component={BasicField}
              isTextArea={true}
              placeholder="Describe the Workspace?"
            />
            <div
              className="tooltip"
              style={{
                maxWidth: "5%",
                border: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                right: "-20px",
                top: 0
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  zIndex: 100
                }}
                src="/img/icons/info.png"
              />
              <span className="tooltiptext">
                Briefly describe this workspace, for example
                “comfortable seats with a window view”, “private table
                away from all the noise”or “Small sunlit window seat
                surrounded by plants”.
              </span>{" "}
            </div>
          </div>
          <div className="item">
            <div className="title">
              <p>How many workspaces of this type do you offer?</p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="field-number clearfix"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="number ">
                    <label style={{ fontSize: 14 }}>

                      Number of Workspaces:

                    </label>
                    <Field
                      name="quantity"
                      component={BasicField}
                      className={"noButtons"}
                      type="number"
                      min={0}
                      max={100000}
                      placeholder=""
                    />
                  </div>
                  <div className="max-number">
                    <label style={{ fontSize: 14 }}>
                      Number of People per Workspace:
                    </label>
                    <Field
                      name="capacity"
                      component={BasicField}
                      className={"noButtons"}
                      type="number"
                      min={0}
                      max={100000}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="item"
            style={{
              position: "relative"
            }}
          >
            <div className="title">
              <p
                style={{
                  position: "relative",
                  maxWidth: "max-content"
                }}
              >
                Cancellation notice period
                <span
                  className="tooltip"
                  style={{
                    maxWidth: "5%",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    right: "-10%",
                    top: 0
                  }}
                >
                  {" "}
                  <img
                    style={{
                      maxWidth: "20px",
                      zIndex: 100
                    }}
                    src="/img/icons/info.png"
                  />
                  <span className="tooltiptext">
                    How much notice do you require if a Dorpee guest
                    needs to cancel their booking?.
                  </span>{" "}
                </span>
              </p>
            </div>
            <Field
              className={"noButtons"}
              name="cancellation_period"
              component={BasicField}
              type="number"
              placeholder="Cancellation Period (In hours)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceForm;
