import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getInvitesList } from "./Dashboard/Actions";
import { DashboardApi } from "../API/DashboardApi";
import { Modal } from "../UI/Modal";
import store from "../store";

const InvitesModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvitesList());
  }, []);

  const inviteList = useSelector(
    state => state.dashboard.customerInvitation
  );

  const hideModal = () => {
    history.push({
      pathname: "/",
      state: { login: false }
    });
  };

  const acceptRejectInvitation = async (invite_number, status) => {
    let data = {
      status: status
    };
    const token = store.getState().auth.token;
    let response = await DashboardApi.AcceptRejectInvitation(
      token,
      invite_number,
      data
    );
    if (response.data) {
      if (response.data.status) {
        toast.success("Invitation successfully " + status, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      dispatch(getInvitesList());
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <>
      {inviteList && inviteList.length > 0 ? (
        <Modal width={500}>
          <div
            className="addressbook-form-box"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "visible"
            }}
          >
            <div
              className="congrat-box__header"
              style={{ background: "#ff567a" }}
            >
              <span>INVITATION</span>
              <div onClick={hideModal} className="close-btn"></div>
            </div>
            <div>
              <div
                className="invitation-container"
                style={{ marginBottom: 30 }}
              >
                {inviteList.map(item => (
                  <div className="my-invitation-block" key={item.id}>
                    <div className="invitation-text">
                      You have been invited by{" "}
                      <b>{item.employer.primary_user.full_name} </b>{" "}
                      to join <b>{item.employer.company_name}</b>{" "}
                      employer plan.
                    </div>
                    <div className="display-flex">
                      <button
                        className="btn invite-action-btn wizard__btn mb-0"
                        onClick={() =>
                          acceptRejectInvitation(
                            item.invite_number,
                            "Accepted"
                          )
                        }
                      >
                        Accept
                      </button>
                      <button
                        className="btn invite-action-btn wizard__btn mb-0"
                        onClick={() =>
                          acceptRejectInvitation(
                            item.invite_number,
                            "Rejected"
                          )
                        }
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
export default InvitesModal;
