import React, { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState, UserType } from "../../store";
import {
  getInterestsEffect,
  loadUserForEditEffect,
  wizardSetStep,
  wizardSetUserType,
} from "./Actions";
import { useHistory } from "react-router-dom";
import { UserInfoForm } from "./UserInfoForm";
import { SignUpStep } from "./Reducers";
import { getProfileEffect } from "../Auth/Actions";
import "../venue-style.css";
import Helmet from "react-helmet";

const SignUp: FC = (props, params) => {
  // useBodyClass("bgr-blue");
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: AppState) => state.auth.user);
  const emailExistMessage: any = useSelector(
    (state: AppState) => state.wizard.emailExistMessage.success
  );
  const personalSettingsFields = useSelector(
    (state: AppState) => state.wizard.personalSettingsFields
  );

  useEffect(() => {
    dispatch(getInterestsEffect());
    if (window.location.href.indexOf("employer/invite") !== -1) {
      dispatch(wizardSetUserType(UserType.Customer));
      dispatch(wizardSetStep(SignUpStep.UserInfo));
      dispatch(getProfileEffect());
    }
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(loadUserForEditEffect());
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Dorpee-workspaces on demand" />
        <title>{`Dorpee - Register ${
          user || window.location.href.indexOf("customer") > -1
            ? "Individual"
            : "Employer"
        }`}</title>
      </Helmet>
      <UserInfoForm
        personalSettingsField={personalSettingsFields}
        user={user === undefined ? true : false}
        history={history}
        emailExistMessage={emailExistMessage}
      />
    </>
  );
};

export default SignUp;
