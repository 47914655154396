/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { UserType, AppState } from "../../store";
import { Dispatch } from "redux";
import { History } from "history";
import {
  AuthApi,
  SignUpRequest,
  isErrorsResponse,
  getUserType,
  UpdateUserRequest,
  OrganisationInfoReq
} from "../../API/AuthApi";
import {
  signInEffect,
  getProfileEffect,
  LoggedIn
} from "../Auth/Actions";
import { UserInfoFields } from "./UserInfoForm";
import { EnumsApi, Interest, Plan } from "../../API/EnumsApi";
import { SignUpStep } from "./Reducers";
import { PersonalSettingsFields } from "./PersonalSettingsForm";
import { createFile } from "../../common/createFile";
import Cookies from "js-cookie";
import { setLoader } from "../Loader/Actions";
import { toast } from "react-toastify";
import { createPaymentEffect } from "../PaymentWizard/Actions";
import {
  handleInfoModal,
  handleRequirementsModal
} from "../Dashboard/Actions";
export function wizardSetStep(step: SignUpStep) {
  return <const>{
    type: "@@wizard/SET_STEP",
    step
  };
}

export const submitUserForm = (userInfoFields: UserInfoFields) =>
  <const>{
    type: "@@wizard/SUBMIT_USER_FORM",
    userInfoFields
  };
export const profileImagesDelete = (data: any) =>
  <const>{
    type: "@@wizard/IMAGE_PROFILE",
    data
  };
export const toggleSMSModal = (flag: boolean) =>
  <const>{
    type: "@@wizard/SMS_MODAL",
    flag
  };
export const interestsLoaded = (interests: Interest[]) =>
  <const>{
    type: "@@wizard/INTERESTS_LOADED",
    interests
  };

export const plansLoaded = (plans: Plan[]) =>
  <const>{
    type: "@@wizard/PLANS_LOADED",
    plans
  };

export const selectPlan = (plan: Plan) =>
  <const>{
    type: "@@wizard/SET_SELECTED_PLAN",
    plan
  };

export const changePersonalSettingsForm = (
  personalSettingsFields: any
) =>
  <const>{
    type: "@@wizard/CHANGE_PERSONAL_SETTINGS_FORM",
    personalSettingsFields
  };

export function wizardSetDisabled(isDisabled: boolean) {
  return <const>{
    type: "@@wizard/SET_DISABLED",
    isDisabled
  };
}

export function wizardSetUserType(userType: UserType) {
  return <const>{
    type: "@@wizard/SET_USER_TYPE",
    userType
  };
}

export function enteredEmailStatus(payload: any) {
  return <const>{
    type: "ENTERED_EMAIL_STATUS",
    payload
  };
}
export function enteredPromoStatus(payload: any) {
  return <const>{
    type: "ENTERED_PROMO_STATUS",
    payload
  };
}
export function setPromoStatus(payload: any) {
  return <const>{
    type: "SET_PROMO",
    payload
  };
}

export const VenueSignUpEffectNew =
  (request: any, history: History) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(setLoader(true));
    var userInfoFields = {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      phoneNumber: request.mobileNumber,
      password: request.password,
      password_confirmation: request.repeatPassword,
      companyName: request.companyName,
      file: request.file,
      state: request.region,
      region: request.state,
      userType: "venue" as UserType
    };
    const userType = "venue" as UserType;
    const response = await AuthApi.SignUp(
      new SignUpRequest(userInfoFields, userType)
    );
    if (isErrorsResponse(response)) {
      return Object.keys(response.errors).reduce(
        (prev: any, current: string) => {
          return { ...prev, [current]: response.errors[current][0] };
        },
        {}
      );
    } else {
      dispatch(signInEffect(response.token, response.user));
    }
    dispatch(LoggedIn(true));
    dispatch(getProfileEffect());
    dispatch(setLoader(false));
    history.push("/create-venue");
    // window.location.href="/create-venue"
  };
export const completeSignUpEffect =
  (history: History, prevLocationState?: any) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    if (state.auth.user) {
      const response = await AuthApi.UpdateProfile(
        new UpdateUserRequest(
          state.wizard.userInfoFields!,
          state.wizard.logo_deleted,
          state.wizard.photo_deleted,
          state.wizard.vaccination_certificate_deleted
        ),
        state.auth.token!
      );
      if (isErrorsResponse(response)) {
        dispatch(setLoader(false));
        return Object.keys(response.errors).reduce(
          (prev: any, current: string) => {
            toast.error(response.errors[current][0]);
            return {
              ...prev,
              [current]: response.errors[current][0]
            };
          },
          {}
        );
      } else {
        toast.success("Updated successfully");
        dispatch(getProfileEffect());
        Cookies.remove("isEditProfile");
        if (prevLocationState && prevLocationState) {
          history.push({
            pathname:
              prevLocationState && prevLocationState.prevLoc
                ? prevLocationState.prevLoc
                : "/dashboard",
            state: {}
          });
          window.location.reload();
        } else {
          history.push({
            pathname: "/dashboard",
            state: {}
          });
          window.location.reload();
        }
        dispatch(setLoader(false));
      }
    } else {
      const response = await AuthApi.SignUp(
        new SignUpRequest(
          state.wizard.userInfoFields!,
          state.wizard.userType!
        )
      );
      if (isErrorsResponse(response)) {
        return Object.keys(response.errors).reduce(
          (prev: any, current: string) => {
            return {
              ...prev,
              [current]: response.errors[current][0]
            };
          },
          {}
        );
      } else {
        await dispatch(getProfileEffect());
        dispatch(signInEffect(response.token, response.user));
      }
      if (response) {
        if (state.wizard.userInfoFields?.friends_invite_code) {
          dispatch(LoggedIn(true));
          history.push("/dashboard");
        } else {
          dispatch(LoggedIn(true));
          history.push(
            response.user.role[0].title === "Employer"
              ? "/dashboard"
              : "/select-plan"
          );
        }
      }
    }
  };

export const addPromoCode =
  (
    promoCode: string,
    user: any,
    selectedPlan: any,
    isSubscriptionCancelled: any,
    history: any
  ) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    if (state.auth.user) {
      const response = await AuthApi.AddPromoCode(
        { promo_code: promoCode },
        state.auth.token!
      );
      if (isErrorsResponse(response)) {
        dispatch(setLoader(false));
        return Object.keys(response.errors).reduce(
          (prev: any, current: string) => {
            toast.error(response.errors[current][0]);
            return {
              ...prev,
              [current]: response.errors[current][0]
            };
          },
          {}
        );
      } else {
        toast.success("Promotional code applied successfully");
        dispatch(setLoader(false));
        if (
          (user && user.plan_id && isSubscriptionCancelled) ||
          user!.card_status
        ) {
          await dispatch(
            createPaymentEffect(
              { plan_id: selectedPlan.id },
              history,
              true
            )
          );
        }
        if (!user) {
          history.push("/register/select-type");
        } else {
          history.push("payment");
          await dispatch(setSelectedPlanEffect(selectedPlan));
        }
      }
    }
  };

export const updateEmployeeCost =
  (cost: number) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await AuthApi.UpdateEmployeeCost(
      cost,
      state.auth.token!
    );
    if (response) {
      dispatch(getProfileEffect());
      dispatch(setLoader(false));
    }
  };

export const updateEmployeeRequirements =
  (fields: any) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await AuthApi.UpdateEmployeeVaccinationOhs(
      fields,
      state.auth.token!
    );
    if (isErrorsResponse(response)) {
      dispatch(setLoader(false));
      return Object.keys(response.errors).reduce(
        (prev: any, current: string) => {
          toast.error(response.errors[current][0]);
          return { ...prev, [current]: response.errors[current][0] };
        },
        {}
      );
    } else {
      toast.success("Requirements Updated success");
      dispatch(getProfileEffect());
      dispatch(setLoader(false));
      dispatch(handleRequirementsModal(false));
    }
  };

export const updateEmployerCompanyInfo =
  (fields: any) =>
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    dispatch(setLoader(true));
    const response = await AuthApi.UpdateEmployerCompanyInfo(
      new OrganisationInfoReq(fields, state.wizard.logo_deleted!),
      state.auth.token!
    );
    if (isErrorsResponse(response)) {
      dispatch(setLoader(false));
      return Object.keys(response.errors).reduce(
        (prev: any, current: string) => {
          toast.error(response.errors[current][0]);
          return { ...prev, [current]: response.errors[current][0] };
        },
        {}
      );
    } else {
      toast.success("Updated Success");
      dispatch(getProfileEffect());
      dispatch(handleInfoModal(false));
      dispatch(setLoader(false));
    }
  };

export const getInterestsEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetInterests();
    dispatch(interestsLoaded(response.data));
  };

export const getExistingEmailStatus =
  (email: String) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const response: any = await AuthApi.GetExistingEmailStatus(email);
    if (
      response.success === "This email already exists" ||
      response.success === "Email already exists!"
    ) {
      dispatch(
        enteredEmailStatus({ success: "This email already exists" })
      );
    } else {
      dispatch(enteredEmailStatus(""));
    }
  };

export const getPromoCodeStatus =
  (code: String, date: any) => async (dispatch: Dispatch) => {
    const response = await AuthApi.ValidatePromo({
      code: code,
      date: date
    });
    if (response.data.valid) {
      dispatch(enteredPromoStatus("Promo Code Valid"));
    } else {
      dispatch(enteredPromoStatus("Invalid or Expired Promo Code"));
    }
  };

export const loadUserForEditEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(wizardSetStep(SignUpStep.UserInfo));
    const response = await AuthApi.GetProfile(getState().auth.token!);
    const user = response.data;
    let file: File | undefined;
    if (user.avatar) {
      file = await createFile(
        user.avatar,
        user.photo!.file_name,
        user.photo!.mime_type
      );
    }
    let vaccination_certificate: File | undefined;

    const userInfoFields: UserInfoFields = {
      company_name: user.company_name,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone_number,
      file: file
      // vaccination_certificate: user.vaccination_certificate,
    };

    const personalSettingsFields: PersonalSettingsFields = {
      interests: user.interests.map(i => i.id.toString())
    };
    dispatch(submitUserForm(userInfoFields));
    dispatch(changePersonalSettingsForm(personalSettingsFields));

    dispatch(wizardSetUserType(getUserType(user.role)));
  };

export const getPlansEffect =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const response = await EnumsApi.GetPlans();
    dispatch(plansLoaded(response.data));
  };

export const setSelectedPlanEffect =
  (plan: Plan) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    await dispatch(selectPlan(plan));
  };

export type WizardAction = ReturnType<
  | typeof wizardSetDisabled
  | typeof wizardSetStep
  | typeof submitUserForm
  | typeof interestsLoaded
  | typeof changePersonalSettingsForm
  | typeof wizardSetUserType
  | typeof plansLoaded
  | typeof selectPlan
  | typeof enteredEmailStatus
  | typeof enteredPromoStatus
  | typeof setPromoStatus
  | typeof toggleSMSModal
  | typeof profileImagesDelete
>;
