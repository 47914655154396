import React, { Component } from "react";
import { Plan } from "../../API/EnumsApi";
import { User } from "../../API/AuthApi";
import ModalComponent from "../Dashboard/modals/AddCreditModal";
import { AppState } from "../../store";
import { connect, MapDispatchToProps } from "react-redux";
import { getCreditPrice } from "../Dashboard/Actions";
import { toast } from "react-toastify";
interface Props {
  title: string;
  description: string;
  buttonText: string;
  isEnquireBtn?: boolean;
  history: any;
}
interface myStateProps {
  isOpen: boolean;
}
type Myprops = Props & ReturnType<any>;
class PlansItemSub extends Component<Myprops, myStateProps> {
  constructor(props: Myprops) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  toggleModal = () => {
    this.setState({ isOpen: false });
  };
  componentDidMount() {
    this.props.getCreditPrice();
  }
  render() {
    return (
      <div className="col-6 col-md-4 col-lg">
        <div
          className={"plan-item"}
          style={{ minHeight: 250, maxWidth: 600 }}
        >
          <div className="plan-item__header">{this.props.title}</div>
          <div
            className="plan-item__content"
            style={{ justifyContent: "center", display: "flex" }}
          >
            <div className="plan-item__content-block">
              <span className="plan-item__content-price"></span>
              <span className="plan-item__content-price-subtitle"></span>
            </div>
            <div
              className="plan-item__content-block"
              style={{ width: 300 }}
            >
              <span
                className="plan-item__content-credit-description"
                style={{ fontWeight: 600 }}
              >
                {this.props.description}
              </span>
            </div>
            {this.props.isEnquireBtn ? (
              <a
                href="https://register.dorpee.com/individuals-0?hs_preview=KqTsCXOd-61763341938&hsLang=en#Tailor"
                style={{ color: "#ffff" }}
                className="free_space_text"
              >
                <button
                  className="btn wizard__btn mb-0 btn-blue2"
                  style={{ textTransform: "uppercase" }}
                >
                  {this.props.buttonText}
                </button>
              </a>
            ) : (
              <button
                onClick={() => {
                  if (this.props.buttonText == "BUY CREDIT") {
                    if (this.props.user)
                      this.setState({ isOpen: !this.state.isOpen });
                    else {
                      toast.error(
                        "Please update payment setting to purchase"
                      );
                      this.props.history.push("/payment");
                    }
                  } else {
                    window.location.href =
                      "https://register.dorpee.com/individuals-0?hs_preview=KqTsCXOd-61763341938&hsLang=en#Tailor";
                  }
                  // // if (this.props.user) {
                  //   this.setState({ isOpen: true });
                  //   window.localStorage.setItem("isPlanName", "true");
                  // } else {
                  //   this.props.history.push("/register/select-type");
                  // }
                }}
                disabled={this.props.isLoadingCredit}
                className={`btn wizard__btn mb-0${
                  this.props.isLoadingCredit ? " disabled" : ""
                }${
                  this.props.isLoadingCredit
                    ? " btn--loadingNew"
                    : " btn-blue"
                }`}
              >
                <div> {this.props.buttonText}</div>
              </button>
            )}
          </div>
          <div className="plan-item__active">ACTIVE</div>
        </div>
        {this.state.isOpen ? (
          <ModalComponent
            openModal={this.state.isOpen}
            handleToggleModal={this.toggleModal}
            history={this.props.history}
            goToDashboard
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  selectedPlan: state.wizard.selectedPlan,
  isLoading: state.Loader.isLoading,
  isLoadingCredit: state.Loader.isLoadingCredit,
  plan_id: state.auth.user,
  isPlanSelected: state.wizard.isPlanSelected,
  user: state.auth.user
});

const mapDispatchToProps = {
  getCreditPrice
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansItemSub);
