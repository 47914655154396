import React from "react";
import { Pie } from "react-chartjs-2";

class VenueBookingsPie_Emp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [
        "<20 Credits/Hr",
        "20-40 Credits/Hr",
        "41-100 Credits/Hr",
        ">100 Credits/Hr",
      ],
      datasets: [
        {
          data: this.getData(),
          backgroundColor: ["#FF6384", "#FFCE56", "#DBDBDB", "#4956A2"],
          hoverBackgroundColor: ["#FF6384", "#FFCE56", "#DBDBDB", "#4956A2"],
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  componentDidUpdate(nextProps) {
    if (
      this.props.pie.space_types["20-40 credits per hour"] !==
        nextProps.pie.space_types["20-40 credits per hour"] ||
      nextProps.pie.space_types["41-100 credits per hour"] !==
        this.props.pie.space_types["41-100 credits per hour"] ||
      nextProps.pie.space_types["<20 credits per hour"] !==
        this.props.pie.space_types["<20 credits per hour"] ||
      nextProps.pie.space_types[">100 credits per hour"] !==
        this.props.pie.space_types[">100 credits per hour"]
    ) {
      const datasets = [
        {
          data: this.getData(),
          backgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#FFCE56",
            "#DBDBDB",
            "#4956A2",
            "#5AAF10",
          ],
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ];

      this.setState({
        datasets,
      });
    }
  }

  getData() {
    let values = [];
    let data = this.props.pie.space_types;
    [
      "20-40 credits per hour",
      "41-100 credits per hour",
      "<20 credits per hour",
      ">100 credits per hour",
    ].map((label) => {
      for (let i in data) {
        if (i == label) {
          values.push(data[i]);
          return;
        }
      }
    });

    return values;
  }

  render() {
    let options = {
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            return (
              data["labels"][tooltipItem["index"]] +
              ": " +
              data["datasets"][0]["data"][tooltipItem["index"]] +
              "%"
            );
          },
        },
      },
    };

    return (
      <div className="venue-chart-small">
        <Pie
          height={250}
          data={this.state}
          options={options}
          legend={{
            labels: {
              boxWidth: 15,
            },
            position: "bottom",
          }}
        />
      </div>
    );
  }
}

export default VenueBookingsPie_Emp;
