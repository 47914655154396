/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../Screens/useOnClickOutside";

const MapMarkerComponentNew = marker => {
  let filterRef = useRef(null);
  const openModal2 = e => {
    setisOpen(!isOpen);
  };
  const [isOpen, setisOpen] = useState(false);
  useOnClickOutside(filterRef, () => {
    setisOpen(false);
  });
  const history = useHistory();
  const user = useSelector(state => state.auth.user);
  const roleUser = useSelector(state => state.auth.userRoles);
  const isVenueFree = marker.marker.spaces.some(
    s => s.free_space === "No"
  );

  const goWorkspace = (venueId, slug) => {
    if (roleUser !== "Venue") {
      if (!isVenueFree) {
        if (!user) {
          history.push("/pricing");
        } else {
          if (user.plan == null) {
            history.push("/select-plan");
          } else if (user.plan.free_space === "No") {
            history.push("/select-plan");
          } else {
            history.push({
              pathname: `/workspace/${slug}`,
              state: venueId
            });
          }
        }
      } else {
        history.push({
          pathname: `/workspace/${slug}`,
          state: venueId
        });
      }
    } else {
      history.push({
        pathname: `/workspace/${slug}`,
        state: venueId
      });
    }
  };

  useEffect(() => {}, [marker]);
  if (marker.marker.spaces.length > 0) {
    if (!marker.cord) {
      let price = !isNaN(
        Math.ceil(parseInt(marker.marker.lowest_price) / 0.3)
      )
        ? Math.ceil(parseInt(marker.marker.lowest_price) / 0.3)
        : marker.marker && Math.ceil(marker.marker.lowest_price)
        ? Math.ceil(marker.marker.lowest_price)
        : 0;
      return (
        <>
          <div
            className="employer__right"
            onClick={e => openModal2(e)}
          >
            <div className="employer__stats2">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    price <= 50
                      ? "#42c0d6"
                      : price <= 171
                      ? "#4956a2"
                      : price <= 300
                      ? "#ffbe00"
                      : "#f5597f",
                  color: "#fff",
                  width: "39px",
                  height: "39px",
                  borderRadius: "50%",
                  textAlign: "center",
                  lineHeight: "39px",
                  marginRight: "5px",
                  fontSize: "13px",
                  zIndex: marker.marker.id == marker.hover ? 1 : ""
                }}
              >
                {/* {!marker.isPrice ? ( */}
                <div className="stats__item-subtitle">
                  {marker.marker &&
                  !isNaN(
                    Math.ceil(
                      parseInt(marker.marker.lowest_price) / 0.3
                    )
                  )
                    ? Math.ceil(
                        parseInt(marker.marker.lowest_price) / 0.3
                      )
                    : "0"}
                </div>
                {/* ) : (
                  <div className="stats__item-subtitle">
                    $ {marker.marker && Math.ceil(marker.marker.lowest_price)}
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {isOpen ? (
            <a
              onClick={() => {
                goWorkspace(marker.marker.id, marker.marker.slug);
              }}
            >
              <div ref={filterRef} className="markerDiv">
                <img
                  className="markerImg"
                  src={
                    marker.marker.images && marker.marker.images[0]
                  }
                  alt=""
                />
                <div
                  style={{
                    fontSize: "14px"
                  }}
                >
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      margin: 5
                    }}
                  >
                    <div className="marker-location">
                      {marker.marker.suburb.toUpperCase()}
                    </div>
                    <div className="marker-subtitle">
                      {marker.marker.name.toUpperCase()}{" "}
                    </div>
                    <span style={{ paddingLeft: 5 }}>
                      <div className="venue-rating__left">
                        <span className={"from"}>From</span>{" "}
                        <span
                          className={"creditCount"}
                          style={{
                            background:
                              price <= 50
                                ? "#42c0d6"
                                : price <= 171
                                ? "#4956a2"
                                : price <= 300
                                ? "#ffbe00"
                                : "#f5597f"
                          }}
                        >
                          {marker.marker &&
                          !isNaN(
                            Math.ceil(
                              parseInt(marker.marker.lowest_price) /
                                0.3
                            )
                          )
                            ? Math.ceil(
                                parseInt(marker.marker.lowest_price) /
                                  0.3
                              )
                            : "0"}
                        </span>
                        / <span className={"hour"}>hr</span>
                      </div>
                      <button
                        onClick={() => {
                          goWorkspace(
                            marker.marker.id,
                            marker.marker.slug
                          );
                        }}
                        className="btn btn-blue"
                        style={{ marginTop: "1rem" }}
                      >
                        BOOK NOW
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </a>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <div style={{ fontWeight: "bold", color: "red" }}>
            {marker.key}
            <img alt="" src="/img/marker.png"></img>
          </div>
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default MapMarkerComponentNew;
