export const getDay = (date: any) => {
  let a = new Date(date);
  let weekdays = new Array(7);
  weekdays[0] = "Sunday";
  weekdays[1] = "Monday";
  weekdays[2] = "Tuesday";
  weekdays[3] = "Wednesday";
  weekdays[4] = "Thursday";
  weekdays[5] = "Friday";
  weekdays[6] = "Saturday";
  return weekdays[a.getDay()];
};
export const getDateformated = (value: string) => {
  let date = value.split("/");
  let formatedDate = new Date(
    Number(date[2]),
    Number(date[0]) - 1,
    Number(date[1]),
    0,
    0
  );
  return formatedDate;
};

export const getDateformated2 = (value: string) => {
  let date = value.split("/");

  let formatedDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
  return formatedDate;
};

export const getDateformatedChange = (
  value: string,
  status?: boolean
) => {
  let date = value.split("/");
  let formatedDate;
  if (status) {
    formatedDate = new Date(
      parseInt(date[2]),
      parseInt(date[0]) - 1,
      parseInt(date[1], 0)
    );
  } else
    formatedDate = new Date(
      parseInt(date[2]),
      parseInt(date[0]) - 1,
      parseInt(date[1])
    );
  return formatedDate;
};
export const convertToDateTimeFormat = (
  value: string,
  value2: string,
  cancel_period: any
) => {
  let date = value.split("/");
  let time = value2.split(":");
  var hour = (Number(time[0]) - cancel_period).toString();
  let formatedDate = new Date(
    `${date[2]}-${date[0]}-${date[1]} ${hour}:${time[1]}:00`
  );
  return formatedDate;
};

export const getDate = (date: any) => {
  let myDate = new Date(date);

  return myDate.getDate();
};
export const formatedTimeMin = (status?: boolean) => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let hours = date.getHours();
  let day = date.getDate();

  var formatedTime = new Date(
    Number(year),
    Number(month),
    Number(day),
    Number(status ? hours + 1 : hours),
    Number(0)
  );
  return formatedTime;
};
export const getMonth = (date: any) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let myDate = new Date(date);
  return monthNames[myDate.getMonth()];
};

export const getYear = (date: any) => {
  let myDate = new Date(date);
  return myDate.getFullYear();
};

export const getFormatedTime = (time: string) => {
  if (time) {
    return time.slice(0, time.lastIndexOf(":"));
  }
};

export const getFormated12hTime = (time: string) => {
  if (time) {
    var formatedTime = time.slice(0, time.lastIndexOf(":"));
    var time12Hours = formatedTime.split(":");
    var hours = parseInt(time12Hours[0]);
    var AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var minutes = time12Hours[1];
    var finalTime = hours + ":" + minutes + ":00 " + AmOrPm;
    return finalTime;
  }
};

export const getFormated12hTimeNew = (time: string) => {
  if (time) {
    var formatedTime = time.slice(0, time.lastIndexOf(":"));
    var time12Hours = formatedTime.split(":");
    var hours = parseInt(time12Hours[0]);
    var AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var minutes = time12Hours[1];
    var finalTime = hours + ":" + minutes + AmOrPm;
    return finalTime;
  }
};
export const getFormatedDate = (date: string) => {
  if (date) {
    let formatedDate = date.split("/");
    let newDate = `${formatedDate[1]}/${formatedDate[0]}/${formatedDate[2]}`;
    return newDate;
  }
};
export const compareDates = (date1: any, date2: any) => {
  let today = new Date(date2);
  let myDate = new Date(date1);
  return myDate.getTime() > today.getTime();
};

export const formatDate = (date: any) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  let newdate = mm + "/" + dd + "/" + yyyy;
  return newdate;
};
export const formatDatemonth = (date: any) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;

  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  let newdate = dd + "/" + mm + "/" + yyyy;
  return newdate;
};

export const formatDateToMonth = (date: string) => {
  var formattedDate = date.split("/");

  let newdate =
    formattedDate[1] +
    "/" +
    formattedDate[0] +
    "/" +
    formattedDate[2];
  return newdate;
};
export const formatedTime = (time: any, status?: boolean) => {
  let hours = time.getHours();
  let mins = time.getMinutes();
  let sec = time.getSeconds();
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (mins < 10) {
    mins = "0" + mins;
  }

  let formatedTime;
  if (status) formatedTime = hours + ":" + mins;
  else formatedTime = hours + ":" + mins + ":00";
  return formatedTime;
};
export const formatedStringTime = (time: any) => {
  let formatedTime = time.split(":");
  let hours = Number(formatedTime[0]);
  let mins = Number(formatedTime[1]);
  let formatedHr;
  let formatedMin;
  if (hours < 10) {
    formatedHr = "0" + hours;
  }
  if (mins < 10) {
    formatedMin = "0" + mins;
  }

  let formatedtime =
    (formatedHr ? formatedHr : hours) +
    ":" +
    (formatedMin ? formatedMin : mins) +
    ":00";
  return formatedtime;
};

export const formatedTimeMin2 = (
  value: string,
  endDate?: string,
  status?: boolean
) => {
  if (endDate && status) {
    var formatted = endDate.split("/");

    let time = value.split(":");
    let formatedDate = new Date(
      parseInt(formatted[2]),
      parseInt(formatted[0]) - 1,
      parseInt(formatted[1]),
      Number(time[0]),
      Number(time[1])
    );
    formatedDate.setHours(formatedDate.getHours() + 1);
    return formatedDate;
  }
  if (endDate) {
    var formatted = endDate.split("/");

    let time = value.split(":");
    let formatedDate = new Date(
      parseInt(formatted[2]),
      parseInt(formatted[0]) - 1,
      parseInt(formatted[1]),
      Number(time[0]),
      Number(time[1])
    );
    return formatedDate;
  }
  let time = value.split(":");
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  var formatedDate = new Date(
    Number(year),
    Number(month),
    Number(day),
    Number(time[0]),
    Number(time[1])
  );

  return formatedDate;
};

export const formatedDateNew = (value: string) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const dateObj = new Date(value);
  let month: any = dateObj.getMonth() + 1;
  const day = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();
  if (month < 10) {
    month = "0" + month;
  }
  const output = month + "/" + day + "/" + year;
  return output;
};

export const dayMonth = (value: string) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const d = new Date(value);
  const day = String(d.getDate()).padStart(2, "0");
  const output = day + " " + monthNames[d.getMonth()];
  return output;
};
