/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, FormErrors, getFormValues } from "redux-form";
import { Helmet } from "react-helmet";
import _ from "lodash";

import {
  getEnvironmentsEffect,
  getVenueTypesEffect,
  workspaceSubmitDetailsForm,
  workspaceWizardSetStep,
  getAssetsEffect,
  // getSuggestedPriceEffect,
  setSpaceFlow
} from "./Actions";
import Footer from "../../footer";
import { AppState } from "../../store";
import { WorkspaceWizardStep } from "./Reducers";
import WorkspaceForm from "./components/WorkspaceForm";
import WorkspaceTypes from "./components/WorkspaceTypes";
import WorkspaceFooter from "./components/WorkspaceFooter";
import WorkspaceSideBar from "./components/WorkspaceSideBar";
import LoaderModal from "../../Screens/Dashboard/modals/Loader";
import WorkspaceAmenities from "./components/WorkspaceAmenities";
import { getVenuesEffect } from "../../Screens/VenueWizard/Actions";
import WorkspaceEnvironment from "./components/WorkspaceEnvironment";
import WorkspacePhotosUploader from "./components/WorkspacePhotosUploader";

import "../../Screens/venue-style.css";
import "../../sass/pages/_index.sass";

export type WorkspaceDetailsFields = {
  name: string;
  venueId: string;
  price: any;
  capacity: number;
  quantity?: number;
  types: string[];
  environments: string[];
  assets?: string[];
  free_space: any;
  _method?: string;
  image1?: File;
  image2?: File;
  image3?: File;
  image4?: File;
  description: string;
  min_booking_hours?: Number;
  max_booking_hours?: Number;
  cancellation_period?: any;
  isSpaceFlow?: any;
};

export let CreateWorkspace = reduxForm<
  WorkspaceDetailsFields,
  { workspaceId?: string; venueId?: any; history?: any }
>({
  form: "workspaceDetails",
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    let details = {
      name: values.name,
      venueId: values.venueId ? values.venueId : props.venueId,
      price: values.free_space ? 0 : values.price,
      capacity: values.capacity,
      quantity: values.quantity,
      types: values.types,
      environments: values.environments,
      assets: values.assets,
      free_space: values.free_space ? "Yes" : "No",
      image1: values.image1,
      image2: values.image2,
      image3: values.image3,
      image4: values.image4,
      min_booking_hours: Number(values.min_booking_hours),
      max_booking_hours: Number(values.max_booking_hours),
      cancellation_period: values.cancellation_period,
      description: values.description,
      _method: props.workspaceId ? "PUT" : "",
      isSpaceFlow: props.history.location.state ? true : false
    };
    dispatch(workspaceSubmitDetailsForm(details));
    dispatch(workspaceWizardSetStep(WorkspaceWizardStep.Preview));
  },
  validate: (values, props) => {
    const errors: FormErrors<WorkspaceDetailsFields> = {};
    if (!values.description) {
      errors.description = "Required";
    }

    if (
      !values.image1 &&
      !values.image2 &&
      !values.image3 &&
      !values.image4
    ) {
      errors._error = "Please upload at least one Workspace image";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!props.workspaceId && !props.venueId) {
      if (!values.venueId) {
        errors.venueId = "Required";
      }
    }

    if (
      !values.price ||
      values.price.toString() === "e" ||
      values.price > 1000000000000000
    ) {
      errors.price = "Required";
    }

    if (
      !values.capacity ||
      values.capacity > 100000 ||
      values.capacity.toString() === "e"
    ) {
      errors.capacity = "Required";
    }

    if (!values.types || values.types.length === 0) {
      errors.types = "Workspace type is required";
    }
    if (!values.environments || values.environments.length === 0) {
      errors.environments = "Environment type is required";
    }
    if (values.min_booking_hours) {
      if (values.max_booking_hours) {
        if (
          Number(values.min_booking_hours) >
          Number(values.max_booking_hours)
        )
          errors.min_booking_hours =
            "Must Be Less than Max Booking Hours";
      }
    }
    if (values.max_booking_hours) {
      if (values.min_booking_hours) {
        if (
          Number(values.max_booking_hours) <
          Number(values.min_booking_hours)
        )
          errors.max_booking_hours =
            "Must Be Greater than Min Booking Hours";
      }
    }
    if (!values.quantity) {
      errors.quantity = "Required";
    }

    return errors;
  }
})(props => {
  const dispatch = useDispatch();
  const [assetsList, setList] = useState<any>([]);
  const assets = useSelector(
    (state: AppState) => state.workspaceWizard.assets
  );

  const environments = useSelector(
    (state: AppState) => state.workspaceWizard.environments
  );
  const venueTypes = useSelector(
    (state: AppState) => state.workspaceWizard.venueTypes
  );
  const venues = useSelector(
    (state: AppState) => state.venueWizard.venues
  );
  const s = useSelector((state: AppState) =>
    getFormValues("workspaceDetails")(state)
  );
  const workspaceFields = useSelector(
    (state: AppState) => state.workspaceWizard.detailsFields
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );
  // const suggestedPrice = useSelector(
  //   (state: AppState) => state.workspaceWizard.suggested_price
  // );

  const [venueId, setVenueId] = useState(1);
  const [venueName, setVenueName] = useState("");
  // const [isSuggested, setSuggested] = useState(false);

  useEffect(() => {
    dispatch(getEnvironmentsEffect());
    dispatch(getVenueTypesEffect());
    dispatch(getVenuesEffect());
    dispatch(getAssetsEffect());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.venueId) {
      handleVenueId(props.venueId);
    }
  }, [venues]);

  useEffect(() => {
    let grouped = _.mapValues(_.groupBy(assets, "category"), asset =>
      asset.map(ast => _.omit(ast, "category"))
    );
    setList(grouped);
  }, [assets]);

  useEffect(() => {
    if (workspaceFields) {
      workspaceFields &&
        props.initialize({
          free_space:
            workspaceFields.free_space === "Yes" ? true : false,
          name: workspaceFields.name,
          capacity: workspaceFields.capacity,
          venueId: workspaceFields.venueId,
          environments: workspaceFields.environments,
          assets: workspaceFields.assets,
          types: workspaceFields.types,
          price: workspaceFields.price,
          quantity: workspaceFields.quantity,
          description: workspaceFields.description,
          image1: workspaceFields.image1 && workspaceFields.image1,
          image2: workspaceFields.image2 && workspaceFields.image2,
          image3: workspaceFields.image3 && workspaceFields.image3,
          image4: workspaceFields.image4 && workspaceFields.image4,
          min_booking_hours: workspaceFields.min_booking_hours,
          max_booking_hours: workspaceFields.max_booking_hours,
          cancellation_period: workspaceFields.cancellation_period
        });
    }
  }, [workspaceFields]);

  const handleVenueId = (e: any) => {
    const id = e;
    setVenueId(id);
    // if (id !== "") setSuggested(true);
    // else setSuggested(false);
    // if (id) dispatch(getSuggestedPriceEffect(id));
    const venueFindName = venues.find(v => v.id === Number(id));
    if (venueFindName) {
      setVenueName(venueFindName.name);
    } else setVenueName("");
  };

  const isVenueFlow = useSelector(
    (state: AppState) => state.venueWizard.detailsFieldsVenue
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Create Workspace</title>
      </Helmet>
      <form onSubmit={props.handleSubmit}>
        <div className="body">
          <div className="container containerAddSpace">
            <div className="page-wrapper">
              <div className="bl-form-register">
                <h1 className="register-title">
                  Add workspace details
                </h1>
                <div className="form-register">
                  <div className="md-workspace-form">
                    <div className="row">
                      <WorkspaceForm
                        isVenueFlow={isVenueFlow}
                        venues={venues}
                        handleVenueId={handleVenueId}
                        {...props}
                      />
                      <WorkspaceSideBar
                        isVenueFlow={isVenueFlow}
                        venueName={venueName}
                        // suggestedPrice={suggestedPrice}
                        venueId={venueId}
                        // isSuggested={isSuggested}
                      />
                    </div>
                  </div>

                  <WorkspacePhotosUploader {...props} />
                  <div style={{ marginBottom: 10, fontSize: 18 }}>
                    <p>
                      How would you describe this Workspace Type?{" "}
                    </p>
                  </div>
                  <WorkspaceTypes venueTypes={venueTypes} />
                  <WorkspaceEnvironment environments={environments} />
                  <WorkspaceAmenities
                    isService={false}
                    assets={assetsList}
                  />
                </div>
              </div>
              <WorkspaceFooter
                onClick={() => {
                  dispatch(
                    workspaceWizardSetStep(
                      WorkspaceWizardStep.Preview
                    )
                  );
                }}
              />
            </div>
          </div>
          {isLoading ? <LoaderModal /> : null}
          <Footer
          // isSubs
          />
        </div>
      </form>
    </>
  );
});
export default CreateWorkspace;
