import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect, useHistory } from "react-redux";
import { createCreditEffect } from "../../PaymentWizard/Actions";
import { getProfileEffect } from "../../Auth/Actions";
class ModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: this.props.openModal,
      credit: 0,
      creditError: false,
      price: 0,
      number: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: nextProps.openModal });
  }

  componentDidMount() {
    this.props.getProfileEffect();
  }
  handleCredit = (event) => {
    this.setState({ creditError: false });
    let value = event.target.value;
    if (value.length > 10) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.setState({ number: value });
      if (value >= 100) {
        this.setState({
          price: value * this.props.defaultCreditPrice.credit_price,
        });
      } else {
        this.setState({ price: 0 });
      }
      this.setState({ credit: value });
    }
  };
  hasErrors = () => {
    return (
      this.state.credit == 0 ||
      this.state.credit < 0 ||
      this.state.credit < 100 ||
      this.state.price < 0
    );
  };
  handleSubmit = () => {
    if (!this.hasErrors()) {
      if (this.props.user.card_status) {
        this.setState({ creditError: false });
        if (this.props.isBooking) {
          this.props.createCreditEffect({ credits: this.state.credit });
        } else {
          this.props.createCreditEffect(
            { credits: this.state.credit },
            this.props.history
          );
        }
        this.props.handleToggleModal(false, this.state.credit);
      } else {
        window.localStorage.setItem("card_status", true);
        window.localStorage.setItem("credit", this.state.price);
        window.localStorage.setItem("credit_card", this.state.credit);
        this.props.history.push("/payment");
      }
    } else {
      this.setState({ creditError: true });
    }
  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>Add Credits</span>
              <div
                onClick={() => this.props.handleToggleModal(false)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container">
                <input
                  className={"noButtons"}
                  onKeyDown={(evt) => {
                    if (evt.key === "e") {
                      evt.preventDefault();
                    }
                    if (evt.key === "E") {
                      evt.preventDefault();
                    }
                    if (evt.key === "-") {
                      evt.preventDefault();
                    }
                    if (evt.key === "+") {
                      evt.preventDefault();
                    }
                  }}
                  onChange={(ev) => this.handleCredit(ev)}
                  type="number"
                  id="Credits"
                  min={100}
                  value={this.state.number}
                  placeholder="Min 100 Credits"
                  style={{ textAlign: "center" }}
                />
              </div>
              <div className="credit-price__row">
                {this.state.price > 0 ? (
                  <>
                    {" "}
                    <div className="credit-price">
                      ${Number(this.state.price).toFixed(2)}{" "}
                    </div>
                    <span className="default_price">Inc GST</span>
                  </>
                ) : null}
              </div>
              {this.state.creditError ? (
                <p className="addressbook-form-error">Min 100 Credits</p>
              ) : null}
              <button
                style={{ marginTop: -20 }}
                onClick={this.handleSubmit}
                className="btn btn-blue wizard__btn mb-0"
              >
                ADD CREDITS
                <img
                  className="wizard__btn--img-left"
                  src="/img/icons/coins-dark-blue.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
  user: state.auth.user,
  defaultCreditPrice: state.dashboard.defaultCreditPrice,
});

const mapDispatchToProps = {
  createCreditEffect,
  getProfileEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
