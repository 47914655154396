import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import { AuthApi } from "../../../API/AuthApi";
import { toast } from "react-toastify";
import { submitUserForm } from "../../SignUp/Actions";

class SMSVerfication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      verificationCode: null,
      codeSent: false,
      errorCode: false,
      errorNum: false,
      countryCode: "+61"
    };
  }

  hasErrors = () => {};
  componentDidMount() {
    if (this.props.phoneNumber) {
      this.setState({ phoneNumber: this.props.phoneNumber });
    } else this.setState({ phoneNumber: this.props.phone_number });
  }
  handleCode = event => {
    this.setState({
      verificationCode: event.target.value,
      errorCode: false
    });
    if (event.target.value == "") {
      this.setState({ errorCode: true });
    }
  };

  handlePhoneNumber = event => {
    // var check = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(
    //   event.target.value

    // );
    var check = /^\d{10}$/g.test(event.target.value);
    this.setState({
      phoneNumber: event.target.value,
      errorNum: !check ? true : false
    });
    if (event.target.value == "") {
      this.setState({ errorNum: true });
    }
  };
  handleCountryCode = event => {
    this.setState({ countryCode: event.target.value });
  };
  handleSubmit = async e => {
    if (
      this.state.verificationCode != null &&
      this.state.phoneNumber != "" &&
      !this.state.errorNum
    ) {
      var formattedNumber =
        this.state.phoneNumber[0] == "0"
          ? this.state.phoneNumber.slice(1)
          : this.state.phoneNumber;
      formattedNumber = this.state.countryCode + formattedNumber;

      const request = {
        phone_number: formattedNumber,
        verification_code: this.state.verificationCode
      };
      let response = await AuthApi.SMSVerification(request);
      if (response.message == "Phone number verified") {
        this.props.handleSignUpForm();
        this.props.handleToggleModal(false);
      } else if (response.error) {
        toast.error(response.error);
      }
    } else {
      if (this.state.verificationCode == null) {
        this.setState({ ...this.state, errorCode: true });
      }
      if ((this.state.phoneNumber = ""))
        this.setState({ ...this.state, errorNum: true });
    }
  };
  handleVerfication = async () => {
    if (this.state.phoneNumber != "" && !this.state.errorNum) {
      var formattedNumber =
        this.state.phoneNumber[0] == "0"
          ? this.state.phoneNumber.slice(1)
          : this.state.phoneNumber;
      formattedNumber = this.state.countryCode + formattedNumber;
      if (this.props.request) {
        const data = { ...this.props.request };
        data["phoneNumber"] = this.state.phoneNumber;
        this.props.submitUserForm(data);
      }
      if (this.props.phoneNumber) {
        this.props.updatePhoneNumber(this.state.phoneNumber);
      }
      let response = await AuthApi.GetSMSVerification({
        phone_number: formattedNumber
      });

      if (response.message) {
        toast.success(response.message);
        this.setState({ codeSent: true });
      } else if (response.error) {
        toast.error(response.error);
      }
    } else {
      this.setState({ errorNum: true });
    }
  };
  handleResend = async () => {
    if (this.state.phoneNumber != "") {
      var formattedNumber =
        this.state.phoneNumber[0] == "0"
          ? this.state.phoneNumber.slice(1)
          : this.state.phoneNumber;
      formattedNumber = this.state.countryCode + formattedNumber;
      let response = await AuthApi.ResendSMSVerification({
        phone_number: formattedNumber
      });

      if (response.message) {
        toast.success(response.message);
      } else if (response.error) {
        toast.success(response.error);
      }
    } else {
      this.setState({ errorNum: true });
    }
  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>Verify Your Phone Number </span>
              <div
                onClick={() => this.props.handleToggleModal(false)}
                className="close-btn"
              ></div>
            </div>
            <div className="payment">
              <div className="input-container">
                <select
                  style={{ paddingLeft: 17 }}
                  value={this.state.countryCode}
                  onChange={e => this.handleCountryCode(e)}
                >
                  <option value="+61">Australia</option>
                  <option value="+64">New Zealand</option>
                </select>
              </div>
              <div className="input-container">
                <input
                  autoComplete="off"
                  type="text"
                  value={this.state.phoneNumber}
                  onChange={e => this.handlePhoneNumber(e)}
                  placeholder="Phone Number (0123456789)"
                />
              </div>
              {this.state.errorNum ? (
                <p
                  style={{
                    color: "red",
                    fontSize: 11,
                    marginBottom: 10
                  }}
                >
                  Invalid Phone Number
                </p>
              ) : null}

              {this.state.codeSent && (
                <div className="input-container">
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="Verfication Code"
                    onChange={e => this.handleCode(e)}
                    value={this.state.verificationCode}
                  />
                </div>
              )}
              {this.state.errorCode ? (
                <p
                  style={{
                    color: "red",
                    fontSize: 11,
                    marginBottom: 10
                  }}
                >
                  Required
                </p>
              ) : null}
              {this.state.codeSent && (
                <div
                  style={{
                    marginBottom: 10,
                    fontSize: 11,
                    fontWeight: 400
                  }}
                >
                  <p>
                    {" "}
                    Didn't get the code?{" "}
                    <i
                      onClick={this.handleResend}
                      style={{ color: "#21c2ff", cursor: "pointer" }}
                    >
                      Click here to resend
                    </i>
                  </p>
                </div>
              )}
              {this.state.codeSent ? (
                <button
                  onClick={this.handleSubmit}
                  className={`btn btn-blue wizard__btn mb-0${
                    this.props.isLoading ? " disabled" : ""
                  }${this.props.isLoading ? " btn--loading" : ""}`}
                >
                  Verify
                </button>
              ) : (
                <button
                  onClick={() => this.handleVerfication()}
                  className={`btn btn-blue wizard__btn mb-0${
                    this.props.isLoading ? " disabled" : ""
                  }${this.props.isLoading ? " btn--loading" : ""}`}
                >
                  Send Verfication Code
                </button>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading,
  phone_number: state.wizard.userInfoFields
    ? state.wizard.userInfoFields.phoneNumber
    : "",
  request: state.wizard.userInfoFields
});

const mapDispatchToProps = {
  submitUserForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SMSVerfication);
