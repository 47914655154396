import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import moment from "moment";
import TimePicker from "rc-time-picker";
import {
  formatedTimeMin2,
  formatedTime
} from "../../../helpers/DateHelpers";
import { ExtendBookingEffect } from "../../Book/Booking/Actions";

class ExtendBookingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment(
        formatedTimeMin2(
          this.props.bookinDetails.end_time,
          this.props.bookinDetails.start_date
        )
      ),
      newClosingTime: this.props.bookinDetails
        ? moment(
            formatedTimeMin2(
              this.props.bookinDetails.end_time,
              this.props.bookinDetails.start_date,
              true
            )
          )
        : moment(),
      spacePrice: Math.ceil(
        (this.props.bookinDetails.space.price *
          this.props.bookinDetails.no_of_booked_spaces) /
          0.3
      ),
      timeError: ""
    };
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  async accept() {
    this.props.ExtendBookingEffect(
      {
        booking_id: this.props.bookinDetails.id,
        end_time: formatedTime(
          this.state.newClosingTime._d
            ? this.state.newClosingTime._d
            : this.state.newClosingTime
        )
      },
      this.props.decline
    );
  }

  decline() {
    this.props.decline();
  }
  componentDidMount() {
    let today = new Date();
    today.setHours(this.props.bookinDetails);
  }

  handleTimeChange(event) {
    this.setState({
      timeError: ""
    });
    let time = this.props.bookinDetails.end_time.split(":");
    var formatted = this.props.bookinDetails.start_date.split("/");

    let formatedDate = new Date(
      parseInt(formatted[2]),
      parseInt(formatted[0]) - 1,
      parseInt(formatted[1]),
      Number(time[0]),
      Number(time[1])
    );

    if (event) {
      if (event._d.getTime() <= formatedDate.getTime()) {
        this.setState({
          timeError:
            "New EndTime Must be Greater than current EndTime"
        });
        formatedDate.setHours(formatedDate.getHours() + 1);
        this.setState({ newClosingTime: formatedDate });
      }
      let year = event._d.getFullYear();
      let month = event._d.getMonth();
      let hours = event._d.getHours();
      let day = event._d.getDate();
      let mins = event._d.getMinutes();
      var formatedTimenew = new Date(
        Number(year),
        Number(month),
        Number(day),
        Number(hours + 1),
        Number(mins)
      );
      if (this.props.bookinDetails.space) {
        var totalHour;
        let spacePrice;
        let totalCredit2;
        let min_price;
        let time = this.props.bookinDetails.end_time.split(":");
        var formatted =
          this.props.bookinDetails.start_date.split("/");

        let formatedDate = new Date(
          parseInt(formatted[2]),
          parseInt(formatted[0]) - 1,
          parseInt(formatted[1]),
          Number(time[0]),
          Number(time[1])
        );
        let second =
          (event._d.getTime() - formatedDate.getTime()) / 1000;
        totalHour = second / 3600;
        if (
          this.props.bookinDetails.space.min_booking_hours == null
        ) {
          totalHour = second / 60;
          min_price = Math.ceil(
            (parseFloat(this.props.bookinDetails.space.price) *
              this.props.bookinDetails.no_of_booked_spaces) /
              0.3
          );
          min_price = min_price / 60;
          totalCredit2 = Math.ceil(totalHour * min_price);
        } else if (
          totalHour >=
          this.props.bookinDetails.space.min_booking_hours
        ) {
          spacePrice =
            parseFloat(this.props.bookinDetails.space.price) *
            this.props.bookinDetails.no_of_booked_spaces;
          totalHour = totalHour * (spacePrice ? spacePrice : 1);
          totalCredit2 = Math.ceil(totalHour / 0.3);
        } else if (
          totalHour < this.props.bookinDetails.space.min_booking_hours
        ) {
          spacePrice =
            parseFloat(this.props.bookinDetails.space.price) *
            this.props.bookinDetails.no_of_booked_spaces;
          let minHours = Number(
            this.props.bookinDetails.space.min_booking_hours
          );
          totalHour = minHours * (spacePrice ? spacePrice : 1);
          totalCredit2 = Math.ceil(totalHour / 0.3);
        }
        this.setState({ spacePrice: totalCredit2 });
      }
      this.setState({
        newClosingTime: event._d
      });
    }
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>{this.props.modalHeader}</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div
              className="addressbook-form"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {this.props.message ==
              "Are you sure you want to extend this booking?" ? (
                <div style={{ alignSelf: "center", maxWidth: "70%" }}>
                  <div
                    className="input-container"
                    style={{
                      alignItems: "center",
                      padding: "0.2rem"
                    }}
                  >
                    <TimePicker
                      placeholder="Start Time"
                      value={moment(
                        formatedTimeMin2(
                          this.props.bookinDetails.end_time,
                          this.props.bookinDetails.start_date
                        )
                      )}
                      showSecond={false}
                      minuteStep={15}
                      disabled
                    />
                    <img src="/img/icons/clock.svg" alt="From" />
                  </div>
                  <div
                    className="input-container"
                    style={{
                      alignItems: "center",
                      padding: "0.2rem"
                    }}
                  >
                    <TimePicker
                      placeholder="Start Time"
                      defaultValue={this.state.newClosingTime}
                      onChange={this.handleTimeChange.bind(this)}
                      showSecond={false}
                      minuteStep={15}
                    />
                    <img src="/img/icons/clock.svg" alt="From" />
                  </div>
                </div>
              ) : (
                <div>{this.props.message}</div>
              )}
              <p>{this.state.timeError}</p>
              <div className="data__item">
                <div className="data__icon">
                  <div className="data__icon-credits"></div>
                  <p
                    style={{
                      color: "#76d4f9",
                      fontSize: "18px",
                      paddingLeft: "0.2rem"
                    }}
                  >
                    {this.state.spacePrice}
                  </p>
                </div>
              </div>

              <div style={{ position: "relative", top: 25 }}>
                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={this.accept.bind(this)}
                  disabled={
                    this.state.timeError === "" ? false : true
                  }
                >
                  {this.props.acceptText}
                </button>
                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={this.decline.bind(this)}
                  disabled={
                    this.state.timeError === "" ? false : true
                  }
                >
                  {this.decline.bind(this)}
                  {this.props.declineText}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading
});

const mapDispatchToProps = {
  ExtendBookingEffect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtendBookingModal);
