import React, { Component } from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import { createCreditEffect, createUpdateEffect } from "../../PaymentWizard/Actions";

class EditCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.data.name,
      exp_month: this.props.data.exp_month,
      exp_year: this.props.data.exp_year,
      last4: "",
      monthNames: [{ id: 1, mon: "January" }, { id: 2, mon: "February" }, { id: 3, mon: "March" }, { id: 4, mon: "April" }, { id: 5, mon: "May" }, { id: 6, mon: "June" }, { id: 7, mon: "July" }, { id: 8, mon: "August" }, { id: 9, mon: "September" }, { id: 10, mon: "October" }, { id: 11, mon: "November" }, { id: 12, mon: "December" }]
    };
  }

  hasErrors = () => {
    return this.state.credit == 0 || this.state.credit < 0;
  };
  handleName = event => {
    this.setState({ name: event.target.value })
  }
  handleExpYear = event => {
    this.setState({ exp_year: event.target.value })
  }
  handleExpMonth = event => {
    this.setState({ exp_month: event.target.value })
  }

  handleSubmit = async () => {
    let request = {
      name: this.state.name,
      exp_month: this.state.exp_month,
      exp_year: this.state.exp_year,
      _method: "PUT"
    }
    this.props.createUpdateEffect(this.props.data.id, request)


    // if (!this.hasErrors()) {
    //   this.setState({ creditError: false });
    //   this.props.createCreditEffect({ credits: this.state.credit });
    //   this.props.handleToggleModal(false);
    // } else {
    //   this.setState({ creditError: true });
    // }

  };

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>Update Card</span>
              <div
                onClick={() => this.props.handleToggleModal(false)}
                className="close-btn"
              ></div>
            </div>
            <div className="payment">
              <div className="input-container">
                <input autoComplete="off" type="text" value={this.props.data.brand} readOnly />


              </div>
              <div className="input-container">
                <input autoComplete="off" type="text" placeholder="Name" onChange={e => this.handleName(e)} value={this.state.name} />
              </div>
              <div className="input-container__group">
                <div className="input-container" style={{ width: 120 }}>
                  <div className="input-select">
                    <select name="month" onChange={e => this.handleExpMonth(e)}>
                      {this.state.monthNames.map(month => {
                        return (

                          <option selected={month.id === Number(this.props.data.exp_month) ? true : false} value={month.id}>{month.mon}</option>
                        );
                      })}


                    </select>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-select">
                    <select onChange={e => this.handleExpYear(e)}>
                      <option>{this.props.data.exp_year}</option>
                      <option>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>2026</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-container">
                <input autoComplete="off" type="text" placeholder="1111" readOnly value={this.props.data.last4} />
              </div>
              <button onClick={() => this.handleSubmit()} className={`btn btn-blue wizard__btn mb-0${this.props.isLoading ? " disabled" : ""
                }${this.props.isLoading ? " btn--loading" : ""}`}>SAVE</button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading
});

const mapDispatchToProps = {
  createUpdateEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCardModal);
