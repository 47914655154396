import { Reducer } from "react";
import { BookingAction } from "../Contact/Actions";
import { ContactState } from "../../store";
import { AnyARecord } from "dns";

const initialState: ContactState = {
  myContacts: [],
  myTeams: [],
  contactIds: [],
  teamIds: [],
  invited_employees: [],
  singleContact: {},
  error: { status: false, message: "" },
  updatedTeam: {},
  myEmployeeContacts: [],
};

export const ContactsReducer: Reducer<ContactState, BookingAction> = (
  state: ContactState = initialState,
  action
) => {
  switch (action.type) {
    case "@@wizard/SET_CONTACTS":
      return { ...state, myContacts: action.Contacts.data };
    case "@@wizard/SET_EMPLOYER_CONTACTS":
      return {
        ...state,
        myEmployeeContacts: action.Contacts,
      };
    case "@@wizard/SET_TEAMS":
      return { ...state, myTeams: action.Teams.data };
    case "@@wizard/SET_CONTACT_IDS":
      return { ...state, contactIds: action.contactIds };
    case "@@wizard/SET_TEAM_IDS":
      return { ...state, teamIds: action.teamsIds };
    case "@@wizard/SET_EMPLOYEES_CONTACT_IDS":
      return { ...state, invited_employees: action.employeesIds };
    case "@@wizard/SET_ERRORS":
      return { ...state, error: action.error };
    case "@@wizard/SET_TEAM_ID_NAME":
      return { ...state, updatedTeam: action.info };
    case "@@wizard/SET_CONTACT":
      return {
        ...state,
        myContacts: state.myContacts.concat(action.Contacts.data),
        singleContact: action.Contacts.data,
      };
    case "@@wizard/SET_CONTACT_INVITE": {
      let contacts = state.myContacts;
      let contactIndex = contacts.findIndex((x) => x.id === action.contactId);
      contacts[contactIndex]["status"] = contacts[contactIndex]["status"]
        ? !contacts[contactIndex]["status"]
        : true;
      return { ...state, myContacts: contacts };
    }
    case "@@wizard/SET_EMPLOYEE_INVITE": {
      let contacts: any = state.myEmployeeContacts;
      let contactIndex: any = contacts?.findIndex(
        (x: any) => x.id === action.contactId
      );
      contacts[contactIndex]["Invitedstatus"] = contacts[contactIndex][
        "Invitedstatus"
      ]
        ? !contacts[contactIndex]["Invitedstatus"]
        : true;
      return { ...state, myEmployeeContacts: contacts };
    }

    case "@@wizard/SET_TEAM_INVITE": {
      let teams = state.myTeams;
      let teamIndex = teams.findIndex((x) => x.id === action.teamId);
      teams[teamIndex]["status"] = teams[teamIndex]["status"]
        ? !teams[teamIndex]["status"]
        : true;
      return { ...state, myTeams: teams };
    }
    default:
      return state;
  }
};
