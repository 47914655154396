import React from "react";
import { reduxForm, FormErrors } from "redux-form";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { Elements, StripeProvider } from "react-stripe-elements";

import CheckoutForm from "./CheckoutForm";

export type PaymentFields = {
  price: string;
  plan_id: string;
  stripe_token: string;
};

const PaymentPge = reduxForm<PaymentFields>({
  form: "paymentInfo",
  destroyOnUnmount: false,
  onSubmit: (formFields, dispatch) => {},
  validate: (values, props) => {
    const errors: FormErrors<PaymentFields> = {};
    return errors;
  }
})(props => {
  let { selectedPlan } = useSelector(
    (state: AppState) => state.wizard
  );
  const plan = useSelector(
    (state: AppState) => state.wizard.selectedPlan
  );
  return (
    <>
      <div
        className="content-wrapper"
        style={{ marginBottom: 159, marginTop: 70 }}
      >
        {plan && plan.name === "Free" ? (
          <p style={{ color: "#31B5CE" }}>
            While you won’t be charged for your free trial, you’ll be
            automatically converted to a paid membership plan at the
            end of the trial period. You can cancel anytime from your
            dashboard.
          </p>
        ) : null}
        {window.localStorage.getItem("isPlanName") ? null : (
          <div className="content-wrapper__title">
            Plan: {selectedPlan.name}
          </div>
        )}
        {/* <StripeProvider apiKey="pk_test_IWiLzns3KY1WzNA4x4P63wYi pk_live_Qeta0dy9XieLn4IjYElHyFXv00BtxZH8Uu"> */}
        <StripeProvider
          apiKey="pk_test_IWiLzns3KY1WzNA4x4P63wYi"
          // apiKey="pk_live_Qeta0dy9XieLn4IjYElHyFXv00BtxZH8Uu"
        >
          <Elements>
            <CheckoutForm />
          </Elements>
        </StripeProvider>
      </div>
    </>
  );
});

export default PaymentPge;
