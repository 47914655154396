import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getInviteFriend } from "../Auth/Actions";
import InviteFriendForm from "../Component/InviteFriendForm";
import LoaderModal from "../Dashboard/modals/Loader";
import Helmet from "react-helmet";
const InviteFriend = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInviteFriend());
  }, [invitedList]);

  const invitedList = useSelector(state => state.auth.invitedList);
  const loading = useSelector(state => state.auth.inviteLoading);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
      </Helmet>
      <div className="container" style={{ minHeight: 400 }}>
        <div
          className="approval__header-title"
          style={{ marginTop: "2rem" }}
        >
          <div className="dashboard__title">Invite Friends</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InviteFriendForm getInvitedList={getInviteFriend} />
          </div>
          <div className="col-md-12" style={{ marginTop: "2rem" }}>
            {invitedList && invitedList.length > 0 && (
              <div
                className="team-list utilization-chart__wrapper"
                style={{ minHeight: "9.3rem" }}
              >
                <table className="team-list-table table-style">
                  <thead>
                    <tr>
                      <th className="table-head">Name</th>
                      <th className="table-head">Email</th>
                      <th className="table-head">Invite Status</th>
                      <th className="table-head">Credits Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invitedList && invitedList.length > 0
                      ? invitedList.map(item => (
                          <tr style={{ height: 50 }}>
                            <td className="table-style">
                              {item.name || "-"}
                            </td>
                            <td className="table-style">
                              {item.email}
                            </td>
                            <td className="table-style">
                              {item.status}
                            </td>
                            <td className="table-style">
                              {item.credits_paid}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <LoaderModal />}
    </>
  );
};

export default InviteFriend;
