import { Reducer } from "react";
import { BookingAction } from "../Booking/Actions";
import { BookingState } from "../../../store";

const InitialBookingFields = {
  user_id: 0,
  space_id: 0,
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  booking_as: "",
  no_of_people: 0,
  no_of_booked_spaces: 0,
  venue_id: 0,
  credits: 0,
  teams: [],
  contacts: [],
  employer_id: 0
};
const initialState: BookingState = {
  BookingFields: InitialBookingFields,
  myBookings: [],
  singleBooking: {},
  myEmployers: [],
  MyteamBooking: [],
  MyorgEmployees: []
};

export const BookingReducer: Reducer<BookingState, BookingAction> = (
  state: BookingState = initialState,
  action
) => {
  switch (action.type) {
    case "@@wizard/SET_BOOKING":
      return { ...state, BookingFields: action.BookingFields };
    case "@@wizard/SET_RECENT_BOOKING":
      return { ...state, myBookings: action.MyBookings.data };
    case "@@wizard/SET_CUSTOMER_DATA":
      return { ...state, myEmployers: action.data };
    case "@@wizard/CANCEL_BOOKING":
      return { ...state, BookingFields: InitialBookingFields };
    case "@@wizard/SET_BOOKING_SINGLE":
      return { ...state, singleBooking: action.SingleBooking.data };
    case "@@wizard/SET_TEAM_BOOKING":
      return { ...state, MyteamBooking: action.data };
    case "@@wizard/SET_EMPLOYEES":
      return { ...state, MyorgEmployees: action.orgEmployees };

    default:
      return state;
  }
};
