import { Reducer } from "react";
import { WizardState } from "../../store";
import { WizardAction } from "./Actions";

export enum SignUpStep {
  UserType,
  UserInfo,
  Interests
}
const initialState: WizardState = {
  step: 1,
  isDisabled: true,
  interests: [],
  signUpStep: SignUpStep.UserType,
  plans: [],
  isPlanSelected: false,
  selectedPlan: {
    id: 0,
    name: "",
    price: "",
    credits: 0,
    description: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    type: ""
  },
  logo_deleted: 0,
  photo_deleted: 0,
  vaccination_certificate_deleted: 0,
  emailExistMessage: "",
  promoStatus: "",
  haspromo: false,
  isOpen: false
};

export const wizardReducer: Reducer<WizardState, WizardAction> = (
  state: WizardState = initialState,
  action
) => {
  switch (action.type) {
    case "@@wizard/SET_STEP":
      return { ...state, signUpStep: action.step };
    case "@@wizard/SET_DISABLED":
      return { ...state, isDisabled: action.isDisabled };
    case "@@wizard/SET_USER_TYPE":
      return { ...state, userType: action.userType };
    case "@@wizard/SUBMIT_USER_FORM":
      return { ...state, userInfoFields: action.userInfoFields };
    case "@@wizard/CHANGE_PERSONAL_SETTINGS_FORM":
      return {
        ...state,
        personalSettingsFields: action.personalSettingsFields
      };
    case "@@wizard/INTERESTS_LOADED":
      return { ...state, interests: action.interests };
    case "@@wizard/PLANS_LOADED":
      return { ...state, plans: action.plans };
    case "@@wizard/SET_SELECTED_PLAN":
      return {
        ...state,
        selectedPlan: action.plan,
        isPlanSelected: true
      };
    case "ENTERED_EMAIL_STATUS":
      return { ...state, emailExistMessage: action.payload };
    case "ENTERED_PROMO_STATUS":
      return { ...state, promoStatus: action.payload };
    case "SET_PROMO":
      return { ...state, hasPromo: action.payload };
    case "@@wizard/SMS_MODAL":
      return { ...state, isOpen: action.flag };
    case "@@wizard/IMAGE_PROFILE":
      if (action.data.photo_deleted) {
        return { ...state, photo_deleted: action.data.photo_deleted };
      } else if (action.data.vaccination_certificate_deleted) {
        return {
          ...state,
          vaccination_certificate_deleted:
            action.data.vaccination_certificate_deleted
        };
      }
      return { ...state, logo_deleted: action.data.logo_deleted };
    default:
      return state;
  }
};
