import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { getVenuesEffect, postSpaceBlockDays } from "../../VenueWizard/Actions";
import {
  PostSpaceAvailabilityEffect,
  EditSpaceAvailabilityEffect,
  storeSpaceAvailability,
} from "../../WorkspaceWizard/Actions";
import { getFormated12hTime } from "../../../../src/helpers/DateHelpers";
import SpaceAvailableSelector from "../../WorkspaceWizard/components/SpaceAvailableSelector";
class SpaceAvailabilityModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleVenue: null,
      selectedVenue: 0,
      selectedSpace: null,
      spaces: [],
      quantity: 1,
      quantityErrorFlag: false,
      quantityError: "",
      spaceError: false,
      startTime: 0,
      selectedDay: 0,
      canBeSubmit: true,
      maxAllowedSpace: 0,
      weekDays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      selectedTimes: {},
      availability: [
        { Monday: [] },
        { Tuesday: [] },
        { Wednesday: [] },
        { Thursday: [] },
        { Friday: [] },
        { Saturday: [] },
        { Sunday: [] },
      ],
    };
    this.CloseModal = this.CloseModal.bind(this);
    this.handleBlock = this.handleBlock.bind(this);
  }
  componentDidMount() {
    let data = { ...this.props.detailFields };
    let editSpace = {};
    let s = [];
    let venue = this.props.venues[0];
    let space = null;
    if (window.localStorage.getItem("editSpaceAvailability")) {
      const arr = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].map((day) => {
        let o = {};
        o[day] = [];
        if (data.availability && data.availability.length > 0) {
          for (let element of data.availability) {
            const key = Object.keys(element)[0];
            const obj = {};
            obj[day] = [];
            o = obj;
            if (day === key) {
              o = element;
              break;
            }
          }
        }
        return o;
      });
      this.setState({ availability: arr });
      this.props.venues.map((v) => {
        space = v.spaces.find((s) => s.id === data.space_id);
        if (space) {
          editSpace["value"] = space.id;
          editSpace["label"] = space.name;
          venue = v;
          let maxAllowedSpace = parseInt(space.quantity);
          this.setState({ maxAllowedSpace });
        }
      });

      this.setState({ selectedVenue: venue.venue_id });
      this.setState({ singleVenue: venue });

      s.push(editSpace);

      this.setState({
        quantity: data.quantity,
        selectedSpace: s,
      });
    }

    this.props.getVenuesEffect();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.venues.length > 0 &&
      prevProps.venues !== this.props.venues
    ) {
      let spaces = [];
      let venue = this.props.venues[0];
      this.props.venues
        .find((x) => x.id == parseInt(this.props.venues[0].id))
        .spaces.map((space) => {
          spaces.push({ value: space.id, label: space.name });
        });
      if (window.localStorage.getItem("editSpaceAvailability")) {
        this.props.venues.map((v) => {
          let s = v.spaces.find(
            (s) => s.id === this.props.detailFields.space_id
          );
          if (s) {
            venue = v;
            let space = [];
            space.push({ value: s.id, label: s.name });
            this.setState({ selectedSpace: space });
          }
        });
      }
      let space = this.props.venues
        .find((x) => x.id === parseInt(venue.id))
        .spaces.find(
          (space) => space.id === parseInt(this.props.detailFields.space_id)
        );
      if (space) {
        let maxAllowedSpace = parseInt(space.quantity);

        this.setState({ maxAllowedSpace });
      }
      this.setState({ spaces });
      this.setState({ selectedVenue: venue.id });
      this.setState({ singleVenue: venue });
    }

    if (
      this.props.detailFields.availability &&
      this.props.detailFields.availability.length !==
        prevProps.detailFields.availability.length
    ) {
      this.setState({ availability: this.props.detailFields.availability });
    }
  }

  CloseModal() {
    window.localStorage.removeItem("editSpaceAvailability");
    this.props.CloseModal();
  }

  setSelectedSpace = (selectedSpace) => {
    if (selectedSpace) {
      this.setState({ spaceError: false });
    }
    this.setState({ selectedSpace });
  };

  handleVenueChange = (event) => {
    let spaces = [];
    let value = event.target.value;
    this.props.venues
      .find((x) => x.id == parseInt(value))
      .spaces.map((space) => {
        spaces.push({ value: space.id, label: space.name });
      });
    this.setState({ selectedVenue: event.target.value, spaces });
    const venueHours = this.props.venues.find((v) => v.id === parseInt(value));
    this.setState({ singleVenue: venueHours });
  };

  handleBlock() {
    if (this.state.availability.length > 0) {
      let request = {
        space_id: this.state.selectedSpace.value
          ? this.state.selectedSpace.value
          : this.props.detailFields.space_id,
        availability: this.state.availability,
      };
      this.props.PostSpaceAvailabilityEffect(request);

      this.props.CloseModal("block");
    }
  }

  changeAvailabilityTime = (e) => {
    const { weekDays, selectedDay, availability } = this.state;

    const itemToEdit = availability[selectedDay][
      weekDays[selectedDay]
    ].findIndex((slot) => slot.time == e.target.name);

    let newAvailability = [...availability];

    let allTimes = [...newAvailability[selectedDay][weekDays[selectedDay]]];

    allTimes[itemToEdit].quantity = e.target.value;

    const selectedDayItems = {
      ...newAvailability[selectedDay],
      [weekDays[selectedDay]]: allTimes,
    };

    newAvailability[selectedDay] = selectedDayItems;

    this.setState({ availability: newAvailability }, () => this.checkErrors());
  };

  checkErrors = () => {
    const { availability, maxAllowedSpace } = this.state;
    let canBeSubmit = true;

    availability.map((val) => {
      let keys = Object.keys(val);
      val[keys[0]].map((time) => {
        if (time.quantity > maxAllowedSpace) {
          canBeSubmit = false;
        }
      });
    });

    this.setState({ canBeSubmit });
  };

  render() {
    const {
      availability,
      selectedDay,
      weekDays,
      canBeSubmit,
      maxAllowedSpace,
    } = this.state;
    return (
      <>
        <Modal>
          <div
            className="addressbook-form-box block-day-modal space-modal"
            style={{ maxHeight: "80vh", overflow: "auto" }}
          >
            <div className="congrat-box__header">
              <span>Workspace Availability</span>
              <div
                className="close-btn"
                onClick={this.CloseModal.bind(this, "aaa")}
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="flexBetween">
                {this.state.weekDays.map((day, index) => {
                  return (
                    <div
                      onClick={() => {
                        this.setState({ selectedDay: index });
                      }}
                      className="spaceDays"
                      style={{
                        cursor: "pointer",
                        borderBottom:
                          this.state.selectedDay === index
                            ? "2px solid #000"
                            : "",
                      }}
                    >
                      {day}
                    </div>
                  );
                })}
              </div>
              <div className="flexBetween">
                <p className="titleSpace">Time</p>
                <p className="titleSpace">Available Workspace</p>
              </div>
              <div className="spaceFormBorder">
                {availability.length > 0 &&
                  availability[selectedDay][weekDays[selectedDay]].map(
                    (dayTime) => {
                      return dayTime.time !== null ? (
                        <div className="daysFlex">
                          <div style={{ display: "flex" }}>
                            {getFormated12hTime(
                              dayTime.time.toString() + ":00:00"
                            )}
                          </div>
                          <div style={{ display: "flex" }}>
                            <input
                              autoComplete="off"
                              onChange={this.changeAvailabilityTime}
                              name={dayTime.time}
                              type="number"
                              value={dayTime.quantity}
                              className="form-control noButtons"
                              style={{ height: 21 }}
                            ></input>

                            {dayTime.quantity > maxAllowedSpace ? (
                              <p style={{ color: "#ff0000", fontSize: 12 }}>
                                {"Quantity exceeds space quantity " +
                                  maxAllowedSpace}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ) : null;
                    }
                  )}

                {/*Starting*/}
              </div>
              <button
                onClick={this.handleBlock.bind(this)}
                disabled={!canBeSubmit}
                style={{ color: "#ffff", position: "relative", top: "14px" }}
                className={`btn btn-blue-venue`}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  venues: state.venueWizard.venues,
  detailFields: state.workspaceWizard.singleSpaceAvailability,
});

const mapDispatchToProps = {
  getVenuesEffect,
  postSpaceBlockDays,
  PostSpaceAvailabilityEffect,
  EditSpaceAvailabilityEffect,
  storeSpaceAvailability,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceAvailabilityModal);
