import { toast } from "react-toastify";

declare const Buffer: any;
const absoluteUrl = (relativeUrl: string) =>
  `${process.env.REACT_APP_API_HOST}${relativeUrl}`;
// const absoluteUrl = (relativeUrl: string) =>
//   `${process.env.REACT_APP_API_HOST}${relativeUrl}`;

export type Files = {
  [key: string]: File;
};
//'throwErrorOnHttpRequestFail' parameter added as a workaround. 'unhandledrejection' event is not supported by all browsers yet.
export class ApiClient {
  static get<TResponse>(
    relativeUrl: string,
    token?: string,
    throwErrorOnHttpRequestFail = false
  ): Promise<TResponse> {
    return fetch(absoluteUrl(relativeUrl), {
      credentials: "omit",
      headers: getHeaders(token)
    }).then<TResponse>(data => {
      return handleFetchResponse(data, throwErrorOnHttpRequestFail);
    });
  }

  static post<TBody, TResponse>(
    relativeUrl: string,
    body: TBody,
    token?: string,
    throwErrorOnHttpRequestFail: boolean = false
  ): Promise<TResponse> {
    const jsonBody = JSON.stringify(body);

    return fetch(absoluteUrl(relativeUrl), {
      method: "POST",
      body: jsonBody,
      mode: "cors",
      headers: getPostHeaders(token, jsonBody),
      credentials: "omit"
    }).then<TResponse>(data => {
      return handleFetchResponse(data, throwErrorOnHttpRequestFail);
    });
  }

  static postMultipart<TBody, TResponse>(
    relativeUrl: string,
    body: TBody,
    token?: string,
    throwErrorOnHttpRequestFail: boolean = false
  ): Promise<TResponse> {
    var data = new FormData();
    Object.keys(body).forEach(key => {
      if (Array.isArray((body as any)[key])) {
        if ((body as any)[key] != undefined) {
          (body as any)[key].forEach((item: any) => {
            data.append(key + "[]", item);
          });
        }
      } else {
        if ((body as any)[key] != undefined) {
          data.append(key, (body as any)[key]);
        }
      }
    });

    return fetch(absoluteUrl(relativeUrl), {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "omit",
      headers: getHeaders(token)
    }).then<TResponse>(data => {
      return handleFetchResponse(data, throwErrorOnHttpRequestFail);
    });
  }

  static put<TBody, TResponse>(
    relativeUrl: string,
    body: TBody,
    token?: string,
    throwErrorOnHttpRequestFail: boolean = false
  ): Promise<TResponse> {
    const jsonBody = JSON.stringify(body);
    return fetch(absoluteUrl(relativeUrl), {
      method: "PUT",
      body: jsonBody,
      mode: "cors",
      headers: getPutHeaders(token),
      credentials: "omit"
    }).then<TResponse>(data => {
      return handleFetchResponse(data, throwErrorOnHttpRequestFail);
    });
  }

  static delete<TResponse>(
    relativeUrl: string,
    token?: string,
    throwErrorOnHttpRequestFail: boolean = true
  ): Promise<TResponse> {
    return fetch(absoluteUrl(relativeUrl), {
      method: "DELETE",
      mode: "cors",
      credentials: "omit",
      headers: getHeaders(token)
    }).then<TResponse>(data => {
      return handleFetchResponse(data, throwErrorOnHttpRequestFail);
      // if (!data.ok) {
      //   if (throwErrorOnHttpRequestFail) {
      //     setTimeout(function() {
      //       // throw new Error(`Delete operation failed. ${response}`);
      //       toast.error("Oops something went wrong...")
      //     });
      //   }

      //   Promise.reject(data);
      // }
    });
  }

  // HTTP FILE UPLOAD
  static upload(
    url: string,
    file: File,
    onProgress: (progress: number) => void,
    throwErrorOnHttpRequestFail: boolean = false
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();

      formData.append("file", file);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response) as string);
          } else {
            throw new Error(
              "Upload failed. Status Code: " + xhr.status
            );
          }
        }
      };

      xhr.upload.onprogress = event => {
        const progress = Math.round(
          (event.loaded / event.total) * 100
        );
        onProgress(progress);
      };

      xhr.open("POST", url, true);
      xhr.send(formData);
    });
  }
}

export function handleFetchResponse<TResponse>(
  response: Response,
  throwErrorOnHttpRequestFail: boolean
): Promise<TResponse> {
  // if (response.ok) {
  var contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  } else {
    //if response is not json and mostly empty
    return Promise.resolve({} as TResponse);
  }
  // }

  //HttpRequestFailError is handled by GlobalErrorHandler
  if (throwErrorOnHttpRequestFail) {
    setTimeout(function () {
      throw new Error(
        `API request failed: ${response.url}, ${response}`
      );
    });
  }

  return Promise.reject(response);
}

const getHeaders = (token?: string) => {
  const headers: any = {
    Accept: "application/json"
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return new Headers(headers);
};

const getPostHeaders = (token?: string, jsonBody?: any) => {
  const headers: any = {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    "Content-Length": new Buffer(jsonBody).byteLength.toString()
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return new Headers(headers);
};

const getPutHeaders = (token?: string) => {
  const headers: any = {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json"
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return new Headers(headers);
};
