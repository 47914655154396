/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import Select from "react-select";
import moment from "moment";

import {
  BookingAPI,
  SpaceAvailabilityRequest
} from "../../API/BookingAPI";
import LoaderModal from "./modals/Loader";
import useForm from "../../hooks/useForm";
import store, { AppState } from "../../store";
import { setLoader } from "../Loader/Actions";
import { DashboardApi } from "../../API/DashboardApi";
import EmployeeLimitModal from "./modals/EmployeeLimitModal";
import { createEmployerBooking } from "../Book/Booking/Actions";
import { formatDate, formatedTime } from "../../helpers/DateHelpers";

import "./accordion.sass";

const MakeEmployerBooking = (props: any) => {
  let initialData = {
    employee_id: "",
    start_date: "",
    start_time: "",
    end_time: "",
    no_of_people: null,
    no_of_booked_spaces: null,
    venue_id: "",
    space_id: "",
    special_requests: ""
  };

  const selectStyles = {
    control: (styles: any) => ({
      ...styles,
      height: "45px",
      border: "1px solid #a8c8d4",
      fontSize: "16px",
      fontFamily: "'RobotoRegular'"
    }),
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any
    ) => {
      return {
        ...styles
      };
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const { form, setForm } = useForm(initialData);
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );

  const [myEmployees, setMyEmployees] = useState([]);
  const [employeeError, setEmployeeError] = useState("");

  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [dateError, setDateError] = useState("");
  const [availableVenues, setAvailableVenues] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedVenue, setSelectedVenue]: any = useState(null);
  const [venueError, setVenueError] = useState("");
  const [venueSpaces, setVenueSpaces] = useState([]);
  const [spaceError, setSpaceError] = useState("");
  const [bookedSpaceError, setBookedSpaceError] = useState("");

  const [selectedSpace, setSelectedSpace]: any = useState(null);
  const [totalSpaceCapacity, setSpaceCapacity] = useState("");
  const [capacityError, setCapacityError] = useState("");
  const [totalSpaceQuantity, setTotalSpaceQuantity] = useState("");

  const [spaceAvailabilityError, setSpaceAvailabilityError]: any =
    useState({});
  let [availabilityCheck, setAvailabilityCheck] = useState("");
  let [fieldsError, setFieldsError] = useState("");
  const [isDurationValid, setDurationCheck] = useState(false);
  let [limit_error, setLimitError] = useState("");
  const [limit_modal, setLimitModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [totalCredits, setTotalCredits]: any = useState(0);
  const [showTotalCredits, setShowTotalCredits]: any =
    useState(false);

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (
      startTimeError == "" &&
      endTimeError == "" &&
      dateError == "" &&
      form.start_date != "" &&
      form.start_time != "" &&
      form.end_time != ""
    ) {
      setSelectedSpace(null);
      setSelectedVenue(null);
      setShowTotalCredits(false);
      getAvailableVenues();
    }
  }, [form.start_date, form.start_time, form.end_time]);
  useEffect(() => {
    if (
      form.start_date != "" &&
      form.start_date != null &&
      form.start_date != undefined &&
      form.start_time != "" &&
      form.start_time != null &&
      form.start_time != undefined &&
      form.end_time != "" &&
      form.end_time != null &&
      form.end_time != undefined &&
      form.venue_id != "" &&
      form.venue_id != null &&
      form.venue_id != undefined &&
      form.space_id != "" &&
      form.space_id != null &&
      form.space_id != undefined &&
      form.no_of_people != "" &&
      form.no_of_people != null &&
      form.no_of_people != undefined &&
      form.no_of_booked_spaces != "" &&
      form.no_of_booked_spaces != null &&
      form.no_of_booked_spaces != undefined &&
      form.space_id != "" &&
      form.space_id != null &&
      selectedSpace &&
      selectedSpace != null
    ) {
      var totalHour;
      let spacePrice;
      let totalCredit2;
      let min_price;
      let second =
        (form.end_time._d.getTime() - form.start_time._d.getTime()) /
        1000;
      totalHour = second / 3600;
      if (selectedSpace.min_booking_hours == null) {
        totalHour = second / 60;
        min_price = Math.ceil(parseFloat(selectedSpace.price) / 0.3);
        min_price = min_price / 60;
        totalCredit2 = Math.ceil(totalHour * min_price);
      } else if (totalHour >= selectedSpace.min_booking_hours) {
        spacePrice = parseFloat(selectedSpace.price);
        totalHour = totalHour * (spacePrice ? spacePrice : 1);
        totalCredit2 = Math.ceil(totalHour / 0.3);
      } else if (totalHour < selectedSpace.min_booking_hours) {
        spacePrice = parseFloat(selectedSpace.price);
        let minHours = Number(selectedSpace.min_booking_hours);
        totalHour = minHours * (spacePrice ? spacePrice : 1);
        totalCredit2 = Math.ceil(totalHour / 0.3);
      }
      let total = totalCredit2
        ? totalCredit2 * parseInt(form.no_of_booked_spaces)
        : 0;
      setTotalCredits(total);
      setShowTotalCredits(true);
    } else {
      setShowTotalCredits(false);
    }
  }, [
    form.start_date,
    form.start_time,
    form.end_time,
    form.venue_id,
    form.space_id,
    form.no_of_people,
    form.no_of_booked_spaces
  ]);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const getAvailableVenues = async () => {
    dispatch(setLoader(true));
    const storeToken: any = store.getState();
    const token: any = props.token
      ? props.token
      : storeToken.auth.token;
    let request = {
      date: formatDate(form.start_date),
      start_time: formatedTime(form.start_time._d),
      end_time: formatedTime(form.end_time._d)
    };
    let response: any =
      await DashboardApi.GetAvailableVenuesForEmployer(
        request,
        token
      );
    if (response.data && response.data.length > 0) {
      const updatedVenues = await response.data.map((list: any) => {
        list["label"] = list.name;
        list["value"] = list.id;
        return list;
      });
      setAvailableVenues(updatedVenues);
      dispatch(setLoader(false));
    } else {
      dispatch(setLoader(false));
    }
  };

  const getCustomers = async () => {
    const storeToken: any = store.getState();
    const token: any = props.token
      ? props.token
      : storeToken.auth.token;
    let response: any = await DashboardApi.GetMyCustomers(token);
    if (response.data && response.data.length > 0) {
      const updatedCustomers = await response.data.map(
        (list: any) => {
          list["label"] =
            list.invited_first_name || list.invited_last_name
              ? list.invited_first_name + " " + list.invited_last_name
              : list.full_name;
          list["value"] = list.id;
          return list;
        }
      );
      setMyEmployees(updatedCustomers);
    }
  };

  const handleFromDate = (event: any) => {
    if (event) {
      setAvailabilityCheck("");

      var selectedDate = event;
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);
      setForm((form: any) => ({
        ...form,
        start_date: event,
        end_date: event
      }));
      setDateError("");
      setFieldsError("");

      if (selectedDate.getTime() === currentDate.getTime()) {
        if (
          form.end_time &&
          form.end_time._d.getTime() < new Date().getTime() &&
          form.start_time &&
          form.start_time._d.getTime() < new Date().getTime()
        ) {
          setStartTimeError("Please select a valid time");
          setEndTimeError("Please select a valid time");
          return;
        }
        if (
          form.end_time &&
          form.end_time._d.getTime() == form.start_time &&
          form.start_time._d.getTime()
        ) {
          setEndTimeError("Please select a Valid time");
          return;
        }
        if (
          form.start_time &&
          form.start_time._d.getTime() < new Date().getTime()
        ) {
          setStartTimeError("Please select a valid time");
          return;
        } else if (
          form.end_time &&
          form.end_time._d.getTime() < form.start_time &&
          form.start_time._d.getTime()
        ) {
          setStartTimeError(
            "Please select a time less than End Time"
          );
          return;
        } else if (
          form.end_time &&
          form.end_time._d.getTime() < new Date().getTime()
        ) {
          setStartTimeError(
            "Please select a time greater than End Time"
          );
          return;
        } else {
          setStartTimeError("");
          setEndTimeError("");
          setSelectedVenue(null);
          setSelectedSpace(null);
          setShowTotalCredits(false);
        }
      } else {
        if (
          form.end_time &&
          form.end_time._d.getTime() == form.start_time &&
          form.start_time._d.getTime()
        ) {
          setEndTimeError("Please select a Valid time");
          return;
        }
        if (
          form.end_time &&
          form.end_time._d.getTime() < form.start_time &&
          form.start_time._d.getTime()
        ) {
          setStartTimeError(
            "Please select a time less than End Time"
          );
          return;
        } else {
          setStartTimeError("");
          setEndTimeError("");
          setSelectedVenue(null);
          setSelectedSpace(null);
          setShowTotalCredits(false);
        }
      }
    }
  };

  const handleFromTime = async (event: any) => {
    if (event) {
      setFieldsError("");
      setStartTimeError("");
      setAvailabilityCheck("");

      var currentDate = new Date();
      var start_date = form.start_date ? form.start_date : new Date();
      start_date.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      if (start_date.getTime() === currentDate.getTime()) {
        if (event._d.getTime() < new Date().getTime()) {
          setForm((form: any) => ({
            ...form,
            start_time: event
          }));
          setStartTimeError("Please select a valid time");
          return;
        } else if (
          form.end_time &&
          form.end_time._d.getTime() < event._d.getTime()
        ) {
          setForm((form: any) => ({
            ...form,
            start_time: event
          }));
          setStartTimeError(
            "Please select a time less than End Time"
          );
          return;
        } else if (
          form.end_time &&
          form.end_time._d.getTime() > event._d.getTime()
        ) {
          setForm((form: any) => ({
            ...form,
            start_time: event
          }));
          setEndTimeError("");
          return;
        } else if (
          form.end_time &&
          form.end_time._d.getTime() == event._d.getTime()
        ) {
          setForm((form: any) => ({
            ...form,
            start_time: event
          }));
          setStartTimeError("Please add a Valid Time");
          return;
        } else {
          setStartTimeError("");
          setSelectedVenue(null);
          setSelectedSpace(null);
          setShowTotalCredits(false);

          setForm((form: any) => ({
            ...form,
            start_time: event
          }));
          return;
        }
      } else if (
        form.end_time &&
        form.end_time._d.getTime() < event._d.getTime()
      ) {
        setForm((form: any) => ({
          ...form,
          start_time: event
        }));
        setStartTimeError("Please select a time less than End Time");
        return;
      } else if (
        form.end_time &&
        form.end_time._d.getTime() > event._d.getTime()
      ) {
        setForm((form: any) => ({
          ...form,
          start_time: event
        }));
        setEndTimeError("");
        return;
      } else if (
        form.end_time &&
        form.end_time._d.getTime() == event._d.getTime()
      ) {
        setForm((form: any) => ({
          ...form,
          start_time: event
        }));
        setStartTimeError("Please add a Valid Time");
        return;
      } else {
        setStartTimeError("");
        setForm((form: any) => ({
          ...form,
          start_time: event
        }));
        setSelectedVenue(null);
        setShowTotalCredits(false);

        setSelectedSpace(null);
        return;
      }
    }
  };

  const handleToTime = async (event: any) => {
    if (event) {
      setFieldsError("");
      setEndTimeError("");
      setAvailabilityCheck("");
      var currentDate = new Date();
      var start_date = form.start_date ? form.start_date : new Date();
      start_date.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      if (start_date.getTime() === currentDate.getTime()) {
        if (
          form.start_time &&
          form.start_time._d.getTime() > event._d.getTime()
        ) {
          setForm((form: any) => ({
            ...form,
            end_time: event
          }));
          setEndTimeError(
            "Please select a time greater than Start Time"
          );
          return;
        } else if (
          form.start_time &&
          form.start_time._d.getTime() == event._d.getTime()
        ) {
          setEndTimeError("Please add a Valid time");
          setForm((form: any) => ({
            ...form,
            end_time: event
          }));
          return;
        } else if (
          form.start_time &&
          form.start_time._d.getTime() < event._d.getTime()
        ) {
          setStartTimeError("");
          setForm((form: any) => ({
            ...form,
            end_time: event
          }));
          return;
        } else if (event._d.getTime() < new Date().getTime()) {
          setEndTimeError("Please add a future time");
          setForm((form: any) => ({
            ...form,
            end_time: event
          }));
          return;
        } else {
          setEndTimeError("");
          setForm((form: any) => ({
            ...form,
            end_time: event
          }));
          setSelectedVenue(null);
          setShowTotalCredits(false);

          setSelectedSpace(null);
          return;
        }
      } else if (
        form.start_time &&
        form.start_time._d.getTime() > event._d.getTime()
      ) {
        setForm((form: any) => ({
          ...form,
          end_time: event
        }));
        setEndTimeError(
          "Please select a time greater than Start Time"
        );
        return;
      } else if (
        form.start_time &&
        form.start_time._d.getTime() < event._d.getTime()
      ) {
        setStartTimeError("");
        setForm((form: any) => ({
          ...form,
          end_time: event
        }));
        return;
      } else if (
        form.start_time &&
        form.start_time._d.getTime() == event._d.getTime()
      ) {
        setEndTimeError("Please add a Valid time");
        setForm((form: any) => ({
          ...form,
          end_time: event
        }));
        return;
      } else {
        setStartTimeError("");
        setForm((form: any) => ({
          ...form,
          end_time: event
        }));
        setSelectedVenue(null);
        setSelectedSpace(null);
        setShowTotalCredits(false);

        return;
      }
    }
  };

  const handleSelectEmployer = (e: any) => {
    setFieldsError("");
    setEmployeeError("");
    setAvailabilityCheck("");

    setForm((form: any) => ({
      ...form,
      employee_id: e.id
    }));
    setSelectedEmployee(e);
  };

  const handleVenueChange = async (e: any) => {
    setFieldsError("");
    setVenueError("");
    setAvailabilityCheck("");

    setForm((form: any) => ({
      ...form,
      venue_id: e.id,
      space_id: ""
    }));
    setSelectedVenue(e);

    setSelectedSpace(null);
    setSpaceCapacity("");
    setTotalSpaceQuantity("");
    const updatedSpaces = await e.spaces.map((list: any) => {
      list["label"] = list.name;
      list["value"] = list.id;
      return list;
    });
    setVenueSpaces(updatedSpaces);
  };

  const handleSelectSpace = (e: any) => {
    setFieldsError("");
    setSpaceError("");
    setAvailabilityCheck("");

    setForm((form: any) => ({
      ...form,
      space_id: e.id
    }));
    setSelectedSpace(e);
    setSpaceCapacity(e.capacity);
    setTotalSpaceQuantity(e.quantity);
  };

  const handleFormChange = (e: any) => {
    e.persist();
    setFieldsError("");
    setAvailabilityCheck("");

    setSpaceAvailabilityError({});
    if (e.target.name == "no_of_people") {
      setCapacityError("");
    }
    if (e.target.name == "no_of_booked_spaces") {
      setBookedSpaceError("");
    }
    setForm((form: any) => ({
      ...form,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.persist();
    let HasError = false;
    if (form.employee_id == "" || form.employee_id == null) {
      setEmployeeError("Required");
      HasError = true;
    }
    if (form.start_time == "" || form.start_time == null) {
      setStartTimeError("Required");
      HasError = true;
    }
    if (form.end_time == "" || form.end_time == null) {
      setEndTimeError("Required");
      HasError = true;
    }
    if (form.start_date == "" || form.start_date == null) {
      setDateError("Required");
      HasError = true;
    }
    if (
      form.start_date == "" ||
      (form.start_date == null && form.start_time == "") ||
      (form.start_time == null && form.end_time == "") ||
      form.end_time == null
    ) {
      setStartTimeError("Please select a date and time first");
      setDateError("Please select a date and time first");

      HasError = true;
    }

    if (form.no_of_people == "" || form.no_of_people == null) {
      setCapacityError("Required");
      HasError = true;
    }
    if (form.space_id == "" || form.space_id == null) {
      setSpaceError("Required");
      HasError = true;
    }
    if (form.venue_id == "" || form.venue_id == null) {
      setVenueError("Required");
      HasError = true;
    }
    if (
      form.no_of_booked_spaces == "" ||
      form.no_of_booked_spaces == null
    ) {
      setBookedSpaceError("Required");
      HasError = true;
    } else if (!HasError) {
      var diff =
        form.end_time._d.getHours() - form.start_time._d.getHours();

      if (selectedSpace) {
        if (
          selectedSpace &&
          diff < Number(selectedSpace.min_booking_hours)
        ) {
          toast.error(
            "Please check that time selected falls within minimum and maximum hour requirement"
          );
          return null;
        }
        if (diff > Number(selectedSpace.max_booking_hours)) {
          toast.error(
            "Please check that time selected falls within minimum and maximum hour requirement"
          );
          return null;
        }
        if (
          selectedVenue &&
          selectedVenue?.lead_time != 0 &&
          selectedVenue?.lead_time != null &&
          selectedVenue?.lead_time != undefined
        ) {
          var currentDate = new Date();
          var start_date = form.start_date
            ? form.start_date
            : new Date();
          start_date.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);
          const beforetime: any = moment(new Date()).add(
            selectedVenue?.lead_time,
            "hours"
          );

          if (start_date.getTime() === currentDate.getTime()) {
            if (
              form.start_time._d.getTime() < beforetime._d.getTime()
            ) {
              setStartTimeError(
                `Lead time must be greater than ${selectedVenue?.lead_time} hours `
              );
              return null;
            }
          } else {
            var totalHour;
            let spacePrice;
            let totalCredit2;
            let min_price;
            let second =
              (form.end_time._d.getTime() -
                form.start_time._d.getTime()) /
              1000;
            totalHour = second / 3600;
            if (selectedSpace.min_booking_hours == null) {
              totalHour = second / 60;
              min_price = Math.ceil(
                parseFloat(selectedSpace.price) / 0.3
              );
              min_price = min_price / 60;
              totalCredit2 = Math.ceil(totalHour * min_price);
            } else if (totalHour >= selectedSpace.min_booking_hours) {
              spacePrice =
                selectedSpace && parseFloat(selectedSpace.price);
              totalHour = totalHour * (spacePrice ? spacePrice : 1);
              totalCredit2 = Math.ceil(totalHour / 0.3);
            } else if (totalHour < selectedSpace.min_booking_hours) {
              spacePrice =
                selectedSpace && parseFloat(selectedSpace.price);
              let minHours = Number(
                selectedSpace && selectedSpace.min_booking_hours
              );
              totalHour = minHours * (spacePrice ? spacePrice : 1);
              totalCredit2 = Math.ceil(totalHour / 0.3);
            }
            let total = totalCredit2
              ? totalCredit2 * parseInt(form.no_of_booked_spaces)
              : 0;
            let requestTimeStart = formatedTime(
              form.start_time._d
            ).split(":");
            let requestTimeEnd = formatedTime(form.end_time._d).split(
              ":"
            );
            let request: any = {
              date: formatDate(form.start_date),
              opening_time:
                requestTimeStart[0] + ":" + requestTimeStart[1],
              closing_time:
                requestTimeEnd[0] + ":" + requestTimeEnd[1],
              space_id: parseInt(form.space_id),
              no_of_spaces: form.no_of_booked_spaces,
              no_of_people: form.no_of_people
            };
            dispatch(setLoader(true));

            let response1 = await BookingAPI.BookingsValidations(
              new SpaceAvailabilityRequest(request)
            );
            if (response1.data.availability) {
              setAvailabilityCheck("");
              setSpaceAvailabilityError({
                availability: true,
                max_available: response1.data.max_available
              });
              let response = await BookingAPI.GetSpaceAvailability(
                new SpaceAvailabilityRequest(request)
              );
              if (response.data) {
                if (response.data.availability) {
                  let req = {
                    employee_id: form.employee_id,
                    credits: total,
                    credits_per_hour: Math.ceil(
                      parseFloat(selectedSpace.price) / 0.3
                    )
                  };
                  const storeToken: any = store.getState();
                  const token: any = props.token
                    ? props.token
                    : storeToken.auth.token;
                  const spendingsResponse =
                    await DashboardApi.validateEmployerSpendings(
                      token,
                      req
                    );
                  if (spendingsResponse.valid == false) {
                    if (spendingsResponse.hourly == false) {
                      dispatch(setLoader(false));
                      setLimitError(
                        "You have reached your hourly credit limit."
                      );
                      setLimitModal(true);
                    }
                    if (spendingsResponse.monthly == false) {
                      dispatch(setLoader(false));
                      setLimitError(
                        "You have reached your monthly credit limit."
                      );
                      setLimitModal(true);
                    }
                  } else {
                    let bookingRequest: any = {
                      start_date: formatDate(form.start_date),
                      end_date: formatDate(form.start_date),
                      start_time:
                        requestTimeStart[0] +
                        ":" +
                        requestTimeStart[1] +
                        ":00",
                      end_time:
                        requestTimeEnd[0] +
                        ":" +
                        requestTimeEnd[1] +
                        ":00",
                      space_id: parseInt(form.space_id),
                      no_of_booked_spaces: form.no_of_booked_spaces,
                      no_of_people: form.no_of_people,
                      special_requests: form.special_requests,
                      credits: total,
                      employee_id: form.employee_id,
                      venue_id: form.venue_id
                    };
                    dispatch(
                      createEmployerBooking(bookingRequest, history)
                    );
                    dispatch(setLoader(false));
                  }
                } else {
                  dispatch(setLoader(false));
                  setAvailabilityCheck(response.data.message);
                }
              } else {
                dispatch(setLoader(false));
                setAvailabilityCheck(
                  "Workspace is not available on the selected day and time."
                );
              }
            } else {
              dispatch(setLoader(false));
              toast.error(response1.data.message);
              setSpaceAvailabilityError(response1.data);
              setAvailabilityCheck(response1.data.message);
            }
          }
        } else {
          var totalHour;
          let spacePrice;
          let totalCredit2;
          let min_price;
          let second =
            (form.end_time._d.getTime() -
              form.start_time._d.getTime()) /
            1000;
          totalHour = second / 3600;
          if (selectedSpace.min_booking_hours == null) {
            totalHour = second / 60;
            min_price = Math.ceil(
              parseFloat(selectedSpace.price) / 0.3
            );
            min_price = min_price / 60;
            totalCredit2 = Math.ceil(totalHour * min_price);
          } else if (totalHour >= selectedSpace.min_booking_hours) {
            spacePrice =
              selectedSpace && parseFloat(selectedSpace.price);
            totalHour = totalHour * (spacePrice ? spacePrice : 1);
            totalCredit2 = Math.ceil(totalHour / 0.3);
          } else if (totalHour < selectedSpace.min_booking_hours) {
            spacePrice =
              selectedSpace && parseFloat(selectedSpace.price);
            let minHours = Number(
              selectedSpace && selectedSpace.min_booking_hours
            );
            totalHour = minHours * (spacePrice ? spacePrice : 1);
            totalCredit2 = Math.ceil(totalHour / 0.3);
          }
          let total = totalCredit2
            ? totalCredit2 * parseInt(form.no_of_booked_spaces)
            : 0;
          let requestTimeStart = formatedTime(
            form.start_time._d
          ).split(":");
          let requestTimeEnd = formatedTime(form.end_time._d).split(
            ":"
          );
          let request: any = {
            date: formatDate(form.start_date),
            opening_time:
              requestTimeStart[0] + ":" + requestTimeStart[1],
            closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
            space_id: parseInt(form.space_id),
            no_of_spaces: form.no_of_booked_spaces,
            no_of_people: form.no_of_people
          };
          dispatch(setLoader(true));

          let response1 = await BookingAPI.BookingsValidations(
            new SpaceAvailabilityRequest(request)
          );
          if (response1.data.availability) {
            setAvailabilityCheck("");
            setSpaceAvailabilityError({
              availability: true,
              max_available: response1.data.max_available
            });
            let response = await BookingAPI.GetSpaceAvailability(
              new SpaceAvailabilityRequest(request)
            );
            if (response.data) {
              if (response.data.availability) {
                let req = {
                  employee_id: form.employee_id,
                  credits: total,
                  credits_per_hour: Math.ceil(
                    parseFloat(selectedSpace.price) / 0.3
                  )
                };
                const storeToken: any = store.getState();
                const token: any = props.token
                  ? props.token
                  : storeToken.auth.token;
                const spendingsResponse =
                  await DashboardApi.validateEmployerSpendings(
                    token,
                    req
                  );
                if (spendingsResponse.valid == false) {
                  if (spendingsResponse.hourly == false) {
                    dispatch(setLoader(false));
                    setLimitError(
                      "You have reached your hourly credit limit."
                    );
                    setLimitModal(true);
                  }
                  if (spendingsResponse.monthly == false) {
                    dispatch(setLoader(false));
                    setLimitError(
                      "You have reached your monthly credit limit."
                    );
                    setLimitModal(true);
                  }
                } else {
                  let bookingRequest: any = {
                    start_date: formatDate(form.start_date),
                    end_date: formatDate(form.start_date),
                    start_time:
                      requestTimeStart[0] +
                      ":" +
                      requestTimeStart[1] +
                      ":00",
                    end_time:
                      requestTimeEnd[0] +
                      ":" +
                      requestTimeEnd[1] +
                      ":00",
                    space_id: parseInt(form.space_id),
                    no_of_booked_spaces: form.no_of_booked_spaces,
                    no_of_people: form.no_of_people,
                    special_requests: form.special_requests,
                    credits: total,
                    employee_id: form.employee_id,
                    venue_id: form.venue_id
                  };
                  dispatch(
                    createEmployerBooking(bookingRequest, history)
                  );
                  dispatch(setLoader(false));
                }
              } else {
                dispatch(setLoader(false));
                setAvailabilityCheck(response.data.message);
              }
            } else {
              dispatch(setLoader(false));
              setAvailabilityCheck(
                "Workspace is not available on the selected day and time."
              );
            }
          } else {
            dispatch(setLoader(false));
            toast.error(response1.data.message);
            setSpaceAvailabilityError(response1.data);
            setAvailabilityCheck(response1.data.message);
          }
        }
      }
    }
  };

  const numhasErrors = () => {
    if (selectedSpace) {
      let capacity;
      if (
        selectedSpace &&
        selectedSpace.capacity &&
        form.no_of_booked_spaces
      ) {
        capacity =
          selectedSpace &&
          selectedSpace.capacity * form.no_of_booked_spaces;
        return form.no_of_people > capacity;
      } else {
        capacity = selectedSpace && selectedSpace.capacity;
        return form.no_of_people > capacity;
      }
    } else {
      return false;
    }
  };
  const spacehasErrors = () => {
    if (selectedSpace && form.no_of_booked_spaces) {
      return (
        form.no_of_booked_spaces >
        Number(
          Object.keys(spaceAvailabilityError).length > 0
            ? spaceAvailabilityError.max_available
            : Number(selectedSpace.quantity)
        )
      );
    } else {
      return false;
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee - Employer Booking</title>
      </Helmet>
      <div className="container pd-0">
        <form>
          <div className="wrapper wrapper-login">
            <div className="wizard account-wizard">
              <div
                className="wizard__title"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Make a booking for your team
                <div
                  className="col-md-1"
                  style={{
                    position: "absolute",
                    right: 0
                  }}
                >
                  <div
                    className="tooltip"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "20px"
                      }}
                      src="/img/info.png"
                      alt="info-icon"
                    />
                    <span
                      className="tooltiptext"
                      style={{
                        textTransform: "none"
                      }}
                    >
                      Here you are able to create bookings for your
                      employees
                    </span>
                  </div>
                </div>
              </div>

              <div className="row registerRow">
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">Select Employee</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Select
                      name="employee_id"
                      styles={selectStyles}
                      value={selectedEmployee}
                      onChange={handleSelectEmployer}
                      isSearchable={false}
                      placeholder="Select Employee *"
                      options={
                        myEmployees && myEmployees.length > 0
                          ? myEmployees
                          : []
                      }
                      onMenuClose={onMenuClose}
                      onMenuOpen={onMenuOpen}
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {employeeError}
                  </span>
                </div>
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">Start Date</label>{" "}
                  <div
                    className="input-container"
                    style={{ paddingLeft: "0px", marginLeft: "-1px" }}
                  >
                    <DatePicker
                      className="Booking_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select Date *"
                      selected={form.start_date}
                      onChange={handleFromDate}
                      minDate={new Date()}
                    />
                    <img
                      src="/img/icons/from.svg"
                      style={{ width: "24px" }}
                      alt="Start Date"
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {dateError}
                  </span>
                </div>
              </div>

              <div className="row registerRow">
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">Start Time</label>
                  <div
                    className="input-container"
                    style={{ paddingLeft: "3px" }}
                  >
                    <TimePicker
                      className={"start_time"}
                      placeholder="Start Time"
                      defaultValue={
                        form.start_time ? form.start_time : null
                      }
                      showSecond={false}
                      onChange={handleFromTime}
                      minuteStep={15}
                    />
                    <img
                      src="/img/icons/clock.svg"
                      style={{ width: "24px" }}
                      alt="StartTime *"
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {startTimeError}
                  </span>
                </div>

                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">End Time</label>
                  <div
                    className="input-container"
                    style={{ paddingLeft: "3px" }}
                  >
                    <TimePicker
                      className={"start_time"}
                      placeholder="End Time *"
                      showSecond={false}
                      defaultValue={
                        form.end_time ? form.end_time : null
                      }
                      onChange={handleToTime}
                      minuteStep={15}
                    />
                    <img
                      src="/img/icons/clock.svg"
                      style={{ width: "24px" }}
                      alt="From"
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {endTimeError}
                  </span>
                </div>
              </div>

              <div className="row registerRow">
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">Venue</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Select
                      name="venue"
                      styles={selectStyles}
                      value={selectedVenue}
                      onChange={handleVenueChange}
                      placeholder="Select Venue *"
                      options={
                        availableVenues && availableVenues.length > 0
                          ? availableVenues
                          : []
                      }
                      noOptionsMessage={() =>
                        !form.start_date ||
                        form.start_time == "" ||
                        form.end_time == ""
                          ? "Please select a date and time first"
                          : "No Venue Found"
                      }
                      onMenuClose={onMenuClose}
                      onMenuOpen={onMenuOpen}
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {venueError}
                  </span>
                </div>
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">Workspace</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Select
                      name="space"
                      styles={selectStyles}
                      value={selectedSpace}
                      onChange={handleSelectSpace}
                      isSearchable={false}
                      placeholder="Select Space *"
                      options={
                        venueSpaces && venueSpaces.length > 0
                          ? venueSpaces
                          : []
                      }
                      noOptionsMessage={() =>
                        !form.start_date ||
                        form.start_time == "" ||
                        form.end_time == ""
                          ? "Please select a date and time first"
                          : "No Spaces Found"
                      }
                      onMenuClose={onMenuClose}
                      onMenuOpen={onMenuOpen}
                    />
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {spaceError}
                  </span>
                </div>
              </div>

              <div className="row registerRow">
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">No Of Spaces</label>
                  <div
                    className="input-container"
                    style={{ paddingLeft: "8px" }}
                  >
                    <input
                      className={"noButtons"}
                      type="number"
                      name="no_of_booked_spaces"
                      placeholder="Number of Spaces"
                      value={form.no_of_booked_spaces}
                      onChange={handleFormChange}
                      // style={{ paddingLeft: "10px" }}
                    />
                  </div>
                  <div className="space-capacity_row">
                    <div
                      className="space-capacity"
                      style={{ marginLeft: 0 }}
                    >
                      {spacehasErrors() ? (
                        <p
                          style={{
                            color: "#ff0000",
                            fontSize: "11px"
                          }}
                        >
                          Number of Workspaces selected is greater
                          than what is available{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      {totalSpaceQuantity ? (
                        <span style={{ fontWeight: "bold" }}>
                          Total Spaces:{" "}
                          {Object.keys(spaceAvailabilityError)
                            .length > 0
                            ? spaceAvailabilityError.availability
                              ? spaceAvailabilityError.max_available
                                ? spaceAvailabilityError.max_available
                                : 0
                              : 0
                            : totalSpaceQuantity}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {bookedSpaceError}
                  </span>
                </div>{" "}
                <div className="col-md-6 registerRowMbl">
                  <label htmlFor="">No Of People</label>
                  <div
                    className="input-container"
                    style={{ paddingLeft: "8px" }}
                  >
                    <input
                      className={"noButtons"}
                      type="number"
                      name="no_of_people"
                      placeholder="Number of People"
                      value={form.no_of_people}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="space-capacity_row">
                    <div
                      className="space-capacity"
                      style={{ marginLeft: 0 }}
                    >
                      {numhasErrors() ? (
                        <p
                          style={{
                            color: "#ff0000",
                            fontSize: "11px"
                          }}
                        >
                          Number of people is greater than Workspace
                          capacity{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      {totalSpaceCapacity ? (
                        <span style={{ fontWeight: "bold" }}>
                          Space Capacity: {totalSpaceCapacity}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {capacityError}
                  </span>
                </div>
              </div>

              <div className="row registerRow">
                <div className="registerRowMbl">
                  <label htmlFor="">Special Request</label>
                  <div
                    className="input-container"
                    style={{ paddingLeft: "8px" }}
                  >
                    <input
                      type="text"
                      name="special_requests"
                      placeholder="Special Request"
                      value={form.special_requests}
                      onChange={handleFormChange}
                      // style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>
              </div>

              <br />
              {availabilityCheck != "" ? (
                <p
                  style={{
                    marginTop: 10,
                    color: "red",
                    textTransform: "uppercase"
                  }}
                >
                  {availabilityCheck}
                </p>
              ) : null}

              <p
                style={{
                  marginTop: 10,
                  color: "red",
                  textTransform: "uppercase"
                }}
              >
                {fieldsError}
              </p>
              {showTotalCredits && selectedVenue != null ? (
                <span>Total Credits Charge: {totalCredits}</span>
              ) : null}
              <button
                type="submit"
                className={
                  numhasErrors() ||
                  spacehasErrors() ||
                  startTimeError != "" ||
                  endTimeError != "" ||
                  dateError != "" ||
                  employeeError != "" ||
                  availabilityCheck != "" ||
                  bookedSpaceError != "" ||
                  capacityError != ""
                    ? "btn btn-disable wizard__btn bookings-process__btn"
                    : "btn btn-pink wizard__btn mb-0"
                }
                disabled={
                  numhasErrors() ||
                  spacehasErrors() ||
                  startTimeError != "" ||
                  endTimeError != "" ||
                  dateError != "" ||
                  employeeError != "" ||
                  availabilityCheck != "" ||
                  bookedSpaceError != "" ||
                  capacityError != ""
                    ? true
                    : false
                }
                onClick={handleSubmit}
              >
                Book Now
              </button>
            </div>
          </div>
        </form>
      </div>
      {isLoading ? <LoaderModal /> : null}
      {limit_modal ? (
        <EmployeeLimitModal
          message={limit_error}
          handleClose={setLimitModal}
        />
      ) : null}
    </>
  );
};
export default MakeEmployerBooking;
