import React from "react";
import { connect } from "react-redux";
import { DashboardApi } from "../../API/DashboardApi";
import store from "../../store";
import { toast } from "react-toastify";
import { getInvitesList } from "../Dashboard/Actions";

class MyInvitations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invitations: [],
    };
  }

  componentDidMount() {
    this.props.getInvitesList();
  }
  componentDidUpdate(nextProps) {
    if (
      nextProps.invitationsList.length !== this.props.invitationsList.length
    ) {
      this.props.getInvitesList();
    }
  }

  async acceptRejectInvitation(invite_number, status) {
    let data = {
      status: status,
    };
    const token = store.getState().auth.token;
    let response = await DashboardApi.AcceptRejectInvitation(
      token,
      invite_number,
      data
    );
    if (response.data) {
      if (response.data.status) {
        toast.success("Invitation successfully " + status, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      this.props.getInvitesList();
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.invitationsList && this.props.invitationsList.length > 0 ? (
          <div className="invitation-container" style={{ marginBottom: 30 }}>
            {this.props.invitationsList.map((item) => (
              <div className="my-invitation-block" key={item.id}>
                <div className="invitation-text">
                  You have been invited by{" "}
                  <b>{item.employer.primary_user.full_name} </b> to join{" "}
                  <b>{item.employer.company_name}</b> employer plan.
                </div>
                <div className="display-flex">
                  <button
                    className="btn invite-action-btn wizard__btn mb-0"
                    onClick={this.acceptRejectInvitation.bind(
                      this,
                      item.invite_number,
                      "Accepted"
                    )}
                  >
                    Accept
                  </button>
                  <button
                    className="btn invite-action-btn wizard__btn mb-0"
                    onClick={this.acceptRejectInvitation.bind(
                      this,
                      item.invite_number,
                      "Rejected"
                    )}
                  >
                    Reject
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.Loader.isLoading,
  invitationsList: state.dashboard.customerInvitation,
});

const mapDispatchToProps = {
  getInvitesList,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyInvitations);
