import React from "react";
import { connect } from "react-redux";

import { Modal } from "../../../UI/Modal";

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
  }

  accept() {
    if (this.props.id) {
      this.props.accept(this.props.id);
    } else {
      this.props.accept();
    }
  }

  decline() {
    this.props.decline();
  }
  componentDidMount() {
    let today = new Date();
    today.setHours(this.props.bookinDetails);
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>{this.props.modalHeader}</span>
              <div
                onClick={this.decline.bind(this)}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div>{this.props.message}</div>
              {this.props.hintMessage && (
                <p style={{ margin: 0 }}>{this.props.hintMessage}</p>
              )}

              {this.props.showButtons ? (
                <div style={{ position: "relative", top: 25 }}>
                  <button
                    className={
                      this.props.disable || this.props.isLoading
                        ? "btn confirmation-modal-btn confirmation-submit-btn disabled"
                        : "btn confirmation-modal-btn confirmation-submit-btn"
                    }
                    onClick={this.accept.bind(this)}
                    disabled={this.props.isLoading}
                  >
                    {this.props.acceptText}
                  </button>
                  <button
                    className={
                      "btn confirmation-modal-btn confirmation-submit-btn"
                    }
                    onClick={this.decline.bind(this)}
                    disabled={
                      this.props.disable ? this.props.disable : false
                    }
                  >
                    {this.decline.bind(this)}
                    {this.props.declineText}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);
