import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import BookingDetailsModal from "../../Screens/Dashboard/modals/BookingDetailsModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { StylesConfig } from "react-select";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import {
  formatDate,
  formatedTime,
  getFormated12hTime,
  formatedTimeMin2
} from "../../helpers/DateHelpers";
import { toast } from "react-toastify";
import useOnClickOutside from "../../Screens/useOnClickOutside";
import { Link } from "react-router-dom";
import { DashboardApi } from "../../API/DashboardApi";
import store from "../../store";
const MapMarkerComponent = ({ marker, openModal }) => {
  const openModal2 = () => {
    openModal(marker);
  };
  var filterRef = useRef(null);
  useEffect(() => {}, []);
  useOnClickOutside(filterRef, () => {
    setDetailBox(false);
  });
  const [openDetail, setDetailBox] = useState(false);
  return (
    <>
      <img
        src="/img/icons/markerIcon.png"
        style={{ marginTop: "-60px", marginLeft: "-31px" }}
        onClick={() => setDetailBox(true)}
      ></img>
      {openDetail && (
        <div
          ref={filterRef}
          className="pin-tooltip"
          style={{
            transform: "translateZ(5px) translate(-66%, -155%)",
            position: "absolute"
          }}
          onClick={() => openModal2()}
        >
          <div className="employer">
            <div className="employer__left">
              <div className="employer__icon">
                <img src="static/img/anna.png" alt="" />
              </div>
              <div className="employer__info">
                <div className="employer__name">{marker.name}</div>
              </div>
            </div>
            <div className="employer__right">
              <div className="employer__stats">
                <div className="stats__item stats__item--blue">
                  <div className="stats__item-title">
                    {marker.credits}
                  </div>
                  <div className="stats__item-subtitle">credits</div>
                </div>
              </div>
            </div>
          </div>
          <div className="pin-tooltip__title">
            {marker.companyName}
          </div>
          <div className="pin-tooltip__title">
            {marker.data.space.venue.name}
          </div>
          <div className="overview__block">
            <div className="overview__block-row">
              <span>Check-in:</span>
              <span className="overview__earned">
                {getFormated12hTime(marker.start)}
              </span>
            </div>
            <div className="overview__block-row">
              <span>Est. Check-out:</span>
              <span className="overview__date">
                {getFormated12hTime(marker.end)}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

class EmployerDashboardMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      center: {
        lat: -37.813629,
        lng: 144.963058
      },
      markerId: 0,
      startTime: "",
      endTime: "",
      date: new Date(),
      selectedEmployee: null,
      myEmployees: [],
      isMenuOpen: false,
      timeError: "",
      markerMap: this.props.markers,
      timeStartOpen: false,
      timeEndOpen: false
    };
  }
  componentDidMount() {
    this.getCustomers();

    if (this.props.markers.length > 0) {
      this.setState({
        center: {
          lat: Number(this.props.markers[0].data.venue.space.lat),
          lng: Number(this.props.markers[0].data.space.venue.lng)
        },
        markerMap: this.props.markers
      });
    }
  }
  getCustomers = async () => {
    const token = this.props.token
      ? this.props.token
      : store.getState().auth.token;
    let response = await DashboardApi.GetMyCustomers(token);
    if (response.data && response.data.length > 0) {
      const updatedCustomers = await response.data.map(list => {
        list["label"] =
          list.invited_first_name || list.invited_last_name
            ? list.invited_first_name + " " + list.invited_last_name
            : list.full_name;
        list["value"] = list.id;
        return list;
      });

      this.setState({ myEmployees: updatedCustomers });
    }
  };
  componentDidUpdate(nextProps) {
    if (nextProps.markers.length !== this.props.markers.length) {
      this.setState({
        center: {
          lat: Number(this.props.markers[0].data.space.venue.lat),
          lng: Number(this.props.markers[0].data.space.venue.lng)
        },
        markerMap: this.props.markers
      });
    }
  }
  static defaultProps = {
    center: {
      lat: -37.813629,
      lng: 144.963058
    },
    zoom: 7
  };

  hideModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = id => {
    this.setState({ markerId: id });
    this.setState({ isModalOpen: true });
  };
  handleFromDate = event => {
    if (event) {
      this.setState({ date: event });
    }
  };
  handleFromTime = event => {
    if (event) {
      this.setState({ startTime: formatedTime(event._d) });
    }
    this.setState({
      timeError:
        this.state.endTime != "" ? "" : "Please select both times"
    });
  };
  handleToTime = event => {
    if (event) {
      this.setState({ endTime: formatedTime(event._d) });
    }
    this.setState({
      timeError:
        this.state.startTime != "" ? "" : "Please select both times"
    });
  };

  handleSubmit = () => {
    let filteredData = this.props.allBooking;
    if (this.state.selectedEmployee) {
      filteredData = filteredData.filter(v => {
        if (
          this.state.startTime != "" &&
          this.state.endTime != "" &&
          this.state.date != ""
        ) {
          return (
            formatedTimeMin2(
              v.data.start_time,
              selectedDate
            ).getTime() >=
              formatedTimeMin2(
                this.state.startTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(
              v.data.start_time,
              selectedDate
            ).getTime() <=
              formatedTimeMin2(
                this.state.endTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(v.data.end_time, selectedDate) >=
              formatedTimeMin2(
                this.state.startTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(
              v.data.end_time,
              selectedDate
            ).getTime() <=
              formatedTimeMin2(
                this.state.endTime,
                selectedDate
              ).getTime() &&
            v.data.start_date === selectedDate &&
            v.data.user.id === this.state.selectedEmployee.id
          );
        } else {
          if (v.data.start_date === selectedDate) {
            return (
              v.data.start_date === selectedDate &&
              v.data.user.id === this.state.selectedEmployee.id
            );
          } else {
            return v.data.user.id === this.state.selectedEmployee.id;
          }
        }
      });
    }
    if (this.state.startTime != "" && this.state.endTime != "") {
      this.setState({ timeError: this.state.endTime != "" });
    }

    if (!this.state.selectedEmployee) {
      var selectedDate = formatDate(this.state.date);
      filteredData = filteredData.filter(v => {
        if (
          this.state.startTime != "" &&
          this.state.endTime != "" &&
          this.state.date != ""
        ) {
          return (
            formatedTimeMin2(
              v.data.start_time,
              selectedDate
            ).getTime() >=
              formatedTimeMin2(
                this.state.startTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(
              v.data.start_time,
              selectedDate
            ).getTime() <=
              formatedTimeMin2(
                this.state.endTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(v.data.end_time, selectedDate) >=
              formatedTimeMin2(
                this.state.startTime,
                selectedDate
              ).getTime() &&
            formatedTimeMin2(
              v.data.end_time,
              selectedDate
            ).getTime() <=
              formatedTimeMin2(
                this.state.endTime,
                selectedDate
              ).getTime() &&
            v.data.start_date === selectedDate
          );
        } else {
          if (v.data.start_date === selectedDate) {
            return v.data.start_date === selectedDate;
          }
        }
      });
    }
    if (filteredData.length > 0) {
      this.setState({ markerMap: filteredData });
      this.setState({
        center: {
          lat: Number(filteredData[0].data.space.venue.lat),
          lng: Number(filteredData[0].data.space.venue.lng)
        }
      });
    } else {
      // this.setState({
      //   center: {
      //     lat: -37.813629,
      //     lng: 144.963058,
      //   },
      // });
      this.setState({ markerMap: [] });
      toast.error("There are no bookings at selected Date/Time");
    }
  };
  handleSelectEmployer = e => {
    this.setState({ selectedEmployee: e });
  };
  onMenuOpen = () => this.setState({ isMenuOpen: true });
  onMenuClose = () => this.setState({ isMenuOpen: false });
  selectStyles = {
    control: styles => ({
      ...styles,
      height: "100%",
      minHeight: "45px",
      border: "1px solid #a8c8d4"
    }),
    option: styles => {
      return {
        ...styles
      };
    }
  };
  render() {
    return (
      <>
        <h3 style={{ marginTop: "2rem" }}>Where Is My Team?</h3>
        <div
          className="emp-fields utilization-chart__wrapper"
          style={{ minHeight: "5rem" }}
        >
          <div className="col-md-2" style={{ marginBottom: 10 }}>
            <label
              style={{
                fontWeight: 500,
                display: "flex",
                fontSize: 12
              }}
            >
              Start Date:
            </label>
            <div
              className="input-container "
              style={{
                justifyContent: "space-between",
                marginLeft: 1,
                width: "100%"
              }}
            >
              {/* <label style={{ fontWeight: 500, fontSize: 10 ,marginTop:12,marginLeft:10}}>Date:</label> */}
              <DatePicker
                onChange={e => this.handleFromDate(e)}
                selected={this.state.date}
                className="empDateField"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div className="col-md-2">
            <label
              style={{
                fontWeight: 500,
                display: "flex",
                fontSize: 12
              }}
            >
              Start Time:
            </label>
            <div className="input-container ">
              <TimePicker
                showSecond={false}
                onChange={e => this.handleFromTime(e)}
                minuteStep={15}
                open={this.state.timeStartOpen}
                onOpen={() => this.setState({ timeStartOpen: true })}
                onClose={() =>
                  this.setState({ timeStartOpen: false })
                }
              />
              {!this.state.timeStartOpen && (
                <img
                  src="/img/icons/clock.svg"
                  style={{
                    width: "10%",
                    marginRight: "0.2rem",
                    cursor: "pointer"
                  }}
                  alt="From"
                  onClick={() => {
                    if (!this.state.timeStartOpen)
                      this.setState({ timeStartOpen: true });
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-2">
            <label
              style={{
                fontWeight: 500,
                display: "flex",
                fontSize: 12
              }}
            >
              End Time:
            </label>
            <div className="input-container ">
              <TimePicker
                showSecond={false}
                onChange={e => this.handleToTime(e)}
                minuteStep={15}
                open={this.state.timeEndOpen}
                onOpen={() => this.setState({ timeEndOpen: true })}
                onClose={() => this.setState({ timeEndOpen: false })}
              />
              {!this.state.timeEndOpen && (
                <img
                  src="/img/icons/clock.svg"
                  style={{
                    width: "10%",
                    marginRight: "0.2rem",
                    cursor: "pointer"
                  }}
                  alt="From"
                  onClick={() => {
                    if (!this.state.timeEndOpen)
                      this.setState({ timeEndOpen: true });
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-md-2">
            <label
              style={{
                fontWeight: 500,
                display: "flex",
                fontSize: 12
              }}
            >
              Employee Details:
            </label>
            <Select
              name="venue"
              styles={this.selectStyles}
              value={this.state.selectedEmployee}
              onChange={e => {
                this.handleSelectEmployer(e);
              }}
              placeholder="Employee"
              options={
                this.state.myEmployees &&
                this.state.myEmployees.length > 0
                  ? this.state.myEmployees
                  : []
              }
              isDisabled={this.state.myEmployees.length <= 0}
              noOptionsMessage={() => "No Employee Found"}
              onMenuClose={this.onMenuClose}
              onMenuOpen={this.onMenuOpen}
            />
          </div>
          <div className="col-2 " style={{ marginTop: "1.4rem" }}>
            <button
              type="submit"
              className={`btn2 btn-blue`}
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
        <span style={{ color: "red" }}>{this.state.timeError}</span>

        <div
          className="map"
          style={{
            height: "25rem",
            marginTop: "3rem",
            boxShadow: "0 9px 10px rgb(0 0 0 / 30%)"
          }}
        >
          <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "aizasyao9kchup2dbciazctcwasoreqnmmjj8dk"
              }}
              defaultCenter={{
                lat: this.state.center.lat,
                lng: this.state.center.lng
              }}
              defaultZoom={this.props.zoom}
              center={{
                lat: this.state.center.lat,
                lng: this.state.center.lng
              }}
              onChange={({ center, zoom, bounds, marginBounds }) => {
                this.setState({
                  center: { lat: center.lat, lng: center.lng }
                });
              }}
            >
              {this.state.markerMap.length > 0 &&
                this.state.markerMap.map((marker, index) => {
                  return (
                    <MapMarkerComponent
                      lat={Number(marker.data.space.venue.lat)}
                      lng={Number(marker.data.space.venue.lng)}
                      key={marker.id}
                      marker={marker}
                      openModal={this.openModal}
                      // position={"top-right"}
                    />
                  );
                })}
            </GoogleMapReact>
          </div>
          {this.state.isModalOpen ? (
            <BookingDetailsModal
              decline={this.hideModal}
              markerId={this.state.markerId}
              bookingDetails={this.props.markers}
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default EmployerDashboardMap;
