import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  venueId?: any;
  isVenueFlow: any;
  venueName?: string;
  // isSuggested?: boolean;
  // suggestedPrice?: Number;
}

const WorkspaceSideBar: FC<Props> = ({
  venueId,
  venueName,
  isVenueFlow
  // isSuggested,
  // suggestedPrice,
}) => {
  // useEffect(() => {}, [isSuggested]);

  return (
    <div className="col-lg-4">
      <div className="md-sidebar">
        <div className="name">
          <p style={{ paddingLeft: 40 }}>
            Venue:{" "}
            <span>
              {Object.keys(isVenueFlow).length > 0
                ? isVenueFlow.name
                : venueName}
            </span>
          </p>
          <div className="image">
            <img
              style={{
                position: "relative",
                top: venueName ? "-20px" : "-12px",
                left: "-25px"
              }}
              src="/img/ico-workspace-name.png"
              alt=""
            />
          </div>
        </div>
        {/* {isSuggested && (
          <div className="suggest">
            <div className="images">
              <img src="/img/ico-price.png" alt="" />
            </div>
            <div className="suggest-box">
              <p>Suggested price</p>
              <p>Suggested price based on similar workspaces around you:</p>
              <div className="aud-box">
                <p>
                  <span>${suggestedPrice} / hr</span>
                </p>
              </div>
              <p className="view">
                <Link
                  to={`/nearby-venues/${venueId}`}
                  style={{ color: "#31B5CE" }}
                  target="_blank"
                >
                  View other venues nearby
                </Link>
              </p>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default WorkspaceSideBar;
