import React from 'react';
import { Modal } from '../../../UI/Modal';
import { getFormated12hTime, formatDate } from "../../../helpers/DateHelpers"
class VenuesModal extends React.Component {
    constructor(props){
        super(props)
        this.state={
            activeItemIndex:1
        }
    }
    decline() {
        this.props.decline();
    }
    render() {
        return (
            <>
                <div style={{"padding":"0 60px","maxWidth":400,"margin":"0 auto","backgroundColor":"red"}}>
                    adsfasdfasdfasdf
                </div>
            </>
        );
    }
}

export default VenuesModal;