import { Reducer } from "react";
import { useSelector } from "react-redux";
import store, { VenueSearchState, AppState } from "../../store";

import { VenueSearchAction } from "./Actions";
const initialState: VenueSearchState = {
  venues: [],
  basic_search: {},
  searchId: 0,
  scrollState: 0
};

export const venueSearchReducer: Reducer<
  VenueSearchState,
  VenueSearchAction
> = (state: VenueSearchState = initialState, action) => {
  switch (action.type) {
    case "@@venue_search/SEARCH_RESULTS_LOADED":
      return { ...state, venues: action.venues };
    case "@@venue_search/BASIC_SEARCH":
      let data = state.basic_search;
      if (Object.keys(state.basic_search).length > 0) {
        let keyToCheck = Object.keys(action.venues);
        data[keyToCheck[0]] = action.venues[keyToCheck[0]];

        return {
          ...state,
          basic_search: data
        };
      }
      return { ...state, basic_search: action.venues };
    case "@@venue_search/BASIC_SEARCH_2":
      return { ...state, basic_search: action.venues };
    case "@@venue_search/BASIC_SEARCH_ID":
      return { ...state, searchId: action.id };
    case "@@venue_search/SAVE_LOADER_STATE":
      return { ...state, scrollState: action.position };
    default:
      return state;
  }
};
