import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";

const TermsAndCondition: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Dorpee-workspaces on demand"
        />
        <title>Dorpee Terms&Conditions</title>
      </Helmet>
      <div className="container pd-0">
        <div className="dashboard">
          <div className="dashboard__top"></div>
        </div>

        <div style={{ paddingBottom: 85 }}>
          <div className="utilization-chart__wrapper terms">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginBottom: 20
              }}
            >
              <img src="/img/imgs/logo.png" alt=""></img>
            </div>
            <div
              className="dashboard__title title-text-color"
              style={{
                justifyContent: "center",
                display: "flex",
                marginBottom: 20
              }}
            >
              Terms & Conditions
            </div>
            <p> Welcome to Dorpee!</p>
            <p>
              <p>
                These terms and conditions (<strong>Terms</strong>)
                are entered into between Dorpee Pty Ltd ABN 35 633 541
                876 (<strong>we, us or our</strong>) and you, together
                the <strong>Parties</strong> and each a{" "}
                <strong>Party</strong>. These Terms supplement and
                incorporate our privacy policy and website terms of
                use posted on the Platform.
              </p>
              <p>
                We provide a platform where venues (
                <strong>Venues</strong>) with workspace available
                within their venue (<strong>Workspace</strong>) and
                people who are seeking to book a workspace (
                <strong>Guests</strong>) can connect and transact (
                <strong>Platform</strong>). The Platform is available
                at{" "}
                <a
                  href="https://www.dorpee.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "blue",
                    textDecoration: "underline"
                  }}
                >
                  www.dorpee.com
                </a>{" "}
                and via other channels or addresses including our
                mobile application and our application programme
                interfaces.
              </p>
              <p>
                In these Terms, <strong>you</strong> means (as
                applicable) (1) the person or entity who has
                registered an account as a user of the Platform; or
                (2) the individual accessing or using the Platform.
              </p>
              <p>
                If you are using the Platform on behalf of your
                employer or a business entity, <strong>you</strong>,
                in your individual capacity, represent and warrant
                that you are authorized to act on behalf of your
                employer or the business entity and to bind the entity
                and the entity’s personnel to these Terms.
              </p>
            </p>
            <p className="title-text-color">1. Acceptance</p>
            <p className="subText">
              1.1. You accept these Terms by registering on the
              Platform or using the Platform or the services.
            </p>
            <p className="subText">
              1.2. You must be 18 years old to use the Platform.
            </p>
            <p className="subText">
              1.3. We may amend these Terms at any time, by providing
              written notice to you. By continuing to use the Platform
              after the notice or 30 days after notification
              (whichever date is earlier), you agree to the amended
              terms.
            </p>
            <p className="subText">
              1.4. If you access or download our mobile application
              from (1) the Apple App Store, you agree to any Usage
              Rules set forth in the App Store Terms of Service or (2)
              the Google Play Store, you agree to the Android, Google
              Inc. Terms and Conditions including the Google Apps
              Terms of Service.
            </p>
            <p className="subText">
              1.5. We may use Google Maps/Earth mapping services,
              including Google Maps API(s). Your use of Google
              Maps/Earth is subject to the Google Maps/Google Earth
              Additional Terms of Service.
            </p>
            <p className="title-text-color">2. Platform summary</p>
            <p className="subText">
              2.1 Our Platform is an online marketplace through which
              Venues may create listings for the availability and use
              of their Workspaces and Guests may browse and book such
              Workspaces. We provide the Platform to users (including
              hosting and maintaining the Platform), assist Venues and
              Guests to form contracts for the supply of services and
              process payments between Venues and Guests (together the{" "}
              <strong>Dorpee Services</strong>).
            </p>
            <p className="subText">
              <strong>
                2.2 You understand and agree that we only make
                available the Platform and the Dorpee Services. We are
                not party to any agreement entered into between a
                Venue and a Guest and we have no control over or
                liability for the conduct of Venues, Guests or any
                other users of the Platform.
              </strong>
            </p>
            <p className="subText">
              2.3 A Venue wanting to publish a listing must first
              create an Account on the Platform and can then post an
              accurate and complete description, with up-to-date
              photographs, of the Workspace/s they can provide
              including the type of Workspace being offered, the time
              and date the Workspace is available, the capacity,
              amenities and other related   attributes (Listing). As a
              Venue you may have the option to link your own calendar
              booking system with the calendar booking system in your
              Listings.
            </p>
            <p className="subText">
              2.4. Venues may choose to provide details in their
              Listing of the practices they are implementing to adhere
              to social distancing standards relating to Covid-19
            </p>
            <p className="subText">
              2.5. Venues may elect to perform an occupational health
              and safety (OHS) selfassessment through the Platform. If
              you answer all of the questions correctly, you may
              receive a shield on your profile and your Listings to
              indicate that you have completed this assessment.
              Receipt of a shield does not mean that we have in any
              way certified or assessed your Listings. We expect you
              to continue to maintain high standards of OHS compliance
              and you are entirely responsible for maintaining such
              standards. From time to time, we may request that you
              re-complete the same or a new OHS self-assessment to
              maintain the shield on your profile and Listings. If you
              fail to complete any assessment within a specified
              timeframe, you may lose your shield.{" "}
            </p>
            <p className="subText">
              2.6. A Guest wanting to book a Workspace must create an
              Account on the Platform to view and browse Listings.{" "}
            </p>
            <p className="subText">
              2.7. A Guest may book an available Workspace described
              in a Listing through the Platform (
              <strong>Booking</strong>). As Bookings are automatic,
              Venues must frequently monitor their in-Platform
              notifications and emails so they are aware of upcoming
              Bookings.
            </p>
            <p className="subText">
              2.8. Venues must include all additional terms and
              conditions, instructions, codes of conduct, or policies{" "}
              (<strong>Additional Terms</strong>) relating to their
              Workspaces in the Listing or must clearly state that
              there are Additional Terms for their Workspaces. By
              creating a Booking, a Guest is accepting and must abide
              by the Additional Terms.
            </p>
            <p className="title-text-color">3. Accounts</p>
            <p className="subText">
              3.1. You must register on the Platform and create an
              account (<strong>Account</strong>) to access the
              Platform’s features.
            </p>
            <p className="subText">
              3.2. You may only have 1 Account which you can use as
              both a Venue and a Guest on the Platform. Guests may be
              employers of a business, and employers may invite their
              employees to create an Account on the Platform. While
              employees may be Guests in their own right, employers
              must take responsibility for all of their employees who
              use Workspaces.
            </p>
            <p className="subText">
              3.3. You must provide basic information when registering
              for an Account including your business name (if
              applicable), contact name and email address and you must
              choose a password.
            </p>
            <p className="subText">
              3.4. Once you have registered an Account, your Account
              information will be used to create a profile which you
              may then curate
            </p>
            <p className="subText">
              3.5. You agree to provide accurate, current and complete
              information during the registration process and
              regularly update such information to keep it accurate,
              current and complete.{" "}
            </p>
            <p className="subText">
              3.6. Your Account is personal and you must not transfer
              it to others, except with our written permission. If a
              legal entity such as a company has created an account,
              you, the legal entity, are the Account owner, and
              regardless of any change in any contact details, you
              will remain responsible for your Account as set out in
              these Terms. If you wish to change the Account owner,
              you must provide us with a written request to transfer
              the ownership of the Account to the incoming party,
              which must also include the incoming party’s written
              consent to take over full responsibility for the
              Account.
            </p>
            <p className="subText">
              3.7. You are responsible for keeping your Account
              details and your password confidential and you will be
              liable for all activity on your Account, including
              Bookings or Listings made using your Account details.
              You agree to immediately notify us of any unauthorized
              use of your Account.
            </p>
            <p className="title-text-color">4. Communication</p>
            <p className="subText">
              4.1. We may contact you via the Platform using
              in-Account notifications, or via off-Platform
              communication channels, such as text message or email.
              Venues and Guests can communicate privately using our
              private messaging service once a Booking has been made.
              Members being both Venue and Guest must not use the
              contact details to organise the provision of services
              off the Platform.
            </p>
            <p className="title-text-color">5. Payment</p>
            <p className="subText">
              5.1. It is free to register an Account on the Platform,
              however a Guest must have a Plan or have purchased
              Credits in order to make a Booking.
            </p>
            <p className="subText">
              5.2. When you create an Account as a Guest, you may
              select a plan (<strong>Plan</strong>) in order to make a
              Booking. Guests who are employees of an employer who has
              a Plan will, when invited, have access to the features
              of their employer’s Plan. As a Guest, you may choose
              between different tiers of Plans and different Plan
              periods (monthly or annual) as set out on our Platform.
              Payment for Plans is discussed below.
            </p>
            <p className="subText">
              5.3. When you create an Account as a Venue, you may also
              add your Listing for a Workspace and elect to establish
              a fee for your Workspace which we will convert to
              Credits for our Guests to purchase (
              <strong>Listing Fee</strong>).
            </p>
            <p className="subText">
              5.4. As a Guest, when you purchase a Plan, your first
              Plan period will be billed immediately. Unless we
              expressly communicate otherwise, your Plan will
              automatically renew at the end of the Plan period for
              another Plan period of the same length and you will be
              billed around the same date each month throughout your
              Plan period. Your billing day could change, for example
              if payment is unable to be processed or if your Plan
              began on a day not contained in a given month. We
              reserve the right to change the timing of our billing
              (and if we do, we’ll make adjustments to the amount we
              charge, as appropriate). Your renewal date may change
              due to the change in your subscription.
            </p>
            <p className="subText">
              5.5. In consideration for providing the Platform, we
              will charge our service fees (including any third-party
              payment processing fees) as set out on the Platform (
              <strong>Service Fee</strong>). To the extent permitted
              by law, our Service Fee is non-refundable.
            </p>
            <p className="subText">
              5.6. If you are a Venue you appoint us as your limited
              payment collection agent solely for the purpose of
              accepting the Listing Fees from the Guest. You agree
              that we will not be required to pay you any amount until
              we have received the Listing Fees from the relevant
              Guest, that we will deduct our Service Fee from any
              Listing Fees we receive and that we may grant refunds to
              Guests in accordance with these Terms.
            </p>
            <p className="subText">
              5.7. As a Guest, depending on the Plan tier you choose
              and purchase, you will be allocated credits to be used
              solely to make a Booking on the Platform during your
              Plan period (<strong>Credits</strong>). You may also be
              able to purchase Credits without purchasing a Plan. You
              can choose how you use your Credit across the various
              Listings. Credits are non-transferable and will expire
              at the end of each Plan period, meaning that any Credit
              you don’t use during your Plan period will not roll over
              into future months, unless we expressly communicate
              otherwise. Expiry dates for Credits are set out on the
              Platform and Credits may not be used on the Platform
              after they have expired.
            </p>
            <p style={{ textDecorationLine: "underline" }}>
              Payment for Plans
            </p>
            <p className="subText">
              5.8. Once you have created an Account and chosen a Plan,
              you agree to pay the Plan fee set out on the Platform (
              <strong>Plan Fee</strong>) to use certain features on
              the Platform and benefit from your Plan.
            </p>
            <p className="subText">
              5.9. To the extent permitted by law, the Plan Fee is
              non-refundable and non-cancellable once paid.
            </p>
            <p className="subText">
              5.10. The Plan Fee will be charged upfront on the
              calendar day corresponding to when you created your
              Account (<strong>Payment Date</strong>). In some cases,
              your Payment Date may change, for example, if payment is
              unable to be processed or if your Plan began on a day
              not contained in a given month.
            </p>
            <p className="subText">
              5.11. Month to month Plans automatically continue until
              cancelled in accordance with the cancellation clause
              below.
            </p>
            <p className="subText">
              5.12. If you do not cancel your annual Plan in
              accordance with the cancellation clause below, it will
              be renewed for another year at the end of your current
              Plan term. We will notify you 30 days prior to the end
              of the current Plan term.
            </p>
            <p className="subText">
              5.13. We may modify our Plans and the Plan Fees from
              time to time. For month to month Plans, any price
              changes will apply to you no earlier than 30 days
              following notice to you. For annual Plans, the price
              changes will apply to you no earlier than the start of
              your renewed Plan. The notice may be provided at any
              time via email or via notification to your Account. If
              you do not agree to the price change, you may cancel
              your Plan in accordance with the cancellation clause
              below.
            </p>
            <p style={{ textDecorationLine: "underline" }}>
              Upgrading or downgrading your Plan
            </p>
            <p className="subText">
              5.14. You may upgrade or downgrade your Plan to another
              tier at any time in your Account page or by sending us
              an email. The payment method linked to your Account will
              automatically be charged the Plan Fee for your new Plan
              tier on the Payment Date on which the upgrade or
              downgrade becomes effective.
            </p>
            <p className="subText">
              5.15. The upgrade or downgrade will apply to the next
              month if you upgrade or downgrade your Plan at least 7
              business days before the next Payment Date.
            </p>
            <p className="subText">
              5.16. If you upgrade or downgrade less than 7 business
              days before the next Payment Date, the upgrade or
              downgrade will become effective for the following month.
            </p>
            <p className="subText">
              5.17. If your need to upgrade is urgent, please send us
              an email and we may upgrade your Plan prior to the
              Payment Date on which the upgrade was due to become
              effective. We may organise for the payment method linked
              to your Account to be charged the pro-rata Plan Fee for
              your new Plan tier.
            </p>
            <p style={{ textDecorationLine: "underline" }}>
              Cancelling Plans
            </p>
            <p className="subText">
              5.18. You may cancel your Plan at any time by giving us
              1 weeks’ notice before your billing date in your Account
              page (<strong>Notice</strong>).
            </p>
            <p className="subText">
              5.19. The cancellation date will be effective one months
              from the date of your Notice (
              <strong>Cancellation Date</strong>). If you are a Guest,
              you have up to the Cancellation Date to use your
              remaining Credit. After the Cancellation Date, we will
              cancel any existing Bookings and you will lose any
              Credit and other amounts paid. Where you are a Venue, we
              will cancel any existing Bookings and refund the
              relevant Guest.
            </p>
            <p style={{ textDecorationLine: "underline" }}>General</p>
            <p className="subText">
              5.20. Payment may be made in certain currencies as set
              out on the Platform. We provide a number of payment
              methods on the Platform, including our third party
              payment processor, currently Stripe. The payment method
              you choose may be subject to additional terms and
              conditions imposed by the applicable third party payment
              processor. By making payment through a third party
              payment processor, you accept the applicable terms and
              conditions.
            </p>
            <p className="subText">
              5.21. We may from time to time issue promotional
              discount codes for the Platform. To claim the discount
              as a Guest, you must enter the promotional discount code
              on the Platform before your next billing date or when
              purchasing Credits. The conditions of use relating to
              promotional discount codes will be specified on the
              Platform at the time they are issued.
            </p>
            <p className="subText">
              5.22. In the absence of fraud or mistake, all payments
              made are final. If you make a payment by debit card or
              credit card, you warrant that the information you
              provide to us is true and complete, that you are
              authorized to use the debit card or credit card to make
              the payment, that your payment will be honoured by your
              card issuer, and that you will maintain sufficient funds
              in your account to cover the payment.
            </p>
            <p className="title-text-color">
              6. Refunds and Cancellation Policy
            </p>
            <p className="subText">
              6.1. The cancellation of a Booking on this Platform is
              subject to the following clauses.
            </p>
            <p className="subText">
              6.2. Guests must provide sufficient reasonable notice to
              Venues when cancelling a Booking. Cancelling last
              minute, or not attending a Booking, may result in a
              deduction of Guests’ Credits.
            </p>
            <p className="subText">
              6.3. Venues must use all efforts to notify Guests of any
              cancellation with ample time before the Booking start
              time. We monitor last minute cancellations and frequent
              last-minute cancellations by Venues may result in the
              suspension or termination of your Account.
            </p>
            <p className="subText">
              6.4. For disputes between Guests and Venues, we
              encourage the parties to attempt to resolve disputes
              (including claims for returns or refunds) with the other
              party directly and in good faith, either on the Platform
              or through external communication methods. In the event
              that a dispute cannot be resolved through these means,
              the parties may choose to resolve the dispute in any
              manner agreed between the Parties or otherwise in
              accordance with applicable laws.
            </p>
            <p style={{ textDecorationLine: "underline" }}>
              Credits and Subscriptions
            </p>
            <p className="subText">
              6.5. Generally (and without limiting our obligations or
              your rights under law, including the Australian Consumer
              Law), our fees (including the monthly membership fee and
              any other fees) are nonrefundable, unless we
              specifically communicate otherwise at the time of
              purchase. However, we will provide a refund to
              subscribers for their current prepaid Subscription Cycle
              only in the following circumstances: (i) if you are
              cancelling your subscription and request a refund within
              5 days of the date of your first payment for your
              subscription or (ii) if your subscription is cancelled
              prior to the end of a Subscription Cycle for which you
              have incurred a charge, due to your relocation,
              disability or death; provided, however, in each case we
              reserve the right to charge a fee to cover the cost of
              any class or other services or products you may have
              used or received prior to your cancellation and to ask
              for proof of such changed condition, to the extent
              permitted by law. WE DO NOT PROVIDE REFUNDS OR MAKE
              GOODS FOR ANY PRIOR MONTHS INCLUDING FOR UNUSED CREDITS
              OR BOOKINGS
            </p>
            <p className="title-text-color">7. Reviews</p>
            <p className="subText">
              7.1. Guests may review their experience with the Venue
              on the Platform, including the Workspace (
              <strong>Review</strong>).
            </p>
            <p className="subText">
              7.2. Reviews can be viewed by any user and will remain
              viewable until the relevant Venue’s Account is removed
              or terminated.
            </p>
            <p className="subText">
              7.3. You agree to provide true, fair and accurate
              information in your Review. If we consider that the
              Review is untrue, unfair, inaccurate, offensive or
              inappropriate, we may delete the Review or ban you from
              posting the Review. We do not undertake to review each
              Review. To the maximum extent permitted by law, we are
              not responsible for the content of any Reviews.
            </p>
            <p className="subText">
              7.4. You can write a Review about a Venue if you have
              had an experience with that Venue, which means that (1)
              you have engaged the Venue through the Platform; or (2)
              you can otherwise document your interaction with the
              Venue in relation to the Platform, including via
              correspondence (collectively referred to as an{" "}
              <strong>Experience</strong>).
            </p>
            <p className="subText">
              7.5. You may not write a review about a Venue if that
              Venue is a business you have previously owned, currently
              own, or which an immediate family member currently owns,
              or if you are an executive or employee of that Venue, or
              work for the Venue. Similarly, you may not write a
              Review about a direct competitor to the Venue you own,
              are employed by or work for.{" "}
            </p>
            <p className="subText">
              7.6. Your Experience must have occurred in the 12 months
              prior to you writing a Review.
            </p>
            <p className="subText">
              7.7. You may only write about your own Experience. You
              are not permitted to write a Review about somebody
              else’s experience, such as that of a family member or
              friend.
            </p>
            <p className="subText">
              7.8. You are encouraged to be specific and factual in
              your Reviews. If you have been offered an incentive by a
              Venue to write a Review, you should include information
              about this in your Review. Incentives include the Venue
              offering you a gift, reward, discount or advantage for
              writing a Review about the Venue on the Platform.
            </p>
            <p className="title-text-color">8. Content</p>
            <p className="subText">
              8.1. We may allow you to (1) post, upload, publish, send
              or receive relevant content and information and Reviews{" "}
              (<strong>User Content</strong>) on or through our
              Platform; and/or (2) access and view User Content and
              the content and information we make available on the
              Platform (<strong>Dorpee Content</strong> and together
              with User Content, <strong>Content</strong>).
            </p>
            <p className="subText">
              8.2. Unless otherwise indicated, we own or license all
              rights, title and interest (including Intellectual
              Property Rights) in our Platform and all of the Content.
              Your use of our Platform and your use of and access to
              any Content does not grant or transfer to you any
              rights, title or interest in relation to our Platform or
              the Content.
            </p>
            <p className="subText">
              8.3. You must not, without the prior written consent of
              ourselves or the owner of the Content (as applicable) or
              except as expressly permitted by these Terms, (1) copy
              or use, in whole or in part, any Content; (2) reproduce,
              reverse engineer, retransmit, distribute, disseminate,
              sell, publish, broadcast or circulate any Content to any
              third party; or (3) breach any Intellectual Property
              Rights connected with our Platform, including by
              altering or modifying any of the Content, causing any of
              the Content to be framed or embedded in another website,
              or creating derivative works from the Content.
            </p>
            <p className="subText">
              8.4. Subject to your compliance with these Terms, we
              grant you a personal, non-exclusive, royalty-free,
              revocable, worldwide, non-transferable licence to
              download and use our Platform on your personal device(s)
              and access and view any Content in accordance with these
              Terms. All other uses are prohibited without our prior
              written consent.
            </p>
            <p className="subText">
              8.5. You grant us a non-exclusive, perpetual,
              irrevocable, worldwide, royalty free, sublicensable and
              transferable right and licence to use, view, distribute,
              communicate, copy, store, modify and exploit in any
              manner the User Content to provide the Platform and
              promote the Platform in any media or promotional
              material.
            </p>
            <p className="subText">
              8.6. You agree that you are solely responsible for all
              User Content that you make available on or through our
              Platform. You represent and warrant that (1) you are
              either the sole and exclusive owner of all User Content
              or you have all rights, licences, consents and releases
              that are necessary to grant to us the rights in such
              User Content as contemplated by these Terms; and (2)
              neither the User Content nor the posting, uploading,
              publication, sending or receiving of the User Content or
              our use of the User Content on, through or by means of
              our Platform will infringe, misappropriate or violate a
              third party’s Intellectual Property Rights, or rights of
              publicity or privacy, or result in the violation of any
              applicable law or regulation.
            </p>
            <p className="subText">
              8.7. Despite anything to the contrary, to the maximum
              extent permitted by law, you agree to indemnify us and
              hold us harmless in respect of any Liability that we may
              suffer, incur or otherwise become liable for, arising
              from or in connection with the User Content you make
              available on or through the Platform, including as a
              result of an Intellectual Property Breach.
            </p>
            <p className="subText">
              8.8. The Content is not comprehensive and is for general
              information purposes only. It does not take into account
              your specific needs, objectives or circumstances, and is
              not advice. While we use reasonable attempts to ensure
              the accuracy and completeness of the Content, to the
              extent permitted by law, we make no representation or
              warranty regarding the Content. The Content is subject
              to change without notice. We do not undertake to keep
              our Platform up-to-date and we are not liable if any
              Content is inaccurate or out-of-date.
            </p>
            <p className="title-text-color">9. Warranties</p>
            <p className="subText">
              9.1. You represent, warrant and agree that:
              <p className="subText" style={{ marginTop: "15px" }}>
                9.1.1. you will not use our Platform, including the
                Content, in any way that competes with our business;
              </p>
              <p className="subText">
                9.1.2. you will not make or arrange a Booking
                independently of the Platform, to circumvent payment
                of any fees or for any other reason;
              </p>
              <p className="subText">
                9.1.3. there are no legal restrictions preventing you
                from entering into these Terms;
              </p>
              <p className="subText">
                9.1.4. all information and documentation that you
                provide to us in connection with these Terms is true,
                correct and complete;
              </p>
              <p className="subText">
                9.1.5. you have not relied on any representations or
                warranties made by us in relation to the Platform
                (including as to whether the Platform is or will be
                fit or suitable for your particular purposes), unless
                expressly stipulated in these Terms;
              </p>
              <p className="subText">
                9.1.6. you will be responsible for the use of any part
                of the Platform, and you must ensure that no person
                uses any part of the Platform: (1) to break any law or
                infringe any person’s rights (including Intellectual
                Property Rights) (2) to transmit, publish or
                communicate material that is defamatory, offensive,
                abusive, indecent, menacing or unwanted; or (3) in any
                way that damages, interferes with or interrupts the
                supply of the Platform; and
              </p>
              <p className="subText">
                9.1.7. where you are a Venue: you are responsible for
                complying with all laws, rules and regulations which
                apply to providing the Workspace in your Listings; you
                have sufficient rights in and to the Workspace and
                your Listing will not violate or result in the breach
                of any agreement between you and any third party,
                including any lease agreement or other agreement
                related to the Venue and Workspace; the Workspace is
                free of any known conditions or defects that would
                pose a hazard or risk the safety of a Guest; the
                Workspace is clean and ready to use at the start of
                each Booking period; the Workspace is in a safe
                condition and in compliance with the law any
                applicable building requirements including but not
                limited to social distancing measures for any known
                infectious disease.
              </p>
            </p>
            <p className="title-text-color">
              10. Australian Consumer Law
            </p>
            <p className="subText">
              10.1. Certain legislation, including the Australian
              Consumer Law (<strong>ACL</strong>) in the Competition
              and Consumer Act 2010 (Cth), and similar consumer
              protection laws and regulations, may confer you with
              rights, warranties, guarantees and remedies relating to
              the provision of the Platform by us to you which cannot
              be excluded, restricted or modified (
              <strong>Statutory Rights</strong>).
              <p className="subText" style={{ marginTop: "15px" }}>
                10.1.1. If the ACL applies to you as a consumer,
                nothing in these Terms excludes your Statutory Rights
                as a consumer under the ACL. You agree that our
                Liability for the Platform provided to an entity
                defined as a consumer under the ACL is governed solely
                by the ACL and these Terms.
              </p>
              <p className="subText">
                10.1.2. Subject to your Statutory Rights, we exclude
                all express and implied warranties, and all material,
                work and services (including the Platform) are
                provided to you without warranties of any kind, either
                express or implied, whether in statute, at law or on
                any other basis.
              </p>
              <p className="subText">
                10.1.3. As a Guest, the goods and services provided by
                a Venue may also confer on you certain rights under
                the ACL.
              </p>
              <p className="subText">
                10.1.4. This clause will survive the termination or
                expiry of these Terms.
              </p>
            </p>
            <p className="title-text-color">
              11. Exclusions to liability
            </p>
            <p className="subText">
              11.1. Despite anything to the contrary, to the maximum
              extent permitted by law, we will not be liable for, and
              you waive and release us from and against, any Liability
              caused or contributed to by, arising from or connected
              with:
            </p>
            <p className="subText">
              11.2. your or your personnel’s acts or omissions;
            </p>
            <p className="subText">
              11.3. any use or application of the Dorpee Services by a
              person or entity other than you, or other than as
              reasonably contemplated by these Terms;
            </p>
            <p className="subText">
              11.4. any aspect of the Guest and Venue interaction
              including the goods and/or services offered by the
              Venue, the description of the goods and/or services
              requested or offered, or any advice provided;
            </p>
            <p className="subText">
              11.5. any third parties or any goods and services
              provided by third parties, including customers, end
              users, suppliers, transportation or logistics providers
              or other subcontractors which the provision of the
              Platform may be contingent on, or impacted by;
            </p>
            <p className="subText">
              11.6. the Dorpee Services being unavailable, or any
              delay in us providing the Dorpee Services to you, for
              whatever reason; and/or
            </p>
            <p className="subText">
              11.7. any event outside of our reasonable control,
              including a Force Majeure Event.
            </p>
            <p className="subText">
              11.8. This clause will survive the termination or expiry
              of these Terms.
            </p>
            <p className="title-text-color">
              12. Limitations on liability
            </p>
            <p className="subText">
              12.1. Despite anything to the contrary, to the maximum
              extent permitted by law:
            </p>
            <p className="subText">
              12.2. we will not be liable for Consequential Loss;
            </p>
            <p className="subText">
              12.3. each Party’s liability for any Liability under
              these Terms will be reduced proportionately to the
              extent the relevant Liability was caused or contributed
              to by the acts or omissions of the other Party (or any
              of that Party’s personnel); and
            </p>
            <p className="subText">
              12.4. our aggregate liability for any Liability arising
              from or in connection with these Terms will be limited
              to us resupplying the Dorpee Services to you or, in our
              sole discretion, to us repaying you the amount of the
              Service Fees paid by you to us in respect of the supply
              of the Dorpee Services to which the Liability relates,
              or where there are no Service Fees paid, $50.
            </p>
            <p className="subText">
              12.5. This clause will survive the termination or expiry
              of these Terms.
            </p>
            <p className="title-text-color">13. Termination</p>
            <p className="subText">
              13.1. Your Account and these Terms may be terminated by
              you at any time, using the ‘cancel Account’
              functionality (or similar) in your in the Account page
              section of your Account settings.
              <br />
              <p className="subText" style={{ marginTop: "15px" }}>
                13.1.1. We may suspend your Account or terminate these
                Terms immediately upon written notice to you, if:
              </p>
              <p className="subText">
                13.1.2. you or any of your personnel breach any
                provision of these Terms and that breach has not been
                remedied within 10 business days of being notified by
                us;
              </p>
              <p className="subText">
                13.1.3. as a Venue you repeatedly receive poor or
                negative reviews;
              </p>
              <p className="subText">
                13.1.4. there is any reason outside our control which
                has the effect of compromising our ability to provide
                the Dorpee Services; or
              </p>
              <p className="subText">
                13.1.5. you are unable to pay your debts as they fall
                due.
              </p>
              <p className="subText">
                13.1.6. These Terms will terminate immediately upon
                written notice by you, if we:
              </p>
              <p className="subText">
                13.1.7. are in breach of a material term of these
                Terms, and that breach has not been remedied within 10
                business days of being notified by you; or
              </p>
              <p className="subText">
                13.1.8. are unable to pay our debts as they fall due.
              </p>
              <p className="subText">
                13.1.9. Upon expiry or termination of these Terms:
              </p>
              <p className="subText">
                13.1.10. we will remove your access to the Platform;
              </p>
              <p className="subText">
                13.1.11. we will immediately cease providing the
                Dorpee Services;
              </p>
              <p className="subText">
                13.1.12. you agree that other than where termination
                is due any payments (including payments for unused
                Credits) made by you to us are not refundable to you;
                and
              </p>
              <p className="subText">
                13.1.13. where you are a Guest, we will cancel any
                existing Bookings and you will lose any Credit and
                other amounts paid. Where you are a Venue, we will
                cancel any existing Bookings and refund the relevant
                Guests. Where you are a Guest, you will lose all
                Credit and other amounts paid.
              </p>
              <p className="subText">
                13.1.14. Termination of these Terms will not affect
                any rights or liabilities that a Party has accrued
                under it.
              </p>
              <p className="subText">
                13.1.15. This clause will survive the termination or
                expiry of these Terms.
              </p>
            </p>
            <p className="title-text-color">14. Venue insurance</p>
            <p className="subText">
              14.1. As a Venue, we may request that you provide
              evidence of your insurance in relation to the Venue
              including but not limited to insurance policies for
              public risk liability and property and content. Where we
              do so, we are not confirming that the insurance you have
              is sufficient or suitable for the goods and/or services
              you choose to provide to Guests. If we do not ask you to
              provide evidence of insurance this does not indicate
              that we believe you do not require insurance. You
              acknowledge and agree it is your responsibility to make
              your own investigations and receive professional advice
              on the insurance you require.
            </p>
            <p className="title-text-color">
              15. Notice regarding Apple
            </p>
            <p className="subText">
              15.1. To the extent that you are using or accessing our
              Platform on an iOS device, you further acknowledge and
              agree to the terms of this clause. You acknowledge that
              these Terms are between you and us only, not with Apple
              Inc. (Apple), and Apple is not responsible for the
              Platform and any content available on the Platform.
            </p>
            <p className="subText">
              15.2. Apple has no obligation to furnish you with any
              maintenance and support services with respect to our
              Platform.
            </p>
            <p className="subText">
              15.3. If our mobile application fails to conform to any
              applicable warranty, you may notify Apple and Apple will
              refund the purchase price of the mobile application to
              you. To the maximum extent permitted by applicable law,
              Apple will have no other warranty obligation whatsoever
              with respect to the mobile application and any other
              claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty
              will be our responsibility.
            </p>
            <p className="subText">
              15.4. Apple is not responsible for addressing any claims
              by you or any third party relating to our mobile
              application or your use of our mobile application,
              including but not limited to (1) product liability
              claims; (2) any claim that our mobile application fails
              to conform to any applicable legal or regulatory
              requirement; and (3) claims arising under consumer
              protection or similar legislation.
            </p>
            <p className="subText">
              15.5. Apple is not responsible for the investigation,
              defence, settlement and discharge of any third-party
              claim that our mobile application infringes that third
              party’s intellectual property rights.
            </p>
            <p className="subText">
              15.6. You agree to comply with any applicable
              third-party terms when using our mobile application,
              including any Usage Rules set forth in the Apple App
              Store Agreement of Service.
            </p>
            <p className="subText">
              15.7. Apple and Apple’s subsidiaries are third-party
              beneficiaries of these Terms, and upon your acceptance
              of these Terms, Apple will have the right (and will be
              deemed to have accepted the right) to enforce these
              Terms against you as a third-party beneficiary of these
              Terms
            </p>
            <p className="subText">
              15.8. You hereby represent and warrant that (1) you are
              not located in a country that is subject to a U.S.
              Government embargo, or that has been designated by the
              U.S. Government as a "terrorist supporting" country; and
              (2) you are not listed on any U.S. Government list of
              prohibited or restricted parties.
            </p>
            <p className="title-text-color">16. General</p>
            <p className="subText">
              16.1. <strong>Assignment: </strong>You must not assign
              or deal with the whole or any part of your rights or
              obligations under these Terms without our prior written
              consent.
            </p>
            <p className="subText">
              16.2. <strong>Disputes: </strong>In relation to a
              dispute, controversy or claim arising from, or in
              connection with, these Terms (including any question
              regarding its existence, validity or termination) (
              <strong>Dispute</strong>) between a Guest and us, or a
              Venue and us, a Party may not commence court proceedings
              relating to a Dispute without first meeting with a
              senior representative of the other Party to seek (in
              good faith) to resolve the Dispute. If the Parties
              cannot agree how to resolve the Dispute at that initial
              meeting, either Party may refer the matter to a
              mediator. If the Parties cannot agree on who the
              mediator should be, either Party may ask the Law
              Institute of Victoria, to appoint a mediator. The
              mediator will decide the time, place and rules for
              mediation. The Parties agree to attend the mediation in
              good faith, to seek to resolve the Dispute. The costs of
              the mediation will be shared equally between the
              Parties. Nothing in this clause will operate to prevent
              a Party from seeking urgent injunctive or equitable
              relief from a court of appropriate jurisdiction.
            </p>
            <p className="subText">
              16.3. <strong>Force Majeure: </strong>We will not be
              liable for any delay or failure to perform our
              obligations under these Terms if such delay is due to
              any circumstance beyond our reasonable control,
              including a Force Majeure Event.
            </p>
            <p className="subText">
              16.4. <strong>Governing law: </strong>These Terms
              governed by the laws of Australia. Each Party
              irrevocably and unconditionally submits to the exclusive
              jurisdiction of the courts operating in Australia and
              any courts entitled to hear appeals from those courts
              and waives any right to object to proceedings being
              brought in those courts.
            </p>
            <p className="subText">
              16.5. <strong>Notices: </strong>Any notice given under
              these Terms must be in writing addressed to us at the
              address at the end of these Terms or to you at the
              address in your Account. Any notice may be sent by
              standard post or email, and will be deemed to have been
              served on the expiry of 48 hours in the case of post, or
              at the time of transmission in the case of transmission
              by email.
            </p>
            <p className="subText">
              16.6. <strong>Relationship of Parties: </strong>These
              Terms are not intended to create a partnership, joint
              venture, employment or agency relationship (except to
              the extent set out in the Payment clause as limited
              payment collection agent) between the Parties.
            </p>
            <p className="subText">
              16.7. <strong>Severance: </strong>If a provision of
              these Terms is held to be void, invalid, illegal or
              unenforceable, that provision is to be read down as
              narrowly as necessary to allow it to be valid or
              enforceable, failing which, that provision (or that part
              of that provision) will be severed from these Terms
              without affecting the validity or enforceability of the
              remainder of that provision or the other provisions in
              these Terms.
            </p>
            <p className="title-text-color">17. Definitions</p>
            <p className="subText">
              17.1. <strong>Consequential Loss </strong>includes any
              consequential loss, indirect loss, real or anticipated
              loss of profit, loss of benefit, loss of revenue, loss
              of business, loss of goodwill, loss of opportunity, loss
              of savings, loss of reputation, loss of use and/or loss
              or corruption of data, whether under statute, contract,
              equity, tort (including negligence), indemnity or
              otherwise.
            </p>
            <p className="subText">
              17.2. <strong>Force Majeure Event </strong>means any
              one, or combination of law or government regulation
              which comes into force or any act of God, flood, war,
              revolution, civil commotion, political disturbance, fire
              explosion, ionizing radiation, contamination by
              radioactivity, nuclear, chemical or biological
              contamination, disease, epidemic, pandemic (including in
              relation to the coronavirus, severe acute respiratory
              syndrome coronavirus 2, or any mutation thereof),
              government sanctioned shutdown, global economic downturn
              or any other cause whatsoever over which a Party has no
              control.
            </p>
            <p className="subText">
              17.3. <strong>Intellectual Property </strong>means any
              domain names, know-how, inventions, processes, trade
              secrets or confidential information; or circuit layouts,
              software, computer programs, databases or source codes,
              including any application, or right to apply, for
              registration of, and any improvements, enhancements or
              modifications of, the foregoing.
            </p>
            <p className="subText">
              17.4. <strong>Intellectual Property Rights </strong>
              means for the duration of the rights in any part of the
              world, any industrial or intellectual property rights,
              whether registrable or not, including in respect of
              Intellectual Property.
            </p>
            <p className="subText">
              17.5. <strong>Intellectual Property Breach </strong>
              means any breach by you (or any of your Personnel) of
              any of our Intellectual Property Rights (or any breaches
              of third party rights including any Intellectual
              Property Rights of third parties).
            </p>
            <p className="subText">
              17.6. <strong>Liability </strong>means any expense,
              cost, liability, loss, damage, claim, notice,
              entitlement, investigation, demand, proceeding or
              judgment (whether under statute, contract, equity, tort
              (including negligence), indemnity or otherwise),
              howsoever arising, whether direct or indirect and/or
              whether present, unascertained, future or contingent and
              whether involving a third party or a party to these
              Terms or otherwise.
            </p>
            <p className="subText">
              17.7. <strong>Venue </strong>means the physical premises
              which the Workspace is located at and known by a street
              address.
            </p>
            <p className="subText">
              17.8. <strong>Workspace </strong>means the designated
              area that is available for use as described in the
              Listing provided by the Venue.
            </p>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <strong>
                For any questions or notices, please contact us at:
              </strong>
              Dorpee Pty Ltd ABN 35 633 541 876
              <strong>Email:</strong> hello@dorpee.com
              <strong>Last update:</strong> 5 June 2020
              <p>
                ©
                <a
                  href="https://legalvision.com.au/ "
                  style={{
                    color: "blue",
                    textDecoration: "underline"
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LegalVision{" "}
                </a>
                <strong>ILP Pty Ltd </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
