import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { Venue as VenueDto, Workspace } from "../../../API/VenueApi";
import { Carousel } from "react-responsive-carousel";
import ViewMoreButton from "./ViewMoreButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "../../../Screens/venue-style.css";
import _ from "lodash";
import WorkspacesAssets from "./WorkspacesAssets";
import AvailabilityCalendarModal from "../../Dashboard/modals/AvailabilityCalendar";
import VaccinationRequirementModal from "../../Book/VaccinationRequiremnetModal";

const WorkspacesItem = ({
  workspace,
  venueName,
  status,
  vUserId,
  disabled,
  vaccinationStatus
}: {
  workspace: Workspace;
  venueName: string;
  vUserId: any;
  status?: string;
  disabled?: boolean;
  vaccinationStatus?: any;
}) => {
  const user = useSelector((state: AppState) => state.auth.user!!);
  const role = useSelector((state: AppState) => state.auth.userRoles);
  const history = useHistory();
  const [isPlanFree, setIsPlanFree] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [price, setPrice] = useState(0);
  const [checkAvailability, setCheckAvailability] = useState(false);
  let [message, setMessage] = useState("");
  let [vaccinationDetails, setShowVaccinationDetails] =
    useState(false);

  useEffect(() => {
    setPrice(
      workspace.price &&
        !isNaN(Math.ceil(parseFloat(workspace.price) / 0.3))
        ? Math.ceil(parseFloat(workspace.price) / 0.3)
        : 0
    );
  }, [workspace]);
  useEffect(() => {
    if (role === "Venue" && user.id === vUserId) {
      setIsPlanFree(true);
    } else {
      if (workspace.free_space === "Yes") {
        if (user) {
          if (user.plan) {
            if (user.plan.free_space === "Yes") {
              setIsPlanFree(true);
            } else {
              setIsPlanFree(false);
            }
          } else {
            setIsPlanFree(false);
          }
        } else {
          setIsPlanFree(false);
        }
      }
    }
  }, [workspace]);

  const hideModal = () => {
    setShowVaccinationDetails(false);
  };
  if (isPlanFree) {
    return (
      <li key={workspace.id} style={{ marginBottom: 40 }}>
        <div className="row">
          <div className="col-md-6">
            {isOpen ? (
              <Lightbox
                // wrapperClassName="lightbox"
                mainSrc={workspace.images[photoIndex]}
                nextSrc={
                  workspace.images[
                    (photoIndex + 1) % workspace.images.length
                  ]
                }
                prevSrc={
                  workspace.images[
                    (photoIndex + workspace.images.length - 1) %
                      workspace.images.length
                  ]
                }
                onCloseRequest={() => {
                  setisOpen(false);
                }}
                onMovePrevRequest={() =>
                  setphotoIndex(
                    (photoIndex + workspace.images.length - 1) %
                      workspace.images.length
                  )
                }
                onMoveNextRequest={() => {
                  setphotoIndex(
                    (photoIndex + 1) % workspace.images.length
                  );
                }}
              ></Lightbox>
            ) : null}
            <Carousel
              dynamicHeight={true}
              showThumbs={false}
              showStatus={false}
            >
              {workspace.images &&
                workspace.images.map((imageUrl, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 0,
                      zIndex: 9999,
                      width: "100%"
                    }}
                    className="thumbnail"
                    onClick={() => setisOpen(true)}
                  >
                    <img
                      src={imageUrl}
                      alt={`${venueName} ${workspace.name} ${index}`}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
          <div className="col-md-6">
            <div className="workspace-detail">
              <div className="sub-title">
                <p>{venueName}</p>
              </div>
              <div className="title">
                <p>{workspace.name}</p>
              </div>
              <div className="meta clearfix">
                <div className="row">
                  <div className="col-lg-7">
                    <ul className="smd-tag-normal clearfix">
                      {workspace.types.map((e, i) => {
                        return (
                          <li key={i} className="active">
                            <a>
                              <span>{e.type}</span>
                            </a>
                          </li>
                        );
                      })}
                      {workspace.environments.map((e, index) => {
                        return (
                          <li key={index} className="active">
                            <a>
                              <span>{e.environment}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-5">
                    <div className="room-detail">
                      <div
                        className="room-number"
                        style={{
                          width: 34,
                          height: 34,
                          display: "block"
                        }}
                      >
                        <span style={{ paddingTop: 4 }}>
                          {workspace.capacity}
                        </span>
                      </div>
                      <div className="number">
                        <span className="image">
                          {workspace.capacity == 1 ? (
                            <img
                              src="/img/1p.png"
                              alt="Space Capcity 1"
                            />
                          ) : workspace.capacity == 2 ? (
                            <img
                              src="/img/2p.png"
                              alt="Space Capcity 2"
                            />
                          ) : workspace.capacity == 3 ? (
                            <img
                              src="/img/people.png"
                              alt="Space Capcity 3"
                            />
                          ) : workspace.capacity == 4 ? (
                            <img
                              src="/img/people4.png"
                              alt="Space Capcity 4"
                            />
                          ) : workspace.capacity >= 4 ? (
                            <img
                              src="/img/people+.png"
                              alt="Space Capcity above 4"
                            />
                          ) : (
                            ""
                          )}
                          <span
                            className="is-number"
                            style={{
                              fontSize: 12,
                              fontWeight: "normal",
                              display: "flex",
                              position: "relative",
                              top: "0.3rem ",
                              margin: 0
                            }}
                          >
                            Capacity per workspace
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ViewMoreButton
                id={`div${workspace.id}`}
                description={workspace.description}
                workspace={workspace}
              />
              <WorkspacesAssets
                workspace={workspace}
              ></WorkspacesAssets>
              <div className="meta-bottom">
                <div className="row">
                  <div className="col-md-7 col-lg-5">
                    <div className="point">
                      <span
                        className={`number ${
                          price <= 50
                            ? "creditBasic"
                            : price <= 170
                            ? "creditStandard"
                            : price > 170
                            ? "creditpremium"
                            : "creditpink"
                        }`}
                      >
                        {price}
                      </span>
                      <span className="slash">credits /</span>
                      <span>hr</span>
                    </div>
                  </div>
                  {user && user.credit < price ? (
                    <div className="col-md-5 col-lg-7">
                      <p className="link">
                        <a
                          style={{ color: "#31B5CE" }}
                          href="/dashboard"
                        >
                          Out of credits? Top Up Now
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="book-now">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: 16,
                    marginBottom: 10
                  }}
                >
                  {""}
                  <p style={{ margin: 0 }}>
                    {workspace.quantity}{" "}
                    {workspace.quantity == 1
                      ? "Workspace"
                      : "Workspaces"}{" "}
                    available{" "}
                  </p>
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      background: "#31b5ce",
                      borderRadius: "6px",
                      color: "#fff",
                      padding: "10px",
                      fontWeight: 700,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setCheckAvailability(true);
                    }}
                  >
                    <img
                      src="/img/icons/calendar.png"
                      style={{
                        color: "white",
                        paddingRight: "5px",
                        filter: "white"
                      }}
                      alt="calendar_icon"
                    ></img>
                    CHECK AVAILABILITY
                  </button>
                </div>
                {status != "Closed" ? (
                  user && user.id ? (
                    <button
                      disabled={disabled}
                      onClick={e => {
                        // if (
                        //   (vaccinationStatus &&
                        //     vaccinationStatus == "Fully Vaccinated" &&
                        //     (user?.vaccination_status !==
                        //       "Fully Vaccinated" ||
                        //       user?.vaccination_status == null ||
                        //       user?.vaccination_status ==
                        //         undefined)) ||
                        //   (vaccinationStatus ==
                        //     "Partially Vaccinated" &&
                        //     ((user?.vaccination_status !==
                        //       "Fully Vaccinated" &&
                        //       user?.vaccination_status !==
                        //         "Partially Vaccinated") ||
                        //       user?.vaccination_status == null ||
                        //       user?.vaccination_status == undefined))
                        // ) {
                        //   setMessage(
                        //     "You do not currently meet this venue's vaccination requirements. Please ensure your COVID-19 vaccination status is up to date in your Dorpee profile."
                        //   );
                        //   setShowVaccinationDetails(true);
                        // } else {
                        history.push(
                          `/book/1/${workspace.venue_id}/${workspace.id}`
                        );
                        // }
                      }}
                      className="btn"
                      style={{ background: "#f5597f" }}
                    >
                      BOOK NOW
                    </button>
                  ) : (
                    <button
                      disabled={disabled}
                      onClick={e => {
                        localStorage.setItem(
                          "gotoBooking",
                          `book/1/${workspace.venue_id}/${workspace.id}`
                        );
                        history.push("/login");
                      }}
                      className="btn "
                      style={{ background: "#f5597f" }}
                    >
                      BOOK NOW
                    </button>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {checkAvailability ? (
          <AvailabilityCalendarModal
            setMessage={setMessage}
            // setShowVaccinationDetails={setShowVaccinationDetails}
            vaccinationStatus={vaccinationStatus}
            workspace={workspace}
            CloseModal={() => {
              setCheckAvailability(false);
            }}
          />
        ) : (
          ""
        )}
        {vaccinationDetails ? (
          <VaccinationRequirementModal
            message={message}
            decline={hideModal}
            modalHeader={"Vaccination Requirement"}
            acceptText={"Update Profile"}
            declineText={"Cancel"}
            history={history}
          />
        ) : null}
      </li>
    );
  } else {
    return <></>;
  }
};

export default WorkspacesItem;
