/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AppState } from "../store";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { getBookingEffect } from "../Screens/Book/Booking/Actions";
import { getDashboardDataEffect } from "../Screens/Dashboard/Actions";
import { getProfileEffect } from "../Screens/Auth/Actions";
import { BookingAPI } from "../API/BookingAPI";
import {
  StoreBookingByIdEffect,
  ExtendBookingEffect
} from "../Screens/Book/Booking/Actions";
import CustomerBookings from "../Screens/Book/CustomerBookings";
import VenueBookings from "../Screens/Book/VenueBookings";
import {
  convertToDateTimeFormat,
  compareDates,
  getDateformatedChange
} from "../helpers/DateHelpers";

const Bookings: FC = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<any>();
  const myBookings = useSelector(
    (state: AppState) => state.Booking.myBookings
  );
  const dashboardData = useSelector(
    (state: AppState) => state.dashboard.dashboardData
  );
  const activeBookings = dashboardData.venues.active_booking;
  const pastBookings = dashboardData.venues.past_bookings;
  const cancelled_bookings = dashboardData.venues.cancelled_bookings;
  var role = false;
  const userToken = useSelector((state: AppState) => state.auth);
  const [isConfimrationBoxOpen, setisConfimrationBoxOpen] =
    useState(false);
  const [id, setid] = useState<Number>(0);
  const [status, setStatus] = useState(false);
  const [header, setHeader] = useState("BOOKING CANCELLATION");
  const [message, setMessage] = useState(
    "Are you sure you want to cancel this booking? Cancel before cancellation notice period, will get refund."
  );
  const [showExtendBookingModal, setExtendBookingModal] =
    useState(false);
  const [showButtons, setShowButtons] = useState(true);

  const [selectedBooking, storeBooking]: any = useState({});
  const [AllBookings, setAllBookings] = useState(myBookings);
  var userRole = Cookies.get("roleUser");
  if (userRole == "Venue") {
    role = true;
  }
  useEffect(() => {
    dispatch(getBookingEffect());
    dispatch(getProfileEffect());
    dispatch(getDashboardDataEffect());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (params.id) {
      var filteredData = [];
      filteredData = myBookings.filter(item => {
        if (
          getDateformatedChange(item.start_date, true).getTime() >=
          new Date().setHours(0, 0, 0, 0)
        ) {
          return item;
        }
      });
      setAllBookings(filteredData);
    } else {
      setAllBookings(myBookings);
    }
  }, [myBookings, params]);
  const hideModal = () => {
    setisConfimrationBoxOpen(false);
    setMessage("Are you sure you want to cancel this booking?");
    setExtendBookingModal(false);
    setShowButtons(true);
  };
  const openConfirmationBox = async (
    id: number,
    booking?: any,
    status?: boolean
  ) => {
    storeBooking(booking);
    setid(id);
    if (status) {
      setStatus(status);
      setHeader("EXTEND BOOKING");
      setMessage("Are you sure you want to extend this booking?");
      setExtendBookingModal(true);
    } else {
      let startTime = booking.start_time;
      let startDate = booking.start_date;
      let formattedDate = convertToDateTimeFormat(
        startDate,
        startTime,
        booking.space.cancellation_period
      );
      let isDue = compareDates(formattedDate, new Date());

      if (!isDue) {
        setMessage(
          `Are you sure you want to cancel this booking? \n
          Credits will not be refunded due to cancellation within the notice period.`
        );
      }
      if (isDue) {
        setMessage(
          `Are you sure you want to cancel this booking? \n
          Your credits will be refunded due to cancellation before the notice period.`
        );
      }
      setisConfimrationBoxOpen(true);
    }
  };

  const handleCancelBooking = async (id: number) => {
    if (isConfimrationBoxOpen) {
      if (status) {
        dispatch(ExtendBookingEffect({ booking_id: id }));
        setisConfimrationBoxOpen(false);
      } else {
        await BookingAPI.CancelBooking(userToken.token!, id, {
          status: "Cancelled",
          _method: "PUT"
        });
        setisConfimrationBoxOpen(false);
        toast.success("Booking successfully cancelled.");
      }
    }
    dispatch(getBookingEffect());
  };
  const handleValdiation = (booking: any) => {
    if (booking.status === "past") {
      toast.error("Booking is complete");
    } else openConfirmationBox(booking.id, booking);
  };
  const handlEditValdiation = (booking: any) => {
    if (booking.status === "past") {
      toast.error("Booking is complete");
    } else {
      let start_date = new Date(booking.start_date);
      let time = booking.start_time.split(":");
      start_date.setHours(time[0], time[1], time[2]);
      if (start_date.getTime() < new Date().getTime()) {
        setMessage(`You cannot update a booking that has started.`);
        setHeader("EDIT BOOKING");
        setShowButtons(false);
        setisConfimrationBoxOpen(true);
      } else {
        dispatch(
          StoreBookingByIdEffect(booking.booking_number, history)
        );
        window.localStorage.setItem("booking_Id", booking.id);
      }
    }
  };
  const handlCheckinValdiation = () => {
    setMessage(`You cannot CheckIn after End Time`);
    setHeader("Checkin");
    setShowButtons(false);
    setisConfimrationBoxOpen(true);
  };

  if (role) {
    return (
      <VenueBookings
        pastBookings={pastBookings}
        activeBookings={activeBookings}
        cancelled_bookings={cancelled_bookings}
      />
    );
  }
  return (
    <CustomerBookings
      handlEditValdiation={handlEditValdiation}
      openConfirmationBox={openConfirmationBox}
      handleValdiation={handleValdiation}
      handlCheckinValdiation={handlCheckinValdiation}
      isConfimrationBoxOpen={isConfimrationBoxOpen}
      hideModal={hideModal}
      handleCancelBooking={handleCancelBooking}
      showExtendBookingModal={showExtendBookingModal}
      myBookings={AllBookings}
      message={message}
      header={header}
      id={id}
      showButtons={showButtons}
    />
  );
};

export default Bookings;
