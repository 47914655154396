import { Reducer } from "react";
import { LoaderAction } from "./Actions";
import { LoaderState } from "../../store";

const initialState: LoaderState = {
  isLoading: false,
  isLoadingCredit: false,
  isLoadingSearch: false,
  isLoadingBooking_Venue: false,
};

export const loaderReducer: Reducer<LoaderState, LoaderAction> = (
  state: LoaderState = initialState,
  action
) => {
  switch (action.type) {
    case "@@wizard/SET_LOADER":
      return { ...state, isLoading: action.isLoading };
    case "@@wizard/SET_LOADER_CREDIT":
      return { ...state, isLoadingCredit: action.isLoading };
    case "@@wizard/SET_LOADER_SEARCH":
      return { ...state, isLoadingSearch: action.isLoading };
    case "@@wizard/UNSET_LOADER":
      return { ...state, isLoading: action.isLoading };
    case "@@wizard/SET_BOOKING_VENUE_LOADER":
      return { ...state, isLoadingBooking_Venue: action.isLoading };
    default:
      return state;
  }
};
