import { Reducer } from "react";
import { WorkspaceWizardState } from "../../store";
import { WorkspaceWizardAction } from "./Actions";

export enum WorkspaceWizardStep {
  Details,
  Preview,
  Success,
}

const initialState: WorkspaceWizardState = {
  step: WorkspaceWizardStep.Details,
  isWorkspacePublished: false,
  venues: [],
  publicVenues: [],
  venueTypes: [],
  environments: [],
  assets: [],
  services: [],
  isFreeSpacedSelected: false,
  workSpacesBlocks: [],
  singleSpaceAvailability: {
    space_id: 0,
    availability: [],
  },
  suggested_price: 0,
  workspaceId: 0,
  filterResult: [],
  filterType: [],
  filterEnv: [],
  filterSort: "",
  isSpaceFlow: false,
  isAutoFocus: true,
  availability: [],
  totalSpaces: "",
};

export const workspaceWizardReducer: Reducer<
  WorkspaceWizardState,
  WorkspaceWizardAction
> = (state: WorkspaceWizardState = initialState, action) => {
  switch (action.type) {
    case "@@workspaceWizard/RESET":
      return initialState;
    case "@@workspace/SET_STEP":
      return { ...state, step: action.step };
    case "@@workspace/PUBLISH_WORKSPACE":
      return { ...state, isWorkspacePublished: true };
    case "@@workspace/SUBMIT_DETAILS_FORM":
      return { ...state, detailsFields: action.fields };
    case "@@workspace/SUBMIT_IMAGES_FORM":
      return { ...state, imagesFields: action.fields };
    case "@@workspace/VENUES_LOADED":
      return { ...state, venues: action.venues };
    case "@@workspaceWizard/VENUE_TYPES_LOADED":
      return { ...state, venueTypes: action.types };
    case "@@workspaceWizard/ENVIRONMENTS_LOADED":
      return { ...state, environments: action.environments };
    case "@@workspaceWizard/ASSETS_LOADED":
      return { ...state, assets: action.assets };
    case "@@workspaceWizard/SERVICES_LOADED":
      return { ...state, services: action.services };
    case "@@workspace/BLOCKED":
      return { ...state, workSpacesBlocks: action.fields };
    case "@@workspace/Availability":
      return { ...state, singleSpaceAvailability: action.fields };
    case "@@WORKSPACE/SUGGESTED_PRICE":
      return { ...state, suggested_price: action.fields };
    case "@@WORKSPACE/SET_ID":
      return { ...state, workspaceId: action.id };
    case "@@workspaceWizard/FILTER_LOAD":
      return { ...state, filterResult: action.filter };
    case "@@workspaceWizard/FILTER_TYPE_LOAD":
      return { ...state, filterType: action.filter };
    case "@@workspaceWizard/FILTER_ENV_LOAD":
      return { ...state, filterEnv: action.filter };
    case "@@workspaceWizard/FILTER_SORT_NAME":
      return { ...state, filterSort: action.filter };
    case "@@workspaceWizard/SPACE_FLOW":
      return { ...state, isSpaceFlow: true };
    case "@@workspace/AUTOFOCUS":
      return { ...state, isAutoFocus: action.field };
    case "@@workspace/AVAILABILITYUPDATE":
      return { ...state, availability: action.data };
    case "@@workspace/TOTALSPACES":
      return { ...state, totalSpaces: action.data };
    default:
      return state;
  }
};
