/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Venue } from "../../API/VenueApi";
import { AppState } from "../../store";
import { Carousel } from "react-responsive-carousel";
import { User } from "../../API/AuthApi";
import { locationUrl2 } from "../../common/googleMaps";
import { Venue as VenueDto } from "../../API/VenueApi";

const WorkspaceItem = ({
  venue,
  showDialog,
  user,
  handleHoverMarker,
  fromSearch
}: {
  venue: Venue;
  showDialog: (venue: Venue) => void;
  user: User;
  handleHoverMarker?: any;
  fromSearch?: any;
}) => {
  const roleUser = useSelector(
    (state: AppState) => state.auth.userRoles
  );
  const [stars, setstars] = useState<string[]>();
  let isFreeSpace = venue.spaces.some(v => v.free_space === "Yes");
  let ispaidSpace = venue.spaces.some(v => v.free_space === "No");
  const [venueName, setVenueName] = useState(venue.name);
  const [venueLowestPrice, setVenueLowestPrice] = useState(0);
  const [venueType, setVenueType] = useState("");
  const [index, setIndex] = useState(
    venue.services.length > 5 ? 5 : venue.services.length
  );

  const types = useSelector(
    (state: AppState) => state.venueWizard.types
  );
  const [showMoreLess, setMoreStyle] = useState(false);
  const [showButton, ShowMoreButton] = useState(true);

  useEffect(() => {
    // freespace show venue name
    // if (user === undefined && isFreeSpace) {
    //   setVenueName("FREE SPACE - Register to access this space");
    // } else
    setVenueName(venue.name);
  }, [venue]);

  useEffect(() => {
    let type = types && types.find(v => v.id === venue.venue_type_id);
    setVenueType(type ? type.type : "");
  }, [types]);
  useEffect(() => {
    let doc = document.getElementById(venue.id.toString());
    let line = Number(doc && doc.offsetHeight);
    if (line > 192) {
      ShowMoreButton(true);
    } else ShowMoreButton(false);
  }, []);
  useEffect(() => {
    var venueLowestPrice =
      venue && !isNaN(Math.ceil(parseInt(venue.lowest_price) / 0.3))
        ? Math.ceil(parseInt(venue.lowest_price) / 0.3)
        : 0;
    setVenueLowestPrice(venueLowestPrice);
    var tempVenue = Number(venue.spaces[0].price);

    let space = venue.spaces.sort(function (a, b) {
      return Number(a.price) - Number(b.price);
    });
    space = space.filter(v => Number(v.price) > 0);
    if (space.length > 0) {
      tempVenue = Math.ceil(Number(space[0].price) / 0.3);
      setVenueLowestPrice(venueLowestPrice);
    }
  }, [venue]);
  const formatAddress = (venue: VenueDto) => {
    return [venue.address, venue.suburb, venue.postcode, venue.state]
      .filter(v => !!v)
      .join(" ");
  };

  const goWorkspace = (venueSlug: any, venueId: any) => {
    if (isFreeSpace) {
      if (!user) {
        if (!ispaidSpace) {
          return "/pricing";
        } else {
          if (venueSlug) {
            return `/workspace/${venueSlug}`;
          } else {
            return `/workspace/${venueId}`;
          }
        }
      }
      if (roleUser !== "Venue") {
        if (user.plan == null && !ispaidSpace) {
          return "/select-plan";
        } else if (
          user.plan &&
          user.plan.free_space === "No" &&
          !ispaidSpace
        ) {
          return "/select-plan";
        } else {
          if (venueSlug) {
            return `/workspace/${venueSlug}`;
          } else {
            return `/workspace/${venueId}`;
          }
        }
      } else {
        if (user.plan == null && !ispaidSpace) {
          return "/select-plan";
        } else if (
          user.plan &&
          user.plan.free_space === "No" &&
          !ispaidSpace
        ) {
          return "/select-plan";
        } else {
          if (venueSlug) {
            return `/workspace/${venueSlug}`;
          } else {
            return `/workspace/${venueId}`;
          }
        }
      }
    } else {
      if (venueSlug) {
        return `/workspace/${venueSlug}`;
      } else {
        return `/workspace/${venueId}`;
      }
    }
  };

  let status = venue.status;
  const handleMouseEnter = (id: any) => {
    if (handleHoverMarker) {
      handleHoverMarker(id);
    }
  };
  const handleMouseLeave = () => {
    if (handleHoverMarker) {
      handleHoverMarker("");
    }
  };

  return (
    <>
      <div className="box1 venue-container">
        {user ? (
          venue && venue.images ? (
            <Carousel
              className="carousel_div"
              dynamicHeight={false}
              showThumbs={false}
              showStatus={false}
            >
              {venue.images &&
                venue.images.map((imageUrl, index) => {
                  return (
                    <>
                      <Link
                        key={index}
                        to={{
                          pathname: goWorkspace(venue.slug, venue.id),
                          state: fromSearch ? true : false
                        }}
                      >
                        <div
                          className=" venue-imgHome"
                          style={{
                            marginBottom: 0,
                            zIndex: 9999,
                            width: "100%"
                          }}
                          onMouseEnter={() =>
                            handleMouseEnter(venue.id)
                          }
                          onMouseLeave={() => handleMouseLeave()}
                        >
                          <img
                            src={imageUrl}
                            alt={`${venue && venue.name}`}
                          ></img>
                          <div className="venue-imgHome__status-building"></div>
                          {isFreeSpace && ispaidSpace ? (
                            <div className=" ribbon-top-right ribbon ">
                              <span>Free Space</span>
                            </div>
                          ) : isFreeSpace &&
                            !ispaidSpace &&
                            user.plan &&
                            user.plan.free_space === "Yes" ? (
                            <div className=" ribbon-top-right ribbon ">
                              <span>Free Space</span>
                            </div>
                          ) : null}

                          {status === "Closed" ? (
                            <>
                              <div className=" ribbon_left ribbon-top-left ">
                                <span>Temporarily Unavailable</span>
                              </div>
                            </>
                          ) : null}
                          <div className="venue-type-label  venueTypeRib ">
                            <span className="ribbon-span">
                              {venue.venue_type?.type}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </>
                  );
                })}
            </Carousel>
          ) : null
        ) : (
          <Carousel
            className="carousel_div"
            dynamicHeight={false}
            showThumbs={false}
            showStatus={false}
          >
            {venue.images &&
              venue.images.map((imageUrl, index) => (
                <Link
                  key={index}
                  to={{
                    pathname: goWorkspace(venue.slug, venue.id),
                    state: fromSearch ? true : false
                  }}
                >
                  <div
                    className="venue-imgHome"
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      zIndex: 9999
                    }}
                    onMouseEnter={() => handleMouseEnter(venue.id)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <img
                      src={imageUrl}
                      alt={`${venue && venue.name} ${index}`}
                    />
                    <div className="venue-imgHome__status-building"></div>
                    {isFreeSpace && !ispaidSpace ? (
                      <>
                        <div className=" ribbon-top-right ribbon ">
                          <span>Free space </span>
                        </div>
                      </>
                    ) : null}
                    {status === "Closed" ? (
                      <>
                        <div className=" ribbon_left ribbon-top-left ">
                          <span>Temporarily Unavailable</span>
                        </div>
                      </>
                    ) : null}
                    <div className="venue-type-label  venueTypeRib ">
                      <span className="ribbon-span">
                        {venue.venue_type?.type}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
          </Carousel>
        )}
        <Link
          to={{
            pathname: goWorkspace(venue.slug, venue.id),
            state: fromSearch ? true : false
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="venue-subtitle"
              style={{ cursor: "pointer" }}
            >
              {user &&
              user.plan &&
              user.plan.free_space === "No" &&
              isFreeSpace &&
              !ispaidSpace
                ? venueName
                : user &&
                  user.plan &&
                  user.plan.free_space === "No" &&
                  isFreeSpace &&
                  ispaidSpace
                ? venueName
                : user &&
                  (!user.plan || user.plan.free_space === "No") &&
                  isFreeSpace &&
                  ispaidSpace
                ? venueName
                : user &&
                  (!user.plan ||
                    (user && user.plan.free_space === "No")) &&
                  isFreeSpace
                ? venueName
                : venueName}
            </div>

            <div style={{ paddingLeft: "5px" }}>
              {venue! && venue.ohs_only == "yes" ? (
                <img
                  src="/img/shield-check-regular.svg"
                  alt=""
                  title={`OH&S Self Assessment was completed by on the ${venue!
                    ?.assessment!?.completed_date!}`}
                ></img>
              ) : null}
            </div>
          </div>
        </Link>
        {user && user.plan && user.plan.free_space === "Yes" ? (
          <div className="bl-ads-workspace">
            <div className="address">
              <div
                className="form-control"
                style={{ border: "0px solid" }}
              >
                {venue && (
                  <a
                    className="venue-address__link"
                    target="_blank"
                    href={locationUrl2(
                      venue.address,
                      venue.suburb,
                      venue.state,
                      venue.postcode
                    )}
                  >
                    <span style={{ fontSize: 15 }}>
                      {venue && formatAddress(venue)}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : user &&
          ((user.plan && user.plan.free_space === "No") ||
            !user.plan) &&
          !isFreeSpace ? (
          <div className="bl-ads-workspace">
            <div className="address">
              <div
                className="form-control"
                style={{ border: "0px solid" }}
              >
                {venue && (
                  <a
                    className="venue-address__link"
                    target="_blank"
                    href={locationUrl2(
                      venue.address,
                      venue.suburb,
                      venue.state,
                      venue.postcode
                    )}
                  >
                    <span style={{ fontSize: 15 }}>
                      {venue && formatAddress(venue)}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : user &&
          user.plan &&
          user.plan.free_space === "No" &&
          isFreeSpace &&
          ispaidSpace ? (
          <div className="bl-ads-workspace">
            <div className="address">
              <div
                className="form-control"
                style={{ border: "0px solid" }}
              >
                {venue && (
                  <a
                    className="venue-address__link"
                    target="_blank"
                    href={locationUrl2(
                      venue.address,
                      venue.suburb,
                      venue.state,
                      venue.postcode
                    )}
                  >
                    <span style={{ fontSize: 15 }}>
                      {venue && formatAddress(venue)}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : user &&
          (!user.plan || user.plan.free_space === "No") &&
          isFreeSpace &&
          ispaidSpace ? (
          <div className="bl-ads-workspace">
            <div className="address">
              <div
                className="form-control"
                style={{ border: "0px solid" }}
              >
                {venue && (
                  <a
                    className="venue-address__link"
                    target="_blank"
                    href={locationUrl2(
                      venue.address,
                      venue.suburb,
                      venue.state,
                      venue.postcode
                    )}
                  >
                    <span style={{ fontSize: 15 }}>
                      {venue && formatAddress(venue)}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : !user && !isFreeSpace ? (
          <div className="bl-ads-workspace">
            <div className="address">
              <div
                className="form-control"
                style={{ border: "0px solid" }}
              >
                {venue && (
                  <a
                    className="venue-address__link"
                    target="_blank"
                    href={locationUrl2(
                      venue.address,
                      venue.suburb,
                      venue.state,
                      venue.postcode
                    )}
                  >
                    <span style={{ fontSize: 15 }}>
                      {venue && formatAddress(venue)}
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : null}

        <div className="venue-rating__left">
          {/*{(venueLowestPrice && venueLowestPrice === 0) ||*/}
          {/*(isFreeSpace &&*/}
          {/*  user &&*/}
          {/*  user.plan &&*/}
          {/*  user.plan.free_space === "YesXXX") ||*/}
          {/*(user && !user.plan) ? (*/}
          {/*  <span className={"from"}>Free Space XXX</span>*/}
          {/*) : (*/}
          <>
            <span className={"from"}>From</span>
            <span
              className={`${
                venueLowestPrice <= 50
                  ? "creditBasic"
                  : venueLowestPrice <= 170
                  ? "creditStandard"
                  : venueLowestPrice > 170
                  ? "creditpremium"
                  : "creditpink"
              }`}
            >
              {venueLowestPrice}
            </span>
            <span className={"hour"}> {"credits "} / hr</span>{" "}
          </>
          {/*)}*/}
        </div>

        <div className="venue-rating">
          <div className="venue-rating__left">
            {Number(venue.average_rating) > 0
              ? Array.from(
                  Array(
                    Number(venue.average_rating) === 0
                      ? 1
                      : Math.trunc(venue.average_rating!)
                  ),
                  (e, i) => {
                    return venue.average_rating! > 3 ? (
                      <a
                        key={i}
                        className="rating-star"
                        style={{ cursor: "pointer" }}
                        onClick={e => {
                          showDialog(venue);
                        }}
                      ></a>
                    ) : (
                      <a
                        onClick={e => {
                          showDialog(venue);
                        }}
                        key={i}
                        className="rating-star"
                      ></a>
                    );
                  }
                )
              : null}

            {venue.average_rating! > 0 ? (
              <span
                className="rating-views"
                onClick={e => {
                  showDialog(venue);
                }}
              >
                {venue.average_rating}
              </span>
            ) : null}
          </div>
        </div>

        {/* {venue && venue.distance_from_city ? (
          <div className="venue-distance">
            {venue && venue.distance_from_city} km from city center
          </div>
        ) : null} */}
        <div
          className="md-widget-sidebar"
          style={{
            whiteSpace: "normal",
            overflow: "hidden",
            height: showMoreLess ? "100%" : "11.5rem",
            marginTop: "1.5rem"
          }}
        >
          <div
            className="sub-title"
            style={{ display: "flex", cursor: "pointer" }}
          >
            <p>Services</p>
          </div>
          {venue.services && (
            <ul
              className="smd-tag clearfix amenitiesStyle"
              style={{ padding: 0 }}
              id={venue.id.toString()}
            >
              {venue.services.map(item => {
                if (item.status === "Active") {
                  return <li key={item.service}>{item.service}</li>;
                }
              })}
            </ul>
          )}
        </div>

        {showButton ? (
          <div
            className="loadmore"
            onClick={() => {
              setMoreStyle(!showMoreLess);
            }}
          >
            {showMoreLess ? "Load less" : "Load more"}
          </div>
        ) : null}

        {venue && venue.bonus_offer ? (
          <div
            className="venue-promotion"
            style={{ marginTop: "2.4rem" }}
          >
            {venue && venue.bonus_offer}{" "}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default WorkspaceItem;
