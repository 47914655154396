import React, { Component } from "react";
import { Plan } from "../../API/EnumsApi";
import { User } from "../../API/AuthApi";
interface Props {
  plan: Plan;
  handlePlanSelection: (e: Plan) => void;
  handleCancelPlanSelection: (e: Plan) => void;
  isLoading: boolean;
  isSubscriptionCancelled: any;
  user: User;
  index: number;
}
export default class PlansItem extends Component<Props> {
  render() {
    return (
      <div className="col-6 col-md-4 col-lg">
        <div
          className={
            (this.props.user &&
              this.props.user.plan &&
              this.props.user.plan_id === this.props.plan.id &&
              this.props.isSubscriptionCancelled) ||
            (!this.props.user && this.props.index === 0)
              ? "plan-item active"
              : "plan-item"
          }
        >
          <div className="plan-item__header">
            {this.props.plan.name}
          </div>
          <div className="plan-item__content">
            <div
              className="plan-item__content-block"
              style={{ marginTop: -12 }}
            >
              <span className="plan-item__content-price">
                ${this.props.plan.price}
              </span>
              <span className="plan-item__content-price-subtitle">
                {" "}
                {this.props.plan && this.props.plan.name === "Free"
                  ? "for your first 2 weeks"
                  : "per month"}
              </span>
            </div>
            <div className="plan-item__content-block">
              <span className="plan-item__content-credit">
                {this.props.plan.credits}
              </span>
              <span className="plan-item__content-credit-subtitle">
                credits
              </span>
              <span className="plan-item__content-credit-description">
                {this.props.plan.description}
              </span>
            </div>
            <div className="bottom_content_selectPlan">
              {this.props.plan &&
              this.props.plan.free_space === "Yes" ? (
                <div className="free_space_text">
                  Includes Free Space Access
                </div>
              ) : null}
              <button
                className={`btn wizard__btn mb-0${
                  this.props.isLoading ||
                  (this.props.plan &&
                    this.props.plan.name === "Free" &&
                    this.props.user &&
                    this.props.user.free_plan_status === "used")
                    ? " disabled"
                    : ""
                }${
                  this.props.isLoading ? " btn--loading" : " btn-blue"
                }`}
                disabled={
                  this.props.plan &&
                  this.props.plan.name === "Free" &&
                  this.props.user &&
                  this.props.user.free_plan_status === "used"
                }
                onClick={() =>
                  this.props.handlePlanSelection(this.props.plan)
                }
              >
                {this.props.plan &&
                this.props.plan.name === "Free" ? (
                  <div className="free_space_text">
                    TRY DORPEE FOR FREE
                  </div>
                ) : (
                  "Subscribe"
                )}
              </button>
            </div>
            {this.props.plan &&
              this.props.plan.name === "Free" &&
              this.props.user &&
              this.props.user.free_plan_status === "used" && (
                <div
                  className="plan-item__active"
                  style={{ display: "block" }}
                >
                  Complete
                </div>
              )}
          </div>
          <div className="plan-item__active">ACTIVE</div>
          <div
            className="plan-item__active "
            style={{
              transform: "none",
              top: "420px",
              background: "none",
              width: "100%",
              left: "0%"
            }}
          >
            {this.props.plan.free_space ? (
              <button
                className={`btn wizard__btn mb-0${
                  this.props.isLoading ? " disabled" : ""
                }${
                  this.props.isLoading ? " btn--loading" : " btn-blue"
                }`}
                style={{ display: "block", width: "82%" }}
                onClick={() =>
                  this.props.handleCancelPlanSelection(
                    this.props.plan
                  )
                }
              >
                {this.props.plan &&
                this.props.plan.name === "Free" ? (
                  <div className="free_space_text">CANCEL PLAN</div>
                ) : (
                  "CANCEL PLAN"
                )}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
