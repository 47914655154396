import React, { Component } from "react";

export default class AddSpaceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleAddSpace = () => {
    this.props.handleAddSpace();
  };
  render() {
    return (
      <li class="clearfix">
        <div class="row">
          <div class="col-md-7">
            <div class="workspace">
              <div class="name">
                <p className="workspaceName">
                  Workspace Type {this.props.index + 1}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="meta">
              <div class="add">
                <button
                  onClick={() => {
                    this.handleAddSpace();
                  }}
                  class="btn btn-add"
                >
                  <div
                    style={{
                      marginLeft: "25px",
                      marginRight: "8px",
                    }}
                  >
                    Add Workspace Type Details
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}
