import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import store from "../../../store";
import { DashboardApi } from "../../../API/DashboardApi";
import { toast } from "react-toastify/index";
import { getTeamEffects } from "../../Contact/Actions";
class AddContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactForm: {
        first_name: "",
        last_name: "",
        email: "",
        team_id: ""
      },
      errors: {
        first_name: false,
        last_name: false,
        email: false,
        team_id: false
      }
    };
  }

  changeContactForm(field, e) {
    let tmp = this.state.contactForm;
    let disabledErrors = {
      first_name: false,
      last_name: false,
      email: false,
      team_id: false
    };
    tmp[field] = e.target.value;
    this.setState({
      contactForm: tmp,
      errors: disabledErrors
    });
  }

  isFormValid() {
    let res = true;
    let tmp = this.state.errors;
    if (
      this.state.contactForm.first_name.length === 0 ||
      this.state.contactForm.first_name.length >= 100
    )
      tmp.first_name = true;
    if (
      this.state.contactForm.last_name.length === 0 ||
      this.state.contactForm.last_name.length >= 100
    )
      tmp.last_name = true;
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(this.state.contactForm.email))
      tmp.email = true;
    if (!this.props.isTeamHidden)
      if (!this.state.contactForm.team_id) tmp.team_id = true;
    if (tmp.first_name || tmp.last_name || tmp.email || tmp.team_id)
      res = false;
    this.setState({ errors: tmp });
    return res;
  }

  hideContact() {
    this.props.showHideModal("addContact", false);
  }

  addContact() {
    if (this.isFormValid()) {
      let data = this.state.contactForm;
      this.props.addContact(data);
      // this.props.getTeam()
      // if(this.props.setAddedContactsInvited)
      // this.props.setAddedContactsInvited()

      if (!this.props.contactErrors.status) {
        //    this.hideContact()
      }
    }
  }

  render() {
    return (
      <>
        <Modal>
          <div className="addressbook-form-box">
            <div className="congrat-box__header">
              <span>Add Contact</span>
              <div
                onClick={this.hideContact.bind(
                  this,
                  "addContact",
                  false
                )}
                className="close-btn"
              ></div>
            </div>
            <div className="addressbook-form">
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <img
                    className="input__icon--user"
                    src="/img/icons/user.svg"
                    alt=""
                  />
                </div>
                <input
                  style={{ textTransform: "capitalize" }}
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="First Name"
                  id="first_name"
                  onInput={this.changeContactForm.bind(
                    this,
                    "first_name"
                  )}
                />
                {this.state.errors.first_name ? (
                  <p className="contact-form-error">Invalid value</p>
                ) : null}
              </div>
              <div className="input-container contact-form-block">
                <div className="input__icon">
                  <img
                    className="input__icon--user"
                    src="/img/icons/user.svg"
                    alt=""
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="Last Name"
                  id="last_name"
                  onInput={this.changeContactForm.bind(
                    this,
                    "last_name"
                  )}
                  style={{ textTransform: "capitalize" }}
                />
                {this.state.errors.last_name ? (
                  <p className="contact-form-error">Invalid value</p>
                ) : null}
              </div>
              <div className="input-container contact-form-block">
                <div className="input__icon ">
                  <img
                    className="input__icon--email"
                    src="/img/icons/email.svg"
                    alt=""
                  />
                </div>
                <input
                  autoComplete="off"
                  className="addressbook-form-input"
                  type="text"
                  placeholder="Email"
                  id="email"
                  onInput={this.changeContactForm.bind(this, "email")}
                />
                {this.state.errors.email ? (
                  <p className="contact-form-error">Invalid value</p>
                ) : null}
                {this.props.contactErrors.status ? (
                  <p className="contact-form-error">
                    {this.props.contactErrors.message}
                  </p>
                ) : null}
              </div>
              {!this.props.isTeamHidden ? (
                <div className="input-container contact-form-block">
                  <div className="input__icon">
                    <img
                      className="input__icon--people"
                      src="/img/icons/people.svg"
                      alt=""
                    />
                  </div>

                  <select
                    name="team_select"
                    className="addressbook-form-input"
                    id="team_list"
                    onChange={this.changeContactForm.bind(
                      this,
                      "team_id"
                    )}
                  >
                    <option defaultValue value="">
                      Select Team
                    </option>
                    {this.props.teamList
                      ? this.props.teamList.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>

                  {this.state.errors.team_id ? (
                    <p className="contact-form-error">
                      Invalid value
                    </p>
                  ) : null}
                </div>
              ) : null}
              <button
                className="btn addressbook-modal-btn addressbook-submit-btn"
                onClick={this.addContact.bind(this)}
              >
                Add
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  contactErrors: state.Contacts.error
});
const mapDispatchToProps = {
  // setTeam_ContactError,
  getTeamEffects
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContact);
