import React from "react";
import { Pie } from "react-chartjs-2";
import store from "../../../store";

class NewVrsReturnedCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: this.getLabels(),
      datasets: [
        {
          data: this.getData(),
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
        },
      ],
    };
  }

  getLabels() {
    let labels = [];
    let data = this.props.pie.data.newVsOld;
    for (let i in data) {
      i = this.replaceLabel(i);
      labels.push(i);
    }
    return labels;
  }

  getData() {
    let values = [];
    let data = this.props.pie.data.newVsOld;
    for (let i in data) {
      values.push(data[i]);
    }
    return values;
  }

  replaceLabel(label) {
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return label;
  }

  render() {
    return (
      <div className="venue-chart-small">
        <Pie data={this.state} legend={{ position: "bottom" }} />
      </div>
    );
  }
}

export default NewVrsReturnedCustomers;
