import React from "react";
import { Modal } from "../../../UI/Modal";
import { connect } from "react-redux";
import {
  getVenuesEffect,
  postVenueStatusEffect
} from "../../VenueWizard/Actions";

class ChangeStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "Published",
      showModal: false
    };
  }

  componentDidMount() {
    this.props.getVenuesEffect();
  }
  decline() {
    this.props.decline();
    this.props.getVenuesEffect();
  }
  declineDraftModal() {
    this.setState({ showModal: false });
  }
  handleSubmit = () => {
    let req = {
      status: this.state.status,
      _method: "PUT"
    };
    if (this.state.status === "Published" && !this.props.canPublish) {
      this.setState({ showModal: true });
    } else {
      this.props.postVenueStatusEffect(this.props.id, req);
      this.props.getVenuesEffect();
      this.props.decline();
    }
  };
  handleSubmitDraft = () => {
    let req = {
      status: "Draft",
      _method: "PUT"
    };
    this.props.postVenueStatusEffect(
      this.props.id,
      req,
      null,
      this.props.showMessage,
      this.props.history
    );
    this.props.getVenuesEffect();
    this.props.decline();
  };
  handleStatus = ev => {
    this.setState({ status: ev.target.value });
  };

  render() {
    return (
      <>
        {!this.props.showMessage && (
          <Modal>
            <div className="addressbook-form-box">
              <div className="congrat-box__header">
                <span>{this.props.modalHeader}</span>
                <div
                  onClick={this.decline.bind(this)}
                  className="close-btn"
                ></div>
              </div>
              <div className="addressbook-form">
                <div className="col-md-10 status-modal-head">
                  <div className="status-label">Status</div>
                  <div
                    style={{
                      border: "1px solid #009cdc",
                      width: "100%"
                    }}
                    className="input-container"
                  >
                    <div className="input-select">
                      <select
                        style={{ color: "#009cdc" }}
                        className="pl-12"
                        onChange={e => this.handleStatus(e)}
                        value={this.state.status}
                      >
                        <option key={1}>Published</option>
                        <option key={2}>Unpublished</option>
                        <option key={3}>Closed</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div>{this.props.message}</div>
                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={this.handleSubmit}
                >
                  {this.props.acceptText}
                </button>
                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={this.decline.bind(this)}
                >
                  {this.decline.bind(this)}
                  {this.props.declineText}
                </button>
              </div>
            </div>
          </Modal>
        )}
        {(this.props.showMessage || this.state.showModal) && (
          <Modal>
            <div className="addressbook-form-box">
              <div className="congrat-box__header">
                <span>Publish Venue</span>
                <div
                  onClick={() => {
                    if (this.props.showMessage) {
                      this.props.handleModal();
                    } else {
                      this.declineDraftModal.bind(this);
                    }
                  }}
                  className="close-btn"
                ></div>
              </div>
              <div className="addressbook-form">
                <div className="col-md-10 status-modal-head">
                  To publish a Venue you need at least one Workspace.
                  Please create one first before trying again.
                </div>

                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={this.handleSubmitDraft}
                  disabled={this.props.isLoading}
                >
                  Save As Draft
                </button>
                <button
                  className="btn addressbook-modal-btn addressbook-submit-btn"
                  onClick={() => {
                    if (this.props.showMessage) {
                      this.props.handleModal();
                    } else {
                      this.declineDraftModal.bind(this);
                    }
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.Loader.isLoading,
  user: state.auth.token
});

const mapDispatchToProps = {
  getVenuesEffect,
  //   postSpaceStatusEffect,
  postVenueStatusEffect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeStatusModal);
