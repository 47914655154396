import React, { useEffect } from "react";
import useBodyClass from "../../hooks/useBodyClass";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState, UserType } from "../../store";
import { User } from "../../API/AuthApi";
import { Helmet } from "react-helmet";

const SelectPlan = () => {
  useBodyClass("bgr-blue");
  const history = useHistory();
  const user = useSelector<AppState, User>(app => app.auth.user!);
  useEffect(() => {
    window.localStorage.removeItem("credit");
    window.localStorage.removeItem("card_status");
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Work from where you want, when you want to - with a flexible membership that’s right for you. No lock-in contracts, cancel anytime."
        />
        <title>Monthly Workspace Memberships | Dorpee</title>
      </Helmet>
      <div className="wrapper wrapper-login">
        <Link className="logo-login" to="/">
          <img src="/img/logo.png" alt="Dorpee" />
        </Link>
        <div className="wizard pt-40 mt-65">
          <div className="wizard-avatar">
            <img
              src={
                user.avatar ? user.avatar : "/img/icons/user-info.svg"
              }
              alt=""
            />
          </div>
          <div className="wizard-greetings">
            <h2>Hi, {user.first_name}</h2>
            <span>You have signed up as a customer</span>
          </div>
          <button
            onClick={() => history.push("/select-plan")}
            className="btn btn-blue wizard__btn mb-0"
          >
            SELECT A SUBSCRIPTION
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectPlan;
