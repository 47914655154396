import React, { FC } from "react";
import { Field } from "redux-form";

import MultiCheckBoxNew from "../../../UI/MultiCheckBoxNew";

interface Props {
  venueTypes: any[];
}

const WorkspaceTypes: FC<Props> = ({ venueTypes }) => {
  return (
    <div className="md-workspace-type">
      <Field
        type="checkbox"
        name="types"
        checkboxes={venueTypes.map((t) => ({
          label: t.type,
          value: t.id.toString(),
        }))}
        component={MultiCheckBoxNew}
        isIcon
        type1={"Type"}
      />
    </div>
  );
};

export default WorkspaceTypes;
