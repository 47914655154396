/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getVenuesEffect } from "../VenueWizard/Actions";
class Checklist extends React.Component {
  constructor(props) {
    super();
    this.state = {
      checked: false,
      checkedVenue: false,
      checkedSpace: false,
      checkOpenHours: false,
      checkBankDetails: false,
      checkVenueAssesment: false,
      checkWifi: false,
      check_vaccination_status: false
    };
  }

  componentDidUpdate(previouProps, previousState) {
    if (
      this.props.venues &&
      this.props.venues.length > 0 &&
      (previouProps.venues.length !== this.props.venues.length ||
        this.props.venuesOpenHours.length !==
          previouProps.venuesOpenHours.length)
    ) {
      var venueHours =
        this.props.venues.length > 0 &&
        this.props.venuesOpenHours.map(v => {
          if (v.venue_id === this.props.venues[0].id) {
            return v;
          }
        });
      this.setState({
        checkedVenue: this.props.venues.length > 0 ? true : false,
        checkedSpace: this.props.venues.some(
          venue => venue.spaces.length > 0
        ),
        checkOpenHours:
          this.props.venuesOpenHours.length <= 0 &&
          venueHours.length <= 0
            ? false
            : true,
        // checkBankDetails:
        // this.props.user.account_number == null ? false : true,
        checkVenueAssesment: this.props.venues.some(
          venue => venue.assessment != null
        ),
        checkWifi: this.props.venues.some(
          venue => venue.wifi_name != "" || venue.wifi_name != null
        ),
        check_vaccination_status: this.props.venues.some(
          venue => venue.vaccination_status == null
        )
      });
    } else {
      const statusChange = this.props.venues.some(
        venue => venue.vaccination_status == null
      );
      const assesmentChange = this.props.venues.some(
        venue => venue.assessment != null
      );
      if (
        !statusChange &&
        previousState.check_vaccination_status == false &&
        this.props.venues.length > 0
      ) {
        this.setState({ check_vaccination_status: true });
      }
      if (
        assesmentChange &&
        previousState.checkVenueAssesment == false
      ) {
        this.setState({ checkVenueAssesment: true });
      }
    }
  }
  componentDidMount() {
    if (this.props.user) {
      this.setState({
        checkBankDetails:
          this.props.user.account_number == null ? false : true
      });
    }
    if (this.props.venues.length > 0) {
      var venueHours =
        this.props.venues.length > 0 &&
        this.props.venuesOpenHours.map(v => {
          if (v.venue_id === this.props.venues[0].id) {
            return v;
          }
        });
      this.setState({
        checkedVenue: this.props.venues.length > 0 ? true : false,
        checkedSpace: this.props.venues.some(
          venue => venue.spaces.length > 0
        ),
        checkOpenHours:
          this.props.venuesOpenHours.length <= 0 &&
          venueHours.length <= 0
            ? false
            : true,
        // checkBankDetails:
        //   this.props.user.account_number == null ? false : true,
        checkVenueAssesment: this.props.venues.some(
          venue => venue.assessment != null
        ),
        checkWifi: this.props.venues.some(
          venue => venue.wifi_name != "" || venue.wifi_name != null
        ),
        check_vaccination_status: this.props.venues.some(
          venue => venue.vaccination_status == null
        )
      });
    }
  }

  handleClick = e => {
    this.setState({
      checked: !this.state.checked
    });
  };
  handleClickVenue = e => {
    this.setState({
      checkedVenue: !this.state.checkedVenue
    });
  };
  handleClickSpace = e => {
    this.setState({
      checkedSpace: !this.state.checkedSpace
    });
  };
  handleClickOpenHours = e => {
    this.setState({
      checkOpenHours: !this.state.checkOpenHours
    });
  };
  handleClickBankDetails = e => {
    this.setState({
      checkBankDetails: !this.state.checkBankDetails
    });
  };
  handleClickAssesment = e => {
    this.setState({
      checkVenueAssesment: !this.state.checkVenueAssesment
    });
  };
  handleClickWifi = e => {
    this.setState({
      checkWifi: !this.state.checkWifi
    });
  };
  handleGoToSpace = () => {
    if (this.props.venues && this.props.venues.length > 0) {
      this.props.history.push("/create-workspace");
    }
  };
  render() {
    const { venueToken } = this.props;

    return (
      <>
        <div className="row">
          <h4 style={{ marginBottom: "18px", fontSize: 24 }}>
            Setup Checklist
          </h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkedVenue}
              onClick={this.handleClickVenue}
            />
            &nbsp;
            <Link
              to="/create-venue"
              onClick={e => {
                if (venueToken) {
                  e.preventDefault();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkedVenue ? "green" : "red"
                }}
              >
                Create your first venue
              </span>
            </Link>
          </div>
        </div>
        <div className="row marginTop_row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkedSpace}
              onClick={this.handleClick}
            />
            &nbsp;
            <a
              onClick={() => {
                if (!venueToken) {
                  this.handleGoToSpace();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkedSpace ? "green" : "red"
                }}
              >
                Add workspaces to venue
              </span>
            </a>
          </div>
        </div>
        <div className="row marginTop_row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkOpenHours}
              onClick={this.handleClickOpenHours}
            />
            &nbsp;
            <Link
              to="/space-availability"
              onClick={e => {
                if (venueToken) {
                  e.preventDefault();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkOpenHours ? "green" : "red"
                }}
              >
                Block out time slots as unavailable{" "}
              </span>
            </Link>
          </div>
        </div>
        <div className="row marginTop_row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkBankDetails}
              onClick={this.handleClick}
            />
            &nbsp;
            <Link
              to="/account-details"
              onClick={e => {
                if (venueToken) {
                  e.preventDefault();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkBankDetails ? "green" : "red"
                }}
              >
                Complete your bank details{" "}
              </span>
            </Link>
          </div>
        </div>
        <div className="row marginTop_row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkVenueAssesment}
              onClick={this.handleClickAssesment}
            />
            &nbsp;
            <Link
              to="/venue-assessment"
              onClick={e => {
                if (venueToken) {
                  e.preventDefault();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkVenueAssesment
                    ? "green"
                    : "red"
                }}
              >
                OH&S Self Assessment{" "}
              </span>
            </Link>
          </div>
        </div>
        {/*<div className="row marginTop_row">*/}
        {/*  <div className="col-md-12">*/}
        {/*    <input*/}
        {/*      type="checkbox"*/}
        {/*      disabled*/}
        {/*      checked={this.state.check_vaccination_status}*/}
        {/*      onClick={this.handleClickAssesment}*/}
        {/*    />*/}
        {/*    &nbsp;*/}
        {/*    <Link*/}
        {/*      to="/venue-vaccination"*/}
        {/*      onClick={e => {*/}
        {/*        if (venueToken) {*/}
        {/*          e.preventDefault();*/}
        {/*        }*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <span*/}
        {/*        style={{*/}
        {/*          fontFamily: "RobotoRegular",*/}
        {/*          color: this.state.check_vaccination_status*/}
        {/*            ? "green"*/}
        {/*            : "red"*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Update vaccination requirements{" "}*/}
        {/*      </span>*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row marginTop_row">
          <div className="col-md-12">
            <input
              type="checkbox"
              disabled
              checked={this.state.checkWifi}
              onClick={this.handleClickWifi}
            />
            &nbsp;
            <Link
              to="/venues"
              onClick={e => {
                if (venueToken) {
                  e.preventDefault();
                }
              }}
            >
              <span
                style={{
                  fontFamily: "RobotoRegular",
                  color: this.state.checkWifi ? "green" : "red"
                }}
              >
                WIFI Details{" "}
              </span>
            </Link>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  venues: state.workspaceWizard.venues,
  venuesOpenHours: state.venueWizard.venuesOpenHours,
  user: state.auth.user
});
const mapDispatchToProps = {
  getVenuesEffect
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checklist);
