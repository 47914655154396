import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setSubmitFailed } from "redux-form";

import { AppState } from "../../../src/store";
import { CreateVenueRequest, VenueApi } from "../../API/VenueApi";
import { Address } from "../../UI/LocationPicker/LocationPicker";
import { setLoader } from "../Loader/Actions";
import {
  DraftVenueEffect,
  getEnvironmentsEffect,
  getVenuesEffect,
  publishVenueEffect,
  venuesAmenitiesForm
} from "./Actions";
import { VenueImagesFields } from "./VenueImagesForm";

export type VenueDetailsFields = {
  name: string;
  location: Address;
  description: string;
  venue_type_id: any;
  email?: string;
  image1?: File;
  image2?: File;
  image3?: File;
  image4?: File;
  _method?: string;
  phone_number?: string;
  lead_time?: number;
  wifi_password?: string;
  wifi_name?: string;
  vaccination_status?: string;
};

export const VenueDraftButton = (props: any) => {
  const dispatch = useDispatch();
  const userToken = useSelector(
    (state: AppState) => state.auth.token
  );
  const formFields = useSelector(
    (state: AppState) => state.form!.venueDetails!.values
  );
  const venueAmenities = useSelector(
    (state: AppState) => state.form?.venueAmen?.values
  );
  const wizardStep = useSelector(
    (state: AppState) => state.venueWizard.step
  );
  const isLoading = useSelector(
    (state: AppState) => state.Loader.isLoading
  );

  const handleSave = async (e: any) => {
    e.preventDefault();
    let error = false;

    if (wizardStep === 0) {
      if (!formFields.venue_type_id) {
        dispatch(setSubmitFailed("venueDetails", "venue_type_id"));
        error = true;
      }
      if (!formFields.name) {
        dispatch(setSubmitFailed("venueDetails", "name"));
        error = true;
      }
      if (!formFields.location) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (formFields.location && !formFields.location!.suburb) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (formFields.location && !formFields.location!.suburb) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (formFields.location && !formFields.location!.postalCode) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (formFields.location && !formFields.location!.state) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (formFields.location && !formFields.location!.address) {
        dispatch(setSubmitFailed("venueDetails", "location"));
        error = true;
      }
      if (!formFields.phone_number) {
        dispatch(setSubmitFailed("venueDetails", "phone_number"));
        error = true;
      }
      if (
        !formFields.image1 &&
        !formFields.image2 &&
        !formFields.image3 &&
        !formFields.image4
      ) {
        dispatch(setSubmitFailed("venueDetails", "image1"));
        error = true;
      }
      if (!formFields.description) {
        dispatch(setSubmitFailed("venueDetails", "description"));
        error = true;
      }
      if (error) {
        return;
      } else {
        dispatch(setLoader(true));
        let data = {
          venue_type_id: formFields.venue_type_id,
          name: formFields.name,
          location: formFields.location,
          phone_number: formFields.phone_number,
          email: formFields.email,
          vaccination_status: formFields.vaccination_status!,
          description: formFields.description!,
          status: "Draft"
        };
        let arrayImages: VenueImagesFields = {};
        if (formFields.image1) {
          arrayImages.image1 = formFields.image1;
        }
        if (formFields.image2) {
          arrayImages.image2 = formFields.image2;
        }
        if (formFields.image3) {
          arrayImages.image3 = formFields.image3;
        }
        if (formFields.image4) {
          arrayImages.image4 = formFields.image4;
        }
        const response = await VenueApi.CreateVenue(
          userToken!,
          new CreateVenueRequest(data, arrayImages)
        );
        if (response.data) {
          dispatch(setLoader(false));
          toast.success("Saved as Draft");
          history.push("/venues");
        } else {
          dispatch(setLoader(false));
          toast.error("Something went wrong, try again");
        }
      }
    }

    if (wizardStep === 1) {
      props.formValid(true);
    }

    if (wizardStep === 2) {
      let error = false;
      if (
        !venueAmenities.amenities ||
        venueAmenities.amenities!.length === 0
      ) {
        dispatch(setSubmitFailed("venueAmen", "amenities"));
        error = true;
      }
      if (error) {
        return;
      } else {
        dispatch(venuesAmenitiesForm(venueAmenities.amenities));
        dispatch(DraftVenueEffect(history));
      }
    }
  };
  const history = useHistory();
  return (
    <>
      {window.location.pathname.includes("create-venue") ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0"
          }}
        >
          <button
            disabled={isLoading}
            className={`col-md-3 btn ${
              !isLoading ? "btn-publish" : "btn--loading3"
            }`}
            style={{
              position: "relative",
              background: "#f5597f",
              border: "none"
            }}
            type="button"
            onClick={e => {
              handleSave(e);
            }}
          >
            <span
              style={{
                color: "#fff",
                textTransform: "uppercase",
                background: "none",
                padding: "12px 16px 12px 34px",
                textAlign: "center",
                borderRadius: "4px"
              }}
            >
              Save as Draft
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
};
export default VenueDraftButton;
