import * as React from "react";
import { useRef } from "react";
import "./Modal.sass";

interface Props {
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  className?: string;
  position?: any;
  width?: any;
  style?: any;
}

export const Modal = (props: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!modalRef.current || !props.onClose) {
      return;
    }

    if (
      e.target !== modalRef.current &&
      !modalRef.current.contains(e.target as Node)
    ) {
      props.onClose(e);
    }
  };

  return (
    <div
      className="overlay"
      onMouseDown={onMouseDown}
      style={{
        position: props.position ? props.position : "",
      }}
    >
      <div
        ref={modalRef}
        className={`modal ${props.className || ""}`}
        style={
          props.style
            ? props.style
            : { minWidth: props.width ? props.width : "" }
        }
      >
        <div onClick={props.onClose}></div>
        {props.children}
      </div>
    </div>
  );
};
