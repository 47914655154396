import React, {
  useState,
  useEffect,
  forwardRef,
  useRef
} from "react";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import store, { AppState } from "../../store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

import { getPublicVenuesEffect } from "../VenueWizard/Actions";
import BookingVenue from "./BookingVenue";
import {
  setBooking,
  getCustomerEmployeesEffect,
  setOrgEmployees
} from "../Book/Booking/Actions";
import {
  getContactEffects,
  getTeamEffects
} from "../Contact/Actions";
import { toast } from "react-toastify";
import {
  formatDate,
  formatedTimeMin,
  formatedTime,
  formatedTimeMin2
} from "../../helpers/DateHelpers";
import {
  SpaceAvailabilityRequest,
  BookingAPI
} from "../../API/BookingAPI";
import VenueSteps from "../VenueWizard/VenueSteps";
import Footer from "../../footer";
import { getProfileEffect } from "../Auth/Actions";
import { InfoBox } from "@react-google-maps/api";
import Moment from "react-moment";
import { Helmet } from "react-helmet";
import VaccinationRequirementModal from "./VaccinationRequiremnetModal";

const DatePickerInput = forwardRef((props: any, ref: any) => {
  const { onClick }: any = props;
  return (
    <div
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      style={{ width: "100%", display: "flex", alignItems: "center" }}
    >
      <input
        {...props}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
      <img
        className="book-date-icon"
        style={{ width: "30px", cursor: "pointer" }}
        onClick={onClick}
        src="/img/icons/from.svg"
        alt=""
      />
    </div>
  );
});
const BookStep1 = ({
  venueId,
  spaceId
}: {
  venueId: string;
  spaceId: any;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const venue = useSelector(
    (state: AppState) => state.venueWizard.publicVenues
  ).find(v => v.id.toString() === venueId);
  const space =
    venue && venue.spaces.find(space => space.id === Number(spaceId));
  const user = useSelector((state: AppState) => state.auth.user);
  const employer = useSelector(
    (state: AppState) => state.Booking.myEmployers
  );

  const bookingDetails = useSelector(
    (state: AppState) => state.Booking.BookingFields
  );
  const searchResult = useSelector(
    (state: AppState) => state.venueSearch.basic_search
  );
  let [startDate, setStartDate]: any = useState(new Date());
  let [endDate, setEndDate]: any = useState(new Date());
  let [tempDate, setTempDate] = useState(new Date());
  let [bookAs, setBookAs] = useState<any>();

  let [timeStart, setStartTime] = useState(formatedTimeMin());
  let [timeEnd, setEndTime] = useState(formatedTimeMin(true));
  let [timeStartOpen, setStartTimeOpen] = useState(false);
  let [timeEndOpen, setEndTimeOpen] = useState(false);
  let [no_of_people, setNumPeople] = useState(1);
  let [no_of_spaces, setNumSpaces]: any = useState(1);
  let [availableSpaces, setavailableSpaces]: any = useState(null);
  let [fromDateValidation, setFromDateError] = useState<string>();
  let [dateError, setDateError] = useState<string>("");
  let [dateFromError, hasFromDateError] = useState(false);
  let [availabilityCheck, setAvailabilityCheck] = useState("");
  let [timeError, setTimeError] = useState("");
  const [isDurationValid, setDurationCheck] = useState(false);
  const [spaceAvailabilityError, setSpaceAvailabilityError]: any =
    useState({});
  // let [vaccinationDetails, setShowVaccinationDetails] =
  //   useState(false);
  // let [message, setMessage] = useState("");
  const [disableTimes, setDisableTimes] = useState(false);

  let date: Number;
  const [only_ohsError, setonly_ohsError] = useState(false);
  useEffect(() => {
    dispatch(getCustomerEmployeesEffect());
    dispatch(getContactEffects());
    dispatch(getTeamEffects());
    window.localStorage.removeItem("gotoBooking");
  }, []);
  useEffect(() => {
    dispatch(getProfileEffect());
  }, [user]);
  useEffect(() => {
    if (space?.min_booking_hours! > 1) {
      let year = timeEnd.getFullYear();
      let month = timeEnd.getMonth();
      let hours = timeEnd.getHours();
      let day = timeEnd.getDate();
      let mins = timeEnd.getMinutes();

      let formatedTimenew = new Date(
        Number(year),
        Number(month),
        Number(day),
        Number(hours + space?.min_booking_hours! - 1),
        Number(mins)
      );

      setEndTime(formatedTimenew);
    }
    dispatch(getProfileEffect());
  }, [space]);
  useEffect(() => {
    if (employer.length > 0) {
      if (
        bookingDetails &&
        bookingDetails.booking_as === "employer"
      ) {
        if (bookingDetails.employer) {
          setBookAs(bookingDetails.employer.id);
        } else {
          setBookAs(bookingDetails.employer_id);
        }
      } else if (
        bookingDetails &&
        bookingDetails.booking_as === "customer"
      ) {
        setBookAs(0);
      } else {
        setBookAs(employer[0].id);
      }
    } else {
      setBookAs(0);
    }
  }, [employer]);
  useEffect(() => {
    if (
      bookingDetails.end_date !== "" &&
      bookingDetails.end_date != undefined &&
      bookingDetails.end_date != null
    ) {
      if (
        bookingDetails.availableSpaces != undefined ||
        bookingDetails.availableSpaces != null
      ) {
        setavailableSpaces(bookingDetails.availableSpaces);
      }
      if (bookingDetails.no_of_people)
        setNumPeople(bookingDetails.no_of_people);
      if (bookingDetails.no_of_booked_spaces)
        setNumSpaces(bookingDetails.no_of_booked_spaces);
      setEndDate(getDateformated(bookingDetails.end_date));
      setStartDate(getDateformated(bookingDetails.start_date));
      setStartTime(
        formatedTimeMin2(
          bookingDetails.start_time,
          bookingDetails.end_date
        )
      );
      setEndTime(
        formatedTimeMin2(
          bookingDetails.end_time,
          bookingDetails.end_date
        )
      );
      if (bookingDetails.booking_as == "customer") {
        setBookAs(0);
      }
      if (bookingDetails.booking_as === "employer") {
        setBookAs(bookingDetails?.employer?.id);
      }
    } else if (
      (searchResult && searchResult.date) ||
      searchResult.capacity
    ) {
      setNumPeople(
        parseInt(searchResult.capacity ? searchResult.capacity : 1)
      );
      if (searchResult.date) setStartDate(searchResult.date);
      if (searchResult.start_time)
        setStartTime(searchResult.start_time.toDate());
      if (searchResult.end_time)
        setEndTime(searchResult.end_time.toDate());
    } else if (
      (bookingDetails.start_time &&
        bookingDetails.start_time != "") ||
      (bookingDetails.end_time && bookingDetails.end_time != "")
    ) {
      if (
        bookingDetails.availableSpaces != undefined ||
        bookingDetails.availableSpaces != null
      ) {
        setavailableSpaces(bookingDetails.availableSpaces);
      }
      if (bookingDetails.no_of_people)
        setNumPeople(bookingDetails.no_of_people);
      if (bookingDetails.no_of_booked_spaces)
        setNumSpaces(bookingDetails.no_of_booked_spaces);
      setEndDate(null);
      setStartDate(null);
      setStartTime(formatedTimeMin2(bookingDetails.start_time));
      setEndTime(formatedTimeMin2(bookingDetails.end_time));
      if (bookingDetails.booking_as == "customer") {
        setBookAs(0);
      }

      if (bookingDetails.booking_as === "employer") {
        setBookAs(bookingDetails?.employer?.id);
      }
    }
  }, []);

  useEffect(() => {
    if (bookingDetails && bookingDetails._method == "PUT") {
      let bookingDate = bookingDetails.start_date.split("/");
      let bookingTime = bookingDetails.start_time.split(":");
      let newBookingDate = new Date(
        `${bookingDate[0]}-${bookingDate[1]}-${bookingDate[2]}`
      );
      newBookingDate.setHours(
        bookingTime[0],
        bookingTime[1],
        bookingTime[2]
      );
      const bookingStartTime: any = moment(newBookingDate);
      var currentDate = new Date();
      var date = startDate.setHours(0, 0, 0, 0);
      let date1 = currentDate.setHours(0, 0, 0, 0);

      if (date === date1) {
        if (
          space &&
          space.cancellation_period != 0 &&
          space.cancellation_period != null &&
          space.cancellation_period != undefined
        ) {
          const beforetime: any = moment(new Date()).add(
            space.cancellation_period,
            "hours"
          );

          if (
            bookingStartTime._d.getTime() < beforetime._d.getTime()
          ) {
            setDisableTimes(true);
          }
        }
        if (
          bookingDetails.space &&
          bookingDetails.space.cancellation_period != 0 &&
          bookingDetails.space.cancellation_period != null &&
          bookingDetails.space.cancellation_period != undefined
        ) {
          const beforetime: any = moment(new Date()).add(
            bookingDetails.space.cancellation_period,
            "hours"
          );

          if (
            bookingStartTime._d.getTime() < beforetime._d.getTime()
          ) {
            setDisableTimes(true);
          }
        }
      }
    }
  }, [bookingDetails]);

  const getDateformated = (value: string) => {
    let date = value.split("/");
    let formatedDate = new Date(
      Number(date[2]),
      Number(date[0]) - 1,
      Number(date[1]),
      0,
      0
    );
    return formatedDate;
  };

  const submitBookingData = async () => {
    if (
      startDate == null ||
      startDate == "" ||
      startDate == undefined
    ) {
      setDateError("Please select Date");
      return;
    }
    var diff = timeEnd.getHours() - timeStart.getHours();
    const timestart: any = moment(timeStart);
    const beforetime: any = moment(new Date()).add(
      venue?.lead_time,
      "hours"
    );

    var currentDate = new Date();
    var date = startDate.setHours(0, 0, 0, 0);
    let date1 = currentDate.setHours(0, 0, 0, 0);

    if (date === date1) {
      let startTimeNew: any = moment(currentDate).add(
        moment.duration(moment(timeStart).format("HH:MM:SS"))
      );

      if (new Date().getTime() > startTimeNew._d.getTime()) {
        setTimeError("Please select a future time");
        return;
      }

      if (
        space &&
        venue &&
        venue?.lead_time != 0 &&
        venue?.lead_time != null &&
        venue?.lead_time != undefined
      ) {
        if (!bookingDetails || bookingDetails._method != "PUT") {
          if (timestart._d.getTime() < beforetime._d.getTime()) {
            setTimeError(
              `Lead time must be greater than ${venue?.lead_time} hours `
            );
            setDurationCheck(true);
            return null;
          }
        }
      }
    }
    // if (bookAs !== 0) {
    //   let selectedEmployer: any = employer.find(v => v.id == bookAs);
    // if (
    //   employer &&
    //   bookAs !== 0 &&
    //   selectedEmployer &&
    //   selectedEmployer.ohs_requirment! ==
    //     "Venues meet OH&S requirements" &&
    //   venue?.ohs_only != "yes"
    // ) {
    //   toast.error(
    //     `Unfortunately this venue doesn’t meet ${employer[0].company_name}’s OH&S requirements`
    //   );
    //   setDurationCheck(true);
    //   return null;
    // }
    // if (
    //   (employer &&
    //     bookAs !== 0 &&
    //     selectedEmployer.vaccination_requirment ==
    //       "Fully Vaccinated" &&
    //     venue?.vaccination_status != "Fully Vaccinated") ||
    //   (employer &&
    //     bookAs != "Personal" &&
    //     selectedEmployer.vaccination_requirment ==
    //       "Partially or Fully Vaccinated" &&
    //     venue?.vaccination_status != "Fully Vaccinated" &&
    //     venue?.vaccination_status != "Partially Vaccinated")
    // ) {
    //   toast.error(
    //     `Unfortunately this venue doesn’t meet ${employer[0].company_name}’s Vaccination requirements`
    //   );
    //   setDurationCheck(true);
    //   return null;
    // }
    // }
    if (space && space.min_booking_hours) {
      if (space && diff < Number(space.min_booking_hours)) {
        toast.error(
          "Please check that time selected falls within minimum and maximum hour requirement"
        );

        setDurationCheck(true);
        return null;
      } else {
        setDurationCheck(false);
      }
    }

    if (space && space.max_booking_hours) {
      if (diff > Number(space!!.max_booking_hours)) {
        toast.error(
          "Please check that time selected falls within minimum and maximum hour requirement"
        );

        setDurationCheck(true);
        return null;
      } else {
        setDurationCheck(false);
      }
    }
    if (space) {
      var totalHour;
      let spacePrice;
      let totalCredit2;
      let min_price;

      let second = (timeEnd.getTime() - timeStart.getTime()) / 1000;
      totalHour = second / 3600;
      if (space!!.min_booking_hours == null) {
        totalHour = second / 60;
        min_price = Math.ceil(parseFloat(space!!.price!!) / 0.3);
        min_price = min_price / 60;
        totalCredit2 = Math.ceil(totalHour * min_price);
      } else if (totalHour >= space!!.min_booking_hours!!) {
        spacePrice = space && parseFloat(space!!.price);
        totalHour = totalHour * (spacePrice ? spacePrice : 1);
        totalCredit2 = Math.ceil(totalHour / 0.3);
      } else if (totalHour < space!!.min_booking_hours!!) {
        spacePrice = space && parseFloat(space.price);
        let minHours = Number(space && space.min_booking_hours);
        totalHour = minHours * (spacePrice ? spacePrice : 1);
        totalCredit2 = Math.ceil(totalHour / 0.3);
      }

      let selectedEmployer = employer.find(v => v.id == bookAs);
      let book: any;
      var total;
      if (bookAs != 0 && user) {
        const token: any = store.getState();

        let Orgemployers = await BookingAPI.EmployerList(
          token.auth.token
        );
        if (Orgemployers.data) {
          const filteredusers = await Orgemployers.data
            .filter((data: any) => data.id !== user.id)
            .map((item: any) => {
              item["label"] = item.full_name;
              item["value"] = item.id;
              return item;
            });
          dispatch(setOrgEmployees(filteredusers));
        }
        total = totalCredit2 ? totalCredit2 * no_of_spaces : 0;
        book = {
          user_id: user.id,
          space_id: parseInt(spaceId),
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          start_time: formatedTime(timeStart),
          end_time: formatedTime(timeEnd),
          booking_as: "employer",
          no_of_people: no_of_people,
          no_of_booked_spaces: no_of_spaces,
          venue_id: parseInt(venueId),
          credits: total,
          credits_per_hour: Math.ceil(
            parseFloat(space!!.price!!) / 0.3
          ),
          teams: bookingDetails.teams ? bookingDetails.teams : [],
          contacts: bookingDetails.contacts
            ? bookingDetails.contacts
            : [],
          employer_id: selectedEmployer!.id,
          employer: selectedEmployer ? selectedEmployer : null,
          _method: bookingDetails._method
            ? bookingDetails._method
            : "",
          invited_employees: bookingDetails.invited_employees
            ? bookingDetails.invited_employees
            : []
        };
        let request: any = {};
        let requestTimeStart = formatedTime(timeStart).split(":");
        let requestTimeEnd = formatedTime(timeEnd).split(":");
        request = {
          date: formatDate(startDate),
          opening_time:
            requestTimeStart[0] + ":" + requestTimeStart[1],
          closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
          space_id: parseInt(spaceId),
          no_of_spaces: no_of_spaces
        };
        if (bookingDetails._method === "PUT") {
          request["booking_id"] = Number(
            window.localStorage.getItem("booking_Id")
          );
          book["previousCredits"] = bookingDetails.previousCredits;
        }
        let response1 = await BookingAPI.BookingsValidations(
          new SpaceAvailabilityRequest(request)
        );
        if (response1.data.availability) {
          setAvailabilityCheck("");
          setSpaceAvailabilityError({
            availability: true,
            max_available: response1.data.max_available
          });
          let response = await BookingAPI.GetSpaceAvailability(
            new SpaceAvailabilityRequest(request)
          );

          if (response.data) {
            if (response.data.availability) {
              dispatch(setBooking(book));
              history.push(`/book/2/${venueId}/${spaceId}`);
              setAvailabilityCheck("");
            } else {
              setAvailabilityCheck(response.data.message);
            }
          } else {
            setAvailabilityCheck(
              "Workspace is not available on the selected day and time."
            );
          }
        } else setSpaceAvailabilityError(response1.data);
      } else {
        const token: any = store.getState();
        let Orgemployers = await BookingAPI.EmployerList(
          token.auth.token
        );
        if (Orgemployers.data) {
          const filteredusers = await Orgemployers.data
            .filter((data: any) => data.id !== user!.id)
            .map((item: any) => {
              item["label"] = item.full_name;
              item["value"] = item.id;
              return item;
            });
          dispatch(setOrgEmployees(filteredusers));
        }
        total = totalCredit2 ? totalCredit2 * no_of_spaces : 0;
        book = {
          user_id: user!.id,
          space_id: parseInt(spaceId),
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          start_time: formatedTime(timeStart),
          end_time: formatedTime(timeEnd),
          booking_as: "customer",
          no_of_people: no_of_people,
          no_of_booked_spaces: no_of_spaces,
          venue_id: parseInt(venueId),
          credits: total,
          credits_per_hour: Math.ceil(
            parseFloat(space!!.price!!) / 0.3
          ),
          teams: bookingDetails.teams ? bookingDetails.teams : [],
          contacts: bookingDetails.contacts
            ? bookingDetails.contacts
            : [],
          invited_employees: bookingDetails.invited_employees
            ? bookingDetails.invited_employees
            : [],
          _method: bookingDetails._method
            ? bookingDetails._method
            : ""
        };
      }
      let request: any = {};
      let requestTimeStart = formatedTime(timeStart).split(":");
      let requestTimeEnd = formatedTime(timeEnd).split(":");
      request = {
        date: formatDate(startDate),
        opening_time: requestTimeStart[0] + ":" + requestTimeStart[1],
        closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
        space_id: parseInt(spaceId),
        no_of_spaces: no_of_spaces
      };
      if (bookingDetails._method === "PUT") {
        request["booking_id"] = Number(
          window.localStorage.getItem("booking_Id")
        );
        book["previousCredits"] = bookingDetails.previousCredits;
      }
      let response1 = await BookingAPI.BookingsValidations(
        new SpaceAvailabilityRequest(request)
      );
      if (response1.data.availability) {
        setAvailabilityCheck("");
        setSpaceAvailabilityError({
          availability: true,
          max_available: response1.data.max_available
        });
        let response = await BookingAPI.GetSpaceAvailability(
          new SpaceAvailabilityRequest(request)
        );
        if (response.data) {
          if (response.data.availability) {
            dispatch(setBooking(book));
            history.push(`/book/2/${venueId}/${spaceId}`);
            setAvailabilityCheck("");
          } else {
            setAvailabilityCheck(response.data.message);
          }
        } else {
          setAvailabilityCheck(
            "Workspace is not available on the selected day and time."
          );
        }
      } else setSpaceAvailabilityError(response1.data);
    }
  };

  const handleFromDate = async (event: any) => {
    var selectedDate = event;
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    date = event;
    if (event) {
      setStartDate(event);
      setEndDate(event);
      setTempDate(event);
      setDateError("");
    }
    if (selectedDate.getTime() === currentDate.getTime()) {
      if (timeStart.getTime() < new Date().getTime()) {
        setTimeError("Please select a future time");
      } else {
        setFromDateError("");
        hasFromDateError(false);
        setAvailabilityCheck("");
        setTimeError("");
      }
    } else {
      setFromDateError("");
      hasFromDateError(false);
      setAvailabilityCheck("");
      setDisableTimes(false);
      setTimeError("");
    }
    if (timeStart && timeEnd) {
      let requestTimeStart = formatedTime(timeStart).split(":");
      let requestTimeEnd = formatedTime(timeEnd).split(":");
      let request: any = {
        date: formatDate(event),
        opening_time: requestTimeStart[0] + ":" + requestTimeStart[1],
        closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
        space_id: parseInt(spaceId)
      };
      if (bookingDetails._method === "PUT") {
        request["booking_id"] = Number(
          window.localStorage.getItem("booking_Id")
        );
      }
      let response = await BookingAPI.BookingsValidations(
        new SpaceAvailabilityRequest(request)
      );
      if (response.data.availability) {
        setAvailabilityCheck("");
        setSpaceAvailabilityError({
          availability: true,
          max_available: response.data.max_available
        });
      } else setSpaceAvailabilityError(response.data);
    }
  };

  const hanleSpaceCheck = async () => {
    let requestTimeStart: any = formatedTime(timeStart).split(":");
    let requestTimeEnd = formatedTime(timeEnd).split(":");
    let request: any = {
      date: formatDate(startDate),
      opening_time: requestTimeStart[0] + ":" + requestTimeStart[1],
      closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
      space_id: parseInt(spaceId)
    };
    if (bookingDetails._method === "PUT") {
      request["booking_id"] = Number(
        window.localStorage.getItem("booking_Id")
      );
    }
    let response = await BookingAPI.BookingsValidations(
      new SpaceAvailabilityRequest(request)
    );
    if (response.data.availability) {
      setAvailabilityCheck("");
      setSpaceAvailabilityError({
        availability: true,
        max_available: response.data.max_available
      });
    } else {
      setSpaceAvailabilityError(response.data);
    }
  };
  useEffect(() => {
    if (timeStart && timeEnd) {
      hanleSpaceCheck();
    }
  }, [timeStart, timeEnd]);

  useEffect(() => {
    if (timeStart && timeEnd) {
      hanleSpaceCheck();
    }
    if (bookingDetails.end_date == "" && !searchResult.start_time) {
      var currentDate = new Date();
      var date = startDate.setHours(0, 0, 0, 0);
      let date1 = currentDate.setHours(0, 0, 0, 0);

      if (date === date1) {
        if (new Date().getTime() > timeStart.getTime()) {
          var formatedTime = new Date(
            Number(new Date().getFullYear()),
            Number(new Date().getMonth()),
            Number(new Date().getDate()),
            Number(new Date().getHours() + 1),
            Number(0)
          );
          setStartTime(formatedTime);
        }
        var formatedTime = new Date(
          Number(new Date().getFullYear()),
          Number(new Date().getMonth()),
          Number(new Date().getDate()),
          Number(new Date().getHours() + 2),
          Number(0)
        );
        setEndTime(formatedTime);
      }
    }
  }, []);

  const handleFromTime = async (event: any) => {
    setTimeError("");
    setDurationCheck(false);
    // setSpaceAvailabilityError({});
    setAvailabilityCheck("");
    if (event) {
      if (
        startDate == "" ||
        startDate == null ||
        startDate == undefined
      ) {
        setStartTime(event._d);
        let year = event._d.getFullYear();
        let month = event._d.getMonth();
        let hours = event._d.getHours();
        let day = event._d.getDate();
        let mins = event._d.getMinutes();
        var formatedTimenew = new Date(
          Number(year),
          Number(month),
          Number(day),
          Number(hours + 1),
          Number(mins)
        );
        setEndTime(formatedTimenew);
        return;
      }
      let event1 = event;
      var isFlag = false;
      var currentDate = new Date();
      var date = startDate.setHours(0, 0, 0, 0);
      let date1 = currentDate.setHours(0, 0, 0, 0);
      if (date === date1) {
        if (event1._d.getTime() < new Date().getTime()) {
          setTimeError("Please select a future time");
          isFlag = true;
        } else {
          setStartTime(event._d);
          setTimeError("");
        }
        if (
          space &&
          venue &&
          venue?.lead_time != 0 &&
          venue?.lead_time != null &&
          venue?.lead_time != undefined
        ) {
          const beforetime: any = moment(new Date()).add(
            venue?.lead_time,
            "hours"
          );
          if (event1._d.getTime() < beforetime._d.getTime()) {
            setTimeError(
              `Lead time must be greater than ${venue?.lead_time} hours `
            );
            isFlag = true;
          }
        }
      } else {
        setStartTime(event._d);
        isFlag = false;
        setTimeError("");
      }
      if (!isFlag) {
        let year = event._d.getFullYear();
        let month = event._d.getMonth();
        let hours = event._d.getHours();
        let day = event._d.getDate();
        let mins = event._d.getMinutes();
        var formatedTimenew = new Date(
          Number(year),
          Number(month),
          Number(day),
          Number(hours + 1),
          Number(mins)
        );
        setEndTime(formatedTimenew);
      }
      let year = event._d.getFullYear();
      let month = event._d.getMonth();
      let hours = event._d.getHours();
      let day = event._d.getDate();
      let mins = event._d.getMinutes();
      var formatedTimenew = new Date(
        Number(year),
        Number(month),
        Number(day),
        Number(hours + 1),
        Number(mins)
      );
      // let requestTimeStart = formatedTime(event._d).split(":");
      // let requestTimeEnd = formatedTime(formatedTimenew).split(":");
      // let request: any;
      // request = {
      //   date: formatDate(startDate),
      //   opening_time: requestTimeStart[0] + ":" + requestTimeStart[1],
      //   closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
      //   space_id: parseInt(spaceId)
      // };
      // if (bookingDetails._method === "PUT") {
      //   request["booking_id"] = Number(
      //     window.localStorage.getItem("booking_Id")
      //   );
      // }
      // let response = await BookingAPI.BookingsValidations(
      //   new SpaceAvailabilityRequest(request)
      // );
      // if (response.data.availability) {
      //   setAvailabilityCheck("");
      //   setSpaceAvailabilityError({
      //     availability: true,
      //     max_available: response.data.max_available
      //   });
      // } else setSpaceAvailabilityError(response.data);
    }
  };
  const handleToTime = async (event: any) => {
    setTimeError("");
    // setSpaceAvailabilityError({});
    setAvailabilityCheck("");
    if (timeStart && event) {
      var diff = event._d.getHours() - timeStart.getHours();
      if (space && space.min_booking_hours) {
        if (space && diff < Number(space.min_booking_hours)) {
          toast.error(
            "Please check that time selected falls within minimum and maximum hour requirement"
          );
          setDurationCheck(true);
        } else {
          setTimeError("");
          setDurationCheck(false);
        }
      }

      if (space && space.max_booking_hours) {
        if (diff > Number(space!!.max_booking_hours)) {
          toast.error(
            "Please check that time selected falls within minimum and maximum hour requirement"
          );
          setDurationCheck(true);
        } else {
          setDurationCheck(false);
        }
      }

      if (timeStart.getTime() > event._d.getTime()) {
        setTimeError("Please a valid time");
      } else {
        setEndTime(event._d);
        setTimeError("");
      }

      // if (
      //   startDate != "" &&
      //   startDate != undefined &&
      //   startDate != null
      // ) {
      //   let requestTimeStart = formatedTime(timeStart).split(":");
      //   let requestTimeEnd = formatedTime(event._d).split(":");
      //   let request: any;
      //   request = {
      //     date: formatDate(startDate),
      //     opening_time:
      //       requestTimeStart[0] + ":" + requestTimeStart[1],
      //     closing_time: requestTimeEnd[0] + ":" + requestTimeEnd[1],
      //     space_id: parseInt(spaceId)
      //   };
      //   if (bookingDetails._method === "PUT") {
      //     request["booking_id"] = Number(
      //       window.localStorage.getItem("booking_Id")
      //     );
      //   }
      //   let response = await BookingAPI.BookingsValidations(
      //     new SpaceAvailabilityRequest(request)
      //   );
      //   if (response.data.availability) {
      //     setAvailabilityCheck("");
      //     setSpaceAvailabilityError({
      //       availability: true,
      //       max_available: response.data.max_available
      //     });
      //   } else setSpaceAvailabilityError(response.data);
      // }
    }
  };
  const hasErrors = () => {
    if (space) {
      let capacity = space!.capacity * no_of_spaces;
      return (
        no_of_people <= 0 ||
        no_of_people > capacity ||
        no_of_spaces <= 0 ||
        no_of_spaces >
          Number(
            Object.keys(spaceAvailabilityError).length > 0
              ? spaceAvailabilityError.availability
                ? spaceAvailabilityError.max_available
                : 0
              : space!.quantity
          )
      );
    }
  };
  const numhasErrors = () => {
    if (space) {
      let capacity = space!.capacity * Number(no_of_spaces);

      return no_of_people > capacity;
    }
  };

  const spacehasErrors = () => {
    if (space) {
      let availabile = availableSpaces
        ? availableSpaces
        : Number(
            Object.keys(spaceAvailabilityError).length > 0
              ? spaceAvailabilityError.max_available
              : space!.quantity
          );
      return no_of_spaces > availabile;
    }
  };

  const handleBookingAs = (event: any) => {
    let hasEmployer = employer;

    if (event.target.value != 0) {
      let check = hasEmployer.find(
        v => v.id === parseInt(event.target.value)
      );

      if (check!.ohs_only === "true") {
        if (venue && venue.assessment == null) {
          toast.error("You can only book OH&S verified venues");
          setonly_ohsError(true);
        } else if (venue && venue.assessment.result == "Fail") {
          toast.error("You can only book OH&S verified venues");
          setonly_ohsError(true);
        }
      } else {
        setonly_ohsError(false);
      }
    }
    setBookAs(parseInt(event.target.value));
  };

  const NumberofSpaces = (event: any) => {
    // setSpaceAvailabilityError({});
    setNumSpaces(event.target.value);
  };

  const NumberofPeople = (event: any) => {
    setNumPeople(event.target.value);
  };
  useEffect(() => {
    dispatch(getPublicVenuesEffect());
  }, []);
  // const hideModal = () => {
  //   setShowVaccinationDetails(false);
  // };
  const inputRef = useRef(null);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          title="Dorpee Venue Bookings"
          content="Dorpee-workspaces on demand"
        />
        <title>
          {`Dorpee - ${venue && venue.name} - ${
            space && space.name
          } - 1`}
        </title>
      </Helmet>

      <div className="body">
        <div className="container containerAddSpace">
          <VenueSteps step={1} isForBooking></VenueSteps>
          <br />
          <div className="page-wrapper">
            {bookingDetails && bookingDetails._method === "PUT" ? (
              <p
                style={{
                  color: "#009cdc",
                  marginLeft: "0px",
                  fontSize: "20px",
                  fontWeight: 400,
                  marginBottom: "10px"
                }}
              >
                {" "}
                Update Booking
              </p>
            ) : (
              ""
            )}
            <BookingVenue
              firstPage
              venue={space}
              availabilityCheck={availabilityCheck}
            />

            <div>
              <div className="filter-block">
                <div className="booking-search__tags-title">
                  Define Timeframe
                </div>
                <div className="input-container">
                  <DatePicker
                    disabled={disableTimes}
                    selected={startDate}
                    onChange={e => handleFromDate(e)}
                    className="Booking_date"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    customInput={
                      <DatePickerInput inputRef={inputRef} />
                    }
                  />
                </div>
                <span style={{ color: "red", fontSize: "11px" }}>
                  {dateError}
                </span>
              </div>

              <div className="filter-block">
                <div className="filter-time__group">
                  <div
                    className="input-container"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <img
                      src="/img/icons/clock.svg"
                      alt="From"
                      style={{
                        position: "absolute",
                        right: "1%"
                      }}
                      onClick={() => {
                        if (!timeStartOpen) setStartTimeOpen(true);
                      }}
                    />
                    <TimePicker
                      prefixCls="input-container-prefix"
                      disabled={disableTimes}
                      value={moment(timeStart)}
                      open={timeStartOpen}
                      onOpen={() => setStartTimeOpen(true)}
                      onClose={() => setStartTimeOpen(false)}
                      showSecond={false}
                      onChange={e => handleFromTime(e)}
                      minuteStep={15}
                    />
                  </div>
                  <div
                    className="input-container"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <img
                      src="/img/icons/clock.svg"
                      alt="To"
                      style={{
                        position: "absolute",
                        right: "1%"
                      }}
                      onClick={() => {
                        if (!timeEndOpen) setEndTimeOpen(true);
                      }}
                    />
                    <TimePicker
                      prefixCls="input-container-prefix"
                      disabled={disableTimes}
                      value={moment(timeEnd)}
                      open={timeEndOpen}
                      onOpen={() => setEndTimeOpen(true)}
                      onClose={() => setEndTimeOpen(false)}
                      showSecond={false}
                      onChange={e => handleToTime(e)}
                      minuteStep={15}
                    />
                  </div>
                </div>
              </div>
              <span style={{ color: "red", fontSize: "11px" }}>
                {timeError}
              </span>
              {employer.length > 0 && (
                <div className="filter-block">
                  <div className="booking-search__tags-title">
                    I'm Booking Under
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className="col-md-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <div
                        className="input-container input__icon m-0 col-6"
                        style={{
                          height: "40px",
                          background: bookAs === 0 ? "#bbb9b9" : ""
                        }}
                        onClick={() => {
                          setBookAs(0);
                        }}
                      >
                        <div className="input__icon--bookinguser"></div>
                      </div>
                      <div
                        className="input-container input__icon m-0 col-6"
                        style={{
                          height: "40px",
                          background: bookAs !== 0 ? "#bbb9b9" : ""
                        }}
                        onClick={() => {
                          setBookAs(employer[0].id);
                        }}
                      >
                        <div className="input__icon--employee"></div>
                      </div>
                    </div>
                    <div className="input-container col-md-10">
                      <div className="input-select pl-12">
                        <select
                          value={bookAs}
                          onChange={e => {
                            handleBookingAs(e);
                          }}
                        >
                          {
                            <>
                              {bookAs !== 0
                                ? employer.map((emp, index) => {
                                    return (
                                      <option
                                        selected={
                                          emp.id == bookAs
                                            ? true
                                            : false
                                        }
                                        value={emp.id}
                                      >
                                        {`${
                                          emp.company_name
                                        }${" "}Subscription`}
                                      </option>
                                    );
                                  })
                                : ""}
                            </>
                          }
                          {
                            <>
                              {bookAs === 0
                                ? employer.length >= 0 && (
                                    <option value={0}>
                                      My Account Credits
                                    </option>
                                  )
                                : ""}
                            </>
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="filter-block">
                <div className="booking-search__tags-title">
                  Number of Workspaces
                </div>
                <div className="input-container">
                  <input
                    className={"noButtons"}
                    onChange={e => NumberofSpaces(e)}
                    type="number"
                    id="numofSpaces"
                    placeholder="Number of Spaces"
                    value={no_of_spaces}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>

                <div className="space-capacity_row">
                  <div className="space-capacity">
                    Available Workspaces:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {availableSpaces != null
                        ? availableSpaces
                        : Object.keys(spaceAvailabilityError).length >
                          0
                        ? spaceAvailabilityError.availability
                          ? spaceAvailabilityError.max_available
                            ? spaceAvailabilityError.max_available
                            : 0
                          : 0
                        : space && space.quantity}
                    </span>
                  </div>
                </div>
              </div>

              <div className="filter-block">
                <div className="booking-search__tags-title">
                  Total Number of People
                </div>
                <div className="input-container">
                  <input
                    className={"noButtons"}
                    onChange={e => NumberofPeople(e)}
                    type="number"
                    id="numofPpl"
                    placeholder="Number of People"
                    value={no_of_people}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>

                <div className="space-capacity_row">
                  <div className="space-capacity">
                    Total Space Capacity:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {space && space.capacity * no_of_spaces}
                    </span>
                  </div>
                </div>
              </div>
              <p
                style={{
                  padding: 10,
                  border: " 0.5px dashed red",
                  borderRadius: 9
                }}
              >
                You have booked for {no_of_people} people. If you
                would like to increase your numbers beyond the total
                space capacity, you can make changes to the number of
                workspaces booked, or book another workspace type.
                {/* ﻿Please remember, all attendees need to comply with
                the venue’s vaccination requirements. These details
                can be found{" "} */}
                {/* <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold"
                  }}
                  onClick={() => setShowVaccinationDetails(true)}
                >
                  here
                </span> . */}
                {/* You can have{" "}
                {space && space!!.capacity * no_of_spaces} people attending the
                booking. Please remember, the venue will not accept the number
                of people over the number you claimed here */}
              </p>
              <span style={{ color: "red", fontSize: "11px" }}>
                {fromDateValidation}
              </span>
              {numhasErrors() ? (
                <p style={{ color: "#ff0000" }}>
                  Number of people is greater than workspace capacity{" "}
                </p>
              ) : (
                ""
              )}
              {spacehasErrors() ? (
                <p style={{ color: "#ff0000" }}>
                  {spaceAvailabilityError.message
                    ? spaceAvailabilityError.message
                    : "Number of Workspaces selected is greater than what is available "}
                </p>
              ) : (
                ""
              )}
              <p
                style={{
                  marginTop: 10,
                  color: "red",
                  textTransform: "none"
                }}
              >
                {availabilityCheck}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 25,
                  marginBottom: 60
                }}
              >
                <button
                  onClick={() => history.goBack()}
                  className={`btn btn-white wizard__btn bookings-process__btn`}
                  style={{ marginBottom: 60, width: "45%" }}
                >
                  Back
                </button>
                <button
                  style={{ marginBottom: 60, width: "45%" }}
                  onClick={() => submitBookingData()}
                  disabled={
                    // Object.keys(spaceAvailabilityError).length > 0 ||
                    hasErrors() ||
                    dateFromError ||
                    timeError != "" ||
                    dateError != ""
                      ? true
                      : false || only_ohsError
                  }
                  className={
                    // Object.keys(spaceAvailabilityError).length > 0 ||
                    hasErrors() ||
                    dateFromError ||
                    isDurationValid ||
                    timeError != "" ||
                    dateError != "" ||
                    only_ohsError
                      ? `btn btn-disable wizard__btn bookings-process__btn`
                      : `btn btn-blue2 wizard__btn bookings-process__btn`
                  }
                >
                  NEXT
                  <img src="/img/icons/arrow-forward.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer
        // isSubs
        />

        {/* {vaccinationDetails ? (
          <VaccinationRequirementModal
            message={message}
            decline={hideModal}
            modalHeader={"Vaccination Requirement"}
            acceptText={"Update Profile"}
            declineText={"Cancel"}
            history={history}
          />
        ) : null} */}
      </div>
    </>
  );
};

export default BookStep1;
